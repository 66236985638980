import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector:    'app-switch',
	templateUrl: './switch.component.html',
	styleUrls:   ['./switch.component.scss']
})
export class SwitchComponent {
	@Input() disabled        = false;
	@Input() value: boolean;
	@Input() falseLabel      = 'Off';
	@Input() trueLabel       = 'On';
	@Output() onValueChanged = new EventEmitter();

	constructor() {
	}

}
