import {
	Component,
	Input, NgModule,
	OnDestroy,
	OnInit,
	SecurityContext,
	TemplateRef,
	ViewChild
} from '@angular/core';
import {DxDataGridComponent, DxPopupComponent} from 'devextreme-angular';
import {UserInterfaceService} from '../../../shared/services/user-interface.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {Tab} from '../../../shared/models/tab';
import {SnapshotService} from '../../../shared/services/snapshot.service';
import {TabService} from '../../../shared/services/tab.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'app-bars-rich-text',
	templateUrl: './bars-rich-text.component.html',
	styleUrls: ['./bars-rich-text.component.scss']
})
export class BARSRichTextComponent implements OnInit, OnDestroy {

	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	@ViewChild('content') content: TemplateRef<any>;
	@ViewChild(DxDataGridComponent) dxDataGrid: DxDataGridComponent;
	private uiSubscription: Subscription;

	@Input() tab: Tab;

	accountDescriptorsDataSource: Array<any>;
	isVisible = true;
	html: any;

	constructor(
		private uiService: UserInterfaceService,
		private snapshotService: SnapshotService,
		private tabService: TabService,
		private sanitizer: DomSanitizer
	) {
		this.uiSubscription = this.uiService.richText$.subscribe(
			value => this.isVisible = value
		);
	}

	ngOnInit() {
		this.tabService.tabs.subscribe((tabs) => {
			const currentTabObject = tabs.find((tab) => tab.selected);
			if (!currentTabObject) {
				return;
			}
			currentTabObject.snapshotIdSubject.subscribe(snapshotId => {
				this.snapshotService.getSnapshot(snapshotId).pipe(first()).subscribe(snapshot => {
						this.accountDescriptorsDataSource = snapshot?.detail.accountDescriptors.map(e => {
								e.description = this.sanitizer.sanitize(SecurityContext.HTML, e.description);
								return e;
							}
						);
					}
				);

			});
		});
	}

	hasHtml(rowData) {
		const endTag = '</';
		if (rowData.description?.includes(endTag)) {
			return true;
		} else {
			return false;
		}
	}

	ngOnDestroy() {
		this.uiSubscription.unsubscribe();
	}

	close = () => this.isVisible = false;

}
