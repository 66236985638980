import {ExploreType} from './explore-by-option';
import {ReportType} from './report-type-menu-option';
import {DatasetSource} from '../../modules/api/fit-api/models/datasets/dataset-source';
import {HintInformation} from './hint-information';

export type ReportId = 'summary' | 'revenues' | 'revenuesWithOthers' | 'expenditures' | 'expendituresWithOthers'
	| 'debtAndLiabilities' | 'schedule01CategoryTotals' | 'schedule09CategoryTotals' | 'governmentalIndicators' | 'enterpriseFundIndicators'
	| 'operatingResultsAnalysisEnterprise' | 'operatingResultsAnalysisGovernmental' | 'schoolsBalanceSheet'
	| 'schoolsStatementRevExp' | 'schoolsRevenuesWithOthers' | 'schoolsExpenditures' | 'schoolsLiabilities' | 'schoolsRevenues' | 'schoolsGeneralFundExpenditures' |
	'schoolsLongTermLiabilities';

/**
 * todo this needs to be subclassed by DatasetSource since each set has different defining properties. (E.g. OSPI has
 *  reportNo, but SAOAnnualFiling has fsSectionIds)
 */
export interface Report {
	id: ReportId;
	name: string;
	supportedExploreTypes: Array<ExploreType>;
	supportedReportType: ReportType;
	financialsDatasetSource: DatasetSource;
	hintInformation?: keyof typeof HintInformation;
	showRowGrandTotals?: boolean;
	hasAccountCodes?: boolean;
	// this report allows you to view empty report lines/categories
	canViewEmptyCells?: boolean;
	supportsProjections?: boolean;
	beta?: boolean;
	rowHeaderLayout?: string;
	hideTotalText?: boolean;
	sortOrder: number;

	/**
	 * Reports that can have their amounts be summed to a single number. E.g. for display on the government profile.
	 */
	canBeSummed?: boolean;

	/**
	 * Only for SAOAnnualFiling. If present, this report is defined by filtering on these values.
	 */
	fsSectionIds?: Array<number>;

	/**
	 * Only for OSPI. If present, this report is defined by filtering on this value.
	 */
	reportNo?: string;
	/**
	 * Only for OSPI. If present, this report is defined by filtering on this value.
	 */
	itemCategories?: Array<string>;
}

export const REPORTS: ReadonlyArray<Readonly<Report>> = [
	/* LGFRS-SAO Annual Filing Reports */
	{ id: 'summary', name: 'Financial Summary', showRowGrandTotals: false, hasAccountCodes: true, supportsProjections: true,
		sortOrder: 1,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.individual, ExploreType.comparison, ExploreType.governmentType],
		financialsDatasetSource: 'SAOAnnualFiling',
		hintInformation: 'totalFinancialSummary',
	},
	{ id: 'revenues', name: 'Revenues', showRowGrandTotals: true, hasAccountCodes: true, supportsProjections: true,
		sortOrder: 2,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.individual, ExploreType.comparison, ExploreType.governmentType],
		financialsDatasetSource: 'SAOAnnualFiling',
		hintInformation: 'revenues', fsSectionIds: [20], canBeSummed: true
	},
	{ id: 'revenuesWithOthers', name: 'Revenues and Other Increases', showRowGrandTotals: true, hasAccountCodes: true, supportsProjections: true,
		sortOrder: 3,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.individual, ExploreType.comparison, ExploreType.governmentType],
		financialsDatasetSource: 'SAOAnnualFiling', fsSectionIds: [20, 25], canBeSummed: true
	},
	{ id: 'expenditures', name: 'Expenditures', showRowGrandTotals: true, hasAccountCodes: true, supportsProjections: true,
		sortOrder: 4,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.individual, ExploreType.comparison, ExploreType.governmentType],
		financialsDatasetSource: 'SAOAnnualFiling',
		hintInformation: 'expenditures', fsSectionIds: [30], canBeSummed: true
	},
	{ id: 'expendituresWithOthers', name: 'Expenditures and Other Decreases', showRowGrandTotals: true, hasAccountCodes: true, supportsProjections: true,
		sortOrder: 5,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.individual, ExploreType.comparison, ExploreType.governmentType],
		financialsDatasetSource: 'SAOAnnualFiling', fsSectionIds: [30, 35], canBeSummed: true
	},
	{ id: 'debtAndLiabilities', name: 'Outstanding Debt and Liabilities', showRowGrandTotals: true, beta: true,
		sortOrder: 6,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.individual, ExploreType.comparison, ExploreType.category],
		financialsDatasetSource: 'SAOAnnualFiling',
		hintInformation: 'debtAndLiabilities', canBeSummed: true, hasAccountCodes: true
	},
	{ id: 'schedule01CategoryTotals', name: 'Government Function', showRowGrandTotals: true,
		sortOrder: 7,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.category],
		financialsDatasetSource: 'SAOAnnualFiling'
	},
	{ id: 'schedule09CategoryTotals', name: 'Government Function', showRowGrandTotals: true,
		sortOrder: 7,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.category],
		financialsDatasetSource: 'SAOAnnualFiling'
	},
	{ id: 'governmentalIndicators', name: 'Governmental Indicators', showRowGrandTotals: true, rowHeaderLayout: 'standard',
		sortOrder: 8,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.financialCondition],
		financialsDatasetSource: 'SAOAnnualFiling'
	},
	{ id: 'enterpriseFundIndicators', name: 'Enterprise Fund Indicators', showRowGrandTotals: true, rowHeaderLayout: 'standard',
		sortOrder: 9,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.financialCondition],
		financialsDatasetSource: 'SAOAnnualFiling'
	},
	{ id: 'operatingResultsAnalysisEnterprise', name: 'Enterprise Operating Results Analysis', beta: true, showRowGrandTotals: false, hasAccountCodes: true, rowHeaderLayout: 'standard',
		sortOrder: 10,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'SAOAnnualFiling',
		hintInformation: 'operatingResultsAnalysisEnterprise',
		hideTotalText: true
	},
	{ id: 'operatingResultsAnalysisGovernmental', name: 'Governmental Operating Results Analysis', beta: true, showRowGrandTotals: false, hasAccountCodes: true, rowHeaderLayout: 'standard',
		sortOrder: 11,
		supportedReportType: ReportType.lgfrs,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'SAOAnnualFiling',
		hintInformation: 'operatingResultsAnalysisGovernmental',
		hideTotalText: true
	},
	/* OSPI-Schools Reports */
	{ id: 'schoolsBalanceSheet', name: 'Balance Sheet', showRowGrandTotals: false, canViewEmptyCells: true,
		sortOrder: 12,
		supportedReportType: ReportType.ospi,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'OSPI', reportNo: '001',
		hintInformation: 'schoolsBalanceSheet'
	},
	{ id: 'schoolsStatementRevExp', name: 'Revenues, Expenditures, & Changes in Fund Balance', showRowGrandTotals: false, canViewEmptyCells: true,
		sortOrder: 13,
		supportedReportType: ReportType.ospi,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'OSPI', reportNo: '002',
		hintInformation: 'schoolsStatementRevExp'
	},
	{ id: 'schoolsRevenues', name: 'Revenues', showRowGrandTotals: false, canViewEmptyCells: true,
		sortOrder: 14,
		supportedReportType: ReportType.ospi,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'OSPI',  reportNo: '002', itemCategories: ['Revenue'], canBeSummed: true
	},
	{ id: 'schoolsRevenuesWithOthers', name: 'Revenues & Other Financing Sources', showRowGrandTotals: false, hasAccountCodes: true, canViewEmptyCells: true,
		sortOrder: 15,
		supportedReportType: ReportType.ospi,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'OSPI', reportNo: '009', canBeSummed: true,
		hintInformation: 'schoolsRevenuesWithOthers'
	},
	{ id: 'schoolsExpenditures', name: 'Expenditures', showRowGrandTotals: false, canViewEmptyCells: true,
		sortOrder: 16,
		supportedReportType: ReportType.ospi,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'OSPI', reportNo: '002', itemCategories: ['Expenditure'], canBeSummed: true,
		hintInformation: 'schoolsExpenditures'
	},
	{ id: 'schoolsGeneralFundExpenditures', name: 'Expenditures Detail (General Fund)', showRowGrandTotals: true, hasAccountCodes: true,
		sortOrder: 17,
		supportedReportType: ReportType.ospi,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'OSPI'
	},
	{ id: 'schoolsLiabilities', name: 'Liabilities', showRowGrandTotals: false, canViewEmptyCells: true,
		sortOrder: 18,
		supportedReportType: ReportType.ospi,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'OSPI', reportNo: '001', itemCategories: ['Liability'], canBeSummed: true
	},
	{ id: 'schoolsLongTermLiabilities', name: 'Outstanding Debt and Liabilities', showRowGrandTotals: false, canViewEmptyCells: true,
		sortOrder: 19,
		supportedReportType: ReportType.ospi,
		supportedExploreTypes: [ExploreType.individual],
		financialsDatasetSource: 'OSPI', reportNo: '013', canBeSummed: true, hintInformation: 'debtAndLiabilities'
	}
];
