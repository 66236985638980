import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {faHistory} from '@fortawesome/free-solid-svg-icons';
import {ReusableGovernmentTypeInputs} from '../../reusable/models/reusable-government-type-inputs';
import {ExternalCommonServiceApiService} from '../../api/external-common-service-api/external-common-service-api.service';
import {GovTypes} from '../../api/external-common-service-api/models/gov-types';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
	selector: 'wasao-government-type-information',
	templateUrl: './government-type-information.component.html',
	styleUrls: ['./government-type-information.component.scss']
})
export class GovernmentTypeInformationComponent extends ReusableGovernmentTypeInputs implements OnInit, OnChanges {

	/**
	 * Action to perform when Gov is clicked.
	 */
	@Input() govClickAction: (mcag: string) => void;

	/**
	 * The action to perform when "Filing Status Report" is clicked.
	 */
	@Input() filerReportClickAction: () => void;

	@Input() showAuditLink: boolean;

	@Input() financialsDatasetSource: string;

	@Input() showPieCharts = true;

	liveGovTypeInfo: GovTypes;
	filingStatusData: boolean;

	enrollmentTotals: number;
	enrollmentYears: string;
	schoolYear: number;

	historicalAuditLink: string;
	icons = {
		history: faHistory
	};

	constructor(
		private common: ExternalCommonServiceApiService,
		private fitApiService: FitApiService
	) {
		super();
	}

	ngOnInit() {
			this.fitApiService.getOSPIDisplayYear().pipe(
				switchMap(schoolYear => {
					return this.fitApiService.getOSPIEnrollmentAveragesTotalOfAllSchools(schoolYear);
				})).subscribe(result => {
					this.enrollmentTotals = result[0].statewideAverageHeadcount,
					this.enrollmentYears = result[0].schoolYear;
				});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.govTypeCode?.currentValue) {
			this.isLoading = true;
			this.common.getGovTypeDetails().subscribe(result => {
				this.liveGovTypeInfo = result.filter(x => x.GovTypeCode === this.govTypeCode)[0];
				this.historicalAuditLink = `https://www.sao.wa.gov/reports-data/audit-reports/?GovType=${this.liveGovTypeInfo.GovTypeCode}&en=${encodeURIComponent(this.liveGovTypeInfo.GovTypeDesc)}`;
				this.isLoading = false;
			});
		}
	}

}
