import {Injectable} from '@angular/core';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {FilingDueDates, FilingStatus, FilingStatusesYearsAndTotals} from './models/filing-status';
import {LocalGovernment} from './models/local-government';
import {GovernmentMetric} from './models/government-metric';
import {forkJoin, Observable, of, share} from 'rxjs';
import {ExternalCommonServiceApiService} from '../external-common-service-api/external-common-service-api.service';
import {FinancialSummary} from './models/financial-summary';
import {GovernmentService} from '../../services/government-service/government.service';
import {FitApiModule} from './fit-api.module';
import {Schedule9} from './models/schedule9';
import {FinancialRanking, PopulationRanking} from '../../info/rankings/models/ranking';
import {IndicatorReport} from './models/indicators/indicator-report';
import {AvailableOperatingResultsAnalyses} from './models/available-operating-results-analyses';
import {MandatoryFieldsEntity} from '../external-common-service-api/models/view-models/mandatory-fields-entity';
import {GovernmentType} from './models/government-type';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {FinancialReportRow} from './models/schools/financial-report-row';
import {OSPIDataset} from './models/datasets/o-s-p-i-dataset';
import {DatasetSource} from './models/datasets/dataset-source';
import {LongTermLiabilityRow} from './models/schools/long-term-liability-row';
import {Enrollment, StatewideEnrollment} from './models/schools/enrollment';
import {IndicatorGroup} from './models/indicators/indicator-group';
import {ODataCollectionResult} from '../odata-collection-result';
import {ShareInProgress} from '../../decorators/share-in-progress';
import {SnapshotId, SnapshotLikeDetailType} from './models/snapshot-like';
import {Snapshot} from './models/snapshot';
import {County} from './models/snapshots/county';
import {ODataResult} from '../odata-result';
import {PublishSnapshotData} from './models/snapshots/publish-snapshot';
import {FundCategoryWithMcagAndYear} from '../../services/fund-service/models/fund-category';

@Injectable({
	providedIn: FitApiModule
})
export class FitApiService {
	/**
	 * Fallback to production if no environment import.
	 */
	private apiUrl = environment?.apis?.app ?? `https://portal.sao.wa.gov/fit/api`;
	private schoolsUrl = `${this.apiUrl}/Schools`;
	private adminUrl = `${environment?.base}/admin`;
	private serviceUrl = `${environment?.base}/service`;

	constructor(
		private httpClient: HttpClient,
		private commonService: ExternalCommonServiceApiService,
		private governmentService: GovernmentService
	) {
	}

	/**
	 * Snapshots are immutable, so cache their observables here once requested.
	 * todo conditionally type
	 */
	private snapshots = new Map<SnapshotId, Observable<any>>();

	/**
	 * Retrieve government types allowed in FIT
	 */
	getGovernmentTypes = this.httpClient
		.get<ODataCollectionResult<GovernmentType>>(`${this.apiUrl}/GovernmentTypes`)
		.pipe(
			map(result => result.value),
			// !!WARNING!! Adding the share() operator here prevents AnnualFilingSummaryService.getItems from working.
			//  Specifically, the call to FinancialSummaryService.getTotalsByReport seems to complete (no catchError(),
			//  and finalize() fires) however subscriptions never fire, even if taken out of the forkJoin.
			// share(),
			shareReplay()
		);

	/**
	 * Retrieve list of Filing Due Dates for each year
	 */
	getAnnualFilingDueDates = this.httpClient.get<ODataCollectionResult<FilingDueDates>>(`${this.apiUrl}/AnnualFilingDueDates`)
		.pipe(
			map(result => result.value),
			shareReplay(1) // cache for lifetime of service
		);

	/**
	 * Retrieve counties allowed in FIT
	 */
	getCounties = this.httpClient.get<ODataCollectionResult<County>>(`${this.apiUrl}/Counties`)
		.pipe(
			map(result => result.value),
			share() // share in-progress requests
		);

	/**
	 * Get all snapshots (without detail), ordered by id, descending (first in array is most recent).
	 * This request is cached. The page needs to be reloaded to get any newly-generated snapshots.
	 */
	getAnnualFilingSnapshots: Observable<Array<Snapshot>> = this.httpClient
		.get(this.getAnnualFilingRoute(null, `?$orderby=id desc`))
		.pipe(
			map((result: any) => result.value),
			shareReplay() // cache for lifetime of service
		);

	/**
	 * Get the latest snapshot's id. This is its own property so that it can be replayed without having to re-evaluate.
	 */
	latestSnapshotId: Observable<SnapshotId> = this.getAnnualFilingSnapshots
		.pipe(
			map(snapshots => snapshots[0].id),
			shareReplay() // cache for lifetime of service
		);

	/**
	 * Emit an Observable of the provided snapshotId, or emit the latestSnapshotId if not provided.
	 * @param snapshotId
	 */
	resolveSnapshot = (snapshotId?: SnapshotId): Observable<SnapshotId> =>
		snapshotId == null
			? this.latestSnapshotId
			: of(snapshotId);

	/**
	 * Get Snapshot or Live, with detail record. This is a shared instance since snapshot data is immutable. This will
	 *  return the proper type when passing parameter id. However, TypeScript currently does not analyze null/undefined
	 *  branches for generics, so I can't indicate that those are SnapshotWithDetail. Arg.
	 *
	 * @param id - Latest (most recent) in system, if not specified.
	 */
	getAnnualFilingSnapshot<T extends SnapshotId, RT extends SnapshotLikeDetailType<T>>(id?: T): Observable<RT> {
		// resolve to latest snapshot if none provided
		return this.resolveSnapshot(id).pipe(switchMap(snapshotId => {
			// If we are requesting a specific snapshot, check to see if Observable is cached
			let observable = this.snapshots.get(snapshotId) as Observable<RT>;
			if (!observable) {
				// if not, generate the request
				observable = this.httpClient
					.get<RT>(this.getAnnualFilingRoute(snapshotId, `?$expand=detail`))
					.pipe(
						catchError(this.handleUnauthorized), // return null for unauthorized
						shareReplay() // always share the same instance of snapshot
					);
				this.snapshots.set(snapshotId, observable); // cache request
			}

			// Get the cached snapshot observable, falling back to latest if none
			return observable;
		}));
	}

	/**
	 * Emits snapshots url to build other endpoints from. Defaults to latestSnapshotId if no snapshotId provided.
	 * @param snapshotId
	 */
	getSnapshotsUrl = (snapshotId?: SnapshotId): Observable<string> =>
		this.resolveSnapshot(snapshotId).pipe(
			map(id => this.getAnnualFilingRoute(id))
		);

	/**
	 * Retrieve a single GovernmentType
	 * @param govTypeCode
	 */
	getGovernmentType = (govTypeCode: string): Observable<GovernmentType> =>
		this.getGovernmentTypes.pipe(map(
			type => type.find(x => x.code === govTypeCode)
		));

	/**
	 * Retrieve a single GovernmentType based on MCAG
	 * @param mcag
	 */
	getGovernmentTypeFromMcag(mcag: string): Observable<GovernmentType> {
		return this.getFITGovernments().pipe(switchMap(governments => {
			const govTypeCode = governments.find(x => x.MCAG === mcag)?.GovTypeCode;
			return this.getGovernmentType(govTypeCode);
		}));
	}

	/**
	 * Retrieve a single GovernmentType based on GovernmentSpecificity
	 * @param specificity
	 */
	getGovernmentTypeFromSpecificity(specificity: GovernmentSpecificity): Observable<GovernmentType> {
		if (specificity.type === 'government') {
			return this.getGovernmentTypeFromMcag(specificity.id);
		} else if (specificity.type === 'governmentType') {
			return this.getGovernmentType(specificity.id);
		}

		throw new Error(`Invalid specificity type: ${specificity.type}`);
	}

	/**
	 * Get the Financials Dataset given a GovernmentSpecificity
	 * @param specificity
	 */
	getDatasetFromSpecificity(specificity: GovernmentSpecificity): Observable<DatasetSource> {
		return this.getGovernmentTypeFromSpecificity(specificity).pipe(
			map(governmentType => governmentType?.financialsDatasetSource)
		);
	}

	/**
	 * @param filter OData filter
	 * @param snapshotId
	 */
	// TODO Decorator breaking 'add projection' logic on financial summary, needs nested switchMap interfering with decorator fix implemented
	// @ShareInProgress
	getFilingStatuses(filter?: string, snapshotId?: SnapshotId): Observable<Array<FilingStatus>> {
		return this.getSnapshotsUrl(snapshotId).pipe(
			switchMap(url => {
				if (filter) {
					url += `/FilingStatuses?$filter=${filter}`;
				} else {
					url += `/FilingStatuses`;
				}
				return this.httpClient.get<ODataCollectionResult<FilingStatus>>(url)
					.pipe(map((result) => result.value));
			})
		);
	}

	@ShareInProgress
	getFilingStatusesYearsAndTotals(amountOfYearsToReturn?: number) {
		const params = `$apply=filter(dateSubmitted ne null and pendingUpdates eq false)/groupby((year),aggregate(mcag with countdistinct as total))&$orderby=year desc&$top=${amountOfYearsToReturn ?? 2}`;
		const url = this.apiUrl + `/FilingStatuses?${params}`;
		return this.httpClient.get<ODataCollectionResult<FilingStatusesYearsAndTotals>>(url)
			.pipe(map((result) => result.value));
	}

	/**
	 * Get a live list of Filing Statuses for active governments (no authentication needed)
	 * @param filter OData filter
	 */
	@ShareInProgress
	getLiveAndUnauthenticatedFilingStatuses(filter?: string ): Observable<Array<FilingStatus>> {
		let url: string;
		 if (filter) {
			url = this.apiUrl + `/FilingStatuses?$filter=${filter}`;
		} else {
			url = this.apiUrl + `/FilingStatuses`;
		}
		return this.httpClient.get<ODataCollectionResult<FilingStatus>>(url).pipe(map((result) => result.value));
	}

	getFilingStatusesForMCAG(mcag: string, snapshotId?: SnapshotId): Observable<Array<FilingStatus>> {

		const filter = `mcag eq '${mcag}'`;
		return this.getFilingStatuses(filter, snapshotId);
	}

	/**
	 * Get the filing statuses for snapshot.barsYearUsed for all governments in the specified GovType.
	 * @param govTypeCode
	 * @param snapshotId
	 */
	getFilingStatusesForGovType(govTypeCode: string, snapshotId?: SnapshotId): Observable<Array<FilingStatus>> {
		return this.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				const filter       = `govTypeCode eq '${govTypeCode}' and year eq ${snapshot.barsYearUsed}`;
				return this.getFilingStatuses(filter, snapshotId);
			})
		);
	}

	getLocalGovernment = (mcag: string, snapshotId?: SnapshotId): Observable<LocalGovernment> =>
		this.getLocalGovernments(`mcag eq '${mcag}'`, snapshotId)
			.pipe(map(result => result[0]));

	@ShareInProgress
	getLocalGovernments(filter: string, snapshotId?: SnapshotId): Observable<Array<LocalGovernment>> {
		return this.getSnapshotsUrl(snapshotId).pipe(
			switchMap(url => {
				url += `/LocalGovernments?$filter=${filter}`;
				return this.httpClient.get<ODataCollectionResult<LocalGovernment>>(url)
					.pipe(map((result) => result.value));
			})
		);
	}

	/**
	 * Get a list of Government Metrics for provided snapshotId
	 * @param filter - OData filter
	 * @param snapshotId
	 */
	@ShareInProgress
	getGovernmentMetrics(filter?: string, snapshotId?: SnapshotId): Observable<Array<GovernmentMetric>> {
		return this.getSnapshotsUrl(snapshotId).pipe(
			switchMap(url => {
				filter ? url += `/GovernmentMetrics?$filter=${filter}` : url += `/GovernmentMetrics`;
				return this.httpClient.get<ODataCollectionResult<GovernmentMetric>>(url).pipe(map((result) => result.value));
			})
		);
	}

	/**
	 * Get the list of Governments from CommonData filtered by the FIT allowed Government Types list.
	 */
	getFITGovernments(): Observable<Array<MandatoryFieldsEntity>> {
		return forkJoin({
			governments: this.commonService.getEntitiesMandatoryFields(),
			types: this.getGovernmentTypes
		}).pipe(
			map(join =>
				join.governments.filter(g => join.types.findIndex(t => t.code === g.GovTypeCode) > -1)
			),
			shareReplay(1) // cache for lifetime of service
		);
	}

	getFinancialRankingsForGovernment = (mcag: string, includeFundCategoryDetail = true, startYear: number, endYear: number, snapshotId?: SnapshotId) => {
		return this.getLocalGovernment(mcag).pipe(
			switchMap(government =>
				this.getFinancialRankingsByGovType(government.govTypeCode, includeFundCategoryDetail, startYear, endYear, `mcag eq '${mcag}'`, snapshotId)
			)
		);
	};

	getPopulationRankingsForGovernment = (mcag: string, startYear: number, endYear: number, snapshotId?: SnapshotId) => {
		return this.getLocalGovernment(mcag).pipe(
			switchMap(government =>
				this.getPopulationRankingsByGovType(government.govTypeCode, startYear, endYear, `mcag eq '${mcag}'`, snapshotId)
			)
		);
	};

	/**
	 * Get rankings for Gov Type. These rankings are based on mcag, snapshotId and year range. Returns numbered 'rank' for revenues and expenditures specified by fsSectionId, basicAccountId, and fundCategoryId (if true)
	 *
	 * @param govTypeCode
	 * @param includeFundCategoryDetail
	 * @param startYear
	 * @param endYear
	 * @param filter
	 * @param snapshotId
	 */
	@ShareInProgress
	getFinancialRankingsByGovType(
		govTypeCode: string,
		includeFundCategoryDetail = true,
		startYear: number,
		endYear: number,
		filter?: string,
		snapshotId?: SnapshotId
	): Observable<Array<FinancialRanking>> {
		return this.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				const filterString = this.resolveQueryStringParameter('?$filter', filter);
				const url          = this.getAnnualFilingRoute(snapshot.id, `/Rankings/GetFinancialRankings(startYear=${startYear},endYear=${endYear},govType='${govTypeCode}',includeFundCategoryDetail=${includeFundCategoryDetail})${filterString}`);
				const request      = this.httpClient.get<ODataCollectionResult<FinancialRanking>>(url);

				return request.pipe(map((result) => result.value));
			})
		);
	}

	/**
	 * Gets population rankings by government type
	 *
	 * @param govTypeCode
	 * @param startYear
	 * @param endYear
	 * @param filter
	 * @param snapshotId
	 */
	@ShareInProgress
	getPopulationRankingsByGovType(
		govTypeCode: string,
		startYear: number,
		endYear: number,
		filter?: string,
		snapshotId?: SnapshotId
	): Observable<Array<PopulationRanking>> {
		return this.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				const filterString = this.resolveQueryStringParameter('?$filter', filter);
				const url          = this.getAnnualFilingRoute(snapshot.id, `/Rankings/GetPopulationRankings(startYear=${startYear},endYear=${endYear},govType='${govTypeCode}')${filterString}`);
				const request      = this.httpClient.get<ODataCollectionResult<PopulationRanking>>(url);

				return request.pipe(map((result) => result.value));
			})
		);
	}

	/**
	 * Lookup the mcag's live GovType from CommonService, and return the flags for the GovType from the snapshot
	 * provided (or latest snapshot, if not).
	 * @param mcag
	 * @param snapshotId - defaults to latest
	 *
	 */
	@ShareInProgress
	getLiveGovTypeFlags(mcag: string, snapshotId?: SnapshotId): Observable<any> {
		return this.commonService.getEntityDetails(mcag).pipe(
			switchMap(entity => this.getAnnualFilingSnapshot(snapshotId).pipe(
				// Then look through the supplied (or fallback) snapshot to find the canHaveFinancials flag on the Gov Type
				map(result => result.detail.governmentTypes.find(x => x.code === entity.GovTypeCode))
			))
		);
	}

	// statewide
	@ShareInProgress
	getFinancialSummariesStatewide(snapshotId?: SnapshotId): Observable<Array<FinancialSummary>> {
		return this.getSnapshotsUrl(snapshotId).pipe(switchMap(url =>
			this.httpClient.get<ODataCollectionResult<FinancialSummary>>(`${url}/FinancialSummaries/Statewide`).pipe(
				map((result) => result.value)
			)
		));
	}

	// mcag
	@ShareInProgress
	getFinancialSummariesForMcag(mcag: string, snapshotId?: SnapshotId): Observable<Array<FinancialSummary>> {
		return this.resolveSnapshot(snapshotId).pipe(switchMap(safeSnapshotId =>
				this.getLocalGovernment(mcag, safeSnapshotId).pipe(
					switchMap(localGovernment => {
						const queryString = this.getQueryStringFromProperties({
							mcag:     mcag,
							revLevel: 'BasicAccount',
							expLevel: this.governmentService.isSpecialPurposeDistrict(localGovernment.govTypeCode)
								? 'ExpenditureObject'
								: 'BasicAccount'
						});
						const url         = this.getAnnualFilingRoute(safeSnapshotId, `/FinancialSummaries/ForMcag(${queryString})`);
						return this.httpClient.get<ODataCollectionResult<FinancialSummary>>(url).pipe(
							map((result) => result.value)
						);
					})
				)
			)
		);
	}

	// govType
	@ShareInProgress
	getFinancialSummariesForGovType(govTypeCode: string, snapshotId?: SnapshotId): Observable<Array<FinancialSummary>> {
		return this.getSnapshotsUrl(snapshotId).pipe(switchMap(url => {
			const queryString = this.getQueryStringFromProperties({
				govTypeCode: govTypeCode,
				revLevel:    'BasicAccount',
				expLevel:    this.governmentService.isSpecialPurposeDistrict(govTypeCode)
					? 'ExpenditureObject'
					: 'BasicAccount'
			});
			url               = `${url}/FinancialSummaries/ForGovType(${queryString})`;
			return this.httpClient.get<ODataCollectionResult<FinancialSummary>>(url).pipe(
				map((result) => result.value)
			);
		}));
	}

	// schedule 9
	@ShareInProgress
	getSchedule9ForMcag(mcag: string, snapshotId?: SnapshotId): Observable<Array<Schedule9>> {
		return this.resolveSnapshot(snapshotId).pipe(switchMap(safeSnapshotId => {
				// const filterString = this.resolveQueryStringParameter('?$filter', `mcag eq '${mcag}' and year eq ${year}`);
				const filterString = this.resolveQueryStringParameter('?$filter', `mcag eq '${mcag}'`);
				const url          = this.getAnnualFilingRoute(safeSnapshotId, `/Schedule9s${filterString}`);
				return this.httpClient.get<ODataCollectionResult<Schedule9>>(url).pipe(
					map((result) => result.value)
				);
			})
		);
	}

	@ShareInProgress
	getIndicatorReports(snapshotId?: SnapshotId, oDataExpr?: string): Observable<Array<IndicatorReport>> {
		return this.getSnapshotsUrl(snapshotId).pipe(switchMap(url => {
			url = `${url}/IndicatorReports`;
			url = oDataExpr != null ? `${url}?${oDataExpr}` : url;
			return this.httpClient.get<ODataCollectionResult<IndicatorReport>>(url).pipe(
				map((result) => result.value)
			);
		}));
	}

	@ShareInProgress
	getIndicatorReportGroups(
		govTypeCode: string,
		year?: number,
		snapshotId?: SnapshotId,
		oDataExpr?: string
	): Observable<Array<IndicatorGroup>> {
		return this.getAnnualFilingSnapshot(snapshotId).pipe(switchMap(snapshot => {
			return this.getSnapshotsUrl(snapshotId).pipe(switchMap(url => {
				year = year ?? snapshot.barsYearUsed;
				url = `${url}/IndicatorReports/GetByIndicatorGroup(govTypeCode='${govTypeCode}',year=${year})`;
				url = oDataExpr != null ? `${url}?${oDataExpr}` : url;
				return this.httpClient.get<ODataCollectionResult<IndicatorGroup>>(url).pipe(
					map((result) => result.value)
				);
			}));
		}));
	}

	// MSL No idea how to type this so that the keys you passed in is the definition for each object in the array?
	// TODO this would be great, if it *can* be typed, to have a generic function that lets you pass an OData model and keys
	// and get back the result with typing
	@ShareInProgress
	getIndicatorReportGroupBy(grouping: Array<keyof IndicatorReport>, snapshotId?: SnapshotId): Observable<Array<any>> {
		return this.getSnapshotsUrl(snapshotId).pipe(switchMap(url => {
			const groupingString = grouping.join(',');
			url = `${url}/IndicatorReports?$apply=groupby((${groupingString}))`;
			return this.httpClient.get<ODataResult<any>>(url);
		}));
	}

	/**
	 * Return booleans to indicate whether Operating Results Analysis reports are available.
	 * @param mcag
	 * @param displayYear
	 * @param snapshotId
	 */
	@ShareInProgress
	getAvailableOperatingResults(mcag: string, displayYear?: number, snapshotId?: SnapshotId)
	: Observable<AvailableOperatingResultsAnalyses> {
		return this.getSnapshotsUrl(snapshotId).pipe(switchMap(url => {
			const yearFilter = displayYear ? ` and year le ${displayYear}` : '';
			url = `${url}/OperatingResults?$filter=mcag eq '${mcag}'${yearFilter}&$apply=groupby((mcag,year,fundCategoryId,fundTypeId))`;
			return this.httpClient.get<Array<FundCategoryWithMcagAndYear>>(url).pipe(map(result => {
				return {
					governmental: result.findIndex(x => x.fundCategoryId === 1) > -1,
					enterprise: result.findIndex(x => x.fundCategoryId === 2 && x.fundTypeId === 4) > -1
				};
			}));
		}));
	}

	/**
	 * Map an object's keys to values in querystring format, omitting if no value provided.
	 * @param paramsObj
	 */
	private getQueryStringFromProperties = (paramsObj: any): string => {
		const paramsWithValues = [];
		Object.keys(paramsObj).forEach(prop => {
			const value = paramsObj[prop];
			let template: string;
			if (value != null && typeof value !== 'object') {
				switch (typeof value) {
					case 'string':
						template = `${prop}='${value}'`; // quote strings
						break;
					case 'number':
						template = `${prop}=${value}`;
				}
				paramsWithValues.push(template);
			}
		});
		return paramsWithValues.join(',');
	};

	private resolveQueryStringParameter = (name: string, value: any): string =>
		value ? `${name}=${value}` : '';

	/**
	 * Gets the object with the greatest value of `property`.
	 * @param array
	 * @param property
	 */
	getMaxByProperty = (array: Array<any>, property = 'id') =>
		array.reduce((max, obj) => max[property] > obj[property] ? max : obj)

	/**
	 * Helper for getting the current snapshot api route
	 *
	 * @param id
	 * @param params
	 */
	getAnnualFilingRoute(id?: string | number, params?: string): string {
		let url = this.apiUrl;

		if (!id) {
			url += `/Snapshots`;
		}
		else if (id.toString().toLowerCase() === 'live') {
			url += `/Live`;
		}
		else {
			url += `/Snapshots(${id})`;
		}

		if (params) {
			url += params;
		}

		return url;
	}

	getLiveUnauthenticatedRoute(params?: string): string {
		let url = this.apiUrl;

		if (params) {
			url += params;
		}

		return url;
	}

	handleUnauthorized(error: Error): Observable<null> {
		// user not logged in gets a HTTP 401 status on the live endpoint
		if (error instanceof HttpErrorResponse && error.status === 401) {
			return of(null);
		}
	}

	// Schools

	getOSPIDataset = (includeDetail = false): Observable<OSPIDataset> => {
		const url = this.schoolsUrl + (includeDetail ? '?$expand=Detail' : '');
		return this.httpClient.get<ODataResult<OSPIDataset>>(url);
	}

	/**
	 * Helper for getting the current schools api route
	 *
	 * @param id
	 * @param params
	 */
	getOSPIRoute(params?: string): string {
		let url = this.schoolsUrl;

		if (params) {
			url += params;
		}

		return url;
	}

	/**
	 * DisplayYear is the latest in includedYears
	 */
	getOSPIDisplayYear(): Observable<number> {
		return this.getOSPIDataset(true).pipe(map(result =>
			Math.max(...result.detail.includedYears)
		));
	}

	/**
	 * Get all financial reports for schools in one result, except long term liabilities.
	 * @param filter OData filter
	 */
	@ShareInProgress
	getOSPIFinancialReports(filter: string): Observable<Array<FinancialReportRow>> {
		const url = `${this.schoolsUrl}/FinancialReports`;
		return this.httpClient.get<ODataCollectionResult<FinancialReportRow>>(`${url}?$filter=${filter}`)
			.pipe(map(result => result.value));
	}

	@ShareInProgress
	getOSPILongTermLiabilities(filter: string): Observable<Array<LongTermLiabilityRow>> {
		const url = `${this.schoolsUrl}/LongTermLiabilities`;
		return this.httpClient.get<ODataCollectionResult<LongTermLiabilityRow>>(`${url}?$filter=${filter}`)
			.pipe(map(result => result.value));
	}

	@ShareInProgress
	getOSPIEnrollmentAverages(filter: string): Observable<Enrollment> {
		const url = `${this.schoolsUrl}/Enrollments`;
		return this.httpClient.get<ODataCollectionResult<Enrollment>>(`${url}?$filter=${filter}`)
			.pipe(map(result => result?.value[0]), catchError(x => of(null)));
	}

	@ShareInProgress
	getOSPIEnrollmentAveragesTotalOfAllSchools(schoolYear: number): Observable<StatewideEnrollment> {
		const url = `${this.schoolsUrl}/Enrollments`;
		return this.httpClient.get<ODataResult<StatewideEnrollment>>(`${url}?$apply=filter(govTypeCode eq '03' and FY eq ${schoolYear})/aggregate(AveragesForAllGradesHC with sum as statewideAverageHeadcount, schoolYear with max as schoolYear)`);
	}

	// end Schools

	// Admin
	@ShareInProgress
	getTime(): Observable<string> {
		return this.httpClient.get<ODataResult<string>>(`${this.serviceUrl}/getTime`);
	}

	publishNewSnapshot = (data: PublishSnapshotData): Observable<any> => {
		const url = `${this.adminUrl}/PublishNewSnapshot`;
		return this.httpClient.post<any>(url, {
			'PublishBARSYear': data.publishBARSYear,
			'MilestoneCode':   data.milestoneCode,
			'MilestoneName':   data.milestoneName,
			'Description':     data.description,
			'Comment':         data.comment,
			'Type':            data.type,
			'Baseline':        data.baseline,
			'TotalRevenues':   data.totalRevenues,
			'FilersWithData':  data.filersWithData
		});
	};



}
