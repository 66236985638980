<div class="drawer">
	<div class="handle" (click)="close()">
		<fa-icon class="close" [icon]="icons.close"></fa-icon>
		<span>{{ handleTitle }}</span>
	</div>

	<dx-popup
		class="confirmation-dialog bonanza-popup"
		width="400"
		height="auto"
		[title]="this.confirmationDialog?.title || 'Abandon Changes?'"
		[showCloseButton]=false
		[visible]="isConfirmationDialogVisible"
	>
		<div *dxTemplate="let data of 'content'">
			<div class="app-popup__wrapper">
				<div class="app-popup__inner">
					<p class="u-margin-bottom-none" *ngIf="this.confirmationDialog.confirmation">
						{{ this.confirmationDialog.confirmation }}
					</p>

					<p class="u-margin-bottom-none" *ngIf="!this.confirmationDialog.confirmation">
						Closing the {{ handleTitle }} will discard the changes you have currently made.
						Do you wish to continue?
					</p>
				</div>

				<div class="app-popup__footer">
					<dx-button
						type="default"
						text="OK"
						(onClick)="onConfirmationDialogChange($event, true)"
					></dx-button>

					<dx-button
						class="u-margin-left-small"
						text="Cancel"
						(onClick)="onConfirmationDialogChange($event, false)"
					></dx-button>
				</div>
			</div>
		</div>
	</dx-popup>

	<ng-content></ng-content>
</div>
