export class Resource {
	id: number;
	title: string;
	sortOrder: number;
	type: string;
	embed?: string;
	description?: string;
	link?: string;
	linkText?: string;
	image?: string;
}
