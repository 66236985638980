<dx-tree-view
	class="account-chooser__tree-view"
	[items]="store"
	dataStructure="plain"
	parentIdExpr="parentId"
	keyExpr="id"
	selectedExpr="selected"
	height="auto"
	hasItemsExpr="hasChildren"
	showCheckBoxesMode="normal"
	(onItemSelectionChanged)="evaluateSelectionChanged($event)"
>
	<div *dxTemplate="let item of 'item'">
		<span class="account-chooser__account-item__name">{{ item.name }}</span>
		<span class="account-chooser__account-item__code" *ngIf="showAccountCodes"> ({{ item.categoryDisplay || 'no account code' }})</span>
	</div>
</dx-tree-view>
