import { Injectable } from '@angular/core';
import { CellService } from './cell.service';
import { LoggerService } from './logger.service';
import { PivotGridComponent } from 'app/components/pivot-grid/pivot-grid.component';
import { TransitionService } from './transition.service';
import { TransitionEvent } from 'app/shared/models/transition-event';
import { SnapshotService } from './snapshot.service';
import {Field} from '../models/field';
import {TabService} from './tab.service';
import {FitActionsService} from './fit-actions.service';
import {ReportId} from '../models/report-id';
const noop = (): any => undefined;

@Injectable({
	providedIn: 'root'
})
export class MenuService {

	constructor(
		private cellService: CellService,
		private logger: LoggerService,
		private transitionService: TransitionService,
		private snapshotService: SnapshotService,
		private tabService: TabService,
		private fitActions: FitActionsService
	) { }

	// Generates the context menu related to the data present in cell click event
	// event: https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxPivotGrid/Configuration/#onContextMenuPreparing
	generate(event, ctrl: PivotGridComponent) {
		// Always remove export
		const exportIndex = event.items.findIndex(v => v.text === 'Export to Excel file');
		if (exportIndex > -1) {
			event.items.splice(exportIndex, 1);
		}

		// Data fields use a different lookup strategy
		if (this.cellService.isArea(event, 'data')) {
			this.attachDataContext(event, ctrl);
		// Lookup function to call to generate actions based on field clicked, excluding grand totals which have no
		// associated field.
		} else if (event.cell.type !== 'GT') {
			const field = this.cellService.getFieldFromHeaderClick(event);
			// Could not locate field, skip remaining processing
			if (field === null) {
				this.logger.warn('MenuService.generate: Field was not found in the event:', event);
				return;
			}

			// Get the field's associated function and call with parameters
			this.getFunctionForField(field)(event, ctrl);
		// grand total logic
		} else {
			this.attachGrandTotalContext(event, ctrl);
		}

		if (!event.items.length) {
			event.items.push({ text: 'no options available', disabled: true });
		}
	}

	/**
	 * Retrieves the associated function that attaches menu items to a given context based on field.name
	 * E.g. field.name === 'governmentType' => attachGovernmentTypeContext()
	 * @param field
	 */
	getFunctionForField(field: Field) {
		let context = null;

		// Use the same function for all bars accounts
		if (this.cellService.isBARSAccountField(field.name)) {
			context = 'Account';
		} else {
			// default strategy uses the capitalized field name as the context
			context = field.name.charAt(0).toUpperCase() + field.name.slice(1);
		}

		const func = this['attach' + context + 'Context'];
		if (!func) {
			this.logger.warn('MenuService.getFunctionForField: No related function for context ' + context + '.');
			// Return empty function to avoid runtime errors
			return noop;
		}

		return func;
	}

	cellHasContextMenu(cell) {
		// https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxPivotGrid/Pivot_Grid_Cell/
		const isDataCellWithValue = 'rowType' in cell && cell.rowType !== 'GT'
			&& 'columnType' in cell && cell.columnType !== 'GT'
			&& cell.value != null;

		const isDataRowOrColumn = 'type' in cell && cell.type !== 'GT';

		return isDataRowOrColumn || isDataCellWithValue;
	}

	/**
	 * Indicates that the clicked cell supports comparing this value to similar governments.
	 * @param event
	 * @param ctrl
	 */
	supportsComparison(event, ctrl: PivotGridComponent): boolean {
		// We do not support peer comparisons in live mode
		if (ctrl.tab.snapshotId === 'live') {
			return false;
		}

		// The peer comparison dialog is hardcoded to schedule1 and cannot support other reports until refactored
		if (ctrl.tab.report.id === ReportId.debtAndLiabilities) {
			return false;
		}

		const doesCellHaveContextMenu = this.cellHasContextMenu(event.cell);
		const isSingleGovernment = ctrl.tab.governments && ctrl.tab.governments.length === 1;
		const hasGovernmentFieldInGrid = event.columnFields
			.concat(event.rowFields).findIndex(function (v) {
				return v.name === 'government';
			}) > -1;

		const comprisingCellValues = this.cellService.getComprisingCellValues(event);

		// Do not allow for FSSectionId
		const hasBARSAccountInGrid = comprisingCellValues
			.findIndex(v => v.field.name.toLowerCase().indexOf('account') > -1) > -1;

		const hasSummaryInGrid = comprisingCellValues
			.findIndex(v => v.field.name.toLowerCase().indexOf('summary') > -1) > -1;

		const hasDebtInGrid = comprisingCellValues
			.findIndex(value => value.field.name.toLowerCase().indexOf('debt') > -1) > -1;

		const hasFundNumberInGrid = comprisingCellValues
			.findIndex(v => v.field.name.toLowerCase() === 'fund') > -1;

		const hasExpenditureObjectInGrid = comprisingCellValues
			.findIndex(v => v.field.name.toLowerCase() === 'expenditureobject') > -1;

		const isSingleYear = ctrl.tab.years && ctrl.tab.years[0] && !ctrl.tab.years[1];
		// year can only be found in column
		const hasYearFieldInGrid = event.columnFields.findIndex(v => v.name === 'year') > -1;

		const isPerCapitaEnabled = ctrl.tab.pivotGridSettings.unit === 'capitaPerDollar';

		return doesCellHaveContextMenu
			&& (isSingleGovernment || hasGovernmentFieldInGrid)
			&& (isSingleYear || hasYearFieldInGrid)
			&& (hasSummaryInGrid || hasBARSAccountInGrid || hasExpenditureObjectInGrid || hasDebtInGrid)
			&& !isPerCapitaEnabled
			&& !hasFundNumberInGrid;
	}

	attachGrandTotalContext = (contextMenuEvent, ctrl: PivotGridComponent) => {
		this.logger.log(contextMenuEvent);
		// enterpriseFundIndicators do not have a grand total, but seems logic to specify by track, anyway
		if (ctrl.tab.track.id === 'e') {
			// remove all sorting capability from grand totals
			contextMenuEvent.items = contextMenuEvent.items.filter(x => !x.text.includes('Sort'));
		}
	};

	attachDataContext = (contextMenuEvent, ctrl: PivotGridComponent) => {

		if (this.supportsComparison(contextMenuEvent, ctrl)) {
			contextMenuEvent.items.push({
				text: 'Show comparison to similar governments',
				beginGroup: true,
				onClick: (clickEvent) => {
					// flows down to comparison dialog
					ctrl.currentContextMenuEvent = contextMenuEvent;
					ctrl.isComparisonDialogVisible = true;
				}
			});
		}

		// Navigate to Indicator
		if (this.supportsIndicator(contextMenuEvent, ctrl)) {
			contextMenuEvent.items.push({
				text: 'Go to indicator view for this measure',
				beginGroup: true,
				onClick: (clickEvent) => {
					const ds = contextMenuEvent.component.instance().getDataSource();
					const dd = ds.createDrillDownDataSource(contextMenuEvent.cell);
					this.logger.log(`indicator view for measure`, dd);
					dd.load().then(result => {
						this.logger.log(`indicator view for measure records`, result);
						// should only ever be one result because we do not attach this context if the indicator is summarized
						const record = result[0];
						this.fitActions.navigateToIndicator(ctrl.tab)(record.mcag, record.instanceCode, ctrl.tab.years);
					});
				}
			});
		}
	};

	private supportsIndicator = (contextMenuEvent, ctrl: PivotGridComponent): boolean => {
		// TODO implement
		// require indicator
		const comprisingCellValues = this.cellService.getComprisingCellValues(contextMenuEvent);
		const hasIndicatorInGrid = comprisingCellValues
			.findIndex(v => v.field.name.toLowerCase() === 'indicator') > -1
			&& contextMenuEvent.cell.value !== undefined; // value will be undefined if no underlying indicator

		// Make sure this is not a summarized (total or grand total) row or column
		const dimensionTypes = [contextMenuEvent.cell.rowType, contextMenuEvent.cell.columnType];
		const summaryTypes = ['GT', 'T'];
		const isSummarized = dimensionTypes.filter(x => summaryTypes.includes(x))?.length > 0;

		return hasIndicatorInGrid && !isSummarized;
	};

	/**
	 * Custom actions to add to the Government field.
	 * @param contextMenuEvent https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxPivotGrid/Configuration/#onContextMenuPreparing
	 * @param ctrl
	 */
	attachGovernmentContext = (contextMenuEvent, ctrl: PivotGridComponent) => {
		contextMenuEvent.items.push({
			text: 'Go to government profile',
			beginGroup: true,
			onClick: (clickEvent) => {
				const mcag = contextMenuEvent.cell.path.slice(-1).pop();
				this.fitActions.navigateToGovProfile(ctrl.tab)(mcag, ctrl.tab.snapshotId);
			}
		});
	};

	/**
	 * Custom actions to add to the Government Type field.
	 * @param contextMenuEvent https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxPivotGrid/Configuration/#onContextMenuPreparing
	 * @param ctrl
	 */
	attachGovernmentTypeContext = (contextMenuEvent, ctrl: PivotGridComponent) => {
		contextMenuEvent.items.push({
			text: 'Go to government type profile',
			beginGroup: true,
			onClick: (clickEvent) => {
				const govTypeCode = contextMenuEvent.cell.path.slice(-1).pop();
				const governmentType = this.snapshotService.findObject(ctrl.tab.snapshotId, 'governmentTypes', govTypeCode, 'code');
				this.fitActions.navigateToGovTypeProfile(ctrl.tab)(governmentType.code, governmentType.description);
			}
		});
	};

	// Attaches BARS related functions.
	attachAccountContext = (event, ctrl: PivotGridComponent) => {
		// does not apply to these tracks
		if (['a', 'b1', 'b2', 'd'].includes(ctrl.tab.track.id)) {
			return;
		}

		event.items.push({
			text: 'Show summary analysis of this government category',
			beginGroup: true,
			onClick: clickEvent => {
				this.logger.log('MenuService.attachAccountContext', event);

				// TODO remove once FSSection is merged into BARS Accounts
				const field = this.cellService.getFieldFromHeaderClick(event);
				let category = null;
				const id = event.cell.path.slice(-1).pop();
				if (field.name === 'standardSummary' || field.name === 'debtCapitalExpSummary') {
					// category = snapshot.detail.FinancialSummarySections.find(f => f.id === id);
					category = this.snapshotService.findObject(ctrl.tab.snapshotId, 'financialSummarySections', id);
					category.isSection = true;
				} else {
					// category = snapshot.detail.accountDescriptors.find(a => a.id === id);
					category = this.snapshotService.findObject(ctrl.tab.snapshotId, 'accountDescriptors', id);
				}


				const transitionEvent = new TransitionEvent('Show summary analysis of this government category', category);
				this.transitionService.executeTransition(transitionEvent, ctrl.pivotGridData, ctrl.tab).then(function (result) {
					if (result === 'success') {
						ctrl.updated.emit(); // Update tab summary titles, govt info, etc.
					}
				});

			}
		});
	};

}
