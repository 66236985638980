<div class="u-padding">
	<app-tab-bar
		[items]="indicatorTabs"
		[selectedId]="indicatorSelectedTab"
		(onSelect)="indicatorSelectedTab = $event"
	></app-tab-bar>

	<div class="indicator-header">
		<h1 class="indicator-header__title">
			<button (click)="goToProfile(government.mcag)">{{ government?.entityNameWithDba }}</button>
		</h1>
		<div class="indicator-header__name">
			{{ tab.indicator.title }}
			<wasao-hint icon="infoCircle">
				{{ tab.indicator.info.meaning }}
			</wasao-hint>
		</div>
		<div class="indicator-header__sub-title">
			<span class="indicator-header__fund">{{ tab.indicator.subTitle }},</span>
			{{ tab.years.length ? tab.years[0] : '' }}-{{ tab.years.length ? tab.years[1] : '' }}
		</div>
		<div class="indicator-header__row u-h6 u-margin-bottom">
			<div class="indicator-header__outlook">
				<strong>Outlook:</strong>
				<span
					class="indicator-header__outlook-status"
					[class.u-color-status-okay]="tab.indicator.outlookInfo.outlook === 'Good'"
					[class.u-color-status-cautionary]="tab.indicator.outlookInfo.outlook === 'Cautionary'"
					[class.u-color-status-concerning]="tab.indicator.outlookInfo.outlook === 'Concerning'"
				>
					{{ tab.indicator.outlookInfo.outlook }}
					<wasao-hint icon="infoCircle">{{ tab.indicator.outlookInfo.annotation }}</wasao-hint>
				</span>
			</div>
			<div class="u-text-align-right">
				Benchmark:
				<strong
					*ngIf="measure.benchmark">{{ measure.benchmark.comparatorText | lowercase }} {{ formatPointLabel(measure.benchmark.comparedTo, measure.unit) }}</strong>
				<strong *ngIf="!measure.benchmark">N/A
					<wasao-hint icon="infoCircle">There is no generally accepted benchmark for this indicator in this
						case.
					</wasao-hint>
				</strong>
				<div><button
					(click)="exportIndicator()"
					title="Download"
				>
					<fa-icon
						size="2x"
						[icon]="icons.download"
					></fa-icon>
				</button>
				</div>
			</div>
		</div>
	</div>

	<ng-container *ngIf="indicatorSelectedTab === indicatorTabIds.Indicator">
		<dx-chart
			[dataSource]="chartStore"
			[tooltip]="chartTooltip"
			(onLegendClick)="handleLegendClick($event)"
		>
			<dxo-legend
				horizontalAlignment="left"
				[rowItemSpacing]="20"
			>
				<dxo-title text="LEGEND" horizontalAlignment="center">
					<dxo-subtitle text="Click to Toggle">
						<dxo-font
							size="10"
							opacity="0.8"
						></dxo-font>
					</dxo-subtitle>
				</dxo-title>
			</dxo-legend>
			<dxo-common-series-settings argumentField="year"></dxo-common-series-settings>
			<dxo-argument-axis type="discrete"></dxo-argument-axis>
			<dxi-value-axis
				*ngIf="hasNet"
				name="net"
				position="right"
			>
				<dxo-label [customizeText]="getNetAxisLabel"></dxo-label>
			</dxi-value-axis>
			<dxi-value-axis
				name="measure"
			>
				<dxo-label [customizeText]="getMeasureAxisLabel"></dxo-label>
			</dxi-value-axis>
			<dxi-series
				*ngIf="hasNet"
				valueField="net"
				[name]="net.name"
				type="bar"
				axis="net"
				color="#55AEE2"
			>
			</dxi-series>
			<dxi-series
				valueField="missingMeasure"
				name="Missing Measure"
				type="line"
				color="#014164"
				dashStyle="dash"
				axis="measure"
				[hoverStyle]="{dashStyle: 'dash', width: 2}"
				[showInLegend]="false"
			>
				<dxo-point visible="false"></dxo-point>
			</dxi-series>
			<dxi-series
				valueField="measure"
				[name]="measure.name"
				type="line"
				axis="measure"
				color="#014164"
			></dxi-series>
			<dxi-series
				*ngIf="measure.benchmark"
				valueField="benchmark"
				name="Benchmark"
				type="line"
				dashStyle="dash"
				axis="measure"
				color="#C00"
			>
				<dxo-point visible="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="!isOSPIDataset"
				valueField="mean"
				[name]="labels.mean"
				type="line"
				axis="measure"
				[visible]="false"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="!isOSPIDataset"
				valueField="median"
				[name]="labels.median"
				type="line"
				axis="measure"
				[visible]="false"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="!isOSPIDataset"
				valueField="trimean"
				[name]="labels.trimean"
				type="line"
				axis="measure"
				[visible]="false"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			// TODO - Need to iterate on the MCAGs here and add new series (one for each comparison government
			// See comparisonMeasures[n].measure
			<dxi-series
				*ngIf="indicatorReportType.key === 'governmentalIndicators' && getComparisonIndicatorReportMcags().length > 1"
				valueField="comparisonMean"
				name="Comparison Governments’ Mean/Average"
				type="line"
				axis="measure"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="indicatorReportType.key === 'governmentalIndicators' && getComparisonIndicatorReportMcags().length > 1"
				valueField="comparisonMedian"
				name="Comparison Governments’ Median"
				type="line"
				axis="measure"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="peerName1"
				valueField="peerMeasure1"
				[name]="peerName1"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="peerName2"
				valueField="peerMeasure2"
				[name]="peerName2"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="peerName3"
				valueField="peerMeasure3"
				[name]="peerName3"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="peerName4"
				valueField="peerMeasure4"
				[name]="peerName4"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
			<dxi-series
				*ngIf="peerName5"
				valueField="peerMeasure5"
				[name]="peerName5"
			>
				<dxo-point [visible]="false"></dxo-point>
			</dxi-series>
		</dx-chart>
		<br/>

		<div class="indicator-footnote" *ngIf="comparisonFootnote">
			<p><em>{{ comparisonFootnote }}</em></p>
		</div>
		<div *ngIf="!isOSPIDataset" class="footnote">
			*Trimean - a weighted average of the distribution's median and its two quartiles.<br>
			<sub>See
				<a href="https://en.wikipedia.org/w/index.php?title=Trimean&oldid=1029934022">
					<i>Trimean</i> in Wikipedia, The Free Encyclopedia
				</a>
				for more information.
			</sub>
		</div>

		<div *ngTemplateOutlet="indicatorReportButton"></div>
	</ng-container>

	<div *ngIf="indicatorSelectedTab === indicatorTabIds.Data">
		<dx-tree-list
			[dataSource]="treeListStore"
			[wordWrapEnabled]="true"
			[allowColumnResizing]="true"
			keyExpr="key"
			parentIdExpr="parent"
			class="u-margin-bottom"
		>
			<dxo-sorting mode="none"></dxo-sorting>
			<dxi-column
				dataField="label"
				cellTemplate="labelCellTemplate"
				caption=""
			></dxi-column>
			<dxi-column
				*ngFor="let year of years"
				[dataField]="year"
				[customizeText]="formatDataCell"
				cellTemplate="cellTemplate"
			></dxi-column>
			<div *dxTemplate="let cell of 'labelCellTemplate'">
				<div [ngSwitch]="cell.data.type">
					<div *ngSwitchCase="rowTypes.Calculation">
					<div class="calcEql">
						<fa-icon
							class="u-color-neutral-base u-margin-right-tiny"
							[icon]="icons.equals"
						></fa-icon>
						<strong>{{ cell.text }}</strong>
					</div>
				</div>
				<div class="indicator-table-meanMedian" *ngSwitchCase="rowTypes.MeanMedian">
					<em>{{ cell.text }}</em>
				</div>
				<div class="indicator-table-comparisonGovt" *ngSwitchCase="rowTypes.ComparisonGovt">
					<em>{{ cell.text }}</em>
				</div>
				<div *ngSwitchCase="rowTypes.SubCalculation">
					<div *ngIf="fruAccounts[cell.data.key] && !isOSPIDataset; then withTooltip else withoutTooltip"></div>
					<ng-template #withTooltip>
						<div [id]="cell.data.key">
							<fa-icon
								*ngIf="getOperationIcon(cell.data.key)"
								class="u-color-neutral-light u-margin-right-tiny"
								[icon]="getOperationIcon(cell.data.key)"
							></fa-icon>
							<a *ngIf="fruAccounts[cell.data.key].drilldownReport"
							   (click)="navigateToScheduleBrowser(cell)">{{ cell.text }}</a>
							<span *ngIf="!fruAccounts[cell.data.key].drilldownReport">{{ cell.text }}</span>
						</div>
						<dx-tooltip
							[target]="getTargetId(cell.data.key)"
							showEvent="dxhoverstart"
							hideEvent="dxhoverend"
							position="top"
						>
							{{ fruAccounts[cell.data.key].text }}
						</dx-tooltip>
					</ng-template>
					<ng-template #withoutTooltip>
						<div class="calcPlus">
							<fa-icon
								*ngIf="getOperationIcon(cell.data.key)"
								class="u-color-neutral-light u-margin-right-tiny"
								[icon]="getOperationIcon(cell.data.key)"
							></fa-icon>
							{{ cell.text }}
						</div>
					</ng-template>
				</div>
				<div *ngSwitchCase="rowTypes.FinancialRollupUnits">
					<div [id]="cell.data.key">
						<fa-icon
							*ngIf="getOperationIcon(cell.data.key)"
							class="u-color-neutral-light u-margin-right-tiny"
							[icon]="getOperationIcon(cell.data.key)"
						></fa-icon>
						<a *ngIf="fruAccounts[cell.data.key].drilldownReport"
						   (click)="navigateToScheduleBrowser(cell)">{{ cell.text }}</a>
						<span *ngIf="!fruAccounts[cell.data.key].drilldownReport">{{ cell.text }}</span>
					</div>
					<dx-tooltip
						*ngIf="!isOSPIDataset"
						[target]="getTargetId(cell.data.key)"
						showEvent="dxhoverstart"
						hideEvent="dxhoverend"
						position="top"
					>
						{{ fruAccounts[cell.data.key].text }}
					</dx-tooltip>
				</div>
				<div *ngSwitchDefault>
					{{ cell.text }}
				</div>
			</div>
	</div>
	<div *dxTemplate="let cell of 'cellTemplate'">
		<div [ngSwitch]="cell.data.type">
			<div *ngSwitchCase="rowTypes.Calculation">
				<strong>{{ cell.text }}</strong>
			</div>
			<div class="indicator-table-meanMedian" *ngSwitchCase="rowTypes.MeanMedian">
				<em>{{ cell.text }}</em>
			</div>
			<div class="indicator-table-comparisonGovt" *ngSwitchCase="rowTypes.ComparisonGovt">
				<em>{{ cell.text }}</em>
			</div>
			<div *ngSwitchDefault>
				{{ cell.text }}
			</div>
		</div>
	</div>
	</dx-tree-list>
	<div class="indicator-footnote">
		<p><em>{{ comparisonFootnote }}</em></p>
	</div>
	<hr/>
	<div *ngIf="!isOSPIDataset" class="footnote">
		*Trimean - a weighted average of the distribution's median and its two quartiles.<br>
		<sub>See
			<a href="https://en.wikipedia.org/w/index.php?title=Trimean&oldid=1029934022">
				<i>Trimean</i> in Wikipedia, The Free Encyclopedia
			</a>
			for more information.
		</sub>
	</div>
	<div *ngTemplateOutlet="indicatorReportButton"></div>
	<div class="indicator-about">
		<h2 class="indicator-about__title">About {{ tab.indicator.title }}</h2>
		<div class="indicator-about__content">
			<h3 class="indicator-about__sub-title">Equation</h3>
			<div class="u-margin-bottom">
				<ng-katex [equation]="tab.indicator.info.texEquation" [options]="{displayMode: true}"></ng-katex>
			</div>
			<h3 class="indicator-about__sub-title">Meaning</h3>
			<p>{{ tab.indicator.info.meaning }}</p>
			<h3 class="indicator-about__sub-title">Importance</h3>
			<p>{{ tab.indicator.info.importance }}</p>
		</div>
	</div>
</div>
<wasao-loading [isLoading]="isLoading"></wasao-loading>
</div>

<ng-template #indicatorReportButton >
	<dx-button
		*ngIf="!isOSPIDataset"
		class="indicator-report-button"
		type="default"
		(click)="goToIndicatorReport()"
	>
		<div *dxTemplate="let data of 'content'">
			Show {{ indicatorReportType?.name }} for {{ filingBasis?.name }} {{ govType?.description }} governments
		</div>
	</dx-button>
</ng-template>
