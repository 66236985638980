<div class="checkbox-grid">
	<ul class="checkbox-grid__list">
		<li *ngFor="let item of store">
			<dx-check-box
				[value]="isSelected(item)"
				[text]="displayExpr(item)"
				(onValueChanged)="checkItem(item, $event)"
			></dx-check-box>
		</li>
	</ul>
</div>