import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { FieldService } from 'app/shared/services/field.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { Years } from 'app/shared/models/years';
import { DataSourceService } from 'app/shared/services/data-source.service';
import { TitleCasePipe } from '@angular/common';
import { UserService } from 'app/shared/services/user.service';
import DataSource from 'devextreme/data/data_source';
import { UtilityService } from 'app/shared/services/utility.service';
import {ExploreType} from '../../shared/models/explore-by-option';

@Component({
	selector: 'app-fund-chooser',
	templateUrl: './fund-chooser.component.html',
	styleUrls: ['./fund-chooser.component.scss']
})
export class FundChooserComponent implements OnInit, OnChanges {
	@ViewChild(DxTreeViewComponent, { static: true }) treeView: DxTreeViewComponent;
	@Input() selections: Array<Array<number>>; // pivotGrid or (tab for indicator?)
	@Input() pivotGridData: any;
	@Input() snapshot: any; // for lgfrs reports
	@Input() exploreType: ExploreType; // lgfrs reports only
	@Input() years: Years; // tab
	@Input() mcag: string; // tab
	@Output() changed = new EventEmitter<Array<Array<any>>>();

	store: any;
	showAccountCodes: boolean;
	private isDataSourceStale = true;

	constructor(
		private fieldService: FieldService,
		// private titleCase: TitleCasePipe,
		private dataSource: DataSourceService,
		private logger: LoggerService,
		private userService: UserService,
		private utility: UtilityService
	) {
		this.userService.user.subscribe(user => this.showAccountCodes = user.showTechnical);
		this.logger.log(this);
	}

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		const exploreHasChanged: boolean = changes.exploreType
			&& changes.exploreType.currentValue !== changes.exploreType.previousValue;

		const mcagHasChanged: boolean = changes.mcag
			&& changes.mcag.currentValue !== changes.mcag.previousValue;

		const yearsHasChanged: boolean = changes.years
			&& changes.years.currentValue !== changes.years.previousValue;

		if(exploreHasChanged || mcagHasChanged || yearsHasChanged) {
			this.isDataSourceStale = true;
		}

		if (this.isDataSourceStale) {
			if (this.exploreType === ExploreType.individual
				&& this.mcag
				&& this.years
			) {
				this.logger.log('Making a new data call');
				// if exploreType changes to individual, run GetFiledFunds(mcag, startYear, endYear) to populate store
				this.store = new DataSource({
					store: this.dataSource.getFiledFundsStore(
						this.snapshot.id, this.mcag, this.years
					),
					postProcess: data => this.getStore(this.snapshot.detail.fundCategories, this.snapshot.detail.fundTypes, data),
					onLoadError: error => this.logger.error(error)
				});
			} else {
				this.logger.log('regenerating data source without funds');
				// otherwise build from snapshotDetail
				this.store = new DataSource({
					store: this.getStore(this.snapshot.detail.fundCategories, this.snapshot.detail.fundTypes),
					onLoadError: error => this.logger.error(error)
				});
			}
			this.isDataSourceStale = false;
		}

		if (mcagHasChanged) {
			this.deselectFunds();
		}
	}

	private getStore = (fundCategories: any, fundTypes: any, funds?: any) => {
		// this.logger.log(fundCategories, fundTypes, funds);
		fundCategories.sort((a, b) => a.sortOrder - b.sortOrder);
		fundTypes.sort((a, b) => a.sortOrder - b.sortOrder);
		let result;

		// build a tree from arguments
		// key can be an array (representative of the filter values, e.g. [0, 1, '001'])
		result = fundCategories.map(category => {
			category.key = [category.id];
			category.selected = this.selections?.some(s => this.utility.areArrayValuesEqual(s, category.key)) ?? false;
			category.items = fundTypes.filter(type => type.categoryId === category.id).map(type => {
				type.key = [category.id, type.id];
				type.selected = this.selections?.some(s => this.utility.areArrayValuesEqual(s, type.key)) ?? false;
				type.items = funds && funds.filter(fund => fund.fundTypeId === type.id).map(fund => {
					fund.id = fund.fundNumber;
					fund.key = [category.id, type.id, fund.id];
					fund.selected = this.selections?.some(s => this.utility.areArrayValuesEqual(s, fund.key)) ?? false;
					//fund.name = this.titleCase.transform(fund.fundName);
					fund.name = fund.fundName;
					return fund;
				});
				return type;
			});
			return category;
		});
		this.logger.log('FundChooser finished processing result', result);
		return result;
	}

	private deselectFunds() {
		if(!this.treeView.instance) {
			return;
		}
		// deselect funds unless whole fundtype is selected
		const nodes = this.treeView.instance.getNodes();
		nodes.forEach(category => {
			category.children.forEach(type => {
				if (type.selected === undefined) {
					this.treeView.instance.unselectItem(type.itemData);
				}
			});
		});
	}

	onSelectionChanged(dxEvent) {
		// user interaction indicated by prescense of dxEvent.event
		this.logger.log(dxEvent);
		const selections = [];
		this.selectPath(selections, this.treeView.instance.getNodes());
		const nodes = this.treeView.instance.getNodes();
		if (selections.length > 0) {
			// Always include null as filter value to preserve phantom rows when another filter exists
			selections.push([null]);
		}
		this.logger.log('FundChooser.evaluateSelectionChanged', dxEvent, selections, nodes);
		this.changed.emit(selections);
	}

	// Recurses into node tree to find the selected value
	// Note that when selected === undefined, this is an indeterminate value and indicates that children are selected
	selectPath(selections: Array<Array<number>>, node: any) {
		node.forEach(node => {
			if (node.selected === true) {
				// if this node is selected, push the key
				selections.push(node.itemData.key);
			} else if (node.selected === undefined && node.children) {
				// if this node is undefined (vs false), then recurse into children
				// to find the selected item
				this.selectPath(selections, node.children);
			}
		});
	}

}
