import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FinancialHealthIndicatorStatisticsComponent} from './financial-health-indicator-statistics.component';
import {DxButtonModule, DxChartModule} from 'devextreme-angular';
import {DxoValueAxisModule} from 'devextreme-angular/ui/nested';


@NgModule({
	declarations: [
		FinancialHealthIndicatorStatisticsComponent
	],
	imports: [
		CommonModule,
		DxChartModule,
		DxButtonModule,
		DxoValueAxisModule
	],
	exports: [
		FinancialHealthIndicatorStatisticsComponent
	]
})
export class FinancialHealthIndicatorStatisticsModule {
}
