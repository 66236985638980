import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {TabService} from 'app/shared/services/tab.service';
import {WizardService} from './wizard.service';
import {LoggerService} from 'app/shared/services/logger.service';
import {Tab} from '../../shared/models/tab';
import {Subject} from 'rxjs';
import {DisabledGovernmentType} from '../../shared/components/government-types/disabled-government-type';
import {SnapshotService} from '../../shared/services/snapshot.service';

@Component({
	selector:    'app-wizard',
	templateUrl: './wizard.component.html',
	styleUrls:   ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, OnDestroy {
	@Input() tab: Tab;

	public isLoading: boolean;
	isConfirmationDialogVisible: boolean;
	private destroy             = new Subject<void>();
	disabledGovTypes: Array<DisabledGovernmentType> = [];
	snapshot: any;
	maxLocationsAllowedForTrackD = 6;

	constructor(
		public tabService: TabService,
		public wizardService: WizardService,
		private snapshotService: SnapshotService,
		private logger: LoggerService,
	) {	}

	ngOnInit(): void {

		// Pattern is identical to government-type-chooser - used to populate list of disabled gov types based on canHaveFinancials
		this.snapshotService.getSnapshot().subscribe(snapshot => {
			this.snapshot = snapshot;
			this.isLoading = false;
			// Get the governments that can't have financials to pass to GovernmentTypesComponent with the reason
			// they cannot be selected there.
			this.disabledGovTypes = this.snapshot.detail.governmentTypes.reduce((acc, govType) => {
				if (govType.canHaveFinancials === false || govType.financialsDatasetSource === 'OSPI') {
					acc.push({ code: govType.code, reason: 'No financial data is available for this government type' });
				}
				return acc;
			}, this.disabledGovTypes);
		});
	}

	ngOnDestroy(): void {
		this.destroy.next();
		this.destroy.complete();

	}

	showConfirmationDialog() {
		const hasCriteria = this.wizardService.govTypes.length > 0
			|| this.wizardService.locations.length > 0;
		if (!hasCriteria) {
			this.isConfirmationDialogVisible = true;
		}
		else {
			this.wizardService.trackD();
		}
	}

	onConfirmationDialogChange(dxEvent: any, action: boolean): void {
		// prevent bubble up
		dxEvent.event.preventDefault();
		dxEvent.event.stopPropagation();

		// hide this dialog either way
		this.isConfirmationDialogVisible = false;

		// start track d
		if (action === true) {
			this.wizardService.trackD();
		}
	}

}
