export class ExtractType {
	constructor(
		public id: string,
		public title: string,
		public subtitle: string,
		public description?: string,
		public isSnapshotBased = false,
		// to use on the button name
		public linkSuffix = 'data',
		public isYearsBased = true
	) {
	}
}
