import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'except'
})
export class ExceptPipe implements PipeTransform {

	transform(arr: Array<any>, toRemove: any, prop?: string): any {
		return arr.filter(a => !toRemove.some(r => prop ? a[prop] === r : a === r));
	}

}
