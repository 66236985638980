import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GovernmentTypeInformationComponent} from './government-type-information.component';
import {FilingStatusSummaryModule} from '../../filings/filing-status-summary/filing-status-summary.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SharedComponentsModule} from '../../../shared/components/shared-components.module';
import {MapModule} from '../../ui/map/map.module';
import {LoadingModule} from '../../ui/loading/loading.module';
import {AccountingBasesModule} from '../accounting-bases/accounting-bases.module';
import {AuditKPIsModule} from '../audit-kpis/audit-kpis.module';
import {GovernmentBodyTypeModule} from '../government-body-type/government-body-type.module';
import {GovernmentBodyOriginsModule} from '../government-body-origins/government-body-origins.module';
import {GovernmentTreasurersModule} from '../government-treasurers/government-treasurers.module';

@NgModule({
	declarations: [
		GovernmentTypeInformationComponent
	],
	imports: [
		CommonModule,
		FilingStatusSummaryModule,
		FontAwesomeModule,
		SharedComponentsModule,
		MapModule,
		LoadingModule,
		AccountingBasesModule,
		AuditKPIsModule,
		GovernmentBodyTypeModule,
		GovernmentBodyOriginsModule,
		GovernmentTreasurersModule,
	],
	exports:      [GovernmentTypeInformationComponent]
})
export class GovernmentTypeInformationModule {
}
