import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxPopoverModule } from 'devextreme-angular';
import { HintComponent } from './hint.component';

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		DxPopoverModule
	],
	declarations: [HintComponent],
	exports: [HintComponent]
})
export class HintModule { }
