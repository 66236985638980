import {SnapshotId} from './snapshot-like';

export enum FilingYearCondition {
	Open = 'Open',
	Past = 'Past'
}

export enum FilingCondition {
	// These are used when looking back on a past filing year
	Timely = 'Timely',
	Late = 'Late',
	None = 'None',
	// These are used when looking at a current, open filing year
	Filed = 'Filed',
	Started = 'Started',
	NotStarted = 'NotStarted',
	// This is used to indicate when the government was not active
	NotActive = 'NotActive'
}

export const filingConditionLookup: Array<{text: string, value: keyof typeof FilingCondition}> = [{text: 'Timely Filing', value: FilingCondition.Timely}, {text: 'Late Filing', value: FilingCondition.Late}, {text: 'No Filing', value: FilingCondition.None},
	{text: 'Filed', value: FilingCondition.Filed}, {text: 'Started', value: FilingCondition.Started}, {text: 'Not Started', value: FilingCondition.NotStarted}];

export interface FilingStatus {
	snapshotId: SnapshotId;
	year: number;
	filingYearCondition: FilingYearCondition;
	governmentStatus: string;
	filingBasis: 'None' | 'Cash' | 'GAAP';
	mcag: string;
	govTypeCode: string;
	countyCodes: Array<number> | number;
	maxAllowedSubmitDate: Date;
	dateSubmitted: Date;
	pendingUpdates: boolean;
	noActivity: boolean;
	noDataAvailable: boolean;
	daysLate: number;
	filingCondition: FilingCondition;
}

export class FilingDueDates {
	year: number;
	filingYearCondition: string;
	fiscalYearEnd: string;
	fiscalYearEndDate: string;
	maxAllowedSubmitDate: string;
	todaysDate: string;
	daysRemainingToSubmit: number;
	activeGovernments: number;
}

export class FilingStatusesYearsAndTotals {
	year: number;
	total: number;
}





