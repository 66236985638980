import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AmountsByCategoryComponent} from './amounts-by-category.component';
import {LoadingModule} from '../../ui/loading/loading.module';
import {DxButtonModule, DxPieChartModule, DxSparklineModule} from 'devextreme-angular';
import {HintModule} from '../../ui/hint/hint.module';
import {FundServiceModule} from '../../services/fund-service/fund-service.module';


@NgModule({
	declarations: [AmountsByCategoryComponent],
	imports: [
		CommonModule,
		HintModule,
		LoadingModule,
		DxSparklineModule,
		DxPieChartModule,
		DxButtonModule,
		FundServiceModule
	],
	exports: [AmountsByCategoryComponent]
})
export class AmountsByCategoryModule {
}
