<dx-popup
	[dragEnabled]="true"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	(onShowing)="onShowing()"
	[width]="1180"
	[height]="'90%'"
	(onHidden)="onHidden()"
	title="Interactive Filing Statistics">
	<div class="app-popup__wrapper">
		<div class="app-popup__inner">
				<div>
					<wasao-annual-filing-current-progress [resetFilter]='closePopup.asObservable()' [isStandAlone]="false"></wasao-annual-filing-current-progress>
				</div>
				<div>
					<wasao-annual-filing-status-trend [resetFilters]='closePopup.asObservable()' [isBannerHidden]="true" [isStandAlone]="false"></wasao-annual-filing-status-trend>
				</div>
		</div>

		<div class="app-popup__footer">
			<div class="closeButton">
				<dx-button
					text="Close"
					type="default"
					(onClick)="isVisible = false"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>


