import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {BehaviorSubject} from 'rxjs';
import {PeerSetsService} from '../../shared/services/peer-sets.service';
import {LoggerService} from '../../shared/services/logger.service';

interface ConfirmationDialogOptions {
	title: string;
	confirmation: string;
}

@Component({
	selector:    'app-side-drawer',
	templateUrl: './side-drawer.component.html',
	styleUrls:   ['./side-drawer.component.scss']
})
export class SideDrawerComponent {

	@Input() handleTitle: string;
	@Input() confirmationDialog: ConfirmationDialogOptions;
	@HostBinding('class.visible') isVisible: boolean;
	@HostBinding('style.width.px') styleWidth: number;
	@Output() closed = new EventEmitter<null>();
	@Output() opened = new EventEmitter<null>();
	public visible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isDirty: boolean;
	public isConfirmationDialogVisible: boolean;
	isPeerSetsVisible: boolean;

	public icons = {
		close: faTimes,
	};

	@Input() set width(value: number) {
		this.styleWidth = value;
	}

	constructor(
		private uiService: UserInterfaceService,
		private peerSets: PeerSetsService,
		private logger: LoggerService
	) {
		this.uiService.activeComponentName.subscribe(this.onComponentChange);
		this.visible$.subscribe(visible => this.isVisible = visible);
		this.peerSets.peerSetViewerVisibility.subscribe(x => this.isPeerSetsVisible = x);
	}

	/**
	 * Open the side drawer
	 */
	open = (): void => {
		this.visible$.next(true);
		this.uiService.requestApplicationFocus(this);

		this.opened.emit();
	};

	/**
	 * Close the side drawer
	 *
	 * @param force
	 */
	close = (force: boolean = false): void => {
		// no changes staged, or forcing close; can close immediately
		if (!this.isDirty || force === true) {
			this.isVisible = false;
			this.uiService.releaseApplicationFocus();
		}
		else { // changes are staged, prompt user to continue
			this.isConfirmationDialogVisible = true;
		}

		this.closed.emit();
	};

	/**
	 * Sets visibility (open() or close())
	 */
	setVisibility = (visibility: boolean): void => {
		this.logger.log(`SideDrawer setVisibility is being set`, visibility);
		visibility ? this.open() : this.close();
	};

	/**
	 * Listen for clicks and detect when the user clicks off the drawer
	 *
	 * @param target
	 */
	@HostListener('document:click', ['$event.target'])
	evaluateClick = (target: HTMLElement): void => {
		// this.logger.log(`${this.constructor.name}'s click event handler`, target, `is PeerSetViewer open? ${this.isPeerSetsVisible}`);
		if (this.isVisible === true
			// ignore while ConfirmationDialog or PeerSetViewer is visible
			&& (!this.isConfirmationDialogVisible || !this.isPeerSetsVisible)
			&& target.id === 'overlay' // click on overlay element
		) {
			// this.logger.log(`${this.constructor.name}'s click event handler is closing the drawer`);
			this.close();
		}
	};

	/**
	 * Listen for escape key to close while dialogue is open
	 *
	 * @param event
	 */
	@HostListener('document:keydown', ['$event'])
	closeOnEscape = (event: KeyboardEvent): void => {
		if (this.isVisible
			&& !this.isConfirmationDialogVisible // ignore while ConfirmationDialog is visible
			&& event.key === 'Escape') {
			this.close();
		}
	};

	/**
	 * When the component changes
	 *
	 * @param componentName
	 */
	onComponentChange(componentName: string): void {
		// how in the hell? This context is not set when this function gets run sometimes
		if (!this) {
			return;
		}
		// If another component is requesting focus, close our dialog but do not release overlay
		if (this.constructor.name !== componentName) {
			this.isVisible = false;
		}
	}

	/**
	 * On confirmation dialog change (hides it)
	 *
	 * @param dxEvent
	 * @param action
	 */
	onConfirmationDialogChange(dxEvent: any, action: boolean): void {
		// prevent bubble up
		dxEvent.event.preventDefault();
		dxEvent.event.stopPropagation();
		// hide this dialog either way
		this.isConfirmationDialogVisible = false;
		// force close
		if (action === true) {
			this.close(true);
		}
	}

}
