import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Tab } from 'app/shared/models/tab';
import DataSource from 'devextreme/data/data_source';
import { DataSourceService } from 'app/shared/services/data-source.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { DxAutocompleteComponent } from 'devextreme-angular';
import {DatasetSource} from '../../modules/api/fit-api/models/datasets/dataset-source';

@Component({
	selector: 'app-government-chooser',
	templateUrl: './government-chooser.component.html',
	styleUrls: ['./government-chooser.component.scss']
})
export class GovernmentChooserComponent implements OnInit {
	@ViewChild(DxAutocompleteComponent) autocomplete: DxAutocompleteComponent;
	@ViewChild('placeholder', { static: true }) placeholder: ElementRef;

	@Input() tab: Tab;

	private _selections: any;
	@Input('selections')
	set selections(value: any) {
		let result = null;
		if (Array.isArray(value) && value.length > 0) {
			result = value;
		// marshall into array, when single value. Note: typeof null === 'object'
		} else if (!Array.isArray(value) && value != null) {
			result = [value];
		}
		if (this.dataSource) {
			this.dataSource.filter(this.getFilter(result, this.financialsDatasetSource, this.govTypeFilter));
		}
		this._selections = result;
	}
	get selections(): any {
		return this._selections;
	}

	@Input() dropzoneDisabled = false;
	@Input() maxSelections: number;
	private _govTypeFilter: string;
	@Input('govTypeFilter')
	set govTypeFilter(value: string) {
		if (this.dataSource) {
			this.dataSource.filter(this.getFilter(this.selections, this.financialsDatasetSource, value));
		}
		this._govTypeFilter = value;
	}
	get govTypeFilter(): string {
		return this._govTypeFilter;
	}
	private _financialsDatasetSource: DatasetSource;
	@Input('financialsDatasetSource')
	set financialsDatasetSource(value: DatasetSource) {
		if (this.dataSource) {
			this.setGovernments(null);
			this.selections = null;
			this.govTypeFilter = null;
			this.dataSource.filter(this.getFilter(this.selections, value, this.govTypeFilter));
		}
		this._financialsDatasetSource = value;
	}
	get financialsDatasetSource(): DatasetSource {
		return this._financialsDatasetSource;
	}
	@Input() validationCallback: Function;

	@Output() changed = new EventEmitter<Array<any>>();
	@Output() govDropped = new EventEmitter<any>();
	dataSource: DataSource;

	icons = {
		close: faTimes
	};

	constructor(
		private dataSourceService: DataSourceService,
		private logger: LoggerService,
		private elRef: ElementRef
	) { }

	ngOnInit() {
		this.logger.log(this);
		this.tab.snapshotIdSubject.subscribe(snapshotId => {
			this.dataSource = new DataSource({
				store: this.dataSourceService.getStore(snapshotId, 'LocalGovernments'),
				filter: this.getFilter(this.selections, this.financialsDatasetSource, this.govTypeFilter)
			});
		});
	}

	selectBoxChanged = (dxEvent: any): void => {
		// only handle if user change (presence of value)
		if (dxEvent.itemData !== null) {
			// concat with existing selections
			const result = this.selections || [];
			result.push(dxEvent.itemData);
			this.setGovernments(result);
			// reset for future use
			this.autocomplete.instance.option('value', null);
		}
	}

	/**
	 * Get the filter for the dataSource. This is currently used to filter out current selections.
	 */
	private getFilter = (selections: Array<any>, financialsDatasetSource: DatasetSource, govTypeFilter: string) => {
		const filter = [];
		if (financialsDatasetSource != null) {
			filter.push(['financialsDatasetSource', '=', financialsDatasetSource]);
		}
		if (govTypeFilter !== null) {
			filter.push(['govTypeCode', '=', govTypeFilter]);
		}
		if (Array.isArray(selections) && selections.length > 0) {
			selections.forEach(s => filter.push( ['!', ['mcag', '=', s.mcag]] ));
		}
		return filter;
	}

	setGovernments = (governments: Array<any>): void => {
		const value = governments && governments.length
			? governments
			: null;
		this.changed.emit(value);
	}

	remove = (government: any): void => {
		const difference = this.selections.filter(x => x !== government);
		const value = difference && difference.length
			? difference
			: null;
		this.changed.emit(value);
	}

	onDrop(event) {
		this.govDropped.emit(event);
	}

	onDragOver = (event: DragEvent) => {
		// yikes this has a weird bug!
		// const target = <HTMLElement> event.target;
		// this.placeholder.nativeElement.innerText = target.innerText;
		// hack to find the dndDragging element
		const dragging = <HTMLElement> document.querySelector('.dndDragging');
		this.placeholder.nativeElement.innerText = dragging.innerText;
	}

}
