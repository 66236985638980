import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnnualFilingFooterComponent} from './annual-filing-footer.component';

@NgModule({
	declarations: [
		AnnualFilingFooterComponent
	],
	imports: [
		CommonModule,
	],
	exports: [
		AnnualFilingFooterComponent
	]
})
export class AnnualFilingFooterModule {
}
