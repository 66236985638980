<dx-select-box
	[dataSource]="availableReports"
	valueExpr="id"
	displayExpr="name"
	[value]="selectedReportId"
	(onValueChanged)="changed.emit($event)"
>
	<dx-validator>
		<dxi-validation-rule type="required" message="Please choose a report to view."></dxi-validation-rule>
	</dx-validator>
</dx-select-box>
<wasao-loading [isLoading]="isLoading" roundedBorder="true"></wasao-loading>
