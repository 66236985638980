import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FinancialHealthIndicatorStatisticsService} from './financial-health-indicator-statistics.service';
import {
	FinancialHealthIndicatorStatisticGroup,
	FinancialHealthIndicatorStatistics
} from './financial-health-indicator-statistics';
import {LoggerService} from '../../../shared/services/logger.service';
import DataSource from 'devextreme/data/data_source';
import {lastValueFrom} from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import {map, tap} from 'rxjs/operators';
import {ReusableGovernmentAndTypeInputs} from '../../reusable/models/reusable-government-and-type-inputs';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {FitActionsService} from '../../../shared/services/fit-actions.service';
import {IndicatorReportType} from '../../api/fit-api/models/indicators/indicator-report-type';
import {FilingBasis} from '../../api/fit-api/models/snapshots/filing-basis';

@Component({
	selector: 'wasao-financial-health-indicator-statistics',
	templateUrl: './financial-health-indicator-statistics.component.html',
	styleUrls: ['./financial-health-indicator-statistics.component.scss']
})
export class FinancialHealthIndicatorStatisticsComponent extends ReusableGovernmentAndTypeInputs implements OnChanges {
	@Input() drilldownClickAction: ReturnType<FitActionsService['navigateToIndicatorReport']>;
	@Input() isInteractive = true;

	dataSource: DataSource;
	// change when filtered
	argumentField: 'group' | 'filingBasis' = 'group';
	title = 'Indicator Group Outlooks';
	argumentTitle = 'Group';
	valueTitle = '# of Indicator Groups';
	// keep track of loading since DevExtreme needs to be hooked in later than ngOnChanges to set the initial loading state
	isLoading = false;
	subtitle = '(click to drill down)';
	currentGroup: string;
	// the year that got returned from the service
	currentYearDisplayed: number;

	constructor(
		filingStatus: FilingStatusService,
		fitApi: FitApiService,
		logger: LoggerService,
		private service: FinancialHealthIndicatorStatisticsService
	) {
		super(filingStatus, fitApi, logger);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.callFunctionWithSpecificity(changes, this.updateDataSource);
	}

	updateDataSource = (specificity: GovernmentSpecificity): void => {
		this.dataSource = this.dataSource ?? this.getDataSource(specificity);
		this.dataSource.reload();
	}

	// Build a custom DataSource and bake in necessary side effects
	private getDataSource(specificity: GovernmentSpecificity): DataSource {
		return new DataSource({
			onLoadError: () => this.isLoading = false,
			store: new CustomStore({
				loadMode: 'raw',
				load: (options) => {
					this.isLoading = true;
					return lastValueFrom(
						this.service.getIndicatorStatistics(specificity, this.snapshotId)
							.pipe(
								map(this.removeNoneFilingBasis),
								// set display year based on the result set
								tap(result => this.currentYearDisplayed = result[0]?.year),
								// reset filter when inputs change
								tap(() => this.setFilter(null))
							)
					);
				},
				onLoaded: (result) => {
				// emit true if result comes back with any length
					this.hasData.emit(result?.length > 0);
					this.isLoading = false;
				},
			})
		});
	}

	/**
	 * Removes records with filingBasis=None. This prevents the chart from drawing an empty series (because
	 *  Indeterminates are hidden). Governments with a FilingBasis of None should only contain Indeterminates.
	 * @param records
	 */
	private removeNoneFilingBasis = (records: Array<FinancialHealthIndicatorStatistics>)
		: Array<FinancialHealthIndicatorStatistics> =>
			records.filter(x => x.filingBasis !== 'None');

	// sets the title of the value axis
	private setValueTitle = (group?: string): void => {
		this.dataSource.load().then((result: Array<FinancialHealthIndicatorStatistics>) => {
			// if we're in filingBasis mode, grab the fundGroup and look to see if the number of indicators matches
			//  the number of governments and use that in the title, otherwise use the default: "# of Indicators"
			if (this.argumentField === 'filingBasis') {
				// flatten all indicator groups
				const flat = result.filter(x => x.group === group).flatMap(x => x.indicatorGroups);
				// get a unique list of mcags from the flattened list
				const mcags = new Set(flat.map(x => x.mcag));
				if (flat.length === mcags.size) {
					this.valueTitle = '# of Governments';
				} else {
					this.valueTitle = '# of Indicator Groups';
				}
			} else {
				this.valueTitle = '# of Indicator Groups';
			}
		});
	}

	setFilter(group: FinancialHealthIndicatorStatisticGroup | null): void {
		if (group === null) {
			this.dataSource.filter(null);
			this.title = `Indicator Group Outlooks (FY ${this.currentYearDisplayed})`;
			this.argumentField = 'group';
			this.argumentTitle = 'Fund Group';
			this.currentGroup = null;
			this.setValueTitle();
		} else {
			this.dataSource.filter(['group', '=', group]);
			this.title = `${group} Indicator Group Outlooks by Filing Basis (FY ${this.currentYearDisplayed})`;
			this.argumentField = 'filingBasis';
			this.argumentTitle = 'Filing Basis';
			this.currentGroup = group;
			this.setValueTitle(group);
		}
	}

	onPointClick = (event: any): void => {
		this.logger.debug(event, this.currentGroup);
		if (this.argumentField === 'group') {
			// set the filter on the data source so that the chart is redrawn in FilingBasis mode
			this.setFilter(event.target.originalArgument);
		} else if (this.argumentField === 'filingBasis') {
			// Drill down to the Indicator Report with appropriate filters
			const indicatorReportType: IndicatorReportType = this.currentGroup === 'Enterprise Funds'
				? new IndicatorReportType('enterpriseFundIndicators')
				: new IndicatorReportType('governmentalIndicators');
			this.drilldownClickAction(indicatorReportType, new FilingBasis(event.target.originalArgument), this.govTypeCode, this.currentYearDisplayed);
		} else {
			throw new Error(`Argument not valid: ${this.argumentField}`);
		}
	}

	customizeTooltip = (event: any) => {
		// this.logger.debug(event);
		let text = `${event.value} ${event.seriesName}`;
		if (this.argumentField === 'filingBasis') {
			if (event.argument === 'None') {
				text += '\nThese governments did not file and thus indicators cannot be evaluated.';
			}
			text += '\n(click to view report)';
		} else {
			text += '\n(click to drill down)';
		}

		return {
			text: text
		};
	}

	back = (): void => this.setFilter(null);

	onPointHoverChanged = (event: any): void => {
		if (event.target.isHovered()) {
			event.element.style.cursor = 'pointer';
		} else {
			event.element.style.cursor = 'auto';
		}
	}

}
