<div class="wizard">
	<dx-load-panel [(visible)]="isLoading" [message]="'Loading data...'"></dx-load-panel>
	<!--
		<app-navigation></app-navigation>
	-->
	<div class="panes" [ngSwitch]="wizardService.step.component">
		<!-- TODO insert one component per screen and ngSwitch -->
		<app-track-chooser *ngSwitchCase="'trackChooser'"></app-track-chooser>

		<!-- no longer enabled here -->
		<!--		<div *ngSwitchCase="'b4'">-->
		<!--			<h3>Choose other governments to compare with {{ wizardService.getPrimaryGovernment().entityNameWithDba }}.</h3>-->
		<!--			<app-peer-search-->
		<!--				[prime]="wizardService.getPrimaryGovernment()"-->
		<!--				[metricYear]="wizardService.metricYear"-->
		<!--				(submitted)="wizardService.setGovernments($event)">-->
		<!--			</app-peer-search>-->
		<!--		</div>-->

		<div *ngSwitchCase="'c3'">
			<h3>Choose government type(s) and/or government locations (or leave blank for all governments statewide).</h3>
			<app-government-type-chooser
				govtTypeLabel="Select government types..."
				locationLabel="... in the following locations"
				(submitted)="wizardService.setGovernmentTypes($event)"
				(back)="wizardService.jumpToStep(wizardService.step.id - 1)"
			></app-government-type-chooser>
		</div>

		<div *ngSwitchCase="'d3'">
			<h3>Choose a {{wizardService.userHasGlobalAccess ? 'debt/liability category,' : ''}} government function, revenue source, beginning balances, or ending balances...
			</h3>
			<div class="wizard__d3">
				<app-account-chooser-freeform
					[snapshot]="wizardService.snapshot"
					[showAccountCodes]="wizardService.showAccountCodes"
					class="wizard__d3__account-chooser-freeform"
					(changed)="wizardService.updateCategory($event)"
				></app-account-chooser-freeform>

				<div class="field-buttons">
					<div class="field-buttons__left">
						<dx-button
							type="normal"
							text="Back"
							(click)="wizardService.jumpToStep(wizardService.step.id - 1)"
						></dx-button>
					</div>
					<div class="field-buttons__right">
						<dx-button
							type="default"
							text="View total {{ wizardService?.category?.displayName }} for {{ (wizardService.locations?.length || wizardService.govTypes?.length ) ? 'these government types and locations' : 'all governments' }}"
							[disabled]="!wizardService.category"
							(click)="showConfirmationDialog()"
							class="u-margin-bottom-small"
						>
						</dx-button>
						<br />
						<dx-button
							type="default"
							text="Select specific government types and/or locations to use"
							[disabled]="!wizardService.category"
							(click)="wizardService.handleForward()"
						></dx-button>
					</div>
				</div>
			</div>
		</div>

		<div *ngSwitchCase="'d4'">
			<h3>Show total {{ wizardService.category?.displayName }} for...</h3>
			<div class="wizard__d4">
				<div class="dx-fieldset">
					<div class="dx-field">
						<div class="dx-field-label">...selected government types...</div>
						<div class="dx-field-value">
							<app-government-types [isTrackD]=true [disabledGovTypes]="disabledGovTypes" (changed)="wizardService.updateGovTypes($event)"></app-government-types>
						</div>
					</div>
					<div class="dx-field">
						<div class="dx-field-label">
							...in the following locations (choose up to {{maxLocationsAllowedForTrackD}})
						</div>
						<div class="dx-field-value">
							<app-locations
								(changed)="wizardService.updateLocations($event)"
								[store]="wizardService.snapshot?.detail?.counties"
								[selections]="wizardService.locations"
								[isTrackD]=true
								[maxLocationsAllowedForTrackD]="maxLocationsAllowedForTrackD"
							></app-locations>
						</div>
					</div>
					<div class="field-buttons">
						<div class="field-buttons__left">
							<dx-button
								type="normal"
								text="Back"
								(click)="wizardService.jumpToStep(wizardService.step.id - 1)"
							></dx-button>
						</div>
						<div class="field-buttons__right">
							<dx-button
								type="default"
								text="View total {{ wizardService.category.displayName }} for {{ (wizardService.locations || wizardService.govTypes) ? 'these government types and locations' : 'all governments' }}"
								(click)="showConfirmationDialog()"
							></dx-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngSwitchCase="'e3'">
			<h3>Choose an accounting basis, fund category, and government type(s).</h3>
			<app-track-e
				(back)="wizardService.jumpToStep(wizardService.step.id - 1)"
				(submit)="wizardService.trackE($event)"
			></app-track-e>
		</div>

	</div>
</div>

<dx-popup
	class="confirmation-dialog bonanza-popup"
	width="400"
	height="auto"
	title="Continue?"
	[showCloseButton]=false
	[visible]="isConfirmationDialogVisible"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<p class="u-margin-bottom-none">
					By not selecting a government type or location filter, all governments are shown in the report, which can take a long time to calculate.
					Do you wish to continue?
				</p>
			</div>

			<div class="app-popup__footer">
				<dx-button
					type="default"
					text="OK"
					(onClick)="onConfirmationDialogChange($event, true)"
				></dx-button>

				<dx-button
					class="u-margin-left-small"
					text="Cancel" too-small
					(onClick)="onConfirmationDialogChange($event, false)"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
