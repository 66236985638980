import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import {median, quantile} from 'datalib';

export enum FormatDateTimeFlag {
	Date,
	Time,
	Day
}

@Injectable({
	providedIn: 'root'
})

export class UtilityService {

	constructor() { }

	/**
	 * `Not Implemented` placeholder
	 * @param info
	 */
	notImplemented(info, delay) {
		// TODO replace with UserInterfaceService.showToast()
		delay = delay || 1500;
		notify('This functionality has not yet been implemented. See console for more information.', 'error', delay);
		info = info || 'I didn\'t think it important to tell you where I came from.';
		throw new Error('Not implemented:' + info.toString());
	}

	/**
	 * Apply properties and methods to a generic Object from a specific type.
	 * @param item
	 * @param constructor
	 * @returns {item}
	 */
	hydrate(item, constructor) {
		constructor = constructor || Object;
		const template = new constructor;
		for (const prop in template) {
			if (!item[prop]) {
				item[prop] = template[prop];
			}
		}

		return item;
	}

	groupBy(arr, key) {
		return arr.reduce(function (v, x) {
			(v[x[key]] = v[x[key]] || []).push(x);
			return v;
		}, {});
	}

	// https://gist.github.com/mikaello/06a76bca33e5d79cdd80c162d7774e9c
	groupByKeys = (keys: Array<string>) => (array: Array<any>) =>
		array.reduce((objectsByKeyValue, obj) => {
			const value = keys.map(key => obj[key]).join('-');
			objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
			return objectsByKeyValue;
		}, {});

	camelToDash(str) {
		return str.replace(/\W+/g, '-')
			.replace(/([a-z\d])([A-Z])/g, '$1-$2')
			.toLowerCase();
	}

	dashToCamel(str) {
		return str.replace(/\W+(.)/g, function (x, chr) {
			return chr.toUpperCase();
		});
	}

	dotToSlash(str) {
		return str.replace(/\./g, '/');
	}

	/**
	 * Join arr1 to arr2 on provided key.
	 * @param {Array.<Object>} arr1
	 * @param {Array.<Object>} arr2
	 * @param {string} key
	 * @returns {Array}
	 */
	joinObjectArrays(arr1, arr2, key) {
		return arr1.map(function (arr1Item) {
			Object.assign(arr1Item, arr2.find(function (arr2Item) {
				return arr1Item[key] === arr2Item[key];
			}));
			return arr1Item;
		});
	}

	range(start, count) {
		return Array.apply(0, Array(count))
			.map(function (element, index) {
				return index + start;
			});
	}

	yearsToRangeText(years: Array<number>) {
		let result = '';

		if (!Array.isArray(years) || !years[0]) {
			return result;
		}

		if (years.length > 2) {
			years = [Math.min(...years), Math.max(...years)];
		}

		if (years[1] === null || years[0] === years[1]) {
			result += years[0];
		} else {
			result += years[0] + '-' + years[1];
		}

		return result;
	}

	uppercaseFirst(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	// todo encapsulate this in a years model
	yearsToRange(years) {
		const isValid = Array.isArray(years)
			&& years.length === 2
			&& Number.isInteger(years[0])
			&& (Number.isInteger(years[1]) || years[1] === null);

		const count = years[1] === null
			? 1
			: years[1] - years[0] + 1;

		return this.range(years[0], count);
	}

	// Allows retrieval of nested property by string
	// e.g. Traverse Level1.Level2.Level3 with getProperty('Level2.Level3', Level1)
	getProperty(propertyName, object) {
		const parts = propertyName.split('.'),
			length = parts.length;
		let property = object || this;

		for (let i = 0; i < length; i++) {
			property = property[parts[i]];
		}

		return property;
	}

	areArrayValuesEqual(a: Array<any>, b: Array<any>): boolean {
		if (a === b) { return true; }
		if (a == null || b == null) { return false; }
		if (a.length !== b.length) { return false; }
		a = a.slice().sort();
		b = b.slice().sort();

		for (let i = 0; i < a.length; ++i) {
			const aValue = a[i];
			const bValue = b[i];
			if (Array.isArray(aValue) && Array.isArray(bValue)) {
				// If value itself is an array, recurse
				if (!this.areArrayValuesEqual(aValue, bValue)) { return false; }
			} else if (aValue !== bValue) {
				// otherwise check to see if value is the same (objects will be equality by reference)
				return false;
			}
		}
		return true;
	}

	// Format our currency values manually given a unit because we are using calculateCustomSummary, etc
	formatCurrency(unit: any, value: number) {
		let unitSuffix = '';
		const format: string = unit?.format;
		if (format.includes('thousands')) {
			value = value / 1000;
			unitSuffix = 'K';
		} else if (format.includes('millions')) {
			value = value / 1000000;
			unitSuffix = 'M';
		}

		const options = {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: unit?.precision,
			maximumFractionDigits: unit?.precision
		};

		return value.toLocaleString('en-US', options) + unitSuffix;
	}

	/**
	 * The "trimean" a weighted average of the distribution's median and its two quartiles.
	 * See Trimean in Wikipedia, The Free Encyclopedia for more information
	 * https://en.wikipedia.org/w/index.php?title=Trimean&oldid=1029934022

	 * @param {Array} numbers An array of numbers.
	 * @return {Number} The calculated median value from the specified numbers.
	 */
	trimean = (numbers) => {
		const numbersMedian = median(numbers);

		// datalib library uses the Excel PERCENTILE.INC/R7 algorithm
		// https://github.com/vega/datalib/wiki/Statistics#dl_quantile
		// Required sorted array as input
		const upperQuartile = quantile(numbers.sort((n1, n2) => n1 - n2), 0.75);
		const lowerQuartile = quantile(numbers.sort((n1, n2) => n1 - n2), 0.25);

		return (lowerQuartile + (2 * numbersMedian) + upperQuartile) / 4;
	}

	// format dateTime to pacific time zone date time string
	formatDateTimeToPacificTimeZone = (dateTimeCreated: Date, formatDateTimeFlag: FormatDateTimeFlag) => {
		if (formatDateTimeFlag === FormatDateTimeFlag.Day) {
			return dateTimeCreated.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
		} else if (formatDateTimeFlag === FormatDateTimeFlag.Time) {
			return dateTimeCreated.toLocaleString('en-US', { timeZone: 'America/Los_Angeles', year: '2-digit', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', timeZoneName: 'short', hour12: true }).replace(',', '');
		} else {
			return dateTimeCreated.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles', year: '2-digit', month: '2-digit', day: '2-digit'});
		}
	};
}
