import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatewideAveragesComponent} from './statewide-averages.component';
import {LoadingModule} from '../../ui/loading/loading.module';
import {DxDataGridModule} from 'devextreme-angular';


@NgModule({
	declarations: [StatewideAveragesComponent],
	imports: [
		CommonModule,
		LoadingModule,
		DxDataGridModule
	],
	exports: [
		StatewideAveragesComponent
	]
})
export class StatewideAveragesModule {
}
