export enum TabState {
	lgfrs = 'lgfrs',
	ospi = 'ospi',
	searchMap = 'government-search-map',
	searchName = 'government-search-name',
	hype = 'hype',
	wizard = 'wizard',
	indicator = 'indicator',
	profile = 'government-profile',
	typeProfile = 'government-type-profile'
}
