import {Injectable} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {cloneDeep, isEqual} from 'lodash';
import {FilterBuilderService} from './filter-builder.service';
import {FieldService} from './field.service';
import {TrackAService} from './tracks/track-a.service';
import {TrackB1Service} from './tracks/track-b1.service';
import {TrackB2Service} from './tracks/track-b2.service';
import {TrackCService} from './tracks/track-c.service';
import {TrackDService} from './tracks/track-d.service';
import {LoggerService} from './logger.service';
import {Tab} from '../models/tab';
import {TabService} from './tab.service';
import {DataSourceService} from './data-source.service';
import {TabReuseService} from './tab-reuse.service';
import {Change} from '../models/transition-event';
import {PivotGridSettings} from '../models/pivot-grid-settings';
import {UnitsService} from './units.service';
import {TrackEService} from './tracks/track-e.service';
import {DatasetSource} from '../../modules/api/fit-api/models/datasets/dataset-source';
import {AccountingCategoryType} from '../../modules/services/accounting-category/models/accounting-category-type';
import {AccountingCategory} from '../../modules/services/accounting-category/models/accounting-category';

/**
 * This service responds to single-change events. E.g., choosing an option from a context menu on an LGFRS report.
 */
@Injectable({
	providedIn: 'root'
})
export class TransitionService {

	// todo Should be baked into report.ts going forward, however these are not the full endpoints, so additional
	//  research needs to be done to determine how to include prefixes like snapshot(12)/Schedule1Details
	segmentMap = {
		a:  {
			default:            'Schedule1Details',
			debtAndLiabilities: 'Schedule9s',
			operatingResultsAnalysisEnterprise: 'OperatingResults',
			operatingResultsAnalysisGovernmental: 'OperatingResults',
			schoolsBalanceSheet: 'FinancialReports',
			schoolsStatementRevExp: 'FinancialReports',
			schoolsRevenuesWithOthers: 'FinancialReports',
			schoolsExpenditures: 'FinancialReports',
			schoolsLiabilities: 'FinancialReports',
			schoolsRevenues: 'FinancialReports',
			schoolsGeneralFundExpenditures: 'GeneralFundExpenditures',
			schoolsLongTermLiabilities: 'LongTermLiabilities'
		},
		b1: {
			default: 'Schedule1s',
			debtAndLiabilities: 'Schedule9s',
		},
		b2: {
			default: 'Schedule1s',
			debtAndLiabilities: 'Schedule9s',
		},
		c:  {
			default: 'Schedule1Totals',
		},
		d:  {
			default: 'Schedule1Summaries',
			schedule09CategoryTotals: 'Schedule9s/AmountsByMcag'
		},
		e:  {
			default: 'IndicatorReports'
		}
	};

	constructor(
		private filterBuilder: FilterBuilderService,
		private fieldService: FieldService,
		private trackA: TrackAService,
		private trackB1: TrackB1Service,
		private trackB2: TrackB2Service,
		private trackC: TrackCService,
		private trackD: TrackDService,
		private trackE: TrackEService,
		private log: LoggerService,
		private tabService: TabService,
		private dataSource: DataSourceService,
		private tabReuse: TabReuseService,
		private unitsService: UnitsService,
		private logger: LoggerService
	) {
	}

	// Get OData segment based on track and report
	getSegment(tab: Tab): string {
		if (!tab.track || !tab.track.id) {
			this.log.info(`TransitionService.getSegment: No track found on tab id ${tab.id}.`, tab);
			return null;
		}

		const reportSegments = this.segmentMap[tab.track.id];
		if (!reportSegments) {
			this.log.info(`TransitionService.getSegment: Could not locate segment for track id ${tab.track.id}`);
			return null;
		}

		// if report id not found, use default for track
		return reportSegments[tab.report.id] || reportSegments['default'];
	}

	/**
	 * Filters fields by fields that have filterValues set, then sorts into a list of
	 * transferrable (filters that can be applied to new track+report) vs non-transferrable
	 * (filters that cannot be applied to new track+report)
	 */
	getFilterReport(pivotGridData, toTrackId, toReportId, tab: Tab) {
		const toReport = this.getReport(toTrackId, toReportId, tab);
		const fields   = pivotGridData.fields();

		return fields.reduce(function(acc, field) {
			// check userFilterable fields that also have values set
			const fieldHasUserFilterValues   = field.userFilterable && field.filterValues && field.filterValues.length;
			const toReportHasUserFilterField = toReport.findIndex(function(v) {
				return v.id === field.id;
			}) > -1;
			if (fieldHasUserFilterValues && !toReportHasUserFilterField) {
				acc.nonTransferrable.push(field);
			}
			else if (fieldHasUserFilterValues) {
				acc.transferrable.push(field);
			}

			return acc;
		}, {transferrable: [], nonTransferrable: []});
	}

	getReport(trackId, reportId, tab) {
		if (!trackId || !reportId) {
			return null;
		}
		const trackService = this.getService(trackId);
		const report       = trackService[reportId];

		if (!report || typeof report !== 'function') {
			throw new Error(`TransitionService.getReport: No report with key "${reportId}" found in track "${trackId}".`);
		}

		return report(tab);
	}

	getService(trackId) {
		let service = null;
		try {
			service = this[`track${trackId.toUpperCase()}`];
		} catch (err) {
			throw new Error(`TransitionService.getService: no service found for track "${trackId}"`);
		}

		if (!service) {
			throw new Error(`TransitionService.getService: no track with key "${trackId}".`);
		}

		return service;
	}

	getPresets(track, report) {
		const trackService = this.getService(track.id);
		let presets        = [];
		if (trackService) {
			presets = trackService.presets();
		}

		// filter out presets that do not apply
		presets = presets.filter(function(p) {
			return !p.requires.report || p.requires.report.includes(report.id);
		});

		return presets;
	}

	/**
	 * Takes a current Array<PivotGridField> and runs transitions on each field provided
	 * @param sourceFields Array<PivotGridField>
	 * @param tab before state (used to retrieve data in fields themselves)
	 * @param toTrackId
	 * @param toReportId
	 * @param hasReportChanged
	 * @returns PivotGridFields https://js.devexpress.com/Documentation/ApiReference/Data_Layer/PivotGridDataSource/Configuration/fields/
	 */
	transitionFields(sourceFields: Array<any>, tab: Tab, toTrackId: string, toReportId: string, hasReportChanged = false): Array<any> {
		// retrieve the report from appropriate track service
		const newReport = this.getReport(toTrackId, toReportId, tab);

		if (!newReport) {
			return;
		}

		// iterate over the "to" report, which will have the effect of throwing out fields from sourceFields
		// that do not apply
		for (let i = 0; i < newReport.length; i++) {
			const field = newReport[i];

			const currentField = sourceFields.find((v) => v.name === field.name);
			// sets the area and areaIndex based on "to" report (defaults)
			// this should probably be refactored to use the transitions for consistency
			// and for allowing presets to be applied from outside the ReportLayoutBuilder
			this.fieldService.setGroupingAreaAndIndex(field);
			// but until then, this will allow us to set layouts from outside ReportLayoutBuilder
			if (currentField && currentField.hasOwnProperty('area') && currentField.hasOwnProperty('areaIndex')) {
				field.area      = currentField.area;
				field.areaIndex = currentField.areaIndex;
			}

			// Iterate over all object keys of transitions object from the "to" report (defaults)
			for (const prop in field.transitions) {
				// Except inherited properties
				if (!field.transitions.hasOwnProperty(prop)) {
					continue;
				}

				// Assign results of transition to field's property; passes null if no currentField
				field[prop] = field.transitions[prop](currentField && currentField[prop], hasReportChanged || tab.report.id !== toReportId);
			}

			delete field.transitions; // Tidy up resulting object
		}

		return newReport;
	}

	/**
	 * Gets the correct field for year filters dependent on dataset source.
	 * OSPI (schools) uses 'fy' and SAO Annual Filing uses 'year'.
	 * @param datasetSource
	 * @private
	 */
	private getYearFieldForFilter(datasetSource: DatasetSource) {
		switch (datasetSource) {
			case 'OSPI':
				return 'fy';
			case 'SAOAnnualFiling':
				return 'year';
			default:
				return null;
		}
	}

	/**
	 * Gets the filter to be set on the dataSource itself
	 * e.g. Snapshots(1)/Schedule1Details?$filter=Year ge 2014 and Year le 2016 and MCAG eq '0095'
	 * 									  ________________________________________________________
	 * Place with PivotGridDataSource.filter(value)
	 * @param tab
	 */
	getPivotGridFilterExpression(tab: Tab) {
		// Do not attempt to evaluate if report or track are not defined
		if (!tab?.track?.id || !tab?.report?.id) {
			return null;
		}
		let filter, mcags, years, govTypeCodes, locationCodes;

		const yearField = this.getYearFieldForFilter(tab.report.financialsDatasetSource);

		if (tab.years?.length !== 2) {
			// invalid years
			this.logger.warn(`No years specified. Cannot generate PivotGridFilter`);
			return null;
		} else if (tab.years[1] === null) {
			// Single year mode
			years = this.filterBuilder.single(yearField, tab.years[0], '=');
		} else {
			// Multi year mode
			const startYear = this.filterBuilder.single(yearField, tab.years[0], '>=');
			const endYear   = this.filterBuilder.single(yearField, tab.years[1], '<=');
			years           = [startYear, 'and', endYear];
		}

		const usesMCAGAsQueryFilter = function(trackId) {
			return ['a', 'b1', 'b2'].some((v) => v === trackId);
		};

		// Tracks are either government based or government type/location based
		if (usesMCAGAsQueryFilter(tab.track.id)) {
			mcags = tab.governments.map((v) => v.mcag);
		}
		else {
			// Retrieve codes from govTypes and locations objects
			govTypeCodes  = Array.isArray(tab.govTypes)
				&& tab.govTypes.map((v) => v.code);
			// TODO this is now an array... how do we get this?
			locationCodes = Array.isArray(tab.locations)
				&& tab.locations.map((v) => v.countyCode);
		}

		// groupOperationByObjectKey does not return keys for null values
		filter = this.filterBuilder.groupOperationByObjectKey({
			mcag:        mcags,
			govTypeCode: govTypeCodes,
			// locations is handled in the url generation to facilitate hitting a separate endpoint
			// see: PivotGridService.getDataSource
		});

		filter = filter
			? [years, 'and', filter]
			: years;

		// needs to now support AccountingCategory
		if (tab.track.id === 'd' && tab.category) {
			const fieldName = this.getAccountingCategoryField(tab.category);
			const fbase        = [fieldName, '=', tab.category.id];
			filter             = [filter, 'and', fbase];
		}

		if (tab.track.id === 'e') {
			filter = [filter, 'and', ['filingBasis', '=', tab.filingBasis.name]];
			const ODataEnumCase = tab.report.id.charAt(0).toUpperCase() + tab.report.id.slice(1);
			filter = [filter, 'and', ['report', '=', ODataEnumCase]];
		}

		// filter on governmental or enterprise for operating results analysis reports
		if (tab.report.id === 'operatingResultsAnalysisEnterprise') {
			filter = [filter, 'and', ['fundCategoryId', '=', 2], 'and', ['fundTypeId', '=', 4]];
		}

		if (tab.report.id === 'operatingResultsAnalysisGovernmental') {
			filter = [filter, 'and', ['fundCategoryId', '=', 1]];
		}

		return filter;
	}

	getKeysOfTrackService(trackId) {
		const trackService = this.getService(trackId);
		return trackService ? Object.keys(trackService) : null;
	}

	updateTabAndPivotGrid(pivotGridData, tab: Tab, tempTab: Tab, changes: Array<Change>, initialFields: Array<any> = null) {
		const self = this;
		// Replace data loss with sensible defaults
		if (changes) {
			changes.forEach(function(change) {
				const changeFunction = self['set' + change.name];
				if (typeof changeFunction !== 'function') {
					self.log.warn('TransitionService.updateTabAndPivotGrid: Unable to locate function for change '
						+ change.name + '.');
				}
				else {
					changeFunction(tab, tempTab);
				}
			});
		}

		// determine event to send to analytics
		const trackChange  = tab.track.id !== tempTab.track.id;
		const reportChange = tab.report.id !== tempTab.report.id;
		if (reportChange || trackChange) {
			const analyticsEvent = trackChange ? 'track' : 'report';
			// TODO: build AnalyticsService
			// AnalyticsService.sendEvent(tempTab, analyticsEvent);
		}

		if (Array.isArray(initialFields)) {
			// If filters are provided, this is a new tab being created
			// temporarily set filters on tab for PG to pick up once loaded
			tempTab.initialFields = initialFields;
			self.tabService.add(tempTab);
		}
		else { // replace inline
			// if segment is changing, we need to delete current tab and create a new one
			// this is because url of PivotGridDataSource is immutable
			const needNewDataSource = (
				self.getSegment(tab) !== self.getSegment(tempTab)
				|| tab.snapshotId !== tempTab.snapshotId
			);
			if (needNewDataSource) {
				// TODO this needs to be aligned with saving the tab inline and rebuilding the dataSource
				delete tempTab.id;
				self.tabService.add(tempTab, true);
				self.tabService.delete(tab);
				return;
			}
			// update tab properties and then update PG and load/reload
			const governmentsAreChanging = (tab.governments && tab.governments.length) !== (tempTab.governments && tempTab.governments.length);
			// const yearsAreChanging = !tab.years.equals(tempTab.years);
			const yearsAreChanging       = !isEqual(tab.years, tempTab.years);
			const categoryIsChanging     = tab.category && tempTab.category && tab.category.id !== tempTab.category.id;

			Object.assign(tab, tempTab);
			if (governmentsAreChanging || yearsAreChanging || categoryIsChanging) {
				self.log.info('TransitionService.executeTransition: Hard reload conditions met, new data call...');
				const filter = self.getPivotGridFilterExpression(tab);
				pivotGridData.filter(filter);
				pivotGridData.fields(self.transitionFields(pivotGridData.fields(), tab, tempTab.track.id, tempTab.report.id, reportChange));
				pivotGridData.reload();
			}
			else {
				self.log.info('TransitionService.executeTransition: No data change, redrawing PivotGrid');
				pivotGridData.fields(self.transitionFields(pivotGridData.fields(), tab, tempTab.track.id, tempTab.report.id, reportChange));
				pivotGridData.load();
			}
		}
	}

	executeTransition(transitionEvent, pivotGridData, tab) {
		const self = this;
		this.log.info('TransitionService.executeTransition: Executing transitionEvent', transitionEvent, pivotGridData, tab);

		return new Promise((res, rej) => {
			const cancel = function() {
				res('cancel');
				return;
			};

			if (!transitionEvent || !transitionEvent.name || transitionEvent.name === 'Undefined Event') {
				this.log.warn('TransitionService.executeTransition: Invalid TransitionEvent: ', transitionEvent);
				cancel();
			}

			const fields = pivotGridData && pivotGridData.fields();
			if (!fields) {
				throw new Error('TransitionService.executeTransition: A valid PivotGridDataSource must be provided.');
			}

			const tempTab        = cloneDeep(tab);
			// perform the defined action on tempTab
			const actionFunction = self['do' + transitionEvent.action].bind(self);
			if (typeof actionFunction === 'function') {
				actionFunction(tempTab, transitionEvent.actionValue);
			}
			else {
				this.log.error('TransitionService.executeTransition: Unable to locate function for '
					+ transitionEvent.action + '. Aborting.');
				cancel();
			}

			// Get changes to present to the user
			const losses = self.lossReport(transitionEvent.changes, tab, tempTab);

			// filters are always evaluated, for any transition
			const filterReport = self.getFilterReport(pivotGridData, tempTab.track.id, tempTab.report.id, tab);
			filterReport.nonTransferrable.forEach(function(field) {
				// key is null so that a method is not called, value is null so that only name is printed for user
				losses.push({key: 'filter', name: 'filter', value: field.caption});
			});

			// No loss, replace inline with no user prompt
			if (!losses.length) {
				self.updateTabAndPivotGrid(pivotGridData, tab, tempTab, transitionEvent.changes);
				res('success');
				return;
			}

			self.tabReuse.openModal({
				title:       'What would you like to do with your current filter(s) or display options?',
				prompt:      'The action you\'re performing does not support '
								+ self.formatLossesText(losses)
								+ '. Your selected values will not be preserved for these items.',
				newHint:     'We\'ll copy your current selections and set up a new tab for this report '
								+ 'and preserve the current tab with the existing filter(s) and display option(s).',
				replaceHint: 'We\'ll replace the current tab for this report. Not all selections made on the current may be applied in the new tab.'
			}).subscribe((result) => {
				switch (result) {

					case 'cancel':
						res('cancel');
						break;

					case 'new':
						self.updateTabAndPivotGrid(pivotGridData, tab, tempTab, transitionEvent.changes, filterReport.transferrable);
						res('cancel');
						break;

					case 'replace':
						self.updateTabAndPivotGrid(pivotGridData, tab, tempTab, transitionEvent.changes);
						res('success');
						break;

					default:
						res('cancel');
						break;
				}
			});
		});
	}

	doChangeSnapshot(tab: Tab, snapshotId) {
		tab.snapshotId = snapshotId;
	}

	doChangeAccountCategory(tab: Tab, category) {
		tab.category = category;
	}

	doChangeReport(tab, reportId) {
		tab.report = this.tabService.availableReports.find(function(report) {
			return report.id === reportId;
		});
	}

	doChangeYears(tab, years) {
		tab.years = years;
	}

	doRemoveBaseline(tab, value) {
		this.tabService.setBaselineGovernment(tab, null);
	}

	doRemoveGovernment(tab, value) {
		this.tabService.deleteGovernment(tab, value);
	}

	doSetBaseline(tab, value) {
		this.tabService.setBaselineGovernment(tab, value.govText, value.year);
	}

	doNavigateToTrackD(tab, category) {
		this.log.info('TransitionService.doNavigateToTrackD', tab, category);
		tab.track          = this.tabService.availableTracks.find(function(track) {
			return track.id === 'd';
		});
		// todo #9906 remove this line so that report evaluation is handled as other changes
		tab.report         = this.tabService.availableReports.find(function(v) {
			return v.id === 'schedule01CategoryTotals';
		});
		tab.category       = category;
		tab.pivotGridState = null;

		const filter = this.filterBuilder.groupOperationByObjectKey({
			countyCodes: tab.locations ? tab.locations.map(function(v) {
				return v.countyCode;
			}) : [],
			govTypeCode: tab.govTypes ? tab.govTypes.map(function(v) {
				return v.code;
			}) : []
		});

		// Get government info for criteria
		const govStoreRequest = new DataSource({
			store:    this.dataSource.getStore(tab.snapshotId, 'localGovernments'),
			paginate: false,
			filter:   filter,
			select:   ['mcag', 'entityName', 'entityNameWithDba', 'lookupNameWithDba', 'govTypeCode', 'countyCodes']
		});

		// not thread-safe
		govStoreRequest.load().then(function(result) {
			tab.governments = result;
		});
	}

	lossReport(changes, oldTab, newTab) {

		if (!changes) {
			return [];
		}

		const self = this;

		const result = changes.reduce(function(accumulator, change) {
			if (change.promptsUser === false) {
				return accumulator;
			}

			const evaluationFunction = self['evaluate' + change.name];
			if (typeof evaluationFunction === 'function') {
				const res = evaluationFunction(oldTab, newTab);
				if (res) {
					// key is used for defaults, name is for user-friendly string
					// e.g. key: 'Comparison', name: 'Comparison', value: '% Total of Total'
					accumulator.push({key: change.name, name: res.name, value: res.value});
				}
			}
			else {
				self.log.warn('TransitionService.executeTransition: Unable to locate function for evaluation '
					+ change.name + '. Skipping.');
			}

			return accumulator;
		}, []);

		return result;
	}

	formatLossesText(items) {
		let result = '';
		items.forEach(function(item, index, array) {
			// item.value e.g. % of Total, null
			// item.name e.g. Comparision, baseline
			const isLast    = (array.length - 1) === index;
			const separator = index > 0 ? ', ' : '';
			const value     = item.value ? item.value + ' ' : '';
			result += separator + value + item.name;
		});

		return result;
	}

	getApplicablePivotGridSettings = (fromTab: Tab, toTab: Tab): PivotGridSettings => {
		// Pull over blank slate from the Tab. Note that this assumes the tab has been build with
		// TabService.build{?}BasedTab which sets some sensible defaults
		const result            = toTab.pivotGridSettings;
		// showAccoutCodes is a globally-available setting, assign this with no check
		result.showAccountCodes = fromTab.pivotGridSettings?.showAccountCodes;

		// report-specific settings will be regenerated and are not user-selectable anyway
		// nothing to do for these

		// Evaluate whether UnitsService-backed options can be transferred to new tab
		const unitsServiceKeys = ['measure', 'comparison', 'unit'];
		const availableUnits   = this.unitsService.getAvailable(toTab);
		unitsServiceKeys.forEach(item => {
			const availableItems = availableUnits[`${item}s`];
			const isValid = availableItems.find(x => x.key === (fromTab?.pivotGridSettings)?.[item]) != null;
			// if the existing pivotGridSetting can be transferred to the new tab, then preserve that selection
			toTab.pivotGridSettings[item] = isValid
				? fromTab.pivotGridSettings[item]
				// otherwise, pick the first in the available list
				: availableItems[0].key;
		});

		// assign population-related items if the governments support it
		const tabSupportsPopulation = (tab: Tab) => {
			const govCodesThatSupportPopulation        = ['06', '07'];
			// tracks a, b1, b2
			const someGovernmentsSupportPopulation     = Array.isArray(tab.governments) && tab.governments.some(gov =>
				govCodesThatSupportPopulation.some(v => v === gov.govTypeCode)
			);
			// tracks c, d
			const someGovernmentTypesSupportPopulation = Array.isArray(tab.govTypes) && tab.govTypes.some(
				gov => govCodesThatSupportPopulation.some(v => v === gov.code)
			);
			return someGovernmentsSupportPopulation || someGovernmentTypesSupportPopulation;
		};

		if (tabSupportsPopulation(toTab)) {
			result.showPopulation  = fromTab.pivotGridSettings?.showPopulation;
			result.populationField = fromTab.pivotGridSettings?.populationField;
		}

		return result;
	}

	/**
	 * Get the related field name for API endpoints. Assumes normalized field names across all financial data endpoints.
	 *
	 *  TODO this could go on the AccountingCategory class, however Dexie does not use the Tab constructor, so it makes
	 *   it hard to populate this
	 */
	getAccountingCategoryField(accountingCategory: AccountingCategory): string {
		switch (accountingCategory.type) {
			case AccountingCategoryType.bars:
				return accountingCategory.barsSegment.charAt(0).toLowerCase()
					+ accountingCategory.barsSegment.slice(1) + 'Id';
			case AccountingCategoryType.financialSummarySection:
				return 'fsSectionId';
			case AccountingCategoryType.debtCategory:
				return 'bonanzaCategoryId';
			case AccountingCategoryType.debtType:
				return 'bonanzaTypeId';
			case AccountingCategoryType.debtItem:
				return 'debtCategoryItemId';
		}
	}
}
