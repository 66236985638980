<dx-popup
	class="popup"
	[title]="title"
	[closeOnOutsideClick]="true"
	[showCloseButton]="true"
	[visible]="isVisible"
	(onShown)="onShown($event)"
	(onHiding)="onHiding($event)"
	contentTemplate="content"
	height="90%"
	width="auto"
>
	<div *dxTemplate="let data of 'content'" class="content">
		<div class="header">
			<h5 class="sub-title">{{ subTitle }}</h5>
		</div>

		<div class="filters">
			<div class="filter">
				<label>Indicator:</label>
				<dx-select-box
					[dataSource]="availableIndicators"
					displayExpr="name"
					valueExpr="id"
					grouped="true"
					[(value)]="indicator"
					(onValueChanged)="updateCustomProperties()"
				></dx-select-box>
			</div>
			<div class="filter">
				<label>Year:</label>
				<dx-select-box
					[dataSource]="availableYears"
					[(value)]="year"
					(onValueChanged)="updateCustomProperties()"
				></dx-select-box>
			</div>

		</div>
		<div class="map-container" #mapContainer [ngStyle]="{ 'width': mapContainerWidth }">
			<wasao-map
				*ngIf="mapContainerWidth !== 'auto'"
				[govTypeCode]="govTypeCode"
				[customProperties]="customProperties"
				[updateTooltip]="updateTooltip"
				[tooltip]="tooltip"
			></wasao-map>
		</div>
		<div class="footer">
			<label>Filter by Indicator:</label>

			<dx-button-group
				[items]="availableOutlooks"
				keyExpr="id"
				[elementAttr]="{id: 'indicator-group'}"
				selectionMode="multiple"
				[(selectedItems)]="outlooks"
				(onSelectionChanged)="updateCustomProperties()"
				buttonTemplate="myTemplate">

				<div class="outlook-key" *dxTemplate="let outlook of 'myTemplate'">

					<div class="indicator-color" [ngClass]=" outlook.id">{{ outlook.id }}</div>


				</div>


			</dx-button-group>

		</div>

		<wasao-loading [isLoading]="isLoading"></wasao-loading>
	</div>
</dx-popup>

<ng-template #tooltip>
	<div class="tooltip">
		<div class="title">{{ tooltipContent.governmentName }}</div>
		<div class="indicators">
			<div class="indicator">{{ tooltipContent.title }} - {{ tooltipContent.subTitle }}</div>
			<div class="measure">{{ tooltipContent.measure }} {{ tooltipContent.unit }}</div>
			<div class="outlook">
				<div class="{{tooltipContent.outlook}}"> {{ tooltipContent.outlook }} </div>
				<span class="annotation">{{ tooltipContent.outlookAnnotation }}</span>
			</div>
		</div>
		<dx-button
			class="profileBtn"
			text="View Profile"
			type="default"
			(click)="handleGovernmentSelect()"
		></dx-button>
	</div>
</ng-template>
