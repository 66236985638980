<ul class="large-ctas">
	<li>
		<button (click)="isRevenuesVisible = true">
			<span>Where does the money come from?</span>
			<img src="assets/images/data_stories/where_does_the_money_come_from.png" alt="Where does the money come from?" />
		</button>
	</li>
	<li>
		<button (click)="isExpendituresVisible = true">
			<span>Where does the money go?</span>
			<img src="assets/images/data_stories/where_does_the_money_go.png" alt="Where does the money go?" />
		</button>
	</li>
	<li *ngIf="tabState === 'hype'">
		<button (click)="handleDefaultClick()">
			<img src="assets/images/data_stories/whats_my_gov_situation.png" alt="What's my government's financial situation?" />
			<span>What's my government's financial situation?</span>
		</button>
	</li>
	<li *ngIf="tabState === 'hype'">
		<button (click)="handleDefaultClick()">
			<img src="assets/images/data_stories/whats-happening.png" alt="What's happening to my government's finances over time?" />
			<span>What's happening to my government's finances over time?</span>
		</button>
	</li>
</ul>
<dx-popup
	class="popup"
	width="auto"
	height="auto"
	[(visible)]="isRevenuesVisible"
	title="Where does the money come from?"
	closeOnOutsideClick=true
>
	<div *dxTemplate="let data of 'content'">
		<div class="popup__content">
			<div class="responsive-video">
				<iframe width="560" height="315" src="https://www.youtube.com/embed/P4U-ygOjJXQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
			<p>The money to operate a government comes from a variety of sources. General purpose governments like cities and counties have three main sources: taxes, charges for services and grants. Special purpose governments like water districts or hospitals, depend on fees and charges related to the service they provide. Discover the main revenue sources for your government.</p>
			<dx-button
				*ngIf="isInteractive"
				text="Explore Revenues"
				type="default"
				(onClick)="handleDefaultClick()"
			></dx-button>
		</div>
	</div>
</dx-popup>
<dx-popup
	class="popup"
	width="auto"
	height="auto"
	[(visible)]="isExpendituresVisible"
	title="Where does the money go?"
	closeOnOutsideClick=true
>
	<div *dxTemplate="let data of 'content'">
		<div class="popup__content">
			<iframe width="560" height="315" src="https://www.youtube.com/embed/siEi-kEyRQY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			<p>General purpose governments like cities and counties spend money on many different services, like transportation, parks, public safety, public health, social services and others. Special purpose governments typically spend money on a single service and most of that is spent on operational costs like salaries and benefits. Research how your government spends its money.</p>
			<dx-button
				*ngIf="isInteractive"
				text="Explore Expenditures"
				type="default"
				(onClick)="handleDefaultClick()"
			></dx-button>
		</div>
	</div>
</dx-popup>
