import {Component, OnInit, HostBinding} from '@angular/core';

import {environment} from 'environments/environment';
import {TabService} from '../../shared/services/tab.service';
import { TabState } from 'app/shared/models/tab-state';

@Component({
	selector:    'app-header',
	templateUrl: './header.component.html',
	styleUrls:   ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@HostBinding('class.test-background') isTest: boolean = environment.test;
	isAboutOpen   = false;
	copyrightYear = (new Date()).getFullYear();

	constructor(
		public tabService: TabService,
	) {
	}

	ngOnInit() {
	}

	setIsAboutOpen(value) {
		this.isAboutOpen = value;
	}

	launchNewHypeTab() {
		this.tabService.buildBlankTab('New Tab', TabState.hype, true);
	}

}
