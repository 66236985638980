<dx-data-grid
	class="government-chooser"
	[dataSource]="store"
	(onSelectionChanged)="handleChange($event)"
	hoverStateEnabled=false
	rowAlternationEnabled=true
	columnAutoWidth=true
	allowColumnResizing=true
	height="100%"
	noDataText="Nothing matches the filters you have chosen. Please remove some filters and try again."
>
	<dxo-scrolling mode="infinite"></dxo-scrolling>
	<dxo-load-panel delay=0></dxo-load-panel>
	<dxo-paging pageSize=20></dxo-paging>
	<dxi-column dataField="localGovernment.lookupNameWithDba" caption="Name" sortOrder="asc" cellTemplate="governmentName"></dxi-column>
	<dxi-column dataField="revenues" caption="Revenues">
		<dxo-format type="currency"></dxo-format>
	</dxi-column>
	<dxi-column dataField="expenditures" caption="Expenditures">
		<dxo-format type="currency"></dxo-format>
	</dxi-column>
	<dxi-column dataField="population" caption="Population" dataType="number">
		<dxo-format type="fixedPoint"></dxo-format>
	</dxi-column>
	<div *dxTemplate="let cell of 'governmentName'">
		<a (click)="handleChange(cell.data)">{{ cell.text }}</a>
	</div>
	<div *dxTemplate="let cell of 'datasetSource'">
		{{ cell.text === 'SAOAnnualFiling' ? 'SAO Annual Filing' : ''}}
	</div>
</dx-data-grid>
