import {Injectable} from '@angular/core';
import {SnapshotService} from './snapshot.service';
import {Field} from 'app/shared/models/field';
import {LoggerService} from './logger.service';
import {FORMAT_TYPES, IFormat} from '../models/i-format';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

type ISortable = { value: string | number } | string | number;

@Injectable({
	providedIn: 'root'
})
/**
 * This Service provides custom comparators for use with Array.prototype.sort()
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * in which case the arguments are expected to be primitive values
 *
 * or DevExtreme PivotGrid custom sorting
 * https://js.devexpress.com/Documentation/ApiReference/Data_Layer/PivotGridDataSource/Configuration/fields/#sortingMethod
 * in which case the arguments are expected to be objects with a 'value' property
 */
export class SortService {

	// TODO: fix how sortOrder is accessed; should access DevExtreme field properties
	sortOrder = 'desc';

	constructor(
		private snapshot: SnapshotService,
		private logger: LoggerService
	) {
	}

	/**
	 * Resolves the underlying value
	 * If object then return .value; Else return primitive
	 */
	private resolveValue = (arg: ISortable) => {
		if (arg !== null && typeof arg === 'object') {
			if (!arg.value) {
				this.logger.warn(`SortService expects an object to have the 'value' property. Sorting will not be applied.`);
				return null;
			}
			return arg.value;
		}
		else {
			return arg;
		}
	}

	/**
	 * Resolve the sort order by looking up the value in the snapshot detail
	 */
	private resolveSortValue = (arg: ISortable, snapshotId: SnapshotId, collectionKey: string, key = 'id', sortOrderKey = 'sortOrder') => {
		const value = this.resolveValue(arg);
		if (!value) {
			return null;
		}
		const snapshotCollection = this.snapshot.getCollection(snapshotId, collectionKey);
		if (!snapshotCollection) {
			this.logger.warn(`SortService: No snapshot collection named '${collectionKey}'. Sorting on value ${value}.`);
			return value;
		}
		const object = snapshotCollection.find(v => v[key] === value);
		if (!object) {
			this.logger.warn(`SortService: No object found in '${collectionKey}' matching ${key} === ${value}. Sorting on ${value}.`);
			return value;
		}
		return object[sortOrderKey];
	};

	/**
	 * Resolve the sort order by looking up the value in the schools detail
	 */
	private resolveOSPISortValue = (arg: ISortable, reportNo: string, collectionKey: string, key = 'id', sortOrderKey = 'sortOrder') => {
		const value = this.resolveValue(arg);
		if (!value) {
			return null;
		}
		const schoolsCollection = this.snapshot.getOSPICollection(collectionKey);
		if (!schoolsCollection) {
			this.logger.warn(`SortService: No schools collection named '${collectionKey}'. Sorting on value ${value}.`);
			return value;
		}
		const object = schoolsCollection.find(v => v[key] === value && (reportNo === null || v['reportNo'] === reportNo));
		if (!object) {
			this.logger.warn(`SortService: No object found in '${collectionKey}' matching reportNo === ${reportNo} and ${key} === ${value}. Sorting on ${value}.`);
			return value;
		}
		return object[sortOrderKey];
	};

	private defaultSort = (a: string | number, b: string | number) => {
		// If both strings, run against localeCompare
		if (typeof a === 'string' && typeof b === 'string') {
			return a.localeCompare(b);
		}

		// otherwise use logical operators
		if (a < b) {
			return -1;
		}
		if (a > b) {
			return 1;
		}
		return 0;
	};

	private sortOn = (a: ISortable, b: ISortable, snapshotId: SnapshotId, unknownOrder = -1, section: string, field: string): number => {
		// Need to use secondary sort using 'Id' since category display can be repeated
		let aSort = this.resolveSortValue(a, snapshotId, section, 'id', field);
		aSort     = aSort ? aSort + this.resolveValue(a) : unknownOrder;
		let bSort = this.resolveSortValue(b, snapshotId, section, 'id', field);
		bSort     = bSort ? bSort + this.resolveValue(b) : unknownOrder;
		return this.defaultSort(aSort, bSort);
	};

	private sortOnOSPI = (a: ISortable, b: ISortable, reportNo: string, key: string, unknownOrder = -1, section: string, field: string): number => {
		let aSort = this.resolveOSPISortValue(a, reportNo, section, key, field);
		aSort     = aSort ? aSort + this.resolveValue(a) : unknownOrder;
		let bSort = this.resolveOSPISortValue(b, reportNo, section, key, field);
		bSort     = bSort ? bSort + this.resolveValue(b) : unknownOrder;
		return this.defaultSort(aSort, bSort);
	};

	sortOnOSPIFund = (a: ISortable, b: ISortable, reportNo: string = null, unknownOrder = -1): number =>
		this.sortOnOSPI(a, b, reportNo, 'code', unknownOrder, 'funds', 'sortOrder');

	sortOnCategoryDisplay = (a: ISortable, b: ISortable, snapshotId: SnapshotId, unknownOrder = -1): number =>
		this.sortOn(a, b, snapshotId, unknownOrder, 'accountDescriptors', 'categoryDisplay');

	sortOnBonanzaSchedule9CategoriesSortOn = (a: ISortable, b: ISortable, snapshotId: SnapshotId, unknownOrder = -1): number =>
		this.sortOn(a, b, snapshotId, unknownOrder, 'bonanzaSchedule9Categories', 'sortOrder');

	sortOnBonanzaSchedule9TypesSortOn = (a: ISortable, b: ISortable, snapshotId: SnapshotId, unknownOrder = -1): number =>
		this.sortOn(a, b, snapshotId, unknownOrder, 'bonanzaSchedule9Types', 'sortOrder');

	sortFormatType = (a: IFormat, b: IFormat) => {
		// priority of formats is in the order they are defined
		const formatTypes = FORMAT_TYPES;
		// use the index to get priority of sort, e.g. [0]currency comes before [1]number
		return this.defaultSort(formatTypes.indexOf(a.format), formatTypes.indexOf(b.format));
	};

	sortFundTypes(a: ISortable, b: ISortable, snapshotId: SnapshotId, unknownOrder = -1) {
		const aSort = this.resolveSortValue(a, snapshotId, 'fundTypes') || unknownOrder;
		const bSort = this.resolveSortValue(b, snapshotId, 'fundTypes') || unknownOrder;
		return this.defaultSort(aSort, bSort);
	}

	sortFundCategories(a: ISortable, b: ISortable, snapshotId: SnapshotId, unknownOrder = -1) {
		const aSort = this.resolveSortValue(a, snapshotId, 'fundCategories') || unknownOrder;
		const bSort = this.resolveSortValue(b, snapshotId, 'fundCategories') || unknownOrder;
		return this.defaultSort(aSort, bSort);
	}

	sortFsSection(a: ISortable, b: ISortable, snapshotId: SnapshotId, unknownOrder = -1) {
		const aSort = this.resolveSortValue(a, snapshotId, 'financialSummarySections') || unknownOrder;
		const bSort = this.resolveSortValue(b, snapshotId, 'financialSummarySections') || unknownOrder;
		return this.defaultSort(aSort, bSort);
	}

	// TODO The following methods have not been retrofit to use the ISortable pattern and are build specifically
	// for use in DxPivotGrid

	sortFunds(a, b) {
		// expect value and text to be ${fundNumber}|${fundName}
		const aFundNumber = a.value.split('|')[0];
		const bFundNumber = b.value.split('|')[0];
		return aFundNumber - bFundNumber;
	}

	sortPeerGroup(a, b) {
		return a.value === 'Baseline Government' ? -1 : 1;
	}

	/**
	 * Sorts Indicators in the Indicator Report
	 * @param a
	 * @param b
	 * @param filingBasis
	 * @param reportId
	 * @param snapshotId
	 */
	sortIndicator = (a, b, filingBasis: FilingBasis, reportId: 'governmentalIndicators' | 'enterpriseIndicators', snapshotId: SnapshotId) => {
		const indicatorA = this.snapshot.findIndicatorName(snapshotId, a.value, filingBasis, reportId);
		const indicatorB = this.snapshot.findIndicatorName(snapshotId, b.value, filingBasis, reportId);
		if (!indicatorA || !indicatorB) {
			let offenders = null as string;
			if (!indicatorA) {
				offenders += `a: ${a.value}`;
			}
			if (!indicatorB) {
				offenders += `b: ${b.value}`;
			}
			this.logger.warn(`Could not get Indicator Name object for sorting! Invalid values: ${offenders}`);
			return 0;
		}
		return this.defaultSort(indicatorA.sortOrder, indicatorB.sortOrder);
	};

	sortGovernment(a, b, prime = null) {
		// Pin prime to the leftmost column by circumventing
		// field's sortOrder
		if (prime && a.value === prime.mcag) {
			return this.sortOrder === 'desc' ? 1 : -1;
		}
		if (prime && b.value === prime.mcag) {
			return this.sortOrder === 'desc' ? -1 : 1;
		}

		// Dictionary sorting
		if (a.text > b.text) {
			return 1;
		}
		if (a.text < b.text) {
			return -1;
		}
		return 0;
	}

	byAreaIndex = (a: Field, b: Field) => a.areaIndex - b.areaIndex;
	byCaption   = (a: Field, b: Field) => a.caption.localeCompare(b.caption);

	byString = (a, b) => {
		a = this.resolveValue(a).toString();
		b = this.resolveValue(b).toString();
		if (a > b) {
			return 1;
		}
		if (a < b) {
			return -1;
		}
		return 0;
	}

	/**
	 * Generically resolve the sort order of two values by looking up the sortOrder in the Snapshot Detail.
	 * Assumes all sortOrders are numerical.
	 * @param snapshotId
	 * @param collection
	 * @param a
	 * @param b
	 */
	sortOnSnapshotSortOrder = (snapshotId: SnapshotId, collection: string, a: number | string, b: number | string) =>
		this.snapshot.findObject(snapshotId, collection, a)?.sortOrder - this.snapshot.findObject(snapshotId, collection, b)?.sortOrder;

}
