<dx-popup
	[title]="title"
	[(visible)]="isVisible"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<ng-container [ngSwitch]="state">
					<app-shapefile-vintages *ngSwitchCase="service.states.Vintages"></app-shapefile-vintages>
					<app-maintain-vintage *ngSwitchCase="service.states.MaintainVintage"></app-maintain-vintage>
					<app-shapefile-upload *ngSwitchCase="service.states.ShapefileUpload"></app-shapefile-upload>
					<app-tcadistrict-assignments *ngSwitchCase="service.states.TaxCodeAreaDistrictAssignments"></app-tcadistrict-assignments>
					<app-crosswalk-editor *ngSwitchCase="service.states.CrosswalkEditor"></app-crosswalk-editor>
					<app-local-government-shape-generator *ngSwitchCase="service.states.LocalGovernmentShapeGenerator"></app-local-government-shape-generator>
				</ng-container>
			</div>
			<div class="app-popup__footer">
				<dx-button
					text="Back"
					[disabled]="state === service.states.Vintages"
					(click)="back($event)"
				></dx-button>
				<dx-button
					text="Close"
					type="default"
					(click)="close()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
