import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FinancialSummaryService} from '../../services/financial-summary-service/financial-summary.service';
import {LoggerService} from '../../../shared/services/logger.service';
import {CategorySummaryItem} from '../../services/financial-summary-service/models/category-summary-item';
import {CompactCategoryNamePipe} from '../../../shared/pipes/compact-category-name.pipe';
import {Report, ReportId} from '../../../shared/models/report';
import {FundCategoryId} from '../../services/fund-service/models/fund-category';
import {ReusableGovernmentAndTypeInputs} from '../../reusable/models/reusable-government-and-type-inputs';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {CategoryType} from '../../services/financial-summary-service/models/category-type';
import {SummaryYearItem} from '../../services/financial-summary-service/models/summary-year-item';
import {CategorySummaryItemViewModel} from './category-summary-item-view-model';
import {DisplayDensity} from '../../reusable/models/reusable';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {pieChart} from '../../../../../sao-patterns/src/tokens/government-colors.js';

@Component({
	selector: 'wasao-amounts-by-category',
	templateUrl: './amounts-by-category.component.html',
	styleUrls: ['./amounts-by-category.component.scss']
})
export class AmountsByCategoryComponent extends ReusableGovernmentAndTypeInputs implements OnInit, OnChanges {

	/**
	 * Required. The category type to display.
	 */
	@Input() categoryType: CategoryType = 'revenues';

	/**
	 * Optional filter; only applies to SAOAnnualFiling Datasets. Defaults to all fundCategoryIds (no filtering)
	 *  if not specified.
	 */
	@Input() fundCategoryId: FundCategoryId;

	/**
	 * The action to perform when "see trend data" is clicked.
	 */
	@Input() trendDataClickAction: (reportId: ReportId, displayYear?: number, fundCategoryId?: number, showExpenditureObjects?: boolean) => void;

	/**
	 * The percentage to use to define the threshold for grouping small category amounts into 'Other' for print mode.
	 * Defaults to 0.10.  Setting to 0 will disable.
	 */
	@Input() smallAmountsPercentage = 0.10;

	/**
	 * The size group name to use - this keeps multiple pie charts the same size
	 */
	@Input() pieChartSizeGroup = 'amounts-by-category';

	/**
	 * The report data is currently displayed from. Used for click events and title display.
	 * @private
	 */
	report: Report;

	private reportFieldTransformations: any;

	// Uses year provided in government/type specificity or can default to BARS year from applicable snapshot
	displayYear: number;

	// Total to display for the year, report type and fund category filters provided
	displayYearTotal: number;

	totalsByCategory: Array<CategorySummaryItemViewModel>;

	// Grand totals by year
	totalsByYear: Array<SummaryYearItem>;

	// Calculated threshold amount for which categories get grouped together into 'Other'
	smallAmountsGroupingThreshold: number;

	pieChartColors: Array<string> = pieChart;

	// @deprecated
	isExpenditureObjects: boolean;

	constructor(
		filingStatus: FilingStatusService,
		private financialSummary: FinancialSummaryService,
		protected logger: LoggerService,
		private compactCategoryName: CompactCategoryNamePipe,
		fitApi: FitApiService
	) {
		super(filingStatus, fitApi, logger);
	}

	ngOnInit() {
		this.isLoading = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.callFunctionWithSpecificity(changes, this.updateData);
	}

	updateData = (specificity: GovernmentSpecificity) => {
		// reset
		this.totalsByCategory = null;
		this.totalsByYear = null;
		this.displayYearTotal = null;
		this.report = null;
		this.isLoading = true;
		this.logger.time(this, this.categoryType);
		this.financialSummary.getTotalsByCategory(specificity, this.categoryType, this.fundCategoryId, this.snapshotId).subscribe(result => {
			// Only process if there are categories to report within the fundCategoryId requested.
			if (result?.length) {
				// doesn't matter what category the following information comes from
				const firstCategory = result[0];
				this.displayYear = firstCategory.displayYear;
				this.report = firstCategory.report;
				this.reportFieldTransformations = firstCategory.fieldTransformations;
				this.totalsByCategory = this.getCategoryViewModel(result);
				this.totalsByYear = this.getTotalsByYear(result);
				this.displayYearTotal = this.totalsByYear.find(x => x.year === this.displayYear)?.amount;
				this.smallAmountsGroupingThreshold = this.getSmallAmountsGroupingThreshold(
					this.totalsByCategory, this.displayYear, this.displayDensity
				);

				// console.log('AmountsByCategory', this.categoryType, this.smallAmountsGroupingThreshold, this.totalsByCategory, this.totalsByYear);
			}

			this.hasData.emit(this.displayYearTotal != null);
			this.isLoading = false;
			this.logger.timeEnd(this, this.categoryType);
		});
	};

	// dxPieChart can't navigate nested properties. Instead, use a view model that places displayYear's
	//  amount at the top object level.
	getCategoryViewModel(categories: Array<CategorySummaryItem>): Array<CategorySummaryItemViewModel> {
		return categories.map(category => {
			return Object.assign(
				new CategorySummaryItemViewModel(),
				category,
				{ displayYearSummaryAmount: category?.displayYearSummary?.amount }
			);
		})
		// Order categories largest to smallest
		.sort((a, b) => b.displayYearSummaryAmount - a.displayYearSummaryAmount);
	}

	// roll up all categories by year for trend display
	getTotalsByYear(categories: Array<CategorySummaryItem>): Array<SummaryYearItem> {
		// todo could be more efficient with reduce
		const totalsByYear = new Array<SummaryYearItem>();
		categories.forEach(category => {
			category.years.forEach(year => {
				let item = totalsByYear.find(x => x.year === year.year);
				if (!item) {
					item = new SummaryYearItem();
					item.year = year.year;
					item.amount = 0;
					totalsByYear.push(item);
				}
				item.amount = this.financialSummary.initOrAdd(item.amount, year.amount);
			});
		});

		return totalsByYear;
	}

	/**
	 * Limit to a specific number of categories based on DisplayDensity
	 * @param items
	 * @param displayDensity
	 */
	getSmallAmountsGroupingThreshold(items: Array<CategorySummaryItem>, displayYear: number, displayDensity: DisplayDensity): number {
		const maxCategories = displayDensity === 'print' ? 5 : 9;
		// if the number of items is equal or greater than maxCategories, make extra room for the "Others" slot
		const indexOfCutoff = items.length >= maxCategories - 1
			? maxCategories - 2 // make space for "Others"
			: maxCategories - 1;
		// select by array index assumes items are sorted by displayYearSummaryAmount
		return items.length > maxCategories
			? items[indexOfCutoff].years.find(x => x.year === displayYear)?.amount
			: 0; // do not group
	}

	/**
	 * Setup chart label text format
	 *
	 * @param pointInfo https://js.devexpress.com/Documentation/ApiReference/Data_Visualization_Widgets/dxPieChart/Series_Types/PieSeries/label/#customizeText
	 */
	customizePointLabel = (pointInfo: any) => {
		const shouldWrapName = this.displayDensity === 'print';
		return this.compactCategoryName.transform(pointInfo.argumentText, shouldWrapName) + '\n' + pointInfo.percentText;
	};


	/**
	 * Setup chart tooltip text format
	 *
	 * @param pointInfo https://js.devexpress.com/Documentation/ApiReference/Data_Visualization_Widgets/dxPieChart/Configuration/tooltip/#customizeTooltip
	 */
	customizeTooltip = (pointInfo: any) => ({text: `${pointInfo.valueText}`});

	// todo replace isExpenditureObjects with preset
	handleTrendDataClick = () => {
		// do nothing if action undefined
		if (typeof this.trendDataClickAction !== 'function') {
			return undefined;
		}
		this.trendDataClickAction(this.report.id, this.displayYear, this.reportFieldTransformations);
	}

}
