export type FilingBasisType = 'None' | 'Cash' | 'GAAP';

export class FilingBasis {
	id: number;
	name: string;
	sortOrder: number;

	constructor(value: 0 | 1 | 2 | FilingBasisType) {
		let idOrName: 'id' | 'name';
		switch (typeof value) {
			case 'number': idOrName = 'id'; break;
			case 'string': idOrName = 'name'; break;
			case 'undefined': return;
		}
		Object.assign(this, FILING_BASES.find(x => x[idOrName] === value));
	}
}

export const FILING_BASES: Readonly<Array<FilingBasis>> = [
	{ id: 0, name: 'None', sortOrder: 0 },
	{ id: 1, name: 'Cash', sortOrder: 1 },
	{ id: 2, name: 'GAAP', sortOrder: 2 }
] as const;
