import { Injectable } from '@angular/core';
import { DxFormatPipe } from 'app/shared/pipes/dx-format.pipe';

@Injectable({
	providedIn: 'root'
})
export class SearchMethodsService {

	constructor(
		private dxFormat: DxFormatPipe
	) { }

	availableForPeerComparison = [
		{
			id: 'revenue',
			title: '+ Add governments by revenue...',
			// displays hint component content when item is rendered
			// hint: 'test content here',
			// defines secondary column properties for displaying metrics
			column: {
				dataField: 'revenues',
				format: this.dxFormat.transform('currency'),
				calculateSortValue: 'baselineMetricDifference/revenuesAbs'
			},
			filter: ['baselineMetricDifference/revenuesAbs', '<>', null],
			supports: ['comparison']
		},
		{
			id: 'expenditure',
			title: '+ Add governments by expenditures...',
			column: {
				dataField: 'expenditures',
				format: this.dxFormat.transform('currency'),
				calculateSortValue: 'baselineMetricDifference/expendituresAbs'
			},
			filter: ['baselineMetricDifference/expendituresAbs', '<>', null],
			supports: ['comparison']
		},
		{
			id: 'population',
			title: '+ Add governments by population...',
			column: {
				dataField: 'population',
				format: this.dxFormat.transform(),
				calculateSortValue: 'baselineMetricDifference/populationAbs'
			},
			filter: ['baselineMetricDifference/populationAbs', '<>', null],
			supports: ['comparison']
		},
		{
			id: 'sameCounty',
			title: '+ Add governments from the same county...'
		},
		{
			id: 'name',
			title: '+ Add governments by name...',
			supports: ['search']
		}
	];

	available = [
		{
			id: 'revenue',
			title: '+ Add governments by revenue...',
			// displays hint component content when item is rendered
			hint: 'test content here',
			// defines secondary column properties for displaying metrics
			column: {
				dataField: 'revenues',
				format: this.dxFormat.transform('currency'),
				calculateSortValue: 'revenues'
			},
			filter: ['revenues', '<>', null],
			supports: ['comparison']
		},
		{
			id: 'expenditure',
			title: '+ Add governments by expenditures...',
			column: {
				dataField: 'expenditures',
				format: this.dxFormat.transform('currency'),
				calculateSortValue: 'expenditures'
			},
			filter: ['expenditures', '<>', null],
			supports: ['comparison']
		},
		{
			id: 'population',
			title: '+ Add governments by population...',
			column: {
				dataField: 'population',
				format: this.dxFormat.transform(),
				calculateSortValue: 'population'
			},
			filter: ['population', '<>', null],
			supports: ['comparison']
		},
		// {
		//    id: 'sameCounty',
		//    title: '+ Add governments from the same county...'
		// }, //TODO - Need to add county filter and gov type filter to novice view
		{
			id: 'name',
			title: '+ Add governments by name...',
			supports: ['search']
		}
	];
}
