import { Injectable } from '@angular/core';
import { Modal, ModalOption } from './modal';
import { Subject } from 'rxjs';
import { LoggerService } from 'app/shared/services/logger.service';

@Injectable({
	providedIn: 'root'
})
export class ModalService {

	modal = new Subject<Modal>();
	isVisible = new Subject<boolean>();
	/** Gets set when this.prompt runs, unset when this.confirm */
	private resolve: (modalOption: ModalOption) => void;

	constructor(private logger: LoggerService) { }

	/** Called by outside components */
	prompt(modal: Modal): Promise<ModalOption> {
		var promise = new Promise<ModalOption>((resolve, reject) => {
			this.resolve = resolve;
		});
		this.modal.next(modal);
		this.isVisible.next(true);
		return promise;
	}

	/** Called by ModalComponent to confirm a selection */
	confirm(modalOption: ModalOption): void {
		if(!this.resolve) {
			this.logger.warn(`${this.constructor.name} No active promise to confirm!`);
			return;
		}

		this.resolve(modalOption);
		this.isVisible.next(false);
		this.resolve = null;
	}

}
