import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountingBasesComponent} from './accounting-bases.component';
import {DxButtonModule, DxPieChartModule} from 'devextreme-angular';
import {LoadingModule} from '../../ui/loading/loading.module';

@NgModule({
	declarations: [AccountingBasesComponent],
	imports:      [
		CommonModule,
		DxPieChartModule,
		DxButtonModule,
		LoadingModule,
	],
	exports:      [
		AccountingBasesComponent,
	]
})
export class AccountingBasesModule {
}
