import {Component, OnInit, ViewChild} from '@angular/core';
import {DxFileUploaderComponent, DxPopupComponent, DxValidatorComponent} from 'devextreme-angular';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {Subscription} from 'rxjs';
import {StorageService} from '../../shared/services/storage.service';
import {UserService} from '../../shared/services/user.service';
import {User} from '../../shared/models/user';
import {ReportIssueService} from './report-issue.service';
import {Reusable} from '../../modules/reusable/models/reusable';
import {environment} from 'environments/environment';
import {catchError, tap} from 'rxjs/operators';
import notify from 'devextreme/ui/notify';

import * as JSZip from 'jszip';

@Component({
	selector: 'app-report-issue',
	templateUrl: './report-issue.component.html',
	styleUrls: ['./report-issue.component.scss']
})
export class ReportIssueComponent extends Reusable implements OnInit {

	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	@ViewChild(DxFileUploaderComponent, {static: true}) fileUploader: DxFileUploaderComponent;
	@ViewChild(DxValidatorComponent, {static: true}) myValidator: DxValidatorComponent;

	private uiSubscription: Subscription;
	private userSubscription: Subscription;
	user: User;
	isVisible = true;
	private imageFile: any;
	private indexDbObj: object;
	private apiUrl = environment?.base ?? `https://portal.sao.wa.gov/fit/api`;
	loadingMessage: string;

	form = {
		contactInfo: null,
		issue: null
	};

	constructor(
		private uiService: UserInterfaceService,
		private storageService: StorageService,
		private userService: UserService,
		private reportIssueService: ReportIssueService
	) {
		super();
		this.uiSubscription = this.uiService.reportIssue$.subscribe(
			value => this.isVisible = value);
	}

	uploadFile = (file: any) => {
		this.imageFile = file;
	};

	onUploaded(e: any) {
		// creates thumbnail
		if (e.file) {
			const preview = document.createElement('img');
			const reader = new FileReader();

			reader.addEventListener('load', function () {
				// convert image file to base64 string
				if (typeof reader.result === 'string') {
					preview.src = reader.result;
				}
			}, false);

			if (e.file) {
				reader.readAsDataURL(e.file);
			}

			// change dimensions of image
			preview.height = 100;
			preview.width = 100;

			const thumbnailSpan = document.querySelector('.thumbnail');
			thumbnailSpan.innerHTML = '';
			thumbnailSpan.appendChild(preview);

		}
	}

	onUploadError(err: any) {
		notify({
			closeOnClick: true,
			closeOnOutsideClick: true,
			type: 'error',
			message: err.message
		});
	}

	onValueChanged() {
		// clears old thumbnail
		const thumbnailSpan = document.querySelector('.thumbnail');
		thumbnailSpan.innerHTML = '';
	}

	ngOnInit() {

	}

	onHidden() {
		// clear form
		this.form = {
			contactInfo: null,
			issue: null
		};

		// clear thumbnail
		const thumbnailSpan = document.querySelector('.thumbnail');
		thumbnailSpan.innerHTML = '';

		// clear uploader
		if (this.fileUploader) {
			this.fileUploader.instance.reset();
		}

		// clear image
		this.imageFile = null;
	}

	onShowing() {
		this.getIndexDbData();
		this.userSubscription = this.userService.user.subscribe(user => this.user = user);

		// clears validator on show
		this.myValidator.instance.reset();
	}

	getIndexDbData() {
		// db index
		this.storageService.transaction('r', [this.storageService.tabs, this.storageService.peerSets,
			this.storageService.users, this.storageService.projections], async () => {

			return Promise.all([this.storageService.tabs.toCollection().toArray(), this.storageService.peerSets.toCollection().toArray(), this.storageService.users.toCollection().toArray(), this.storageService.projections.toCollection().toArray()]);
		}).then((result) => {
			this.indexDbObj = {
				'tabs': result[0],
				'peerSets': result[1],
				'users': result[2],
				'projections': result[3]
			};
		}).catch(error => {
			this.errorHandler(error);
		});
	}

	handleSubmit(e: any) {
		e.preventDefault();

		const zip = new JSZip();

		const info = {
			'contactInfo': this.form.contactInfo,
			'issue': this.form.issue,
			'userAgent': navigator.userAgent,
			'userId': this.user.id === 1 ? 1 : this.user.claim.contactID
		};
		const infoJson = JSON.stringify(info, null, 2);
		const indexDbJson = JSON.stringify(this.indexDbObj, null, 2);

		if (this.imageFile) {
			zip.file(this.imageFile.name, this.imageFile);
		}
		zip.file('info.json', infoJson);
		zip.file('indexDb.json', indexDbJson);

		zip.generateAsync({type: 'blob'}).then((content: Blob) => {
			// post request
			this.reportIssueService.create(content)
				.pipe(
					tap(_ => {
						this.isLoading = true;
						this.loadingMessage = 'Submitting issue details';
					}),
					catchError(error => {
						throw new this.errorHandler(error);
					})
				)
				.subscribe(result => {
					this.sendEmail(result);
					this.isLoading = false;
					this.isVisible = false;
				});
		}).catch(error => {
			this.errorHandler(error);
		});

	}

	errorHandler(err: any) {
		notify({
			closeOnClick: true,
			displayTime: 10000,
			closeOnOutsideClick: true,
			type: 'error',
			message: err.message
		});
	}

	sendEmail(result: any) {
		const email = environment.reportAnIssueEmail;
		const subject = 'FIT Issue Report';
		const body = `Saved Issue Details: ${this.apiUrl}/IssueReports/${result.shortCode} %0DIssue:  ${this.form.issue}`;
		const mailto_link = 'mailto:' + email + '?subject=' + subject + '&body=' + body;
		window.open(mailto_link, 'emailWindow');
	}
}
