<ng-container>
	<dx-pie-chart
		[palette]="pieChartColors"
		[dataSource]="governingBodyTypesSourceControl"
		[sizeGroup]="sizeGroup || null"
		resolveLabelOverlapping="shift"
		type="doughnut"
	>

		<dxo-size [height]="displayDensity === 'print' ? 280 : undefined"></dxo-size>
		<dxo-legend [visible]="false"></dxo-legend>
		<dxi-series argumentField="name" valueField="activeCount">
			<dxo-label visible=true [customizeText]="customizeLabel">
				<dxo-connector [visible]="true" width="1"></dxo-connector>
			</dxo-label>
		</dxi-series>

	</dx-pie-chart>
	<div class="caption">
		<span class="title">Governing Body Types for {{ selectedGovernmentType?.description }} Governments</span>
	</div>

</ng-container>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
