import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {faBuilding, faLock, faTimes} from '@fortawesome/free-solid-svg-icons';
import {PeerSetsService} from '../../shared/services/peer-sets.service';
import {PeerSet} from '../../shared/models/peer-set';
import {DxDataGridComponent, DxValidatorComponent} from 'devextreme-angular';
import {confirm} from 'devextreme/ui/dialog';
import {Peer} from '../../shared/models/peer';
import {maxComparisonPeers, minComparisonPeers} from '../../shared/services/peer-sets.service';
import {LoggerService} from '../../shared/services/logger.service';
import {DndDropEvent} from 'ngx-drag-drop';

type Area = 'included' | 'baseline';

@Component({
	selector:    'app-peer-set-editor',
	templateUrl: './peer-set-editor.component.html',
	styleUrls:   ['./peer-set-editor.component.scss']
})
export class PeerSetEditorComponent implements OnInit, OnChanges {

	@ViewChild(DxValidatorComponent) dxValidator: DxValidatorComponent;

	@Input() peerSet: PeerSet = new PeerSet();
	public isDirty = false;

	public icons = {
		close:    faTimes,
		building: faBuilding,
		lock:     faLock,
	};

	public maxComparisonPeers   = maxComparisonPeers;
	public minComparisonPeers   = minComparisonPeers;
	public peerSetPlaceholders = [];

	highlightBaseline = false;
	highlightIncluded = false;

	constructor(
		private peerSetsService: PeerSetsService,
		private logger: LoggerService
	) {
	}

	ngOnInit() {

	}

	ngOnChanges(changes: SimpleChanges) {
		this.calculatePlaceholders();
	}

	/**
	 * Update the PeerSet name on change
	 *
	 * @param name
	 */
	peerSetNameChanged(name: string): void {
		this.peerSet.name = name;
		this.isDirty      = true;
	}

	/**
	 * Update the PeerSet description on change
	 *
	 * @param description
	 */
	peerSetDescriptionChanged(description: string): void {
		this.peerSet.description = description;
		this.isDirty             = true;
	}

	updatePeersFromSelector(peers: Array<Peer>) {
		// this.logger.log(`PeerSetEditor.updatePeersFromSelector`, peers);
		// In some scenarios, PeerSetSelector will return duplicated values. Remove these before setting.
		const unique = [];
		const distinct = [];
		for (let i = 0; i < peers.length; i++) {
			if (!unique[peers[i].mcag]) {
				distinct.push(peers[i]);
				unique[peers[i].mcag] = 1;
			}
		}
		// this.logger.log(`PeerSetEditor.updatePeersFromSelector after unique`, distinct);
		this.peerSet.peers = distinct;
		this.calculatePlaceholders();
	}

	/**
	 * Removes peer from list
	 *
	 * @param peer
	 */
	removePeerFromList(peer: Peer): void {
		this.peerSet.removePeer(peer);
		this.calculatePlaceholders();
	}

	/**
	 * Must have at least one comparison government.
	 */
	hasMinimumPeers = (): boolean => {
		return this.peerSet.peers?.filter(x => !x.prime)?.length >= minComparisonPeers;
	}

	calculatePlaceholders = () => this.peerSetPlaceholders = [...Array(Math.max(
		maxComparisonPeers - this.peerSet.peers.filter(x => !x.prime).length, 0
	)).keys()];

	canDropInArea(area: Area) {
		if (area === 'baseline') {
			return this.peerSet.peers.filter(x => x.prime).length === 0;
		}
		if (area === 'included') {
			return this.peerSet.peers.filter(x => !x.prime).length < 5;
		}

		this.logger.warn(`No area ${area} defined for canDropInArea`);
		return false;
	}

	// Drop onto a target area
	onPeerDrop(event: DndDropEvent, targetArea: Area) {
		this.removeDropAreaHighlights();
		if (!this.canDropInArea(targetArea)) {
			return false;
		}
		// this.peerSet.peers.splice(event.index, 0, event.data);
		const peer = this.peerSet.peers.find(x => x.mcag === event.data.mcag);
		this.logger.log(`The following peer was moved to target area ${targetArea}`, peer);
		peer.prime = targetArea === 'baseline';
		this.calculatePlaceholders();
		// Break array reference so that PeerSetSelector gets notified
		this.peerSet.peers = this.peerSet.peers.slice();
		return peer;
	}

	// Move from a source area (reorder, move to another area, etc)
	onPeerMoved(peer: Peer, sourceArea: Area) {
		this.logger.log(`The following peer was moved from source area ${sourceArea}`, peer);
	}

	highlightDropAreas(peer: Peer) {
		if (peer.prime && this.canDropInArea('included')) {
			this.highlightIncluded = true;
		}
		if (!peer.prime && this.canDropInArea('baseline')) {
			this.highlightBaseline = true;
		}
	}

	removeDropAreaHighlights() {
		this.highlightIncluded = false;
		this.highlightBaseline = false;
	}

	/**
	 * Verify if the user wants to dump their changes
	 */
	confirmClose(): Promise<any> {
		return new Promise<void>((resolve, reject) => {
			if (this.isDirty) {
				confirm('Are you sure you want to close the Peer Set Editor? All changes will be lost.', 'Close Peer Set Editor?').then(confirmed => {
					if (confirmed) {
						resolve();
					}
					else {
						reject();
					}
				});
			}
			else {
				resolve();
			}
		});
	}

}
