import {Component, HostListener, OnInit} from '@angular/core';
import {UserInterfaceService} from '../../shared/services/user-interface.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	isOverlayActive: boolean;
	isSnapshotLoading = false;
	innerWidth: any;

	constructor(
		private uiService: UserInterfaceService
	) {
		this.uiService.overlayActive.subscribe(value => this.isOverlayActive = value);
		this.uiService.isSnapshotLoading.subscribe(value => this.isSnapshotLoading = value);
	}

	ngOnInit() {
		this.innerWidth = window.innerWidth;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

}
