<div class="filters u-padding-horizontal">
	<div class="filters__filter">
		<!--
		<app-text-search
			[placeholder]="'Address or zip code'"
			(changed)="updateFilterName($event)"
			class="search-filter__input"
		></app-text-search>
		<div *ngIf="addressFeedback" class="u-margin-top-tiny u-tiny">{{ addressFeedback }}</div>
		-->
		<dx-autocomplete
			[(value)]="address"
			[dataSource]="addressAutocompleteSource"
			placeholder="Address or zip code"
			displayValue="place_name"
			searchExpr="place_name"
			valueExpr="place_name"
			itemTemplate="items"
			showClearButton="true"
			(onSelectionChanged)="handleAddressSelect($event)"
		>
			<div *dxTemplate="let item of 'items'">
				{{ item.place_name }}
			</div>
		</dx-autocomplete>
	</div>

	<div class="filters__filter filters__filter--with-hint">
		<dx-select-box
			placeholder="Select a government type"
			[dataSource]="governmentTypesDataSource"
			[value]="selectedGovernmentTypeCode"
			valueExpr="code"
			(onValueChanged)="governmentTypeChanged($event)"
			[showClearButton]="true"
			itemTemplate="govTypes"
			[displayExpr]="getGovTypeDisplay"
		>
			<div class="government-types" *dxTemplate="let item of 'govTypes'">
				<div class="label">
					<div class="description">
						<div>{{item.description}}</div>
					</div>
					<div class="activeCount">
						<div> {{item.activeCount}} Active</div>
					</div>
				</div>
				<div class="profile-btn">
					<button (click)="goToTypeProfile(item)">
						<wasao-hint [icon]="icons.extLink" showOn="hover">
							Go to profile for <br>{{ govTypesService.getPlural(item.description) }}
						</wasao-hint>
					</button>
				</div>
			</div>
		</dx-select-box>
	</div>
</div>

<div class="government-search__listings">
	<dx-data-grid
		[dataSource]="dataGridDataSource"
		(onSelectionChanged)="dataGridSelectionChanged($event.selectedRowsData[0])"
		hoverStateEnabled=false
		rowAlternationEnabled=true
		columnAutoWidth=true
		allowColumnResizing=true
		noDataText="Nothing matches the filters you have chosen. Please remove some filters and try again."
		wordWrapEnabled=true
		height="100%"
	>
		<dxo-scrolling mode="infinite"></dxo-scrolling>
		<dxo-paging pageSize=20></dxo-paging>
		<dxo-selection mode="single" allowSelectAll=false></dxo-selection>
		<dxi-column width="38" cellTemplate="cellTemplate"></dxi-column>
		<div *dxTemplate="let cell of 'cellTemplate'">
			<div class="dx-item dx-radiobutton">
				<div class="dx-radio-value-container">
					<div class="dx-radiobutton-icon">
						<div class="dx-radiobutton-icon-dot"></div>
					</div>
				</div>
			</div>
		</div>
		<dxi-column
			dataField="EntityName"
			[calculateDisplayValue]="getGovernmentDisplayName"
			caption="Name"
			sortOrder="asc"
			width="194"
		></dxi-column>
	</dx-data-grid>
	<div id="mapContainer" class="map-container">
		<wasao-map
			[mcag]="selectedMCAG"
			[govTypeCode]="selectedGovernmentTypeCode"
			[govClickAction]="govClickAction"
			[marker]="marker"
			autoSelect=true
			(governmentSelected)="mapGovernmentSelected($event)"
			(popupClosed)="mapPopupClosed()"
		></wasao-map>
	</div>
</div>

