import {Injectable} from '@angular/core';
import ArrayStore from "devextreme/data/array_store";

@Injectable({
	providedIn: 'root'
})
/**
 * Helper functions for common DevExtreme patterns.
 */
export class DevExtremeHelperService {

	constructor() {
	}

	/**
	 * Get a DataSource-like object for use with DxLookups (e.g. on a DxDataGrid column).
	 * Note that you can't type as DataSource because DxLookup can only accept a DataSource-like object (configuration)
	 * @param data
	 * @param key
	 * @param pageSize
	 * @see https://js.devexpress.com/Documentation/20_2/ApiReference/UI_Components/dxDataGrid/Configuration/columns/lookup/#dataSource
	 */
	makeLookupDataSource = (data: Array<any>, key: string, pageSize = 20): any => {
		return {
			store: new ArrayStore({
				key: key,
				data: data
			}),
			// turn on paging explicitly (does not get turned on by default by passing to DxLookup.dataSource)
			paginate: true,
			pageSize: pageSize
		};
	}
}
