import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

@Injectable({
	providedIn: 'root'
})
export class CellService {

	constructor(
		private logger: LoggerService
	) { }

	// Does fieldName match the target field in event?
	isField(event, fieldName: string) {
		const field = this.getFieldFromHeaderClick(event);
		return field !== null && field.name === fieldName;
	}

	// Does areaName match event.area?
	isArea = (event, areaName: string) => event.area === areaName;

	/**
	 * Get related field from onCellPrepared event.
	 * @param event onCellPrepared
	 * @param pivotGridDataSource
	 */
	getRelatedFieldForCell(event, pivotGridDataSource: PivotGridDataSource) {
		let field = null;
		// Only cells with paths can be resolved to a single field (header rows)
		if (event.cell.path) {
			// https://supportcenter.devexpress.com/ticket/details/t546536/dxpivotgrid-special-behaviour-for-row-column-fields
			const areaFields = pivotGridDataSource.getAreaFields(event.area, true);
			field = areaFields[event.cell.path.length - 1];
		}
		return field;
	}

	isBARSAccountField(fieldName: string): boolean {
		// Field names that belong to the Account context
		// 'standardSummary', 'debtCapitalExpSummary', -- removed in 10506
		const accounts = ['standardAccount', 'standardSubAccount', 'standardElement', 'standardSubElement',
			'debtCapitalExpAccount', 'debtCapitalExpSubAccount', 'debtCapitalExpElement', 'debtCapitalExpSubElement'];
		return accounts.includes(fieldName);
	}

	// Get the related field from a click event invoked on a row or column header.
	getFieldFromHeaderClick(event) {
		let field = null;
		if (event.cell.type === 'GT') {
			throw Error('Grand Total does not have an associated field.');
		}
		const index = event.cell?.path?.length - 1;
		switch (event.area) {
			case 'row':
				field = event.rowFields[index];
				break;
			case 'column':
				field = event.columnFields[index];
				break;
			case 'data':
				this.logger.warn(
					`CellService.getFieldFromHeaderClick: Cannot retrieve single header field for a data cell.
					Use getComprisingCellValues, instead.`
				);
				break;
		}

		return field;
	}

	// Gets clicked cell values associated with their respective Field objects
	getComprisingCellValues(event) {
		if (!this.isArea(event, 'data')) {
			this.logger.warn('CellService.getComprisingCellValues: Not a data area event.', event);
			return null;
		}

		const result = [];

		event.cell.columnPath.forEach(function (value, index) {
			result.push({
				field: event.columnFields[index],
				cellValue: value
			});
		});

		event.cell.rowPath.forEach(function (value, index) {
			result.push({
				field: event.rowFields[index],
				cellValue: value
			});
		});

		// data field
		result.push({
			field: event.dataFields[event.cell.dataIndex],
			cellValue: event.cell.value
		});

		return result;
	}

}
