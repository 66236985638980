import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserInterfaceService } from 'app/shared/services/user-interface.service';
import { Subscription } from 'rxjs';
import { UserService } from 'app/shared/services/user.service';
import { User, UserGroup, Claim, PortalSystemGroup } from 'app/shared/models/user';
import { LoggerService } from 'app/shared/services/logger.service';
import { DxSelectBoxModule } from 'devextreme-angular';
import { cloneDeep } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
	selector: 'app-test-user-harness',
	templateUrl: './user-harness.component.html',
	styleUrls: ['./user-harness.component.scss']
})
export class TestUserHarnessComponent implements OnInit, OnDestroy {
	private popupSubscription: Subscription;
	private userSubscription: Subscription;

	isVisible: boolean = true;
	roles = Object.keys(UserGroup).map(x => ({ text: UserGroup[x], value: x }));
	users: Array<User>;
	user: User;

	selectedUser: User;
	selectedUserId: number; // for selecting dropdown value in dx

	editUser: User;
	editUserRole: UserGroup;
	editUserMCAG: string;

	constructor(
		private uiService: UserInterfaceService,
		private userService: UserService,
		private logger: LoggerService,
		private http: HttpClient
	) {
		this.logger.log(this);
	}

	ngOnInit() {
		this.popupSubscription = this.uiService.testUserHarness$.subscribe(state => {
			this.isVisible = state;
			this.refreshUsers();
		});
		this.userSubscription = this.userService.user.subscribe(this.userUpdate);
	}

	ngOnDestroy() {
		this.popupSubscription.unsubscribe();
		this.userSubscription.unsubscribe();
	}

	refreshUsers = () => this.userService.getTestableUsers().then(users => this.users = users);

	userUpdate = (user: User) => {
		this.user = this.selectedUser = user;
		this.selectedUserId = user.id;
	}

	userDisplay = (user: User) => {
		if(!user) {
			return null;
		}
		let result = `id ${user.id}`;
		if(user.claim.contactID) {
			result += `: ${user.claim.fullName} (SubscriptionServices)`;
		}
		return result;
	}

	addUser() {
		this.editUser = new User();
		this.editUser.claim = new Claim();
		this.editUser.claim.portalSystemGroups = [];
	}

	editSelectedUser() {
		const user = this.users.find(x => x.id === this.selectedUserId);
		this.editUser = cloneDeep(user);
	}

	addRoleToEditUser = () => {
		const portalSystemGroup = new PortalSystemGroup();
		portalSystemGroup.saoportalSystemGroupName = this.editUserRole;
		portalSystemGroup.mcag = this.editUserMCAG;
		this.editUser.claim.portalSystemGroups.push(portalSystemGroup);
		this.editUserRole = null;
		this.editUserMCAG = null;
	}

	deleteEditUserRole = (portalSystemGroup: PortalSystemGroup) => {
		this.editUser.claim.portalSystemGroups = this.editUser.claim.portalSystemGroups.filter(x => {
			return x.saoportalSystemGroupName !== portalSystemGroup.saoportalSystemGroupName
				&& x.mcag !== portalSystemGroup.mcag
		});
	}

	saveEditUser() {
		this.userService.save(this.editUser, false);
		// send new post if editUser is also selectedUser
		if(this.editUser.id === this.selectedUser.id) {
			this.sendTestRoles();
		}
		this.editUser = null;
	}

	sendTestRoles = () => this.http.post(`${environment.base}/service/TestGroups`, this.user.claim.portalSystemGroups).subscribe();

	selectUser() {
		this.userService.setUser(this.selectedUserId).then(() => {
			this.uiService.testUserHarness$.next(false);
			this.uiService.requestApplicationFocus(this);
			this.sendTestRoles();
			this.uiService.releaseApplicationFocus();
		});
	}
}
