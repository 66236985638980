import {NgModule} from '@angular/core';
import {GovernmentTreasurersComponent} from './government-treasurers.component';
import {DxButtonModule, DxPieChartModule} from 'devextreme-angular';
import {LoadingModule} from '../../ui/loading/loading.module';
import {CommonModule} from '@angular/common';


@NgModule({
	declarations: [
		GovernmentTreasurersComponent
	],
	imports: [
		CommonModule,
		DxPieChartModule,
		DxButtonModule,
		LoadingModule,
	],
	exports: [
		GovernmentTreasurersComponent
	]
})
export class GovernmentTreasurersModule {
}
