import {AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {environment} from 'environments/environment';
import {DxPopupComponent} from 'devextreme-angular';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {Subscription} from 'rxjs';
import {Disclaimer} from '../../shared/models/disclaimer';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {UserService} from '../../shared/services/user.service';
import {LoggerService} from '../../shared/services/logger.service';
import {UtilityService} from '../../shared/services/utility.service';

@Component({
	selector:      'app-about',
	encapsulation: ViewEncapsulation.None,
	templateUrl:   './about.component.html',
	styleUrls:     ['./about.component.scss']
})
export class AboutComponent implements AfterViewInit, OnDestroy {

	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
	@ViewChild('content') content: TemplateRef<any>;
	private popupSubscription: Subscription;
	snapshot: any = null;
	snapshotInfoMessage: any;

	copyrightYear: number;
	appVersion: string;
	isVisible = true;

	public disclaimer = Disclaimer;

	constructor(
		private uiService: UserInterfaceService,
		private snapshotService: SnapshotService,
		private userService: UserService,
		private logger: LoggerService,
		private utility: UtilityService,
	) {
		this.popupSubscription = this.uiService.about$.subscribe(
			value => this.isVisible = value
		);

		this.copyrightYear = (new Date()).getFullYear();
		this.appVersion    = environment.version;
	}

	ngAfterViewInit () {
		this.snapshotService.getSnapshot().subscribe(snapshot => {
			if (snapshot) {
				this.snapshot = snapshot;
				this.snapshotInfoMessage = `The data in this snapshot includes annual reports from ${this.utility.yearsToRangeText(this.snapshot.detail?.includedYears)} and is structured according to the ${this.snapshot.barsYearUsed} BARS Manual.`;
			}
		});
	}

	ngOnDestroy() {
		this.popupSubscription.unsubscribe();
	}

	close = () => this.isVisible = false;

	showDisclaimer = () => this.uiService.showDisclaimer();

}
