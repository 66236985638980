import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { Tab } from '../models/tab';
import {environment} from 'environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {

	private customMap = {
		'dimension1': 'mcags',
		'dimension2': 'govTypes',
		'dimension3': 'locations',
		'dimension4': 'years',
		'dimension5': 'track',
		'dimension6': 'report',
		'dimension7': 'indicator',
		'dimension8': 'state',
		'dimension9': 'hasProjection'
	};
	gtag: any;

	constructor(
		private logger: LoggerService
	) {
		// @ts-ignore
		window.dataLayer = window.dataLayer || [];
		// @ts-ignore
		function gtag() { dataLayer.push(arguments); }
		// @ts-ignore
		gtag('js', new Date());
		// @ts-ignore
		gtag('config', environment.googleTrackingId, {
			// this allows us to ignore the initial page load, if desired
			// 'send_page_view': false,
			'custom_map': this.customMap
		});

		this.gtag = gtag;
	}

	sendEvent(event: string, tab: Tab) {
		const dims = this.collectDimensions(tab);
		this.gtag('event', event, dims);
	}

	private collectDimensions = (tab: Tab) => {
		const result = {};
		// iterate over every value of customMap and call a translation function, or pull from Tab model property
		for (const key in this.customMap) {
			if (this.customMap.hasOwnProperty(key)) {
				const element = this.customMap[key];
				const translationFunction = typeof this[element] === 'function' && this[element];
				if(translationFunction) {
					result[element] = translationFunction(tab);
				} else if(tab[element]) {
					result[element] = tab[element];
				}
			}
		}

		return result;
	}

	mcags = (tab: Tab) => {
		let result = null;

		// Do not log MCAGs for summary or ranking
		if (['c', 'd'].includes(tab && tab.track && tab.track.id)) {
			return null;
		}

		if (Array.isArray(tab.governments)) {
			result = tab.governments.map(v => v.mcag);
		}

		return result;
	}

	govTypes = (tab: Tab) => Array.isArray(tab.govTypes) && tab.govTypes.map(v => v.code);

	locations = (tab: Tab) => Array.isArray(tab.locations) && tab.locations.map(v => v.countyName);

	track = (tab: Tab) => tab.track && tab.track.id;

	report = (tab: Tab) => tab.report && tab.report.id;

	indicator = (tab: Tab) => tab.indicator && tab.indicator.code;

	hasProjection = (tab: Tab) => tab.pivotGridSettings?.projectionId ? 'True' : 'False';
}
