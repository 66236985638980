import {Injectable} from '@angular/core';
import {DataStories, DataStory, DataStoryId} from './data-story';
import {LoggerService} from '../../../shared/services/logger.service';

@Injectable({
	providedIn: 'root'
})
export class DataStoryService {

	constructor(
		private logger: LoggerService
	) {
	}

	getDataStory(id: DataStoryId): DataStory {
		const obj = DataStories.find(x => x.id === id);
		if (!obj) {
			this.logger.info(`DataStory not found for '${id}'. No button will be drawn for this scenario.`);
		}
		return obj;
	}
}
