import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GovernmentTypeTearSheetComponent} from './government-type-tear-sheet.component';
import {DxButtonModule, DxLoadIndicatorModule} from 'devextreme-angular';
import {BadgesModule} from '../badges/badges.module';
import {GovernmentInformationModule} from '../government-information/government-information.module';
import {FinancesAtAGlanceModule} from '../../financials/finances-at-a-glance/finances-at-a-glance.module';
import {GovernmentalServicesModule} from '../../financials/governmental-services/governmental-services.module';
import {EnterpriseServicesModule} from '../../financials/enterprise-services/enterprise-services.module';
import {GovernmentTypeInformationModule} from '../government-type-information/government-type-information.module';
import {
	FinancialHealthIndicatorStatisticsModule
} from '../../financials/financial-health-indicator-statistics/financial-health-indicator-statistics.module';

@NgModule({
	declarations: [GovernmentTypeTearSheetComponent],
	imports: [
		CommonModule,
		DxButtonModule,
		DxLoadIndicatorModule,
		BadgesModule,
		GovernmentInformationModule,
		FinancesAtAGlanceModule,
		GovernmentalServicesModule,
		EnterpriseServicesModule,
		GovernmentTypeInformationModule,
		FinancialHealthIndicatorStatisticsModule
	],
	exports: [
		GovernmentTypeTearSheetComponent
	]
})
export class GovernmentTypeTearSheetModule {
}
