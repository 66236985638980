import { Component } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { UserInterfaceService } from 'app/shared/services/user-interface.service';

@Component({
	selector: 'app-no-special-access',
	templateUrl: './no-special-access.component.html',
	styleUrls: ['./no-special-access.component.scss']
})
export class NoSpecialAccessComponent {
	isVisible = false;
	doNotShow = false;

	constructor(
		private userService: UserService,
		private uiService: UserInterfaceService
	) {
		this.uiService.noSpecialAccess.subscribe(value => this.isVisible = value);
	}

	closeDialog = () => {
		this.isVisible = false;
		this.userService.setDisableNoSpecialAccess(this.doNotShow);
	}

}
