<dx-button
	[disabled]="disabled"
	[hint]="hint"
	(click)="mode === 'add' ? launch() : remove()"
	class="button--nonoperative"
>
	<div *dxTemplate="let data of 'content'">
		<fa-icon [icon]="icon" class="dx-icon"></fa-icon>
		<ng-container *ngIf="mode === 'remove'">Remove Projection From Report</ng-container>
		<ng-container *ngIf="mode === 'add'">Add Projection</ng-container>
	</div>
</dx-button>
