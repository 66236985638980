import {FundCategoryId} from '../../../../services/fund-service/models/fund-category';

export type OSPIItemCategory = 'Revenue' | 'OtherFinancingSource' | 'Expenditure' | 'Liability';
export type OSPIItemType = 'Header' | 'Detail' | 'Total' | 'GrandTotal';

// Base class for all OSPI financial reports
export abstract class OSPIFinancialReportRow {
	fy: number;
	mcag: string;
	ccddd: string;
	reportNo: string;
	fundCode: string;
	itemNo: string;
	originalItemNo: string;
	multiYearSortOrder: number;
	fundCategoryId: FundCategoryId;
	itemType: OSPIItemType;
	amount: number;

	// Determine if ALL rows have the specified itemType. Note that this must return false for empty array.
	static allRecordsHaveItemType = (rows: Array<OSPIFinancialReportRow>, itemType: OSPIItemType): boolean =>
		rows?.length > 0 &&
		rows.every(x => x.itemType === itemType);
}
