import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

export class ExtractYear {
	constructor(
		public year: number,
		public url: string,
		public snapshotId: SnapshotId = null,
		public dateCreated: Date = null,
		public displayYear?: string
	) {
	}
}
