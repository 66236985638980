import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {Tab} from '../../shared/models/tab';
import {DataSourceService} from 'app/shared/services/data-source.service';
import {TabService} from 'app/shared/services/tab.service';
import {FilterBuilderService} from 'app/shared/services/filter-builder.service';
import DataSource from 'devextreme/data/data_source';
import {LoggerService} from 'app/shared/services/logger.service';
import {HistoryService} from '../../shared/services/history.service';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {DisabledGovernmentType} from '../../shared/components/government-types/disabled-government-type';

@Component({
	selector:    'app-government-search-name',
	templateUrl: './government-search-name.component.html',
	styleUrls:   ['./government-search-name.component.scss']
})
export class GovernmentSearchNameComponent implements OnInit, OnChanges {
	@Input() tab: Tab;
	@Input() snapshot;

	public store;
	public filter: any                         = {};
	public governmentTypes: any[];
	public gridSelectedGovTypes: Array<string> = [];

	constructor(
		private tabService: TabService,
		private dataSource: DataSourceService,
		private filterBuilder: FilterBuilderService,
		private logger: LoggerService,
		private historyService: HistoryService,
		private snapshotService: SnapshotService
	) {
	}

	ngOnInit() {
		this.tab.snapshotIdSubject.subscribe(
			(snapshotId) => {
				this.snapshotService.getSnapshot(snapshotId).subscribe(
					(snapshot) => {
						if (snapshot) {
							this.snapshot = snapshot;
						}
					}
				);
			}
		);
		this.evaluate();
	}

	ngOnChanges(changes: SimpleChanges) {
		const hasSnapshotChange = changes.snapshot
			&& changes.snapshot.currentValue
			&& changes.snapshot.currentValue.id !== (changes.snapshot.previousValue && changes.snapshot.previousValue.id);
		if (hasSnapshotChange) {
			this.evaluate();
		}
	}

	handleSelect = event => {
		this.historyService.replaceStateTabs();
		const selectedGovernment = {
			mcag:              event.mcag,
			entityNameWithDba: event.localGovernment.entityNameWithDba,
			govTypeCode:       event.localGovernment.govTypeCode
		};

		this.tabService.buildProfileTab(this.tab, {
			governments: [selectedGovernment],
			title:       selectedGovernment.entityNameWithDba,
			years:       [(this.snapshot.barsYearUsed - 2), this.snapshot.barsYearUsed]
		}, true).then(() => this.historyService.pushStateTabs());
	};

	updateFilterName(data: string) {
		if (!data) {
			Object.assign(this.filter, {name: '', mcags: []});
		}
		else {
			Object.assign(this.filter, {name: data});
		}
		this.evaluate();
	}

	updateFilterTypes(data) {
		Object.assign(this.filter, {govCodes: data});
		this.evaluate();
	}

	evaluate() {
		if (!this.snapshot || this.snapshot.id === 'live') { // components may trigger eval before snapshot is loaded & this component cannot use live data (no gov metrics)
			return;
		}

		this.filter.metricYear = this.snapshot.barsYearUsed;
		this.governmentTypes   = this.snapshot.detail.governmentTypes;

		const filterArray = [];

		if (this.filter.govCodes && this.filter.govCodes.length) {
			filterArray.push(this.filterBuilder.group('localGovernment/govTypeCode', this.filter.govCodes));
		}
		if (this.filter.name) {
			filterArray.push(['localGovernment/lookupNameWithDba', 'contains', this.filter.name]);
		}
		filterArray.push(['localGovernment/status', '=', 'Active']);
		filterArray.push(['year', '=', this.filter.metricYear]);

		if (filterArray.length === 0) {
			return;
		}

		this.store = new DataSource({
			store:  this.dataSource.getStore(
				this.snapshot.id,
				'GovernmentMetrics',
				{$expand: 'localGovernment'}
			),
			filter: filterArray
		});
	}

}
