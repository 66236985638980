import {Component} from '@angular/core';
import {PeerSetsService} from '../../shared/services/peer-sets.service';
import {PeerSet} from '../../shared/models/peer-set';
import {faBuilding, faCopy, faEdit, faLock, faTrash} from '@fortawesome/free-solid-svg-icons';
import {confirm} from 'devextreme/ui/dialog';
import {TabBarItem} from '../../shared/components/tab-bar/tab-bar.component';
import {LoggerService} from '../../shared/services/logger.service';

@Component({
	selector:    'app-peer-set-viewer',
	templateUrl: './peer-set-viewer.component.html',
	styleUrls:   ['./peer-set-viewer.component.scss']
})
export class PeerSetViewerComponent {

	public isVisible: boolean;
	isSelectMode: boolean;
	public myPeerSets: Array<PeerSet> = [];
	currentPeerSet: PeerSet;
	tabBarItems: Array<TabBarItem> = [
		{ id: 'existing', label: 'Existing Peer Sets' },
		{ id: 'create', label: 'Create Peer Set' },
		{ id: 'edit', label: 'Edit Peer Set', visible: false }
	];
	selectedTabBarId: string;

	public icons = {
		building: faBuilding,
		lock:     faLock,
		edit: faEdit,
		delete: faTrash,
		copy: faCopy
	};

	constructor(
		public peerSetsService: PeerSetsService,
		private logger: LoggerService
	) {
		this.peerSetsService.peerSetViewerVisibility.subscribe(value => {
			this.isVisible = value;
			this.reset();
		});
		this.peerSetsService.isSelectMode.subscribe(x => this.isSelectMode = x);
		this.peerSetsService.peerSets.subscribe(peerSets => {
			this.myPeerSets = peerSets;
		});
		this.peerSetsService.create.subscribe(peers => {
			this.peerSetsService.peerSetViewerVisibility.next(true);
			this.edit(Object.assign(new PeerSet, { peers }));
		});
		this.reset();
	}

	reset() {
		this.selectedTabBarId = 'existing';
		this.currentPeerSet = null;
	}

	confirmDelete(peerSet: PeerSet) {
		confirm('Are you sure you want to delete this peer set?', 'Delete Peer Set').then(confirmed => {
			if (confirmed) {
				this.peerSetsService.delete(peerSet);
			}
		});
	}

	handleTabBarSelect(tabBarId: string) {
		this.selectedTabBarId = tabBarId;
		if (tabBarId === 'existing') {
			this.setCreateEditTabBar('create');
		}
		if (tabBarId === 'create') {
			this.currentPeerSet = new PeerSet();
		}
	}

	edit(peerSet: PeerSet) {
		this.setCreateEditTabBar('edit');
		this.selectedTabBarId = 'edit';
		this.currentPeerSet = Object.assign(new PeerSet, peerSet);
	}

	create() {
		this.setCreateEditTabBar('create');
		this.selectedTabBarId = 'create';
		this.currentPeerSet = new PeerSet();
	}

	copy(peerSet: PeerSet) {
		this.setCreateEditTabBar('create');
		this.selectedTabBarId = 'create';
		const newPeerSet = Object.assign(new PeerSet, peerSet);
		delete newPeerSet.id;
		this.currentPeerSet = newPeerSet;
	}

	select(peerSet: PeerSet) {
		this.peerSetsService.selection.next(peerSet);
	}

	setCreateEditTabBar(which: 'edit' | 'create') {
		const other = which === 'edit' ? 'create' : 'edit';
		this.tabBarItems.find(x => x.id === which).visible = true;
		this.tabBarItems.find(x => x.id === other).visible = false;
	}

	submit(event: Event): void {
		event.preventDefault();
		this.savePeerSet();
	}

	/**
	 * Save peer set (either create a new one or update an existing one)
	 * This is pretty fragile as it assumes the currentPeerSet reference is unbroken.
	 */
	savePeerSet(): void {
		if (this.currentPeerSet?.id != null) {
			this.peerSetsService.save(this.currentPeerSet);
		} else {
			this.peerSetsService.add(this.currentPeerSet);
		}

		this.setCreateEditTabBar('create');
		// open viewer and reset
		this.peerSetsService.openViewer();
	}

}
