import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {Subscription} from 'rxjs';
import {TabService} from '../../services/tab.service';
import {DisabledGovernmentType} from '../government-types/disabled-government-type';

/**
 * Boy, is this named incorrectly. This is really Track C filters.
 */
@Component({
	selector:    'app-government-type-chooser',
	templateUrl: './government-type-chooser.component.html',
	styleUrls:   ['./government-type-chooser.component.scss']
})
export class GovernmentTypeChooserComponent implements OnInit {
	@Input() govtTypeLabel: string;
	@Input() locationLabel: string;
	@Output() submitted = new EventEmitter<any>();
	@Output() back      = new EventEmitter<any>();

	snapshot: any;
	selection = {};
	isLoading: boolean;
	locationSelections: Array<any>;
	disabledGovTypes: Array<DisabledGovernmentType> = [];

	constructor(
		private snapshotService: SnapshotService,
		public tab: TabService,
	) { }

	ngOnInit() {
		this.isLoading = true;
		// Only use latest snapshot data to drive wizard
		this.snapshotService.getSnapshot().subscribe(snapshot => {
			this.snapshot = snapshot;
			this.isLoading = false;
			// Get the governments that can't have financials to pass to GovernmentTypesComponent with the reason
			// they cannot be selected there.
			this.disabledGovTypes = this.snapshot.detail.governmentTypes.reduce((acc, govType) => {
				if (govType.canHaveFinancials === false || govType.financialsDatasetSource === 'OSPI') {
					acc.push({ code: govType.code, reason: 'No financial data is available for this government type' });
				}
				return acc;
			}, this.disabledGovTypes);
		});
	}

	updateFilterTypes(govTypeCodes) {
		if (govTypeCodes === null) {
			Object.assign(this.selection, {govTypeCodes: []});
			return;
		}
		Object.assign(this.selection, {govTypeCodes});
	}

	updateFilterLocations(data) {
		this.locationSelections = data;
		Object.assign(this.selection, {locationCodes: data});
	}

	updateFromMap(data) {
		this.locationSelections = data;
		Object.assign(this.selection, {locationCodes: data});
	}

	handleSubmit() {
		this.submitted.emit(this.selection);
	}

	handleBack(event) {
		this.back.emit(event);
	}

}
