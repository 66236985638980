import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DxPopupComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import {UserService} from '../../shared/services/user.service';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {LoggerService} from '../../shared/services/logger.service';
import {UtilityService} from '../../shared/services/utility.service';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { User } from 'app/shared/models/user';
import { take } from 'rxjs/operators';

@Component({
	selector:    'app-login',
	templateUrl: './login.component.html',
	styleUrls:   ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {

	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
	@ViewChild('content') content: TemplateRef<any>;
	private popupSubscription: Subscription;

	isVisible = false;
	icons     = {
		infoCircle: faInfoCircle
	};

	constructor(
		private userService: UserService,
		private uiService: UserInterfaceService,
		private logger: LoggerService,
		private utility: UtilityService,
	) {
		this.popupSubscription = this.uiService.login.subscribe(value => this.isVisible = value);
		this.userService.user.pipe(take(1)).subscribe(this.onUserUpdate);
	}

	ngOnDestroy() {
		this.popupSubscription.unsubscribe();
	}

	onUserUpdate = (user: User) => {
		
	}

	continueAsGuest = () => this.dismiss();

	login() {
		this.dismiss();
		this.userService.login();
	}

	dismiss = () => this.isVisible = false;

}
