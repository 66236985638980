import { Injectable } from '@angular/core';
import {AccountingCategoryServiceModule} from './accounting-category-service.module';

@Injectable({
  providedIn: AccountingCategoryServiceModule
})
export class AccountingCategoryService {

	public readonly allowedSections = [
		10, // Beginning Balances
		20, // Revenues
		25, // Other Increases
		30, // Expenditures
		35, // Other Decreases
		40 // Ending Balances
	];

  constructor() { }
}
