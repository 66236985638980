import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dxFormat'
})
export class DxFormatPipe implements PipeTransform {
	transform(name?: string): { type: string, precision: number } {
		const base = { type: 'fixedPoint', precision: 0 };

		switch (name) {
			case 'currency':
				return Object.assign(base, { type: 'currency' });
			case 'currencyK':
				return Object.assign(base, { type: 'currency thousands' });
			case 'currencyM':
				return Object.assign(base, { type: 'currency thousands' });
			default:
				return base;
		}
	}

}
