<dx-popup
	class="account-settings-popup"
	width="50%"
	height="auto"
	[dragEnabled]="false"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	title="About FIT">
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<p class="about__content">
					The Office of the Washington State Auditor has developed the Financial Intelligence Tool (FIT) to provide financial transparency into local governments within Washington. FIT is an easy to use tool that provides access to data filed by the local governments annually with the State Auditor.
				</p>

				<div *ngFor="let limitation of disclaimer.limitations">
					<p>
						{{ limitation.paragraph }}
					</p>
					<ul *ngIf="limitation.list">
						<li *ngFor="let item of limitation.list">
							{{ item }}
						</li>
					</ul>
				</div>

				<p *ngIf="!snapshot">
					Loading snapshot details...
				</p>

				<p *ngIf="snapshot">
					{{ snapshotInfoMessage }}
				</p>

				<dx-button
					text="Data sources and limitations"
					(onClick)="showDisclaimer()"
					class="u-margin-bottom"
				></dx-button>

				<p class="about__build">
					&copy;2018-{{ copyrightYear }} Office of the Washington State Auditor. All Rights Reserved. Software build: {{ appVersion }}
				</p>
			</div>

			<div class="app-popup__footer">
				<dx-button
					text="Close"
					type="default"
					(onClick)="close()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
