<dx-tag-box
	class="locations"
	[dataSource]="store"
	[value]="_selections"
	(onSelectionChanged)="handleSelectionChanged($event)"
	[width]="width || 'auto'"
	(onOpened)="onOpened($event)"
	(onContentReady)="onContentReady()"
	applyValueMode=instantly
	searchEnabled=true
	placeholder='Choose from a list of counties...'
	valueExpr='countyCode'
	displayExpr='countyName'
	showClearButton=true
	showSelectionControls=true
></dx-tag-box>
