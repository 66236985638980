import {Input, SimpleChanges, Directive, Injectable} from '@angular/core';
import {Reusable} from './reusable';
import {GovernmentSpecificity} from './government-specificity';
import {LoggerService} from '../../../shared/services/logger.service';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {of} from 'rxjs';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {FitApiModule} from '../../api/fit-api/fit-api.module';
import {map} from 'rxjs/operators';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

/**
 * Supplies all members of Reusable plus govTypeCode and snapshotId Inputs. Either the govTypeCode or the mcag
 * input can be provided, but not both.  For
 */
@Directive()
export class ReusableGovernmentAndTypeInputs extends Reusable {
	@Input() govTypeCode: string;
	@Input() mcag: string;
	/**
	 * When using the mcag input, specify the year to use to display this component. Otherwise, will use default display year logic
	 * based on the snapshot filing year, the government's filing status, and the government's filing due date.
	 * This input is not used with the govTypeCode input.
	 */
	@Input() displayYear: number;
	@Input() snapshotId: SnapshotId;

	/**
	 * Allows parent to inform children whether beta features should be shown.
	 */
	@Input() showBeta: boolean;

	constructor(
		protected filingStatus: FilingStatusService,
		protected fitApi: FitApiService,
		protected logger: LoggerService
	) {
		super();
	}

	/**
	 * Calls the specified function, defaulting to government context, if both govTypeCode and mcag passed as inputs to the component.
	 *   todo this function should not take filingStatus and logger, but should be constructed within this class or
	 *   perhaps concretely referenced, especially since govType now requires FitApiService to get barsYearUsed
	 * @param changes
	 * @param func
	 * @param filingStatus
	 * @param logger
	 */
	callFunctionWithSpecificity(
		changes: SimpleChanges,
		func: (specificity: GovernmentSpecificity) => any
	) {
		// Can't do both govType and mcag at the same time
		if (this.mcag && this.govTypeCode) {
			this.logger.warn(`${this.constructor.name} received both mcag (${this.mcag}) and govTypeCode (${this.govTypeCode}). Using mcag.`);
		}

		if (this.mcag) {
			// If displayYear is provided then use it to build the specificity object, otherwise get the default filing year to display for the mcag and snapshot provided
			const _displayYear = this.displayYear ? of(this.displayYear) : this.filingStatus.getFilingYearForDisplay(this.mcag, this.snapshotId);
			_displayYear.subscribe(y => func({ type: 'government', id: this.mcag, year: y }));
		} else if (this.govTypeCode) {
			// todo this should query for barsYearUsed for the snapshot
			const _displayYear = this.displayYear ? of (this.displayYear) : this.fitApi.getAnnualFilingSnapshot(this.snapshotId).pipe(map(result => result.barsYearUsed));
			_displayYear.subscribe(y => {
				func({ type: 'governmentType', id: this.govTypeCode, year: y });
			});
		}
	}
}
