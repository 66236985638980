import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../modal.service';
import { ModalOption } from '../modal';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

	title: string;
	content: string;
	hideQuestion: boolean;
	width: number | 'auto';
	options: Array<ModalOption>;
	isVisible: boolean;

	constructor(private service: ModalService) { }

	ngOnInit() {
		this.service.modal.subscribe(modal => {
			this.title = modal.title;
			this.content = modal.content;
			this.hideQuestion = modal.hideQuestion;
			this.options = modal.options;
			this.width = modal.width || 'auto';
		})
		this.service.isVisible.subscribe(value => this.isVisible = value);
	}

	onConfirm = (event: object, option: ModalOption) => this.service.confirm(option);

}
