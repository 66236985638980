import {Component, ViewChild, TemplateRef} from '@angular/core';
import {UserService} from 'app/shared/services/user.service';
import {UserInterfaceService} from 'app/shared/services/user-interface.service';
import {DxPopupComponent} from 'devextreme-angular';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {Disclaimer} from '../../shared/models/disclaimer';

@Component({
	selector:    'app-disclaimer',
	templateUrl: './disclaimer.component.html',
	styleUrls:   ['./disclaimer.component.scss']
})
export class DisclaimerComponent {
	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
	@ViewChild('content', { static: true }) content: TemplateRef<any>;

	isVisible = false;

	public disclaimer = Disclaimer;

	constructor(
		private userService: UserService,
		private snapshotService: SnapshotService,
		private uiService: UserInterfaceService
	) {
		this.uiService.disclaimer.subscribe(value => this.isVisible = value);
	}

	closeDisclaimer() {
		this.isVisible = false;
	}

}
