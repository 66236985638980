import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'unique'
})
export class UniquePipe implements PipeTransform {

	/**
     * Removes duplicate array items, optionally taking a key to check against
     */
	transform(arr: Array<any>, key?: string): any {
		return arr.filter((value, index, self) => {
			return key
				? self.findIndex(v => v[key] === value[key]) === index
				: self.indexOf(value) === index;
		});
	}

}
