import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnnualFilingExplanationComponent} from './annual-filing-explanation.component';

@NgModule({
	declarations: [
		AnnualFilingExplanationComponent
	],
	imports: [
		CommonModule,
	],
	exports: [
		AnnualFilingExplanationComponent
	]
})
export class AnnualFilingExplanationModule {
}
