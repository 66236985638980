import {Directive, HostListener, ElementRef, AfterViewInit, Renderer2, Inject, OnInit, OnDestroy} from '@angular/core';
import {LoggerService} from '../services/logger.service';
import {DOCUMENT} from '@angular/common';

// Styles located in src/scss/_directives.scss
@Directive({
	selector: '[appScrollableShadow]'
})
export class ScrollableShadowDirective implements AfterViewInit, OnInit {
	hasMoreContent = {
		above: false,
		below: false
	};

	private overlay: HTMLElement;
	private target: HTMLElement;

	constructor(
		private logger: LoggerService,
		private el: ElementRef,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document
	) {
		this.target = this.el.nativeElement;
		this.target.classList.add('more-below');
	}

	/**
	 * Create extra overlay element
	 */
	ngOnInit() {
		this.overlay = document.createElement('div');
		this.renderer.appendChild(this.target, this.overlay);
	}

	/**
	 * First calculation (not necessarily safe, since elements can be off
	 * screen, giving our CSS calculations bad values)
	 */
	ngAfterViewInit(): any {
		this.calculateTopAndBottomShadows(this.target);
		this.overlay.classList.add('appScrollableShadow-overlay', 'more-below');
	}

	/**
	 * Scroll event
	 *
	 * @param event
	 */
	@HostListener('window:resize', ['$event'])
	@HostListener('scroll', ['$event'])
	private onContentScroll(event: MouseEvent): void {
		const target = <HTMLElement>event.target;
		// this.logger.log('scroll ', this.target.offsetHeight, this.target.scrollHeight);
		this.calculateTopAndBottomShadows(target);
	}

	/**
	 * Calculates whether or not to show top and bottom shadows
	 * and adds the class to show them accordingly
	 *
	 * @param source
	 */
	private calculateTopAndBottomShadows(source: HTMLElement) {
		// this.logger.log({
		// 	element:      source,
		// 	scrollHeight: source.scrollHeight,
		// 	offsetHeight: source.offsetHeight,
		// 	scrollTop:    source.scrollTop
		// });

		this.hasMoreContent.above = source.scrollTop > 0;
		// take into account possible pixel rounding error (-1px)
		this.hasMoreContent.below = (source.scrollHeight - source.offsetHeight - 1) >= source.scrollTop;

		this.overlay.style.transform = `translate3d(0, ${source.scrollTop}px, 0)`;

		this.hasMoreContent.above
			? this.overlay.classList.add('more-above')
			: this.overlay.classList.remove('more-above');

		this.hasMoreContent.below
			? this.overlay.classList.add('more-below')
			: this.overlay.classList.remove('more-below');
	}

}
