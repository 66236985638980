<dx-popup
	maxHeight="90%"
	[dragEnabled]="true"
	[closeOnOutsideClick]="true"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	title="BARS Rich Text Demo">

	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<dx-data-grid
					[dataSource]="accountDescriptorsDataSource"
					height="100%"
					[columnAutoWidth]="false"
					[allowColumnResizing]="true"
					[rowAlternationEnabled]="true"
					[columnMinWidth]="50"
					[showBorders]="true"
				>
					<dxo-scrolling mode="infinite" showScrollbar="always"></dxo-scrolling>
					<dxo-header-filter visible=true allowSearch=true></dxo-header-filter>
					<dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
					<div *dxTemplate="let district of 'detail'" class="template-class">
						<span [innerHtml]="district.data.description"></span>
					</div>
					<dxi-column
						name="id"
						dataField="id"
						width="10"
						dataType="number"
						fixed="true"
						[allowHeaderFiltering]="false"
					></dxi-column>
					<dxi-column
						name="categoryDisplay"
						dataField="categoryDisplay"
						dataType="string"
						width="100"
						fixed="true"
						[allowHeaderFiltering]="false"
					></dxi-column>
					<dxi-column
						name="name"
						dataField="name"
						dataType="string"
						width="250"
						fixed="true"
						[allowHeaderFiltering]="false"
					></dxi-column>
					<dxi-column
						name="description"
						dataField="description"
						dataType="string"
						[allowHeaderFiltering]="false"
						fixed="true"
					></dxi-column>
					<dxi-column
						caption="Has Embedded Html"
						width="100"
						fixed="true"
						allowSorting="true"
						allowFiltering="true"
						[calculateCellValue]="hasHtml"
						[filterValues]="[true]"
					></dxi-column>
				</dx-data-grid>
			</div>
			<div class="app-popup__footer">
				<dx-button
					text="Close"
					type="default"
					(onClick)="close()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>




