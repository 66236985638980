<ng-container *ngIf="!isLoading && data">
<div class="metrics">
	<label>{{ year }}</label>
	<div class="metric">
		<span class="large-number large-number--color-accent-teal">
			{{ data.TotalCompletedAudit || 0 }}
		</span>

		Audits Completed
	</div>

	<div class="metric">
		<span class="large-number large-number--color-status-projection">
			{{ data.TotalInProgressAudit || 0 }}
		</span>

		Audits in Progress
	</div>
</div>
</ng-container>
