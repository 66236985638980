import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FilterBuilderService {

	constructor() { }

	/**
	 * Returns an expression array grouped by operation
	 */
	groupOperationByObjectKey(object: any, operation: 'and' | 'or' = 'and'): any[] {
		const self = this;
		const result = [];

		if (!object) {
			return result;
		}

		Object.keys(object).forEach(function (key) {
			const target = object[key]; // array or value
			// Do not process null, undefined or empty arrays
			if (!target || (Array.isArray(target) && !target.length)) {
				return;
			}

			if (Array.isArray(target)) {
				result.push(self.group(key, object[key]));
				result.push(operation);
			} else {
				result.push(self.single(key, object[key]), operation);
			}
		});
		result.pop(); // remove the trailing operation

		// Return null over an empty array
		return result.length === 0 ? null : result;
	}

	/**
	 * Returns an expression array grouped by operation
	 */
	group(key: string, values: any[], operation = 'or'): any[] {
		// $log.debug('FilterBuilder.groupOperationByObjectKey', [key, values, operation]);
		const self = this;
		const result = [];

		for (let i = 0; i < values.length; i++) {
			if (i !== 0) {
				result.push(operation);
			}
			result.push(self.single(key, values[i]));
		}

		return result;
	}

	/**
	 * Returns an array in [key, operation, value] format
	 */
	single(key: string, values: any, operation = '='): [string, string, any[]] {
		return [key, operation, values];
	}
}
