<div
	#container
	class="schedule-browser__narrative"
	[class.schedule-browser__narrative--active]="summary && summary.length > 0"
	[class.schedule-browser__narrative--collapsed]="!isExpanded"
	[class.schedule-browser__narrative--expanded]="isExpanded"
>
	<div class="schedule-browser__narrative__content" (resized)="onContentResize($event)">{{ summary }}</div>
	<div
		class="schedule-browser__narrative__more"
		*ngIf="isOverflowing"
		(click)="toggleExpanded()"
	>
		<span *ngIf="isExpanded">(show less)</span>
		<span *ngIf="!isExpanded">(show more)</span>
	</div>
</div>

