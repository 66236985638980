<dx-menu
	#menu
	[items]="menuItems"
	displayExpr="name"
	orientation="horizontal"
	submenuDirection="auto"
	[hideSubmenuOnMouseLeave]="false"
	(onItemClick)="itemClick($event)"
	class="user-menu"
></dx-menu>
