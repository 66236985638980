import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndicatorsModule} from '../../financials/indicators/indicators.module';
import {GovernmentInformationModule} from '../government-information/government-information.module';
import {FinancesAtAGlanceModule} from '../../financials/finances-at-a-glance/finances-at-a-glance.module';
import {GovernmentTearSheetComponent} from './government-tear-sheet.component';
import {BadgesModule} from '../badges/badges.module';
import {DxButtonModule, DxLoadIndicatorModule} from 'devextreme-angular';
import {GovernmentalServicesModule} from '../../financials/governmental-services/governmental-services.module';
import {EnterpriseServicesModule} from '../../financials/enterprise-services/enterprise-services.module';

@NgModule({
	declarations: [
		GovernmentTearSheetComponent
	],
	imports: [
		CommonModule,
		DxLoadIndicatorModule,
		DxButtonModule,
		BadgesModule,
		GovernmentInformationModule,
		FinancesAtAGlanceModule,
		IndicatorsModule,
		GovernmentalServicesModule,
		EnterpriseServicesModule,
		IndicatorsModule
	],
	exports: [
		GovernmentTearSheetComponent
	]
})
export class GovernmentTearSheetModule {
}
