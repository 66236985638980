import { Component, OnInit, ViewChild } from '@angular/core';
import { Toast } from 'app/shared/models/toast';
import { UserInterfaceService } from 'app/shared/services/user-interface.service';
import { DxToastComponent } from 'devextreme-angular';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

	@ViewChild(DxToastComponent, { static: true }) dxToast: DxToastComponent;
	toast = new Toast();

	constructor(private uiService: UserInterfaceService) {
		this.uiService.toast.subscribe(toast => {
			this.dxToast.instance.option('type', toast.type);
			this.dxToast.instance.option('message', toast.message);
			this.dxToast.instance.option('displayTime', toast.displayTime);
			this.dxToast.instance.show();
		});
	}

	ngOnInit() {
	}

}
