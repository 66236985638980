import { Injectable } from '@angular/core';
import { Field } from 'app/shared/models/field';
import { TrackBase } from 'app/shared/services/tracks/track-interface';
import { UserService } from '../user.service';
import { FormatService } from '../format.service';
import { SortService } from '../sort.service';
import { FieldService } from '../field.service';

@Injectable({
	providedIn: 'root'
})
export class TrackDService implements TrackBase {

	constructor(
		private user: UserService,
		private format: FormatService,
		private sort: SortService,
		private field: FieldService
	) {
		this.schedule01CategoryTotals = this.schedule01CategoryTotals.bind(this);
		this.schedule09CategoryTotals = this.schedule09CategoryTotals.bind(this);
	}

	presets() {
		return [];
	}

	schedule01CategoryTotals(tab): Array<Field> {
		const self = this;
		const reportFields = [
			new Field({
				id: 'year',
				name: 'year',
				dataField: 'year',
				caption: 'Year',
				sortingMethod: self.sort.byString,
				groupingConfig: {
					expanded: true,
					required: true,
					column: {
						defaultOrder: 0
					}
				}
			}),
			new Field({
				id: 'government',
				name: 'government',
				dataField: 'mcag',
				caption: 'Government',
				sortBySummaryField: 'measure',
				sortBySummaryPath: [tab.years[1]],
				sortOrder: 'desc',
				groupingConfig: {
					required: true,
					row: {
						defaultOrder: 0
					}
				},
				customizeText: function (data) {
					return self.format.getGovernmentText(data, tab);
				},
				sortingMethod: function (a, b) {
					return self.sort.sortGovernment(a, b);
				}
			}),
			new Field({
				id: 'governmentType',
				name: 'governmentType',
				dataField: 'govTypeCode',
				caption: 'Government Type',
				width: 150,
				groupingConfig: {
					row: {}
				},
				customizeText: function (data) {
					return self.format.getNameForGovtTypeCode(data, tab);
				}
			}),
			new Field({
				id: 'fundType',
				name: 'fundType',
				dataField: 'fundTypeId',
				caption: 'Fund Type',
				groupingConfig: {
					column: {
						defaultOrder: 2
					}
				},
				transitions: {
					filterValues: function (currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				sortingMethod: function (a, b) {
					return self.sort.sortFundTypes(a, b, tab.snapshotId);
				},
				customizeText: function (data) {
					return self.format.getFundTypeText(data, tab.snapshotId);
				}
			}),
			new Field({
				id: 'fundCategory',
				name: 'fundCategory',
				dataField: 'fundCategoryId',
				caption: 'Fund Category',
				groupingConfig: {
					column: {
						defaultOrder: 1
					}
				},
				transitions: {
					filterValues: function (currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				customizeText: function (data) {
					return self.format.getFundCategoryText(data, tab.snapshotId);
				}
			})
		];

		return self.field.getAnnotationsFields()
			.concat(reportFields)
			.concat(self.field.expenditureObjectFilterField())
			.concat(self.field.getFundGroupFilterFields())
			.concat(self.field.getMeasureField(tab))
			.concat(self.field.getComparisonField(tab));
	}

	schedule09CategoryTotals(tab): Array<Field> {
		const self = this;
		const reportFields = [
			new Field({
				id: 'year',
				name: 'year',
				dataField: 'year',
				caption: 'Year',
				sortingMethod: self.sort.byString,
				groupingConfig: {
					expanded: true,
					column: {
						defaultOrder: 0
					}
				}
			}),
			new Field({
				id: 'government',
				name: 'government',
				dataField: 'mcag',
				caption: 'Government',
				sortBySummaryField: 'measure',
				sortBySummaryPath: [tab.years[1]],
				sortOrder: 'desc',
				groupingConfig: {
					required: true,
					row: {
						defaultOrder: 5
					}
				},
				customizeText: function (data) {
					return self.format.getGovernmentText(data, tab);
				},
				sortingMethod: function (a, b) {
					return self.sort.sortGovernment(a, b);
				}
			}),
			new Field({
				id: 'governmentType',
				name: 'governmentType',
				dataField: 'govTypeCode',
				caption: 'Government Type',
				width: 150,
				groupingConfig: {
					row: {}
				},
				customizeText: function (data) {
					return self.format.getNameForGovtTypeCode(data, tab);
				}
			}),
		];

		return self.field.getAnnotationsFields()
			.concat(reportFields)
			.concat(self.field.getFundGroupFilterFields())
			.concat(self.field.getMeasureField(tab))
			.concat(self.field.getComparisonField(tab));
	}

}
