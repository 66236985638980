import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideDrawerComponent} from './side-drawer.component';
import {DxButtonModule, DxPopupModule, DxScrollViewModule} from 'devextreme-angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
	declarations: [
		SideDrawerComponent
	],
	imports:      [
		CommonModule,
		DxPopupModule,
		DxButtonModule,
		FontAwesomeModule,
		DxScrollViewModule,
	],
	exports:      [
		SideDrawerComponent
	]
})
export class SideDrawerModule {
}
