import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnnualFilingSummaryComponent} from './annual-filing-summary.component';
import {HintModule} from '../../ui/hint/hint.module';
import {LoadingModule} from '../../ui/loading/loading.module';
import {BadgesModule} from '../../info/badges/badges.module';
import {DataStoryModule} from '../../info/data-story/data-story.module';


@NgModule({
	declarations: [
		AnnualFilingSummaryComponent
	],
	imports: [
		CommonModule,
		HintModule,
		LoadingModule,
		BadgesModule,
		DataStoryModule
	],
	exports: [
		AnnualFilingSummaryComponent
	]
})
export class AnnualFilingSummaryModule {
}
