import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilingBasisPickerComponent} from './filing-basis-picker.component';
import {DxLoadPanelModule, DxSelectBoxModule} from 'devextreme-angular';


@NgModule({
	declarations: [
		FilingBasisPickerComponent
	],
	imports: [
		CommonModule,
		DxSelectBoxModule,
		DxLoadPanelModule
	],
	exports: [
		FilingBasisPickerComponent
	]
})
export class FilingBasisPickerModule {
}
