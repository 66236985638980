import {Component, OnInit, Input, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import { faInfo, IconDefinition, faQuestionCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { HintInformation, HintInformationType } from 'app/shared/models/hint-information';
import { UserService } from 'app/shared/services/user.service';
import {Disclaimer} from '../../../shared/models/disclaimer';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
	selector: 'wasao-hint',
	templateUrl: './hint.component.html',
	styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit, OnDestroy {
	@Input() text: string;
	@Input() icon: 'question' | 'info' | IconDefinition;
	@Input() position: 'top' | 'bottom' | 'left' | 'right';
	@Input() showOn: 'click' | 'hover' = 'click';
	/**
	 * CSS color for the icon
	 */
	@Input() iconColor = 'inherit';
	/**
	 * Supply a key from HintInformation const
	 */
	@Input() hintInformation: string;

	@ViewChild('target', { static: true }) target: ElementRef;
	iconDefinitions = {
		question: faQuestionCircle,
		info: faInfo,
		infoCircle: faInfoCircle,
	};
	iconDefinition: IconDefinition;
	hintText: string;

	destroy = new Subject<void>();

	private disclaimer = Disclaimer;

	constructor(
		private userService: UserService
	) { }

	ngOnInit() {
		this.position = this.position || 'right';

		if (this.icon === undefined && this.text === undefined) {
			// If no icon or text is specified, default to questionmark icon
			this.iconDefinition = faQuestionCircle;
		} else if (this.icon && typeof this.icon === 'string') {
			// if icon is not valid, default to questionmark icon
			this.iconDefinition = this.iconDefinitions[this.icon] || faQuestionCircle;
		} else if (this.icon) {
			// if icon is not valid, default to questionmark icon
			this.iconDefinition = this.icon as IconDefinition || faQuestionCircle;
		}
		if (this.hintInformation && HintInformation.hasOwnProperty(this.hintInformation)) {
			this.userService.user.pipe(takeUntil(this.destroy)).subscribe(
				(user) => {
					const lookupHintText = user.showTechnical
						? HintInformation[this.hintInformation][HintInformationType.Technical]
						: HintInformation[this.hintInformation][HintInformationType.Plain];
					this.hintText = lookupHintText;
				});
		}
	}

	ngOnDestroy() {
		this.destroy.next();
		this.destroy.complete();
	}

}
