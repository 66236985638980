<dx-popup
	class="test-user-harness"
	width="50%"
	height="90%"
	[dragEnabled]="false"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	title="Choose your test user"
>
	<div class="content" *dxTemplate="let data of 'content'">
		
		<div *ngIf="!user || user.id === 1">
			You must be logged in to make use of test users. The default (local) user is active.
		</div>

		<div *ngIf="user.id !== 1">
			<h5>Select a User</h5>
			<div class="fieldset">
				<dx-select-box
					[items]="users"
					[displayExpr]="userDisplay"
					valueExpr="id"
					[(value)]="selectedUserId"
					(onSelectionChanged)="selectedUser = $event.selectedItem"
					[disabled]="editUser"
				></dx-select-box>
				<dx-button
					(onClick)="selectUser()"
					[disabled]="user.id === selectedUserId || editUser"
				>Select
				</dx-button>
				<dx-button
					(onClick)="editSelectedUser()"
					[disabled]="editUser || (selectedUser.claim && selectedUser.claim.contactID > 0)"
				>Edit User
				</dx-button>
				<dx-button (onClick)="addUser()" [disabled]="editUser">New User</dx-button>
			</div>
	
			<div *ngIf="editUser" class="edit">
				<h5>Edit User</h5>
				<div *ngFor="let group of editUser.claim.portalSystemGroups" class="fieldset">
					<dx-select-box
						[items]="roles"
						displayExpr="text"
						valueExpr="text"
						[(value)]="group.saoportalSystemGroupName"
					></dx-select-box>
					<dx-text-box
						[(value)]="group.mcag"
						placeholder="type mcag"
					></dx-text-box>
					<dx-button (onClick)="deleteEditUserRole(group)">Delete Role</dx-button>
				</div>
	
				<h5>Add Role</h5>
				<div class="fieldset">
					<dx-select-box
						placeholder="select role"
						[items]="roles"
						displayExpr="text"
						valueExpr="text"
						[(value)]="editUserRole"
					></dx-select-box>
					<dx-text-box
						placeholder="type mcag"
						[(value)]="editUserMCAG"
					></dx-text-box>
					<dx-button (onClick)="addRoleToEditUser()">Add Role</dx-button>
				</div>
	
				<div class="footer">
					<dx-button (onClick)="editUser = null">Cancel</dx-button>
					<dx-button (onClick)="saveEditUser()">Save</dx-button>
				</div>
			</div>
		</div>

		<div class="actions">
			<a *ngIf="user.id === 1" href="saml2/login">Login</a>
		</div>
	</div>
</dx-popup>