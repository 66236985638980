<div class="container">
	<wasao-amounts-by-category
		[mcag]="mcag"
		[govTypeCode]="govTypeCode"
		[snapshotId]="snapshotId"
		categoryType="revenues"
		[fundCategoryId]="fundCategories.governmental"
		[trendDataClickAction]="trendDataClickAction"
		[hideWhenContentEmpty]="hideWhenContentEmpty"
		[isInteractive]="isInteractive"
		[displayDensity]="displayDensity"
		pieChartSizeGroup="governmentalServices"
		(hasData)="aggregateHasData($event, 'totalGovernmentalRevenues')"
	></wasao-amounts-by-category>
	<wasao-amounts-by-category
		[mcag]="mcag"
		[govTypeCode]="govTypeCode"
		[snapshotId]="snapshotId"
		categoryType="expenditures"
		[fundCategoryId]="fundCategories.governmental"
		[trendDataClickAction]="trendDataClickAction"
		[hideWhenContentEmpty]="hideWhenContentEmpty"
		[isInteractive]="isInteractive"
		[displayDensity]="displayDensity"
		pieChartSizeGroup="governmentalServices"
		(hasData)="aggregateHasData($event, 'totalGovernmentalExpenditures')"
	></wasao-amounts-by-category>
	<div *ngIf="hasDataChildren?.statewideRevenues || hasDataChildren?.statewideExpenditures" class="spacer"></div>
	<wasao-statewide-averages
		*ngIf="displayDensity !== 'print'"
		[mcag]="mcag"
		[year]="displayYear"
		[snapshotId]="snapshotId"
		[fundCategoryId]="fundCategories.governmental"
		[reportType]="reportTypes.revenues"
		[hideWhenContentEmpty]="hideWhenContentEmpty"
		[isInteractive]="isInteractive"
		[displayDensity]="displayDensity"
		(hasData)="aggregateHasData($event, 'statewideRevenues')"
	></wasao-statewide-averages>
	<wasao-statewide-averages
		*ngIf="displayDensity !== 'print'"
		[mcag]="mcag"
		[year]="displayYear"
		[snapshotId]="snapshotId"
		[fundCategoryId]="fundCategories.governmental"
		[reportType]="reportTypes.expenditures"
		[hideWhenContentEmpty]="hideWhenContentEmpty"
		[isInteractive]="isInteractive"
		[displayDensity]="displayDensity"
		(hasData)="aggregateHasData($event, 'statewideExpenditures')"
	></wasao-statewide-averages>
</div>

