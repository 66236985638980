<app-side-drawer
        [handleTitle]="'Add a Projection: ' + currentGovernmentName + ', ' + baseYear"
		(closed) = "onClosed($event)">
	<!-- Use isVisible to control populating editor -->
	<div *ngIf="isVisible" id="content" class="content field-label--small form--dark" appScrollableShadow>
		<p>Projections are a way of creating an estimate for potential future finances. It’s important to remember that these projections are only what-if scenarios for “looking forward” -- by making a projection, you’re
			<strong>not</strong> filing data, or amending any data that’s already been filed.  Saved projections are only available on the same device &amp; browser they were created on. Clearing “cookies and site data”
			on your browser will delete any saved projections.</p>
		<app-tab-bar
			[items]="tabBarItems"
			[selectedId]="selectedTabBarId"
			[hasCheck]="true"
			(onSelect)="handleTabBarSelect($event)"
		></app-tab-bar>
		<ng-container *ngIf="selectedTabBarId === 'existing'">
			<div class="intro">
				<p *ngIf="!projections || projections.length === 0">
					You have no saved projections for {{ currentGovernmentName }} and base year {{ baseYear }}. Start by
					<button href="#" (click)="handleCreateClick()">creating one</button>.
				</p>
				<p *ngIf="projections && projections.length > 0">
					Your saved projections for {{ currentGovernmentName }} and base year {{ baseYear }}:
				</p>
			</div>
			<div class="projection" *ngFor="let projection of projections">
				<div class="title">
					<h4>{{ projection.name }}</h4>

					<span class="actions">
						<button href="#" (click)="edit(projection)">
							<fa-icon [icon]="icons.edit"></fa-icon>
						</button>
						<button href="#" (click)="confirmDelete(projection)">
							<fa-icon [icon]="icons.delete"></fa-icon>
						</button>
						<button href="#" (click)="copy(projection)">
							<fa-icon [icon]="icons.copy"></fa-icon>
						</button>
					</span>
					<dx-button (click)="apply(projection)" class="apply" type="default">Apply to Report</dx-button>
				</div>

				<p class="notes" *ngIf="projection.notes">
					{{ projection.notes }}
				</p>
			</div>
		</ng-container>
		<app-projection-editor
			*ngIf="selectedTabBarId === 'create' || selectedTabBarId === 'edit'"
			[tab]="tab"
			(projectionUpdate)="setProjection($event)"
		></app-projection-editor>
	</div>
	<div class="action-bar u-text-align-right">
		<ng-container *ngIf="selectedTabBarId === 'create' || selectedTabBarId === 'edit'">
			<dx-validation-summary class="validation-summary"></dx-validation-summary>
			<dx-button
				[disabled]="!currentProjection?.hasAnyUserAdjustments()"
				[visible]="editingMultipleFunds"
				text="Clear Adjustments (All Funds)"
				(onClick)="resetAllFunds($event)"
				class="u-margin u-text-align-left"
			></dx-button>
			<dx-button
				[disabled]="!currentProjection?.hasAnyUserAdjustments(this.editorFundValue)"
				text="Clear Adjustments (This Fund)"
				(onClick)="resetThisFund($event)"
				class="u-margin u-text-align-left"
			></dx-button>
			<dx-button
				[disabled]="!currentProjection?.hasAnyUserAdjustments()"
				text="Save + Apply Projection"
				type="default"
				(onClick)="saveAndApply($event)"
			    class="u-margin-left"
			></dx-button>
			<dx-button
				[disabled]="!isDirty"
				text="Save"
				(onClick)="save($event)"
				class="u-margin-left-small"
			></dx-button>
		</ng-container>
		<dx-button text="Cancel" (onClick)="onCancelClick()" class="u-margin-left-small"></dx-button>
	</div>
</app-side-drawer>

