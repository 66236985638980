<div class="stage">
	<div class="container">
		<div class="page">
			<div class="print-bar">
				<div>
					<dx-load-indicator [visible]="!isReadyToPrint"></dx-load-indicator>
				</div>
				<div class="u-margin-left-small">
					<span *ngIf="isReadyToPrint; then readyToPrint else waitingToPrint"></span>
					<ng-template #readyToPrint>Click here to print:</ng-template>
					<ng-template #waitingToPrint>Finishing loading graphics...</ng-template>
				</div>
				<dx-button
					text="Print"
					(click)="handlePrint()"
					type="default"
					[disabled]="!isReadyToPrint"
					class="u-margin-left-small"
				></dx-button>
			</div>

			<div class="header">
				<div class="fit-logo">
					<img src="assets/images/fit_isolated.svg" alt="FIT Logo"/>

					<div class="separator"></div>

					<div class="fit-text" href="#home">
						Financial<br/>
						Intelligence<br/>
						Tool
					</div>
				</div>
				<div class="sao-logo">
					<img src="assets/images/sao_seal_print.png"
						 alt="Seal of the Office of the Washington State Auditor"/>
				</div>
			</div>

			<div class="section">
				<div class="title">
					<div class="label">Government Profile:</div>
					<h1 *ngIf="govInfoLive" class="entity">
						{{ govInfoLive?.EntityName }} <span class="dba"
															*ngIf="govInfoLive?.Entity_Dba">({{ govInfoLive.Entity_Dba }}
						)</span>
					</h1>
				</div>
				<wasao-badges
					hideWhenContentEmpty=true
					[snapshotId]="snapshotId"
					[mcag]="mcag"
					[displayYear]="displayYear"
					[isInteractive]=false
					displayDensity="print"
					(hasData)="childHasData($event, 'badges')"
				></wasao-badges>
			</div>

			<div class="subtitle">
				Report prepared: {{ today | date:'MMMM d, y, h:mm a' }}<br/>
				For detailed, interactive analysis visit: {{ profileUrl }}
			</div>

			<h3>About this Government</h3>
			<wasao-government-information
				[mcag]="mcag"
				[displayYear]="displayYear"
				[snapshotId]="snapshotId"
				[isInteractive]=false
				displayDensity="print"
				[canShowFinancials]="canShowFinancials"
				(hasData)="childHasData($event, 'genGovInfo')"
			></wasao-government-information>

			<ng-container *ngIf="canShowFinancials">
				<h3>Finances at a Glance</h3>
				<wasao-finances-at-a-glance
					[mcag]="mcag"
					[displayYear]="displayYear"
					[snapshotId]="snapshotId"
					[isInteractive]=false
					displayDensity="print"
					[showBeta]="showBeta"
					(hasData)="childHasData($event, 'financesAtAGlance')"
				></wasao-finances-at-a-glance>

				<h3 [ngClass]="{ 'u-hidden': childrenThatHaveData.governmentalServices === false }">Governmental Services</h3>
				<wasao-governmental-services
					[ngClass]="{ 'u-hidden': childrenThatHaveData.governmentalServices === false}"
					[mcag]="mcag"
					[displayYear]="displayYear"
					[snapshotId]="snapshotId"
					(hasData)="childHasData($event, 'governmentalServices')"
					[isInteractive]=false
					displayDensity="print"
				></wasao-governmental-services>
			</ng-container>

			<!--Show FHI on page 1 when governmental services doesnt exist-->
			<ng-container *ngIf="canShowIndicators && indicatorGroups.length && childrenThatHaveData.governmentalServices === false">
				<h3>Financial Health Indicators</h3>
				<p class="u-tiny u-margin-bottom-tiny">{{ hintInfo.financialHealthIndicators[hintInfoType] }}</p>
				<div>
					<ul class="one-page__indicators">
						<li
							*ngFor="let group of indicatorGroups"
							class="one-page__indicator"
							[class.one-page__indicator--good]="group.outlookInfo.outlook === 'Good'"
							[class.one-page__indicator--cautionary]="group.outlookInfo.outlook === 'Cautionary'"
							[class.one-page__indicator--concerning]="group.outlookInfo.outlook === 'Concerning'"
						>
							<div class="one-page__indicator-title">
								{{ group.group }}
							</div>
							<div class="one-page__indicator-content">
								<strong>Outlook:</strong> <span
								class="one-page__indicator-outlook">{{ group.outlookInfo.outlook }}</span><br>
								{{ group.outlookInfo.annotation }}
							</div>
						</li>
					</ul>
				</div>
			</ng-container>
		</div>
		<div class="page">


			<ng-container *ngIf="canShowFinancials">
				<h3 [ngClass]="{ 'u-hidden': childrenThatHaveData.enterpriseServices === false }">Enterprise
					Services</h3>
				<wasao-enterprise-services
					[ngClass]="{ 'u-hidden': childrenThatHaveData.enterpriseServices === false }"
					[mcag]="mcag"
					[displayYear]="displayYear"
					[snapshotId]="snapshotId"
					(hasData)="childHasData($event, 'enterpriseServices')"
					[isInteractive]=false
					displayDensity="print"
					[hasGovServices]="childrenThatHaveData.governmentalServices"
				></wasao-enterprise-services>
			</ng-container>

			<!--Show FHI on page 2 when governmental services exists-->
			<ng-container *ngIf="canShowIndicators && indicatorGroups.length && childrenThatHaveData.governmentalServices === true">
				<h3>Financial Health Indicators</h3>
				<p class="u-tiny u-margin-bottom-tiny">{{ hintInfo.financialHealthIndicators[hintInfoType] }}</p>
				<div>
					<ul class="one-page__indicators">
						<li
							*ngFor="let group of indicatorGroups"
							class="one-page__indicator"
							[class.one-page__indicator--good]="group.outlookInfo.outlook === 'Good'"
							[class.one-page__indicator--cautionary]="group.outlookInfo.outlook === 'Cautionary'"
							[class.one-page__indicator--concerning]="group.outlookInfo.outlook === 'Concerning'"
						>
							<div class="one-page__indicator-title">
								{{ group.group }}
							</div>
							<div class="one-page__indicator-content">
								<strong>Outlook:</strong> <span
								class="one-page__indicator-outlook">{{ group.outlookInfo.outlook }}</span><br>
								{{ group.outlookInfo.annotation }}
							</div>
						</li>
					</ul>
				</div>
			</ng-container>
		</div>
	</div>
</div>

