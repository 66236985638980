import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IncomeByYearComponent} from './income-by-year.component';
import {DxButtonModule, DxChartModule} from 'devextreme-angular';
import {HintModule} from '../../ui/hint/hint.module';
import {LoadingModule} from '../../ui/loading/loading.module';
import {FundServiceModule} from '../../services/fund-service/fund-service.module';


@NgModule({
	declarations: [IncomeByYearComponent],
	imports: [
		CommonModule,
		DxChartModule,
		DxButtonModule,
		HintModule,
		LoadingModule,
		FundServiceModule
	],
	exports: [IncomeByYearComponent]
})
export class IncomeByYearModule {
}
