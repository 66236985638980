import {Injectable} from '@angular/core';
import {DisabledGovernmentType} from './disabled-government-type';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {FitApiService} from '../../../modules/api/fit-api/fit-api.service';
import {
	IndicatorReportType
} from '../../../modules/api/fit-api/models/indicators/indicator-report-type';
import {FilingBasis} from '../../../modules/api/fit-api/models/snapshots/filing-basis';
import * as pluralize from 'pluralize';
import {titleCase} from 'title-case';
import {SnapshotId} from '../../../modules/api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class GovernmentTypesService {

	constructor(
		private fitApi: FitApiService
	) {
		pluralize.addIrregularRule('Area Agency on Aging', 'Area Agencies on Aging');
	}

	getPlural = (govTypeDesc: string) => {
		// some descriptions from CommonData have spaces at the end
		let result = govTypeDesc.trim();
		// pluralize the govType
		result = pluralize(result);
		// pluralize has weird casing rules, so title case it
		result = titleCase(result);
		return result;
	}

	getDisabledGovTypesForIndicators = (
		filingBasis: FilingBasis,
		indicatorReportType: IndicatorReportType,
		snapshotId?: SnapshotId
	): Observable<Array<DisabledGovernmentType>> => {
		const snapshot = this.fitApi.getAnnualFilingSnapshot(snapshotId);
		const indicatorReportGroupBy = this.fitApi.getIndicatorReportGroupBy(['govTypeCode', 'filingBasis', 'report'], snapshotId);
		return forkJoin({snapshot, indicatorReportGroupBy}).pipe(map(result => {
			let disabledGovTypes = new Array<DisabledGovernmentType>();
			disabledGovTypes = result.snapshot.detail.governmentTypes.reduce((acc, govType) => {
				// define a reason; if populated, the govTypeCode should be added with this reason
				let reason: string;
				// look to see if this combination of AccountingBasis and IndicatorReportType produce any indicator results for this govTypeCode
				const govTypeHasIndicators = result.indicatorReportGroupBy.find(
					x => x.govTypeCode === govType.code
						// Look for the following properties by id since aggregation causes OData to emit underlying int behind enum keys
						&& x.report === indicatorReportType?.id
						&& x.filingBasis === filingBasis?.id
				);
				// if not, record the reason
				if (govTypeHasIndicators == null) {
					reason = `No ${filingBasis.name} ${indicatorReportType.name} financial indicators for this government type.`;
				}
				// override this reason with if the govType just can't have indicators
				if (govType.canHaveIndicators === false) {
					reason = `No financial indicators are available for this government type.`;
				}
				// if we populated reason, push this to the stack
				if (reason) {
					acc.push({ code: govType.code, reason: reason });
				}
				return acc;
			}, disabledGovTypes);
			return disabledGovTypes;
		}), catchError(error => of(error)));
	}
}
