import {Component, ViewChild} from '@angular/core';
import {ShapefileLoaderService} from '../shapefile-loader.service';
import DataSource from 'devextreme/data/data_source';
import {ShapefileLoaderState} from '../models/shapefile-loader-state';
import {DxDataGridComponent} from 'devextreme-angular';
import {ShapefileLoaderVintage} from '../models/shapefile-loader-vintage';
import {VintageStatus, VintageStatuses} from '../../../shared/models/vintage-status';

@Component({
	selector: 'app-shapefile-vintages',
	templateUrl: './shapefile-vintages.component.html',
	styleUrls: ['./shapefile-vintages.component.scss']
})
export class ShapefileVintagesComponent {

	shapefileLoaderVintageStatuses = VintageStatuses;
	dataSource: DataSource;
	latestExistingYear: number;

	@ViewChild(DxDataGridComponent) dxDataGrid: DxDataGridComponent;

	/**
	 * Indicates whether a row is being edited or created. Facilitates disabling certain columns while editing.
	 */
	rowEditType: 'edit' | 'create';

	constructor(
		private service: ShapefileLoaderService
	) {
		this.dataSource = service.getODataDataSource(ShapefileLoaderState.Vintages);
		this.dataSource.store().on('loaded', this.setLatestExistingYear);
	}

	maintainVintage = (e) => {
		this.service.setState(ShapefileLoaderState.MaintainVintage, e.row.key);
	}

	// https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/columns/buttons/#visible
	isMaintainVintageVisible = (e) => {
		return !e.row.isEditing;
	}

	onEditingStart = event => {
		this.rowEditType = 'edit';
	}

	onInitNewRow = (event: { data: ShapefileLoaderVintage }) => {
		this.rowEditType = 'create';
		// sensible defaults
		event.data.status = VintageStatus.Pending;
		event.data.year = event.data.assessmentYear = this.latestExistingYear + 1;
		event.data.taxableYear = this.latestExistingYear + 2;
	}

	refreshDataGrid = (event) => this.dxDataGrid.instance.refresh();

	/**
	 * Sets the latest year that already exists in the database for use in filling in new row data.
	 * @param data
	 */
	setLatestExistingYear = (data: Array<ShapefileLoaderVintage>) => {
		this.latestExistingYear = Math.max(...data.map(x => x.year), 0);
	}
}
