import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'wasao-badge',
	templateUrl: './badge.component.html',
	styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

	@Input() text: string;

	constructor() {
	}

	ngOnInit() {
	}

}
