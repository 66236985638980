import {Component, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {environment} from 'environments/environment';
import {DxPopupComponent} from 'devextreme-angular';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-feedback',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnDestroy {

	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
	@ViewChild('content') content: TemplateRef<any>;
	private uiSubscription: Subscription;

	copyrightYear: number;
	appVersion: string;
	isVisible = true;

	constructor(
		private uiService: UserInterfaceService
	) {
		this.uiSubscription = this.uiService.feedback$.subscribe(
			value => this.isVisible = value
		);

		this.copyrightYear = (new Date()).getFullYear();
		this.appVersion = environment.version;
	}

	ngOnDestroy() {
		this.uiSubscription.unsubscribe();
	}

	close = () => this.isVisible = false;

}
