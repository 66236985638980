export class User {
	id: number;
	showTechnical: boolean;
	autoUpdateSnapshots: boolean;
	disclaimer                     = {
		ackDate: <string>null,
		hash:    <number>null
	};
	fitByTheNumbers				   = {
		lastViewDate: <string>null
	};
	claim: Claim;
	disableNoSpecialAccess: boolean;
	isRestrictedByMcag: boolean    = null;
	allowedMcags: Array<string>    = null;
	hasReadReportPopupNotification = false;

	get isLocalUser(): boolean {
		return this.id === 1;
	}

	get hasAdminRole(): boolean {
		return this.hasAccessRoleForScope('admin');
	}

	get hasDeveloperRole(): boolean {
		return this.hasAccessRoleForScope('developer');
	}

	get hasAnyFitAccessRole(): boolean {
		return this.hasAccessRoleForScope('fit');
	}

	get hasAnyAccessRole(): boolean {
		return this.claim && this.claim.userGroups && this.claim.userGroups.length > 0;
	}

	private hasAccessRoleForScope(role: 'fit' | 'admin' | 'developer'): boolean {
		if (!this.hasAnyAccessRole) {
			return false;
		}
		let accessRoles = [];
		if (role === 'admin') {
			accessRoles = [UserGroup.fitAdministrator];
		}
		else if (role === 'fit') {
			accessRoles = [UserGroup.annualReportFiler, UserGroup.governmentUser, UserGroup.governmentPortalAdmin,
				UserGroup.lgcsAdministrator, UserGroup.lgcsReportEditor, UserGroup.lgcsReportViewer];
		}
		else if (role === 'developer') {
			accessRoles = [UserGroup.saoDevelopers];
		}
		return accessRoles
			.filter(r => this.claim.userGroups.indexOf(r) > -1)
			.length > 0;
	}

	/**
	 * Check if the user has access to an mcag
	 * Test for global access role / MCAG role and viewing track A or viewing gov't profile user has access to
	 *
	 * @param mcag
	 */
	public hasAccessToMcag(mcag: string): boolean {
		if (this.hasGlobalAccess() || (this.allowedMcags && this.allowedMcags.includes(mcag))) {
			return true;
		}

		// defaults to false
		return false;
	}

	/**
	 * Has Access to Any School is defined by if the User has any MCAG connected to a school district (govTypeCode equals '03') in their user profile
	 *
	 */
	public hasAccessToAnySchool(): boolean {
		if (this.claim?.portalSystemGroups.some(psg => psg.govTypeCode === '03')) {
			return true;
		}

		// defaults to false
		return false;
	}
	/**
	 * Global access is defined by the server returning isRestrictedByMCAG = false. true and undefined values imply
	 * the user does not have global access.
	 */
	public hasGlobalAccess(): boolean {
		return this.isRestrictedByMcag === false;
	}
}

// Based off SAO's user implementation - WASAO.SSO.DALModels.ViewModels.ContactClaim
export class Claim {
	contactID: number;
	mcag: string; // this is self-reported by the user, do not rely on for logic
	registered: boolean;
	fullName: string;
	email: string;
	userGroups: Array<UserGroup>;
	portalSystemGroups: Array<PortalSystemGroup>;
}

export enum UserGroup {
	lgcsAdministrator     = 'LGCS Administrator',
	lgcsReportViewer      = 'LGCS Report Viewer',
	lgcsReportEditor      = 'LGCS Report Editor',
	annualReportFiler     = 'Annual Report Filer',
	governmentUser        = 'Government User',
	governmentPortalAdmin = 'Government Portal Admin',
	fitAdministrator      = 'FIT Admin',
	saoDevelopers 		  = 'SAO Developers'
}

export class PortalSystemGroup {
	contactID: number;
	saoportalSystemGroupName: UserGroup;
	saoportalSystemGroupId: number;
	mcag: string;
	govTypeCode: string;
	entityName: string;
	lookupName: string;
	acctBasisTypeID: number;
	adfsInternalOnly: boolean;
	parentMCAG: string;
	isActive: boolean;
}
