<app-side-drawer
	handleTitle="Report Menu"
	width="800">

	<dx-popup
		class="confirmation-dialog bonanza-popup"
		width="400"
		height="auto"
		title="Abandon Changes?"
		[showCloseButton]=false
		[visible]="isConfirmationDialogVisible"
	>
		<div *dxTemplate="let data of 'content'">
			<div class="app-popup__wrapper">
				<div class="app-popup__inner">
					<p class="u-margin-bottom-none">
						Closing the Report Menu will discard the changes you have currently made.
						Do you wish to continue?
					</p>
				</div>

				<div class="app-popup__footer">
					<dx-button
						type="default"
						text="OK"
						(onClick)="onConfirmationDialogChange($event, true)"
					></dx-button>

					<dx-button
						class="u-margin-left-small"
						text="Cancel"
						(onClick)="onConfirmationDialogChange($event, false)"
					></dx-button>
				</div>
			</div>
		</div>
	</dx-popup>

	<form class="form" action="#" (submit)="submit($event)">
		<dx-validation-group>
			<div id="content" class="content field-label--small form--dark" appScrollableShadow>
				<h3>Report Type</h3>
				<div class="report-menu__tab-bar">
					<app-tab-bar
						[items]="availableReportTypes"
						hasCheck=true
						[selectedId]="transients.reportType"
						(onSelect)="onReportTypeChange($event)"
					></app-tab-bar>
					<wasao-hint
						icon="infoCircle"
						class="tooltip-icon u-margin-bottom"
						hintInformation="reportType"
					></wasao-hint>
				</div>

				<h3>Report Options</h3>
				<div class="dx-field" *ngIf="model.hasOwnProperty('explore')">
					<div class="dx-field-label">Explore</div>
					<div class="dx-field-value">
						<dx-select-box
							[dataSource]="availableExploreByOptions"
							valueExpr="id"
							displayExpr="name"
							[(value)]="transients.explore"
							(onValueChanged)="onExploreChange($event)"
						></dx-select-box>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('baselineGovernment')">
					<div class="dx-field-label">Baseline Gov't</div>
					<div class="dx-field-value">
						<app-government-chooser
							*ngIf="validations.isBaselineGovernmentEnabled"
							[tab]="tab"
							[selections]="transients.baselineGovernment"
							[validationCallback]="validateHasGovernments"
							maxSelections=1
							[govTypeFilter]="validations.govTypeFilter"
							[financialsDatasetSource]="validations.financialsDatasetSource"
							(changed)="onBaselineChange($event)"
							(govDropped)="onBaselineDropped($event)"
						></app-government-chooser>
						<span *ngIf="!validations.isBaselineGovernmentEnabled">
							Baseline governments can be used only in single-year scenarios.
							To designate a baseline government, try using a single-year data range.
						</span>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('includedGovernments')">
					<div class="dx-field-label">Comparison Gov'ts (Max 5)</div>
					<div class="dx-field-value">
						<app-government-chooser
							[tab]="tab"
							[selections]="transients.includedGovernments"
							[validationCallback]="validateHasGovernments"
							maxSelections=5
							[dropzoneDisabled]="transients.includedGovernments?.length > 4"
							[govTypeFilter]="validations.govTypeFilter"
							[financialsDatasetSource]="validations.financialsDatasetSource"
							(changed)="onIncludedGovernmentsChange($event)"
							(govDropped)="onIncludedDropped($event)"
						></app-government-chooser>
						<div class="actions">
							<button title="Select a Saved Peer Set" type="button" id="load" (click)="loadPeers()">
								<fa-icon [icon]="icons.load"></fa-icon>
							</button>
							<dx-tooltip
								width="10em"
								target="#load"
								[(visible)]="isBaselineNoteVisible"
							>
								<dxo-position my="right" at="left" of="#load"></dxo-position>
								<div *dxTemplate="let data of 'content'" class="tooltip">
									The Peer Set you have applied contains a baseline government, but baseline
										comparisons can only be used in single-year mode. Please select a single year
										and apply this Peer Set again if desired.
								</div>
							</dx-tooltip>
							<button title="Save selections as new Peer Set" type="button" *ngIf="transients.includedGovernments?.length" (click)="savePeers()">
								<fa-icon [icon]="icons.save"></fa-icon>
							</button>
						</div>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('report')">
					<div class="dx-field-label">Report</div>
					<div class="dx-field-value u-margin-bottom-large">
<!--						<dx-select-box-->
<!--							[dataSource]="transients.availableReports"-->
<!--							valueExpr="id"-->
<!--							displayExpr="label"-->
<!--							[value]="transients.report"-->
<!--							(onValueChanged)="onReportChange($event)"-->
<!--						>-->
<!--							<dx-validator>-->
<!--								<dxi-validation-rule type="required" message="Please choose a report to view."></dxi-validation-rule>-->
<!--							</dx-validator>-->
<!--						</dx-select-box>-->
						<app-report-selector
							[mcag]="transients.baselineGovernment?.mcag"
							[reportType]="transients.reportType"
							[exploreType]="transients.explore"
							[showBetaForUser]="showBetaForUser"
							[selectedReportId]="transients.report"
							(changed)="onReportChange($event)"
						></app-report-selector>
						<wasao-hint
							icon="infoCircle"
							class="tooltip-icon"
							hintInformation="report"
						></wasao-hint>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('filingBasis')">
					<div class="dx-field-label">Accounting Basis</div>
					<div class="dx-field-value u-margin-bottom-large">
						<wasao-filing-basis-picker
							placeholder="Select..."
							[value]="transients.filingBasis.name"
							(changed)="onFilingBasisChange($event)"
						></wasao-filing-basis-picker>
<!--						<wasao-hint-->
<!--							icon="infoCircle"-->
<!--							class="tooltip-icon"-->
<!--							hintInformation="accountingBasis"-->
<!--						></wasao-hint>-->
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('indicatorNames')">
					<div class="dx-field-label">Indicators</div>
					<div class="dx-field-value u-margin-bottom-large">
						<app-indicator-name-chooser
							[indicatorReportType]="transients.report"
							[filingBasis]="transients.filingBasis"
							[value]="transients.indicatorNames"
							(changed)="onIndicatorNameChange($event)"
						></app-indicator-name-chooser>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('indicatorCode')">
					<div class="dx-field-label">Report
						<span *ngIf="sources.indicatorAccountingBasis">({{ sources.indicatorAccountingBasis }} Basis)</span>
					</div>
					<div class="dx-field-value u-margin-bottom-large">
						<app-indicator-instance-selector
							[snapshot]="snapshot"
							[selection]="transients.indicatorCode"
							[years]="transients.years"
							[mcag]="transients.baselineGovernment?.mcag"
							(changed)="onIndicatorInstanceChange($event)"
							(accountingBasisChanged)="sources.indicatorAccountingBasis = $event"
						></app-indicator-instance-selector>
						<wasao-hint
							icon="infoCircle"
							class="tooltip-icon"
							hintInformation="indicatorCode"
						></wasao-hint>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('years')">
					<div class="dx-field-label">Data Range</div>
					<div class="dx-field-value">
						<app-years
							[mode]="validations.yearsMode"
							[available]="transients.availableYears"
							[selections]="transients.years"
							(change)="onYearsChange($event)"
						></app-years>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('govTypes')">
					<div class="dx-field-label">Include Government Types</div>
					<div class="dx-field-value">
						<app-government-types
							class="u-margin-bottom"
							[selections]="transients.govTypes"
							[disabledGovTypes]="sources.disabledGovernmentTypes"
							[validationCallback]="validateGovTypes"
							validationMessage="You must choose at least one government type."
							(changed)="onGovTypesChange($event)">
						</app-government-types>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('locations')">
					<div class="dx-field-label">Filter Locations</div>
					<div class="dx-field-value">
						<app-locations
							[selections]="transients.locations"
							(changed)="onLocationsChanged($event)"
							[store]="snapshot?.detail.counties"
						></app-locations>
						<wasao-hint
							icon="infoCircle"
							class="tooltip-icon"
							hintInformation="filterLocations"
						></wasao-hint>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('funds') && transients.report !== 'debtAndLiabilities'">
					<div class="dx-field-label">Filter Funds</div>
					<div class="dx-field-value">
						<app-switch
							[value]="transients.hasFunds"
							(onValueChanged)="onHasFundsChanged($event.value)"
						></app-switch>
						<wasao-hint
							icon="infoCircle"
							class="tooltip-icon"
							hintInformation="filterFunds"
						></wasao-hint>
					</div>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('funds') && transients.hasFunds">
					<div class="dx-field-label"></div>
					<div class="dx-field-value">
						<app-fund-chooser
							[pivotGridData]="pivotGridData"
							[snapshot]="snapshot"
							[selections]="transients.funds"
							[mcag]="transients.baselineGovernment?.mcag"
							[exploreType]="transients.explore"
							[years]="transients.years"
							(changed)="onFundFilterChange($event)"
							class="u-margin-right-tiny"
						></app-fund-chooser>
					</div>
				</div>
				<div class="dx-field" *ngIf="validations.hasCategoriesFilter">
					<div class="dx-field-label">Filter Categories</div>
					<div class="dx-field-value">
						<app-switch
							[value]="transients.hasCategories"
							(onValueChanged)="onHasCategoriesChanged($event.value)"
						></app-switch>
						<wasao-hint
							icon="infoCircle"
							class="tooltip-icon"
							hintInformation="filterCategories"
						></wasao-hint>
					</div>
				</div>
				<div class="dx-field" *ngIf="validations.hasCategoriesFilter && transients.hasCategories">
					<div class="dx-field-label"></div>
					<div class="dx-field-value">
						<app-account-chooser
							[selections]="transients.categories"
							[showAccountCodes]="showAccountCodes"
							[pivotGridData]="pivotGridData"
							[reportId]="transients.report"
							[snapshot]="snapshot"
							(changed)="onCategoriesChanged($event)"
						></app-account-chooser>
					</div>
				</div>
				<div class="dx-field" *ngIf="validations.hasExpenditureObjectsFilter">
					<div class="dx-field-label">Filter Expenditure Objects</div>
					<div class="dx-field-value">
						<app-switch
							[value]="transients.hasExpenditureObjects"
							(onValueChanged)="onHasExpenditureObjectsChanged($event.value)"
						></app-switch>
						<wasao-hint
							icon="infoCircle"
							class="tooltip-icon"
							hintInformation="filterExpenditureObjects"
						></wasao-hint>
					</div>
				</div>
				<div class="dx-field" *ngIf="validations.hasExpenditureObjectsFilter && transients.hasExpenditureObjects">
					<div class="dx-field-label"></div>
					<div class="dx-field-value">
						<app-expenditure-object-chooser
							[snapshot]="snapshot"
							[selections]="transients.expenditureObjects"
							(changed)="onExpenditureObjectsChanged($event)"
						>
						</app-expenditure-object-chooser>
					</div>
				</div>
				<div *ngIf="model.hasOwnProperty('category')">
					<h4 class="u-margin-top">Choose a category</h4>
					<app-account-chooser-freeform
						[initialSelection]="transients.category"
						[showAccountCodes]="showAccountCodes"
						[snapshot]="snapshot"
						(changed)="onCategoryChanged($event)"
					></app-account-chooser-freeform>
				</div>
				<div class="dx-field" *ngIf="model.hasOwnProperty('guideline')">
					<div class="dx-field-label">SAO Financial Fitness Guideline</div>
					<div class="dx-field-value">
						<app-switch></app-switch>
						<wasao-hint
							icon="infoCircle"
							class="tooltip-icon"
							hintInformation="financialFitnessGuideline"
						></wasao-hint>
					</div>
				</div>

				<!--
				TODO: enable once peer sets is completely implemented
				<div class="dx-field hr hr&#45;&#45;top">
					<div class="dx-field-label">Peer Benchmarking</div>
					<div class="dx-field-value">
						<app-switch
							[value]="peerBenchmarking"
							(onValueChanged)="onPeerBenchmarkingChanged($event.value)"
						></app-switch>
					</div>
				</div>
				<div class="dx-field" *ngIf="peerBenchmarking">
					<div class="dx-field-label"></div>
					<div class="dx-field-value">
						<app-report-peer-set-selector
							[baselineGovernment]="transients.baselineGovernment">
						</app-report-peer-set-selector>
					</div>
				</div>-->
			</div>

			<div class="action-bar u-text-align-right">
				<dx-check-box
					[(value)]="isOpenInNewTab"
					text="Open in a new tab"
					class="u-color-neutral-white"
				></dx-check-box>

				<dx-button
					[disabled]="!isDirty"
					text="Apply Changes"
					type="default"
					[useSubmitBehavior]="true"
					class="u-margin-left"
				></dx-button>

				<dx-button
					text="Cancel"
					(onClick)="drawer.close(true)"
					class="u-margin-left-small"
				></dx-button>
			</div>
		</dx-validation-group>
	</form>
</app-side-drawer>

<dx-load-panel
	[visible]="isApplyingChanges"
	message="One moment while we apply your changes..."
	shadingColor="rgba(255, 255, 255, 0.8);"
></dx-load-panel>
