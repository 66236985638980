import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { UserService } from 'app/shared/services/user.service';
import { UserInterfaceService } from 'app/shared/services/user-interface.service';

@Component({
	selector: 'app-login-banner',
	templateUrl: './login-banner.component.html',
	styleUrls: ['./login-banner.component.scss'],
	animations: [
		trigger('visible', [
			state('visible', style({
				height: '*',
				opacity: 1
			})),
			state('invisible', style({
				height: '0',
				opacity: 0
			})),
			transition('visible => invisible', [ animate('0.4s') ]),
			transition('invisible => visible', [ animate('0.4s') ])
		])
	]
})
export class LoginBannerComponent implements OnInit {

	closeIcon = faTimes;
	isVisible = false;

	constructor(public userService: UserService, public uiService: UserInterfaceService) { }

	ngOnInit() {
		this.userService.user.subscribe(user => {
			if(!user.claim || !user.claim.contactID) {
				this.isVisible = true;
			}
		});
	}

	dismiss = () => this.isVisible = false;

	login = () => this.userService.login();

	moreInfo = () => this.uiService.showLogin();
}
