import {AccountingCategoryType} from './accounting-category-type';

export class AccountingCategory {
	constructor(
		public type: AccountingCategoryType,
		public key: string,  // Composite, unique key
		public parentKey: number | string,  // TODO - Replace 0 with null for top level
		public hasChildren: boolean,
		// these values should eventually be made part of AccountingCategoryType
		public barsSegment: 'BasicAccount'|'SubAccount'|'Element'|'SubElement',
		// deprecated - for legacy purposes, is indicated by AccountingCategoryType.financialSummarySection
		public isSection: boolean,  // Deprecated
		public id: number,
		public sortOrder: number | string,
		public displayName: string,
		public displayCode?: string
	) {	}

	static getCompositeKey(type: AccountingCategoryType, id: number): string {
		return `${type}|${id}`;
	}
}
