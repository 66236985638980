<div class="uploader">
	<dx-file-uploader
		[disabled]="hasExistingRecords === undefined || isLoading"
		name="file"
		uploadMode="useForm"
		selectButtonText="Select TCA shapefile zip"
		[allowedFileExtensions]="['.zip']"
		accept="application/zip"
		(onValueChanged)="valueChanged($event)"
	>
	</dx-file-uploader>
	<div *ngIf="shapefile">
		<div>{{ shapefile.name }}, {{ shapefile.size / 1024 / 1024 | number:'1.0-2' }}MB</div>

		<div *ngIf="hasExistingRecords === true">
			<fa-icon [icon]="warningIcon" class="warning"></fa-icon>
			Tax Code Area shapefiles already exist for this vintage year. Uploading a new file will overwrite these records.
		</div>
	</div>
	<div>
		<dx-button
			[disabled]="!shapefile"
			text="Upload"
			(onClick)="upload()"
		></dx-button>
	</div>
</div>
<div class="grid-container">
	<dx-data-grid
		[dataSource]="dataSource"
		[disabled]="isLoading"
	>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxi-column dataField="countyId" dataType="number"></dxi-column>
		<dxi-column dataField="countyName" dataType="string"></dxi-column>
		<dxi-column dataField="tcaId" dataType="string"></dxi-column>
	</dx-data-grid>
</div>
<wasao-loading [isLoading]="isLoading" message="Uploading file"></wasao-loading>

