import { Pipe, PipeTransform } from '@angular/core';
import { Tab } from 'app/shared/models/tab';

@Pipe({
	name: 'primaryGovernment'
})
export class PrimaryGovernmentPipe implements PipeTransform {

	transform(tab: Tab, args?: any): any {
		const hasGovernments = Array.isArray(tab.governments)
			&& tab.governments.length;

		if (hasGovernments) {
			return tab.governments.length === 1
				? tab.governments[0] // return the first
				: tab.governments.find((v) => v.prime); // or find prime
		} else {
			return null;
		}
	}

}
