<ng-container *ngIf="govTypeCanHaveFinancials === false; else hasFinancials">
	<p>
		<strong>{{ government.entityNameWithDba }}</strong> cannot be ranked due to lack of comparative financial data for {{ government.govTypeDesc }} governments.
	</p>
</ng-container>

<ng-template #hasFinancials>
	<ng-container *ngIf="snapshotId !== 'live'; else liveMessage">
		<p *ngIf="filingStatus && government">
			Of {{ filingStatus.filers - filingStatus.filersWithPendingUpdates }} {{ government.govTypeDesc }}
			governments<sup>*</sup>, <strong>{{ government.entityNameWithDba }} </strong>
			<ng-container *ngIf="rankingTrends?.length">is ...</ng-container>
			<ng-container *ngIf="!rankingTrends?.length">cannot be ranked due to lack of data for {{ currentYear }}.</ng-container>
		</p>

		<ul *ngIf="rankingTrends?.length">
			<li *ngFor="let ranking of rankingTrends">
				<strong># {{ ranking.currentRanking }}</strong> in <strong>{{ ranking.name }}</strong>
				&nbsp;
				<wasao-hint [icon]="getTrendIcon(ranking)">
					<span *ngIf="ranking.currentRanking">{{ ranking.currentYear }}: # {{ ranking.currentRanking }}
						<!--Financial Ranking-->
						<ng-container *ngIf="!ranking.population">
							({{ ranking.currentMeasure | currency }})<br />
						</ng-container>

						<!--Population Ranking-->
						<ng-container *ngIf="ranking.population">
							({{ ranking.currentMeasure | number }})<br />
						</ng-container>
					</span>
					<span *ngIf="ranking.priorRanking">{{ ranking.priorYear }}: # {{ ranking.priorRanking }}
						<!--Financial Ranking-->
						<ng-container *ngIf="!ranking.population">
							({{ ranking.priorMeasure | currency }})
						</ng-container>

						<!--Population Ranking-->
						<ng-container *ngIf="ranking.population">
							({{ ranking.priorMeasure | number }})
						</ng-container>
					</span>
					<span *ngIf="!ranking.priorRanking">
						{{ ranking.priorYear }}: (no data)
					</span>
				</wasao-hint>
				&nbsp;
				<a *ngIf="isInteractive && seeDataClickAction && !ranking.population" (click)="handleSeeDataClick(ranking)">(see data)</a>
			</li>
		</ul>

		<p *ngIf="filingStatus && snapshot" class="footnote">
			<sup>*</sup><u>Note:</u> As of {{ snapshot.dateCreated | date:'longDate' }} there were {{ filingStatus.total }}
			active {{ government.govTypeDesc }} governments and {{ filingStatus.filers }} had filed
			annual reports.
			<span *ngIf="filingStatus.filersWithPendingUpdates > 0">
				Of these filed annual reports, {{ filingStatus.filersWithPendingUpdates }} have updates pending and are not included in the rankings.
			</span>
		</p>
	</ng-container>

	<ng-template #liveMessage>
		<p>Rankings are not available when viewing live (unpublished data). Select a published snapshot to see government rankings data.</p>
	</ng-template>
</ng-template>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
