const pieChart = [
    '#53acdf',
    '#b8d5e2',
    '#929938',
    '#fbda8b',
    '#4a9e82',
    '#48346c'
];

const barChart = [
    '#48346c',
    '#53acdf'
];

const governmentTypes = {
    '03': '#959A14', // School District
    '05': '#AA7942', // Library District
    '06': '#66AED4', // County
    '07': '#E8702D', // City/Town
    '08': '#D35050', // Fire Protection District
    '09': '#999999', // Cemetery District
    '22': '#1441BA', // Hospital District
    '64': '#A6C663', // Park and Recreation District
    'default': '#1441BA'
};

function forGovernmentType(govTypeCode) {
    return governmentTypes[govTypeCode] ?? governmentTypes.default;
}

const neutral = {
    dark: '#444444',
    medium: '#646464',
    light: '#979693',
};

module.exports = {
    forGovernmentType,
    pieChart,
    barChart,
    neutral
};
