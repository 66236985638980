import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ReusableGovernmentAndTypeInputs} from '../../reusable/models/reusable-government-and-type-inputs';
import {LoggerService} from '../../../shared/services/logger.service';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {FundCategoryId} from '../../services/fund-service/models/fund-category';
import {FinancialSummaryService} from '../../services/financial-summary-service/financial-summary.service';
import {TotalAmounts} from '../../services/financial-summary-service/models/total-amounts';
import {ReportId} from '../../../shared/models/report-id';
import {HistoryService} from '../../../shared/services/history.service';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {FieldService} from '../../../shared/services/field.service';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {barChart} from '../../../../../sao-patterns/src/tokens/government-colors.js';

@Component({
	selector: 'wasao-income-by-year',
	templateUrl: './income-by-year.component.html',
	styleUrls: ['./income-by-year.component.scss']
})
export class IncomeByYearComponent extends ReusableGovernmentAndTypeInputs implements OnInit, OnChanges {

	/**
	 * Optional. Defaults to all fundCategoryIds, if not specified.
	 */
	@Input() fundCategoryId: FundCategoryId;

	/**
	 * The action to perform when "see trend data" is clicked.
	 */
	@Input() underlyingDataClickAction: (reportId: ReportId, fieldTransformations: Array<any>) => void;

	dataSource: Array<TotalAmounts>;
	barChartColors: Array<string> = barChart;

	constructor(
		filingStatus: FilingStatusService,
		fitApi: FitApiService,
		logger: LoggerService,
		private financialSummary: FinancialSummaryService,
		private history: HistoryService,
		private fieldService: FieldService
	) {
		super(filingStatus, fitApi, logger);
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.callFunctionWithSpecificity(changes, this.updateData);
	}

	updateData = (specificity: GovernmentSpecificity) => {
		this.logger.time(this);
		this.isLoading = true;
		this.financialSummary.getTotalAmounts(specificity, this.fundCategoryId, this.snapshotId).subscribe(result => {
			this.dataSource = result;
			this.hasData.emit(this.dataSource?.length > 0);
			this.isLoading = false;
			this.logger.timeEnd(this);
		});
	};

	handleUnderlyingDataClick = () => {
		// do nothing if action undefined
		if (typeof this.underlyingDataClickAction !== 'function') {
			return undefined;
		}
		this.underlyingDataClickAction(
			ReportId.summary,
			[this.fieldService.getFundGroupFilterTransformation(this.fundCategoryId)]
		);
	}
}
