import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataStoryComponent} from './data-story.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DxButtonModule, DxPopupModule} from 'devextreme-angular';


@NgModule({
	declarations: [
		DataStoryComponent
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		DxPopupModule,
		DxButtonModule
	],
	exports: [
		DataStoryComponent
	]
})
export class DataStoryModule {
}
