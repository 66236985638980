import {Component, OnDestroy, OnInit, HostListener, TemplateRef, ElementRef, ViewChild} from '@angular/core';
import {DxPopupComponent, DxScrollViewComponent} from 'devextreme-angular';
import {UserInterfaceService} from '../../../shared/services/user-interface.service';
import {Subject} from 'rxjs';
import {Disclaimer} from '../../../shared/models/disclaimer';
import {faArrowLeft, faDownload} from '@fortawesome/free-solid-svg-icons';
import {HttpClient} from '@angular/common/http';
import {finalize, takeUntil} from 'rxjs/operators';
import {ExtractYear} from '../extract-year';
import {ExtractType} from '../extract-type';
import {DataExtractService} from '../data-extract.service';

@Component({
	selector:    'app-data-extract',
	templateUrl: './data-extract.component.html',
	styleUrls:   ['./data-extract.component.scss']
})
export class DataExtractComponent implements OnDestroy, OnInit {

	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	@ViewChild('content') content: TemplateRef<any>;
	@ViewChild(DxScrollViewComponent) scrollView: DxScrollViewComponent;
	@ViewChild('nonScrollableContent') nonScrollableContent: ElementRef;
	private destroy = new Subject<void>();

	isVisible = true;
	isLoading = false;

	extractSelection: ExtractType;
	extractSelectionYears: Array<ExtractYear>;
	extractSelectionTitle = 'FIT Data Extracts';
	disclaimer = Disclaimer;

	public icons = {
		download: faDownload,
		back: faArrowLeft
	};

	constructor(
		private uiService: UserInterfaceService,
		public service: DataExtractService
	) {
		this.uiService.dataExtract$.pipe(takeUntil(this.destroy)).subscribe(
			value => {
				this.isVisible = value;
			}
		);
	}

	ngOnInit() { }

	set setExtractSelection(extractTypeId: string) {
		const title = 'FIT Data Extracts';
		this.extractSelection = this.service.types.find(e => e.id === extractTypeId);

		// reset
		if (!this.extractSelection) {
			this.extractSelectionTitle = title;
			this.extractSelectionYears = null;
			return;
		}

		this.extractSelectionTitle = `${title} - ${this.extractSelection.title}`;
		if (this.extractSelection.isYearsBased) {
			this.isLoading = true;
			this.service.getExtractYears(this.extractSelection)
				.pipe(finalize(() => this.isLoading = false))
				.subscribe(x => this.extractSelectionYears = x);
		}
	}

	onResized(e) {
		setTimeout(() => {
			if (this.popup != null && this.popup.instance != null) {
				this.popup.instance.option('position', {at: 'center'});
			}
		});
	}

	@HostListener('window:resize', ['$event'])
	setScrollViewHeight(event?: any) {
		if (!this.popup.instance || !this.scrollView) {
			return;
		}
		const popupContent          = this.popup.instance.content();

		// @ts-ignore - style is missing from typescript definition
		const popupContentMaxHeight = parseFloat(popupContent.style.maxHeight);
		const popupContentHeight    = popupContent.clientHeight;
		let nonScrollableHeight: any;
		if (this.nonScrollableContent) {
			nonScrollableHeight = this.nonScrollableContent.nativeElement.clientHeight;
		}

		// This is the potential new fixed height of the sclview (if needed, otherwise set to 'auto')
		const newScrollViewHeight = popupContentMaxHeight - nonScrollableHeight;

		if (newScrollViewHeight < popupContentHeight) {
			this.scrollView.instance.option('height', newScrollViewHeight + 'px');
		}
		else {
			this.scrollView.instance.option('height', 'auto');
		}
		this.scrollView.instance.update();
	}

	ngOnDestroy() {
		this.destroy.next();
		this.destroy.complete();
	}

	close = () => {
		this.isVisible = false;
		this.setExtractSelection = null;
	}

}
