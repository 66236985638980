import { Pipe, PipeTransform } from '@angular/core';
import { PrimaryGovernmentPipe } from './primary-government.pipe';
import { Tab } from 'app/shared/models/tab';

@Pipe({
	name: 'latestMetric'
})
export class LatestMetricPipe implements PipeTransform {

	constructor(
		private primaryGovernment: PrimaryGovernmentPipe
	) { }

	transform(tab: Tab, metricKey: string, mcag: string = null) {
		let gov;

		if (!mcag) {
			gov = this.primaryGovernment.transform(tab);
		} else {
			gov = tab.governments.find(function (obj) {
				return obj.mcag === mcag;
			});
		}

		if (!gov || !gov.metrics) {
			return null;
		}

		const latest = gov.metrics.reduce(function (acc, obj) {
			if (obj[metricKey] && obj.year > acc.Year) {
				return obj;
			} else {
				return acc;
			}
		}, { Year: 0 });

		const value = latest[metricKey] || null;

		return { value: value, year: latest.year };
	}

}
