import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Tab} from 'app/shared/models/tab';
import {ExternalCommonServiceApiService} from '../../../modules/api/external-common-service-api/external-common-service-api.service';
import {GovTypes} from '../../../modules/api/external-common-service-api/models/gov-types';
import {FitActionsService} from '../../../shared/services/fit-actions.service';
import {FitApiService} from '../../../modules/api/fit-api/fit-api.service';
import {ReportId} from '../../../shared/models/report-id';
import {FundCategoryId} from '../../../modules/services/fund-service/models/fund-category';
import {TabService} from '../../../shared/services/tab.service';
import {GovernmentTypes} from '../../../modules/api/fit-api/models/government-types';
import {SnapshotId} from '../../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector:    'app-government-type-profile',
	templateUrl: './government-type-profile.component.html',
	styleUrls:   ['./government-type-profile.component.scss']
})
export class GovernmentTypeProfileComponent implements OnInit, OnChanges {

	@Input() tab: Tab;

	govTypeCode: string;
	snapshotId: SnapshotId;
	canHaveIndicators = false;

	financialsDatasetSource: string;
	liveGovTypeDetails: GovTypes;

	hasDataChildren = {
		financialHealthIndicatorStatistics: true,
		governmentalServices: true,
		enterpriseServices:   true
	};

	/**
	 * Store the click actions needed by children. Only regenerate them when the tab changes.
	 */
	clickActions = {
		filerReport:   undefined as () => void,
		govProfile:    undefined as (mcag: string, snapshotId?: number) => void,
		summaryReport: undefined as (reportId: ReportId, displayYear?: number, fieldTransformations?: Array<any>) => void,
		indicatorReport: undefined as ReturnType<FitActionsService['navigateToIndicatorReport']>
	};

	constructor(
		private common: ExternalCommonServiceApiService,
		private fitApi: FitApiService,
		private tabService: TabService,
		public fitActions: FitActionsService
	) {
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tab?.currentValue) {
			this.tab.snapshotIdSubject.subscribe(snapshotId => {
				this.govTypeCode = this.tab.govTypes[0];
				this.snapshotId  = this.tab.snapshotId;
				this.generateClickActions();

				this.fitApi.getAnnualFilingSnapshot(snapshotId).subscribe(snapshot => {
					const govTypeFlags           = snapshot.detail.governmentTypes.find(x => x.code === this.govTypeCode);
					this.canHaveIndicators       = govTypeFlags.canHaveIndicators;
					this.financialsDatasetSource = govTypeFlags.financialsDatasetSource;
				});

				this.updateData();
			});
		}
	}

	navigateToTearSheet(): void {
		console.log('SID: ' + this.tab.snapshotId + '//' +  'GTC: ' + this.govTypeCode );
		window.open(`${window.location.origin}${window.location.pathname}government-type-tear-sheet/${this.govTypeCode}/${this.tab.snapshotId}`);
	}

	updateData() {
		this.common.getGovTypeDetailsForGovType(this.govTypeCode).subscribe(
			govTypeCode => this.liveGovTypeDetails = govTypeCode
		);
	}

	private generateClickActions = () => {
		this.clickActions = {
			filerReport:   this.fitActions.launchFilerReport(this.tab),
			govProfile:    this.fitActions.navigateToGovProfile(this.tab),
			summaryReport: this.fitActions.navigateToSummaryReport(this.tab, [this.govTypeCode]),
			indicatorReport: this.fitActions.navigateToIndicatorReport(this.tab)
		};
	}

}
