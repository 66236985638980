<dx-popup
	class="tab-reuse-dialog__popup"
	width="50%"
	height="auto"
	[visible]="modalData.isVisible"
	title="Confirmation"
	(onInitialized)="handlePopupInit($event)"
	(onHiding)="handleAction('cancel')"
>
	<div *dxTemplate="let data of 'content'" class="tab-reuse-dialog__popup__content">
		<h3>{{ modalData.title }}</h3>
		<p>{{ modalData.prompt }}</p>
		<dx-button text="Create New Tab" (click)="handleAction('new')" class="tab-reuse-dialog__popup__content__create-new"></dx-button>
		<wasao-hint icon="'question'">{{ modalData.newHint }}</wasao-hint>
		<dx-button text="Replace Existing Tab" (click)="handleAction('replace')" class="tab-reuse-dialog__popup__content__update-existing"></dx-button>
		<wasao-hint icon="'question'">{{ modalData.replaceHint }}</wasao-hint>
	</div>
</dx-popup>
