import { Input, Directive } from '@angular/core';
import {Reusable} from './reusable';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

/**
 * Supplies all members of Reusable plus govTypeCode and snapshotId Inputs.
 */
@Directive()
export class ReusableGovernmentTypeInputs extends Reusable {
	@Input() govTypeCode: string;
	@Input() snapshotId: SnapshotId;
}
