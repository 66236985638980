import { Component, OnInit } from '@angular/core';
import { AdminNotifyHubService } from 'app/shared/services/admin-notify-hub.service';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

	min = 0;
	max: number;
	value: number;
	active = false;
	isHidden = false;

	constructor(private adminNotify: AdminNotifyHubService) {
		this.adminNotify.dataExtractProgress.subscribe(update =>
			this.update(update.value, update.max)
		);
	}

	update(value: number, max: number) {
		this.value = value;
		this.max = max;
		this.active = max !== null && value !== max;
	}

	hide() {
		this.isHidden = true;
	}

	ngOnInit() {
	}

}
