import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {PeerSet} from '../models/peer-set';
import {StorageService} from './storage.service';
import {LoggerService} from './logger.service';
import {UserInterfaceService} from './user-interface.service';
import {UserService} from './user.service';
import {delay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {User} from '../models/user';
import {Peer} from '../models/peer';

export const maxComparisonPeers = 5;
export const minComparisonPeers = 1;

@Injectable({
	providedIn: 'root'
})
export class PeerSetsService {

	private _peerSets: Array<PeerSet>;
	public peerSets = new Subject<Array<PeerSet>>();
	private _user: User;

	// Popup visibility
	public peerSetViewerVisibility = new Subject<boolean>();

	isSelectMode = new Subject<boolean>();
	/**
	 * Emits the PeerSet when the user clicks the "select" button.
	 */
	selection = new Subject<PeerSet>();

	/**
	 * Call by component to initiate a create scenario. PeerSetViewer subscribes and receives the value.
	 */
	create = new Subject<Array<Peer>>();

	constructor(
		private uiService: UserInterfaceService,
		private storageService: StorageService,
		private logger: LoggerService,
		private userService: UserService
	) {
		// Watch for user change to retrieve the current user's peer sets
		this.userService.user.pipe(
			tap(user => this._user = user),
			switchMap(user =>
				this.storageService.peerSets
					.where({ userId: user.id }) // only retrieve this user's peers
					// can't where and sortBy at the same time
					// https://github.com/dfahlander/Dexie.js/issues/297
					// .sortBy('name') // sort alphabetically
					.toArray()
			)
		).subscribe(peerSets => this.peerSets.next(
			this._peerSets = peerSets.sort(
				(a, b) => a.name.localeCompare(b.name))
			)
		);
	}

	openViewer = () => this.peerSetViewerVisibility.next(true);

	/**
	 * Open the PeerSetViewer and provides a subscription for when the user selects a PeerSet. Automatically
	 * unsubscribes if the dialog is closed, or requested by another component.
	 */
	openViewerForSelect = (): Observable<PeerSet> => {
		this.peerSetViewerVisibility.next(true);
		this.isSelectMode.next(true);
		return this.selection.pipe(
			// cancel if the visibility changes (close or another component opens)
			takeUntil(this.peerSetViewerVisibility),
			// unsubscribe after first value
			take(1),
			// push tap onto next digest
			delay(0),
			// turn off select mode and close PeerSetViewer
			tap(() => {
				this.isSelectMode.next(false);
				this.peerSetViewerVisibility.next(false);
			})
		);
	}

	openEditorForCreate = (peers: Array<Peer>): void => {
		// this.logger.log(`PeerSetsService received peers`, peers);
		this.create.next(peers);
	}

	/**
	 * Add a PeerSet to My Saved Peer Sets
	 *
	 * @param peerSet
	 */
	add(peerSet: PeerSet) {
		// Set user id explicitly
		peerSet.userId = this._user.id;

		this.storageService.peerSets.add(peerSet).then(id => { // add to database
			Object.assign(peerSet, id); // push autoincrement id onto peerSet
			this._peerSets.push(peerSet); // Add to private array
		}, rejectReason => {
			this.logger.error('PeerSetService: Could not add peer set! This peer set may not exist on reload.', rejectReason);
		});
	}

	/**
	 * Save/update a PeerSet to My Saved Peer Sets
	 *
	 * @param peerSet
	 */
	save(peerSet: PeerSet) {
		this.storageService.peerSets.update(peerSet.id, peerSet).then(updated => {
			if (updated) { // 1 if update successful, 0 if not found or identical data
				const existingPeerSet = this._peerSets.find(x => x.id === peerSet.id); // find in private array
				Object.assign(existingPeerSet, peerSet); // assign, in case object reference has been broken
				this.peerSets.next(this._peerSets); // emit to subscribers
			}
		});
	}

	/**
	 * Delete a PeerSet from My Saved Peer Sets
	 *
	 * @param peerSet
	 */
	delete(peerSet: PeerSet): void {
		this.storageService.peerSets.delete(peerSet.id).then(() => {
			this._peerSets = this._peerSets.filter(x => x.id !== peerSet.id); // filter to prevent breaking array reference
			this.peerSets.next(this._peerSets); // emit to subscribers
		});
	}
}
