import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TabService} from '../../../shared/services/tab.service';
import {LoggerService} from '../../../shared/services/logger.service';
import {RouteAction} from '../route-action';
import {SnapshotService} from '../../../shared/services/snapshot.service';
import {convertSnapshotIdString, SnapshotId} from '../../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector:  'app-new-government-profile-tab',
	template:  '',
	styleUrls: []
})
export class NewGovernmentProfileTabComponent extends RouteAction implements OnInit {

	constructor(
		private log: LoggerService,
		private tabService: TabService,
		private route: ActivatedRoute,
		private snapshotService: SnapshotService,
		private router: Router
	) {
		super();
	}

	/**
	 * Open government profile tab, and replace the history state to '/'
	 */
	ngOnInit() {
		const mcag       = this.route.snapshot.paramMap.get('mcag') as string;
		const snapshotId = convertSnapshotIdString(this.route.snapshot.paramMap.get('snapshotId'));

		this.log.log(`Creating new government profile tab based on route. mcag: ${mcag}, snapshotId: ${snapshotId}`);

		this.tabService.areTabsLoaded.subscribe(loaded => {
			if (loaded) {
				this.tabService.buildNewGovernmentProfileTab(mcag, snapshotId).then(tab => {
					this.tabService.setSelectedTab(tab);
					this.tabService.save(tab);
					this.router.navigateByUrl('');
				});
			}
		});
	}

}
