import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnnualFilingCurrentProgressComponent} from './annual-filing-current-progress.component';
import {LoadingModule} from '../../ui/loading/loading.module';
import {
	DxPopupModule,
	DxChartModule,
	DxDataGridModule,
	DxScrollViewModule,
	DxVectorMapModule,
	DxSelectBoxModule
} from 'devextreme-angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AnnualFilingFootnotesModule} from '../annual-filing-footnotes/annual-filing-footnotes.module';
import {AnnualFilingExplanationModule} from '../annual-filing-explanation/annual-filing-explanation.module';
import {AnnualFilingFooterModule} from '../annual-filing-footer/annual-filing-footer.module';

@NgModule({
	declarations: [
		AnnualFilingCurrentProgressComponent
	],
	imports: [
		CommonModule,
		LoadingModule,
		DxPopupModule,
		DxChartModule,
		DxDataGridModule,
		FontAwesomeModule,
		DxScrollViewModule,
		DxVectorMapModule,
		DxSelectBoxModule,
		AnnualFilingFootnotesModule,
		AnnualFilingExplanationModule,
		AnnualFilingFooterModule
	],
	exports: [
		AnnualFilingCurrentProgressComponent
	]
})
export class AnnualFilingCurrentProgressModule {
}
