<div *ngIf="resource">
	<div *ngIf=" resource.type === 'Link' else elseBlock">
		<!-- link part-->
		<dx-popup
			[width]="'100vh'"
			[height]="'auto'"
			[dragEnabled]="true"
			[showCloseButton]="true"
			[(visible)]="isVisible"
			[closeOnOutsideClick]="true"
			[position]="{ my: 'center', at: 'center', of:'window'}"
			title="{{resource.title}}">
			<div class="app-popup__wrapper">
				<div class="container">
					<img alt='resource image' src='data:image/jpeg;base64,{{resource.image}}'>
					<dx-button
						class="btn"
						id="button"
						hoverStateEnabled="true"
						text="{{resource.linkText}}"
						type="default"
						(onClick)="handleResourceLinkClick()"
					></dx-button>
				</div>
				<div class="app-popup__footer">
					<dx-button
						text="Close"
						type="default"
						(onClick)="isVisible = false"
					></dx-button>
				</div>
			</div>
		</dx-popup>
	</div>

	<ng-template #elseBlock>
		<!-- embed part-->
		<dx-popup
			[minWidth]="600"
			[maxWidth]="'80%'"
			[width]="'auto'"
			[height]="600"
			[dragEnabled]="true"
			[showCloseButton]="true"
			[(visible)]="isVisible"
			[closeOnOutsideClick]="true"
			title="{{resource.title}}"
			[position]="{ my: 'center', at: 'center', of:'window'}"
		>
			<div class="app-popup__wrapper">
				<div class="app-popup__inner">
					<div class="embed" [innerHTML]="urlSafe"></div>
					<p class="embedText" >{{resource.description}}</p>
				</div>
				<div class="app-popup__footer">
					<dx-button
						text="Close"
						type="default"
						(onClick)="isVisible = false"
					></dx-button>
				</div>
			</div>
		</dx-popup>
	</ng-template>

</div>
