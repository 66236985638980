// TODO place in Design System

import { EventEmitter, HostBinding, Input, Output, Directive } from '@angular/core';

export type DisplayDensity = 'normal' | 'compact' | 'print';

/**
 * Properties that facilitate making a Component reusable. This includes Inputs to enable the consumer to specify behavior.
 */
@Directive()
export class Reusable {

	// Display Density controls how the component is displayed by adding the appropriate class to the host.
	// Keep private property so that @HostBinding can access appropriately (function gets stringified, not executed).
	private _displayDensity: DisplayDensity = 'normal';
	/**
	 * How to display the Component.
	 */
	@Input('displayDensity')
	get displayDensity(): DisplayDensity { return this._displayDensity; }
	set displayDensity(val: DisplayDensity ) { this._displayDensity = val; }
	@HostBinding('class.display-density--normal')
	get isDisplayDensityNormal() { return this._displayDensity === 'normal'; }
	@HostBinding('class.display-density--compact')
	get isDisplayDensityCompact() { return this._displayDensity === 'compact'; }
	@HostBinding('class.display-density--print')
	get isDisplayDensityPrint() { return this._displayDensity === 'print'; }

	/**
	 * Enable interactive/clickable features by default.
	 */
	@Input() isInteractive = true;

	/**
	 * Whether to show the Component when there is no content to display.
	 * ~~CAUTION~~
	 * This should be used sparingly, as most Reusable components should display "no data" to prevent the sense of
	 * hiding data from the user.
	 */
	@Input() hideWhenContentEmpty = false;


	/**
	 * Use to show and hide a DxLoadIndicator/DxLoadPanel and related elements during fetch and/or processing.
	 */
	isLoading = false;

	/**
	 * Allow the component to communicate its state back to parent. Receiving this event implies that the component
	 * has completed any data fetch/business logic.
	 */
	@Output() hasData = new EventEmitter<boolean>();

	/**
	 * Object containing states of the children you'd like to keep track of. Values are limited to booleans.
	 */
	hasDataChildren: { [key: string]: boolean } = {};

	/**
	 * Hook up to hasData Output of each child. Aggregates these values to provide hasData for the parent, as a whole.
	 * E.g. <child [hasData]="aggregateData($event, 'myChild')"></child>
	 * @param event
	 * @param child
	 */
	aggregateHasData = (event: boolean, child: string) => {
		this.hasDataChildren[child] = event;
		let hasData = false;
		for (const key in this.hasDataChildren) {
			if (this.hasDataChildren[key] === true) {
				hasData = true;
				break;
			}
		}
		this.hasData.emit(hasData);
	}
}
