import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {DxPivotGridComponent, DxPopupComponent} from 'devextreme-angular';
import {Tab} from 'app/shared/models/tab';
import {LoggerService} from 'app/shared/services/logger.service';
import {UnitsService} from 'app/shared/services/units.service';
import {PivotGridService} from 'app/shared/services/pivot-grid.service';
import {isEqual, some} from 'lodash';
import {ReportId} from '../../shared/models/report-id';

@Component({
	selector:    'app-schedule-browser-chart',
	templateUrl: './schedule-browser-chart.component.html',
	styleUrls:   ['./schedule-browser-chart.component.scss']
})
export class ScheduleBrowserChartComponent implements OnInit {
	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	@Input() tab: Tab;
	@Input() reportId: ReportId;
	@Input() summary: any;
	@Input() popupTitle: string;
	@Input() pivotGridComponent: DxPivotGridComponent;

	isVisible = null;

	chartSubTitle: string;

	private areChartsBound = false;
	private pivotGridState: any;
	private today: Date    = new Date();
	private isExporting    = false;
	private column         = 'Amounts';

	scheduleBrowserChartComponent: any;

	fontFamily           = '"Roboto", sans-serif';
	fontColor            = '#646464';
	chartMargin          = 30;
	minHeightSingleChart = 435;
	chartColorPalette    = [ // Profile Doughnut colors
		'#53acdf',
		'#b8d5e2',
		'#929938',
		'#fbda8b',
		'#4a9e82',
		'#48346c'
	];

	constructor(
		private logger: LoggerService,
		private units: UnitsService,
		private pivotGridService: PivotGridService,
	) {
	}

	ngOnInit() {
		this.logger.log(this);
		this.pivotGridService.getChartVisibility(this.tab).subscribe(isVisible => this.isVisible = isVisible);

		if (this.reportId === 'debtAndLiabilities') {
			this.column = 'Ending Balance';
		}
	}

	handleChartInitialize(event) {
		this.scheduleBrowserChartComponent = event.component;
		this.logger.info('schedule-browser-chart.handleChartInitialize');
	}

	handleChartPopupHidden() {
		this.pivotGridService.setChartVisibility(this.tab, false);
	}

	@HostListener('window:resize', ['$event'])
	setChartHeight(event: any) {
		if (!this.popup || !this.scheduleBrowserChartComponent) {
			return;
		}

		const popupContent = this.popup.instance.content();

		const popupContentHeight = popupContent.clientHeight;
		const numPanes         = this.scheduleBrowserChartComponent.panes.length;

		// Take max of minHeight or space in the popup; handle case where there are two panes (don't need as much space because titles are not repeated)
		const dxChartHeight = Math.max(popupContentHeight, this.minHeightSingleChart + ((numPanes - 1) * this.minHeightSingleChart * 0.75));

		this.scheduleBrowserChartComponent.option('size.height', dxChartHeight);
	}

	customizeTooltip = (arg: any) => {
		const isPct: boolean = arg.seriesName.indexOf('%') >= 0;
		return {
			text: (isPct ? arg.percentText : arg.valueText) + '\n' + arg.seriesName.replace(` | ${this.column}`, '') + '\n' + arg.argumentText
		};
	};

	customizeLegend = (arg: any) => arg.seriesName.replace(` | ${this.column}`, '');

	handleExportPrep(event) {
		const self = this;

		if (self.isExporting) {
			return;
		}

		event.cancel     = true;
		self.isExporting = true;

		const title    = event.component.option('title.text');
		const subtitle = event.component.option('title.subtitle.text');

		const annotation = ' \nOffice of the Washington State Auditor - Financial Intelligence Tool - Report prepared:' + self.today.toLocaleString();

		// Set new option values for export
		event.component.option('animation', false);
		event.component.option('title.text', self.summary.title);
		event.component.option('title.subtitle.text', self.summary.info + annotation);

		// Export
		event.component.exportTo(event.fileName, event.format);

		// Restore original values
		event.component.option('title.text', title);
		event.component.option('title.subtitle.text', subtitle);
		event.component.option('animation', true);

		self.isExporting = false;
	}

	handleChartPopupShown() {
		const self = this;

		this.setChartHeight('');

		if (!this.areChartsBound) {
			const result = this.pivotGridComponent.instance.bindChart(this.scheduleBrowserChartComponent, {
				inverted:              false,
				dataFieldsDisplayMode: 'splitPanes',
				putDataFieldsInto:     'series',
				alternateDataFields:   true,
				processCell:           (cellData) => {
					// self.logger.info('schedule-browser-chart.handleChartPopupShown bindChart.processCell args:', cellData);
					this.pivotGridState = this.pivotGridComponent.instance.getDataSource().state();

					cellData.visible = true; // By default, everything is visible on chart
					if ((Array.isArray(cellData.columnPath) && cellData.columnPath.length === 0)
						|| (Array.isArray(cellData.rowPath) && cellData.rowPath.length === 0)) {
						cellData.visible = false; // This handles grand totals (makes them not visble on the chart)
					}

					if ((Array.isArray(cellData.columnPath) && some(this.pivotGridState.columnExpandedPaths, function(path) {
							return isEqual(path, cellData.columnPath);
						}))
						|| (Array.isArray(cellData.rowPath) && some(this.pivotGridState.rowExpandedPaths, function(path) {
							return isEqual(path, cellData.rowPath);
						}))) {
						cellData.visible = false; // This handles any row or column subtotals when visible on the pivotGrid (makes them not visble on the chart)
					}

					// Handle expand all scenario where the row/columnExpandedPaths don't include the individual values,
					// but in fields array on pivotGridState, the field.expanded property is true
					const rowFieldIndex = cellData.rowPath.length - 1;
					if (rowFieldIndex >= 0 && some(this.pivotGridState.fields, {
						'dataField': cellData.rowFields[rowFieldIndex].dataField,
						'expanded':  true
					})) {
						cellData.visible = false;
					}
					const colFieldIndex = cellData.columnPath.length - 1;
					if (colFieldIndex >= 0 && some(this.pivotGridState.fields, {
						'dataField': cellData.columnFields[colFieldIndex].dataField,
						'expanded':  true
					})) {
						cellData.visible = false;
					}

					return cellData;
				},
				customizeChart:        chartOpts => {
					// self.logger.info('schedule-browser-chart.handleChartPopupShown bindChart.customizeChart args:', chartOpts);
					const pcOfTotal          = '% of Total';
					const pcOfTotalPane      = chartOpts.panes.find(p => p.name === pcOfTotal);
					const pcOfTotalValueAxis = chartOpts.valueAxis.find(v => v.name === pcOfTotal);

					const pctDifference          = '% Difference';
					const pctDifferencePane      = chartOpts.panes.find(p => p.name === pctDifference);
					const pctDifferenceValueAxis = chartOpts.valueAxis.find(v => v.name === pctDifference);

					const amtDifference          = '$ Difference';
					const amtDifferencePane      = chartOpts.panes.find(p => p.name === amtDifference);
					const amtDifferenceValueAxis = chartOpts.valueAxis.find(v => v.name === amtDifference);

					chartOpts.panes     = [chartOpts.panes.find(p => p.name.indexOf(this.column) >= 0)];
					chartOpts.valueAxis = [chartOpts.valueAxis.find(v => v.name.indexOf(this.column) >= 0)];

					// Don't show 'Amounts' / this.column title
					chartOpts.valueAxis[0].title = '';

					if (pcOfTotalPane) {
						chartOpts.panes.push(pcOfTotalPane);
						chartOpts.valueAxis.push(pcOfTotalValueAxis);
					}

					if (pctDifferencePane) {
						chartOpts.panes.push(pctDifferencePane);
						chartOpts.valueAxis.push(pctDifferenceValueAxis);
					}

					if (amtDifferencePane) {
						chartOpts.panes.push(amtDifferencePane);
						chartOpts.valueAxis.push(amtDifferenceValueAxis);
					}

				},
				customizeSeries:       function() {
					// self.logger.info('schedule-browser-chart.handleChartPopupShown bindChart.customizeSeries args:', arguments);
					arguments[0] = arguments[0].replace(` | ${self.column}`, '');
				}
			});
			this.logger.info('schedule-browser-chart.handleChartPopupShown bindChart run, result:', result);
			this.areChartsBound = true;
		}
		else {
			this.logger.info('schedule-browser.handleChartPopupShown re-rendered chart.');
		}
	}

}
