<button *ngIf="dataStory" (click)="showPopup()">
	<fa-icon [icon]="icons.youtube"></fa-icon>
</button>

<dx-popup
	class="popup"
	width="auto"
	height="auto"
	[(visible)]="isPopupVisible"
	[title]="dataStory?.title"
	closeOnOutsideClick=true
>
	<div *dxTemplate="let data of 'content'">
		<div class="popup__content">
			<div class="responsive-video">
				<iframe width="560" height="315" [src]="videoUrl" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
			<p>{{ dataStory?.description }}</p>
			<dx-button
				*ngIf="isInteractive && clickHandler"
				[text]="clickHandlerText"
				type="default"
				(onClick)="clickHandler()"
			></dx-button>
		</div>
	</div>
</dx-popup>
