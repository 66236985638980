import {Injectable} from '@angular/core';
import {LngLatBoundsLike, Layer, LngLatLike} from 'mapbox-gl';
import {environment} from '../../../../environments/environment';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {GovernmentService} from '../../services/government-service/government.service';
import {forkJoin, merge, Observable, of} from 'rxjs';
import {LoggerService} from '../../../shared/services/logger.service';
import {Feature, FeatureCollection} from 'geojson';
import {ExternalCommonServiceApiService} from '../../api/external-common-service-api/external-common-service-api.service';
import {GovernmentShape} from './models/government-shape';
import {MandatoryFieldsEntity} from '../../api/external-common-service-api/models/view-models/mandatory-fields-entity';
import {CustomProperties} from './models/custom-properties';
import {EntityGeoCoordinates} from '../../api/external-common-service-api/models/entity-geo-coordinates';
import {LookupListItem} from '../../api/external-common-service-api/models/view-models/lookup-list-item';
import {forGovernmentType} from '../../../../../sao-patterns/src/tokens/government-colors.js';

@Injectable({
	providedIn: 'root'
})
export class MapService {
	// https://anthonylouisdagostino.com/bounding-boxes-for-all-us-states/
	static washingtonStateBounds: LngLatBoundsLike = [-124.763068, 45.543541, -116.915989, 49.002494];
	static washingtonStateCentroid: LngLatLike = [-120.4472, 47.3826];
	// https://docs.mapbox.com/api/search/#data-types
	static mapboxFeatureTypesOption = '&types=country,region,district,postcode,locality,place,address';

	// fall back to production if no environment file present
	private shapefilesApi = environment?.apis?.externalLGData ?? `https://portal.sao.wa.gov/gisdata/api/v2`;
	private mapboxApi = environment?.apis?.mapbox ?? 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
	private mapboxApiKey = environment?.mapboxAccessToken;

	/**
	 * Get info on all governments from CommonData and cache result.
	 */
	private mandatoryFields = this.commonData.getEntitiesMandatoryFields()
		.pipe(shareReplay(1));

	private governmentTypes = this.commonData.getGovernmentTypes()
		.pipe(shareReplay(1));

	private commonFields = forkJoin({ mandatoryFields: this.mandatoryFields, governmentTypes: this.governmentTypes});

	constructor(
		private http: HttpClient,
		private governmentService: GovernmentService,
		private logger: LoggerService,
		private commonData: ExternalCommonServiceApiService
	) {
		// fire on construction so that there is no perceptible delay when accessing entity info
		this.mandatoryFields.subscribe();
		this.governmentTypes.subscribe();
	}

	/**
	 * Gets the latest PropertyTaxBoundaries shapefiles year and caches value.
	 */
	private latestBoundariesYear = this.http
		.get(`${this.shapefilesApi}/PropertyTaxBoundaries?$orderby=year desc`)
		.pipe(
			map((response: any) => response.value[0].year as number),
			shareReplay(1)
		);

	/**
	 * Gets the latest CensusCounties shapefiles year and caches value.
	 */
	private latestCountiesYear = this.http
		.get(`${this.shapefilesApi}/CensusCounties?$orderby=year desc`)
		.pipe(
			map((response: any) => response.value[0].year as number),
			shareReplay(1)
		);

	/**
	 * Counties have their own endpoint.
	 * @param filter - OData filter clause
	 * @param tolerance
	 */
	private getCountyShapes = (filter?: string, tolerance = '0.005'): Observable<Array<GovernmentShape>> =>
		this.latestCountiesYear.pipe(
			switchMap(year => {
				filter = filter ? `&$filter=${filter}` : '';
				return this.http.get(
					`${this.shapefilesApi}/CensusCounties(${year})/Shapes/Simplify(Tolerance=${tolerance})?$expand=geometry${filter}`
				).pipe(map((response: any) => response.value));
			})
		)
	;

	/**
	 *
	 * @param filter - OData filter clause
	 * @param tolerance
	 */
	private getShapes = (filter: string, tolerance = '0.001'): Observable<Array<GovernmentShape>> =>
		this.latestBoundariesYear.pipe(
			switchMap(year => {
				filter = filter ? `&$filter=${filter}` : '';
				return this.http.get(
					`${this.shapefilesApi}/PropertyTaxBoundaries(${year})/Boundaries/Simplify(Tolerance=${tolerance})?$expand=Geometry${filter}`
				).pipe(map((response: any) => response.value));
			})
		)
	;

	/**
	 * Tests for 5 or 5-4 zip codes.
	 * @param zip
	 */
	isValidZip = (zip: string) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);

	getSourceForGovType(govTypeCode: string, customProperties: Array<CustomProperties> = null) {
		const shapeObservable = this.governmentService.isCounty(govTypeCode)
			? this.getCountyShapes()
			: this.getShapes(`govTypeCode eq '${govTypeCode}'`);

		return this.commonFields.pipe(
			switchMap(commonFields => {
				// filter down governments by govType
				const governments = commonFields.mandatoryFields.filter(x => x.GovTypeCode === govTypeCode);
				return forkJoin({
					shapes: shapeObservable,
					coords: this.commonData.getCoordinatesForGovTypes([govTypeCode])
				}).pipe(
					map(join => {
						return this.buildFeatureCollection(join.shapes, join.coords, governments, commonFields.governmentTypes, customProperties);
					})
				);
			})
		);
	}

	// todo refactor getSourceForGovernment to return a FeatureCollection with a single shape
	getSourceForGovernment(mcag: string, customProperties: Array<CustomProperties> = null) {
		// We first query CommonData for govTypeCode since LGExternalData does not contains snapshots
		return this.commonFields.pipe(
			switchMap(commonFields => {
				// find this specific government
				const government = commonFields.mandatoryFields.find(x => x.MCAG === mcag);
				if (!government) {
					this.logger.warn(`${this.constructor.name}.getShapeForGovernment: No record for ${mcag} was found in ExternalCommonService!`);
				}
				return this.getSourceForGovType(government.GovTypeCode, customProperties);
			})
		);
	}

	/**
	 * Determine if the given govType has any shapefiles.
	 * @param govTypeCode
	 */
	hasAnyShapes(govTypeCode: string): Observable<boolean> {
		// a bit of a cheat, we always know counties have shapes, so query only boundaries
		return this.latestBoundariesYear.pipe(
			switchMap(year => {
				const filter = `$filter=govTypeCode ne null&$apply=groupby((govTypeCode))`;
				return this.http.get(
					`${this.shapefilesApi}/PropertyTaxBoundaries(${year})/Boundaries?${filter}`
				).pipe(map((response: any) => {
					return govTypeCode === '06' || // hardcoded for county
						response.find(x => x.govTypeCode === govTypeCode) !== undefined; // look through result for others
				}));
			})
		);
	}

	/**
	 * Build a FeatureCollection from GIS endpoint model
	 * @param shapes
	 * @param coords
	 * @param governments
	 * @param customProperties
	 */
	private buildFeatureCollection(
		shapes: Array<GovernmentShape>,
		coords: Array<EntityGeoCoordinates>,
		governments: Array<MandatoryFieldsEntity>,
		governmentTypes: Array<LookupListItem>,
		customProperties: Array<CustomProperties> = null
	): FeatureCollection {
		// this.logger.log(`${this.constructor.name} before processing`, govGeometries);

		const featureCollection: FeatureCollection = {
			type: 'FeatureCollection',
			features: []
		};

		// Because shapes can show up under multiple countyIds, filter out any duplicates
		// This has the effect of randomly picking a county association for govs with multiple associations
		shapes = shapes.filter((item, index) =>
			shapes.findIndex(x => x.mcag === item.mcag) === index
		);

		// Begin with governments since that will be all active

		governments.forEach(government => {
			const shape = shapes.find(x => x.mcag === government.MCAG);
			const govCoords = coords.find(x => x.Mcag === government.MCAG);
			const customPropertiesForMCAG = customProperties?.find(x => x.mcag === government.MCAG);
			const govTypeDesc = governmentTypes.find(x => x.Value === government.GovTypeCode)?.Text;
			// Build a Feature from the shapefile, if we have it. Otherwise, use the government's coordinates.
			const feature = shape != null
				? this.buildFeatureForShape(shape, government, govTypeDesc, customPropertiesForMCAG)
				: this.buildFeatureForCoords(govCoords, government, govTypeDesc, customPropertiesForMCAG);
			featureCollection.features.push(feature);
		});

		// sort from largest to smallest area to prevent overlapping
		featureCollection.features.sort((a, b) => b?.properties?.area - a?.properties?.area);

		return featureCollection;
	}

	// Build feature from GIS model
	buildFeatureForShape(shape: GovernmentShape, government: MandatoryFieldsEntity, govTypeDesc: string, customProperties: CustomProperties = null): Feature {
		if (shape == null) {
			return null;
		}

		// Grab the centroid to use as anchor for click/mouseover events
		const lngLat = shape.geometry?.centroid?.type === 'Point'
			? shape.geometry?.centroid?.coordinates
			: null;

		const feature: Feature = {
			type: 'Feature',
			id: shape.mcag,
			geometry: shape.geometry?.shape,
			properties: {
				name: government?.EntityDBA ?? government?.EntityName,
				// this is meaningless since we have to remove duplicate shapes
				// countyId: shape.countyId
				mcag: shape.mcag,
				govTypeCode: government?.GovTypeCode,
				govTypeDesc: govTypeDesc,
				lngLat: lngLat,
				area: shape.geometry.area
			}
		};

		// Apply the provided custom properties
		if (customProperties) {
			Object.assign(feature.properties, customProperties.properties);
		}

		return feature;
	}

	// Build feature from coordinates model
	buildFeatureForCoords(coords: EntityGeoCoordinates, government: MandatoryFieldsEntity, govTypeDesc: string, customProperties: CustomProperties = null): Feature {
		if (coords == null) {
			return null;
		}

		const feature: Feature = {
			type: 'Feature',
			id: coords.Mcag,
			geometry: {
				type: 'Point',
				coordinates: [coords.Longitude, coords.Latitude]
			},
			properties: {
				name: government?.EntityDBA ?? government?.EntityName,
				// this is meaningless since we have to remove duplicate shapes
				// countyId: shape.countyId
				mcag: coords.Mcag,
				govTypeCode: government?.GovTypeCode,
				govTypeDesc: govTypeDesc,
				lngLat: [coords.Longitude, coords.Latitude],
			}
		};

		// Apply the provided custom properties
		if (customProperties) {
			Object.assign(feature.properties, customProperties.properties);
		}

		return feature;
	}

	/**
	 * Returns the Mapbox layers for painting any shapes drawn. This currently detects fillColor in the
	 *
	 * https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/
	 * To inspect Gard's layers, open the map on the main website
	 * https://www.sao.wa.gov/reports-data/explore-governments-that-serve-you/
	 * and run the following in the console:
	 * map.getStyle().layers
	 *
	 * Data expressions can be used in most properties
	 * https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/
	 *
	 */
	getLayers(mcag: string, govTypeCode: string, hasAnyFills = null, showAllOfType = false): Array<Layer> {
		const defaultFill = forGovernmentType(govTypeCode);
		const isCounty = this.governmentService.isCounty(govTypeCode);
		// cast mcag to null since Mapbox doesn't LIKE undefineds 🙄
		mcag = mcag || null;
		return [
			{
				id: 'fill',
				type: 'fill',
				paint: {
					'fill-color': [
						'case', // use fillColor on properties, if exists.
						['has', 'fillColor'],
						['get', 'fillColor'],
						defaultFill // fall back to government type color
					],
					// this would be more efficient than a separate line layer, but this looks different than the Gard
					// implementation
					// 'fill-outline-color': isCounty ? '#000000' : fill,
					'fill-opacity': [
						'case',
						['boolean', ['feature-state', 'hover'], false],
						0.5, // on state
						0.3 // off state
					]
				},
				// show if !hasAnyFills OR hasAnyFills & fillColor present
				// this removes shapes that do not have a fillColor defined
				filter: [
					'all', [
						'case',
						!hasAnyFills, true, // if !hasAnyFills, then show all shapes
						['!=', ['geometry-type'], 'Point'], true, // if not, check if is any other shape than a point
						['has', 'fillColor'] // default to if fillColor present on customProperties,
					], [
						'case',
						showAllOfType === false && mcag != null, // if mcag is present then evaluate
						['==', mcag, ['get', 'mcag']], // if this is the mcag
						true // otherwise show all shapes
					]
				]
			},
			{
				id: 'outline',
				type: 'line',
				paint: {
					// hardcode county lines to black to match website
					// Also black outlines if any fillColors are present
					'line-color': isCounty || hasAnyFills ? '#000000' :
						[
							'case',
							['has', 'fillColor'],
							['get', 'fillColor'],
							defaultFill
						],
					'line-width': 1,
					'line-opacity': isCounty || hasAnyFills ? 0.1 : 1
				},
				filter: ['!=', ['geometry-type'], 'Point']
			},
			{
				id: 'pins',
				type: 'circle',
				paint: {
					'circle-color': [
						'case',
						['has', 'fillColor'],
						['get', 'fillColor'],
						defaultFill
					],
					'circle-radius': 10,
					'circle-stroke-width': 1,
					'circle-stroke-color': '#ffffff',
					'circle-opacity': [
						'case',
						['boolean', ['feature-state', 'hover'], false],
						1,  // on state
						0.8 // off state
					]
				},
				filter: [
					'all',
					['==', ['geometry-type'], 'Point'],
					[
						'case',
						showAllOfType === false && mcag != null, // if mcag is present then evaluate
						['==', mcag, ['get', 'mcag']], // if this is the mcag
						true // otherwise show all shapes
					]
				]
			},
			// {
			// 	id: 'cluster-count',
			// 	type: 'symbol',
			// 	layout: {
			// 		'icon-allow-overlap': false,
			// 		'text-field': 'P: {point_count_abbreviated}',
			// 		'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			// 		'text-size': 12,
			// 		'text-allow-overlap': false,
			// 	},
			// 	paint: {
			// 		'text-color': '#fff',
			// 	},
			// 	filter: ['!=', 'cluster', true],
			// },
			// {
			// 	id: 'pins',
			// 	type: 'symbol',
			// 	layout: {
			// 		'icon-image': 'SAOmarker',
			// 		'icon-size': 1,
			// 		'icon-allow-overlap': true,
			// 		'text-field': ['get', 'mcag'],
			// 		'text-font': [
			// 			'Open Sans Bold',
			// 			'Arial Unicode MS Bold'
			// 		],
			// 		'text-size': 11,
			// 		'text-allow-overlap': true,
			// 	},
			// 	paint: {
			// 		'icon-color': '#fff',
			// 	},
			// 	filter: ['==', ['geometry-type'], 'Point']
			// }
		];
	}

	/**
	 * Given an address, retrieve coordinates from Mapbox.
	 * @param address
	 */
	getCoordinates = (address: string): Observable<LngLatLike> =>
		this.http.get(`${this.mapboxApi}${address}.json?bbox=${MapService.washingtonStateBounds}&country=US${MapService.mapboxFeatureTypesOption}&limit=10&access_token=${this.mapboxApiKey}`)
			.pipe(map((response: any) => {
					const result = response.features.find((feat) => feat.place_name.indexOf('Washington') > -1);
					if (result) {
						return result.center;
					}
					return [];
				})
			);

}


