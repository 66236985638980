<div id="unsupported">
	<a class="seal" href="http://www.sao.wa.gov">
		<img class="sao-seal" width="300" src="assets/images/WSAO_LogoLargerIcon-1.png" alt="SAO Website"/>
	</a>
	<p>
		Your device or browser is reporting a width of {{innerWidth}}.<br/>
		For the best experience, please use this application from a device or browser with a scaled width greater than
		1080.
	</p>
	<img class="fit-logo" width="100" src="assets/images/fit_isolated.svg" alt="Financial Intelligence Tool"/>
</div>

<div id="overlay" class="overlay" [class.overlay--active]="isOverlayActive"></div>

<app-header class="u-hidden-print"></app-header>
<app-login-banner class="u-hidden-print"></app-login-banner>

<app-progress-bar></app-progress-bar>
<app-command-bar class="u-hidden-print"></app-command-bar>

<div class="wrapper" id="wrapper">

	<app-sidebar class="u-hidden-print"></app-sidebar>

	<div class="wrapper__main">
		<app-tabs></app-tabs>
		<app-taskbar></app-taskbar>
		<app-report-menu class="u-hidden-print"></app-report-menu>
		<app-projection-drawer></app-projection-drawer>
	</div>
</div>

<app-tab-reuse-modal></app-tab-reuse-modal>
<app-modal></app-modal>

<div class="popups">
	<app-interactive-filing-statistics></app-interactive-filing-statistics>
	<app-no-special-access></app-no-special-access>
	<app-account-settings></app-account-settings>
	<app-disclaimer></app-disclaimer>
	<app-login></app-login>
	<app-first-time></app-first-time>
	<app-data-extract></app-data-extract>
	<app-about></app-about>
	<app-feedback></app-feedback>
	<app-peer-set-viewer></app-peer-set-viewer>
	<app-test-user-harness></app-test-user-harness>
	<app-bars-rich-text></app-bars-rich-text>
	<app-report-issue></app-report-issue>
	<app-manage-resources></app-manage-resources>
	<app-create-edit-resource></app-create-edit-resource>
	<app-resource-item></app-resource-item>
	<app-shapefile-loader></app-shapefile-loader>
	<app-publish-lgcs-snapshot></app-publish-lgcs-snapshot>
	<app-population-importer></app-population-importer>
</div>

<dx-load-panel
	[visible]="isSnapshotLoading"
	message="One moment while we load data..."
	shadingColor="rgba(255, 255, 255, 0.8);"
></dx-load-panel>
<app-toast></app-toast>
