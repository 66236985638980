import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EnterpriseServicesComponent} from './enterprise-services.component';
import {AmountsByCategoryModule} from '../amounts-by-category/amounts-by-category.module';
import {IncomeByFundModule} from '../income-by-fund/income-by-fund.module';
import {GovernmentServiceModule} from '../../services/government-service/government-service.module';
import {FitApiModule} from '../../api/fit-api/fit-api.module';
import {IncomeByYearModule} from '../income-by-year/income-by-year.module';


@NgModule({
	declarations: [
		EnterpriseServicesComponent
	],
	imports: [
		CommonModule,
		FitApiModule,
		AmountsByCategoryModule,
		IncomeByFundModule,
		GovernmentServiceModule,
		IncomeByYearModule
	],
	exports: [
		EnterpriseServicesComponent
	]
})
export class EnterpriseServicesModule {
}
