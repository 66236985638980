import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {Layer, LngLatBoundsLike, LngLatLike, MapLayerMouseEvent, MapMouseEvent, Map as MapboxMap} from 'mapbox-gl';
import {MapService} from './map.service';
import {Reusable} from '../../reusable/models/reusable';
import {LoggerService} from '../../../shared/services/logger.service';
import {Feature, FeatureCollection} from 'geojson';
import {extent} from 'geojson-bounds';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {CustomProperties} from './models/custom-properties';
import {GovernmentLocation} from './models/government-location';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'wasao-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent extends Reusable implements OnInit, OnChanges {

	icons = {
		warning: faExclamationTriangle
	};

	/**
	 * Action to perform when Gov is clicked.
	 */
	@Input() govClickAction: (mcag: string) => void;

	/**
	 * The MCAG of the Government to display and zoom to.
	 */
	@Input() mcag: string;
	/**
	 * If mcag is passed, then automatically select the government (i.e., show the popup as if the user clicked on the
	 * locator.
	 */
	@Input() autoSelect = false;

	@Input() govTypeCode: string;

	/**
	 * Add additional properties to the GeoJson object for use in layer styling. Providing fillColor will override
	 * default colors. When this property is not null, any MCAGs within the GovType that do not have corresponding
	 * fillColors will have no color and only show an outline.
	 */
	@Input() customProperties: Array<CustomProperties>;

	/**
	 * Calls a function to update some template reference. This would be better handled by just emitting
	 * the hovered/selected government and letting callers implement.
	 */
	@Input() updateTooltip: (mcag: string, title: string) => void;

	/**
	 * If provided, will overrride the built-in "selected government" popup.
	 */
	@Input() tooltip: TemplateRef<any>;

	/**
	 * An additional marker to show a place.
	 */
	@Input() marker: LngLatLike;

	/**
	 * Indicates that the government popup was closed.
	 */
	@Output() popupClosed = new EventEmitter<boolean>();

	map: MapboxMap;
	fitBounds: LngLatBoundsLike = MapService.washingtonStateBounds;
	source: FeatureCollection | Feature;
	layers: Array<Layer>;
	imageLoaded = false;
	cursorStyle: string;
	center: LngLatLike;

	private pointZoomLevel = 12;

	/**
	 * Message to display in loading dialog.
	 */
	loadingMessage: string;
	zoom: [number]; // Must be single-value array https://github.com/Wykks/ngx-mapbox-gl/wiki/API-Documentation#mgl-map-mapbox-gl-api

	/**
	 * Keeps track of selected and hovered governments and emits them to consumers.
	 */
	private _selectedGovernment: GovernmentLocation;
	@Output() governmentSelected = new EventEmitter<GovernmentLocation>();
	get selectedGovernment(): GovernmentLocation {
		return this._selectedGovernment;
	}
	set selectedGovernment(government: GovernmentLocation) {
		this._selectedGovernment = government;
		this.governmentSelected.emit(government);
	}
	private _hoveredGovernment: GovernmentLocation;
	@Output() governmentHovered = new EventEmitter<GovernmentLocation>();
	get hoveredGovernment(): GovernmentLocation {
		return this._hoveredGovernment;
	}
	set hoveredGovernment(government: GovernmentLocation) {
		this._hoveredGovernment = government;
		this.governmentHovered.emit(government);
	}

	// Setup Subjects so that requests are cancellable (via switchMap) when Inputs change in rapid succession
	private showGovernmentsOfType = new Subject<void>();
	private showGovernment = new Subject<void>();
	private updateMapFromInputs = new Subject<void>();

	constructor(
		private mapService: MapService,
		private logger: LoggerService
	) {
		super();

		this.updateMapFromInputs.pipe(
			tap(() => {
				this.showLoadingMessage();
				// reset any selections or hovers
				this.hoveredGovernment = null;
				this.selectedGovernment = null;
			}),
			switchMap(() => {
				return this.govTypeCode
					? this.mapService.getSourceForGovType(this.govTypeCode, this.customProperties)
					: this.mapService.getSourceForGovernment(this.mcag, this.customProperties);
			}),
			// todo why is finalize not working?
			// finalize(() => this.hideLoadingMessage())
		).subscribe(result => {
			if (!result) {
				return;
			}

			this.source = result;
			// get the govTypeCode off the first shape, in case we are in a scenario where govTypeCode is not passed in
			const govTypeCode = result.features[0].properties.govTypeCode;
			// If any fill colors are passed in, let getLayers know so that it can disable the "default" fill colors
			const hasAnyFillColors = this.customProperties?.find(x => x.properties['fillColor']) !== null;
			this.layers = this.mapService.getLayers(this.mcag, govTypeCode, hasAnyFillColors, this.govTypeCode != null);

			// zoom in logic for mcag
			if (this.mcag) {
				// find this mcag's shape
				const shape = result.features.find(x => x.properties.mcag === this.mcag);
				this.logger.log('MapComponent showing MCAG', this.mcag, shape);
				if (!shape) {
					this.logger.error(`MapComponent: Shape not found for ${this.mcag}!`);
				}

				// zoom in to it
				this.zoomToFeature(shape);


				// if autoSelect = true, then "select" the government; i.e. show the popup
				if (this.autoSelect) {
					this.selectedGovernment = new GovernmentLocation({
						mcag: shape.properties.mcag,
						govTypeDesc: shape.properties.govTypeDesc,
						name: shape.properties.name,
						lngLat: shape.properties.lngLat
					});
				}
			} else if (this.govTypeCode) {
				// reset map bounds in govType mode
				this.map?.fitBounds(MapService.washingtonStateBounds);
			}

			this.hideLoadingMessage();
		});
	}

	private showLoadingMessage(): void {
		if (!this.mcag && !this.govTypeCode) {
			this.loadingMessage = 'Loading map of Washington State...';
		} else {
			this.loadingMessage = 'Loading locations...';
		}

		this.isLoading = true;
	}

	private hideLoadingMessage = (): void => {
		this.isLoading = false;
	};


	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		// this.logger.log('MapComponent changes', changes);
		// always reset any selections or hovers when new inputs come in
		this.hoveredGovernment = null;
		this.selectedGovernment = null;
		// if mcag, govTypeCode, or customProperties (styling) change, then recreate source and layers
		const shouldRebuildSource = changes.mcag?.currentValue !== undefined
			|| changes.govTypeCode?.currentValue !== undefined
			|| changes.customProperties?.currentValue !== undefined;

		if (shouldRebuildSource) {
			this.logger.log('MapComponent shouldRebuildSource', changes, this);

			// todo if govTypeCode did not change, or mcag changed but is the same govTypeCode, do not reload layers
			// only change selectedGovernment
			// (handles scenario of government search, using the grid to select a government)

			if (this.mcag || this.govTypeCode || this.customProperties) {
				this.updateMapFromInputs.next();
			} else {
				// reset map. Setting to null results in an error
				this.source = { type: 'FeatureCollection', features: [] };
				this.map?.fitBounds(MapService.washingtonStateBounds);
			}
		}

		if (changes.marker?.currentValue !== undefined) {
			// this.logger.log('MapComponent marker change', changes);
			if (this.marker) {
				this.center = this.marker;
				this.zoom = [this.pointZoomLevel];
			} else {
				// ng change detection doesn't seem to get picked up here, so set fitBounds explicitly
				this.map?.fitBounds(MapService.washingtonStateBounds);
			}
		}
	}

	/**
	 * Fired immediately after all necessary resources have been downloaded and the first visually complete rendering of the map has occurred.
	 * https://docs.mapbox.com/mapbox-gl-js/api/#map.event:load
	 * @param event - Mapbox's MapGL object
	 */
	mapLoad = (event: MapboxMap) => {
		this.map = event;
		// disable mouse-wheel scrolling
		this.map.scrollZoom.disable();
		// load and add map marker PNG images
		const markers = [
			{ id: 'SAOmarker', filename: 'SAOMarker.png' }, // default
			{ id: 'SAOmarkerGood', filename: 'SAOMarker1.png' }, // GOOD
			{ id: 'SAOmarkerCTN', filename: 'SAOMarker2.png' }, // CAUTIONARY
			{ id: 'SAOmarkerCNC', filename: 'SAOMarker3.png' }, // CONCERNING
			{ id: 'SAOmarkerINDT', filename: 'SAOMarker4.png' }, // INDETERMINATE
		];
		// markers.forEach(m => this.addImage(m.id, m.filename));
	};

	private addImage(id: string, filename: string) {
		const path = environment.base + '/assets/images/';
		this.map.loadImage(path + filename, (error?: Error, ref?) => {
			if (error) {
				throw error;
			}
			this.map.addImage(id, ref);
		});
	}

	/**
	 * Translate MapLayerMouseEvent to Government suitable for keeping track of selected/hovered governments.
	 * @param event
	 */
	private getGovernmentFromEvent = (event: MapLayerMouseEvent): GovernmentLocation => {
		// Pick the first feature. There will be multiple if shapes are stacked/overlapping
		// In general, we should not have overlapping shapes if only displaying one govType at a time
		const feature = event.features[0];
		return new GovernmentLocation({
			mcag: feature.properties.mcag,
			govTypeDesc: feature.properties.govTypeDesc,
			name: feature.properties.name,
			lngLat: JSON.parse(feature.properties.lngLat)
		});
	};

	handleMapClick = (event: MapMouseEvent) => {
		// this.logger.log('Map click', event);
	};


	/**
	 * This bizarre little handler contains a MapMouseEvent with a GeoJSON Feature Array attached. Although you
	 * will not see .features while logging, you can see it while debugging. 🤔
	 * @param event
	 */
	handleLayerClick = (event: MapLayerMouseEvent) => {
		// Should not respond to clicks if not interactive mode
		if (!this.isInteractive) {
			return;
		}
		this.selectedGovernment = this.getGovernmentFromEvent(event);
		const firstFeature = event.features[0];

		this.zoomToFeature(firstFeature);
	};

	/**
	 * Zooms in to the feature by
	 *  1. If point, then zoom in to a predefined zoom level
	 *  2. Otherwise (all shapes) bound inside (fill) viewport
	 * @param feature
	 * @private
	 */
	private zoomToFeature(feature: Feature) {
		if (feature.geometry.type === 'Point') {
			// handle case where location is not known
			if (feature.geometry.coordinates.includes(null)) {
				// @ts-ignore Postition satisfies LngLatLike
				feature.geometry.coordinates = MapService.washingtonStateCentroid;
				this.map?.fitBounds(MapService.washingtonStateBounds);
			} else {
				// center and zoom in to a specific level
				// this array is now quoted because... Mapbox, I guess
				// this.center = feature.properties.lngLat;
				// @ts-ignore Postition satisfies LngLatLike
				this.center = feature.geometry.coordinates;
				this.zoom = [this.pointZoomLevel];
			}
		} else {
			// fit shape inside viewport
			this.fitBounds = extent(feature); // https://github.com/jczaplew/geojson-bounds
		}
	}

	// https://docs.mapbox.com/mapbox-gl-js/example/hover-styles/
	// https://docs.mapbox.com/mapbox-gl-js/api/#map.event:mousemove
	handleMouseMove = (event: MapLayerMouseEvent) => {
		// this.logger.log('layer mouseMove', event.features);
		// if there are no features, nothing to do
		if (event.features.length > 0) {
			const firstMcag = event.features[0].properties.mcag;
			// if the mcag didn't change, there is no need to process for changes
			if (firstMcag === this.hoveredGovernment?.mcag) {
				return;
			}
			// If the mcag is changing, set hover state on the previous hovered government to false
			if (this.hoveredGovernment) {
				this.setFeatureState(this.hoveredGovernment.mcag, 'hover', false);
			}
			this.hoveredGovernment = this.getGovernmentFromEvent(event);
			// if updateTooltip function is provided, call it to update the template
			if (typeof this.updateTooltip === 'function') {
				this.updateTooltip(this.hoveredGovernment.mcag, this.hoveredGovernment.name);
			}
			this.setFeatureState(this.hoveredGovernment.mcag, 'hover', true);
		}
	};

	handleMouseEnter = (event: MapLayerMouseEvent) => {
		this.map.getCanvas().style.cursor = 'pointer';
	};

	// https://docs.mapbox.com/mapbox-gl-js/api/#map.event:mouseleave
	handleMouseLeave = (event: MapLayerMouseEvent) => {
		this.map.getCanvas().style.cursor = '';
		const target = event.originalEvent.relatedTarget as HTMLElement;
		// this.logger.log('layer mouseLeave', event, target);
		// If target is null, that implies the user did not hover over anything generated by the state. I.e. a popup
		// or tooltip. We can safely remove the hovered state.
		if (target == null) {
			if (this.hoveredGovernment) {
				this.setFeatureState(this.hoveredGovernment.mcag, 'hover', false);
			}
			this.hoveredGovernment = null;
		}
		// If the user did hover over a popup/tooltip, then there is nothing to do since we do not want to remove
		// the hovered state
	};

	/**
	 * Set a state on a feature.
	 * https://docs.mapbox.com/mapbox-gl-js/api/#map#setfeaturestate
	 * @param featureId - shape id (mcag)
	 * @param state
	 * @param value
	 */
	private setFeatureState = (featureId: string, state: string, value: boolean) => {
		this.map.setFeatureState(
			{ id: featureId, source: 'shapes' },
			{ [state]: value }
		);
	};

	handleGovernmentClick = (mcag: string): void => {
		if (typeof this.govClickAction === 'function') {
			this.govClickAction(mcag);
			this.logger.log('Map click', mcag);
		} else {
			this.logger.error('User clicked on a government, but no govClickAction defined! Check the Input.');
		}
	};

	handlePopupClosed() {
		// if gov type mode, then zoom back out when popup is closed
		if (this.govTypeCode && !this.mcag) {
			this.map?.fitBounds(MapService.washingtonStateBounds);
		}
		this.popupClosed.emit();
	}
}
