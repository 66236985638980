import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {ReusableGovernmentTypeInputs} from '../../reusable/models/reusable-government-type-inputs';
import {FilingCondition} from '../../api/fit-api/models/filing-status';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {faHashtag, faPercent} from '@fortawesome/free-solid-svg-icons';
import {DxSelectBoxComponent} from 'devextreme-angular';
import {catchError, mergeMap, switchMap, tap} from 'rxjs/operators';
import notify from 'devextreme/ui/notify';

class DropDownItem {
	name: string;
	value: any;
	disabled?: boolean;
}

@Component({
	selector: 'wasao-annual-filing-status-trend',
	templateUrl: './annual-filing-status-trend.component.html',
	styleUrls: ['./annual-filing-status-trend.component.scss']
})
export class AnnualFilingStatusTrendComponent extends ReusableGovernmentTypeInputs implements OnInit {

	@Input() isStandAlone = true;
	@Input() isBannerHidden = false;
	@Input() isCombined = false;
	@Input() resetFilters: Observable<void>;
	@ViewChild('govTypeSelectBox') govTypeSelectBox: DxSelectBoxComponent;
	@ViewChild('countyCodeSelectBox') countyCodeSelectBox: DxSelectBoxComponent;

	dataSource: any;
	submissionDueDate: Date;
	governmentTypesDataSource: Array<DropDownItem> = [{name: 'All Government Types', value: null}];
	countyCodesDataSource: Array<DropDownItem> = [{name: 'All Counties', value: null}];
	selectedGovernmentType: DropDownItem = {name: 'All Government Types', value: null};
	selectedCountyCode: DropDownItem = {name: 'All Counties', value: null};
	selectedGraphOption = 'fullStackedBar';
	chartColorPalette = ['#4C9F83', '#FAB040', '#E8702D', 'rgba(76, 159, 131, 0.3)', 'rgba(250, 176, 64, .3)', 'rgba(232, 112, 45, .3)'];
	currentFilingYearForDisplay: number;
	public icons = {
		percent: faPercent,
		hash: faHashtag
	};
	buttonClicked = 'percent';
	loadingMessage: string;
	isDataSourceEmpty: boolean;
	str = '2';

	constructor(private filingStatusService: FilingStatusService, private fitApi: FitApiService, private route: ActivatedRoute) {
		super();

		// All 5 subscriptions: gets government types for dropdown, gets county types for dropdown, gets current year, gets filing statuses, and gets submission date
		this.fitApi.getGovernmentTypes.pipe(
			// Sets government types data source
			switchMap(govTypesList => {
					govTypesList.map(e => {
							// maps to dataSource, disables dropdown items that don't have financial dataset source SAOAnnualFiling
							if (e.financialsDatasetSource === 'SAOAnnualFiling' && e.activeCount !== 0) {
								this.governmentTypesDataSource.push({name: e.description, value: e.code});
							} else {
								this.governmentTypesDataSource.push({name: e.description, value: e.code, disabled: true});
							}
						}
					);
				return this.fitApi.getCounties;
			}),
			// Sets county codes data source
			switchMap(countiesList => {
					countiesList.map(e => {
							this.countyCodesDataSource.push({name: e.countyName, value: e.countyCode});
						}
					);
				return this.filingStatusService.getCurrentYear();
			}),
			// Sets current year, sets types (if standalone), sets data source
			switchMap(currentYear => {
				this.currentFilingYearForDisplay = currentYear;
				this.standAloneLogic();
				this.setDataSource();
				return this.filingStatusService.getFilingDueDatesForMostGovernments(this.currentFilingYearForDisplay);
			}),
			catchError(error => {
				throw new this.errorHandler(error);
			})
			// Sets submission date
		).subscribe( filingStatus => {
			this.submissionDueDate = new Date(filingStatus.maxAllowedSubmitDate);
		});
	}

	ngOnInit(): void {
		// reset select boxes and percent toggle (if stand alone, ignore)
		if (this.resetFilters) {
			this.resetFilters.subscribe(() => {
				this.govTypeSelectBox.value = null;
				this.countyCodeSelectBox.value = null;
				this.buttonClicked = 'percent';
				this.selectedGraphOption = 'fullStackedBar';
			});
		}
	}

	onGovernmentTypeSelectionChanged(e) {
		this.selectedGovernmentType = e.selectedItem;
		this.setDataSource();
	}

	onCountyCodeSelectionChanged(e) {
		this.selectedCountyCode = e.selectedItem;
		this.setDataSource();
	}

	togglePercent() {
		this.selectedGraphOption = 'fullStackedBar';
		this.buttonClicked = 'percent';
	}

	toggleCounts() {
		this.selectedGraphOption = 'stackedBar';
		this.buttonClicked = 'counts';
	}

	pointClick(e: any) {
		const currentFilingStatusSelected = {
			year: parseInt(e.target.argument, 0),
			govTypeCode: this.selectedGovernmentType.value,
			countyCodes: this.selectedCountyCode.value,
			filingCondition: this.getFilingConditionFromSeriesLabel(e.target.series.name),
		};
		this.filingStatusService.filingReportIsVisible();
		this.filingStatusService.filingReportIsLiveUnauthenticated();
		this.filingStatusService.filingReportFilter(currentFilingStatusSelected);
	}

	customizeTooltip(arg: any) {
		return {
			text: `<strong>${arg.seriesName}</strong>\n ${arg.argumentText}: ${arg.valueText} (${parseFloat((arg.percent * 100).toFixed(1))}%)`
		};
	}

	getFilingConditionFromSeriesLabel(name: string): FilingCondition {
		switch (name) {
			case 'Timely Filers':
				return FilingCondition.Timely;
			case 'Late Filers':
				return FilingCondition.Late;
			case 'Non Filers':
				return FilingCondition.None;
			case 'Filed':
				return FilingCondition.Filed;
			case 'Started':
				return FilingCondition.Started;
			case 'Not Started':
				return FilingCondition.NotStarted;
		}
	}

	setDataSource() {
		this.filingStatusService.getSummarizedLiveFilingStatusesByYear((this.currentFilingYearForDisplay - 8), this.currentFilingYearForDisplay, this.selectedGovernmentType.value, this.selectedCountyCode.value).pipe(
			tap(_ => {
				this.isLoading = true;
				this.loadingMessage = 'Loading Graph Details';
			}),
			catchError(error => {
				throw new this.errorHandler(error);
			})
		).subscribe(
			element => {

				this.dataSource = element;
				// sets boolean if dataSource has all zeroes
				this.isDataSourceEmpty = this.calculateIsDataSourceEmpty(this.dataSource);

				this.isLoading = false;
			});
	}

	calculateIsDataSourceEmpty(dataSource) {
		let emptyStatus = false;
		dataSource.map(x => {
			if (x.filed === 0 && x.filers === 0 && x.total === 0) {
				emptyStatus = true;
			} else {
				return false;
			}
		});
		return emptyStatus;
	}

	standAloneLogic() {
		const govTypeCode = this.route.snapshot.paramMap.get('govTypeCode') as string;
		const countyCode = parseInt(this.route.snapshot.paramMap.get('countyCode'), 10);
		const countyName = this.route.snapshot.paramMap.get('countyName') as string;

		if (govTypeCode) {
			this.selectedGovernmentType = this.governmentTypesDataSource.find(x => x.value === govTypeCode);
		}
		if (countyCode) {
			this.selectedCountyCode = this.countyCodesDataSource.find(x => x.value === countyCode);
		}
		if (countyName) {
			this.selectedCountyCode = this.countyCodesDataSource.find(x => x.name.toLowerCase() === countyName.toLowerCase());
		}

	}

	onPointHoverChanged(e) {
		if (e.target.isHovered() && !this.isStandAlone) {
			e.element.style.cursor = 'pointer';
		} else {
			e.element.style.cursor = 'auto';
		}
	}

	errorHandler(err: any) {
		notify({
			closeOnClick: true,
			displayTime: 10000,
			closeOnOutsideClick: true,
			type: 'error',
			message: err.message
		});
	}

}
