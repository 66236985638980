import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Reusable} from 'app/modules/reusable/models/reusable';
import {FitApiService} from 'app/modules/api/fit-api/fit-api.service';
import {ExternalCommonServiceApiService} from 'app/modules/api/external-common-service-api/external-common-service-api.service';
import {forkJoin, of, Subject} from 'rxjs';
import {LocalGovernment} from '../../../api/fit-api/models/local-government';
import {Entities} from '../../../api/external-common-service-api/models/entities';
import {GovernmentMetric} from '../../../api/fit-api/models/government-metric';
import {FilingStatus} from '../../../api/fit-api/models/filing-status';
import {LoggerService} from '../../../../shared/services/logger.service';
import {faCheck, faCalendarAlt, faHistory, faTimes, faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {FilingStatusService} from '../../../services/filing-status-service/filing-status.service';
import {GovernmentType} from '../../../api/fit-api/models/government-type';
import {UserService} from '../../../../shared/services/user.service';
import {catchError, takeUntil} from 'rxjs/operators';
import {ExternalLGDataService} from '../../../api/external-lgdata/external-lgdata.service';
import {Population} from '../../../api/external-lgdata/models/population';
import {SnapshotId} from '../../../api/fit-api/models/snapshot-like';

@Component({
	selector:    'wasao-government-information',
	templateUrl: './government-information.component.html',
	styleUrls:   ['./government-information.component.scss']
})
export class GovernmentInformationComponent extends Reusable implements OnInit, OnChanges, OnDestroy {
	@Input() mcag: string;
	@Input() govTypeCode: string;
	/**
	 * Specify the year to use to display this component. Otherwise, will use default display year logic.
	 */
	@Input() displayYear?: number;
	@Input() snapshotId: SnapshotId;
	/**
	 * Action to perform when Gov Type link is clicked.
	 */
	@Input() govTypeClickAction: (govTypeCode: string, govTypeName: string) => void;

	/**
	 * Action to perform when Gov Type link is clicked.
	 */
	@Input() govClickAction: (mcag: string, snapshotId: SnapshotId) => void;

	@Input() canShowFinancials: boolean;

	infoSnapshot: LocalGovernment;
	infoLive: Entities;
	treasurer: Entities;
	componentUnitOf: Entities;
	population: Population;
	displayFilingStatus: FilingStatus;
	_displayYear: number;
	snapshot: any;
	enrollmentAverage: number;
	schoolYear: string;

	hasAnyFilingStatuses: boolean;
	accountingBasis: string;
	historicalAuditLink: string;
	address: string;
	dataFetchFailed = false;

	governmentTypes: Array<GovernmentType>;
	displayedGovernmentType: GovernmentType;
	private destroy = new Subject<void>();
	userHasGlobalAccess = false;
	userHasSchoolAccess = false;

	icons = {
		check:    faCheck,
		calendar: faCalendarAlt,
		history:  faHistory,
		times:    faTimes,
		externalLink: faExternalLinkAlt
	};

	constructor(
		private fitApi: FitApiService,
		private filingStatusService: FilingStatusService,
		private commonService: ExternalCommonServiceApiService,
		private logger: LoggerService,
		private userService: UserService,
		private externalLGData: ExternalLGDataService
	) {
		super();
		this.fitApi.getGovernmentTypes.subscribe(x => this.governmentTypes = x);
		this.userService.user.pipe(takeUntil(this.destroy)).subscribe(x => {
				this.userHasGlobalAccess = x.hasGlobalAccess();
				this.userHasSchoolAccess = x.hasAccessToAnySchool();
			}
		);
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.destroy.next();
		this.destroy.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.mcag?.currentValue || changes.snapshotId?.currentValue || changes.displayYear?.currentValue) {
			this.isLoading = true;
			this.updateSources();
		}
	}

	updateSources(): void {
		// TODO could make more efficient by detecting snapshot change and not updating commondata
		// const governmentMetrics = this.fitApi.getGovernmentMetrics(`mcag eq '${this.mcag}'`);
		const populations 		= this.externalLGData.getPopulationsForMCAG(this.mcag);
		const commonData        = this.commonService.getEntityDetails(this.mcag);
		const filingStatuses    = this.fitApi.getFilingStatusesForMCAG(this.mcag, this.snapshotId);
		const displayYear       = this.displayYear ? of(this.displayYear) : this.filingStatusService.getFilingYearForDisplay(this.mcag, this.snapshotId);
		const snapshot          = this.fitApi.getAnnualFilingSnapshot(this.snapshotId);
		const enrollment 		= this.displayYear ? this.fitApi.getOSPIEnrollmentAverages(`mcag eq '${this.mcag}' and fy eq ${this.displayYear}`) : of(null);

		forkJoin({populations, commonData, filingStatuses, displayYear, snapshot, enrollment}).subscribe(
			result => {
				this.generateConditionalContent(result.commonData);
				this.snapshot     = result.snapshot;
				this._displayYear = result.displayYear;
				// this.metrics      = result.governmentMetrics.find(x => x.year === this._displayYear);
				this.population = result.populations?.find(x => x.year === this._displayYear);
				this.infoLive     = result.commonData;

				this.enrollmentAverage = result.enrollment?.averagesForAllGradesHC;
				this.schoolYear = result.enrollment?.schoolYear;


				// determine if government has any filing statuses in the system. This will be true for any active entity,
				// as statuses are generated by the system
				this.hasAnyFilingStatuses = result.filingStatuses?.length > 0;
				this.displayFilingStatus = result.filingStatuses?.find(x => x.year === this._displayYear);
				this.accountingBasis      = this.getBasisOfAccountingInfoKey(this.infoLive?.EntityExtendedProperty?.AcctBasisTypeId);
				this.historicalAuditLink  = `https://www.sao.wa.gov/reports-data/audit-reports/?MCAG=${this.mcag}&en=${encodeURIComponent(result.commonData.EntityName)}`;
				this.address              = this.commonService.getFormattedAddress(this.infoLive);
				this.displayedGovernmentType = this.governmentTypes?.find(x => x.code === result.commonData.GovTypeCode);
				this.dataFetchFailed = false;
			},
			// TODO this could also be more intuitive by failing on the individual requests
			// Currently, this handles the error on the outside, so if *any* requests fail, the data is not shown
			error => {
				this.dataFetchFailed = true;
				this.logger.error(`GovernmentInformation.updateSources encountered an error`, error);
				this.hasData.emit(false);
				this.isLoading = false;
			},
			() => {
				this.hasData.emit(this.infoLive != null);
				this.isLoading = false;
			}
		);
	}

	handleGovTypeClick = (): void => {
		if (this.govTypeClickAction) {
			this.govTypeClickAction(this.infoLive?.GovType?.GovTypeCode, this.infoLive?.GovType?.GovTypeDesc);
		}
	};

	handleGovClick = (mcag?: string): void => {
		if (this.govClickAction) {
			this.govClickAction(mcag ?? this.mcag, this.snapshotId);
		}
	};

	private getBasisOfAccountingInfoKey(acctBasisTypeId: number): string {
		switch (acctBasisTypeId) {

			case 1:
				return 'gaapAccounting';

			case 2:
				return 'cashAccounting';

			case 3:
				return 'schoolsModifiedAccrual';

			case 4:
				return 'schoolsCash';

			default:
				return 'tbd';
		}
	}

	private generateConditionalContent(government: Entities) {
		this.treasurer = null;
		this.componentUnitOf = null;
		const treasurer = government?.EntityExtendedProperty?.TreasurerMCAG;
		if (treasurer != null && treasurer !== this.mcag) {
			this.commonService.getEntityDetails(treasurer).subscribe(result => this.treasurer = result);
		}
		const componentUnitOf = government?.EntityExtendedProperty?.ComponentUnitOfMCAG;
		if (componentUnitOf != null && componentUnitOf !== this.mcag) {
			this.commonService.getEntityDetails(componentUnitOf).subscribe(result => this.componentUnitOf = result);
		}
	}
}
