import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {TabService} from 'app/shared/services/tab.service';
import {Tab} from 'app/shared/models/tab';
import {LoggerService} from 'app/shared/services/logger.service';
import {HistoryService} from 'app/shared/services/history.service';
import {Reusable} from '../../../reusable/models/reusable';
import {TabState} from '../../../../shared/models/tab-state';
import {SnapshotId} from '../../../api/fit-api/models/snapshot-like';

/**
 * CAUTION: Even though this extends Reusable, it has not been retrofitted yet. It is still heavily-dependant on Tab.
 */

@Component({
	selector:    'wasao-indicator-groups',
	templateUrl: './indicator-groups.component.html',
	styleUrls:   ['./indicator-groups.component.scss']
})
export class IndicatorGroupsComponent extends Reusable implements OnChanges {

	@Input() tab: Tab;
	@Input() mcag: string;
	/**
	 * Specify the year to use to display this component. Otherwise, will use default display year logic.
	 */
	@Input() displayYear?: number;
	@Input() snapshotId: SnapshotId;
	@Input() indicatorGroups = [];

	public icons = {
		back: faArrowLeft
	};

	constructor(
		private tabService: TabService,
		private logger: LoggerService,
		private historyService: HistoryService
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.indicatorGroups && this.indicatorGroups?.length === 1) {
			this.tab.indicatorGroupsState = this.indicatorGroups[0];
		} else if (changes.indicatorGroups) {
			this.indicatorGroups.forEach((group) => {
				group.outlookInfo.concerningIndicators = group.indicators.filter(i => i.outlookInfo.outlook === 'Concerning').length;
				group.outlookInfo.cautionaryIndicators = group.indicators.filter(i => i.outlookInfo.outlook === 'Cautionary').length;

				const x = [];
				if (group.outlookInfo.concerningIndicators > 0) {
					x.push(`${group.outlookInfo.concerningIndicators} concerning`);
				}
				if (group.outlookInfo.cautionaryIndicators > 0) {
					x.push(`${group.outlookInfo.cautionaryIndicators} cautionary`);
				}
				group.outlookInfo.indicatorString = x.join(', ');
			});

			this.roundCalculations(this.indicatorGroups);
		}
	}

	set setIndicatorGroup(val) {
		this.historyService.replaceStateTabs();
		this.tab.indicatorGroupsState = val;
		this.historyService.pushStateTabs();
	}

	async handleIndicatorSelect(indicator, displayYear: number) {
		this.tab.navigatedFromIndicators = true;
		this.historyService.replaceStateTabs();

		const gov = this.tab.governments.find(g => g.mcag === this.mcag);

		if (gov.financialsDatasetSource === 'OSPI') {
			this.tabService.getInitialYearsRangeForTabState(TabState.indicator, 'OSPI').subscribe(years => {
				this.tabService.buildIndicatorBasedTab(this.tab, {
					indicator,
					years:       years,
					governments: this.tab.governments,
					title:       `${gov.entityNameWithDba}: ${indicator.title}`
				}, true).then(() => this.historyService.pushStateTabs());
			});
		} else {
			this.tabService.getInitialYearsRangeForTabState(TabState.indicator, 'SAOAnnualFiling', this.tab.snapshotId, displayYear).subscribe(years => {
				this.tabService.buildIndicatorBasedTab(this.tab, {
					indicator,
					years:       years,
					governments: this.tab.governments,
					title:       `${gov.entityNameWithDba}: ${indicator.title}`
				}, true).then(() => this.historyService.pushStateTabs());
			});
		}

	}

	getGaugeValue(outlook: string) {
		switch (outlook) {
			case 'Good':
				return 5;

			case 'Cautionary':
				return 3;

			case 'Concerning':
				return 1;

			default:
				return 0;
		}
	}

	private roundCalculations = (indicatorGroups) => {
		indicatorGroups.forEach((group) => {
			group.indicators.forEach((indicator) => {
				indicator.calculations.forEach((calculation) => {
					calculation.measure = this.roundToPrecision(calculation.measure, calculation.measureUnitInfo.unitDecimalPrecision);
				});
			});
		});
	};

	// TODO centralize this. Copied over from IndicatorComponent to fix #12658
	private roundToPrecision(num: number, precision: number) {
		if (isNaN(num)) {
			return null;
		}
		const factor = Math.pow(10, precision);
		return Math.round(num * factor) / factor;
	}

}
