import {LngLatLike} from 'mapbox-gl';

export class GovernmentLocation {
	mcag: string;
	govTypeDesc: string;
	name: string;
	lngLat: LngLatLike;

	constructor(value: Partial<GovernmentLocation>) {
		Object.assign(this, value);
	}

	hasValidLocation() {
		const lng = this.lngLat[0];
		const lat = this.lngLat[1];
		return lng != null && lat != null;
	}
}
