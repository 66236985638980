<dx-select-box
	id="indicator-instance-selector"
	[disabled]="disabled"
	[items]="data"
	[displayExpr]="formatSelectionTitle"
	[grouped]="true"
	[placeholder]="placeholder"
	itemTemplate="items"
	valueExpr="instanceCode"
	[value]="selection"
	(onValueChanged)="onValueChange($event)"
>
	<dx-validator>
		<dxi-validation-rule type="required" message="Please choose a report to view."></dxi-validation-rule>
	</dx-validator>
	<div *dxTemplate="let item of 'items'">
		{{ item.title }}
	</div>
</dx-select-box>
<wasao-loading [isLoading]="isLoading" roundedBorder="true"></wasao-loading>
