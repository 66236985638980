<dx-button
	(click)="setIsOpen(true)"
	class="button--nonoperative"
>
	<div *dxTemplate="let data of 'content'">
		<fa-icon [icon]="icons.columns" class="dx-icon"></fa-icon>
		Report Layout Builder
	</div>
</dx-button>

<dx-popup
	width="auto"
	maxHeight="90%"
	class="grouping-editor__popup"
	[(visible)]="isOpen"
	closeOnOutsideClick="true"
	title="Edit how your data is grouped"
	(onShowing)="resetFields()"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<dx-scroll-view showScrollbar="always">
				<div class="app-popup__inner grouping-editor__scroll-view">
					<p class="grouping-editor__description">
						These groupings will affect how your data is organized on the report. No data is filtered or hidden due to these choices
						-- the data is simply displayed in a different hierarchy.
						<strong>Click and drag to start rearranging!</strong>
					</p>

					<div class="grouping-editor__pane-container">

						<div class="grouping-editor__recommended">
							<div class="grouping-editor__recommended__header">
								<fa-icon [icon]="icons.star"></fa-icon>
								Recommended presets for your analysis
								<wasao-hint text="(why?)">Preset layouts are provided to perform typical analyses with the available data</wasao-hint>
							</div>
							<div class="grouping-editor__recommended__content">
								<span *ngFor="let p of availablePresets">
									<dx-button
										[text]="p.text"
										[hint]="p.hint"
										(click)="p.onClick()"
										class="u-margin-right"
									></dx-button>
								</span>
								<div class="grouping-editor__recommended__message" *ngIf="availablePresets.length === 0">
									No presets available for this report.
								</div>
							</div>
						</div>

						<div
							class="grouping-editor__available"
							[class.grouping-editor__available--not-allowed]="notAllowedTarget === 'available'"
							[class.grouping-editor__available--drop-target]="isAllowedInArea('available')"
						>
							<div class="grouping-editor__available__header">
								Or, pick elements to group your data manually:
							</div>
							<ul
								class="grouping-editor__available__list"
								dndDropzone
								dndHorizontal="true"
								(dndDragover)="onDragOver('available')"
								(dndDrop)="onDrop($event, 'available')"
							>
								<li
									*ngFor="let field of fields.available; let i = index"
									dndEffectAllowed="move"
									[dndDraggable]="field"
									(dndStart)="onDragStart(field)"
									(dndEnd)="onDragEnd(field)"
									(dndMoved)="onMoved(i, 'available')"
									class="grouping-editor__field"
								>
									{{ field.caption }}
<!--									<wasao-hint icon="'question'" include="fieldHintPartial(field)"></wasao-hint>-->
								</li>
								<li class="dndPlaceholder" dndPlaceholderRef></li>
							</ul>
						</div>

						<div
							class="grouping-editor__columns"
							[class.grouping-editor__columns--not-allowed]="notAllowedTarget === 'columns'"
							[class.grouping-editor__columns--drop-target]="isAllowedInArea('columns')"
						>
							<div class="grouping-editor__columns__header">
								<fa-icon [icon]="icons.listUL" rotate="90"></fa-icon>
								Column Grouping
							</div>
							<ul
								class="grouping-editor__columns__list"
								dndDropzone
								dndHorizontal="true"
								(dndDragover)="onDragOver('columns')"
								(dndDrop)="onDrop($event, 'columns')">
								<li
									*ngFor="let field of fields.columns; let i = index"
									[dndDraggable]="field"
									dndEffectAllowed="move"
									(dndStart)="onDragStart(field)"
									(dndEnd)="onDragEnd(field)"
									(dndMoved)="onMoved(i, 'columns')"
									[dndDisableIf]="isFixed(field)"
									class="grouping-editor__field"
									[class.grouping-editor__field--disabled]="isFixed(field)"
								>
									<span *ngIf="isFixed(field)">
										<fa-icon [icon]="icons.anchor"></fa-icon>
									</span>
									<span *ngIf="canBeDeleted(field)" class="grouping-editor__remove" (click)="delete(i, field, fields.columns)">
										<fa-icon [icon]="icons.close"></fa-icon>
									</span>
									{{ field.caption }}
<!--									<wasao-hint icon="'question'" include="fieldHintPartial(field)"></wasao-hint>-->
									<span *ngIf="(i + 1) !== fields.columns.length" class="grouping-editor__field__suffix">
										<span *ngIf="isNextLinked(fields.columns, i)" class="grouping-editor__field__suffix--linked"></span>
										<span *ngIf="!isNextLinked(fields.columns, i)" class="grouping-editor__field__suffix--ordered">
											<fa-icon [icon]="icons.angleDoubleRight"></fa-icon>
										</span>
									</span>
								</li>
								<li class="dndPlaceholder" dndPlaceholderRef></li>
							</ul>

						</div>

						<div
							class="grouping-editor__rows"
							[class.grouping-editor__rows--not-allowed]="notAllowedTarget === 'rows'"
							[class.grouping-editor__rows--drop-target]="isAllowedInArea('rows')"
						>
							<div class="grouping-editor__rows__header">
								<fa-icon [icon]="icons.listUL"></fa-icon>
								Row Grouping
							</div>
							<ul
								class="grouping-editor__rows__list"
								dndDropzone
								dndHorizontal="true"
								(dndDragover)="onDragOver('rows')"
								(dndDrop)="onDrop($event, 'rows')"
							>
								<li *ngFor="let field of fields.rows; let i = index"
								    [dndDraggable]="field"
								    dndEffectAllowed="move"
								    (dndStart)="onDragStart(field)"
								    (dndEnd)="onDragEnd(field)"
								    (dndMoved)="onMoved(i, 'rows')"
								    [dndDisableIf]="isFixed(field)"
								    class="grouping-editor__field"
								    [class.grouping-editor__field--disabled]="isFixed(field)"
								>
									<span *ngIf="isFixed(field)">
										<fa-icon [icon]="icons.anchor"></fa-icon>
									</span>
									<span *ngIf="canBeDeleted(field)" class="grouping-editor__remove" (click)="delete(i, field, fields.rows)">
										<fa-icon [icon]="icons.close"></fa-icon>
									</span>
									{{ field.caption }}
<!--									<wasao-hint icon="'question'" include="fieldHintPartial(field)"></wasao-hint>-->
									<span *ngIf="(i + 1) !== fields.rows.length" class="grouping-editor__field__suffix">
										<span *ngIf="isNextLinked(fields.rows, i)" class="grouping-editor__field__suffix--linked"></span>
										<span *ngIf="!isNextLinked(fields.rows, i)" class="grouping-editor__field__suffix--ordered">
											<fa-icon [icon]="icons.angleDoubleRight"></fa-icon>
										</span>
									</span>
								</li>
								<li class="dndPlaceholder" dndPlaceholderRef></li>
							</ul>
						</div>
					</div>
				</div>
			</dx-scroll-view>

			<div class="app-popup__footer">
				<dx-button
					text="Done"
					type="default"
					(onClick)="handleSubmit()"
					class="choice grouping-editor__submit"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
