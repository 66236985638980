<wasao-on-time-filer-badge
	[snapshotId]="snapshotId"
	[mcag]="mcag"
	[displayYear]="displayYear"
	[displayDensity]="displayDensity"
	[badgeClickAction]="onTimeFilerClickAction"
	[hideWhenContentEmpty]="hideWhenContentEmpty"
	[isInteractive]="isInteractive"
	(hasData)="aggregateHasData($event, 'onTimeFilerBadge')"
></wasao-on-time-filer-badge>

