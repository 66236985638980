import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {ListCode} from './models/list-code';
import {LookupListItem} from './models/view-models/lookup-list-item';
import {Entities} from './models/entities';
import {GovTypes} from './models/gov-types';
import {OptionsFilter} from './models/options-filter';
import {MandatoryFieldsEntity} from './models/view-models/mandatory-fields-entity';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ExternalCommonServiceApiModule} from './external-common-service-api.module';
import {IncludeOptions} from './models/include-options';
import {IEntityName} from './models/i-entity-name';
import {LoggerService} from '../../../shared/services/logger.service';
import {GovTypeIndicator} from './models/gov-type-indicator';
import {EntityGeoCoordinates} from './models/entity-geo-coordinates';
import {ShareInProgress} from '../../decorators/share-in-progress';
import {PerformanceIndicator} from './models/performance-indicator';
import {Teams} from './models/teams';

@Injectable({
	providedIn: ExternalCommonServiceApiModule
})
/**
 * Official documentation:
 *  http://saosp/TeamSites/IS/Applications/Documents/ExternalCommonService/ExternalCommonServiceAPI.docx
 *
 * Types can be found at:
 *  $/CustomAssemblies/ExternalCommonService/Source/Dev/ExternalCommonDataType/Data/CommonData
 *
 */
export class ExternalCommonServiceApiService {

	/**
	 * Base url for API
	 * @private
	 */
	private apiUrl = environment?.apis?.externalCommonService
		?? `https://portal.sao.wa.gov/ExternalCommonservice/api`; // Fallback to production if no environment import.

	/**
	 * The default objects to expand when querying EntityDetails.
	 */
	private defaultIncludeOptions = [
		IncludeOptions.extendedProperties,
		IncludeOptions.govType,
		IncludeOptions.county
	];

	constructor(
		private http: HttpClient,
		private logger: LoggerService
	) {
	}

	// region API calls
	@ShareInProgress
	getPerformanceIndicator(): Observable<PerformanceIndicator> {
		const url = `${this.apiUrl}/CommonData/GetPerformanceIndicator`;
		return this.http.get<PerformanceIndicator>(url);
	}

	@ShareInProgress
	getPerformanceIndicatorByGovType(govTypeCode: string): Observable<GovTypeIndicator> {
		const url = `${this.apiUrl}/CommonData/GetPerformanceIndicatorByGovType?GTCode=${govTypeCode}`;
		return this.http.get<GovTypeIndicator>(url);
	}

	/**
	 * Get a list of GovTypes or Governments (entities) depending on the input.
	 * @param listCode
	 */
	@ShareInProgress
	getLookupListByCode(listCode: ListCode): Observable<Array<LookupListItem>> {
		const url = `${this.apiUrl}/CommonData/GetLookupListByCode?ListCode=${listCode}`;
		return this.http.get<Array<LookupListItem>>(url);
	}

	/**
	 * Get a specific government's details.
	 * @param mcag
	 * @param options
	 */
	@ShareInProgress
	getEntityDetails(mcag: string, options = this.defaultIncludeOptions): Observable<Entities> {
		// provide IncludeOptions to expand contained models
		// additional options: BillingAddress,MailingAddress
		const optionsString = options.join(',');
		const url           = `${this.apiUrl}/CommonData/GetEntityWithRelatedData?MCAG=${mcag}&IncludeOptions=${optionsString}`;
		return this.http.get<Entities>(url).pipe(
			map(result => {
				if (result?.EntityExtendedProperty?.Website) {
					result.EntityExtendedProperty.Website = this.sanitizeWebsite(result.EntityExtendedProperty.Website);
				}
				return result;
			})
		);
	}

	@ShareInProgress
	getGovTypeDetails(option: OptionsFilter = 'activeonly'): Observable<Array<GovTypes>> {
		const url = `${this.apiUrl}/CommonData/GetGovTypesDetails?option=${option}`;
		return this.http.get<Array<GovTypes>>(url);
	}

	@ShareInProgress
	getGovTypeDetailsForGovType(govTypeCode: string): Observable<GovTypes> {
		return this.getGovTypeDetails().pipe(
			map(govTypeDetails => govTypeDetails.find(x => x.GovTypeCode === govTypeCode))
		);
	}

	@ShareInProgress
	getEntitiesMandatoryFields(option: OptionsFilter = 'activeonly'): Observable<Array<MandatoryFieldsEntity>> {
		const url = `${this.apiUrl}/CommonData/GetEntitiesMandatoryFields?option=${option}`;
		return this.http.get<Array<MandatoryFieldsEntity>>(url)
			.pipe(map(results => results.filter(entity => !entity.MCAG?.startsWith('7') && !entity.MCAG?.startsWith('9'))));
	}

	@ShareInProgress
	getGovernmentTypes(): Observable<Array<LookupListItem>> {
		const url = `${this.apiUrl}/CommonData/GetLookupListByCode?ListCode=COMMONDATA_govtypes`;
		return this.http.get<Array<LookupListItem>>(url);
	}

	@ShareInProgress
	getCounties(): Observable<Array<LookupListItem>> {
		const url = `${this.apiUrl}/CommonData/GetLookupListByCode?ListCode=COMMONDATA_Counties`;
		return this.http.get<Array<LookupListItem>>(url);
	}

	@ShareInProgress
	getAuditTeamContactInfo(mcag: string): Observable<Teams> {
		const url = `${this.apiUrl}/CommonData/GetAuditTeamContact?mcag=${mcag}`;
		return this.http.get<Teams>(url);
	}

	@ShareInProgress
	getCoordinatesForGovTypes(govTypes: Array<string>): Observable<Array<EntityGeoCoordinates>> {
		const govTypesQueryParam = govTypes.join(',');
		const endpoint = `${this.apiUrl}/CommonData/GetEntityGeoCoordinates?GovTypeCode=${govTypesQueryParam}`;
		return this.http.get<Array<EntityGeoCoordinates>>(endpoint);
	}

	@ShareInProgress
	getCoordinatesForMcags(mcags: Array<string>): Observable<Array<EntityGeoCoordinates>> {
		const mcagsQueryParam = mcags.join(',');
		const endpoint = `${this.apiUrl}/CommonData/GetEntityGeoCoordinates?MCAG=${mcagsQueryParam}`;
		return this.http.get<Array<EntityGeoCoordinates>>(endpoint);
	}

	// endregion API calls

	// region Helpers

	/**
	 * Returns the EntityName with Entity_DBA in parenthesis if
	 * 1) Entity_DBA exists and
	 * 2) Entity_DBA is not the same as EntityName.
	 * @param entity
	 */
	getEntityNameWithDBA = (entity: IEntityName) => {
		if (!entity) {
			this.logger.error(`${this.constructor.name}.getEntityNameWithDBA must be passed `);
		}
		const useDba = entity.Entity_DBA && entity.EntityName === entity.Entity_DBA;
		const dba    = useDba ? ` (${entity.Entity_DBA})` : '';
		return `${entity.EntityName}${dba}`;
	};

	// Website may or may not have protocol, may be null, empty string, or 'NA'
	private sanitizeWebsite(website: string) {
		const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
		if (regex.test(website)) {
			// strip off any http/https
			return website.replace(/^https?:\/\//, '');
		} else {
			return null;
		}
	}

	// Get the formatted address from EntityData
	getFormattedAddress(entityData: Entities): string {
		if (!entityData.Street && !entityData.City && !entityData.Zip) {
			return null;
		}

		return (entityData.Street ?? '') +
			(entityData.Street && entityData.State ? ', ' : '') +
			(entityData.City ?? '') +
			(entityData.City && entityData.State ? ', ' : '') +
			(entityData.State ? `${entityData.State}` : '') +
			(entityData.State && entityData.Zip ? ' ' : '') +
			(entityData.Zip ?? '');
	}
	// endregion Helpers
}
