import {Component, OnInit, ViewChild} from '@angular/core';
import {UserInterfaceService} from '../../../shared/services/user-interface.service';
import {Subscription} from 'rxjs';
import {DxListComponent, DxPopupComponent} from 'devextreme-angular';
import {ResourcesService} from '../resources.service';
import {faPlus, faEdit} from '@fortawesome/free-solid-svg-icons';
import {Resource} from '../../../shared/models/resource';
import {confirm} from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import {Reusable} from '../../../modules/reusable/models/reusable';
import {catchError, tap} from 'rxjs/operators';
import {ResourceChanged} from '../resource-changed';

@Component({
	selector: 'app-manage-resources',
	templateUrl: './manage-resources.component.html',
	styleUrls: ['./manage-resources.component.scss']
})
export class ManageResourcesComponent extends Reusable implements OnInit {
	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	@ViewChild(DxListComponent, {static: true}) list: DxListComponent;


	public icons = {
		plus: faPlus,
		edit: faEdit
	};

	reorderEnabled = true;
	allowDeletion = true;
	itemDeleteMode = 'static';
	private uiSubscription: Subscription;
	isVisible = true;
	resourcesDataSource: Array<Partial<Resource>>;
	loadingMessage: string;

	constructor(private uiService: UserInterfaceService, private resourcesService: ResourcesService) {
		super();
		this.uiSubscription = this.uiService.manageResources$.subscribe(
			value => this.isVisible = value);
		this.resourcesService.resourceChanged.subscribe(value => {
			if (value.action === 'create') {
				this.resourcesDataSource.push({id: value.id, title: value.title});
			}
			if (value.action === 'edit') {
				const foundIndex = this.resourcesDataSource.findIndex(x => x.id === value.id);
				this.resourcesDataSource[foundIndex].title = value.title;
			}
			if (value.action === 'delete') {
				const foundIndex = this.resourcesDataSource.findIndex(x => x.id === value.id);
				this.resourcesDataSource.splice(foundIndex, 1);
			}
		});
	}

	ngOnInit(): void {
		this.resourcesService.getResources().subscribe(result => this.resourcesDataSource = result);
	}

	searchValueChanged(e) {
		if (e !== '') {
			this.reorderEnabled = false;
		} else {
			this.reorderEnabled = true;
		}
	}

	addResource() {
		this.uiService.createEditResource();
	}

	editResource(id: number) {
		this.uiService.createEditResource(id);
	}

	onItemDeleting(e) {
		e.cancel = true;
		const result = confirm('<i>Are you sure?</i>', 'Confirm changes');
		result.then((dialogResult) => {
			if (dialogResult) {
				this.resourcesService.deleteResource(e.itemData.id).pipe(
					tap(_ => {
						this.isLoading = true;
						this.loadingMessage = 'Deleting Resource';
					}),
					catchError(error => {
						throw new notify({
							closeOnClick: true,
							displayTime: 10000,
							closeOnOutsideClick: true,
							type: 'error',
							message: error.message
						});
					})
				).subscribe(_ => {
					e.cancel = false;
					this.resourcesService.resourceChanged.next(new ResourceChanged(e.itemData.id, 'delete', e.itemData.title));
					this.isLoading = false;
				});
			} else {
				return;
			}

		});
	}

	onItemReordered(e) {
		const arr = this.resourcesDataSource;
		// sorts array to match reorder - changes sortOrder field to correct order
		if (e.toIndex >= arr.length) {
			let k = e.toIndex - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(e.toIndex, 0, arr.splice(e.fromIndex, 1)[0]);
		arr.map((el, index) => el.sortOrder = index);

		// creates array to be sent to /sortOrder endpoint
		const sortedArr = arr.map((element, i) => {
			const container = {
				id: undefined,
				sortOrder: undefined
			};

			container.id = element.id;
			container.sortOrder = element.sortOrder;

			return container;
		});

		this.resourcesService.orderResources(sortedArr).pipe(
			tap(_ => {
				this.isLoading = true;
				this.loadingMessage = 'Reordering Resources';
			}),
			catchError(error => {
				throw new notify({
					closeOnClick: true,
					displayTime: 10000,
					closeOnOutsideClick: true,
					type: 'error',
					message: error.message
				});
			})
		).subscribe(_ => this.isLoading = false);
	}
}
