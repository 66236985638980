import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AmountsByCategoryComponent} from '../amounts-by-category/amounts-by-category.component';
import {GovernmentalServicesComponent} from './governmental-services.component';
import {LoadingModule} from '../../ui/loading/loading.module';
import {DxButtonModule, DxPieChartModule, DxSparklineModule} from 'devextreme-angular';
import {SharedComponentsModule} from '../../../shared/components/shared-components.module';
import {StatewideAveragesModule} from '../statewide-averages/statewide-averages.module';
import {AmountsByCategoryModule} from '../amounts-by-category/amounts-by-category.module';


@NgModule({
	declarations: [
		GovernmentalServicesComponent
	],
	imports: [
		CommonModule,
		SharedComponentsModule,
		StatewideAveragesModule,
		AmountsByCategoryModule
	],
	exports: [
		GovernmentalServicesComponent
	]
})
export class GovernmentalServicesModule {
}
