import {Component, Input, OnInit} from '@angular/core';
import {TabService} from '../../shared/services/tab.service';
import {TabState} from 'app/shared/models/tab-state';
import {faGlobe, faSearch, faHome, faFolder, faMapMarked} from '@fortawesome/free-solid-svg-icons';
import {LoggerService} from 'app/shared/services/logger.service';
import {WizardService} from '../wizard/wizard.service';
import {Tab} from 'app/shared/models/tab';
import {HistoryService} from 'app/shared/services/history.service';
import {User} from '../../shared/models/user';
import {UserService} from '../../shared/services/user.service';
import DataSource from 'devextreme/data/data_source';
import {DataSourceService} from '../../shared/services/data-source.service';

@Component({
	selector:    'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls:   ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

	public icons = {
		globe:  faGlobe,
		search: faSearch,
		home:   faHome,
		folder: faFolder,
		map: faMapMarked
	};

	public currentTab;
	public tabState               = TabState;


	constructor(
		public tabService: TabService,
		private historyService: HistoryService,
		private logger: LoggerService,
		private wizardService: WizardService,
	) {
		this.logger.log(this);
	}

	ngOnInit() {
		this.tabService.tabs.subscribe(tabs => this.currentTab = tabs.find((tab) => tab.selected));
	}

	setTabState(state: TabState) {
		this.historyService.replaceStateTabs();
		switch (state) {
			case TabState.wizard:
				this.currentTab.title = 'Explore Data';
				this.tabService.setSelectedTabState(TabState.wizard);
				this.wizardService.jumpToStep(1);
				this.wizardService.reset();
				break;

			case TabState.searchMap:
				this.currentTab.title = 'Find Government on Map';
				this.tabService.setSelectedTabState(TabState.searchMap);
				break;
			case TabState.searchName:
				this.currentTab.title = 'Find Government by Name';
				this.tabService.setSelectedTabState(TabState.searchName);
				break;

			default:
		}

		this.historyService.pushStateTabs();
	}

}
