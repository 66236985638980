import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {LoggerService} from 'app/shared/services/logger.service';
import {faArrowUp, faArrowDown, faEquals, IconDefinition, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Reusable} from '../../reusable/models/reusable';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {RankingTrend} from './models/ranking-trend';
import {LocalGovernment} from '../../api/fit-api/models/local-government';
import {forkJoin} from 'rxjs';
import {RankingParameters} from './models/ranking-parameters';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {RankingService} from './ranking.service';
import {SummaryFilingStatus} from '../../services/filing-status-service/models/summary-filing-status';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Component({
	selector:    'wasao-rankings',
	templateUrl: './rankings.component.html',
	styleUrls:   ['./rankings.component.scss']
})
export class RankingsComponent extends Reusable implements OnInit, OnChanges {
	@Input() mcag: string;
	@Input() snapshotId: SnapshotId;

	/**
	 * Pass in a year to see historical data. Otherwise returns the latest.
	 */
	@Input() year: number;
	@Input() seeDataClickAction: (parameters: RankingParameters) => void;

	snapshot;
	filingStatus: SummaryFilingStatus;
	government: LocalGovernment;

	/**
	 * This is filtered to *only* contain records with rankings for currentYear.
	 */
	rankingTrends: Array<RankingTrend> = [];

	/**
	 * Reported back from RankingService. This will be the correct value, even if consumer has not passed @Input year
	 */
	currentYear: number;

	govTypeCanHaveFinancials: boolean;

	constructor(
		private logger: LoggerService,
		private rankingService: RankingService,
		private filingStatusService: FilingStatusService,
		private fitApi: FitApiService
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.mcag?.currentValue || changes.snapshotId?.currentValue) {
			this.updateGovernment();
		}
	}

	updateGovernment = () => {
		this.isLoading = true;
		// get local government for govType
		this.fitApi.getAnnualFilingSnapshot(this.snapshotId).subscribe(snapshot => {
			this.snapshot = snapshot;
			// do not attempt to get results for Live (message in template)
			if (snapshot.id === 'live') {
				this.isLoading = false;
				return;
			}

			this.fitApi.getLocalGovernment(this.mcag, snapshot.id).subscribe(government => {
				this.government = government;

				if (this.government.financialsDatasetSource === 'OSPI') {
					this.govTypeCanHaveFinancials = false;
					this.isLoading = false;
					return;
				}

				forkJoin({
					filingStatuses: this.filingStatusService.getSummarizedFilingStatuses(government.govTypeCode, snapshot.id),
					rankingsTrend:  this.rankingService.getRankingsTrend(this.mcag, this.year, snapshot.id)
				}).subscribe(result => {
					this.govTypeCanHaveFinancials = snapshot.detail.governmentTypes
						.find(x => x.code === this.government.govTypeCode)
						?.canHaveFinancials;
					// summarized filing statuses for the govType to show in footnote
					this.filingStatus             = result.filingStatuses;
					this.rankingTrends            = result.rankingsTrend.rankings.filter(row =>
						// There are three keys to summarized ranking data: fundCategoryId, fsSectionId, basicAccountId.
						// When one of these keys is null, it signals that it is a wildcard. E.g. fundCategoryId === null
						// means all fundCategoryIds are summed. fundCategoryId is never null.

						// We only want top-level FSSection rankings per Duane's spreadsheet (#87, 89, 91, 92)
						(row.fundCategoryId && row.basicAccountId == null)
						// We only want Tax Revenue and Public Safety Expenditure rankings per Duane's spreadsheet (#88, 90)
						|| (row.fundCategoryId == null && (row.basicAccountId === 6 || row.basicAccountId === 2082))
						// Or if it's a population ranking
						|| (row.population)
					)
						// only return records that have ranking present for current year
						.filter(x => x.currentRanking);
					this.currentYear              = result.rankingsTrend.year;
					this.isLoading                = false;
				});
			});
		});
	};

	ngOnInit() {
	}

	getTrendIcon(ranking: RankingTrend): IconDefinition {
		if (ranking.priorRanking == null) {
			return faTimes;
		}

		// For comparison, undefined or null should be treated as 9999
		const current = ranking.currentRanking || 9999;
		const prior   = ranking.priorRanking || 9999;
		if (current > prior) {
			return faArrowDown;
		}
		else if (current < prior) {
			return faArrowUp;
		}
		return faEquals;
	}

	handleSeeDataClick = (rankingTrend: RankingTrend) => {
		this.seeDataClickAction({
			barsAccountId:  rankingTrend.basicAccountId,
			fundCategoryId: rankingTrend.fundCategoryId,
			fsSectionId:    rankingTrend.fsSectionId,
			govTypeCode:    this.government.govTypeCode
		});
	}
}
