<dx-popup
	class="account-settings-popup"
	width="50%"
	height="auto"
	[dragEnabled]="false"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	title="Want to Give Us Feedback?">
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<p class="about__feedback">
					We are continually working on improvements to FIT and would love your feedback. If you have questions or ideas for how we can make FIT better or would like to volunteer to help us test future versions, please email
					<a target="_blank" href="mailto:Center@sao.wa.gov?subject=FIT%20Feedback&body=Was%20there%20anything%20missing%20from%20the%20application%20that%20you%20were%20hoping%20to%20find%3F%0A%0AWhat%20type%20of%20user%20do%20you%20identify%20as%3F%20%28Citizen%2C%20Local%20Government%2C%20Legislature%2C%20Association%2C%20etc.%29%0A%0AIf%20it%20is%20ok%20for%20us%20to%20contact%20you%2C%20please%20provide%20your%20email%20and/or%20phone%20number%20here%3A%0A%0A">Center@sao.wa.gov</a>.
				</p>

				<p class="about__build">
					&copy;2018-{{ copyrightYear }} Office of the Washington State Auditor. All Rights Reserved. Software build: {{ appVersion }}
				</p>
			</div>

			<div class="app-popup__footer">
				<dx-button
					text="Close"
					type="default"
					(onClick)="close()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
