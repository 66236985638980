import {Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {LoggerService} from 'app/shared/services/logger.service';
import {GovernmentTypes} from '../../../modules/api/fit-api/models/government-types';
import {DisabledGovernmentType} from '../government-types/disabled-government-type';
import {DxFileUploaderComponent, DxTagBoxComponent} from 'devextreme-angular';

@Component({
	selector:    'app-locations',
	templateUrl: './locations.component.html',
	styleUrls:   ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
	@ViewChild(DxTagBoxComponent) dxTagBox: DxTagBoxComponent;

	@Input() store: any;
	@Input() selections: any;
	@Input() width: string | number;
	// internal array should always be an array of numbers (countyCode)
	_selections: Array<number>;
	@Output() changed = new EventEmitter<any>();
	@Input() isTrackD = false;
	@Input() maxLocationsAllowedForTrackD: number;

	constructor(private logger: LoggerService) {
	}

	ngOnInit() {
	}

	ngOnChanges(changes) {
		// max amount logic for track D
		if (this.isTrackD) {
			if (changes.selections.currentValue.length >= this.maxLocationsAllowedForTrackD) {
				this.dxTagBox?.instance.option('items').map(item => {
					item.disabled = !(changes.selections.currentValue.includes(item));
				});
			} else {
				this.dxTagBox?.instance.option('items').map(item => {
					item.disabled = false;
				});
			}
		}
		if (changes.selections && changes.selections.currentValue) {
			const isSelectionsArrayOfNumbers = changes.selections.currentValue.length
				&& typeof changes.selections.currentValue[0] === 'number';

			// Extract countyCode from array to pass to dx-tag-box as 'value'
			if (!isSelectionsArrayOfNumbers) {
				this._selections = changes.selections.currentValue.map(x => x.countyCode);
			}
		}
	}

	handleSelectionChanged(event) {
		if (event.addedItems.length > 0 || event.removedItems.length > 0) {
			const value = event.component.option('value');
			this.changed.emit(value);
		}
	}

	onOpened(event) {
		// Hide select all button
		if (this.isTrackD) {
			event.component.content().querySelector('.dx-list-select-all').style.display = 'none';
		}
	}

	onContentReady() {
		// clears tag box
		this.dxTagBox?.instance.reset();
		this.dxTagBox?.instance.option('items').map(item => {
			item.disabled = false;
		});
	}

}
