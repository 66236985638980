import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class GradientService {
	private colorStops = 24;
	private colorKeys = ['red', 'green', 'blue'];

	constructor() { }

	rgbToHex(r, g, b) {
		return '#' + this.byteToHex(r) + this.byteToHex(g) + this.byteToHex(b);
	}

	byteToHex(n) {
		const hexVals = '0123456789ABCDEF';
		// tslint:disable-next-line:no-bitwise
		return String(hexVals.substr((n >> 4) & 0x0F, 1)) + hexVals.substr(n & 0x0F, 1);
	}

	parseColor(color) {
		if ((color).toString() === '[object Object]') {
			return color;
		} else {
			color = (color.charAt(0) === '#') ? color.substring(1, 7) : color;
			return {
				red: parseInt((color).substring(0, 2), 16),
				green: parseInt((color).substring(2, 4), 16),
				blue: parseInt((color).substring(4, 6), 16)
			};
		}
	}

	// Generates a gradient given a from and to color, and number of stops.
	generate(from: string, to: string, stops: number): Array<string> {
		const colors = [];
		let beginColor = { red: 0, green: 0, blue: 0 };
		let endColor = { red: 0, green: 0, blue: 0 };
		const diff = { red: 0, green: 0, blue: 0 };

		const len = this.colorKeys.length;
		let pOffset = 0;
		if (typeof (from) !== 'undefined') {
			beginColor = this.parseColor(from);
		}
		if (typeof (to) !== 'undefined') {
			endColor = this.parseColor(to);
		}
		stops = Math.max(1, (stops || this.colorStops) - 1);
		for (let x = 0; x < stops; x++) {
			pOffset = x / stops;
			for (let y = 0; y < len; y++) {
				diff[this.colorKeys[y]] = endColor[this.colorKeys[y]] - beginColor[this.colorKeys[y]];
				diff[this.colorKeys[y]] = (diff[this.colorKeys[y]] * pOffset) + beginColor[this.colorKeys[y]];
			}
			colors.push(this.rgbToHex(diff.red, diff.green, diff.blue));
		}
		colors.push(this.rgbToHex(endColor.red, endColor.green, endColor.blue));

		return colors;
	}
}
