<div class="filters u-padding-horizontal">
	<div class="filters__filter">
		<div class="filters__filter__value u-margin-right">
			<app-text-search
				[placeholder]="'Government name'"
				(changed)="updateFilterName($event)"
				class="search-filter__input"
			></app-text-search>
		</div>
	</div>

	<div class="filters__filter">
		<div class="filters__filter__label">Filter by Government Type:</div>
		<div class="filters__filter__value">
			<app-government-types
				(changed)="updateFilterTypes($event)"
				[selections]="gridSelectedGovTypes"
			></app-government-types>
		</div>
	</div>
</div>

<app-single-government-chooser
	[store]="store"
	(changed)="handleSelect($event)"
></app-single-government-chooser>
