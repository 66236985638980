<dx-range-slider
	[visible]="mode === 'multi' && min !== max"
	[min]="min"
	[max]="max"
	[(start)]="start"
	[(end)]="end"
>
	<dxo-tooltip
		[enabled]="true"
		showMode="always"
		position="top">
	</dxo-tooltip>

	<dxo-label
		[visible]="true"
		position="bottom">
	</dxo-label>
</dx-range-slider>

<dx-slider
	[visible]="mode === 'single' && min !== max"
	[min]="min"
	[max]="max"
	[value]="start"
>
	<dxo-tooltip
		[enabled]="true"
		showMode="always"
		position="top">
	</dxo-tooltip>

	<dxo-label
		[visible]="true"
		position="bottom">
	</dxo-label>
</dx-slider>

<div *ngIf="min === max" class="slider-empty">
	There is currently only one available year of data ({{ min }}).
</div>

<div *ngIf="mode !== 'single' && mode !== 'multi'">
	YearsComponent "mode" has not been configured properly.
</div>
