// Angular features
import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler} from '@angular/core';
import {DatePipe, CurrencyPipe, PercentPipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from 'environments/environment';

// Third party libraries
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
	DxTabPanelModule,
	DxPopupModule,
	DxLoadPanelModule,
	DxSelectBoxModule,
	DxButtonModule,
	DxPivotGridModule,
	DxVectorMapModule,
	DxDataGridModule,
	DxCheckBoxComponent,
	DxCheckBoxModule,
	DxTreeViewModule,
	DxTooltipModule,
	DxSwitchModule,
	DxMenuModule,
	DxTextBoxModule,
	DxScrollViewModule,
	DxRadioGroupComponent,
	DxRadioGroupModule,
	DxMapModule,
	DxAccordionModule,
	DxSparklineModule,
	DxPieChartModule,
	DxChartModule,
	DxDrawerModule,
	DxRangeSliderModule,
	DxAutocompleteModule,
	DxTreeListModule,
	DxCircularGaugeModule,
	DxLoadIndicatorModule,
	DxValidatorModule,
	DxTreeMapModule,
	DxTextAreaModule,
	DxValidationSummaryModule,
	DxFormModule,
	DxValidationGroupModule,
	DxPopoverModule,
	DxTagBoxModule,
	DxButtonGroupModule, DxListModule, DxMultiViewModule, DxFileUploaderModule, DxLookupModule
} from 'devextreme-angular';

import {DndModule} from 'ngx-drag-drop';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';
import {KatexModule} from 'ng-katex';
import {AngularResizeEventModule} from 'angular-resize-event';

// Application
import {TabService} from './shared/services/tab.service';
import {UserService} from './shared/services/user.service';
import {DataSourceService} from './shared/services/data-source.service';
import {SnapshotService} from './shared/services/snapshot.service';
import {FilterBuilderService} from './shared/services/filter-builder.service';
import {ReportSummaryService} from './shared/services/report-summary.service';
import {HintModule} from 'app/modules/ui/hint/hint.module';
import {TransitionService} from 'app/shared/services/transition.service';
import {GradientService} from 'app/shared/services/gradient.service';
import {MenuService} from 'app/shared/services/menu.service';
import {CellService} from 'app/shared/services/cell.service';
import {GroupingEditorService} from 'app/shared/services/grouping-editor.service';
import {StorageService} from 'app/shared/services/storage.service';

import {AppComponent} from './app.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {ScheduleBrowserComponent} from './components/schedule-browser/schedule-browser.component';
import {WizardModule} from './components/wizard/wizard.module';
import {SharedComponentsModule} from './shared/components/shared-components.module';
import {HeaderComponent} from './components/header/header.component';
import {DxFormatPipe} from './shared/pipes/dx-format.pipe';
import {EnsureArrayPipe} from './shared/pipes/ensure-array.pipe';
import {ExceptPipe} from './shared/pipes/except.pipe';
import {UniquePipe} from './shared/pipes/unique.pipe';
import {ReportChooserComponent} from './components/report-chooser/report-chooser.component';
import {DisplayOptionsComponent} from './components/display-options/display-options.component';
import {NarrativeComponent} from './components/narrative/narrative.component';
import {OptionsDrawerComponent} from './components/options-drawer/options-drawer.component';
import {PivotGridComponent} from './components/pivot-grid/pivot-grid.component';
import {PrimaryGovernmentPipe} from './shared/pipes/primary-government.pipe';
import {StringListPipe} from './shared/pipes/string-list.pipe';
import {LatestMetricPipe} from './shared/pipes/latest-metric.pipe';
import {TaskbarComponent} from './components/taskbar/taskbar.component';
import {FilerReportComponent} from './components/filer-report/filer-report.component';
import {ReportLayoutBuilderComponent} from './components/report-layout-builder/report-layout-builder.component';
import {FundChooserComponent} from './components/fund-chooser/fund-chooser.component';
import {
	ExpenditureObjectChooserComponent
} from './components/expenditure-object-chooser/expenditure-object-chooser.component';
import {DebtCapitalToggleComponent} from './components/debt-capital-toggle/debt-capital-toggle.component';
import {TabReuseModalComponent} from './components/tab-reuse-modal/tab-reuse-modal.component';
import {ComparisonDialogComponent} from './components/comparison-dialog/comparison-dialog.component';
import {AboutComponent} from './components/about/about.component';
import {DisclaimerComponent} from './components/disclaimer/disclaimer.component';
import {LoginComponent} from './components/login/login.component';
import {FirstTimeComponent} from './components/first-time/first-time.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {AccountSettingsComponent} from './components/account-settings/account-settings.component';
import {UserDropdownComponent} from './components/user-dropdown/user-dropdown.component';
import {PeerSetEditorComponent} from './components/peer-set-editor/peer-set-editor.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {CommandBarComponent} from './components/command-bar/command-bar.component';
import {GovernmentSearchComponent} from './components/government-search/government-search.component';
import {TestUserHarnessComponent} from './components/tests/user-harness/user-harness.component';
import {HypeComponent} from './components/hype/hype.component';
import {SidebarDrawerComponent} from './components/sidebar-drawer/sidebar-drawer.component';
import {FeedbackComponent} from './components/feedback/feedback.component';
import {ReportMenuComponent} from './components/report-menu/report-menu.component';
import {GovernmentChooserComponent} from './components/government-chooser/government-chooser.component';
import {GovernmentSearchMapComponent} from './components/government-search-map/government-search-map.component';
import {GovernmentSearchNameComponent} from './components/government-search-name/government-search-name.component';
import {GovernmentTearSheetComponent} from './modules/info/government-tear-sheet/government-tear-sheet.component';
import {ShareUrlComponent} from './components/share-url/share-url.component';
import {
	IndicatorInstanceSelectorComponent
} from './components/indicator-instance-selector/indicator-instance-selector.component';
import {CompactCategoryNamePipe} from './shared/pipes/compact-category-name.pipe';
import {DataExtractComponent} from './components/data-extract/data-extract/data-extract.component';
import {GlobalErrorHandler} from './shared/handlers/global-error.handler';
import {HistoryService} from './shared/services/history.service';
import {ScheduleBrowserChartComponent} from './components/schedule-browser-chart/schedule-browser-chart.component';
import {SwitchComponent} from './shared/switch/switch.component';
import {LoginBannerComponent} from './components/login-banner/login-banner.component';
import {NoSpecialAccessComponent} from './components/no-special-access/no-special-access.component';
import {GovernmentProfileModule} from './components/government-profile/government-profile.module';
import {ProjectionModule} from './components/projection/projection.module';
import {ModalModule} from './shared/modules/modal/modal.module';
import {PeerSetViewerComponent} from './components/peer-set-viewer/peer-set-viewer.component';
import {SideDrawerModule} from './components/side-drawer/side-drawer.module';
import {ReportPeerSetSelectorComponent} from './components/report-peer-set-selector/report-peer-set-selector.component';
import {PeerSetSelectorComponent} from './components/peer-set-selector/peer-set-selector.component';
import {GovernmentTearSheetModule} from './modules/info/government-tear-sheet/government-tear-sheet.module';
import {IndicatorsModule} from './modules/financials/indicators/indicators.module';
import {SidebarEntitiesComponent} from './components/sidebar-entities/sidebar-entities.component';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './components/route-actions/home/home.component';
import {NewShareUrlTabComponent} from './components/route-actions/new-share-url-tab/new-share-url-tab.component';
import {
	NewGovernmentProfileTabComponent
} from './components/route-actions/new-government-profile-tab/new-government-profile-tab.component';
import {
	NewGovernmentTypeProfileTabComponent
} from './components/route-actions/new-government-type-profile-tab/new-government-type-profile-tab.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {
	GovernmentTypeTearSheetModule
} from './modules/info/government-type-tear-sheet/government-type-tear-sheet.module';
import {IndicatorNameChooserComponent} from './components/indicator-name-chooser/indicator-name-chooser.component';
import {FilingBasisPickerModule} from './modules/ui/filing-basis-picker/filing-basis-picker.module';
import {IndicatorReportMapComponent} from './components/indicator-report-map/indicator-report-map.component';
import {MapModule} from './modules/ui/map/map.module';
import {LoadingModule} from './modules/ui/loading/loading.module';
import {BadgesModule} from './modules/info/badges/badges.module';
import {ReportSelectorComponent} from './components/report-selector/report-selector.component';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {DatasetSelectorComponent} from './components/dataset-selector/dataset-selector.component';
import {BARSRichTextComponent} from './components/tests/bars-rich-text/bars-rich-text.component';
import {ReportIssueComponent} from './components/report-issue/report-issue.component';
import {
	AccountDescriptorServiceModule
} from './modules/services/account-descriptor-service/account-descriptor-service.module';
import {BarsDescriptionModule} from './modules/ui/bars-description/bars-description.module';
import { ManageResourcesComponent } from './components/resources/manage-resources/manage-resources.component';
import { CreateEditResourceComponent } from './components/resources/create-edit-resource/create-edit-resource.component';
import {FormsModule} from '@angular/forms';
import { ResourceItemComponent } from './components/resources/resource-item/resource-item.component';
import { FinancialHealthIndicatorsComponent } from './components/data-extract/financial-health-indicators/financial-health-indicators.component';
import {AccountingCategoryServiceModule} from './modules/services/accounting-category/accounting-category-service.module';
import { ShapefileLoaderComponent } from './components/shapefile-loader/shapefile-loader.component';
import { ShapefileVintagesComponent } from './components/shapefile-loader/shapefile-vintages/shapefile-vintages.component';
import { MaintainVintageComponent } from './components/shapefile-loader/maintain-vintage/maintain-vintage.component';
import { InteractiveFilingStatisticsComponent } from './components/interactive-filing-statistics/interactive-filing-statistics.component';
import {
	AnnualFilingCurrentProgressModule
} from './modules/filings/annual-filing-current-progress/annual-filing-current-progress.module';
import {
	AnnualFilingStatusTrendModule
} from './modules/filings/annual-filing-status-trend/annual-filing-status-trend.module';
import {DxoToolbarModule} from 'devextreme-angular/ui/nested';
import { ShapefileUploadComponent } from './components/shapefile-loader/shapefile-upload/shapefile-upload.component';
import { TCADistrictAssignmentsComponent } from './components/shapefile-loader/tcadistrict-assignments/tcadistrict-assignments.component';
import { CrosswalkEditorComponent } from './components/shapefile-loader/crosswalk-editor/crosswalk-editor.component';
import { LocalGovernmentShapeGeneratorComponent } from './components/shapefile-loader/local-government-shape-generator/local-government-shape-generator.component';
import { PensionLiabilityVarianceComponent } from './components/data-extract/pefi/pension-liability-variance.component';
import { AnnualFilingStandaloneFilingStatisticsModule} from './modules/filings/annual-filing-standalone-filing-statistics/annual-filing-standalone-filing-statistics.module';
import {PublishLgcsSnapshotComponent} from './components/publish-lgcs-snapshot/publish-lgcs-snapshot.component';
import { PopulationImporterComponent } from './components/population-importer/population-importer.component';
import { PopulationVintagesComponent } from './components/population-importer/population-vintages/population-vintages.component';
import { PopulationRecordsComponent } from './components/population-importer/population-records/population-records.component';

@NgModule({
	declarations: [
		AppComponent,
		TabsComponent,
		ScheduleBrowserComponent,
		HeaderComponent,
		DxFormatPipe,
		EnsureArrayPipe,
		ExceptPipe,
		UniquePipe,
		ReportChooserComponent,
		DisplayOptionsComponent,
		NarrativeComponent,
		OptionsDrawerComponent,
		PivotGridComponent,
		PrimaryGovernmentPipe,
		StringListPipe,
		LatestMetricPipe,
		TaskbarComponent,
		FilerReportComponent,
		ReportLayoutBuilderComponent,
		FundChooserComponent,
		ExpenditureObjectChooserComponent,
		DebtCapitalToggleComponent,
		TabReuseModalComponent,
		ComparisonDialogComponent,
		AboutComponent,
		DisclaimerComponent,
		LoginComponent,
		FirstTimeComponent,
		SidebarComponent,
		SidebarEntitiesComponent,
		AccountSettingsComponent,
		UserDropdownComponent,
		PeerSetEditorComponent,
		BreadcrumbComponent,
		CommandBarComponent,
		GovernmentSearchComponent,
		TestUserHarnessComponent,
		HypeComponent,
		SidebarDrawerComponent,
		FeedbackComponent,
		ReportMenuComponent,
		GovernmentChooserComponent,
		GovernmentSearchMapComponent,
		GovernmentSearchNameComponent,
		ShareUrlComponent,
		IndicatorInstanceSelectorComponent,
		CompactCategoryNamePipe,
		DataExtractComponent,
		ScheduleBrowserChartComponent,
		SwitchComponent,
		LoginBannerComponent,
		NoSpecialAccessComponent,
		PeerSetViewerComponent,
		ReportPeerSetSelectorComponent,
		PeerSetSelectorComponent,
		SidebarEntitiesComponent,
		HomeComponent,
		NewShareUrlTabComponent,
		NewGovernmentProfileTabComponent,
		NewGovernmentTypeProfileTabComponent,
		DashboardComponent,
		IndicatorNameChooserComponent,
		IndicatorReportMapComponent,
		ReportSelectorComponent,
		DatasetSelectorComponent,
		BARSRichTextComponent,
		BARSRichTextComponent,
		ReportIssueComponent,
		ManageResourcesComponent,
		CreateEditResourceComponent,
		ResourceItemComponent,
		FinancialHealthIndicatorsComponent,
		ShapefileLoaderComponent,
		ShapefileVintagesComponent,
		MaintainVintageComponent,
		InteractiveFilingStatisticsComponent,
  ShapefileUploadComponent,
  TCADistrictAssignmentsComponent,
  CrosswalkEditorComponent,
  LocalGovernmentShapeGeneratorComponent,
  PensionLiabilityVarianceComponent,
		PublishLgcsSnapshotComponent,
  PopulationImporterComponent,
  PopulationVintagesComponent,
  PopulationRecordsComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		DxTabPanelModule,
		DxPopupModule,
		DxLoadPanelModule,
		DxSelectBoxModule,
		DxButtonModule,
		DxPivotGridModule,
		DxVectorMapModule,
		DxDataGridModule,
		DxCheckBoxModule,
		DxTreeViewModule,
		DxTooltipModule,
		DxSwitchModule,
		DxMenuModule,
		DxTextBoxModule,
		DxScrollViewModule,
		DxRadioGroupModule,
		DxAccordionModule,
		DxSparklineModule,
		DxPieChartModule,
		DxPopoverModule,
		DxChartModule,
		DxMapModule,
		DxDrawerModule,
		DxRangeSliderModule,
		DxAutocompleteModule,
		DxTreeListModule,
		DxCircularGaugeModule,
		DxLoadIndicatorModule,
		DxValidatorModule,
		DxValidationGroupModule,
		DxFormModule,
		DxTreeMapModule,
		FontAwesomeModule,
		WizardModule,
		SharedComponentsModule,
		HintModule,
		DndModule,
		// todo remove when MapModule complete
		NgxMapboxGLModule.withConfig({accessToken: environment.mapboxAccessToken}),
		KatexModule,
		AngularResizeEventModule,
		BrowserAnimationsModule,
		GovernmentProfileModule,
		ProjectionModule,
		ModalModule,
		SideDrawerModule,
		DxTextAreaModule,
		DxValidationSummaryModule,
		GovernmentTearSheetModule,
		GovernmentTypeTearSheetModule,
		IndicatorsModule,
		AppRoutingModule,
		DxTagBoxModule,
		FilingBasisPickerModule,
		MapModule,
		LoadingModule,
		BadgesModule,
		FilterPipeModule,
		DxButtonGroupModule,
		DxListModule,
		DxMultiViewModule,
		DxFileUploaderModule,
		AccountDescriptorServiceModule,
		AccountingCategoryServiceModule,
		BarsDescriptionModule,
		FormsModule,
		DxLookupModule,
		AnnualFilingCurrentProgressModule,
		AnnualFilingStatusTrendModule,
		AnnualFilingStandaloneFilingStatisticsModule,
		DxoToolbarModule
	],
	providers: [
		HistoryService,
		TabService,
		UserService,
		SnapshotService,
		FilterBuilderService,
		ReportSummaryService,
		StringListPipe,
		LatestMetricPipe,
		TransitionService,
		GradientService,
		DatePipe,
		MenuService,
		CellService,
		GroupingEditorService,
		CurrencyPipe,
		PercentPipe,
		StorageService,
		CompactCategoryNamePipe,
		{provide: ErrorHandler, useClass: GlobalErrorHandler}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
