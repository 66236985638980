import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {UserInterfaceService} from 'app/shared/services/user-interface.service';
import {faFolderOpen, faInfoCircle, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {TabService} from 'app/shared/services/tab.service';
import {Tab} from 'app/shared/models/tab';
import {TabState} from 'app/shared/models/tab-state';
import {PivotGridService} from 'app/shared/services/pivot-grid.service';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {UserService} from 'app/shared/services/user.service';
import {LoggerService} from 'app/shared/services/logger.service';
import {ReportMenuService} from 'app/shared/services/report-menu.service';
import {Composite, Indicator} from './report-menu.models';
import {Years} from 'app/shared/models/years';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {User} from 'app/shared/models/user';
import {SideDrawerComponent} from '../side-drawer/side-drawer.component';
import {PeerSet} from '../../shared/models/peer-set';
import {DxPopupComponent} from 'devextreme-angular';
import {confirm} from 'devextreme/ui/dialog';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {DisabledGovernmentType} from '../../shared/components/government-types/disabled-government-type';
import {GovernmentTypesService} from '../../shared/components/government-types/government-types.service';
import {INDICATOR_REPORT_TYPES} from '../../modules/api/fit-api/models/indicators/indicator-report-type';
import {PeerSetsService} from '../../shared/services/peer-sets.service';
import {Peer} from '../../shared/models/peer';
import Timeout = NodeJS.Timeout;
import {first} from 'rxjs/operators';
import {maxComparisonPeers} from '../../shared/services/peer-sets.service';
import {Report, REPORTS} from '../../shared/models/report';
import {REPORT_TYPES, ReportTypeMenuOption, ReportType} from '../../shared/models/report-type-menu-option';
import {TabBarItem} from '../../shared/components/tab-bar/tab-bar.component';
import {EXPLORE_BY_OPTIONS, ExploreByOption, ExploreType} from '../../shared/models/explore-by-option';
import {AccountingCategory} from "../../modules/services/accounting-category/models/accounting-category";
import {forkJoin} from 'rxjs';

/**
 * 1. Upon opening, reset component (copy from source values to transients).
 * 2. Handle any events by calling appropriate "set" method in ReportMenuService.
 * 2a. Set method calls any validations that are required
 * 3. Check model.hasOwnProperty in HTML template to determine which widgets to show.
 * 4. Individual components check transients for additional constraints.
 *    E.g. Baseline Gov't only in single-year mode.
 * 5. Exit event checks isDirty and shows confirmation dialog, if necessary.
 */

@Component({
	selector:    'app-report-menu',
	templateUrl: './report-menu.component.html',
	styleUrls:   ['./report-menu.component.scss']
})
export class ReportMenuComponent implements OnInit, AfterViewInit {

	@ViewChild(SideDrawerComponent, {static: true}) drawer;
	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	@ViewChild('content') contentElement: ElementRef;
	/** true if transients differ from source inputs */
	isDirty: boolean;
	/** Confirmation dialog is currently active */
	isConfirmationDialogVisible: boolean;
	/** Open in new tab */
	isOpenInNewTab    = false;
	isApplyingChanges = false;
	tab: Tab;
	user: User;
	pivotGridData: PivotGridDataSource;
	public snapshot;
	private _latestSnapshotId;
	public schoolsDataSetSource;
	showAccountCodes  = false;
	showBetaForUser: boolean;
	showSchoolsForUser: boolean;

	availableReportTypes: Readonly<Array<TabBarItem>>;
	availableExploreByOptions: Readonly<Array<Partial<ExploreByOption>>>;

	/** Background data that cannot be manipulated */
	sources           = {
		availableYears:           [] as Array<number>,
		indicatorAccountingBasis: null as string,
		disabledGovernmentTypes: [] as Array<DisabledGovernmentType>
	};

	/** User-changable values. Keep track of all possible values so users can easily flip
	 * back and forth without losing settings.
	 */
	transients        = new Composite();
	peerBenchmarking  = false;
	/** We use this to detect which options are available, as well as strip off the changes that don't
	 * need to be applied during submit. */
	model             = {};
	previousModel     = {};
	// centralized place to check for validations, as well as preventing constant calling of functions
	validations       = {
		govTypeFilter:               null,
		financialsDatasetSource:           null,
		yearsMode:                   'multi',
		isBaselineGovernmentEnabled: false,
		hasCategoriesFilter:         false,
		hasExpenditureObjectsFilter: false
	};
	baselineGovernmentAsPeerSet: PeerSet;

	icons = {
		close: faTimes,
		info:  faInfoCircle,
		load: faFolderOpen,
		save: faSave
	};

	isBaselineNoteVisible = false;
	isBaselineNoteVisibleTimer: Timeout;

	expenditureObjectsDisplayFormat = (item) => item.name;

	constructor(
		private uiService: UserInterfaceService,
		private tabService: TabService,
		private pivotGridService: PivotGridService,
		private snapshotService: SnapshotService,
		private userService: UserService,
		private logger: LoggerService,
		private service: ReportMenuService,
		private fitApi: FitApiService,
		private govTypesService: GovernmentTypesService,
		private peerSets: PeerSetsService
	) {
	}

	ngOnInit() {
		this.uiService.reportMenu$.subscribe(value => {
			this.drawer.setVisibility(value);
			this.onVisibleChange(value);
		});
		this.tabService.tabs.subscribe(this.onTabsUpdate);
		this.userService.user.subscribe(this.onUserUpdate);

		forkJoin({
			latest: this.fitApi.latestSnapshotId,
			schoolsDataSetSource: this.fitApi.getOSPIDataset(true)
		}).subscribe(response => {
			this._latestSnapshotId = response.latest;
			this.schoolsDataSetSource = response.schoolsDataSetSource;
		});
	}

	ngAfterViewInit() {
		this.logger.log(this.contentElement);
	}

	setShowAccountCodes = () => {
		const isTabShowingAccountCodes = this.tab && this.tab.pivotGridSettings && this.tab.pivotGridSettings.showAccountCodes === true;
		const isUserTechnical          = this.user && this.user.showTechnical === true;
		this.showAccountCodes          = isTabShowingAccountCodes === true || isUserTechnical === true;
	};

	onUserUpdate = (user: User) => {
		this.user = user;
		// allow users with any FIT access role to see beta features
		this.showBetaForUser = user.hasAnyFitAccessRole;
		// allow users with FIT global access or School Access roles to see schools features
		this.showSchoolsForUser = user.hasGlobalAccess() || user.hasAccessToAnySchool();
		this.setShowAccountCodes();
		this.generateAvailableReportTypes();
	};

	generateAvailableReportTypes() {
		this.availableReportTypes = REPORT_TYPES
			.filter(this.schoolsForUser)
			.map(rt => new TabBarItem(rt.reportType, rt.name));
	}

	/**
	 * Remove report types when showSchoolsForUser is not true
	 */
	private schoolsForUser = (reportType: ReportTypeMenuOption): boolean => reportType.schools === true ? this.showSchoolsForUser : true;

	onTabsUpdate = (tabs: Array<Tab>) => {
		this.logger.log(`${this.constructor.name}.tabsUpdate received tabs update`, tabs);
		if (tabs.length === 0 || !tabs.find(t => t.selected)) {
			return;
		}
		this.tab = tabs.find(t => t.selected);
		this.setShowAccountCodes();
		// need to get the dataSource even if not lgfrs mode in case we switch to it
		this.pivotGridService.getDataSource(this.tab).pipe(first()).subscribe(dataSource => {
			this.pivotGridData = dataSource;
		});

		this.snapshotService.getSnapshot(this.tab.snapshotId).pipe(first()).subscribe(this.onSnapshotUpdate);
	};

	onSnapshotUpdate = snapshot => {
		if (snapshot === null) {
			return;
		}
		this.snapshot               = snapshot;
		this.sources.availableYears = this.snapshot.detail.includedYears;
		// not really necessary as the component resets on opening, and you can't change snapshot while open
		this.service.setAvailableYears(this);
	};

	updateDisabledGovTypes = () => {
		if (this.transients.explore === ExploreType.financialCondition) {
			const indicatorReportType = INDICATOR_REPORT_TYPES.find(x => x.key === this.transients.report);
			this.govTypesService.getDisabledGovTypesForIndicators(this.transients.filingBasis, indicatorReportType, this.snapshot.id)
				.subscribe(disabledGovTypes => {
						this.sources.disabledGovernmentTypes = disabledGovTypes;
					}
				);
		} else {
			this.sources.disabledGovernmentTypes = null;
		}
	};

	onVisibleChange = (visibility: boolean) => {
		if (visibility === true) {
			this.service.reset(this);
		}
	};

	@HostListener('document:keydown', ['$event'])
	closeOnEscape(event) {
		if (event.key === 'Escape') {
			this.drawer.close();
		}
	}

	onConfirmationDialogChange(dxEvent: any, action: boolean): void {
		// prevent bubble up
		dxEvent.event.preventDefault();
		dxEvent.event.stopPropagation();
		// hide this dialog either way
		this.isConfirmationDialogVisible = false;
		// force closeDrawer
		if (action === true) {
			this.drawer.close(true);
		}
	}

	submit = (event?: Event): void => {
		this.logger.log('We submitted');
		event.preventDefault();

		// todo perform operations to whatever state is active
		switch (this.transients.reportType) {
			case ReportType.lgfrs:
			case ReportType.ospi:
				this.service.applyChangesToLGFRSReport(this);
				break;
			case ReportType.indicator:
				this.service.applyChangesToIndicator(this);
				break;
		}

		this.drawer.close(true);
	};

	runDirtyCheck = (): boolean => this.isDirty = this.service.checkIsDirty(this);

	onReportTypeChange = (id: ReportType): void => {
		this.service.setReportType(this, id);
		this.availableExploreByOptions = this.service.generateAvailableExploreByOptions(id);
		this.service.setFinancialsDatasetSourceFilter(this);
		this.validateBaselineGovernment();
		this.validateCategoryFilter();
		this.validateExpenditureObjectsFilter();
		this.runDirtyCheck();
	};

	confirmExploreChange = (dxEvent: any) => new Promise<'confirmed' | 'declined' | 'no-check'>(res => {
		// Checks if the user's new selection is not track A, and that they're currently on th the live snapshot
		if (dxEvent.previousValue === 'individual' && dxEvent.value !== 'individual' && this.tab.snapshotId === 'live') {
			confirm(
				'Live filing data can only be viewed for an individual government that you have access to.<br/>' +
				'Continuing will change the dataset from live filing data to the latest snapshot available.',
				'Change Snapshot?'
			).then(confirmed => res(confirmed ? 'confirmed' : 'declined'));
		}
		else {
			res('no-check');
		}
	});

	onExploreChange = (dxEvent: any) => {
		this.confirmExploreChange(dxEvent).then(choice => {
			// If we're skipping the check, or the user has explicitly confirmed
			if (choice === 'no-check' || choice === 'confirmed') {
				// If the user has confirmed to change snapshot, switch to latest
				if (choice === 'confirmed') {
					this.tab.snapshotIdSubject.next(this._latestSnapshotId);
					this.tabService.save(this.tab);
				}

				// Regardless, always set explore, validate changes and run the dirty check
				this.service.setExplore(this, dxEvent.value);
				this.validateCategoryFilter();
				this.validateBaselineGovernment();
				this.runDirtyCheck();
			}
			else if (choice === 'declined') {
				// If the user declined, revert the input's value
				this.transients.explore = dxEvent.previousValue;
			}
		});
	};

	onBaselineChange = (baseline: any) => {
		const value = baseline && baseline.length ? baseline[0] : null;
		this.service.setBaselineGovernment(this, value);
		this.service.setGovTypeFilter(this);
		this.validateYears();
		this.runDirtyCheck();
	};

	onIncludedGovernmentsChange = (governments: Array<any>) => {
		this.service.setIncludedGovernments(this, governments);
		this.service.setGovTypeFilter(this);
		this.validateYears();
		this.runDirtyCheck();
	};

	onBaselineDropped(event) {
		const droppedGov      = event.data;
		const currentBaseline = this.transients.baselineGovernment;
		if (currentBaseline && currentBaseline.mcag === droppedGov.mcag) {
			return;
		}
		const includedGovernments = this.transients.includedGovernments.slice();
		const index               = includedGovernments.findIndex((g) => g.mcag === droppedGov.mcag);
		includedGovernments.splice(index, 1);
		if (currentBaseline) {
			includedGovernments.push(currentBaseline);
		}
		this.service.setIncludedGovernments(this, includedGovernments);
		this.service.setBaselineGovernment(this, droppedGov);
		this.validateYears();
		this.runDirtyCheck();
	}

	onIncludedDropped(event) {
		const droppedGov          = event.data;
		const includedGovernments = this.transients.includedGovernments
			? this.transients.includedGovernments.slice()
			: [];
		// handle case where user drags from included govs, but also drops on included govs
		if (Array.isArray(includedGovernments) && includedGovernments.find(x => x.mcag === droppedGov.mcag)) {
			return;
		}
		includedGovernments.push(droppedGov);
		this.service.setIncludedGovernments(this, includedGovernments);
		this.service.setBaselineGovernment(this, null);
		this.validateYears();
		this.runDirtyCheck();
	}

	onReportChange = (event) => {
		// this.logger.log(event);
		this.service.setReport(this, event.value);
		this.validateCategoryFilter();
		this.validateExpenditureObjectsFilter();
		if (this.transients.explore === ExploreType.financialCondition) {
			this.updateDisabledGovTypes();
		}
		this.runDirtyCheck();
	};

	onFilingBasisChange = (event: FilingBasis) => {
		this.service.setFilingBasis(this, event);
		if (this.transients.explore === ExploreType.financialCondition) {
			this.updateDisabledGovTypes();
		}
		this.runDirtyCheck();
	};

	onIndicatorInstanceChange = (event) => {
		this.service.setIndicatorCode(this, event);
		// todo refresh funds
		this.runDirtyCheck();
	};

	onIndicatorNameChange = (event: Array<string>) => {
		this.service.setIndicatorNames(this, event);
		this.runDirtyCheck();
	};

	onYearsChange = (value: Years) => {
		const latestYear = Math.max(...this.sources.availableYears);
		if (this.tab.pivotGridSettings.projectionId && latestYear !== value[1]) {
			value[1] = latestYear;
			this.uiService.showToast('error', 'You cannot change the ending year of a report while a projection is applied.');
		}
		this.service.setYears(this, value);
		this.validateBaselineGovernment();
		this.runDirtyCheck();
	};

	onFundFilterChange(selections) {
		this.service.setFunds(this, selections);
		this.logger.info('onFundFilterChange:', this);
		this.runDirtyCheck();
	}

	onGovTypesChange(event) {
		this.logger.info('onGovTypesChange', event);
		this.service.setGovTypes(this, event);
		this.runDirtyCheck();
	}

	onLocationsChanged(event) {
		this.logger.info('onLocationsChanged', event);
		this.service.setLocations(this, event);
		this.runDirtyCheck();
	}

	onHasFundsChanged(value) {
		this.service.setHasFunds(this, value);
		this.runDirtyCheck();
	}

	onExpenditureObjectsChanged(value) {
		const ids = value;
		this.service.setExpenditureObjects(this, ids);
		this.runDirtyCheck();
	}

	onHasExpenditureObjectsChanged(value) {
		this.service.setHasExpenditureObjects(this, value);
		this.runDirtyCheck();
	}

	onCategoriesChanged(value) {
		this.service.setCategories(this, value);
		this.runDirtyCheck();
	}

	onHasCategoriesChanged(value) {
		this.service.setHasCategories(this, value);
		this.runDirtyCheck();
	}

	// todo type as AccountingCategory
	onCategoryChanged(value: AccountingCategory) {
		this.logger.info('onCategoryChanged', value);
		this.service.setCategory(this, value);
		this.runDirtyCheck();
	}

	// onPeerBenchmarkingChanged(value) {
	// 	// Need something like this built into the report service layer
	// 	// this.service.setHasCategories(this, value);
	// 	this.peerBenchmarking = value;
	// 	this.runDirtyCheck();
	// }

	validateCategoryFilter(): void {
		const isSupportedByModel             = this.model.hasOwnProperty('categories');
		const isApplicableReport             = this.transients.report === 'revenues'
			|| this.transients.report === 'revenuesWithOthers'
			|| this.transients.report === 'expenditures'
			|| this.transients.report === 'expendituresWithOthers';
		this.validations.hasCategoriesFilter = isSupportedByModel && isApplicableReport;
	}

	validateExpenditureObjectsFilter(): void {
		const isSupportedByModel                     = this.model.hasOwnProperty('expenditureObjects');
		const isExpendituresReport                   = this.transients.report === 'expendituresWithOthers' || this.transients.report === 'expenditures';
		this.validations.hasExpenditureObjectsFilter = isSupportedByModel && isExpendituresReport;
	}

	/**
	 * Sets flag validations.isBaselineGovernmentEnabled and runs any related government moving logic
	 */
	validateBaselineGovernment = (): void => {
		let isValid: boolean;
		if (this.model instanceof Indicator) {
			this.validations.isBaselineGovernmentEnabled = true;
			return;
		}
		const yearMode = !this.transients.years[1] || this.transients.years[0] === this.transients.years[1]
			? 'single' : 'multi';

		if (this.transients.explore === ExploreType.comparison) {
			if (yearMode === 'multi') {
				if (this.validations.isBaselineGovernmentEnabled === true && this.transients.baselineGovernment) {
					this.transients.includedGovernments = this.transients.includedGovernments || [];
					// Move baseline to included governments if there is room
					if (this.transients.includedGovernments?.length < maxComparisonPeers) {
						this.transients.includedGovernments.splice(0, 0, this.transients.baselineGovernment);
					}
					this.transients.baselineGovernment = null;
				}
				isValid = false;
			}
			else if (yearMode === 'single') {
				isValid = true;
			}
		}
		else if (this.transients.explore === ExploreType.individual) {
			// if no baseline, but there are includedGovs, pick off the first one for individual's baseline
			if (!this.transients.baselineGovernment && this.transients.includedGovernments && this.transients.includedGovernments.length) {
				this.transients.baselineGovernment  = this.transients.includedGovernments[0];
				this.transients.includedGovernments = null;
			}
			isValid = true;
		}
		else { // catch-all for other explore types
			this.transients.baselineGovernment = null;
			isValid                            = false;
		}

		this.logger.log('validateBaselineGovernment', isValid);
		this.validations.isBaselineGovernmentEnabled = isValid;
	};

	validateYears = (): void => {
		if (this.transients.reportType === ReportType.lgfrs
			&& this.transients.explore === ExploreType.comparison
			&& this.transients.baselineGovernment !== null
		) {
			this.validations.yearsMode = 'single';
		}
		else {
			this.validations.yearsMode = 'multi';
		}

		this.logger.log('validateYears', this.validations.yearsMode);
	};

	validateHasGovernments = (dxValidation): boolean => {
		this.logger.log('validateHasGovernments', dxValidation, this.service.getCombineGovernments(this).length > 0);
		return this.service.getCombineGovernments(this).length > 0;
	};

	validateGovTypes = (dxValidation): boolean => {
		this.logger.log('validateGovTypes', dxValidation);
		if (this.transients.explore === ExploreType.financialCondition) {
			return dxValidation.value?.length > 0;
		}

		return true;
	}

	loadPeers() {
		this.peerSets.openViewerForSelect().subscribe(peerSet => {
			this.logger.log(`Report Menu received PeerSet`, peerSet);
			// Break object references to prevent changing prime flag in source peer set during report menu manipulation
			const peers = peerSet.peers.map(x => Object.assign(new Peer, x));
			const baseline = peers.find(x => x.prime);
			if (baseline) {
				const isSingleYearMode = this.transients.years[0] === this.transients.years[1];
				// if baseline present in peer set, but current report menu scenario does not support it, discard (do not set) and notify the user
				if (this.transients.explore === ExploreType.comparison && !isSingleYearMode) {
					// notify user
					this.isBaselineNoteVisible = true;
					clearTimeout(this.isBaselineNoteVisibleTimer);
					this.isBaselineNoteVisibleTimer = setTimeout(() => this.isBaselineNoteVisible = false, 8000);
				} else {
					// supported for scenario
					this.service.setBaselineGovernment(this, baseline);
				}
			} else {
				// baseline not provided, explicitly clear out
				this.service.setBaselineGovernment(this, null);
			}
			// Filter out any baseline already set in report menu, and make sure to break object reference
			const included = peers.filter(x => !x.prime && x.mcag !== this.transients?.baselineGovernment?.mcag);
			this.service.setIncludedGovernments(this, included);
			this.runDirtyCheck();
		});
	}

	savePeers() {
		let peers = [];
		if (this.transients.includedGovernments) {
			peers = peers.concat(this.transients.includedGovernments);
		}
		const isSingleYearMode = this.transients.years[0] === this.transients.years[1];
		// This is an ugly hack since we try to preserve the baseline if you swap between scenarios before saving, but
		// baseline is not actually valid for multiyear mode
		if (this.transients.baselineGovernment && this.transients.explore === ExploreType.comparison && isSingleYearMode) {
			peers.push(this.transients.baselineGovernment);
		}

		this.peerSets.openEditorForCreate(peers.map(this.toPeer));
	}

	/**
	 * Strip off any unused properties to avoid confusion.
	 * @param government
	 */
	private toPeer = (government: any): Peer =>
		Object.assign(
			new Peer,
			{
				mcag: government.mcag,
				entityNameWithDba: government.entityNameWithDba,
				govTypeCode: government.govTypeCode,
				prime: government.prime
			}
		);

}
