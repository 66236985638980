<ul *ngIf="indicatorGroups.length && !tab.indicatorGroupsState" class="indicator-groups">
	<li
		*ngFor="let group of indicatorGroups"
		class="indicator"
		[class.indicator--good]="group.outlookInfo.outlook === 'Good'"
		[class.indicator--cautionary]="group.outlookInfo.outlook === 'Cautionary'"
		[class.indicator--concerning]="group.outlookInfo.outlook === 'Concerning'"
		[class.indicator--is-group]="group.indicators.length > 1"
		(click)="group.indicators.length > 1 ? setIndicatorGroup = group : handleIndicatorSelect(group.indicators[0], displayYear)"
	>
		<div class="indicator__inner">
			<div *ngIf="group.indicators.length > 1">
				<div class="indicator__header">
					<div class="indicator__sub-title">Group outlook</div>
					<div class="indicator__title">
						{{ group.outlookInfo.outlook }}
						<wasao-hint icon="infoCircle" showOn="hover">{{ group.outlookInfo.annotation }}</wasao-hint>
					</div>
				</div>

				<div class="indicator__content">
					<dx-circular-gauge
						class="indicator__gauge"
						[value]="getGaugeValue(group.outlookInfo.outlook)"
						[size]="{height: 75}"
						[geometry]="{startAngle: 200, endAngle: 340}"
						[valueIndicator]="{color: group.outlookInfo.outlook !== 'Indeterminate' ? '#81807e' : '#bcbcbc'}"
					>
						<dxo-scale
							[startValue]="0"
							[endValue]="6"
							[label]="{visible: false}"
							[tickInterval]="2"
						>
						</dxo-scale>
						<dxo-range-container>
							<dxi-range
								[color]="group.outlookInfo.outlook !== 'Indeterminate' ? '#e8702d' : '#bcbcbc'"
								[startValue]="0"
								[endValue]="2">
							</dxi-range>
							<dxi-range
								[color]="group.outlookInfo.outlook !== 'Indeterminate' ? '#f6b333' : '#bcbcbc'"
								[startValue]="2"
								[endValue]="4">
							</dxi-range>
							<dxi-range
								[color]="group.outlookInfo.outlook !== 'Indeterminate' ? '#a2d45e' : '#bcbcbc'"
								[startValue]="4"
								[endValue]="6">
							</dxi-range>
						</dxo-range-container>
					</dx-circular-gauge>
				</div>

				<div class="indicator__group-label">
					<div class="indicator__title">
						{{ group.group }}
					</div>
					<div class="indicator__sub-title">
						{{ group.indicators.length }} indicators

						<div *ngIf="group.outlookInfo.indicatorString">
							({{ group.outlookInfo.indicatorString }})
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="group.indicators.length === 1">
				<div class="indicator__header">
					<div class="indicator__title">
						{{ group.indicators[0].title }}
					</div>
					<div class="indicator__sub-title">
						{{ group.indicators[0].subTitle }}
					</div>
				</div>
				<div class="indicator__content">
					<div class="indicator__item">
						<div class="indicator__sub-title">Outlook:</div>
						<div class="indicator__title">
							{{ group.indicators[0].outlookInfo.outlook }}
							<wasao-hint icon="infoCircle" showOn="hover">{{ group.indicators[0].outlookInfo.annotation }}</wasao-hint>
						</div>
						<dx-circular-gauge
							class="indicator__gauge"
							[value]="getGaugeValue(group.indicators[0].outlookInfo.outlook)"
							[size]="{height: 75}"
							[geometry]="{startAngle: 200, endAngle: 340}"
							[valueIndicator]="{color: group.indicators[0].outlookInfo.outlook !== 'Indeterminate' ? '#81807e' : '#bcbcbc'}"
						>
							<dxo-scale
								[startValue]="0"
								[endValue]="6"
								[label]="{visible: false}"
								[tickInterval]="2"
							>
							</dxo-scale>
							<dxo-range-container>
								<dxi-range
									[color]="group.indicators[0].outlookInfo.outlook !== 'Indeterminate' ? '#e8702d' : '#bcbcbc'"
									[startValue]="0"
									[endValue]="2">
								</dxi-range>
								<dxi-range
									[color]="group.indicators[0].outlookInfo.outlook !== 'Indeterminate' ? '#f6b333' : '#bcbcbc'"
									[startValue]="2"
									[endValue]="4">
								</dxi-range>
								<dxi-range
									[color]="group.indicators[0].outlookInfo.outlook !== 'Indeterminate' ? '#a2d45e' : '#bcbcbc'"
									[startValue]="4"
									[endValue]="6">
								</dxi-range>
							</dxo-range-container>
						</dx-circular-gauge>
					</div>
					<div class="indicator__item">
						<dx-sparkline
							class="sparkline"
							[dataSource]="group.indicators[0].calculations"
							argumentField="year"
							valueField="measure"
							type="line"
							lineColor="#0076a9"
							size="30"
							[showMinMax]="false"
							[showFirstLast]="false"
							[tooltip]="{enabled: false}"
						>
						</dx-sparkline>
					</div>
				</div>
			</div>
		</div>
	</li>
</ul>
<div *ngIf="tab.indicatorGroupsState" class="indicator-group__container">
	<div *ngIf="indicatorGroups.length && indicatorGroups.length > 1" class="group__head u-margin-bottom">
		<h3 class="group__title u-margin-bottom-none">{{ tab.indicatorGroupsState.group }}</h3>
		<button class="group__back" (click)="setIndicatorGroup = null">
			<fa-icon [icon]="icons.back"></fa-icon>
			Back to all indicators
		</button>
	</div>
	<ul class="indicator-groups">
		<li
			*ngFor="let indicator of tab.indicatorGroupsState.indicators"
			class="indicator"
			[class.indicator--good]="indicator.outlookInfo.outlook === 'Good'"
			[class.indicator--cautionary]="indicator.outlookInfo.outlook === 'Cautionary'"
			[class.indicator--concerning]="indicator.outlookInfo.outlook === 'Concerning'"
			(click)="handleIndicatorSelect(indicator, displayYear)"
		>
			<div class="indicator__header">
				<div class="indicator__title">
					{{ indicator.title }}
				</div>
				<div class="indicator__sub-title">
					{{ indicator.subTitle }}
				</div>
			</div>
			<div class="indicator__content">
				<div class="indicator__item">
					<div class="indicator__sub-title">Outlook:</div>
					<div class="indicator__title">
						{{ indicator.outlookInfo.outlook }}
						<wasao-hint icon="infoCircle" showOn="hover">{{ indicator.outlookInfo.annotation }}</wasao-hint>
					</div>
					<dx-circular-gauge
						class="indicator__gauge"
						[value]="getGaugeValue(indicator.outlookInfo.outlook)"
						[size]="{height: 75}"
						[geometry]="{startAngle: 190, endAngle: 350}"
						[valueIndicator]="{color: indicator.outlookInfo.outlook !== 'Indeterminate' ? '#81807e' : '#bcbcbc'}"
					>
						<dxo-scale
							[startValue]="0"
							[endValue]="6"
							[label]="{visible: false}"
							[tickInterval]="2"
						>
						</dxo-scale>
						<dxo-range-container>
							<dxi-range
								[color]="indicator.outlookInfo.outlook !== 'Indeterminate' ? '#e8702d' : '#bcbcbc'"
								[startValue]="0"
								[endValue]="2">
							</dxi-range>
							<dxi-range
								[color]="indicator.outlookInfo.outlook !== 'Indeterminate' ? '#f6b333' : '#bcbcbc'"
								[startValue]="2"
								[endValue]="4">
							</dxi-range>
							<dxi-range
								[color]="indicator.outlookInfo.outlook !== 'Indeterminate' ? '#a2d45e' : '#bcbcbc'"
								[startValue]="4"
								[endValue]="6">
							</dxi-range>
						</dxo-range-container>
					</dx-circular-gauge>
				</div>
				<div class="indicator__item">
					<dx-sparkline
						class="sparkline"
						[dataSource]="indicator.calculations"
						argumentField="year"
						valueField="measure"
						type="line"
						lineColor="#0076a9"
						size="30"
						[showMinMax]="false"
						[showFirstLast]="false"
						[tooltip]="{enabled: false}"
					>
					</dx-sparkline>
				</div>
			</div>
		</li>
	</ul>
</div>
<div *ngIf="indicatorGroups.length === 0" class="u-text-align-center">
	No indicators available
</div>
