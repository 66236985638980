<div class="sidebar__buttons" *ngIf="currentTab">
	<button
		class="sidebar__button"
		[class.sidebar__button--active]="currentTab.state === 'wizard' || currentTab.state === 'lgfrs'"
		(click)="setTabState(tabState.wizard)"
	>
		<fa-icon class="sidebar__icon" [icon]="icons.globe"></fa-icon>
		Explore Data
	</button>

	<button
		class="sidebar__button"
		[class.sidebar__button--active]="currentTab.state === 'government-search-map'"
		(click)="setTabState(tabState.searchMap)"
	>
		<fa-icon class="sidebar__icon" [icon]="icons.map"></fa-icon>
		Find Government on Map
	</button>

	<button
		class="sidebar__button"
		[class.sidebar__button--active]="currentTab.state === 'government-search-name'"
		(click)="setTabState(tabState.searchName)"
	>
		<fa-icon class="sidebar__icon" [icon]="icons.search"></fa-icon>
		Find Government by Name
	</button>

	<app-sidebar-entities
		label="Your Entities"
		[icon]="icons.home">
	</app-sidebar-entities>

	<!-- FOR LATER:
		<button class="sidebar__button">
			<fa-icon class="sidebar__icon" [icon]="icons.folder"></fa-icon>
			Annual Filing
		</button>
	-->
</div>

<app-sidebar-drawer></app-sidebar-drawer>
