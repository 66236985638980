import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Tab} from 'app/shared/models/tab';
import {LoggerService} from '../../shared/services/logger.service';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {DataSourceService} from '../../shared/services/data-source.service';
import {DatePipe} from '../../../../node_modules/@angular/common';
import {UtilityService} from '../../shared/services/utility.service';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {DxDataGridComponent} from 'devextreme-angular';
import {UserInterfaceService} from 'app/shared/services/user-interface.service';
import {TabState} from 'app/shared/models/tab-state';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {TabService} from '../../shared/services/tab.service';
import {FilingStatusService} from '../../modules/services/filing-status-service/filing-status.service';
import {
	FilingCondition,
	filingConditionLookup,
	FilingStatus,
	FilingYearCondition
} from '../../modules/api/fit-api/models/filing-status';
import {forkJoin} from 'rxjs';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector: 'app-filer-report',
	templateUrl: './filer-report.component.html',
	styleUrls: ['./filer-report.component.scss']
})
export class FilerReportComponent implements OnChanges {
	@Input() tab: Tab;
	@Input() snapshot: any;
	@ViewChild(DxDataGridComponent) dxDataGrid: DxDataGridComponent;

	isVisible = false;
	title = 'Filer Report';
	icons = {
		downArrow: faChevronDown
	};
	dataSource: any;

	useLiveUnauthenticatedData = false;

	// static filters
	governmentStatusFilterValues = [
		{text: 'Active', value: 'Active'},
		{text: 'Inactive', value: 'Inactive'}
	];
	booleanFilterValues = [
		{text: 'Yes', value: true},
		{text: 'No', value: false}
	];
	filingBasisFilterValues = [
		{text: 'Cash', value: 'Cash'},
		{text: 'GAAP', value: 'GAAP'}
	];

	// dynamically populated from snapshot data
	yearFilterValues = [];
	governmentFilterValues = [];
	govTypeFilterValues = [];
	locationFilterValues = [];
	filingConditionLookupDataSource = filingConditionLookup;

	constructor(
		private logger: LoggerService,
		private snapshotService: SnapshotService,
		private dataSourceService: DataSourceService,
		private fitApi: FitApiService,
		private date: DatePipe,
		private utilities: UtilityService,
		private uiService: UserInterfaceService,
		public filingStatusService: FilingStatusService
	) {
		this.logger.info(this);

		// This method of displaying the filer report is the legacy way and only works for tab-based context
		this.uiService.filerReport$.subscribe(x => {
			this.isVisible = x;
			this.setInitialFiltersAndSortingFromTabs();
		});

		// This is the new way to control the filer report (make visible, set filters, etc.)
		this.filingStatusService.filingReportIsVisible$.subscribe(x => {
			this.isVisible = x;
		});
		this.filingStatusService.filingReportIsLiveUnauthenticated$.subscribe(x => {
			// Sets up the filer report to use FIT's live, authenticated endpoints
			this.useLiveUnauthenticatedData = x;
			this.updateDataSource();
		});
		this.filingStatusService.filingReportFilter$.subscribe(filter => {
			this.setInitialFiltersAndSorting(filter);
		});
	}

	handleClickToShowFromTab() {
		// If we show the filer report from the tab footer,
		// allow the snapshot selection from the tab to control the filer report data source
		if (this.useLiveUnauthenticatedData) {
			this.useLiveUnauthenticatedData = false;
			this.updateDataSource();
		}

		this.uiService.showFilerReport();
	}

	ngOnChanges(changes) {
		if (changes.snapshot?.currentValue) {
			this.updateDataSource();
		}
	}

	private updateDataSource() {
		if (this.useLiveUnauthenticatedData) {
			this.dataSource = this.dataSourceService.getLiveUnauthenticatedStore('FilingStatuses');
			this.updateTitle();
			this.setHeaderFilterDataSources();
		} else if (this.snapshot?.id) {
			this.dataSource = this.dataSourceService.getStore(this.snapshot.id, 'FilingStatuses');
			this.updateTitle();
			this.setHeaderFilterDataSources();
		}
	}

	// updates the popup title
	updateTitle(): void {
		const date = this.useLiveUnauthenticatedData ? new Date() : this.snapshot?.dateCreated || new Date();
		const transformedDate = this.date.transform(date, 'longDate');
		this.title = `Local Government Annual Filing Status (as of ${transformedDate})`;
	}

	// Set dataSources for headerFilters to data extracted from snapshot
	// static filter values are set directly in the columns list
	setHeaderFilterDataSources(): void {
		if (this.useLiveUnauthenticatedData) {
			this.fitApi.getAnnualFilingDueDates.subscribe(dueDates => {
				const years = dueDates.map(item => item.year);
				const distinctYears = years.filter((year, index) => years.indexOf(year) === index);
				this.yearFilterValues = distinctYears.map(year => ({text: year, value: year}));
			});
			this.fitApi.getFITGovernments().subscribe(governments => {
				this.governmentFilterValues = governments.map(govt => ({
					text: govt.EntityName + (govt.EntityDBA ? `(${govt.EntityDBA})` : ''),
					value: govt.MCAG
				}));
			});
			this.fitApi.getGovernmentTypes.subscribe( govTypes => {
				this.govTypeFilterValues = govTypes.map(govType => ({text: govType.description, value: govType.code}));
			});
			this.fitApi.getCounties.subscribe( counties => {
				this.locationFilterValues = counties.map(county => ({text: county.countyName, value: county.countyCode}));
			});
		}
		else {
			this.yearFilterValues = this.getYearFilterValuesFromSnapshot();
			this.governmentFilterValues = this.getGovernmentFilterValuesFromSnapshot();
			this.govTypeFilterValues = this.getGovTypeFilterValuesFromSnapshot();
			this.locationFilterValues = this.getLocationsFilterValuesFromSnapshot();
		}
	}

	getYearFilterValuesFromSnapshot = () =>
		this.snapshot.detail.includedYears.map((year: number) => ({text: year, value: year}));

	getGovernmentFilterValuesFromSnapshot = () => {
		return this.snapshot.detail.governments.map((government: any) => {
			return {text: government.entityNameWithDba, value: government.mcag};
		});
	};

	getGovTypeFilterValuesFromSnapshot = () => {
		return this.snapshot.detail.governmentTypes.map((govType: any) => {
			return {text: govType.description, value: govType.code};
		});
	};

	getLocationsFilterValuesFromSnapshot = () => {
		return this.snapshot.detail.counties.map((county: any) => {
			return {text: county.countyName, value: county.countyCode};
		});
	};

	// Sets the filtering and sorting based on track
	// should only be called from dxPopup (resets every time opened)
	setInitialFiltersAndSortingFromTabs = () => {
		if (!this.dxDataGrid) {
			return;
		}
		this.dxDataGrid.instance.clearFilter('header');
		this.dxDataGrid.instance.clearSorting();

		const isSingleGovernment = this.tab.state === TabState.profile
			|| this.tab.state === TabState.indicator
			|| (this.tab.state === TabState.lgfrs && this.tab.track.id === 'a');
		const isComparison = this.tab.state === TabState.lgfrs
			&& ['b1', 'b2'].includes(this.tab.track.id);
		const isSummary = this.tab.state === TabState.lgfrs
			&& ['c', 'd'].includes(this.tab.track.id);
		const isGovType = this.tab.state === TabState.typeProfile;

		if (isSingleGovernment) {
			this.setSingleColumnOptions();
		} else if (isComparison) {
			this.setComparisonColumnOptions();
		} else if (isSummary) {
			this.setSummaryColumnOptions();
		} else if (isGovType) {
			this.setGovTypeColumnOptions();
		}

		// noop for hype; filters have already been cleared
	};

	// set initial filters based on filter object input
	setInitialFiltersAndSorting = (filter: FilingStatus) => {
		if (!this.dxDataGrid) {
			return;
		}
		this.dxDataGrid.instance.clearFilter('header');
		this.dxDataGrid.instance.clearSorting();

		// iterate filter object and set dxDataGrid based on values
		Object.entries(filter).forEach(
			([key, value]) => {
				if (value) {
					this.dxDataGrid.instance.columnOption(key, {
						filterValues: [value]
					});
				}
			}
		);

	};

	setSingleColumnOptions(): void {
		this.dxDataGrid.instance.columnOption('government', 'filterValues', [this.tab.governments[0].mcag]);
		this.dxDataGrid.instance.columnOption('year', {sortIndex: 0, sortOrder: 'desc'});
	}

	setComparisonColumnOptions(): void {
		this.dxDataGrid.instance.columnOption('government', {
			filterValues: this.tab.governments.map(v => v.mcag),
			sortIndex: 0,
			sortOrder: 'asc'
		});
		this.dxDataGrid.instance.columnOption('year', {
			filterValues: this.utilities.yearsToRange(this.tab.years),
			sortIndex: 1,
			sortOrder: 'desc'
		});
	}

	setSummaryColumnOptions(): void {
		this.dxDataGrid.instance.columnOption('governmentType', {
			filterValues: this.tab.govTypes.map(v => v.code),
			sortIndex: 0,
			sortOrder: 'asc'
		});
		this.dxDataGrid.instance.columnOption('locations', {
			filterValues: this.tab.locations.map(v => v.countyCode),
			sortIndex: 1,
			sortOrder: 'asc'
		});
		this.dxDataGrid.instance.columnOption('year', {
			filterValues: this.utilities.yearsToRange(this.tab.years),
			sortIndex: 2,
			sortOrder: 'desc'
		});
	}

	setGovTypeColumnOptions(): void {
		this.fitApi.getAnnualFilingSnapshot(this.tab.snapshotId).subscribe(snapshot => {
			this.dxDataGrid.instance.columnOption('governmentType', {
				filterValues: this.tab.govTypes
			});
			this.dxDataGrid.instance.columnOption('year', {
				filterValues: [snapshot.barsYearUsed]
			});
			this.dxDataGrid.instance.columnOption('government', {
				sortIndex: 1,
				sortOrder: 'asc'
			});
		});
	}

	// allows intercepting headerFilter changes before applying to dataSource
	getLocationsFilterExpression = (filterValue, selectedFilterOperation, target) => {
		let filter = null;
		if (selectedFilterOperation === '=') {
			// support querying against collection of values (countyCodes)
			filter = [`countyCodes/any(c: c in (${filterValue}))`, '=', true];
		}
		return filter;
	};

	// #region display values for grid
	// some columns use unique ids, but display friendly names to user
	getGovernmentName = (rowData: any): any[] => {
		const governments = this.snapshot.detail.governments;
		const lookup = governments.find(x => rowData.mcag === x.mcag);
		return lookup ? lookup.entityNameWithDba : rowData.mcag;
	};

	getLocationNames = (rowData: any): string => {
		const counties = this.snapshot.detail.counties;
		const names: Array<string> = [];
		rowData.countyCodes.forEach(code => {
			const lookup = counties.find(x => x.countyCode === code);
			const nameOrCode = lookup ? lookup.countyName : code;
			names.push(nameOrCode);
		});
		return names.join(', ');
	};

	getGovTypeDesc = (rowData: any): any[] => {
		const govTypes = this.snapshot.detail.governmentTypes;
		const lookup = govTypes.find(x => rowData.govTypeCode === x.code);
		return lookup ? lookup.description : rowData.code;
	};

	getFilingYearConditionText = (rowData: FilingStatus): any[] => {
		const lookup = this.filingStatusService.filingYearConditions.find(x => x.value === rowData.filingYearCondition);
		return lookup ? lookup.text : rowData.filingYearCondition;
	}

	// #endregion
}
