import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LoggerService} from '../../../shared/services/logger.service';
import {DisabledGovernmentType} from '../../../shared/components/government-types/disabled-government-type';
import {GovernmentTypesService} from '../../../shared/components/government-types/government-types.service';
import {
	INDICATOR_REPORT_TYPES,
	IndicatorReportType
} from '../../../modules/api/fit-api/models/indicators/indicator-report-type';
import {FilingBasis} from '../../../modules/api/fit-api/models/snapshots/filing-basis';

export class TrackESubmit {
	filingBasis: FilingBasis;
	indicatorReportType: IndicatorReportType;
	govTypeCodes?: Array<string>;
}

@Component({
	selector: 'app-track-e',
	templateUrl: './track-e.component.html',
	styleUrls: ['./track-e.component.scss']
})
export class TrackEComponent implements OnInit {

	@Output() submit = new EventEmitter<TrackESubmit>();
	@Output() back = new EventEmitter<any>();

	meetsGovTypeRequirements = false;
	meetsSubmitRequirements = false;
	filingBasis: FilingBasis;
	indicatorReportType: IndicatorReportType;
	indicatorReportTypes = INDICATOR_REPORT_TYPES;
	govTypes: any;
	disabledGovTypes: Array<DisabledGovernmentType> = [];
	isLoading = false;
	snapshot: any;
	submitLabel = this.getSubmitLabel();

	constructor(
		private logger: LoggerService,
		private service: GovernmentTypesService
	) {
	}

	ngOnInit() { }

	updateDisabledGovernmentTypes = (): void => {
		this.isLoading = true;
		this.disabledGovTypes = [];
		// Do not pass snapshotId here to get the latest (Wizard does not use snapshot selector)
		this.service.getDisabledGovTypesForIndicators(this.filingBasis, this.indicatorReportType).subscribe(
			result => {
				this.disabledGovTypes = result;
			},
			error => {
				this.logger.log(`${this.constructor.name}.updateDisabledGovernmentTypes could not retrieve disabled governments`, error);
			},
			() => {
				this.isLoading = false;
			}
		);
	};

	getSubmitLabel() {
		const filingBasisLabel = this.filingBasis?.name || '';
		const indicatorReportLabel = this.indicatorReportType?.name || '';
		return `View ${filingBasisLabel} ${indicatorReportLabel} Indicators For These Government Types`;
	}

	filingBasisChanged(event: FilingBasis) {
		this.filingBasis = event;
		this.govTypes = [];
		this.checkRequirements();
		this.submitLabel = this.getSubmitLabel();
		if (this.meetsGovTypeRequirements) {
			this.updateDisabledGovernmentTypes();
		}
	}

	indicatorReportTypeChanged(event: IndicatorReportType) {
		this.indicatorReportType = event;
		this.govTypes = [];
		this.checkRequirements();
		this.submitLabel = this.getSubmitLabel();
		if (this.meetsGovTypeRequirements) {
			this.updateDisabledGovernmentTypes();
		}
	}

	govTypesChanged(event: Array<string>) {
		this.govTypes = event;
		this.checkRequirements();
	}

	checkRequirements = (): void => {
		this.meetsGovTypeRequirements = this.filingBasis != null && this.indicatorReportType != null;
		this.meetsSubmitRequirements = this.meetsGovTypeRequirements && this.govTypes?.length > 0;
	};

	handleSubmit() {
		const result: TrackESubmit = {
			filingBasis: this.filingBasis,
			indicatorReportType: this.indicatorReportType,
			govTypeCodes: this.govTypes
		};
		this.submit.emit(result);
	}

	handleBack(event) {
		this.back.emit(event);
	}

}
