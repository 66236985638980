import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {switchMap} from 'rxjs/operators';
import {LoggerService} from '../../../shared/services/logger.service';
import {ChartDataRow} from '../../services/filing-status-service/models/chart-data-row';
import {ReusableGovernmentTypeInputs} from '../../reusable/models/reusable-government-type-inputs';
import {ExternalCommonServiceApiService} from '../../api/external-common-service-api/external-common-service-api.service';
import {GovTypes} from '../../api/external-common-service-api/models/gov-types';
import {of} from 'rxjs';
import {pieChart} from '../../../../../sao-patterns/src/tokens/government-colors.js';

@Component({
	selector:    'wasao-filing-status-summary',
	templateUrl: './filing-status-summary.component.html',
	styleUrls:   ['./filing-status-summary.component.scss']
})
export class FilingStatusSummaryComponent extends ReusableGovernmentTypeInputs implements OnChanges {

	/**
	 * The action to perform when "Filing Status Report" is clicked.
	 */
	@Input() filerReportClickAction: () => void;
	@Input() sizeGroup: string;

	dataSource: Array<ChartDataRow>;
	liveGovTypeInfo: GovTypes;
	singleYearToDisplay: number;
	disabledForGovType = false;
	pieChartColors: Array<string> = pieChart;

	constructor(
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private logger: LoggerService,
		private common: ExternalCommonServiceApiService
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.govTypeCode?.currentValue || changes.snapshotId?.currentValue) {
			this.updateData();
		}
	}

	updateData = () => {
		// Never process live results
		if (this.snapshotId === 'live') {
			return;
		}
		this.isLoading = true;
		this.common.getGovTypeDetails().subscribe(result => {
			this.liveGovTypeInfo = result.filter(x => x.GovTypeCode === this.govTypeCode)[0];
		});
		// this.fitApi.getFilingStatusesForGovType(this.govTypeCode, this.snapshotId).pipe(
		// 	map(filingStatuses => this.filingStatus.getChart(filingStatuses))
		// ).subscribe(chartData => {
		// 	this.dataSource = chartData;
		// 	this.isLoading = false;
		// });
		this.fitApi.getAnnualFilingSnapshot(this.snapshotId).pipe(
			switchMap(snapshot => {
				const canHaveFinancials = snapshot.detail.governmentTypes.find(x => x.code === this.govTypeCode)?.canHaveFinancials;
				return canHaveFinancials
					? this.fitApi.getFilingStatusesForGovType(this.govTypeCode, this.snapshotId)
					: of(null);
			})
		).subscribe(filingStatuses => {

			if (filingStatuses === null || filingStatuses.length === 0) {
				this.hasData.emit(false);
				this.isLoading          = false;
				this.disabledForGovType = true;
				return;
			}
			this.hasData.emit(true);
			this.disabledForGovType  = false;
			this.dataSource          = this.filingStatus.getChart(filingStatuses);
			this.singleYearToDisplay = filingStatuses[0]?.year;
			this.isLoading           = false;
			this.logger.log(`FilingStatusSummary: Finished processing results for ${this.snapshotId}`);
		});
	};

	customizeLabel = (pointInfo) => `${pointInfo.argumentText}\n(${pointInfo.valueText})`;

	handleFilerReportClick = () => {
		// do nothing if action undefined
		if (typeof this.filerReportClickAction !== 'function') {
			return undefined;
		}
		this.filerReportClickAction();
	};

}
