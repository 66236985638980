import { Injectable } from '@angular/core';
import { Field } from 'app/shared/models/field';
import { Tab } from 'app/shared/models/tab';
import { UserService } from './user.service';
import { FieldService } from './field.service';
import { StringListPipe } from '../pipes/string-list.pipe';
import { SnapshotService } from './snapshot.service';
import {UtilityService} from './utility.service';

@Injectable({
	providedIn: 'root'
})
export class ReportSummaryService {

	constructor(
		private user: UserService,
		private field: FieldService,
		private stringList: StringListPipe,
		private snapshotService: SnapshotService,
		private util: UtilityService
	) {
		this.update = this.update.bind(this);
	}

	update(tab: Tab, snapshot, pivotGridData) {

		// Never process a summary for New Tab button
		if (tab.canCreate) {
			return;
		}

		let subtitle = '';
		const result = {
			title: '',
			heading: '',
			info: '',
			filters: '',
			subtitle: ''
		};

		switch (tab.track.id) {
			case 'a':
				result.heading += this.primaryGovernments(tab);
				subtitle       += this.reportName(tab) + ': ' + this.years(tab) + this.projectionTitle(tab);
				break;
			case 'b1':
				result.heading += this.primaryGovernments(tab);
				subtitle       += this.reportName(tab) + ' Comparison: ' + this.years(tab);
				result.info    += 'A comparison with ' + this.secondaryGovernments(tab) + '. ';
				break;
			case 'b2':
				result.heading    += this.reportName(tab) + ' Comparison';
				subtitle += this.years(tab);
				result.info       += 'A comparison of ' + this.secondaryGovernments(tab) + '. ';
				break;
			case 'c':
				result.heading    += 'Total ' + this.reportName(tab);
				subtitle += this.years(tab);
				result.info       += 'Totals for all ' + this.getGovTypeNames(tab) + ' governments'
								+ this.getLocationNames(tab) + '. ';
				break;
			case 'd':
				result.heading += tab.category.displayName + ' by Government';
				subtitle       += this.years(tab);
				result.info    += 'Ranking for all ' + this.getGovTypeNames(tab) + ' governments'
							+ this.getLocationNames(tab) + '. ';
				break;
			case 'e':
				result.heading += `${tab.filingBasis.name} ${tab.report.name} by Government`;
				subtitle += this.years(tab);
				result.info += `${tab.report.name} for ${tab.filingBasis.name} ${this.getGovTypeNames(tab)} governments.`;
		}

		result.filters += this.getFilters(tab, snapshot, pivotGridData);
		result.info += result.filters;
		result.info += this.projectionInfo(tab);
		// requires: snapshotDetail, pivotGridData to get filters
		result.info += result.info.length > 0 ? ' ' : '';
		result.info += this.getDebtCapitalActivity(tab);

		result.subtitle = subtitle;

		// Update tab title by reference
		result.title = tab.title = result.heading + ', ' + subtitle;

		return result;
	}

	primaryGovernments(tab: Tab) {
		if (!Array.isArray(tab.governments)) {
			return 'No Governments';
		}

		if (tab.governments.length === 1) {
			return tab.governments[0].entityNameWithDba;
		}

		if (tab.governments.length > 1) {
			const hasPeers = tab.governments.findIndex((v) => v.prime === true) > -1;

			if (hasPeers) {
				return tab.governments
					.filter((v) => v.prime)[0]
					.entityNameWithDba;
			} else {
				return tab.governments
					.map((v) => v.entityNameWithDba)
					.join(', ');
			}
		}
	}

	secondaryGovernments(tab: Tab) {
		if (!Array.isArray(tab.governments)) {
			return 'No Governments';
		}

		return tab.governments
			.filter((v) => !v.prime)
			.map( (v) => v.entityNameWithDba)
			.join(', ');
	}

	reportName(tab: Tab) {
		return tab.report.name;
	}

	years(tab: Tab) {
		if (!Array.isArray(tab.years)) {
			return '';
		}

		return this.util.yearsToRangeText(tab.years);
	}

	projectionTitle = (tab: Tab) => {
		const result = '';
		const projectionId = tab.pivotGridSettings && tab.pivotGridSettings.projectionId;
		if (projectionId) {
			return ' (with future projections)';
		}
		return result;
	}

	projectionInfo = (tab: Tab) => {
		const result = '';
		const projectionName = tab.pivotGridSettings && tab.pivotGridSettings.projectionName;
		if (projectionName) {
			return ` With "${projectionName}" projection applied. `;
		}
		return result;
	}

	getFilters = (tab, snapshot, pivotGridData) => {

		if (!pivotGridData || typeof pivotGridData.fields !== 'function') {
			return '';
		}

		let result = '';
		const accountsField = this.field.getActiveAlternateField(pivotGridData, 'financialSummaryHierarchy', 'functionalAccountsGroup');
		const fundsField = pivotGridData.field('fundGroupFilter');
		const expObjsField = pivotGridData.field('expenditureObjectFilter');

		const accounts = this.getDisplaybleFilters(accountsField).map(v => {
			// we only need the last value in the array
			const obj = this.getObjectFromSnapshot(snapshot, 'accountDescriptors', v[v.length - 1]);
			if (obj.name) {
				return tab.pivotGridSettings.showAccountCodes
					? `${obj.name} (${obj.categoryDisplay})`
					: obj.name;
			} else {
				return '<unknown>';
			}
		});

		// const dataRows = pivotGridData.getData();
		const funds = this.getDisplaybleFilters(fundsField).map((v) => {
			// fund filter is an array in order: category, type, fund
			const value = v.slice(-1).pop();
			if (v.length === 1) {
				return this.snapshotService.findObject(snapshot.id, 'fundCategories', value).name;
			}
			else if (v.length === 2) {
				return this.snapshotService.findObject(snapshot.id, 'fundTypes', value).name;
			}
			else if (v.length === 3) {
				return value;
			}
		});

		const expObjs = this.getDisplaybleFilters(expObjsField).map(v => {
			const obj = this.getObjectFromSnapshot(snapshot, 'expenditureObjects', v);
			return obj.name || '<unknown>';
		});

		result += accounts && accounts.length
			? ' Category [' + this.stringList.transform(accounts, null, ';') + ']'
			: '';
		result += accounts && accounts.length && funds && funds.length ? ';' : '';
		result += funds && funds.length
			? ' Fund Category/Type/Number [' + this.stringList.transform(funds, null, ';') + ']'
			: '';
		result += funds && funds.length && expObjs && expObjs.length ? ';' : '';
		result += expObjs && expObjs.length
			? ' Expenditure Object [' + this.stringList.transform(expObjs, null, ';') + ']'
			: '';

		if (result.length > 0) {
			result = 'Includes only: ' + result + '.';
		}

		// $log.debug('ReportSummaryService.getFilters', result);

		return result;
	}

	getDebtCapitalActivity(tab: Tab) {
		return tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
			? 'Debt service and capital outlay has been included with the associated expenditure category.'
			: '';
	}

	getLocationNames(tab: Tab) {
		const isValid = Array.isArray(tab.locations) && tab.locations.length;
		if (!isValid) {
			return '';
		}

		const nounWithCardinality = tab.locations.length > 1
			? 'counties'
			: 'county';

		const result = tab.locations.map(function(v) {
			return v.countyName;
		}).join(', ');

		return ' in ' + result + ' ' + nounWithCardinality;
	}

	getGovTypeNames(tab: Tab) {
		const isValid = Array.isArray(tab.govTypes) && tab.govTypes.length;
		if (!isValid) {
			return '';
		}

		return tab.govTypes.map((v) => v.description).join(', ');
	}

	getDisplaybleFilters(field) {
		let result = [];

		if (!field || !Array.isArray(field.filterValues)) {
			return result;
		}

		result = field.filterValues.filter((v) => Array.isArray(v) ? v[0] !== null : v !== null);

		return result;
	}

	getObjectFromSnapshot = (snapshot, node, value) => {
		const collection = snapshot.detail[node];
		if (!collection || !Array.isArray(collection)) {
			throw new Error(`ReportSummaryService.getObjectFromSnapshot: No collection named ${node} was found in snapshot ${snapshot.id}`);
		}

		return collection.find(function(obj) {
			return obj.id === value;
		});
	};
}
