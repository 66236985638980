import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {Tab} from '../../../shared/models/tab';
import {GovernmentInfoService} from 'app/shared/services/government-info.service';
import {ExternalCommonServiceApiService} from '../../../modules/api/external-common-service-api/external-common-service-api.service';
import {FitActionsService} from 'app/shared/services/fit-actions.service';
import {FitApiService} from '../../../modules/api/fit-api/fit-api.service';
import {Entities} from '../../../modules/api/external-common-service-api/models/entities';
import {LocalGovernment} from '../../../modules/api/fit-api/models/local-government';
import {ReportId} from '../../../shared/models/report-id';
import {FundCategoryId} from '../../../modules/services/fund-service/models/fund-category';
import {RankingParameters} from '../../../modules/info/rankings/models/ranking-parameters';
import {TabService} from '../../../shared/services/tab.service';
import {TabState} from '../../../shared/models/tab-state';
import {UserService} from '../../../shared/services/user.service';
import {FilingStatusService} from '../../../modules/services/filing-status-service/filing-status.service';
import {Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {GovernmentType} from '../../../modules/api/fit-api/models/government-type';
import {SnapshotId} from '../../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector:    'app-government-profile',
	templateUrl: './government-profile.component.html',
	styleUrls:   ['./government-profile.component.scss']
})
export class GovernmentProfileComponent implements OnChanges, OnDestroy {
	@ViewChild('financialHealthIndicators', {static: true}) financialHealthIndicators: ElementRef;

	@Input() tab: Tab;
	@Output() scrollParent = new EventEmitter<{ x?: number, y?: number }>();

	private destroy = new Subject<void>();

	snapshotId: SnapshotId;

	canShowFinancials = false;
	canShowIndicators = false;
	govInfoLive: Entities;
	government: LocalGovernment;
	displayYear: number;
	// todo refactor out of this component
	indicatorGroups: any;

	hasDataChildren = {
		governmentalServices: true,
		enterpriseServices:   true
	};

	showBeta = false;
	governmentTypes: Array<GovernmentType>;
	currentGovernmentType: GovernmentType;
	userHasGlobalAccess = false;
	userHasSchoolAccess = false;
	userHasMCAGAccess = false;
	govTypeIsSchool = false;

	/**
	 * Store the click actions needed by children. Only regenerate them when the tab changes.
	 */
	clickActions = {
		filerReport:        undefined as () => void,
		govProfile:     	undefined as (mcag: string, snapshotId?: SnapshotId) => void,
		govTypeProfile:     undefined as (govTypeCode: string, govTypeName: string) => void,
		govReport:          undefined as (reportId: ReportId, displayYear: number, fieldTransformations: Array<any>) => void,
		rankingReport:      undefined as (parameters: RankingParameters) => void,
	};

	constructor(
		private commonData: ExternalCommonServiceApiService,
		private govInfoService: GovernmentInfoService,
		public fitActions: FitActionsService,
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private tabService: TabService,
		private userService: UserService
	) {
		this.userService.user.pipe(takeUntil(this.destroy)).subscribe(user => {
			this.showBeta = user.hasAnyFitAccessRole;
			this.userHasGlobalAccess = user.hasGlobalAccess();
			this.userHasSchoolAccess = user.hasAccessToAnySchool();
			this.evaluateCanShowFinancials();
		});
		this.fitApi.getGovernmentTypes.subscribe(x => this.governmentTypes = x);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tab?.currentValue) {
			this.generateClickActions();
			this.government = this.tab.getPrimaryGovernment();

			this.commonData.getEntityDetails(this.government.mcag).subscribe(government => {
				this.govInfoLive = government;
			});

			this.fitApi.getGovernmentTypes.subscribe(governmentTypes => {
				this.currentGovernmentType = governmentTypes.find(x => x.code === this.government.govTypeCode);
				this.govTypeIsSchool = this.currentGovernmentType.financialsDatasetSource === 'OSPI';
				this.evaluateCanShowFinancials();
				this.evaluateCanShowIndicators(this.government.mcag);

				this.tab.snapshotIdSubject.pipe(takeUntil(this.destroy)).subscribe(snapshotId => {
					this.snapshotId = snapshotId;
					// get the display year based on data source
					const getYearForDisplay = this.currentGovernmentType.financialsDatasetSource === 'OSPI'
						? this.fitApi.getOSPIDisplayYear()
						: this.filingStatus.getFilingYearForDisplay(this.government.mcag, snapshotId);
					getYearForDisplay.pipe(takeUntil(this.destroy)).subscribe(displayYear => {
						this.displayYear = displayYear;
						// todo refactor into IndicatorGroups
						this.tabService.getInitialYearsRangeForTabState(TabState.indicator, this.currentGovernmentType.financialsDatasetSource, snapshotId, this.displayYear).subscribe(years => {
							if (this.govTypeIsSchool) {
								this.govInfoService.getSchoolsIndicatorGroups(this.government.mcag, years).subscribe(x => {
									this.indicatorGroups = x;
								});
							} else {
								this.govInfoService.getAnnualFilingIndicatorGroups(snapshotId, this.government.mcag, years).subscribe(x => {
									this.indicatorGroups = x;
								});
							}
							this.scrollIfGroupIndicatorsOpen();
						});
					});
				});
			});
		}
	}

	/**
	 * Evaluate and set canShowFinancials flag based on defined criteria
	 */
	evaluateCanShowFinancials(): void {
		// PUBLIC ACCESS
		// this.canShowFinancials = this.currentGovernmentType?.financialsDatasetSource !== null;


		// NON-PUBLIC ACCESS
		// always show financial sections if the data set is Annual Filing based, OR
		this.canShowFinancials = this.currentGovernmentType?.financialsDatasetSource === 'SAOAnnualFiling'
			// dataset is OSPI *only* if the user has global access or schools access
			|| (this.currentGovernmentType?.financialsDatasetSource === 'OSPI' && (this.userHasGlobalAccess || this.userHasSchoolAccess ));
	}

	/**
	 * Evaluate and set canShowIndicators flag based on defined criteria
	 */
	evaluateCanShowIndicators(mcag: string): void {
		this.userService.user.pipe(takeUntil(this.destroy)).subscribe(user => {
			if (this.currentGovernmentType.financialsDatasetSource === 'SAOAnnualFiling') {
				this.canShowIndicators = this.currentGovernmentType.canHaveIndicators;
			} else if (this.currentGovernmentType.financialsDatasetSource === 'OSPI') {
				this.canShowIndicators = this.currentGovernmentType.canHaveIndicators && (user.hasAccessToMcag(mcag));
			} else {
				this.canShowIndicators = false;
			}
		});
	}

	ngOnDestroy() {
		this.destroy.next();
		this.destroy.complete();
	}

	navigateToTearSheet(): void {
		window.open(`${window.location.origin}${window.location.pathname}government-tear-sheet/${this.government.mcag}/${this.tab.snapshotId}`);
	}

	/**
	 * Emit the scrollParent output if the user needs to be scrolled down to the group indicators
	 * This happens if the user comes back to a tab where the indicatorGroupState has been selected
	 */
	scrollIfGroupIndicatorsOpen(): boolean {
		// this variable simply makes the view not be so tight when the user's scrolled down; it adds in a little buffer
		const arbitraryPadding = 100;

		if (this.tab.navigatedFromIndicators) {
			this.scrollParent.emit({y: this.financialHealthIndicators.nativeElement.offsetTop - arbitraryPadding});
			this.tab.navigatedFromIndicators = false;
			return true;
		}

		return false;
	}

	private generateClickActions = () => {
		this.clickActions = {
			filerReport:        this.fitActions.launchFilerReport(this.tab),
			govProfile:			this.fitActions.navigateToGovProfile(this.tab),
			govTypeProfile:     this.fitActions.navigateToGovTypeProfile(this.tab),
			govReport:          this.fitActions.navigateToGovernmentReport(this.tab),
			rankingReport:      this.fitActions.navigateToRankingReport(this.tab, true),
		};
	}

}
