<dx-popup
	class="data-extract-popup"
	width="55%"
	height="auto"
	maxHeight="90%"
	[dragEnabled]="false"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	(onShown)="setScrollViewHeight($event)"
	(onHiding)="close()"
	[title]="extractSelectionTitle"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<dx-scroll-view showScrollbar="always">
				<div class="app-popup__inner" (resized)="onResized($event)">
					<button *ngIf="extractSelection" class="options__back" (click)="setExtractSelection = null">
						<fa-icon [icon]="icons.back" class="icon"></fa-icon>
						Back to all extract options
					</button>
					<div *ngIf="!extractSelection" class="options">
						<p class="data-extract__content">
							Various data extracts are available for download within FIT.
							Some extracts are specifically requested by organizations outside the State Auditor’s Office.
							No endorsement by the State Auditor’s Office of the data analysis or measures contained within
							any of these 3rd party extracts should be inferred.
						</p>

						<dx-scroll-view showScrollbar="always" direction="horizontal">
							<ul class="headers">
								<li *ngFor="let extract of service.types">
									{{ extract.title }}
								</li>
							</ul>
							<ul class="selections">
								<li *ngFor="let extract of service.types">
									<button
										type="button"
										class="choice"
										(click)="setExtractSelection = extract.id"
									>
										{{ extract.subtitle }}
									</button>
								</li>
							</ul>
						</dx-scroll-view>

						<div class="disclaimer">
							<div [innerHTML]=disclaimer.full>
							</div>
						</div>
					</div>
					<div *ngIf="extractSelection" class="extract">
						<p class="data-extract__content">
							{{ extractSelection.description }}
						</p>
						<div *ngIf="extractSelection.isYearsBased" class="years">
							<p class="data-extract__content" *ngIf="extractSelection.isSnapshotBased">
								{{ service.snapshot?.description }}
							</p>
							<ul *ngIf="extractSelectionYears?.length > 0; else noYears" class="downloads">
								<li *ngFor="let extractYear of extractSelectionYears"
									class="data-extract__content u-margin-top u-margin-right">
									<a class="c-button u-margin-bottom" [href]="extractYear.url">
										<span>
										<!--Added to handle OMWBE extract button display-->
											<span *ngIf="extractYear.displayYear">
												Download {{ extractYear.displayYear }} {{ extractSelection.linkSuffix || 'data' }}
											</span>
											<span *ngIf="!extractYear.displayYear">
												Download {{ extractYear.year }} {{ extractSelection.linkSuffix || 'data' }}
											</span>
											<fa-icon [icon]="icons.download"></fa-icon>
										</span>
										<span *ngIf="extractYear?.dateCreated" class="created-date">
											published {{ extractYear.dateCreated | date }}
										</span>
									</a>
								</li>
							</ul>
							<ng-template #noYears>
								<p *ngIf="extractSelection.id === 'fullDataExtracts'; else notFullExtracts" class="no-data-available">
									No extracts have been cached. Please contact center@sao.wa.gov.
								</p>
								<ng-template #notFullExtracts class="no-data-available">
									No data extracts are currently available.  Please check back again later.
								</ng-template>
							</ng-template>
						</div>
						<div *ngIf="!extractSelection.isYearsBased" class="non-years">
							<div *ngIf="extractSelection.id === 'financialHealthIndicators'" class="financial-health-indicators">
								<app-financial-health-indicators>
								</app-financial-health-indicators>
							</div>
							<div *ngIf="extractSelection.id === 'pensionLiabilityVariance'">
								<app-pension-liability-variance></app-pension-liability-variance>
							</div>
						</div>
						<wasao-loading [isLoading]="isLoading"></wasao-loading>
					</div>
				</div>
			</dx-scroll-view>
			<div #nonScrollableContent class="app-popup__footer">
				<dx-button
					text="Close"
					type="default"
					(onClick)="close()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
