import {Component, OnInit} from '@angular/core';
import {WizardService} from '../wizard.service';
import {TabService} from 'app/shared/services/tab.service';
import {TabState} from 'app/shared/models/tab-state';
import {HistoryService} from '../../../shared/services/history.service';

@Component({
	selector:    'app-track-chooser',
	templateUrl: './track-chooser.component.html',
	styleUrls:   ['./track-chooser.component.scss']
})
export class TrackChooserComponent implements OnInit {
	trackId: string;

	constructor(
		public wizardService: WizardService,
		private tabService: TabService,
		private historyService: HistoryService,
	) {
	}

	ngOnInit() {
		this.trackId = this.wizardService.trackId;
	}

	changeTrack(trackId: string) {
		if (trackId === 'a') {
			this.historyService.replaceStateTabs();
			this.tabService.setSelectedTabState(TabState.searchMap).then(() => this.historyService.pushStateTabs());
		}
		else {
			this.wizardService.changeTrack(trackId);
		}
	}

}
