import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {FinancialSummaryService} from '../../services/financial-summary-service/financial-summary.service';
import {LoggerService} from '../../../shared/services/logger.service';
import {forkJoin, of} from 'rxjs';
import {FundCategoryId} from '../../services/fund-service/models/fund-category';
import {DataPoint} from './data-point';
import {ReportId} from '../../../shared/models/report-id';
import {HistoryService} from '../../../shared/services/history.service';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {ReusableGovernmentInputs} from '../../reusable/models/reusable-government-inputs';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {FieldService} from '../../../shared/services/field.service';
import {barChart} from '../../../../../sao-patterns/src/tokens/government-colors.js';

@Component({
	selector: 'wasao-income-by-fund',
	templateUrl: './income-by-fund.component.html',
	styleUrls: ['./income-by-fund.component.scss']
})
export class IncomeByFundComponent extends ReusableGovernmentInputs implements OnInit, OnChanges {
	/**
	 * Optional. Defaults to all fundCategoryIds, if not specified.
	 */
	@Input() fundCategoryId: FundCategoryId;

	/**
	 * The year to display. Defaults to the barsYearUsed of the newest snapshot in the system.
	 */
	@Input() year: number;

	/**
	 * The action to perform when "see trend data" is clicked.
	 */
	@Input() underlyingDataClickAction: (reportId: ReportId, displayYear: number, fieldTransformations: Array<any>) => void;

	dataSource: Array<DataPoint>;
	singleYearToDisplay: number;
	totalRevenues: number;
	totalExpenditures: number;
	barChartColors: Array<string> = barChart;

	constructor(
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private financialSummary: FinancialSummaryService,
		private logger: LoggerService,
		private historyService: HistoryService,
		private fieldService: FieldService
	) {
		super();
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.mcag?.currentValue || changes?.snapshotId?.currentValue || changes?.year?.currentValue) {
			this.updateData();
		}
	}

	updateData = () => {
		this.logger.time(this);
		this.isLoading = true;
		const specificity: GovernmentSpecificity = { type: 'government', id: this.mcag };
		forkJoin({
			snapshot: this.fitApi.getAnnualFilingSnapshot(this.snapshotId),
			displayYear: this.year ? of(this.year) : this.filingStatus.getFilingYearForDisplay(this.mcag, this.snapshotId),
			totalsTrend: this.financialSummary.getTotalAmounts(specificity, this.fundCategoryId, this.snapshotId)
		}).subscribe(result => {
			this.singleYearToDisplay = result.displayYear ?? result.snapshot.barsYearUsed;
			const singleYearSummary = result.totalsTrend.find(x => x.year === this.singleYearToDisplay);

			// only process results if there is a record matching the year
			if (singleYearSummary) {
				this.dataSource = this.transformArraysToDataPoints({
					revenues: singleYearSummary.enterpriseRevenues,
					expenditures: singleYearSummary.enterpriseExpenditures
				}, 'fund', 'amount', 'name');
				this.totalRevenues = singleYearSummary.totalEnterpriseRevenues;
				this.totalExpenditures = singleYearSummary.totalEnterpriseExpenditures;
			} else {
				// null out dataSource in the event user switches to a snapshot without data
				this.dataSource = null;
			}

			this.hasData.emit(this.dataSource?.length > 0);
			this.isLoading = false;
			this.logger.timeEnd(this);
		});
	};

	/**
	 * TODO genericize the return type and move this to a service
	 * Given an object containing arrays, flattens each into a record matched up on joinKey.
	 * E.g. { revenues: [{ fund: '401', amount: 100 }], expenditures: [{ fund: '401', amount: 85 }] }
	 * ->
	 * { label: '401', revenues: 100, expenditures: 85 }
	 * @param objOfArrays - Every member of the object must be an array.
	 * @param joinKey - the common key across all arrays
	 * @param field - the field to extract
	 * @param label
	 */
	transformArraysToDataPoints<T, K extends keyof T, F extends keyof T, L extends keyof T>(
		objOfArrays: { [key: string]: Array<T> }, joinKey: K, field: F, label: L
	): Array<DataPoint> {
		const arrayNames = Object.keys(objOfArrays);
		let flattenedArray = new Array<T>();
		// flatten all arrays provided
		arrayNames.forEach(name => flattenedArray = flattenedArray.concat(objOfArrays[name]));
		// filter out any duplicates while preserving key and label for future sorting and display use
		const unique = flattenedArray.map(x => ({ key: x[joinKey], label: x[label] }))
			.filter((element, index, array) =>
				array.findIndex(x => x.key === element.key) === index
			);

		const result = new Array<DataPoint>();
		// iterate unique keys from arrays
		unique.forEach(uniqueKey => {
			const row = new DataPoint();
			row[joinKey.toString()] = uniqueKey.key;
			row[label.toString()] = uniqueKey.label;
			// create a new field for each array provided
			arrayNames.forEach(arrayName => {
				// get the appropriate source array (objOfArrays.keys)
				const sourceArr = objOfArrays[arrayName];
				// find the associated source record
				const record = sourceArr.find(x => x[joinKey] === uniqueKey.key);
				// set property on result to field
				row[arrayName] = record && record[field];
			});
			result.push(row);
		});
		result.sort((a, b) => a[joinKey.toString()] - b[joinKey.toString()]);
		return result;
	}

	handleUnderlyingDataClick = () => {
		// do nothing if action undefined
		if (typeof this.underlyingDataClickAction !== 'function') {
			return undefined;
		}
		this.underlyingDataClickAction(ReportId.summary,
			this.singleYearToDisplay,
			[this.fieldService.getFundGroupFilterTransformation(this.fundCategoryId)]
		);
	}

}
