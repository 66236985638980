import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import notify from 'devextreme/ui/notify';
import {faListUl, faAnchor, faStar, faAngleDoubleRight, faColumns, faTimes} from '@fortawesome/free-solid-svg-icons';
import { DndDropEvent } from 'ngx-drag-drop';
import { GroupingEditorService } from '../../shared/services/grouping-editor.service';
import { Tab } from 'app/shared/models/tab';
import { TransitionService } from '../../shared/services/transition.service';
import { LoggerService } from '../../shared/services/logger.service';
import { TabService } from '../../shared/services/tab.service';
import { UtilityService } from '../../shared/services/utility.service';
import { SortService } from 'app/shared/services/sort.service';
import { Field } from 'app/shared/models/field';

@Component({
	selector: 'app-report-layout-builder',
	templateUrl: './report-layout-builder.component.html',
	styleUrls: ['./report-layout-builder.component.scss']
})
export class ReportLayoutBuilderComponent implements OnInit, OnChanges {

	@Input() snapshot = null;
	@Input() tab: Tab;
	@Input() lastUpdate = null;
	@Input() store = null;
	@Output() submit = new EventEmitter<any>();
	isOpen = false;
	fields = {
		available: [] as Array<Field>,
		columns: [] as Array<Field>,
		rows: [] as Array<Field>
	};
	availablePresets = [];
	itemDragging = null;
	notAllowedTarget = null;
	icons = {
		listUL: faListUl,
		anchor: faAnchor,
		close: faTimes,
		star: faStar,
		angleDoubleRight: faAngleDoubleRight,
		columns: faColumns
	};

	constructor(
		private groupingEditor: GroupingEditorService,
		private transition: TransitionService,
		private log: LoggerService,
		private tabService: TabService,
		private utilities: UtilityService,
		private sort: SortService
	) { }

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.store && changes.store.currentValue) {
			this.resetFields();
		}

		// this.availablePresets = this.generatePresetButtons(this.tab.track, this.tab.report);
	}

	setIsOpen(value) {
		this.isOpen = value;
		if (this.isOpen) {
			this.availablePresets = this.generatePresetButtons(this.tab.track, this.tab.report);
		}
	}

	resetFields() {
		if (this.store) {
			this.fields = this.groupingEditor.generate(this.store.fields());
		}
	}

	generatePresetButtons(track, report) {
		const self = this;
		const presets = this.transition.getPresets(track, report);

		const presetObjects = [];
		presetObjects.push(self.getDefaultPreset(track.id, report.id));
		presets.forEach((p) => {
			const obj = {
				text: p.key,
				hint: self.getHintForPreset(p),
				onClick: function() { self.setFields(p.fields); }
			};

			presetObjects.push(obj);
		});

		return presetObjects;
	}

	getDefaultPreset(trackId, reportId) {
		const self = this;
		let report = this.transition.getReport(trackId, reportId, this.tab);
		report = this.transition.transitionFields(report, this.tab, trackId, reportId);

		// pick off column and row fields
		const fields = report.reduce(function(accumulator, field, index) {
			const area = field.area;
			if (['column', 'row'].includes(area)) {
				const fieldObj: any = {};
				fieldObj.name = field.name;
				fieldObj[area] = field.areaIndex;
				accumulator.push(fieldObj);
			}

			return accumulator;
		}, []);

		const obj = {
			text: 'Default',
			hint: 'Reset the layout to the default according to the report and user level.',
			onClick: function() { self.setFields(fields); }
		};

		return obj;
	}

	getHintForPreset = (preset) => {
		const self = this;
		let hint = '';
		const fields = this.fields.available.concat(this.fields.rows).concat(this.fields.columns);
		const rows = preset.fields.filter(function(v) { return v.row > -1; });
		const cols = preset.fields.filter(function(v) { return v.column > -1; });
		hint += 'Rows: ';
		rows.forEach(function(r, index, array) {
			let name = '';
			const relatedField = fields.find(function(f) {
				return f.name === r.name;
			});

			if (!relatedField || !relatedField.caption) {
				self.log.warn('GroupingEditor.getHintForPreset: No related field found for field', r);
			} else {
				name = relatedField.caption;
			}

			const trail = index < (array.length - 1) ? ', ' : '';
			hint += name + trail;
		});
		hint += '; Columns: ';
		cols.forEach(function(c, index, array) {
			let name = '';
			const relatedField = fields.find(function(f) {
				return f.name === c.name;
			});

			if (!relatedField || !relatedField.caption) {
				self.log.warn('GroupingEditor.getHintForPreset: No related field found for field', c);
			} else {
				name = relatedField.caption;
			}

			const trail = index < (array.length - 1) ? ', ' : '';
			hint += name + trail;
		});

		return hint;
	}

	// Used by presets to set fields en masse
	setFields(fields) {
		// this is a terrible hack and leaks implementation. The track services should be modified to
		// facilitate this behavior
		const activeSegment = this.tabService.getActiveSegment(this.tab);

		const rows = this.fields.rows.splice(0);
		const cols = this.fields.columns.splice(0);
		// sort available fields by caption
		this.fields.available = this.fields.available.concat(rows).concat(cols).sort(this.sort.byCaption);

		fields.forEach((f) => {
			let area = null;
			if (f.column > -1) { area = 'columns'; }
			if (f.row > -1) { area = 'rows'; }
			const availableIndex = this.fields.available.findIndex(function(v) {
				return v.name === f.name;
			});

			// skip fields not in available array or account fields that are not the active
			if (
				availableIndex < 0
				|| (f.category && activeSegment !== f.category)
			) {
				return;
			}


			const currField = this.fields.available.splice(availableIndex, 1);
			// make sure field area is sorted by areaIndex
			this.fields[area] = this.fields[area].concat(currField).sort(this.sort.byAreaIndex);
		});

		this.log.info('GroupingEditor.setFields', this.fields);
	}

	onDragStart(item) {
		this.itemDragging = item;
	}

	onDragEnd(item) {
		this.itemDragging = null;
		this.notAllowedTarget = null;
	}

	/**
	 * Handles the drop event.
	 * @param {DndDropEvent} event
	 * @param {string} area
	 * @returns {boolean|Object} true indicates allowed, but this handler will perform the insertion.
	 * Object indicates the object to pass to dnd-{action}
	 * falsy indicates the drop fails and dnd-{action} should not be called
	 */
	onDrop(event: DndDropEvent, area: string) {
		const field = event.data;
		const index = event.index;

		if (!this.groupingEditor.isAllowedHierarchy(this.fields[area], index, field)) {
			// TODO replace with UserInterfaceService.showToast()
			notify({
				closeOnClick: true,
				closeOnOutsideClick: true,
				displayTime: 10000,
				type: 'error',
				message: field.caption + ' can\'t be moved here because it violates the data\'s '
					+ 'natural hierarchy. Sorry about that!'
			});
			return false;
		}

		// Splice field into the dropped area
		this.fields[area].splice(index, 0, field);

		return field;
	}

	/**
	 * Invoked when an element is dragged over a target list.
	 * @param {Number} index The position in the list at which the element would be dropped.
	 * @param {function} callback If dnd-callback was set on the source element, this is a function reference to the callback.
	 * @param {string} area The area being hovered over.
	 * @returns {boolean|Object} Falsy cancels the drop. True allows drop target to be drawn, but leaves processing
	 * up to function. Returning the dragged element allows directive to handle the drop event (array splicing) automatically.
	 */
	onDragOver(area) {
		// debugger;
		// DevExtreme removes the popup from the directive and places it on the body.
		const placeholder = <HTMLElement> document.querySelector('.grouping-editor__popup .dndPlaceholder');
		const dragging = <HTMLElement> document.querySelector('.grouping-editor__popup .dndDragging');

		if (dragging && placeholder) {
			placeholder.innerText = dragging.innerText;
		}
		// placeholder.style.width = `${dragging.offsetWidth}px`;
		// placeholder.style.height = `${dragging.offsetHeight}px`;
		// this.log.info('GroupingEditor.onDragOver', index, area, dragging, placeholder);
		const allowed = this.groupingEditor.isAllowedInArea(area, this.itemDragging);

		// Dragging over disallowed area, give the user feedback
		if (!allowed) {
			this.notAllowedTarget = area;
			this.log.info('GroupingEditor.onDragOver: Not allowed in area ', this.notAllowedTarget);
		} else {
			this.notAllowedTarget = null;
		}

		return allowed;
	}

	onMoved(sourceIndex, sourceArea) {
		// Remove field from old/source area
		this.fields[sourceArea].splice(sourceIndex, 1);
	}

	fieldHintPartial(field) {
		const path = 'scripts/app/components/grouping-editor/hint-partials/'
			+ this.utilities.camelToDash(field.id) + '.html';
		// this.log.info('GroupingEditor.fieldHintPartial', path);
		return path;
	}

	modifierClasses(area) {
		const result = [];
		const base = 'grouping-editor__';
		if (this.notAllowedTarget === area) {
			result.push(base + area + '--not-allowed');
		}
		if (this.isAllowedInArea(area)) {
			result.push(base + area + '--drop-target');
		}

		// if(result.length)
		//     this.log.info('GroupingEditor.modifierClasses()', result);

		return result;
	}

	delete(index, item, removeFrom) {
		this.log.info('GroupingEditor.delete', item);
		if (this.groupingEditor.isAllowedInArea('available', item)) {
			this.fields.available.push(item);
			removeFrom.splice(index, 1);
		}
	}

	canBeDeleted(field) {
		return this.groupingEditor.isAllowedInArea('available', field);
	}

	isFixed(field) {
		return this.groupingEditor.isFixed(field);
	}

	isAllowedInArea(area, field= null) {
		field = field || this.itemDragging;
		return this.groupingEditor.isAllowedInArea(area, field);
	}

	isNextLinked(arr, index) {
		return this.groupingEditor.isNextFieldInHierarchy(arr, index);
	}

	/* Apparently deprecated code?
	applyLayout(format) {
		const self = this;
		this.isOpen = false;

		// Reset all fields to available
		const allFields = this.store.fields();
		this.fields.rows = [];
		this.fields.columns = [];
		this.fields.available = allFields.filter(function(v) {
			return v.groupingConfig;
		});

		// This object will be passed up to ScheduleBrowser for post-processing
		const actions = {
			expand: [], // Indexes of fields to expand upon update
			collapse: [] // Indexes of fields to collapse upon update
		};

		['columns', 'rows'].forEach(function(key) {
			format[key].forEach(function(field) {
				const availableIndex = self.fields.available.findIndex(function(available) {
					return available.id === field.id;
				});

				if (availableIndex < 0) {
					throw new Error('GroupingEditor.applyFormat: Field id `' + field.id + '` was not found in the ' +
						'available fields for the `' + self.tab.report.name + '` report.');
					return;
				}

				// Move fields from available to columns/rows
				self.fields[key].push(self.fields.available.splice(availableIndex, 1)[0]);

				const storeIndex = self.store.fields().findIndex(function(storeField) {
					return field.id === storeField.id;
				});

				if (storeIndex < 0) {
					throw new Error('GroupingEditor.applyFormat: Field id `' + field.id + '` was not found in the ' +
						'pivotGridDataSource.');
				}

				if (field.expanded) {
					actions.expand.push(storeIndex);
				} else {
					actions.collapse.push(storeIndex);
				}

				self.onSubmit({
					data: self.groupingEditor.process(this.store, this.fields),
					actions: actions
				});
			});
		});
	}
	*/

	handleSubmit() {
		this.submit.emit({
			data: this.groupingEditor.process(this.store, this.fields)
		});
		this.setIsOpen(false);
	}
}
