import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ReportIssueService {
	private url = (environment?.base ?? 'https://portal.sao.wa.gov/fit') + '/IssueReports';

	constructor(
		private http: HttpClient
	) {
	}

	create = (content: any) => {
		const form = new FormData();
		form.append('file', content, 'content.zip');

		return this.http.post(this.url, form);
	};
}
