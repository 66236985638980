<dx-popup
	maxHeight="90%"
	[minHeight]="560"
	height="auto"
	width="50%"
	class="comparison-dialog__popup"
	[title]="current.title"
	[visible]="isVisible"
	(onHidden)="handleOnHidden()"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<dx-scroll-view showScrollbar="always">
				<div class="app-popup__inner">
					<h2 class="comparison-dialog__title-government">{{ current.governmentName }}, {{ current.year }}</h2>
					<h3 class="comparison-dialog__title-showing">{{ current.subtitle }}</h3>

					<table *ngIf="current.totals" class="comparison-dialog__peerlist">
						<tr
							*ngFor="let t of current.totals"
							class="comparison-dialog__peerlist__item"
							[class.comparison-dialog__peerlist__item--selected]="current.mcag === t.mcag">
							<td class="comparison-dialog__peerlist__item__name">{{ t.entityName }}</td>
							<td class="comparison-dialog__peerlist__item__amount">{{ t.value }}</td>
						</tr>
					</table>

					<table *ngIf="!current.totals" class="comparison-dialog__peerlist">
						<tr *ngFor="let n of [1, 2, 3, 4, 5, 6]">
							<td class="comparison-dialog__peerlist__item__name">---</td>
							<td class="comparison-dialog__peerlist__item__amount">---</td>
						</tr>
					</table>

					<div class="u-text-align-center">
						<dx-button
							[class.button--nonoperative]="!current.totals"
							type="default"
							(click)="performFinancialSummary($event)"
						>
							<div *dxTemplate="let data of 'content'">
								<strong>Show Financial Summary Comparison</strong><br />
								(this will open data in a new tab)
							</div>
						</dx-button>
					</div>

					<div class="comparison-dialog__button__annotation"></div>

					<div class="comparison-dialog__summary">
						<span *ngIf="summary.filters || contextualAnnotations">*</span>
						{{ summary.filters }}{{ contextualAnnotations }}
						<span *ngIf="summary.filters || contextualAnnotations">.</span>
					</div>

					<dx-load-panel
						class="comparison-dialog__load-panel"
						[visible]="isLoading"
						message="Loading data..."
					></dx-load-panel>
				</div>
			</dx-scroll-view>

			<div class="app-popup__footer">
				<dx-button
					text="Close"
					type="default"
					(onClick)="close()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
