<dx-popover
	target="#popover-position"
	[width]="300"
	[shading]="true"
	position="false"
	[visible]="shouldShowPopover()"
	(onHidden)="closePopover()">
	<div *dxTemplate="let data of 'content'" class="popover">
		<h3>New!</h3>
		<p>You can right-click cells within the pivot grid for additional options.</p>
		<button class="close" (click)="closePopover()">
			<fa-icon [icon]="icons.close"></fa-icon>
		</button>
	</div>
</dx-popover>
<div id="popover-position"></div>

<dx-pivot-grid
	class="pivot-grid"
	[dataSource]="pivotGridData"
	[showRowGrandTotals]="tab?.report?.showRowGrandTotals"
	[rowHeaderLayout]="tab?.report?.rowHeaderLayout || 'tree'"
	allowFiltering="false"
	allowSorting="true"
	allowSortingBySummary="true"
	[allowExpandAll]="true"
	[showColumnGrandTotals]="false"
	[showColumnTotals]="false"
	[showRowTotals]="tab.track.id !== 'e'"
	[hideEmptySummaryCells]="true"
	(onCellPrepared)="onCellPrepared($event)"
	(onContextMenuPreparing)="contextMenu($event)"
	(onContentReady)="onContentReady($event)"
	style="width: 100%; height: 100%;"
>

	<dxo-load-panel text="Loading financial records..."></dxo-load-panel>
	<dxo-field-chooser [enabled]="false"></dxo-field-chooser>
	<dxo-field-panel
		[visible]="true"
		[showFilterFields]="false"
		[showDataFields]="false"
		[showColumnFields]="false"
		[showRowFields]="false"
		[allowFieldDragging]="false">
	</dxo-field-panel>
	<dxo-texts
		[total]="tab?.report?.hideTotalText ? '{0}' : '{0} Total'">
	</dxo-texts>
	<dxo-state-storing
		enabled=true
		type="custom"
		[customLoad]="load"
		[customSave]="save">
	</dxo-state-storing>
</dx-pivot-grid>

<wasao-bars-description
	[target]="barsDescriptionTarget"
	[id]="barsDescriptionId"
></wasao-bars-description>

<app-comparison-dialog
	[isVisible]="isComparisonDialogVisible"
	[tab]="tab"
	[pivotGridData]="pivotGridData"
	[summary]="summary"
	[event]="currentContextMenuEvent"
	(visible)="setIsComparisonDialogVisible($event)"
></app-comparison-dialog>
