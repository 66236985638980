import {Schedule1Detail} from '../../shared/models/schedules/schedule1-detail';
import {Fund} from '../../modules/services/fund-service/models/fund';
import {FundTypeId} from '../../modules/services/fund-service/models/fund-type';

export type FundReset = 'all' | 'selected';

export class ProjectionEdit {
	savedProjectionId: number;
	baseYear: number;
	funds = new Array<ProjectionEditFund>();

	/**
	 * Returns reference to editRows based on either finding the indicated fund or creating a new
	 * record if the fundNumber is new.
	 * @param fundNumber
	 * @param fundName
	 */
	getProjectionEditRows = (fundNumber: string, fundName: string = null, fundTypeId: FundTypeId = null) => {
		let editFund: ProjectionEditFund = this.funds.find(f => f.fund.fundNumber === fundNumber);
		if (editFund == null) {
			const newEditFund = new ProjectionEditFund();
			newEditFund.fund = new Fund(fundNumber, fundName, fundTypeId);
			this.funds.push(newEditFund);
			editFund = newEditFund;
		}
		// If fundName & fundTypeId not set previously, do it now (we might have called this method for prior year
		// schedule 1 records whose fund names we don't use - since they can change from year to year
		// and we want to use the base year's fund name)
		if (fundName && editFund.fund.fundName === null) {
			editFund.fund.fundName = fundName;
		}
		if (fundName && editFund.fund.fundTypeId === null) {
			editFund.fund.fundTypeId = fundTypeId;
		}
		return editFund.editRows;
	}

	hasUserEditsForFund(fundName: string): boolean {
		return this.funds.find(f => f.fund?.fundNumber === fundName)
			.editRows.some(r => r.hasUserEdits);
	}

	calculateBalances() {
		this.funds.forEach(f => {
			ProjectionEditRow.calculateBalances(f.editRows);
		});
	}

	generateProjection(mcag: string, name: string, notes: string) {
		const savableProjection = new Projection();
		if (this.savedProjectionId) {
			savableProjection.id = this.savedProjectionId;
		}
		savableProjection.mcag = mcag;
		savableProjection.baseYear = this.baseYear;
		savableProjection.name = name;
		savableProjection.notes = notes;
		savableProjection.count = this.funds[0]?.editRows[0]?.futureYears.length;
		this.funds.forEach(f => f.editRows.filter(r => r.hasUserEdits).forEach(r => {
				const newRow = new ProjectionRow();
				newRow.fundNumber = f.fund.fundNumber;
				newRow.accountId = r.id;
				newRow.rowType = r.rowType;
				newRow.futureYearAdjustments = r.futureYears
					.map(y => y.adjustmentType === AdjustmentType.USER ? y.adjustmentValue : null);
				savableProjection.adjustmentRows.push(newRow);
			})
		);
		return savableProjection;
	}
}

export class ProjectionEditFund {
	fund: Fund;
	editRows = new Array<ProjectionEditRow>();
}

export class ProjectionEditRow {
	/** BARS id */
	id: string;
	rowType: string;
	parentId: string;
	expenditureObjectId: string;
	hasChildren: boolean;
	name: string;
	nameWithCategory: string;
	priorYearAmount: number;
	baseYearAmount: number;
	difference: number;
	categoryDisplay: string;  // Controls order of row display
	futureYears: Array<ProjectionEditYear>;

	get isEditable() {
		if (this.rowType === 'balances') {
			return false;
		} else {
			return this.baseYearAmount > 0 || this.baseYearAmount < 0;
		}
	}

	get hasUserEdits() {
		return this.futureYears.some(y => y.adjustmentType === AdjustmentType.USER);
	}

	static calculateBalances = (projectionEditRows: Array<ProjectionEditRow>) => {
		const numYears = projectionEditRows[0]?.futureYears?.length;
		const beginBalanceRow = projectionEditRows.find(r => r.id === 'BEGIN');
		const endBalanceRow = projectionEditRows.find(r => r.id === 'END');
		const increasesRow = projectionEditRows.find(r => r.id === '1');
		const decreasesRow = projectionEditRows.find(r => r.id === '1902');

		if (beginBalanceRow && endBalanceRow) {
			let i: number;
			for (i = 0; i < numYears; i++) {
				if (i === 0) {
					beginBalanceRow.futureYears[i].amount = endBalanceRow?.baseYearAmount;
				} else {
					beginBalanceRow.futureYears[i].amount = endBalanceRow?.futureYears[i - 1].amount;
				}
				endBalanceRow.futureYears[i].amount = (beginBalanceRow?.futureYears[i].amount ?? 0)
					+ (increasesRow?.futureYears[i].amount ?? 0)
					- (decreasesRow?.futureYears[i].amount ?? 0);
			}
		}
	}

	plusUpAmountsForDataLoad(s1Row: Schedule1Detail, baseYear: number, isBalanceRow: boolean = false) {
		// Plus up baseYearAmount and future years
		if (s1Row.year === baseYear) {
			this.baseYearAmount += s1Row.amount;
			if (!isBalanceRow) {
				this.futureYears.forEach(x => x.amount += s1Row.amount);
			}
		}
		else {
			this.priorYearAmount += s1Row.amount;
		}
	}
}

export enum AdjustmentType {
	USER                = 'user',    // User entered adjustment
	CALCULATED_READONLY = 'calculatedReadOnly', // Calculated beginning & ending balances
	CALCULATED          = 'calculated', // Calculated percentage adjustment from children
	INHERITED           = 'inherited',  // Inherited percentage adjustment from parent
	DEFAULT             = 'default'     // Defaults to zero
}

export class ProjectionEditYear {
	amount: number;
	/** percent */
	adjustmentValue: number;
	adjustmentType: AdjustmentType = AdjustmentType.DEFAULT;

	constructor(amount = 0, adjustmentValue = 0, adjustmentType = AdjustmentType.DEFAULT) {
		this.amount          = amount;
		this.adjustmentValue = adjustmentValue;
		this.adjustmentType  = adjustmentType;
	}

	changePercentAdjustment(percent: number, adjustmentType: AdjustmentType, previousYearAmount: number) {
		if (previousYearAmount !== null) {
			this.adjustmentValue = percent;
			this.adjustmentType = adjustmentType;
			this.applyPercentAdjustment(previousYearAmount);
		}
	}

	applyPercentAdjustment(previousYearAmount: number) {
		const calc = previousYearAmount * (1 + this.adjustmentValue);
		this.amount = +calc.toFixed(2);
	}
}

/**
 * Projection object ready for saving
 */
export class Projection {
	id: number;
	userId: number;
	mcag: string;
	baseYear: number;
	name: string;
	notes: string;
	count: number;
	adjustmentRows = new Array<ProjectionRow>();

	hasAnyUserAdjustments(fundNumber: string = null) {
		if (fundNumber) {
			return this.adjustmentRows.filter(a => a.fundNumber === fundNumber).length > 0;
		} else {
			return this.adjustmentRows.length > 0;
		}
	}
}

export class ProjectionRow {
	id: number;
	fundNumber: string;
	accountId: string;
	rowType: string;
	/** percent */
	futureYearAdjustments: Array<number>;
}
