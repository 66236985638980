<div
	*ngIf="yearsRunning > 0 || !hideWhenContentEmpty"
	(click)="handleBadgeClick()"
	[class.compact]="displayDensity === 'compact'"
>
	<div class="badge">
		<img src="assets/images/check-shield.svg" alt="Filed On Time Badge" />
		<span *ngIf="yearsRunning > 0" class="label">{{ yearsRunning > 10 ? '10+' : yearsRunning }}</span>
	</div>
	<div class="text">
		<div *ngIf="yearsRunning === 0">Did not file<br />on time.</div>
		<div *ngIf="yearsRunning > 0">Filed on time<br /></div>
		<div *ngIf="yearsRunning === 1">this year!</div>
		<div *ngIf="yearsRunning > 1">{{ yearsRunning > 10 ? '10+' : yearsRunning }} years running!</div>
	</div>
	<wasao-loading [isLoading]="isLoading"></wasao-loading>
</div>
