import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FilingBasis} from '../../api/fit-api/models/snapshots/filing-basis';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {LoggerService} from '../../../shared/services/logger.service';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Component({
	selector: 'wasao-filing-basis-picker',
	templateUrl: './filing-basis-picker.component.html',
	styleUrls: ['./filing-basis-picker.component.scss']
})
export class FilingBasisPickerComponent implements OnInit, OnChanges {

	/**
	 * Displayed in the SelectBox before a value has been chosen. Can be overriden with input.
	 */
	@Input() placeholder = 'Choose a filing basis...';
	/**
	 * FilingBasis.name
	 */
	@Input() value: string;
	@Input() snapshotId: SnapshotId;
	@Output() changed = new EventEmitter<FilingBasis>();

	isLoading = false;
	dataSource: Array<FilingBasis>;

	constructor(
		private fitApi: FitApiService,
		private logger: LoggerService
	) {
	}

	ngOnInit(): void {
		this.updateDataSource();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.snapshotId?.currentValue) {
			this.updateDataSource();
		}
	}

	updateDataSource = () => {
		this.fitApi.getAnnualFilingSnapshot(this.snapshotId).subscribe(
			snapshot => {
				this.dataSource = snapshot.detail.filingBases
					.filter(x => x.name !== 'None');
			},
			error => this.logger.error(error),
			() => this.isLoading = false
		);
	};

	selectionChanged = (event: FilingBasis) => this.changed.emit(event);

}
