import {Component, OnInit} from '@angular/core';
import {
	faBars,
	faChartBar,
	faDownload,
	faMapMarkedAlt,
	faPrint,
	faShareAlt
} from '@fortawesome/free-solid-svg-icons';
import {LoggerService} from '../../shared/services/logger.service';
import {TabService} from '../../shared/services/tab.service';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {Tab} from 'app/shared/models/tab';
import {PivotGridService} from 'app/shared/services/pivot-grid.service';
import {PivotGridComponent} from 'app/components/pivot-grid/pivot-grid.component';
import {FieldService} from 'app/shared/services/field.service';
import {UserService} from 'app/shared/services/user.service';
import {ReportSummaryService} from 'app/shared/services/report-summary.service';
import {combineLatest, forkJoin} from 'rxjs';
import {UserInterfaceService} from 'app/shared/services/user-interface.service';
import {ShareUrlService} from 'app/shared/services/share-url.service';
import {User} from 'app/shared/models/user';
import {environment} from 'environments/environment';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {MapService} from '../../modules/ui/map/map.service';
import {TrackEService} from '../../shared/services/tracks/track-e.service';
import {skipWhile} from 'rxjs/operators';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector: 'app-command-bar',
	templateUrl: './command-bar.component.html',
	styleUrls: ['./command-bar.component.scss']
})
export class CommandBarComponent implements OnInit {

	snapshots: Array<any> = [];
	currentSnapshotId: SnapshotId;
	currentSnapshot: any;
	currentTab: Tab;
	pivotGridData = null;
	pivotGridUpdate = null;
	isLoadingSnapshots = false;
	isLoaded = false;
	summary: any = {};
	reportMenuIcon = faBars;
	isCopyLinkVisible = false;
	user: User;
	/** Determines whether the ProjectionLauncher is shown */
	isProjectionEnabled = false;
	isIndicatorMapAvailable = false;
	isReportChartAvailable = false;
	isDisplayingOSPI = false;

	icons = {
		print: faPrint,
		download: faDownload,
		share: faShareAlt,
		chart: faChartBar,
		map: faMapMarkedAlt
	};

	constructor(
		private logger: LoggerService,
		private tabService: TabService,
		private snapshotService: SnapshotService,
		private pivotGridService: PivotGridService,
		private fieldService: FieldService,
		private userService: UserService,
		private reportSummaryService: ReportSummaryService,
		private uiService: UserInterfaceService,
		private shareUrlService: ShareUrlService,
		private fitApi: FitApiService,
		private mapService: MapService,
		private trackE: TrackEService
	) {
	}

	ngOnInit() {
		/**
		 * this.tabService.getSelectedTab() does not work here;
		 * it gets called before tabService._tabs is populated w/tabs from storage
		 */
		this.tabService.tabs.subscribe((tabs) => {
			// cast to new tab to notify children of nested changes
			const currentTabObject = tabs.find((tab) => tab.selected);
			if (currentTabObject) {
				this.currentTab = Object.assign(new Tab(), currentTabObject);
			}
			this.updateSnapshotsDataSource(this.currentTab);
			if (this.currentTab) {
				this.currentTab.snapshotIdSubject.subscribe(
					(snapshotId) => {
						this.logger.info(this.constructor.name + ': snapshotId updated:', snapshotId);
						// Test for valid snapshotId based on tab state, etc.
						this.currentSnapshotId = snapshotId;
						const snapshotSub = this.snapshotService.getSnapshot(this.currentSnapshotId).pipe(skipWhile(snapshot => !snapshot));
						combineLatest([snapshotSub, this.userService.user, this.fitApi.getGovernmentTypes]).subscribe(
							([snapshot, user, govTypes]) => {
								// force off of Live if not applicable
								if (!this.currentTab.canUseLive(user.hasGlobalAccess()) && snapshotId === 'live') {
									this.currentTab.snapshotId = this.snapshotService.latestId;
								} else {
									this.evaluateReportChartAvailable();
									this.evaluateIndicatorReportMapAvailable();
									this.currentSnapshot = snapshot;
									this.user = user;
									// TODO passing in govTypeList as param because we cant make service call in tabs model but this should probably be changed in next iteration
									this.isDisplayingOSPI = this.currentTab.isDisplayingOSPIDataset(user.hasGlobalAccess(), user.hasAccessToAnySchool(), govTypes);
									this.isProjectionEnabled = this.evaluateIsProjectionEnabled(this.currentTab, user);
									if (this.isProjectionEnabled && snapshotId !== this.snapshotService.latestId) {
										delete this.currentTab.pivotGridSettings?.projectionId;
										delete this.currentTab.pivotGridSettings?.projectionName;
									}
									if (this.currentTab.state === 'lgfrs') {
										this.pivotGridService.getDataSource(this.currentTab).subscribe(dataSource => {
											this.pivotGridData = dataSource;
											this.currentTab.pivotGridSettings = this.pivotGridService.getSettings(this.currentTab, user);
											this.isLoaded = true;
										});
									} else {
										this.isLoaded = true;
									}
								}
							}
						);
					}
				);
			}
		});
	}

	private updateSnapshotsDataSource = (tab: Tab): void => {
		if (!tab) {
			return;
		}
		this.isLoadingSnapshots = true;
		forkJoin([
			this.fitApi.getAnnualFilingSnapshot('live'), // this actually gets the detail, which takes a little longer
			this.fitApi.getAnnualFilingSnapshots
		]).subscribe(([live, snapshots]) => {
			this.snapshots = this.tabService.getCombinedSnapshotsForUserAccess(live, snapshots, tab)
				.map(s => ({id: s.id, name: s.name, totalRevenues: s.totalRevenues, filersWithData: s.filersWithData}));
			this.isLoadingSnapshots = false;
		});
	};

	private evaluateIsProjectionEnabled = (tab: Tab, user: User) => {
		const supportedReports = this.tabService.availableReports.filter(x => x.supportsProjections).map(x => x.id);
		const userCanAccess = user?.hasAnyFitAccessRole;
		const isSupported = tab?.state === 'lgfrs' &&
			tab?.track?.id === 'a' &&
			supportedReports.includes(tab?.report?.id);

		return userCanAccess && isSupported;
	};


	displayExpr(item) {
		if (item) {
			return item.name;
		}
	}

	updateSnapshot(event) {
		this.logger.info(this.constructor.name + ' updateSnapshot(event) called.');
		// ensure snapshot is loaded before triggering a change
		this.snapshotService.getSnapshot(event.value).pipe(skipWhile(snapshot => !snapshot)).subscribe(snapshot => {
			this.logger.info(this.constructor.name + ' snapshot ' + event.value + ' cached.', snapshot);
			this.currentSnapshotId = event.value;
			this.currentTab.snapshotId = event.value;
			this.tabService.save(this.currentTab);
		});
	}

	updateFromDisplayOptions(data) {
		// Merge settings
		Object.assign(this.currentTab.pivotGridSettings, data);
		this.fieldService.updateFromDisplayOptions(this.pivotGridData, this.currentTab);
	}

	handleReportLayoutBuilderSubmit({data}, actions = null) {
		this.pivotGridData.fields(data.fields());
		this.currentTab.pivotGridSettings.populationField = this.fieldService.getFieldNameForPopulationDisplay(this.pivotGridData, this.currentTab);
		this.currentTab.pivotGridSettings.filingField = this.fieldService.getFieldNameForUniqueFilingDisplay(this.pivotGridData, this.currentTab);
		this.pivotGridData.load();

		if (!actions) {
			return;
		}

		// Run collapse and expand actions for any field indexes indicated in `actions` object
		if (actions.collapse && Array.isArray(actions.collapse)) {
			actions.collapse.forEach(function (fieldIndex) {
				this.pivotGridData.collapseAll(fieldIndex);
			});
		}

		if (actions.expand && Array.isArray(actions.expand)) {
			actions.expand.forEach(function (fieldIndex) {
				this.pivotGridData.expandAll(fieldIndex);
			});
		}
	}

	showReportChart() {
		this.pivotGridService.setChartVisibility(this.currentTab, true);
	}

	showIndicatorReportMap = () => this.pivotGridService.setIndicatorReportMapVisibility(this.currentTab.id, true);


	handleCustomExport() {
		this.pivotGridService.getInstance(this.currentTab.id).customExport();
	}

	print() {
		window.print();
	}

	showReportMenu = (dxEvent: any) => {
		dxEvent.event.preventDefault();
		dxEvent.event.stopPropagation();
		this.uiService.showReportMenu();
	};

	getShareUrl() {
		this.shareUrlService.storeTabData(null);
	}

	/**
	 * Set the isReportChartAvailable flag.
	 */
	evaluateReportChartAvailable(): void {
		const hiddenReports = ['summary', 'schedule01CategoryTotals', 'governmentalIndicators', 'enterpriseFundIndicators', 'debtAndLiabilities', 'schedule09CategoryTotals'];

		this.isReportChartAvailable = this.currentTab.state === 'lgfrs' &&
			!hiddenReports.includes(this.currentTab?.report?.id) &&
			this.currentTab?.report?.financialsDatasetSource !== 'OSPI';
	}

	/**
	 * Set the isIndicatorMapAvailable flag.
	 */
	evaluateIndicatorReportMapAvailable(): void {
		if (this.currentTab?.track?.id !== 'e') {
			this.isIndicatorMapAvailable = false;
			return;
		}
		// Get a single govTypeCode or false/undefined/null
		const govTypeCode = Array.isArray(this.currentTab.govTypes) // boolean
			&& this.currentTab.govTypes.length === 1 // boolean
			&& this.currentTab.govTypes[0].code; // any
		if (!govTypeCode) {
			this.isIndicatorMapAvailable = false;
			return;
		}
		// Make a call to shapes endpoint to determine if any shapes are available in order to show the map
		this.mapService.hasAnyShapes(govTypeCode).subscribe(result => {
			this.isIndicatorMapAvailable = result === true &&
				this.currentTab.state === 'lgfrs' &&
				this.currentTab?.report?.id === 'governmentalIndicators';
		});
	}

}
