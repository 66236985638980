import {Component, OnInit} from '@angular/core';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector:    'app-sidebar-drawer',
	templateUrl: './sidebar-drawer.component.html',
	styleUrls:   ['./sidebar-drawer.component.scss']
})
export class SidebarDrawerComponent implements OnInit {

	public tabOpen = false;
	public icons   = {
		close: faTimes,
	};

	constructor() {
	}

	ngOnInit() {
	}

	toggleTab() {
		this.tabOpen = !this.tabOpen;
	}

}
