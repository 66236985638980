import {Injectable} from '@angular/core';
import Dexie from 'dexie';
// import 'dexie-syncable'; // for use with syncing changes atomically to server
// import 'dexie-observable';
import {Tab} from 'app/shared/models/tab';
import {User} from 'app/shared/models/user';
import {UniquePipe} from 'app/shared/pipes/unique.pipe';
import {PeerSet} from '../models/peer-set';
import { Projection } from 'app/components/projection/projection';
import {LoggerService} from './logger.service';
import {alert} from 'devextreme/ui/dialog';

enum DexieObservableChangeTypes {
	Create = 1,
	Update = 2,
	Delete = 3
}

@Injectable({
	providedIn: 'root'
})
/**
 * IndexedDB storage service implementing Dexie. http://dexie.org/docs
 */
export class StorageService extends Dexie {
	tabs: Dexie.Table<Tab, number>;
	locations: Dexie.Table<any, number>;
	peerSets: Dexie.Table<PeerSet, number>;
	users: Dexie.Table<User, number>;
	projections: Dexie.Table<Projection, number>;

	// Time to wait after model changes before sending to the database. This is to prevent changes that happen
	// within a short amount of time from triggering multiple calls to the server
	private debounceTime = 5000;

	// tables to sync with the server
	private remoteTables = ['tabs', 'users', 'peerSets'];

	constructor(
		private unique: UniquePipe,
		private logger: LoggerService
	) {
		super('FIT'); // database name
		this.init();
	}

	/**
	 * Init our storage containers, tables, etc
	 */
	init() {
		// Syntax: http://dexie.org/docs/Version/Version.stores()
		this.version(1).stores({
			tabs:      '++id',
			users:     '++id',
			peerSets:  '++id',
			locations: 'zipCode'
		});

		// link to user
		this.version(2).stores({
			tabs: '++id,userId'
		}).upgrade((transaction: any) => {
			// type as 'any' since transaction doesn't pick up table names
			return transaction.tabs.toCollection().modify((tab: Tab) => tab.userId = 1);
		});

		this.version(3).stores({
			projections: '++id,userId'
		});

		// Delete any Enterprise Fund Change in Net Position indicators. No schema changes.
		// http://saoapolytfsweb:8080/tfs/SAOCollection/BonanzaUI/_workitems/edit/13038
		this.version(4).upgrade((transaction: any) => {
			return transaction.tabs.toCollection()
				.filter((tab: Tab) => tab?.indicator?.code === 'GAAP62.ChangeInFundEquityOrNetPosition.Enterprise')
				.delete()
				.then(count => {
					if (count > 0) {
						this.logger.log(`Dexie deleted ${count} tabs during v4 upgrade.`);
						// Let the user know
						alert(`<p style="max-width: 600px;">One or more saved tabs contained a financial indicator (Enterprise Fund Change in Net Position) that is no longer available for the government entity it was originally created for. These saved tabs have been deleted.</p>`, 'Notice');
					}
			});
		});

		this.version(5).stores({
			peerSets: '++id,userId'
		});

		this.version(6)
			.upgrade((transaction: any) => {
				return transaction.projections.toCollection()
					.delete()
					.then(count => {
						if (count > 0) {
							this.logger.log(`Dexie deleted ${count} projections during v6 upgrade.`);
							// Let the user know
							alert(`<p style="max-width: 600px;">One or more saved projections were based on fund categories which is no longer supported. These saved projections have been deleted.</p>`, 'Notice');
						}
					});
			})
			.stores({
			projections: '++id,userId,mcag,baseYear'
		});

		// Delete any GAAP Financial Statement View reports. No schema changes.
		// http://saoapolytfsweb:8080/tfs/saocollection/BonanzaUI/_workitems/edit/13717
		this.version(7).upgrade((transaction: any) => {
			return transaction.tabs.toCollection()
				.filter((tab: Tab) => tab?.report?.id === 'gaapFinancialStatementEnterprise' || tab?.report?.id === 'gaapFinancialStatementGovernmental')
				.delete()
				.then(count => {
					if (count > 0) {
						this.logger.log(`Dexie deleted ${count} tabs during v7 upgrade.`);
						// Let the user know
						alert(`<p style="max-width: 600px;">One or more saved tabs contained a GAAP Financial Statement View that has been changed to an Operating Results Analysis for the government entity it was originally created for. These saved tabs have been deleted.</p>`, 'Notice');
					}
				});
		});

		// Addition of Debt & Liability to the Explore By Category wizard (Track D - GovernmentFunction reports)
		// http://saoapolytfsweb:8080/tfs/saocollection/BonanzaUI/_workitems/edit/14523
		this.version(8).upgrade((transaction: any) => {
			return transaction.tabs.toCollection()
				.filter((tab: Tab) => tab?.track?.id === 'd')
				.delete()
				.then(count => {
					if (count > 0) {
						this.logger.log(`Dexie deleted ${count} tabs during v8 upgrade.`);
						// Let the user know
						alert(`<p style="max-width: 600px;">One or more saved tabs contained a version of the "Explore Data -> By Category" report that is no longer supported. These saved tabs have been deleted.</p>`, 'Notice');
					}
				});
		});

		this.tabs.mapToClass(Tab);
		this.users.mapToClass(User);
		this.peerSets.mapToClass(PeerSet);
		this.projections.mapToClass(Projection);
		this.on('populate', this.populate.bind(this)); // runs only on database creation
		// this.on('changes', this.handleChangeEvent); // fires when any changes (create, delete, update) occur
	}

	private handleChangeEvent(event) {
		// event contains an array of actions (1 or multiple in case of a transaction) with the table
		// operated on. Pull out a unique list of table names...
		// const involvedTables = this.unique.transform(event, 'table');
		// Could also look for DexieObservableChangeTypes for specific actions
	}

	/**
	 * Migrate any existing localStorage, and setup a default user and peer set
	 *
	 * @return Promise<any>
	 */
	private populate(): Promise<any> {
		const user    = new User();
		// const peerSet = new PeerSet();

		return Promise.all([
			this.users.add(user),
			// this.peerSets.add(peerSet),
		]);
	}

	/**
	 * Convert current local storage
	 */

	/**
	 * Get local storage via key
	 *
	 * @param key
	 * @return any
	 */
	private getLocalStorage(key: string) {
		const obj = JSON.parse(localStorage.getItem(key));
		return (obj && obj._value) || null;
	}

}
