<div class="schedule-browser-chart">
	<dx-popup
		class="schedule-browser-chart__popup"
		[title]="popupTitle"
		[closeOnOutsideClick]="true"
		[showCloseButton]="true"
		[visible]="isVisible"
		contentTemplate="content"
		(onShown)="handleChartPopupShown()"
		(onHidden)="handleChartPopupHidden()"
	>
		<div *dxTemplate="let data of 'content'">
			<div class="app-popup__wrapper">
				<div class="app-popup__inner">
					<dx-chart
						(onInitialized)="handleChartInitialize($event)"
						[palette]="chartColorPalette"
						[paletteExtensionMode]="'alternate'"
						(onExporting)="handleExportPrep($event)"
						redrawOnResize=true
					>
						<dxo-margin
							[top]="chartMargin"
							[bottom]="chartMargin"
							[left]="chartMargin"
							[right]="chartMargin">
						</dxo-margin>
						<dxo-title
							text=" "
							verticalAlignment="bottom"
							margin=20
						>
							<dxo-font [family]="fontFamily" [color]="fontColor" size=20></dxo-font>
							<dxo-subtitle [text]="summary.info">
								<dxo-font [family]="fontFamily" [color]="fontColor" size=14></dxo-font>
							</dxo-subtitle>
						</dxo-title>
						<dxo-legend
							hoverMode="none"
							[customizeText]="customizeLegend"
						>
							<dxo-font [family]="fontFamily" [color]="fontColor" size=14></dxo-font>
						</dxo-legend>
						<dxo-common-axis-settings>
							<dxo-label
								[rotationAngle]="15"
								overlappingBehavior="rotate"
							>
								<dxo-font [family]="fontFamily" [color]="fontColor" size=14></dxo-font>
							</dxo-label>
							<dxo-title>
								<dxo-font [family]="fontFamily" [color]="fontColor" size=14></dxo-font>
							</dxo-title>
						</dxo-common-axis-settings>
						<dxo-common-series-settings type="stackedBar">
							<dxo-label [visible]="false">
								<dxo-font [family]="fontFamily" [color]="fontColor" size=14></dxo-font>
								<dxo-format type="currency"></dxo-format>
							</dxo-label>
						</dxo-common-series-settings>
						<dxo-tooltip
							[enabled]="true"
							location="edge"
							zIndex=1600
							format="currency"
							[customizeTooltip]="customizeTooltip"
						>
						</dxo-tooltip>
						<dxo-export
							[enabled]="true"
							[printingEnabled]="false"
							[fileName]="summary.title"
						></dxo-export>
					</dx-chart>
				</div>
			</div>
		</div>
	</dx-popup>
</div>
