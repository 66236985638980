<table *ngIf="!(displayDensity === 'print')" class="table table--zebra">
	<tr *ngFor="let item of items">
		<td>
			<button *ngIf="isInteractive"
					(click)="handleReportTypeClick(item.report.id, item.displayYearSummary.year)">{{ item.report.name }}</button>
			<span *ngIf="!isInteractive">{{ item.report.name }} </span>
			<wasao-hint *ngIf="isInteractive && item.report.hintInformation" icon="infoCircle"
						[hintInformation]="item.report.id"></wasao-hint>
			<ng-container *ngIf="item.displayYear">(FY {{ item.displayYear }})</ng-container>
		</td>
		<td>
			{{ item.report.canBeSummed && item.displayYearSummary?.amount == null
			? '(no data)'
			: item.displayYearSummary.amount | currency:'USD':'symbol':'1.0-0' }}
		</td>
		<td>
			<dx-sparkline
				*ngIf="item.years?.length > 2"
				(click)="handleReportTypeClick(item.report.id, item.displayYearSummary.year)"
				[dataSource]="item.years"
				argumentField="year"
				valueField="amount"
				type="line"
				lineColor="#0076a9"
				[showMinMax]="false"
				[showFirstLast]="false">
				<dxo-size width="45" height="20"></dxo-size>
				<dxo-tooltip format="currency" [enabled]="isInteractive"></dxo-tooltip>
			</dx-sparkline>
		</td>
	</tr>
</table>


<!-- tear sheet version -->
<div *ngIf="displayDensity === 'print'" class="grid-tear">
	<dl *ngFor="let item of items">

		<dt>
			<span *ngIf="!isInteractive">{{ item.report.name }} </span>
			<ng-container *ngIf="item.displayYear">(FY {{ item.displayYear }}):</ng-container>
		</dt>

		<dd>

			<div>
			{{ item.report.canBeSummed && item.displayYearSummary?.amount == null
			? '(no data)'
			: item.displayYearSummary.amount | currency:'USD':'symbol':'1.0-0' }}
			</div>

			<div class="grid-tear sparkline">
			<dx-sparkline
				*ngIf="item.years?.length > 2"
				(click)="handleReportTypeClick(item.report.id, item.displayYearSummary.year)"
				[dataSource]="item.years"
				argumentField="year"
				valueField="amount"
				type="line"
				lineColor="#0076a9"
				[showMinMax]="false"
				[showFirstLast]="false">
				<dxo-size width="45" height="20"></dxo-size>
				<dxo-tooltip format="currency" [enabled]="isInteractive"></dxo-tooltip>
			</dx-sparkline>
			</div>

		</dd>

	</dl>
</div>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
