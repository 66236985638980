import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {ReusableGovernmentTypeInputs} from '../../reusable/models/reusable-government-type-inputs';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {LoggerService} from '../../../shared/services/logger.service';
import {ExternalCommonServiceApiService} from '../../api/external-common-service-api/external-common-service-api.service';
import {GovTypeIndicator} from '../../api/external-common-service-api/models/gov-type-indicator';

@Component({
	selector:    'wasao-audit-kpis',
	templateUrl: './audit-kpis.component.html',
	styleUrls:   ['./audit-kpis.component.scss']
})
export class AuditKPIsComponent extends ReusableGovernmentTypeInputs implements OnChanges {

	public data: GovTypeIndicator;
	public year = new Date().getFullYear();

	constructor(
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private logger: LoggerService,
		private common: ExternalCommonServiceApiService,
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.govTypeCode?.currentValue) {
			this.updateData();
		}
	}

	updateData = (): void => {
		this.isLoading = true;

		this.common.getPerformanceIndicatorByGovType(this.govTypeCode).subscribe(result => {
			this.data      = result;
			this.isLoading = false;
		});
	};

}
