<!-- movingMethod only works for center and zoom -->
<!-- to disable animation for fitBounds, pass duration (not documented in the API, see github) -->
<!--https://github.com/mapbox/mapbox-gl-js/issues/1473-->
<!--[fitBoundsOptions]="{ padding: 30, duration: isInteractive ? 4 : 0 }"-->
<mgl-map
	id="map"
	[style]="'mapbox://styles/gardcommunications/cjnkppgwd8b1u2sowjj3sxqfu'"
	[movingMethod]="isInteractive ? 'flyTo' : 'jumpTo'"
	[fitBounds]="fitBounds"
	[fitBoundsOptions]="{ padding: 30 }"
	[interactive]="isInteractive"
	[zoom]="zoom"
	[center]="center"
	(mapClick)="handleMapClick($event)"
	(mapLoad)="mapLoad($event)"
	scrollZoom=false
	[movingMethod]="isInteractive ? 'flyTo' : 'jumpTo'"
>
	<mgl-control
		*ngIf="isInteractive"
		mglNavigation
	></mgl-control>

	<mgl-geojson-source
		id="shapes"
		[data]="source"
	></mgl-geojson-source>

	<!-- Mapbox filters: https://docs.mapbox.com/mapbox-gl-js/style-spec/other/#other-filter -->
	<mgl-layer
		id="fill"
		type="fill"
		source="shapes"
		[paint]="layers && layers[0]?.paint"
		[filter]="layers && layers[0]?.filter"
		(layerClick)="handleLayerClick($event)"
		(layerMouseMove)="handleMouseMove($event)"
		(layerMouseLeave)="handleMouseLeave($event)"
	></mgl-layer>

	<mgl-layer
		id="outline"
		type="line"
		source="shapes"
		[paint]="layers && layers[1]?.paint"
		[filter]="layers && layers[1]?.filter"
	></mgl-layer>
	<mgl-layer
		id="pins"
		type="circle"
		source="shapes"
		[paint]="layers && layers[2]?.paint"
		[filter]="layers && layers[2]?.filter"
		(layerClick)="handleLayerClick($event)"
		(layerMouseMove)="handleMouseMove($event)"
		(layerMouseEnter)="handleMouseEnter($event)"
		(layerMouseLeave)="handleMouseLeave($event)"
	></mgl-layer>

	<!-- Show popup when we are not in single government mode, and the selectedGovernment (user click) is set -->
	<mgl-popup
		*ngIf="!updateTooltip && isInteractive && selectedGovernment?.hasValidLocation()"
		[lngLat]="selectedGovernment.lngLat"
		(popupClose)="handlePopupClosed()"
	>
		<div class="profile-popup">
			<div class="title">{{ selectedGovernment.name }}</div>
			<div class="sub-title"><strong>Type:</strong> {{ selectedGovernment.govTypeDesc }}</div>
			<dx-button
				*ngIf="govClickAction"
				text="View Profile"
				type="default"
				(click)="handleGovernmentClick(selectedGovernment.mcag)"
			></dx-button>
		</div>
	</mgl-popup>

	<mgl-popup
		*ngIf="updateTooltip && hoveredGovernment"
		[lngLat]="hoveredGovernment.lngLat"
	>
		<ng-container *ngTemplateOutlet="tooltip"></ng-container>
	</mgl-popup>

	<mgl-marker
		*ngIf="marker"
		[lngLat]="marker"
	></mgl-marker>
</mgl-map>

<div class="no-location" *ngIf="!updateTooltip && isInteractive && selectedGovernment && !selectedGovernment.hasValidLocation()">
	<div class="profile-popup">
		<div class="title">{{ selectedGovernment.name }}</div>
		<div class="sub-title">
			<fa-icon class="warning" [icon]="icons.warning"></fa-icon> This government could not be located on the map.<br>
			<strong>Type:</strong> {{ selectedGovernment.govTypeDesc }}
		</div>
		<dx-button
			*ngIf="govClickAction"
			text="View Profile"
			type="default"
			(click)="handleGovernmentClick(selectedGovernment.mcag)"
		></dx-button>
	</div>
</div>

<wasao-loading [isLoading]="isLoading" [message]="loadingMessage"></wasao-loading>
