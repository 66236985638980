<dx-popup
	[width]="600"
	[height]="710"
	[dragEnabled]="true"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	(onHidden)="onHidden()"
	(onShowing)="onShowing()"
	title="Report an Issue">

	<div class="app-popup__wrapper">
		<div class="app-popup__inner">
			<form (submit)="handleSubmit($event)">
				<dx-validation-group>
					<label class="labels">Contact Information</label>
					<div class="inputs">
						<dx-text-box [(value)]="form.contactInfo"></dx-text-box>
					</div>
					<label class="labels">Please describe the issue you are encountering:</label>
					<div class="inputs">
						<dx-text-area [height]="100" [(value)]="form.issue">
							<dx-validator>
								<dxi-validation-rule
									type="required"
									message="Issue Description is required"
								></dxi-validation-rule>
							</dx-validator>
						</dx-text-area>

						<div>
							<dx-validation-summary></dx-validation-summary>
						</div>
					</div>


					<label class="labels">Upload a screenshot of the issue (optional):</label>

					<div id="fileuploader-container">
						<div class="row">
							<div class="column left">
								<dx-file-uploader
									name="file"
									[allowCanceling]="true"
									[allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']"
									[maxFileSize]="4000000"
									[uploadFile]="uploadFile"
									(onUploaded)="onUploaded($event)"
									(onUploadError)="onUploadError($event)"
									(onValueChanged)="onValueChanged()"
								></dx-file-uploader>
							</div>

							<div class="column right">
								<div class="thumbnailDiv">
									<span class="thumbnail"></span>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="note">Allowed file extensions: .jpg, .jpeg, .gif, .png. Max file size 4Mb.</div>
						</div>
					</div>
					<br>
					<div class="submit">
						<dx-button
							id="button"
							text="Generate Email"
							type="success"
							[useSubmitBehavior]="true"
						>
						</dx-button>
					</div>
				</dx-validation-group>
			</form>
		</div>
		<div class="app-popup__footer">
			<dx-button
				text="Close"
				type="default"
				(onClick)="isVisible = false"
			></dx-button>
		</div>
	</div>

	<wasao-loading [isLoading]="isLoading" [message]="loadingMessage"></wasao-loading>
</dx-popup>
