import {Component, OnInit} from '@angular/core';
import {RouteAction} from '../route-action';
import {LoggerService} from '../../../shared/services/logger.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TabService} from '../../../shared/services/tab.service';
import {SnapshotService} from '../../../shared/services/snapshot.service';
import {TabState} from '../../../shared/models/tab-state';
import {HistoryService} from '../../../shared/services/history.service';
import {ExternalCommonServiceApiService} from '../../../modules/api/external-common-service-api/external-common-service-api.service';
import {FitApiService} from '../../../modules/api/fit-api/fit-api.service';
import {convertSnapshotIdString, SnapshotId} from '../../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector:  'app-new-government-type-profile-tab',
	template:  '',
	styleUrls: []
})
export class NewGovernmentTypeProfileTabComponent extends RouteAction implements OnInit {

	constructor(
		private log: LoggerService,
		private route: ActivatedRoute,
		private tabService: TabService,
		private snapshotService: SnapshotService,
		private history: HistoryService,
		private fitApi: FitApiService,
		private common: ExternalCommonServiceApiService,
		private router: Router
	) {
		super();
	}

	/**
	 * Open government profile tab, and replace the history state to '/'
	 */
	async ngOnInit() {
		const govTypeCode = this.route.snapshot.paramMap.get('govTypeCode') as string;
		let snapshotId    = convertSnapshotIdString(this.route.snapshot.paramMap.get('snapshotId'));

		// Wait for tabs to load and snapshots to resolve before trying to create the new tab
		this.tabService.areTabsLoaded.subscribe(loaded => {
			if (loaded) {
				this.snapshotService.getSnapshots().subscribe(snapshots => {
					if (snapshots && snapshots.length > 0) {
						this.log.log(`Creating new government type profile tab based on route. govTypeCode: ${govTypeCode}, snapshotId: ${snapshotId}`);

						if (!snapshotId) {
							snapshotId = this.snapshotService.latestId;
						}

						let tabTitle       = 'Loading...';
						const subscription = this.fitApi.getGovernmentTypes.subscribe(governmentTypes => {
							const govType = governmentTypes.find(x => x.code === govTypeCode);
							if (govType.description) {
								tabTitle = govType.description;

								this.tabService.getInitialYearsRangeForTabState(TabState.typeProfile, govType.financialsDatasetSource, snapshotId).subscribe(years =>
									this.tabService.buildTypeProfileTab(null, {
										snapshotId: snapshotId,
										govTypes:   [govTypeCode],
										selected:   true,
										years:      years,
										title:      tabTitle
									}).then(tab => this.tabService.save(tab).then(tab => {
										this.tabService.setSelectedTab(tab);
										this.router.navigateByUrl('');
									}))
								);

								subscription.unsubscribe();
							}
						});
					}
				});
			}
		});
	}

}
