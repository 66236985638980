import {Injectable} from '@angular/core';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {map, switchMap} from 'rxjs/operators';
import {Schedule9TotalAmounts} from './models/schedule9-total-amounts';
import {Schedule9} from '../../api/fit-api/models/schedule9';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {forkJoin, Observable, of} from 'rxjs';
import {FundCategoryId} from '../fund-service/models/fund-category';
import {FilingStatusService} from '../filing-status-service/filing-status.service';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class Schedule9Service {

	constructor(
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService
	) {
	}

	/**
	 * Get schedule 9 based on mcag
	 *
	 * @param specificity
	 * @param snapshotId
	 * @param year
	 */
	getTotalAmountsForYear = (specificity: GovernmentSpecificity, snapshotId?: SnapshotId, year?: number): Observable<Schedule9TotalAmounts | null> =>
		this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				// No logic for this scenario yet
				if (specificity.type === 'governmentType') {
					return of(null);
				}

				const displayYear = year ? of(year) : (specificity.type === 'government' ? this.filingStatus.getFilingYearForDisplay(specificity.id, snapshotId) : of(snapshot.barsYearUsed));
				const sch9 = this.fitApi.getSchedule9ForMcag(specificity.id, snapshot.id);

				return forkJoin([displayYear, sch9]).pipe(
					map( ([y, schedule9s]) => schedule9s
						// would use this filter if we didn't specify year in the getSchedule9ForMcag call
						.filter(x => x.year === y)
						.reduce((acc, row) => {
								acc.year = row.year;
								this.slotIntoSummary(acc, row);
								return acc;
							},
							new Schedule9TotalAmounts()
						)
				));
			})
		);

	getTotalAmounts = (specificity: GovernmentSpecificity, snapshotId?: SnapshotId): Observable<Array<Schedule9TotalAmounts>> =>
		this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				if (specificity.type === 'governmentType') {
					// No logic for this scenario yet
					return of(null);
				}

				return this.fitApi.getSchedule9ForMcag(specificity.id, snapshot.id).pipe(
					map(result => result
						.reduce((acc, row) => {
							// if year already exists in acc, use that
							// if not, create
							let objInAcc = acc.find(x => x.year === row.year);
							if (!objInAcc) {
								objInAcc      = new Schedule9TotalAmounts();
								objInAcc.year = row.year;
								acc.push(objInAcc);
							}
							this.slotIntoSummary(objInAcc, row);
							return acc;
						}, new Array<Schedule9TotalAmounts>())
						.sort((a, b) => a.year - b.year)
					));
			})
		);

	private slotIntoSummary = (model: Schedule9TotalAmounts, data: Schedule9): void => {
		model[`totalEndings`] = this.initOrAdd(model[`totalEndings`], data.ending);
	};

	private initOrAdd = (base: number, addition: number) =>
		base == null ? addition : base + addition;

}
