import {Component} from '@angular/core';
import {ShapefileLoaderService} from '../shapefile-loader.service';
import DataSource from 'devextreme/data/data_source';
import {ShapefileLoaderState} from '../models/shapefile-loader-state';
import {crosswalkStatuses} from '../models/crosswalk-status';
import {finalize} from 'rxjs/operators';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-crosswalk-editor',
	templateUrl: './crosswalk-editor.component.html',
	styleUrls: ['./crosswalk-editor.component.scss']
})
export class CrosswalkEditorComponent {

	isLoading = false;
	dataSource: DataSource;
	// Use to notify the user that they will be overwriting existing records
	hasExistingRecords: boolean;
	vintageYear: number;
	crosswalkStatuses = crosswalkStatuses;
	governmentsLookup: any;
	countiesLookup: any;
	warningIcon = faExclamationTriangle;

	constructor(
		private service: ShapefileLoaderService
	) {
		this.service.vintageYear.subscribe(year => {
			this.vintageYear = year;
			this.dataSource = this.service.getODataDataSource(ShapefileLoaderState.CrosswalkEditor, year);
			this.dataSource.store().on('loaded', this.setHasExistingRecords);
		});
		this.service.getGovernmentsLookup.subscribe(governments => this.governmentsLookup = governments);
		this.service.getCountiesLookup.subscribe(counties => this.countiesLookup = counties);
	}

	setHasExistingRecords = (records: Array<any>) => {
		this.hasExistingRecords = records.length > 0;
	}

	copyFromPrevious() {
		this.isLoading = true;
		this.service.copyFromPrevious(this.vintageYear)
			.pipe(finalize(() => this.isLoading = false))
			.subscribe(
				result => this.dataSource.reload(),
				error => this.service.handleError(error)
			);
	}
}
