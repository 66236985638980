<ng-container>
	<div>
		<i><sup>1</sup>Some local government types are not included in the map or statistics since they are not required
			to
			file with the State Auditor's Office or they are required to file their financial reports with another
			agency.
			For example, school districts and educational service districts are required to submit financial reports to
			the
			Office of the Superintendent of Public Instruction (OSPI).</i>
	</div>

	<div *ngIf="numberOfNotes >= 2">
		<i><sup>2</sup>'Filed' also includes non-12/31 fiscal year end submissions, which includes late reports for
			earlier
			deadlines. Filed reports may continue to be processed after the deadline for reports that were mailed rather
			than filed electronically.</i>
	</div>

	<div *ngIf="numberOfNotes === 3">
		<i><sup>3</sup>On-time reports may continue to be processed after the deadline for reports that were mailed rather than filed electronically.</i>
	</div>

</ng-container>
