import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { DxPopupModule, DxButtonModule } from 'devextreme-angular';



@NgModule({
	declarations: [
		ModalComponent
	],
	imports: [
		CommonModule,
		DxPopupModule,
		DxButtonModule
	],
	exports: [
		ModalComponent
	]
})
export class ModalModule { }
