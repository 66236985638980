import {SnapshotId} from './snapshot-like';

export enum FinancialSummarySection {
	beginningBalances = 10,
	revenues = 20,
	otherIncreases = 25,
	expenditures = 30,
	otherDecreases = 35,
	endingBalances = 40,
	balanceSheet = 80
}

export interface FinancialSummary {
	id: number;
	snapshotId?: SnapshotId;
	year: number;
	fundCategoryId: number;
	fundTypeId: number;
	fund: string;
	latestFundName: string;
	fsSectionId: FinancialSummarySection;
	barsAccountId: number;
	expenditureObjectId: string;
	amount: number;
}
