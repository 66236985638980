import {Peer} from './peer';

export class PeerSet {
	id: number;
	userId: number;
	name: string;
	description: string;
	peers: Array<Peer>  = [];

	constructor() {
	}

	/**
	 * Add a peer to the set. Adding a peer with the prime flag set to true will set the prime flag to false elsewhere.
	 * @param peer
	 */
	addPeer(peer: Peer) {
		if (peer.prime === true) {
			this.peers.forEach(x => x.prime = false);
		}
		this.peers.push(peer);
	}

	removePeer(peer: Peer) {
		this.peers = this.peers.filter(p => p.mcag !== peer.mcag);
	}
}
