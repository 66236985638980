<dx-data-grid
	[dataSource]="dataSource"
	(onInitNewRow)="onInitNewRow($event)"
	(onEditingStart)="onEditingStart($event)"
>
	<dxo-toolbar>
		<dxi-item name="addRowButton"></dxi-item>
		<dxi-item>
			<dx-button
				icon="refresh"
				text="Reload records"
				(onClick)="refreshDataGrid($event)"
			></dx-button>
		</dxi-item>
	</dxo-toolbar>
	<dxo-editing [allowAdding]="true" [allowUpdating]="true"></dxo-editing>
	<dxi-column dataField="year" caption="Vintage Year" dataType="number" [allowEditing]="rowEditType === 'create'" sortOrder="desc">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-column>
	<dxi-column dataField="status" dataType="string">
		<dxo-lookup [dataSource]="vintageStatuses" valueExpr="id" displayExpr="label"></dxo-lookup>
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-column>
	<dxi-column dataField="description" dataType="string"></dxi-column>
	<dxi-column type="buttons">
		<dxi-button name="edit"></dxi-button>
		<dxi-button name="save"></dxi-button>
		<dxi-button name="cancel"></dxi-button>
		<dxi-button
			text="Maintain Data"
			[visible]="isMaintainVintageVisible"
			[onClick]="maintainVintage"
		></dxi-button>
	</dxi-column>
</dx-data-grid>
