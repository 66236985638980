import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';

@Component({
	selector:    'app-narrative',
	templateUrl: './narrative.component.html',
	styleUrls:   ['./narrative.component.scss']
})
export class NarrativeComponent implements OnInit {
	@Input() summary: string;
	@Input() summaryInfo: string;
	@Input() summaryFilters: string;
	@ViewChild('container', { static: true }) container: ElementRef;
	isOverflowing = false;
	isExpanded    = false;

	constructor() { }

	ngOnInit() { }

	onContentResize(event) {
		this.isOverflowing = event.newHeight > this.container.nativeElement.offsetHeight;
	}

	toggleExpanded() {
		this.isExpanded = !this.isExpanded;
	}

}
