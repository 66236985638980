import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {LoggerService} from 'app/shared/services/logger.service';
import {faInfoCircle, faExternalLinkAlt, faBan} from '@fortawesome/free-solid-svg-icons';
import {Tab} from 'app/shared/models/tab';

import {FitActionsService} from '../../services/fit-actions.service';
import {FitApiService} from '../../../modules/api/fit-api/fit-api.service';
import {GovernmentTypes} from '../../../modules/api/fit-api/models/government-types';
import {DisabledGovernmentType} from './disabled-government-type';
import {DxValidatorComponent} from 'devextreme-angular';
import {TabService} from '../../services/tab.service';
import {SnapshotService} from 'app/shared/services/snapshot.service';

import {combineLatest} from 'rxjs';
import {GovernmentTypesService} from './government-types.service';
import {SnapshotId} from '../../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector: 'app-government-types',
	templateUrl: './government-types.component.html',
	styleUrls: ['./government-types.component.scss']
})
/**
 * This is the generic picker. Its data source uses a "live" version of Government Types, not snapshot based.
 */
export class GovernmentTypesComponent implements OnInit {


	constructor(
		private logger: LoggerService,
		private fitApi: FitApiService,
		private tabService: TabService,
		private snapshotService: SnapshotService,
		public fitActions: FitActionsService,
		public govTypesService: GovernmentTypesService
	) {
	}

	@Input() tab: Tab;

	@Input() selections: Array<string> = [];
	@Input() placeholder = 'Choose from a list of government types...';
	@Input() validationCallback: Function;
	@Input() validationMessage: string;

	// Pass an array with a gov type's code and an associated reason this gov type is disabled in this context
	@Input() disabledGovTypes: Array<DisabledGovernmentType> = [];
	@Input() disabled: boolean;
	@Input() isTrackD = false;
	@Output() changed = new EventEmitter<any>();

	@ViewChild(DxValidatorComponent) validator: DxValidatorComponent;

	maxGovTypeAllowedForTrackD = 4;
	maxGovTypeDisclaimerForTrackD = `(Choose up to ${this.maxGovTypeAllowedForTrackD})`;
	baseDisabledGovTypesForTrackD: Array<DisabledGovernmentType> = [];

	store: Array<GovernmentTypes>;
	item: GovernmentTypes;

	govTypeCode: string;
	snapshotId: SnapshotId;
	snapshot: any;

	isLoading = true;
	isPopupVisible = false;
	icons = {
		infoCircle: faInfoCircle,
		extLink: faExternalLinkAlt,
		ban: faBan,
	};
	errors: Array<string> = [];


	ngOnInit() {
		combineLatest([
			this.fitApi.getGovernmentTypes,
			this.snapshotService.getSnapshot()
		]).subscribe(result => {
			this.store = result[0].sort((a, b) => a.description.localeCompare(b.description));

			this.snapshot = result[1].snapshot;

			this.isLoading = false;
		});

		// Sets the base list of disabled gov types for Track D using input disabled list
		if (this.isTrackD) {
			this.baseDisabledGovTypesForTrackD = [...this.disabledGovTypes];
		}

		// this.logger.log('Loading government types.', this);
		// this.fitApi.getGovernmentTypes.pipe(delay(5000)).subscribe(types => {
		// 	this.isLoading = false;
		//
		// 	if (types?.status === 404) {
		// 		this.errors = ['Could not retrieve government types. Please try again later.'];
		// 	} else {
		// 		this.store = types.value.sort((a, b) => a.description.localeCompare(b.description));
		// 	}
		// });
		//
		// this.snapshotService.getSnapshot().subscribe(snapshot => {
		// 	this.snapshot = snapshot;
		// 	// this.isLoading = false;
		// });
	}

	handleOpen(event) {
		event.component.close();
		this.isPopupVisible = true;
	}

	displayFormat = item => `${item.description} (${item.activeCount} active)`;

	checkItem(item, event) {
		if (!this.selections) {
			this.changed.emit([item.code]);
			return;
		}

		const index = this.selections.findIndex(i => i === item.code);
		// if unchecking, make sure item is found
		if (!event.value && index > -1) {
			this.selections.splice(index, 1);
		} else if (event.value && index === -1) {
			this.selections.splice(-1, 0, item.code);
		}
		this.changed.emit(this.selections);
	}

	handleTagboxChanged(event) {
		// checks max allowed gov types (for track d)
		if (this.isTrackD) {
			if (event.value.length >= this.maxGovTypeAllowedForTrackD) {
				// if amount of allowed selected gov types is reached, filter all other gov types to be disabled
				const govsToDisable = this.store.filter(x => !event.value.includes(x.code));
				govsToDisable.map(y => this.disabledGovTypes.push({ code: y.code, reason: 'Please deselect other government types in order to select another'}));
			} else {
				// if amount of allowed selected gov types is not reached, reset list of disabled gov types to original input list
				this.disabledGovTypes = [...this.baseDisabledGovTypesForTrackD];
			}
		}

		// this.logger.log(`tagbox changed`, event);
		this.selections = event.value;
		// Manually validate, if callback provided
		this.validator?.instance?.validate();
		this.changed.emit(this.selections);
	}

	isDisabled = (item: GovernmentTypes) => {
		if (!this.disabledGovTypes) {
			return false;
		}
		for (const disabled of this.disabledGovTypes) {
			if (disabled.code === item.code) {
				return true;
			}
		}

		return false;
	};

	getDisabledReason = (code: string): string =>
		this.disabledGovTypes.find(x => x.code === code)?.reason
		?? 'This government type is unavailable for this scenario.';


	goToProfile(item) {
		console.log('click: ' + item.code + '///item: ' + item.description);
		this.fitActions.navigateToGovTypeProfile()(item.code, item.description);
	}

}
