import {Injectable} from '@angular/core';
import {AnnualFilingSummaryItem} from './annual-filing-summary-item';
import {forkJoin, Observable, of} from 'rxjs';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {FinancialSummaryService} from '../../services/financial-summary-service/financial-summary.service';
import {REPORTS} from '../../../shared/models/report';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class AnnualFilingSummaryService {

	constructor(
		private fitApi: FitApiService,
		private financialSummary: FinancialSummaryService
	) {
	}

	/**
	 * Get generic AnnualFilingSummaryItems based on Dataset for GovernmentType
	 * @param specificity
	 * @param snapshotId Only used for AnnualFiling Datasets
	 */
	getItems(specificity: GovernmentSpecificity, snapshotId?: SnapshotId): Observable<Array<AnnualFilingSummaryItem>> {
		return this.fitApi.getDatasetFromSpecificity(specificity).pipe(
			switchMap(datasetSource => {
				switch (datasetSource) {
					case 'SAOAnnualFiling':
						return this.getItemsForAnnualFiling(specificity, snapshotId);
					case 'OSPI':
						return this.getItemsForOspi(specificity);
					default:
						throw new Error('AnnualFilingSummaryService.getItems: Cannot get items for DatasetSource '
							+ datasetSource);
				}
			})
		);
	}

	public getItemsForAnnualFiling(specificity: GovernmentSpecificity, snapshotId: SnapshotId): Observable<Array<AnnualFilingSummaryItem>> {
		// Only get Operating results for governments, not types
		const availableOperatingResultsAnalyses = specificity.type === 'government'
			? this.fitApi.getAvailableOperatingResults(specificity.id, specificity.year)
			: of(null);

		return forkJoin([
			this.financialSummary.getTotalsByReport(specificity, snapshotId),
			availableOperatingResultsAnalyses
		]).pipe(map(join => {
			const [financialSummary, operatingResults] = join;
			const result = new Array<AnnualFilingSummaryItem>();

			result.push(
				{
					report: REPORTS.find(x => x.id === 'revenues'),
					amount: financialSummary
						?.find(x => x.report.id === 'revenues')?.displayYearSummary?.amount
				},
				{
					report: REPORTS.find(x => x.id === 'expenditures'),
					amount: financialSummary
						?.find(x => x.report.id === 'expenditures')?.displayYearSummary?.amount
				},
				{
					report: REPORTS.find(x => x.id === 'summary')
				},
				{
					report: REPORTS.find(x => x.id === 'debtAndLiabilities'),
					amount: financialSummary
						.find(x => x.report.id === 'debtAndLiabilities')?.displayYearSummary?.amount
				}
			);

			if (operatingResults?.governmental) {
				result.push({
					report: REPORTS.find(x => x.id === 'operatingResultsAnalysisGovernmental')
				});
			}

			if (operatingResults?.enterprise) {
				result.push({
					report: REPORTS.find(x => x.id === 'operatingResultsAnalysisEnterprise')
				});
			}

			return result;
		}));
	}

	private getItemsForOspi(specificity: GovernmentSpecificity): Observable<Array<AnnualFilingSummaryItem>> {
		return this.financialSummary.getTotalsByReport(specificity).pipe(map(reports => {
			const result: Array<AnnualFilingSummaryItem> = [
				{
					report: REPORTS.find(x => x.id === 'schoolsRevenuesWithOthers'),
					amount: reports.find(x => x.report.id === 'schoolsRevenuesWithOthers')?.displayYearSummary?.amount
				},
				{
					report: REPORTS.find(x => x.id === 'schoolsExpenditures'),
					amount: reports.find(x => x.report.id === 'schoolsExpenditures')?.displayYearSummary?.amount
				},
				{
					report: REPORTS.find(x => x.id === 'schoolsBalanceSheet')
				},
				{
					report: REPORTS.find(x => x.id === 'schoolsStatementRevExp')
				},
				{
					report: REPORTS.find(x => x.id === 'schoolsLongTermLiabilities'),
					amount: reports.find(x => x.report.id === 'schoolsLongTermLiabilities')?.displayYearSummary?.amount
				}
			];

			return result;
		}));

	}
}
