<ng-container *ngIf="totalsByCategory?.length > 0 || !hideWhenContentEmpty">
	<dx-pie-chart
		[dataSource]="totalsByCategory"
		[palette]="pieChartColors"
		resolveLabelOverlapping="shift"
		[sizeGroup]="pieChartSizeGroup"
		type="doughnut"
	>
		<dxo-animation [enabled]="isInteractive"></dxo-animation>
		<dxo-size [height]="displayDensity === 'print' ? 250 : undefined"></dxo-size>
		<dxo-legend [visible]="false"></dxo-legend>
		<dxo-common-series-settings argumentField="category"></dxo-common-series-settings>
		<dxi-series valueField="displayYearSummaryAmount" [hoverMode]="isInteractive ? 'pointOnly' : 'none'">
			<dxo-label
				visible=true
				format="currency"
				[customizeText]="customizePointLabel"
				textOverflow="none"
			>
				<dxo-connector [visible]="true" width="1"></dxo-connector>
			</dxo-label>
			<dxo-small-values-grouping
				groupName="Others"
				mode="smallValueThreshold"
				[threshold]="smallAmountsGroupingThreshold"
			></dxo-small-values-grouping>
		</dxi-series>
		<dxo-tooltip
			[enabled]="isInteractive"
			[customizeTooltip]="customizeTooltip"
			format="currency"
		></dxo-tooltip>
	</dx-pie-chart>

	<div class="caption">
		<span class="title">{{ fundCategoryId | fundCategoryLabel }} {{ report?.name }}</span>
		<br />
		(FY {{ displayYear }}):
		{{ displayYearTotal | currency:'USD':'symbol':'1.0-0' }}
	</div>

	<ng-container *ngIf="totalsByYear?.length > 2 && displayDensity !== 'print'">
		<dx-sparkline
			[dataSource]="totalsByYear"
			argumentField="year"
			valueField="amount"
			type="line"
			lineColor="#0076a9"
			[showMinMax]="false"
			[showFirstLast]="false"
		>
			<dxo-size width="45" height="20"></dxo-size>
			<dxo-tooltip format="currency" [enabled]="isInteractive"></dxo-tooltip>
		</dx-sparkline>
		<ng-container *ngIf="trendDataClickAction || isInteractive">
			<br />
			<dx-button
				text="See Trend Data"
				type="default"
				(click)="handleTrendDataClick()"
			>
			</dx-button>
			<br />
			<wasao-hint
				icon="infoCircle"
				hintInformation="seeTrendData"
			></wasao-hint>
		</ng-container>
	</ng-container>
</ng-container>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
