import {Component, ViewChild} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {ShapefileLoaderService} from '../shapefile-loader.service';
import {ShapefileLoaderState} from '../models/shapefile-loader-state';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {finalize} from 'rxjs/operators';
import {DxFileUploaderComponent} from 'devextreme-angular';

@Component({
	selector: 'app-shapefile-upload',
	templateUrl: './shapefile-upload.component.html',
	styleUrls: ['./shapefile-upload.component.scss']
})
export class ShapefileUploadComponent {

	@ViewChild(DxFileUploaderComponent) dxFileUploader: DxFileUploaderComponent;
	dataSource: DataSource;
	isLoading = false;
	// Use to notify the user that they will be overwriting existing records
	hasExistingRecords: boolean;
	warningIcon = faExclamationTriangle;
	shapefile: File;
	vintageYear: number;

	constructor(
		private service: ShapefileLoaderService
	) {
		this.service.vintageYear.subscribe(year => {
			this.vintageYear = year;
			this.dataSource = this.service.getODataDataSource(ShapefileLoaderState.ShapefileUpload, year);
			this.dataSource.store().on('loaded', this.setHasExistingRecords);
		});
	}

	setHasExistingRecords = (records: Array<any>) => {
		this.hasExistingRecords = records.length > 0;
	}

	upload() {
		this.isLoading = true;
		this.service.uploadShapefile(this.vintageYear, this.shapefile)
			.pipe(
				finalize(() => this.isLoading = false)
			)
			.subscribe({
				next: value => {
					this.shapefile = null;
					this.dxFileUploader.instance.removeFile(0);
					this.dataSource.reload();
				},
				error: error => this.service.handleError(error)
			});
	}

	valueChanged(event: any) {
		this.shapefile = event.value?.[0];
	}
}
