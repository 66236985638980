<wasao-income-by-fund
	*ngIf="mcag"
	[mcag]="mcag"
	[year]="displayYear"
	[snapshotId]="snapshotId"
	[fundCategoryId]="fundCategories.proprietary"
	[underlyingDataClickAction]="trendDataClickAction"
	[hideWhenContentEmpty]="hideWhenContentEmpty"
	[isInteractive]="isInteractive"
	[displayDensity]="displayDensity"
	(hasData)="aggregateHasData($event, 'incomeByFund')"
></wasao-income-by-fund>
<wasao-income-by-year
	*ngIf="govTypeCode"
	[snapshotId]="snapshotId"
	[fundCategoryId]="fundCategories.proprietary"
	[govTypeCode]="govTypeCode"
	[underlyingDataClickAction]="trendDataClickAction"
	[hideWhenContentEmpty]="hideWhenContentEmpty"
	[isInteractive]="isInteractive"
	[displayDensity]="displayDensity"
	(hasData)="aggregateHasData($event, 'incomeByYear')"
></wasao-income-by-year>

<ng-container *ngIf="showExpenditureAmountsByCategory">
	<div *ngIf="!(displayDensity === 'print' && hasGovServices)" class="spacer"></div>
	<!-- This should only show for special purpose districts. Furthermore, this will be the ExpenditureObject view since
	 that is how Special Purpose Districts show "categories". -->
	<wasao-amounts-by-category
		[ngClass]="{ 'u-hidden': displayDensity === 'print' && hasGovServices}"
		[mcag]="mcag"
		[govTypeCode]="govTypeCode"
		[snapshotId]="snapshotId"
		categoryType="expenditures"
		[fundCategoryId]="fundCategories.proprietary"
		[trendDataClickAction]="trendDataClickAction"
		[hideWhenContentEmpty]="hideWhenContentEmpty"
		[isInteractive]="isInteractive"
		[displayDensity]="displayDensity"
		[smallAmountsPercentage]="0"
		(hasData)="aggregateHasData($event, 'expenditureObjects')"
	></wasao-amounts-by-category>
</ng-container>




