import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {combineLatest, Subscription} from 'rxjs';
import {environment} from 'environments/environment';
import {UserService} from 'app/shared/services/user.service';
import {User} from 'app/shared/models/user';
import {HttpClient} from '@angular/common/http';
import {TabService} from 'app/shared/services/tab.service';
import {LoggerService} from 'app/shared/services/logger.service';
import {PeerSetsService} from '../../shared/services/peer-sets.service';
import {ResourcesService} from '../resources/resources.service';
import {Resource} from '../../shared/models/resource';
import {DxMenuComponent} from 'devextreme-angular';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

class MenuItem {
	id: string | number;
	name: string;
	items: Array<MenuItem>;
}

@Component({
	selector: 'app-user-dropdown',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './user-dropdown.component.html',
	styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent implements OnInit, OnDestroy {

	@ViewChild(DxMenuComponent) dxMenu: DxMenuComponent;
	menuItems: Array<MenuItem>;
	private popupSubscription: Subscription;
	private activeSnapshotId: SnapshotId;
	private user: User;
	resourcesDataSource: Array<Resource>;
	resourceItems: Array<MenuItem>;

	constructor(
		private uiService: UserInterfaceService,
		private userService: UserService,
		private peerSetsService: PeerSetsService,
		private http: HttpClient,
		private tabService: TabService,
		private resourcesService: ResourcesService,
		private logger: LoggerService
	) {
		this.userService.user.subscribe(user => this.user = user);
		this.popupSubscription = this.uiService.accountSettings$.subscribe();
		this.tabService.tabs.subscribe(tabs => {
			const selectedTab = tabs.find(x => x.selected);
			if (selectedTab) {
				combineLatest(
					[this.userService.user,
						selectedTab.snapshotIdSubject]
				).subscribe(([user, snapShotId]) => {
					this.activeSnapshotId = snapShotId;

					// resource items menu logic
					this.resourcesService.getResources().subscribe(result => {
						this.resourcesDataSource = result;
						this.resourceItems = this.resourcesDataSource.map(e => {
							const item = {
								id: e.id,
								name: e.title,
								items: null
							};

							return item;
						});
						this.generateMenuItems(user);

					});

				});
			}

		});
	}

	ngOnInit() {

	}

	private generateMenuItems(user: User) {


		this.menuItems = [{
			id: 'welcome',
			name: this.getMenuTitle(user),
			items: [
				{
					id: 'account-settings',
					name: 'Account Settings',
					items: null
				}, {
					// }, {
					// 	id:   'help',
					// 	name: 'Help',
					// 	items: null
					// }, {
					id: 'data-extracts',
					name: 'Data Extracts',
					items: null
				}, {
					id: 'about',
					name: 'About',
					items: null
				}, {
					id: 'feedback',
					name: 'Feedback',
					items: null
				}, {
					id: 'interactive-filing-statistics',
					name: 'Interactive Filing Statistics',
					items: null
				}, {
					id: 'report-issue',
					name: 'Report an Issue',
					items: null
				}
			]
		}];

		// Adds resources link if there are resources to show
		if (this.resourceItems.length !== 0) {
			this.addToMenu({
				id: 'resources',
				name: 'Resources',
				items: this.resourceItems
			});
		}


		// TODO: enable once peer sets is completely implemented
		this.addToMenu({id: 'peer-sets-viewer', name: 'Your Saved Peer Sets', items: null});
		if (!this.user.isLocalUser) {
			// this.addToMenu({id: 'peer-sets-viewer', name: 'Your Saved Peer Sets', items: null});
		}
		if (environment.testUserHarness) {
			this.addToMenu({id: 'change-test-user', name: 'Change Test User', items: null});
		}
		if (user.claim && user.claim.contactID) {
			this.addToMenu({id: 'logout', name: 'Logout', items: null});
		}
		if (!user.claim || !user.claim.contactID) {
			this.addToMenu({id: 'login', name: 'Login', items: null});
		}
		if (user.hasAdminRole) {
			const adminFunctions = {
				id: 'admin',
				name: 'Admin',
				items: [
					{
						id: 'addToJobQueueSnapshots',
						name: 'Snapshot Data Pre-Caching ' +
							(typeof this.activeSnapshotId !== 'number' ?
								'(not available - select a published snapshot)' :
								'(using published snapshot #' + this.activeSnapshotId + ')'),
						items: [
							{id: 'generateSnapshotBars', name: 'Generate BARS Chart of Accounts Cache', items: null},
							{id: 'generateFilingStatuses', name: 'Generate Filing Statuses Cache', items: null},
							{id: 'generateDataExtracts', name: 'Generate Data Extracts Cache', items: null},
							{
								id: 'generateSnapshotIndicatorReports',
								name: 'Generate Financial Indicators Cache',
								items: null
							},
							{
								id: 'GenerateDebtServiceCoverageRatioExtracts',
								name: 'Generate Debt Coverage Ratio Extracts Cache',
								items: null
							},
						],
						disabled: typeof this.activeSnapshotId !== 'number'
					},
					{
						id: 'addToJobQueueLive',
						name: 'Live Data Pre-Caching (using live filings)',
						items: [
							{
								id: 'generateLiveBars',
								name: 'Manually Generate "Live" BARS Chart of Accounts Cache (refreshes nightly)',
								items: null
							},
							{
								id: 'generateLiveIndicatorReports',
								name: 'Manually Generate "Live" Financial Indicators Cache (refreshes nightly)',
								items: null
							},
							{
								id: 'GenerateLiveGlobalOperatingResultsWorksheets',
								name: 'Manually Generate "Live" Global Operating Results Worksheets Cache',
								items: null
							},
						]
					},
					{id: 'viewJobQueue', name: 'View Job Queue', items: null},
					{ id: 'shapefile-loader', name: 'Shapefile Loader', items: null },
					{ id: 'publish-lgcs-snapshot', name: 'Publish LGCS Snapshot', items: null },
					{ id: 'population-importer', name: 'Population Importer', items: null },
				]
			};
			this.addToMenu(adminFunctions, 1);
		}

		if (user.hasAdminRole || user.hasDeveloperRole) {
			const testFunctions = {
				id: 'tests',
				name: 'Tests',
				items: [
					{id: 'bars-rich-text', name: 'BARS Rich Text Demo', items: null}
				]
			};

			this.addToMenu({id: 'manage-resources', name: 'Manage Resources', items: null});
			this.addToMenu(testFunctions);
		}

	}

	/**
	 * Add a MenuItem to the exisitng top-level menu
	 * @param item
	 * @param index Defaults to inserting at end of array
	 */
	private addToMenu(item: MenuItem, index: number = null) {
		index = index === null ? this.menuItems[0].items.length : index;
		this.menuItems[0].items.splice(index, 0, item);
	}

	ngOnDestroy() {
		this.popupSubscription.unsubscribe();
	}

	itemClick(data) {
		if (typeof data.itemData.id === 'number') {
			this.uiService.resourceItem(data.itemData.id);
			return;
		}

		switch (data.itemData.id) {

			case 'interactive-filing-statistics':
				this.uiService.interactiveFilingStatistics();
				break;

			case 'manage-resources':
				this.uiService.manageResources();
				break;

			case 'report-issue':
				this.uiService.reportIssue();
				break;

			case 'bars-rich-text':
				this.uiService.showRichText();
				break;

			case 'account-settings':
				this.uiService.showAccountSettings();
				break;

			case 'peer-sets-viewer':
				this.peerSetsService.openViewer();
				break;

			case 'data-extracts':
				this.uiService.showDataExtracts();
				break;

			case 'about':
				this.uiService.showAbout();
				break;

			case 'feedback':
				this.uiService.showFeedback();
				break;

			case 'change-test-user':
				this.uiService.showTestUserHarness();
				break;

			case 'logout':
				this.userService.logout();
				break;

			case 'login':
				this.uiService.showLogin();
				break;

			case 'generateSnapshotBars':
				this.callAdminFunction('GenerateSnapshotBars', this.activeSnapshotId);
				break;

			case 'generateFilingStatuses':
				this.callAdminFunction('GenerateFilingStatuses', this.activeSnapshotId);
				break;

			case 'generateDataExtracts':
				this.callAdminFunction('GenerateDataExtracts', this.activeSnapshotId);
				break;

			case 'generateSnapshotIndicatorReports':
				this.callAdminFunction('GenerateSnapshotIndicatorReports', this.activeSnapshotId);
				break;

			case 'GenerateDebtServiceCoverageRatioExtracts':
				this.callAdminFunction('GenerateDebtServiceCoverageRatioExtracts', this.activeSnapshotId);
				break;

			case 'generateLiveBars':
				this.callAdminFunction('GenerateLiveBars');
				break;

			case 'generateLiveIndicatorReports':
				this.callAdminFunction('GenerateLiveIndicatorReports');
				break;

			case 'GenerateLiveGlobalOperatingResultsWorksheets':
				this.callAdminFunction('GenerateLiveGlobalOperatingResultsWorksheets');
				break;

			case 'viewJobQueue':
				window.open(`${environment.base}/hangfire/jobs/processing`);
				break;

			case 'shapefile-loader':
				this.uiService.showShapefileLoader();
				break;

			case 'publish-lgcs-snapshot':
				this.uiService.showPublishLgcsSnapshot();
				break;

			case 'population-importer':
				this.uiService.showPopulationImporter();
				break;

			default:
				return;
		}
	}

	/**
	 * Calls the appropriate admin function and shows a notification if there is any response.
	 */
	private callAdminFunction = (action: string, snapshotId?: SnapshotId) => {
		this.http.get(
			`${environment.base}/admin/${action}${snapshotId ? '/' + snapshotId : ''}`,
			{responseType: 'text'}
		).subscribe(result => {
			if (result) {
				this.uiService.showToast('info', result);
			}
		});
	};

	private getMenuTitle(user: User): string {
		if (user.claim && user.claim.fullName) {
			return `Welcome, ${user.claim.fullName}`;
		} else {
			return 'Menu';
		}
	}

}
