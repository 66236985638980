import {Component, OnInit} from '@angular/core';
import {ShapefileLoaderService} from '../shapefile-loader.service';

@Component({
	selector: 'app-maintain-vintage',
	templateUrl: './maintain-vintage.component.html',
	styleUrls: ['./maintain-vintage.component.scss']
})
export class MaintainVintageComponent implements OnInit {

	constructor(
		public service: ShapefileLoaderService
	) {
	}

	ngOnInit(): void {
	}

}
