import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, BehaviorSubject, Subject, Observable } from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import { polygon, multiPolygon } from '@turf/turf';
import { environment } from 'environments/environment';
import { LoggerService } from './logger.service';
import {ExternalCommonServiceApiService} from '../../modules/api/external-common-service-api/external-common-service-api.service';
import {LngLatBoundsLike, LngLatLike} from 'mapbox-gl';
import {ShareInProgress} from '../../modules/decorators/share-in-progress';

@Injectable({
	providedIn: 'root'
})
export class GeoDataService {

	// https://anthonylouisdagostino.com/bounding-boxes-for-all-us-states/
	static stateBounds: LngLatBoundsLike = [
		-124.763068,
		45.543541,
		-116.915989,
		49.002494
	];

	// fallback to production
	private endpoint = environment?.apis?.externalLGDataLegacy || `https://portal.sao.wa.gov/gisdata/api/v1`;
	private mapboxEndpoint = environment.apis.mapbox;
	private mapboxFeatureTypesOption = '&types=country,region,district,postcode,locality,place,address';
	// old, not accurate
	// private stateBounds = [
	// 	-124.8151,
	// 	45.5375,
	// 	-116.9,
	// 	49.0082
	// ];

	private apiKey = environment.mapboxAccessToken;
	private currentYear;
	private currentCountyYear;
	private countyCode = '06';
	private isInitialized = new BehaviorSubject<boolean>(false);
	private governmentTypeFills = {
		'03': '#959A14',
		'05': '#AA7942',
		'06': '#66AED4',
		'07': '#E8702D',
		'08': '#d35050',
		'09': '#999999',
		'22': '#1441BA',
		'64': '#a6c663'
	};
	private subscriptions: any = {};

	constructor(
		private http: HttpClient,
		private logger: LoggerService,
		private commonData: ExternalCommonServiceApiService
	) {
		this.subscriptions.constructorCombineLatest = combineLatest(this.getLatestBoundariesYear(), this.getLatestCountiesYear()).subscribe(([currentYear, currentCountyYear]) => {
			this.currentCountyYear = currentCountyYear;
			this.currentYear = currentYear;
			this.isInitialized.next(true);
		});
	}

	/**
	 * Gets the latest PropertyTaxBoundaries shapefiles year and caches value.
	 */
	private latestBoundariesYear = this.http.get(`${this.endpoint}/PropertyTaxBoundaries?$orderby=Year desc`)
		.pipe(
			map((response: any) => response.value[0].Year as number),
			shareReplay(1)
		);

	/**
	 * Gets the latest CensusCounties shapefiles year and caches value.
	 */
	private latestCountiesYear = this.http.get(`${this.endpoint}/CensusCounties?$orderby=Year desc`)
		.pipe(
			map((response: any) => response.value[0].Year as number),
			shareReplay(1)
		);

	/**
	 * @deprecated
	 */
	private getCountiesUrl(path = '') {
		return `${this.endpoint}/CensusCounties(${this.currentCountyYear})/${path}`;
	}

	/**
	 * @deprecated
	 */
	private getBoundariesUrl(path = '') {
		return `${this.endpoint}/PropertyTaxBoundaries(${this.currentYear})/${path}`;
	}

	/**
	 * @deprecated
	 */
	@ShareInProgress
	private getLatestBoundariesYear(): Observable<number> {
		return this.http.get(`${this.endpoint}/PropertyTaxBoundaries?$orderby=Year desc`).pipe(
			map((response: any) => response.value[0].Year)
		);
	}

	/**
	 * @deprecated
	 */
	@ShareInProgress
	private getLatestCountiesYear(): Observable<number> {
		return this.http.get(`${this.endpoint}/CensusCounties?$orderby=Year desc`).pipe(
			map((response: any) => response.value[0].Year)
		);
	}

	private processLayer(geoEntities: Array<any>, code: string) {
		// why do we have both a source and layers? yes
		const mapData = {
			source: null,
			layers: []
		};
		const isCounty = code === this.countyCode;
		const geoJson = { // can be typed as GEOJson
			type: 'FeatureCollection',
			features: []
		};
		// what is this for? Checking to see if we already processed this MCAG?
		const processedEntities = {};

		geoEntities.forEach((entity, i) => {
			const mcag = isCounty ? entity.Mcag : entity.MCAG;
			if (processedEntities.hasOwnProperty(mcag)) {
				return;
			}

			// wat
			processedEntities[mcag] = true;
			const geometryObj = isCounty ? entity.Geometry : entity.Geometry.GeoJSON;
			const options = {id: mcag};
			// add properties to each feature (title, mcag)
			const properties = {
				title: isCounty ? `${entity.CountyName} County` : entity.LookupName,
				mcag: isCounty ? entity.Mcag : entity.MCAG
			};
			// do some sort of transformation
			if (geometryObj.coordinates) {
				if (geometryObj.type === 'Polygon') {
					geoJson.features.push(polygon(geometryObj.coordinates, properties, options));
				}
				if (geometryObj.type === 'MultiPolygon') {
					geoJson.features.push(multiPolygon(geometryObj.coordinates, properties, options));
				}
			}
		});

		const fill = this.governmentTypeFills.hasOwnProperty(code)
			? this.governmentTypeFills[code]
			: '#D35050';

		// code is countyCode
		mapData.source = {
			id: code,
			data: geoJson,
			cluster: false,
			tolerance: 0,
			// clusterMaxZoom: 12,
			// clusterMinZoom: 6
		};
		mapData.layers.push({
			id: code,
			source: code,
			type: 'fill',
			paint: {
				'fill-color': fill,
				'fill-outline-color': fill,
				'fill-opacity': 0.3
			}
		});
		mapData.layers.push({
			id: `${code}-lines`,
			source: code,
			type: 'line',
			paint: {
				'line-color': isCounty ? '#000000' : fill,
				'line-width': 1,
				'line-opacity': 0.2
			}
		});
		mapData.layers.push({
			id: `${code}-select`,
			source: code,
			type: 'fill',
			paint: {
				'fill-color': fill,
				'fill-outline-color': fill,
				'fill-opacity': 0.5
			}
		});
		mapData.layers.push({
			id: `${code}-lines-select`,
			source: code,
			type: 'line',
			paint: {
				'line-color': isCounty ? '#000000' : fill,
				'line-width': 2,
				'line-opacity': 0.7
			}
		});
		return mapData;
	}

	getLayerFromGovernmentTypeCode(govTypeCode: string): Subject<any> {
		const subject = new Subject();
		this.subscriptions.isInitialized = this.isInitialized.subscribe((isInit) => {
			if (isInit) {
				if (govTypeCode === this.countyCode) {
					this.subscriptions.get = this.http.get(this.getCountiesUrl('Shapes?'))
						.pipe(map((response: any) => this.processLayer(response.value, govTypeCode)))
						.subscribe((layer) => {
							subject.next(layer);
							this.cleanup();
						});
				}
				else {
					this.subscriptions.get = this.http.get(this.getBoundariesUrl(`Boundaries?$expand=Geometry,Centroid&$filter=GovernmentTypeCode eq '${govTypeCode}'`))
						.pipe(map((response: any) => this.processLayer(response.value, govTypeCode)))
						.subscribe((layer) => {
							subject.next(layer);
							this.cleanup();
						});
				}
			}
		});
		return subject;
	}

	@ShareInProgress
	getLayerFromMcag(mcag: string): Observable<any> {
		// We first query CommonData for govTypeCode since LGExternalData does not contains snapshots
		return this.commonData.getEntityDetails(mcag).pipe(
			switchMap(entity => {
				// get the latest year from the appropriate endpoint
				const yearSource = entity.GovTypeCode === this.countyCode
					? this.latestCountiesYear
					: this.latestBoundariesYear;
				return yearSource.pipe(
					switchMap(year => {
						// then we can finally issue the call to get the endpoint
						const url = entity.GovTypeCode === this.countyCode
							? `${this.endpoint}/CensusCounties(${year})/Shapes?$filter=MCAG eq '${mcag}'`
							: `${this.endpoint}/PropertyTaxBoundaries(${year})/Boundaries?$expand=Geometry,Centroid&$filter=Mcag eq '${mcag}'`;
						return this.http.get(url).pipe(
							map((response: any) => this.processLayer(response.value, entity.GovTypeCode))
						);
					})
				);
			})
		);
	}

	@ShareInProgress
	getCoordinates(address: string): Observable<[number, number]> {
		return this.http.get(`${this.mapboxEndpoint}${address}.json?bbox=${GeoDataService.stateBounds}&country=US${this.mapboxFeatureTypesOption}&limit=10&access_token=${this.apiKey}`)
			.pipe(map((response: any) => {
				const result = response.features.find((feat) => feat.place_name.indexOf('Washington') > -1);
				if (result) {
					return result.center;
				}
				return [];
			}));
	}

	/** Does not work right now; needs Enterprise mapbox account to execute batch requests */
	getBatchCoordinates(addresses: Array<string>): Observable<any> {
		return this.http.get(`${this.mapboxEndpoint}${addresses.join(';')}.json?bbox=${GeoDataService.stateBounds}&country=US${this.mapboxFeatureTypesOption}&limit=10&access_token=${this.apiKey}`);
	}

	@ShareInProgress
	getAddressList(address: string): Observable<Array<number>> {
		return this.http.get(`${this.mapboxEndpoint}${address}.json?bbox=${GeoDataService.stateBounds}&country=US${this.mapboxFeatureTypesOption}&limit=10&access_token=${this.apiKey}`)
			.pipe(map((response: any) => response.features.filter((feat) => feat.place_name.indexOf('Washington') > -1)
				.sort((a, b) => {
					if (a.place_type[0] === b.place_type[0]) {
						if (a.relevance > b.relevance) { return -1; }
						if (b.relevance > a.relevance) { return 1; }
					} else {
						if (a.place_type[0] === 'place') { return -1; }
						if (b.place_type[0] === 'place') { return 1; }
					}
					return 0;
				})));
	}

	getIntersectingGovernments(coords: LngLatLike): Subject<any> {
		const subject = new Subject();
		const subjects = [];
		const lat = coords[1];
		const lon = coords[0];
		subjects.push(this.http.get(this.getCountiesUrl(`Shapes/Contains(Latitude=${lat},Longitude=${lon})`)));
		subjects.push(this.http.get(this.getBoundariesUrl(`Boundaries/Contains(Latitude=${lat},Longitude=${lon})`)));
		this.subscriptions.combineLatest = combineLatest(...subjects).subscribe(([countyResults, otherResults]: [any, any]) => {
			const county = countyResults.value[0];
			const result = [{
				mcag: county.Mcag,
				lookupName: `${county.CountyName} County`,
				countyId: county.CountyId,
				govTypeCode: this.countyCode
			}];
			subject.next(result.concat(otherResults.value.map((gov) => ({
				mcag: gov.MCAG,
				lookupName: gov.LookupName,
				countyId: gov.CountyId,
				govTypeCode: gov.GovernmentTypeCode
			}))));
			this.cleanup();
		});
		return subject;
	}

	getGovernmentTypesThatHaveShapefileData(): Subject<Array<string>> {
		const subject = new Subject<Array<string>>();
		this.subscriptions.isInitialized = this.isInitialized.subscribe((isInit) => {
			if (isInit) {
				this.subscriptions.get = this.http.get(this.getBoundariesUrl('Boundaries?$filter=GovernmentTypeCode ne null&$apply=groupby((GovernmentTypeCode))'))
					.subscribe((result: any) => {
						const govTypes = result.value.map((entity) => entity.GovernmentTypeCode);
						govTypes.push(this.countyCode);
						subject.next(govTypes);
						this.cleanup();
					});
			}
		});
		return subject;
	}

	private cleanup = () => {
		for (const sub in this.subscriptions) {
			if (this.subscriptions.hasOwnProperty(sub)) {
				this.logger.info(`geo-data.service unsubscribing... ${sub}`);
				try {
					this.subscriptions[sub].unsubscribe();
				} catch (e) {
					this.logger.info(`geo-data.service issues unsubscribing ${sub}:`, e);
				}
			}
		}
	}
}
