import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {IndicatorService} from './indicator.service';
import {LoggerService} from './logger.service';
import {ExpenditureLevel} from '../models/expenditure-level';
import {chain} from 'lodash';
import {SnapshotService} from './snapshot.service';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {ShareInProgress} from '../../modules/decorators/share-in-progress';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';
import {ODataCollectionResult} from '../../modules/api/odata-collection-result';
import {LocalGovernment} from '../../modules/api/fit-api/models/local-government';
import {GovernmentMetric} from '../../modules/api/fit-api/models/government-metric';
import {FilingStatus} from '../../modules/api/fit-api/models/filing-status';

@Injectable({
	providedIn: 'root'
})
/**
 * This service is DEPRECATED. Please do not use.
 * @deprecated
 */
export class GovernmentInfoService {
	private apiUrl: string;
	private subscriptions: any = {};

	constructor(
		private http: HttpClient,
		private indicatorService: IndicatorService,
		private logger: LoggerService,
		private snapshotService: SnapshotService,
		private apiService: FitApiService,
	) {
		this.apiUrl = environment.apis.app;
	}

	getGovernmentInfo(snapshot, mcag, years: Array<number> = null) {
		const snapshotId  = snapshot.id;
		const currentYear = snapshot.barsYearUsed;
		if (years === null) {
			years    = [];
			years[0] = currentYear - 4;
			years[1] = currentYear;
		}
		const subject               = new Subject();
		this.subscriptions.forkJoin = forkJoin(
			this.getLocalGovernment(snapshotId, mcag),
			this.getGovernmentMetrics(snapshotId, mcag, currentYear),
			this.getFilingStatus(snapshotId, mcag),
			this.getAnnualFilingIndicatorGroups(snapshotId, mcag, years)
		).subscribe(([government, governmentMetrics, filingStatuses, indicatorGroups]) => {
			const result    = {
				government,
				governmentMetrics,
				filingStatuses,
				indicatorGroups,
				expenditureLevel:     government.govTypeCode === '06' || government.govTypeCode === '07'
					                      ? ExpenditureLevel.BasicAccount
					                      : ExpenditureLevel.ExpenditureObject,
				thisYearFilingStatus: null,
				yearsFiledOnTime:     0,
				financialSummary:     null,
				financialData:        null,
				hasFinancialData:     false
			};
			let yearsOnTime = 0;
			if (result.filingStatuses.length) {
				result.hasFinancialData = true;
				chain(result.filingStatuses)
					.sortBy((s) => s.year)
					.reverse()
					.forEach((s, i) => {
						if (s.daysLate === null && s.dateSubmitted && yearsOnTime === i) {
							yearsOnTime++;
						}
						if (s.year === currentYear) {
							result.thisYearFilingStatus = s;
						}
					}).value();

				result.yearsFiledOnTime = result.thisYearFilingStatus.dateSubmitted
					? yearsOnTime
					: 0;

				this.subscriptions.getFinancialSummary = this.getFinancialSummary(snapshotId, result.government.govTypeCode, government.mcag).subscribe((financialSummary) => {
					result.financialSummary = financialSummary;
					result.financialData    = this.calculateFinancialData(snapshot, financialSummary, result.expenditureLevel);

					subject.next(result);
					this.cleanup();
				});
			}
			else {
				subject.next(result);
				this.cleanup();
			}
		});
		return subject;
	}

	@ShareInProgress
	private getLocalGovernment(snapshotId, mcag) {
		const url     = this.apiService.getAnnualFilingRoute(snapshotId, `/LocalGovernments?$filter=MCAG eq '${mcag}'`);
		const request = this.http.get<ODataCollectionResult<LocalGovernment>>(url);
		return request.pipe(map((result) => result.value[0]));
	}

	@ShareInProgress
	private getGovernmentMetrics(snapshotId, mcag, currentYear) {
		const url     = this.apiService.getAnnualFilingRoute(snapshotId, `/GovernmentMetrics?$filter=MCAG eq '${mcag}' and year eq ${currentYear}`);
		const request = this.http.get<ODataCollectionResult<GovernmentMetric>>(url);

		return request.pipe(map((result) => result.value[0]));
	}

	// deprecated, moved to FitApiService
	@ShareInProgress
	private getFilingStatus(snapshotId, mcag) {
		const url     = this.apiService.getAnnualFilingRoute(snapshotId, `/FilingStatuses?$filter=(mcag eq '${mcag}')`);
		const request = this.http.get<ODataCollectionResult<FilingStatus>>(url);
		return request.pipe(map((result) => result.value));
	}

	public getAnnualFilingIndicatorGroups(snapshotId, mcag, years) {
		return this.indicatorService.getAnnualFilingIndicatorGroups(mcag, snapshotId, years[0], years[1]);
	}

	public getSchoolsIndicatorGroups(mcag, years) {
		return this.indicatorService.getSchoolsIndicatorGroups(mcag, years[0], years[1]);
	}

	/**
	 * Gets the financialSummary endpoint with the appropriate expenditureLevel option, based on govTypeCode
	 * @param snapshotId required
	 * @param govTypeCode Filters results as well as determines ExpenditureLevel to send to query
	 * @param mcag Filters based on government
	 */
	@ShareInProgress
	getFinancialSummary(snapshotId: SnapshotId, govTypeCode: string, mcag: string = null, filters: Array<string> = null) {
		const mcagSegment      = mcag ? `,mcag='${mcag}'` : '';
		const expenditureLevel = ['06', '07'].includes(govTypeCode)
			? ExpenditureLevel.BasicAccount
			: ExpenditureLevel.ExpenditureObject;
		const filterString     = filters
			? `?$filter=${filters.join(' and ')}`
			: '';
		const url              = this.apiService.getAnnualFilingRoute(snapshotId, `/FinancialSummaries/Options(revLevel='BasicAccount',expLevel='${expenditureLevel}',govType='${govTypeCode}'${mcagSegment})${filterString}`);
		const request          = this.http.get<ODataCollectionResult<any>>(url);
		return request.pipe(map((result) => result.value));
	}

	private calculateFinancialData(snapshot, financialSummary, expenditureLevel) {
		const financialData = {
			financesAtAGlance:    {
				year:         0,
				list:         [],
				revenues:     {
					amount:    '(no data)',
					sparkLine: [],
				},
				expenditures: {
					amount:    '(no data)',
					sparkLine: []
				}
			},
			governmentalServices: {
				list:         [],
				revenues:     {
					amount:              0,
					smallValueThreshold: 0,
					pieChart:            [],
					sparkLine:           []
				},
				expenditures: {
					amount:               0,
					smallValueThreshold:  0,
					pieChart:             [],
					sparkLine:            [],
					isExpenditureObjects: expenditureLevel === 'ExpenditureObject'
				}
			},
			enterpriseServices:   {
				list:         [],
				revenues:     {
					amount:    0,
					pieChart:  [],
					sparkLine: [],
				},
				expenditures: {
					amount:               0,
					pieChart:             [],
					sparkLine:            [],
					isExpenditureObjects: expenditureLevel === 'ExpenditureObject'
				},
				chart:        []
			}
		};

		let year = 0;
		financialSummary.forEach((row) => {
			if (row.year > year) {
				year = row.year;
			}
		});
		financialData.financesAtAGlance.year = year;

		// Process each row
		financialSummary.forEach((row) => {
			try {
				// Determine if revenue or expenditure
				let type = null;
				if (row.fsSectionId === 20) {
					type = 'revenues';
				}
				else if (row.fsSectionId === 30) {
					type = 'expenditures';
				}

				// Make sure it's either a revenue or an expenditure, and has an amount
				if (type && row.amount) {
					this.populateSparklineData(row, type, financialData.financesAtAGlance);

					if (row.fundCategoryId === 1) {
						this.populateSparklineData(row, type, financialData.governmentalServices);
					}
					else if (row.fundCategoryId === 2) {
						this.populateSparklineData(row, type, financialData.enterpriseServices);
					}

					// Latest year logic
					if (row.year === year) {
						// The initial state of financesAtAGlance[type].amount is a string (no data)
						// so make sure that we set it to a number when a value comes along
						if (row.amount && typeof financialData.financesAtAGlance[type].amount === 'string') {
							financialData.financesAtAGlance[type].amount = row.amount;
						}
						else if (row.amount && typeof financialData.financesAtAGlance[type].amount === 'number') {
							// otherwise just add it to the existing number
							financialData.financesAtAGlance[type].amount += row.amount;
						}

						const idProperty  = (expenditureLevel === ExpenditureLevel.BasicAccount || type === 'revenues')
							? 'barsAccountId'
							: 'expenditureObjectId';
						const descriptors = (expenditureLevel === ExpenditureLevel.BasicAccount || type === 'revenues')
							? snapshot.detail.accountDescriptors
							: snapshot.detail.expenditureObjects;

						// Lookup label name
						const descriptor = descriptors.find(x => x.id === row[idProperty]);
						const label      = descriptor ? descriptor.name : '';

						// Ensure the label has been added to the list
						if (!financialData.financesAtAGlance.list.find(x => x === label)) {
							financialData.financesAtAGlance.list.push(label);
						}

						// Add governmental services data
						if (row.fundCategoryId === 1) {

							if (!financialData.governmentalServices.list.find(x => x === label)) {
								financialData.governmentalServices.list.push(label);
							}

							financialData.governmentalServices[type].amount += row.amount;
							financialData.governmentalServices[type].smallValueThreshold = financialData.governmentalServices[type].amount * 0.10;

							// Pie chart data
							this.populatePieChartData(row, type, financialData.governmentalServices, idProperty, label);
						}
						// Add proprietary data
						else if (row.fundCategoryId === 2) {
							// Add the sum data
							financialData.enterpriseServices[type].amount += row.amount;

							// Ensure the label has been added to the list
							if (!financialData.enterpriseServices.list.find(x => x === this.getRowFundName(row))) {
								financialData.enterpriseServices.list.push(this.getRowFundName(row));
							}

							// Add the chart data
							this.populateChartData(row, type, financialData.enterpriseServices);

							// Pie chart data
							this.populatePieChartData(row, type, financialData.enterpriseServices, idProperty, label);
						}
					}
				}
			} catch (e) {
				this.logger.warn(`Single government financial data calculation error: ${e}`);
			}
		});

		// Sort data
		const sortAmount = (a, b) => {
			if (a.amount > b.amount) {
				return -1;
			}
			if (a.amount < b.amount) {
				return 1;
			}
			return 0;
		};
		financialData.governmentalServices.revenues.pieChart.sort(sortAmount);
		financialData.governmentalServices.expenditures.pieChart.sort(sortAmount);
		financialData.enterpriseServices.chart                   = chain(financialData.enterpriseServices.chart)
			.sortBy((c) => c.label.length)
			.reverse()
			.value();
		financialData.governmentalServices.revenues.pieChart     = chain(financialData.governmentalServices.revenues.pieChart)
			.sortBy((a) => a.amount)
			.reverse()
			.value();
		financialData.governmentalServices.expenditures.pieChart = chain(financialData.governmentalServices.expenditures.pieChart)
			.sortBy((a) => a.amount)
			.reverse()
			.value();

		return financialData;
	}

	private populateSparklineData = (row, type, section) => {
		// Non-year-specific calculations
		let gs = section[type].sparkLine.find(x => x.year === row.year);
		if (!gs) {
			section[type].sparkLine.push(gs = {
				year:   row.year,
				amount: 0
			});
		}

		gs.amount += row.amount;
	}

	private populatePieChartData = (row, type, section, idProperty, label) => {
		const f = section[type].pieChart.find(x => x[idProperty] === row[idProperty]);

		if (!f) {

			// Pie chart representation of data
			section[type].pieChart.push({
				label:        label,
				amount:       row.amount,
				[idProperty]: row[idProperty]
			});
		}
		else {
			f.amount += row.amount;
		}
	}

	populateChartData = (row, type, section) => {
		let e = section.chart.find(x => x.label === this.getRowFundName(row));

		if (!e) {
			section.chart.push(e = {
				label:        this.getRowFundName(row),
				revenues:     0,
				expenditures: 0,
			});
		}

		e[type] += row.amount;
	}

	private getRowFundName = (row) => {
		return row.latestFundName || row.fund;
	}

	private cleanup = () => {
		for (const sub in this.subscriptions) {
			if (this.subscriptions.hasOwnProperty(sub)) {
				this.logger.info(`govt-info.service unsubscribing... ${sub}`);
				try {
					this.subscriptions[sub].unsubscribe();
				} catch (e) {
					this.logger.info(`govt-info.service issues unsubscribing ${sub}:`, e);
				}
			}
		}
	}
}
