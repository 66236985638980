<div class="header">
	<div class="left">
		<h2>
			Government Type Profile:
			<br/>
			<span>
				{{ liveGovTypeDetails?.GovTypeDesc }}
			</span>
		</h2>
	</div>

	<div class="right">
		<dx-button
			text="Generate Tear Sheet"
			type="default"
			(onClick)="navigateToTearSheet()">
		</dx-button>
	</div>

</div>

<h3>About This Government Type</h3>
<wasao-government-type-information
	class="section"
	[snapshotId]="snapshotId"
	[govTypeCode]="govTypeCode"
	[govClickAction]="clickActions.govProfile"
	[showAuditLink]=true
	[filerReportClickAction]="clickActions.filerReport"
	[financialsDatasetSource]="financialsDatasetSource"
></wasao-government-type-information>

<ng-container *ngIf="financialsDatasetSource === 'SAOAnnualFiling'">
	<h3>
		Annual Filing Data
		<wasao-hint
			icon="infoCircle"
			class="u-color-neutral-white u-margin-left-small"
			hintInformation="annualFilingData"
		></wasao-hint>
	</h3>
	<wasao-annual-filing-summary
		class="section"
		[snapshotId]="snapshotId"
		[govTypeCode]="govTypeCode"
		[reportTypeClickAction]="clickActions.summaryReport"
	></wasao-annual-filing-summary>

	<h3>Finances at a Glance</h3>
	<wasao-finances-at-a-glance
		class="section"
		[snapshotId]="snapshotId"
		[govTypeCode]="govTypeCode"
		[reportTypeClickAction]="clickActions.summaryReport"
	></wasao-finances-at-a-glance>

	<ng-container *ngIf="canHaveIndicators">
			<h3 [ngClass]="{ 'u-hidden': !hasDataChildren.financialHealthIndicatorStatistics }">Financial Health Indicators
				<wasao-hint
					icon="infoCircle"
					class="u-color-neutral-white u-margin-left-small"
					hintInformation="financialHealthIndicators"
				></wasao-hint>
			</h3>

			<wasao-financial-health-indicator-statistics
				[ngClass]="{ 'u-hidden': !hasDataChildren.financialHealthIndicatorStatistics }"
				class="section"
				(hasData)="this.hasDataChildren.financialHealthIndicatorStatistics = $event"
				[snapshotId]="snapshotId"
				[govTypeCode]="govTypeCode"
				[drilldownClickAction]="clickActions.indicatorReport"
			></wasao-financial-health-indicator-statistics>

	</ng-container>

	<h3 [ngClass]="{ 'u-hidden': !hasDataChildren.governmentalServices }">Governmental Services</h3>
	<wasao-governmental-services
		[ngClass]="{ 'u-hidden': !hasDataChildren.governmentalServices }"
		class="section"
		[govTypeCode]="govTypeCode"
		[snapshotId]="snapshotId"
		[trendDataClickAction]="clickActions.summaryReport"
		(hasData)="this.hasDataChildren.governmentalServices = $event"
		hideWhenContentEmpty=true
	></wasao-governmental-services>

	<h3 [ngClass]="{ 'u-hidden': !hasDataChildren.enterpriseServices }">Enterprise Services</h3>
	<wasao-enterprise-services
		[ngClass]="{ 'u-hidden': !hasDataChildren.enterpriseServices }"
		class="section"
		[govTypeCode]="govTypeCode"
		[snapshotId]="snapshotId"
		[trendDataClickAction]="clickActions.summaryReport"
		(hasData)="this.hasDataChildren.enterpriseServices = $event"
		hideWhenContentEmpty=true
	></wasao-enterprise-services>
</ng-container>
