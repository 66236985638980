<div class="container">
	<div class="split">
		<ng-container *ngIf="dataFetchFailed else content">
			<p>Data could not be loaded.</p>
		</ng-container>
		<ng-template #content>
			<dl>
				<dt>Legal name:</dt>
				<dd>{{ infoLive?.LegalName }}</dd>

				<ng-container *ngIf="infoLive?.Entity_Dba">
					<dt>Doing Business As:</dt>
					<dd>{{ infoLive?.Entity_Dba }}</dd>
				</ng-container>


				<dt>Address:</dt>
				<dd>{{ address }}</dd>

				<ng-container *ngIf="infoLive?.EntityExtendedProperty?.Website">
					<dt>Website:</dt>
					<dd>
						<a [href]="'http://' + infoLive.EntityExtendedProperty.Website"
						   target="_blank">{{ infoLive.EntityExtendedProperty.Website }}</a>
					</dd>
				</ng-container>
			</dl>

			<dl>
				<dt>Government Type:</dt>
				<dd *ngIf="infoLive?.GovType; else govTypeEmpty">
					<button *ngIf="isInteractive && govTypeClickAction; else govTypeText"
							(click)="handleGovTypeClick()">{{ infoLive?.GovType?.GovTypeDesc }}</button>
					<ng-template #govTypeText>{{ infoLive?.GovType?.GovTypeDesc }}</ng-template>
					<wasao-hint *ngIf="isInteractive" icon="infoCircle">
						{{ infoLive?.GovType?.ShortDescription }}
					</wasao-hint>
				</dd>
				<ng-template #govTypeEmpty>&nbsp;</ng-template>

				<dt>Basis of Accounting:</dt>
				<dd>
					<div *ngIf="infoLive?.EntityExtendedProperty?.AcctBasisType?.AcctBasisTypeDesc && infoLive?.EntityExtendedProperty?.AcctBasisType?.AcctBasisTypeId !== 5;
				      then canShowAcctBasis else cannotShowAcctBasis"></div>
					<ng-template #canShowAcctBasis>
						{{ infoLive.EntityExtendedProperty.AcctBasisType.AcctBasisTypeDesc }}
						<wasao-hint
							*ngIf="isInteractive && accountingBasis"
							icon="infoCircle"
							[hintInformation]="accountingBasis"
						>
						</wasao-hint>
					</ng-template>
					<ng-template #cannotShowAcctBasis>
						<i>not available</i>
					</ng-template>
				</dd>

				<dt>Fiscal year end:</dt>
				<dd>{{ infoLive?.FiscalYearEnd }}</dd>

				<dt *ngIf="infoLive?.EstablishedDate">Date Established:</dt>
				<dd *ngIf="infoLive?.EstablishedDate">{{ infoLive.EstablishedDate | date:'longDate' }}</dd>

				<dt>Status:</dt>
				<dd>{{ infoLive?.IsActive === 'Y' ? 'Active' : 'Inactive' }}</dd>

				<dt *ngIf="population">Population:</dt>
				<dd *ngIf="population">{{ population.total | number }}
					<wasao-hint *ngIf="isInteractive" icon="infoCircle">Provided by OFM.</wasao-hint>
				</dd>

				<ng-container *ngIf="infoLive?.EntityExtendedProperty?.GoverningBodyType">
					<dt>Governing Body Type:</dt>
					<dd>{{ infoLive?.EntityExtendedProperty?.GoverningBodyType.GoverningBodyTypesDesc }}</dd>
				</ng-container>

				<ng-container *ngIf="infoLive?.EntityExtendedProperty?.GoverningBodySize">
					<dt>Governing Body Size:</dt>
					<dd>{{ infoLive?.EntityExtendedProperty?.GoverningBodySize }}</dd>
				</ng-container>

				<ng-container *ngIf="infoLive?.EntityExtendedProperty?.GoverningBodyOrigin">
					<dt>Governing Body Origin:</dt>
					<dd>{{ infoLive?.EntityExtendedProperty?.GoverningBodyOrigin.GoverningBodyOriginsDesc }}</dd>
				</ng-container>

				<ng-container *ngIf="treasurer">
					<dt>Treasurer:</dt>
					<dd>
						<button *ngIf="isInteractive" (click)="handleGovClick(treasurer.Mcag)">{{ treasurer.LegalName }}</button>
						<span *ngIf="!isInteractive">{{ treasurer.LegalName }}</span>
					</dd>
				</ng-container>

				<!-- filing status for tear sheet -->
				<ng-container *ngIf="hasAnyFilingStatuses && infoLive?.EntityExtendedProperty?.GoverningBodyOrigin && displayDensity === 'print'">
					<dt>Filing Status <br/>(for fiscal year {{ displayFilingStatus?.year }}):</dt>
					<dd>
						<span *ngIf="displayFilingStatus?.daysLate === null && displayFilingStatus?.dateSubmitted">
							Filed on time
						</span>
						<span *ngIf="displayFilingStatus?.daysLate > 0">
							Filed {{ displayFilingStatus?.daysLate }} {{ displayFilingStatus?.daysLate === 1 ? 'day' : 'days' }} late
						</span>
						<span *ngIf="displayFilingStatus?.dateSubmitted === null">
							Has not filed <br/>
							(as of {{ snapshot.id === 'live' ? 'today' : snapshot?.dateCreated | date:'longDate' }}) <br/>
							Filing deadline {{ displayFilingStatus?.maxAllowedSubmitDate | date:'longDate' }}
						</span>

						<div *ngIf="displayFilingStatus?.dateSubmitted">
							Most recent data filed on {{ displayFilingStatus?.dateSubmitted | date:'longDate' }}
						</div>
					</dd>
				</ng-container>

				<ng-container *ngIf="displayedGovernmentType?.financialsDatasetSource === 'OSPI'">
					<dt>Average Enrollment:</dt>
					<dd>{{enrollmentAverage | number: '1.0-0'}} ({{schoolYear}} Headcount)
						<wasao-hint
							*ngIf="isInteractive"
							icon="infoCircle"
							[hintInformation]="'enrollment'"
						>
						</wasao-hint>
					</dd>
				</ng-container>
				<ng-container *ngIf="componentUnitOf">
					<dt>Component Unit Of:</dt>
					<dd>
						<button (click)="handleGovClick(componentUnitOf.Mcag)">{{ componentUnitOf.LegalName }}</button>
					</dd>
				</ng-container>
			</dl>
						<ng-container *ngIf="infoLive?.IsActive && hasAnyFilingStatuses && displayDensity !== 'print'">
							<h4>Filing Status (for fiscal year {{ displayFilingStatus?.year }}):</h4>
							<dl class="filing-status">
								<dt class="u-h4 u-padding-right-tiny u-text-align-right">
									<fa-icon
										[icon]="displayFilingStatus?.dateSubmitted == null ? icons.times : icons.check"
										[class.u-color-status-okay]="displayFilingStatus?.daysLate == null && displayFilingStatus?.dateSubmitted"
										[class.u-color-status-cautionary]="displayFilingStatus?.daysLate != null"
										[class.u-color-status-concerning]="displayFilingStatus?.dateSubmitted == null"
									></fa-icon>
								</dt>
								<dd>
									<span *ngIf="displayFilingStatus?.daysLate === null && displayFilingStatus?.dateSubmitted">
										Filed on time
									</span>
									<span *ngIf="displayFilingStatus?.daysLate > 0">
										Filed {{ displayFilingStatus?.daysLate }} {{ displayFilingStatus?.daysLate === 1 ? 'day' : 'days' }} late
									</span>
									<span *ngIf="displayFilingStatus?.dateSubmitted == null">
										Has not filed (as of {{ snapshot.id === 'live' ? 'today' : snapshot?.dateCreated | date:'longDate' }})
									</span>
								</dd>
								<dt class="u-h4 u-padding-right-tiny u-text-align-right">
									<fa-icon [icon]="icons.calendar"></fa-icon>
								</dt>
								<dd>
									<span *ngIf="displayFilingStatus?.dateSubmitted">
										Most recent data filed on {{ displayFilingStatus?.dateSubmitted | date:'longDate' }}
										<span
											*ngIf="!infoLive?.EntityExtendedProperty?.AcctBasisType?.AcctBasisTypeDesc || infoLive?.EntityExtendedProperty?.AcctBasisType?.AcctBasisTypeDesc.indexOf(displayFilingStatus.filingBasis) === -1">
											({{displayFilingStatus.filingBasis}} Basis
											<wasao-hint
												*ngIf="isInteractive"
												icon="infoCircle"
												[hintInformation]="displayFilingStatus.filingBasis === 'Cash' ? 'cashAccounting' : 'gaapAccounting'"
											></wasao-hint>)
										</span>
									</span>
									<span *ngIf="displayFilingStatus?.dateSubmitted === null">Filing deadline {{ displayFilingStatus?.maxAllowedSubmitDate | date:'longDate' }}</span>
								</dd>
							</dl>
						</ng-container>

			<div class="audit-reports" *ngIf="isInteractive">
				<fa-icon [icon]="icons.history"></fa-icon>
				<a [href]="historicalAuditLink" target="_blank">Browse historical audit reports</a>
			</div>

			<div *ngIf="!(displayDensity === 'print')">
				<!-- Ospi financial dataset logic -->
				<ng-container *ngIf="displayedGovernmentType?.financialsDatasetSource === 'OSPI'">
					<!--TODO remove conditional when making schools public access-->
					<ng-container *ngIf="canShowFinancials" >
						<p>Washington school districts' financial data
							is gathered by the Office of Superintendent of Public Instruction (OSPI), provided to the State
							Auditor's Office on a yearly basis, and extracts are then loaded into FIT.</p>
						<p>
							<a href="https://www.k12.wa.us/safs/Reports.asp">
								Visit OSPI to see the source data for Schools' Apportionment, Enrollment, and Fiscal Reports
								<wasao-hint showOn="hover" [icon]="icons.externalLink">Navigate
									to OSPI
								</wasao-hint>
							</a>
						</p>
					</ng-container>
					<ng-container *ngIf="!canShowFinancials">
						<h4>School District data is offsite</h4>

						<p>Washington school districts' financial data is gathered by the Office of Superintendent of Public
							Instruction (OSPI).

							<a href="https://www.k12.wa.us/safs/Reports.asp">
								Visit OSPI and select a district to see its financial data
								<wasao-hint showOn="hover" [icon]="icons.externalLink">Navigate
									to OSPI
								</wasao-hint>
							</a>
						</p>
					</ng-container>
				</ng-container>

			</div>

			<!-- Governments Without FIT Financials -->
			<ng-container *ngIf="displayedGovernmentType?.financialsDatasetSource === null">
				<h4>Financial data is not available for this government type</h4>
			</ng-container>

		</ng-template>
		<wasao-loading [isLoading]="isLoading"></wasao-loading>
	</div>

	<div class="split">
		<wasao-map
			[mcag]="mcag"
			[isInteractive]="isInteractive"
			[displayDensity]="displayDensity"
		></wasao-map>
	</div>
</div>

<div *ngIf="displayDensity === 'print'">
	<!-- Ospi financial dataset logic tear sheet -->
	<ng-container *ngIf="displayedGovernmentType?.financialsDatasetSource === 'OSPI'">
		<!--TODO remove conditional when making schools public access-->
		<ng-container *ngIf="canShowFinancials" >
			<p class="school-tear-sheet-format">Washington school districts' financial data
				is gathered by the Office of Superintendent of Public Instruction (OSPI), provided to the State
				Auditor's Office on a yearly basis, and extracts are then loaded into FIT.</p>
			<p class="school-tear-sheet-format">
				<a href="https://www.k12.wa.us/safs/Reports.asp">
					Visit OSPI to see the source data for Schools' Apportionment, Enrollment, and Fiscal Reports
					<wasao-hint *ngIf="displayDensity !== 'print'" showOn="hover" [icon]="icons.externalLink">Navigate
						to OSPI
					</wasao-hint>
				</a>
			</p>
		</ng-container>

		<ng-container *ngIf="!canShowFinancials">
			<h4>School District data is offsite</h4>

			<p>Washington school districts' financial data is gathered by the Office of Superintendent of Public
				Instruction (OSPI).

				<a href="https://www.k12.wa.us/safs/Reports.asp">
					Visit OSPI and select a district to see its financial data
				</a>
			</p>
		</ng-container>
	</ng-container>

</div>


