import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingModule} from '../../ui/loading/loading.module';
import {DxChartModule, DxPopupModule, DxSelectBoxModule} from 'devextreme-angular';
import {AnnualFilingStatusTrendComponent} from './annual-filing-status-trend.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AnnualFilingExplanationModule} from '../annual-filing-explanation/annual-filing-explanation.module';
import {AnnualFilingFootnotesModule} from '../annual-filing-footnotes/annual-filing-footnotes.module';
import {AnnualFilingFooterModule} from '../annual-filing-footer/annual-filing-footer.module';


@NgModule({
	declarations: [
		AnnualFilingStatusTrendComponent
	],
	imports: [
		CommonModule,
		LoadingModule,
		DxPopupModule,
		DxChartModule,
		DxSelectBoxModule,
		FontAwesomeModule,
		AnnualFilingExplanationModule,
		AnnualFilingFootnotesModule,
		AnnualFilingFooterModule
	],
	exports: [
		AnnualFilingStatusTrendComponent
	]
})
export class AnnualFilingStatusTrendModule {
}
