import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {LoggerService} from '../../shared/services/logger.service';
import {IndicatorName} from '../../modules/api/fit-api/models/snapshots/indicator-name';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector: 'app-indicator-name-chooser',
	templateUrl: './indicator-name-chooser.component.html',
	styleUrls: ['./indicator-name-chooser.component.scss']
})
export class IndicatorNameChooserComponent implements OnInit, OnChanges {
	@Input() filingBasis: FilingBasis;
	@Input() indicatorReportType: string;
	@Input() value: Array<string>;
	@Output() changed = new EventEmitter<Array<string>>();
	dataSource: Array<IndicatorName>;
	isLoading = false;

	constructor(
		private fitApi: FitApiService,
		private logger: LoggerService
	) {
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		// If the filing basis or indicator report type are changing and *both* have current valid values, regenerate
		// the data source
		if ((changes.filingBasis?.currentValue || changes.indicatorReportType?.currentValue)
			&& (this.filingBasis && this.indicatorReportType)
		) {
			this.updateDataSource(this.filingBasis, this.indicatorReportType);
		}
		// If the filing basis or the indicator report type are changing from valid values to valid values, clear out
		// any indicator names that don't apply to the new one
		if (changes.filingBasis?.previousValue && changes.filingBasis?.currentValue
			|| changes.indicatorReportType?.previousValue && changes.indicatorReportType?.currentValue
		) {
			// Remove any values that don't apply.
			// const selections = this.dataSource.filter(x => this.value.includes(x.name));
			const value = this.value.filter(x => this.dataSource.find(y => y.name === x) != null);
			this.changed.emit(value);
		}
	}

	updateDataSource = (filingBasis: FilingBasis, indicatorReportType: string, snapshotId?: SnapshotId): void => {
		this.isLoading = true;
		this.dataSource = null;
		// Clear out values anytime the inputs change
		// this.changed.emit([]);
		this.fitApi.getAnnualFilingSnapshot(snapshotId).subscribe(snapshot => {
			// Since the enum is capitalized on the OData side, we have to convert our key
			const indicatorReportTypeODataEnumValue = indicatorReportType.charAt(0).toUpperCase() + indicatorReportType.slice(1);
			this.dataSource = snapshot.detail.indicatorNames.filter(row =>
				row.filingBasis === filingBasis.name
				&& row.report === indicatorReportTypeODataEnumValue
			);
		},
			error => this.logger.error,
			() => this.isLoading = false
		);
	};

	onValueChanged = (event: Array<string>) => {
		this.changed.emit(event);
	}

}
