<div>
	<p class="financial-health-indicators__content">
		{{ snapshot?.description }}
	</p>
	<app-tab-bar
		[items]="availableModes"
		hasCheck=true
		[selectedId]="selectedMode"
		(onSelect)="onModeChange($event)"
	></app-tab-bar>
	<dx-lookup
		*ngIf="selectedMode === FHIMode.Team"
		class="lookup"
		[dataSource]="teams"
		[searchExpr]="['code', 'description']"
		[displayExpr]="displayExpr"
		[disabled]="false"
		valueExpr="code"
		placeholder="Type a team code or name to filter the list"
		(onSelectionChanged)="selectionChanged($event)"
	>
		<dxo-drop-down-options
			[closeOnOutsideClick]="true"
			[showTitle]="false"
			position="top"
		></dxo-drop-down-options>
	</dx-lookup>
	<dx-lookup
		*ngIf="selectedMode === FHIMode.GovernmentType"
		class="lookup"
		[dataSource]="governmentTypes"
		[searchExpr]="['code', 'description']"
		[displayExpr]="displayExpr"
		[disabled]="false"
		valueExpr="code"
		placeholder="Type a government type code or name to filter the list"
		(onSelectionChanged)="selectionChanged($event)"
	>
		<dxo-drop-down-options
			[closeOnOutsideClick]="true"
			[showTitle]="false"
			position="top"
		></dxo-drop-down-options>
	</dx-lookup>
	<dx-lookup
		*ngIf="selectedMode === FHIMode.Government"
		class="lookup"
		[dataSource]="governments"
		[searchExpr]="['mcag', 'lookupNameWithDba']"
		[displayExpr]="displayGovernmentExpr"
		[disabled]="false"
		valueExpr="mcag"
		placeholder="Type a MCAG or government name to filter the list"
		(onSelectionChanged)="selectionChanged($event)"
	>
		<dxo-drop-down-options
			[closeOnOutsideClick]="true"
			[showTitle]="false"
			position="top"
		></dxo-drop-down-options>
	</dx-lookup>
	<p *ngIf="availableModes.length === 0" class="no-modes">No modes available for selection.</p>
	<dx-button
		*ngIf="availableModes.length > 0"
		class="download-button"
		[disabled]="!buttonUrl"
		(click)="downloadClick()"
	>
		Download Financial Health Indicators&nbsp;
		<fa-icon [icon]="icons.download" class="dx-icon"></fa-icon>
	</dx-button>
	<wasao-loading [isLoading]="isLoading"></wasao-loading>
</div>
