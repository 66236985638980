<div class="tab-bar">
	<ng-container *ngFor="let item of items">
		<button
			*ngIf="item.visible !== false"
			type="button"
			class="tab-bar__option"
			[class.tab-bar__option--selected]="selectedId === item.id"
			(click)="selectItem(item.id)"
		>
			<fa-icon
				class="u-margin-right-tiny"
				*ngIf="hasCheck && selectedId === item.id"
				[icon]="checkIcon">
			</fa-icon>
			{{ item.label }}
		</button>
	</ng-container>
</div>
