import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectionDrawerComponent} from './projection-drawer/projection-drawer.component';
import {ProjectionLauncherComponent} from './projection-launcher/projection-launcher.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
	DxButtonModule,
	DxCheckBoxModule,
	DxFormModule,
	DxListModule,
	DxPopupModule,
	DxSelectBoxModule,
	DxTextAreaModule,
	DxTextBoxModule,
	DxTreeListModule, DxValidationSummaryModule,
	DxValidatorModule
} from 'devextreme-angular';
import {SharedComponentsModule} from 'app/shared/components/shared-components.module';
import {ProjectionEditorComponent} from './projection-editor/projection-editor.component';
import {SideDrawerModule} from '../side-drawer/side-drawer.module';

@NgModule({
	declarations: [
		ProjectionDrawerComponent,
		ProjectionLauncherComponent,
		ProjectionEditorComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		DxButtonModule,
		DxPopupModule,
		DxCheckBoxModule,
		DxTreeListModule,
		DxTextBoxModule,
		DxTextAreaModule,
		DxSelectBoxModule,
		DxListModule,
		SharedComponentsModule,
		DxValidatorModule,
		DxFormModule,
		SideDrawerModule,
		DxValidationSummaryModule,
	],
	exports:      [
		ProjectionDrawerComponent,
		ProjectionLauncherComponent,
	],
})
export class ProjectionModule {
}
