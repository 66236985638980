import jsonPackage from '../../package.json';

export const common = {
	// use version from package.json
	version: jsonPackage.version,
	// fellow test mapbox
	// mapboxAccessToken: 'pk.eyJ1IjoiYXN0ZXdhcnRmZWxsb3ciLCJhIjoiY2pweWtqY3k1MDI3dDQyc3o5cTF3Ymt2byJ9.lGflcvsl0uVo-PtU6ItEzw'
	mapboxAccessToken: 'pk.eyJ1IjoiZ2FyZGNvbW11bmljYXRpb25zIiwiYSI6ImNqbmtwbTd2NzAyNjUzd3FyeTA0ZW12cncifQ.8JyWoZs6SltBrxpcRzrPFw',
	googleTrackingId: 'G-LDKEV3J9BD'
};

