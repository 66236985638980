import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {PopulationImporterState} from './models/population-importer-state';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ShapefileLoaderState} from '../shapefile-loader/models/shapefile-loader-state';
import {DxODataStoreKey} from '../../modules/api/dx-o-data-store-key';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import {map, shareReplay} from 'rxjs/operators';
import {
	ExternalCommonServiceApiService
} from '../../modules/api/external-common-service-api/external-common-service-api.service';
import {DevExtremeHelperService} from '../../modules/services/dev-extreme-helper/dev-extreme-helper.service';
import {alert} from 'devextreme/ui/dialog';

@Injectable({
	providedIn: 'root'
})
export class PopulationImporterService {

	apiUrl = `${environment.apis.app}/PopulationImporterVintages`;
	states = PopulationImporterState;
	private _state = new BehaviorSubject(PopulationImporterState.Vintages);
	state = this._state.asObservable();

	private _vintageYear = new BehaviorSubject<number>(null);
	vintageYear = this._vintageYear.asObservable();

	private stateToRouteMap: Partial<{ [key in PopulationImporterState]: string }> = {
		[PopulationImporterState.Vintages]: '',
		[PopulationImporterState.Records]: 'Records',
	};

	private stateToDxDataStoreKeyMap: Partial<{ [key in PopulationImporterState]: DxODataStoreKey }> = {
		[PopulationImporterState.Records]: {
			key: 'id',
			keyType: 'Int32'
		},
	};

	getGovernmentsLookup = this.commonData.getEntitiesMandatoryFields().pipe(
		map(result => {
			result.map(x => {
				x.EntityName = `${x.MCAG}: ${x.EntityName}`;
				return x;
			});
			return this.dxHelper.makeLookupDataSource(result, 'MCAG');
		}),
		shareReplay(1)
	);

	constructor(
		private http: HttpClient,
		private commonData: ExternalCommonServiceApiService,
		private dxHelper: DevExtremeHelperService
	) {
	}

	/**
	 * Set the state of the ShapefileLoader, and optionally the VintageYear.
	 * @param state
	 * @param vintageYear undefined retains VintageYear, null resets it
	 */
	setState(state: PopulationImporterState, vintageYear?: number) {
		this._state.next(state);
		if (vintageYear !== undefined) {
			this._vintageYear.next(vintageYear);
		}
	}

	private getRoute(state: PopulationImporterState, year?: number, suffix?: string): string {
		const route = this.stateToRouteMap[state];
		if (route == null) {
			throw new Error(`No route defined for state: ${state}`);
		}
		// if the route is empty, it is the base. Do not specify year.
		let url = route === ''
			? this.apiUrl
			// otherwise build year into the route
			: `${this.apiUrl}(${year})/${route}`;

		if (suffix) {
			url += suffix;
		}

		return url;
	}

	private getDxDataStoreKey(state: PopulationImporterState): DxODataStoreKey  {
		const dxDataStoreKey = this.stateToDxDataStoreKeyMap[state];
		// default to just year -- sufficient for routes that are readonly (most)
		if (dxDataStoreKey == null) {
			return { key: 'year', keyType: 'Int32' };
		}
		return dxDataStoreKey;
	}

	getODataDataSource(state: PopulationImporterState, year?: number) {
		const storeObj = {
			version: 4,
			url: this.getRoute(state, year),
			...this.getDxDataStoreKey(state)
		};
		return new DataSource({
			store: new ODataStore(storeObj)
		});
	}

	uploadPopulationFile = (year: number, file: File): Observable<any> => {
		const url = this.getRoute(PopulationImporterState.Records, year, '/Upload');
		const formData = new FormData();
		formData.append('file', file);
		return this.http.post(url, formData);
	}

	handleError = (err: HttpErrorResponse) => {
		let message = 'An error occurred, but I have no information to provide.';
		if (err?.error?.error?.message) {
			message = err.error.error.message;
		}
		alert(message, 'Error');
		return of(err);
	}
}
