<div class="display-options">
	<dx-button
		class="button--nonoperative"
		(onClick)="popup.instance.show()"
	>
		<div *dxTemplate="let data of 'content'">
			<fa-icon class="dx-icon" [icon]="icon"></fa-icon>
			Display Options
		</div>
	</dx-button>
	<dx-popup
		class="display-options__popup"
		title="Display Options"
		width="auto"
		height="auto"
		closeOnOutsideClick=true
		(onShowing)="onShowing($event)"
	>
		<div *dxTemplate="let data of 'content'">
			<div class="app-popup__wrapper">
				<div class="app-popup__inner">
					<div class="display-options__popup__option" *ngIf="availableMetrics?.measures?.length">
						<span>Show</span>
						<dx-select-box
							[items]="availableMetrics.measures"
							[(value)]="transientSelections.measure"
							[disabled]="availableMetrics.measures?.length === 1"
							valueExpr="key"
							displayExpr="name"
						></dx-select-box>
					</div>
					<div class="display-options__popup__option" *ngIf="availableMetrics?.comparisons?.length > 1">
						<span>with</span>
						<dx-select-box
							[items]="availableMetrics.comparisons"
							[(value)]="transientSelections.comparison"
							[disabled]="availableMetrics.comparisons?.length === 1"
							valueExpr="key"
							displayExpr="name"
						></dx-select-box>
					</div>
					<div class="display-options__popup__option" *ngIf="availableMetrics?.units?.length">
						<span>in</span>
						<dx-select-box
							[items]="availableMetrics.units"
							[(value)]="transientSelections.unit"
							[disabled]="availableMetrics.units?.length === 1"
							valueExpr="key"
							displayExpr="name"
						></dx-select-box>
					</div>

					<div class="display-options__popup__options-group">
						<dx-check-box
							*ngIf="tab.pivotGridSettings.populationField"
							[text]="'Show population by ' + tab.pivotGridSettings.populationField"
							[(value)]="transientSelections.showPopulation"
						></dx-check-box>

						<div *ngIf="tab?.report?.hasAccountCodes && tab?.report?.financialsDatasetSource === 'SAOAnnualFiling'">
							<dx-check-box
								text="Show category account codes"
								[(value)]="transientSelections.showAccountCodes"
							></dx-check-box>
							<wasao-hint icon="question">
								The State Auditor's Office is required by state law (<a target=_blank href="https://apps.leg.wa.gov/rcw/default.aspx?cite=43.09.200">RCW 43.09.200</a>) to prescribe uniform budgeting, accounting, and reporting systems that local governments must follow. These functional revenue, expenditure and expense codes are prescribed by this Office as the standardized chart of accounts local governments must use to report to the State Auditor's Office every year. For more detail see the
								<a target=_blank href="https://www.sao.wa.gov/local/Pages/Bars-new-user-information.aspx">Budgeting, Accounting, and Reporting System (BARS)</a> manuals.
							</wasao-hint>
						</div>

						<div *ngIf="tab?.report?.hasAccountCodes && tab?.report?.financialsDatasetSource === 'OSPI'">
							<dx-check-box
								text="Show report item codes"
								[(value)]="transientSelections.showAccountCodes"
							></dx-check-box>
						</div>

						<div *ngIf="tab?.report?.canViewEmptyCells">
							<dx-check-box
								text="Show empty report rows/columns"
								[(value)]="transientSelections.showEmptySchoolsRows"
							></dx-check-box>
						</div>

						<dx-check-box
							*ngIf="isDebtCapitalToggleVisible"
							text="Show debt service and capital outlay with expenditures"
							[(value)]="transientSelections.showDebtServiceWithExpenditures"
						></dx-check-box>
					</div>
				</div>

				<div class="app-popup__footer">
					<dx-button
						text="Done"
						type="default"
						(onClick)="handleSubmit()"
					></dx-button>
				</div>
			</div>
		</div>
	</dx-popup>
</div>
