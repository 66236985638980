<div class="schedule-browser">
	<div class="u-margin-top u-margin-bottom u-padding-horizontal">
		<h2 class="u-hidden">
			{{ tab.title }}
		</h2>

		<h2>
			<span *ngIf="tab.track.id === 'a' || tab.track.id === 'b1'; then govTitle else title"></span>
			<ng-template #govTitle>
				<button class="u-padding-none schedule-browser__title-button" (click)="handleGoToProfile()">{{ summary.heading }}</button>,
				{{ summary.subtitle }}
			</ng-template>
			<ng-template #title>
				{{ summary.title }}
			</ng-template>
			<wasao-badge *ngIf="tab.report.beta" text="beta"></wasao-badge>
		</h2>
	</div>

	<div class="schedule-browser__narrative-row">
		<app-narrative
			class="schedule-browser__narrative-component"
			[summary]="summary?.info"
			[summaryInfo]="summary?.info"
			[summaryFilters]="summary?.filters"
		></app-narrative>
	</div>

	<div class="schedule-browser__grid-outer-wrapper">
		<div class="schedule-browser__grid-inner-wrapper">
			<app-pivot-grid
				*ngIf="!isLoading"
				[pivotGridData]="pivotGridData"
				[tab]="tab"
				[summary]="summary"
				(updated)="updateBaseline()"
				(loaded)="handlePivotGridLoaded($event)"
				style="display: block; height: 100%;"
			></app-pivot-grid>
		</div>
	</div>
	<wasao-loading
		[isLoading]="isApplyingProjection"
		message="Applying projection..."
	></wasao-loading>
</div>

<app-schedule-browser-chart
	*ngIf="chartVisible"
	[tab]="tab"
	[reportId]="tab.report.id"
	[summary]="summary"
	[popupTitle]="summary.title"
	[pivotGridComponent]="pivotGridComponent">
</app-schedule-browser-chart>

<app-indicator-report-map
	[tab]="tab"
	[title]="summary.title"
	[subTitle]="summary?.info"
	[pivotGridData]="pivotGridData"
></app-indicator-report-map>
