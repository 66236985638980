
<div id="content" class="content field-label--small">
	<div class="header">
		<h3>{{ peerSet?.id ? 'Edit an Existing Peer Set' : 'Create a New Peer Set' }}:</h3>

		<label>
			<dx-text-box
				placeholder="Name this Peer Set..."
				valueChangeEvent="keyup"
				(onValueChanged)="peerSetNameChanged($event.value)"
				[value]="peerSet?.name"
			>
				<dx-validator>
					<dxi-validation-rule
						type="required"
						message="Please name your peer set before saving.">
					</dxi-validation-rule>
				</dx-validator>
			</dx-text-box>
		</label>

		<wasao-hint
			icon="infoCircle"
			class="tooltip-icon u-margin-bottom">
			<p>
				Choose a name for this saved peer set. Saved peer sets will be privately saved to your local
				browser's data cache, but will not be seen by other users. Deleting your browser cache will
				remove all saved peer sets.
			</p>
		</wasao-hint>
	</div>

	<div class="baseline-description-container">
		<ol class="peer-set__items"
			dndDropzone
			(dndDrop)="onPeerDrop($event, 'baseline')"
		>
			<li class="peer-set__item"
				*ngFor="let peer of peerSet.peers | filterBy: { prime: true }; let i = index"
				[dndDraggable]="peer"
				dndEffectAllowed="move"
				(dndMoved)="onPeerMoved(peer, 'baseline')"
				(dndStart)="highlightDropAreas(peer)"
				(dndEnd)="removeDropAreaHighlights()"
			>
				<fa-icon
					class="peer-set__icon peer-set__icon--delete"
					[icon]="icons.close"
					(click)="removePeerFromList(peer)">
				</fa-icon>
				<fa-icon class="peer-set__icon peer-set__icon--building" [icon]="icons.building"></fa-icon>
				<span>{{ peer.entityNameWithDba }}</span>
				<wasao-badge *ngIf="peer.prime" text="Baseline"></wasao-badge>
			</li>
			<li
				*ngIf="(peerSet.peers | filterBy: { prime: true }).length === 0"
				class="peer-set__item peer-set__item--placeholder"
				[ngClass]="{ 'peer-set__item--drop-target': highlightBaseline }"
			>
				<fa-icon class="peer-set__icon peer-set__icon--building" [icon]="icons.building"></fa-icon>
				<span class="peer-set__icon__caption">Baseline Gov't</span>
			</li>
		</ol>
		<dx-text-area
			class="description"
			placeholder="Add a note to help you remember the purpose of this Peer Set."
			(onValueChanged)="peerSetDescriptionChanged($event.value)"
			[value]="peerSet.description"
		></dx-text-area>
	</div>

	<h4>Comparison Governments</h4>
	<ol class="peer-set__items"
		dndDropzone
		(dndDrop)="onPeerDrop($event, 'included')"
	>
		<li class="peer-set__item"
			*ngFor="let peer of peerSet.peers | filterBy: { prime: false }"
			[dndDraggable]="peer"
			dndEffectAllowed="move"
			(dndMoved)="onPeerMoved(peer, 'included')"
			(dndStart)="highlightDropAreas(peer)"
			(dndEnd)="removeDropAreaHighlights()"
		>
			<fa-icon
				class="peer-set__icon peer-set__icon--delete"
				[icon]="icons.close"
				(click)="removePeerFromList(peer)">
			</fa-icon>
			<fa-icon class="peer-set__icon peer-set__icon--building" [icon]="icons.building"></fa-icon>

			<span>{{ peer.entityNameWithDba }}</span>
		</li>
		<li
			*ngFor="let i of peerSetPlaceholders"
			class="peer-set__item peer-set__item--placeholder"
			[ngClass]="{ 'peer-set__item--drop-target': highlightIncluded }"
		>
			<fa-icon class="peer-set__icon peer-set__icon--building" [icon]="icons.building"></fa-icon>
		</li>
	</ol>

	<app-peer-set-selector
		[peers]="peerSet.peers"
		(peersChange)="updatePeersFromSelector($event)"
	></app-peer-set-selector>
</div>

<div class="peer-set">
	<dx-text-box
		mode="number"
		[value]="peerSet.peers?.length?.toString()"
		[visible]=false
	>
		<dx-validator>
			<dxi-validation-rule
				type="custom"
				reevaluate="true"
				message="Please add at least {{ minComparisonPeers }} comparison government to your peer set."
				[validationCallback]="hasMinimumPeers">
			</dxi-validation-rule>
		</dx-validator>
	</dx-text-box>
</div>
