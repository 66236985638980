import {Component} from '@angular/core';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {ShapefileLoaderService} from './shapefile-loader.service';
import {ShapefileLoaderState} from './models/shapefile-loader-state';

@Component({
	selector: 'app-shapefile-loader',
	templateUrl: './shapefile-loader.component.html',
	styleUrls: ['./shapefile-loader.component.scss']
})
export class ShapefileLoaderComponent {
	isVisible: boolean;
	state: ShapefileLoaderState;
	title: string;
	vintageYear: number;

	constructor(
		public service: ShapefileLoaderService,
		private ui: UserInterfaceService
	) {
		this.ui.shapefileLoader.subscribe(x => this.isVisible = x);
		this.service.state.subscribe(x => {
			this.state = x;
			this.updateTitle();
		});
		this.service.vintageYear.subscribe(x => {
			this.vintageYear = x;
			this.updateTitle();
		});
	}

	updateTitle(): void {
		const vintageYearText = this.vintageYear
			? ` for ${this.vintageYear}`
			: '';
		this.title = `Shapefile Loader: ${this.state}${vintageYearText}`;
	}

	back = (event) => {
		if (this.state === ShapefileLoaderState.MaintainVintage) {
			// MaintainVintage goes back to Vintages and clears out year
			this.service.setState(this.service.states.Vintages, null);
		} else {
			// all others go back to MaintainVintage, leave year the same
			this.service.setState(ShapefileLoaderState.MaintainVintage);
		}
	}

	close = () => this.isVisible = false;
}
