import {Injectable} from '@angular/core';
import ODataContext from 'devextreme/data/odata/context';
import ODataStore from 'devextreme/data/odata/store';
import {environment} from 'environments/environment';
import {Years} from '../models/years';
import {SnapshotService} from './snapshot.service';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class DataSourceService {
	private apiUrl = environment.apis.app;

	// this is only useful for calling unbound functions since ODataContext does not support nested entity sets
	oDataContext = new ODataContext({
		url:     this.apiUrl,
		version: 4,
		// entities: {
		// 	Schedule1s: { key: 'id', keyType: 'Int32' },
		// 	Schedule1Totals: { key: 'id', keyType: 'Int32' },
		// 	LocalGovernments: { key: 'mcag', keyType: 'String' },
		// 	Snapshots: { key: 'id', keyType: 'Int32' },
		// 	GovernmentMetrics: { key: ['mcag', 'year'], keyType: { mcag: 'String', year: 'Int16' } }
		// }
	});

	constructor(
		private snapshotService: SnapshotService,
		private apiService: FitApiService,
	) {
	}

	/**
	 * Returns a list of years that apply to the given MCAGs
	 */
	evaluateYears(snapshotId: SnapshotId, mcags: string[], selected: number[]) {
		return this.oDataContext.get('GetYears', {forMcags: mcags})
			.then(function(result) {
				// Result is the available years for this collection of mcags
				const available = result;
				let newValues;

				// If selected is passed in, remove any selections not present in the available years
				if (selected) {
					newValues = selected.filter(x => available.indexOf(x) >= 0);
					// Sort the list in numerical order
					newValues = newValues.sort((a, b) => a - b);
				}

				return {
					available: available,
					selected:  newValues
				};
			});
	}

	getFiledFundsStore(snapshotId: SnapshotId, mcag: string, years: Years): ODataStore {
		const url = this.apiService.getAnnualFilingRoute(snapshotId, `/funds/getFiledFunds(mcag='${mcag}',startYear=${years[0]},endYear=${years[1]})`);
		return new ODataStore({
			url:     url,
			version: 4
		});
	}

	getIndicatorGroupsFor(snapshotId: SnapshotId, mcag: string, years: Years): ODataStore {
		const url = this.apiService.getAnnualFilingRoute(snapshotId, `/IndicatorGroups/for(mcag='${mcag}',startYear=${years[0]},endYear=${years[1]})`);
		return new ODataStore({
			url:     url,
			version: 4
		});
	}

	getBaselineMetrics(snapshotId: SnapshotId, mcag: string, limit?: number): ODataStore {
		const url = this.apiService.getAnnualFilingRoute(snapshotId, `/GovernmentMetrics/WithBaseline(mcag='${mcag}')`);
		return new ODataStore({
			url:        url,
			version:    4,
			key:        'mcag',
			beforeSend: function(obj) {
				obj.params.$expand = 'localGovernment';
				if (limit) {
					obj.params.$top = limit;
				}
			}
		});
	}

	getGovernmentMetrics(snapshotId: SnapshotId, limit?: number): ODataStore {
		const url = this.apiService.getAnnualFilingRoute(snapshotId, `/GovernmentMetrics`);
		return new ODataStore({
			url:        url,
			version:    4,
			beforeSend: function(obj) {
				obj.params.$expand = 'localGovernment';
				if (limit) {
					obj.params.$top = limit;
				}
			}
		});
	}

	getStore(snapshotId: SnapshotId, storeName: string, params?: any): ODataStore {
		const url = this.apiService.getAnnualFilingRoute(snapshotId, `/${storeName}`);
		return new ODataStore({
			url:        url,
			version:    4,
			key:        this.getStoreKey(storeName),
			beforeSend: function(obj) {
				if (storeName && storeName.indexOf('Schedule1') !== -1) {
					obj.timeout = 120000;
				}
				if (!params) {
					return;
				}

				const keys = Object.keys(params);
				for (let i = 0; i < keys.length; i++) {
					const key       = keys[i];
					obj.params[key] = params[key];
				}
			}
		});
	}

	getLiveUnauthenticatedStore(storeName: string, params?: any): ODataStore {
		const url = this.apiService.getLiveUnauthenticatedRoute(`/${storeName}`);
		return new ODataStore({
			url:        url,
			version:    4,
			key:        this.getStoreKey(storeName),
			beforeSend: function(obj) {
				if (!params) {
					return;
				}

				const keys = Object.keys(params);
				for (let i = 0; i < keys.length; i++) {
					const key       = keys[i];
					obj.params[key] = params[key];
				}
			}
		});
	}

	getStoreKey(storeName: string): string {
		switch (storeName) {
			case 'GovernmentMetrics':
			case 'LocalGovernments':
				return 'mcag';
			default:
				break;
		}
	}
}
