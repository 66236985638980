import {Injectable} from '@angular/core';
import {AccountDescriptorServiceModule} from './account-descriptor-service.module';
import {first, map} from 'rxjs/operators';
import {SnapshotService} from '../../../shared/services/snapshot.service';
import {AccountDescriptor} from '../../api/fit-api/models/snapshots/account-descriptor';
import {Observable} from 'rxjs';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: AccountDescriptorServiceModule
})
export class AccountDescriptorService {

	constructor(
		private snapshotService: SnapshotService,
	) {
	}

	get(id: number, snapshotId?: SnapshotId): Observable<AccountDescriptor> {
		return this.snapshotService.getSnapshot(snapshotId).pipe(
			first(),
			map(x => x.detail.accountDescriptors.find(a => a.id === id))
		);
	}
}
