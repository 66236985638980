import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	DxPopupModule,
	DxLoadPanelModule,
	DxButtonModule,
	DxSelectBoxModule,
	DxTabPanelModule
} from 'devextreme-angular';

import { WizardService } from './wizard.service';
import { TrackChooserComponent } from './track-chooser/track-chooser.component';
import { WizardComponent } from './wizard.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SharedComponentsModule } from 'app/shared/components/shared-components.module';
import { HintModule } from 'app/modules/ui/hint/hint.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { TrackEComponent } from './track-e/track-e.component';
import {FilingBasisPickerModule} from '../../modules/ui/filing-basis-picker/filing-basis-picker.module';
import {FundCategoryPickerModule} from '../../modules/ui/fund-category-picker/fund-category-picker.module';
import {LoadingModule} from '../../modules/ui/loading/loading.module';

@NgModule({
	imports: [
		CommonModule,
		DxPopupModule,
		DxLoadPanelModule,
		DxButtonModule,
		SharedComponentsModule,
		HintModule,
		FilingBasisPickerModule,
		FundCategoryPickerModule,
		LoadingModule,
		DxSelectBoxModule,
		DxTabPanelModule
	],
	declarations: [
		WizardComponent,
		TrackChooserComponent,
		NavigationComponent,
		WelcomeComponent,
		TrackEComponent
	],
	providers: [
		WizardService
	],
	exports: [
		WizardComponent
	]
})
export class WizardModule { }
