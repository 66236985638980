<div class="container">
	<div class="split">
		<p [ngClass]="{'bio': !isDisplayDensityPrint,
                 'bio-print': isDisplayDensityPrint,
                 'bio-long': liveGovTypeInfo?.ShortDescription.length > 400 && liveGovTypeInfo?.ShortDescription.length <= 625,
                 'bio-extra-long': liveGovTypeInfo?.ShortDescription.length > 625}">
			{{ liveGovTypeInfo?.ShortDescription }}
		</p>


		<dl>
			<ng-container *ngIf="this.financialsDatasetSource === 'OSPI'">
				<dt >Average Enrollment:</dt>
				<dd>{{enrollmentTotals | number: '1.0-0'}} ({{enrollmentYears}} Headcount)
					<wasao-hint
						*ngIf="isInteractive"
						icon="infoCircle"
						[hintInformation]="'enrollmentTotal'"
					>
					</wasao-hint>
				</dd>
			</ng-container>
			<br>
			<ng-container *ngIf="historicalAuditLink && showAuditLink">
				<dt>
					<fa-icon [icon]="icons.history"></fa-icon>
				</dt>
				<dd><a [href]="historicalAuditLink" target="_blank">Browse historical audit reports</a></dd>
			</ng-container>
		</dl>

		<wasao-audit-kpis
			class="entity"
			[govTypeCode]="govTypeCode">
		</wasao-audit-kpis>

		<wasao-loading [isLoading]="isLoading"></wasao-loading>
	</div>
	<div class="split map">
		<wasao-map
			[govTypeCode]="govTypeCode"
			[isInteractive]="isInteractive"
			[displayDensity]="displayDensity"
			[govClickAction]="govClickAction"
		></wasao-map>
	</div>
</div>

<ng-container *ngIf="showPieCharts">
	<div class="spacer"></div>
	<div
		class="pie-container"
		[ngClass]="{
		'charts': !isDisplayDensityPrint,
    'charts-print': isDisplayDensityPrint
  }"
	>
		<wasao-accounting-bases
			[snapshotId]="snapshotId"
			[govTypeCode]="govTypeCode"
			[isInteractive]="isInteractive"
			[displayDensity]="displayDensity"
			sizeGroup="governmentTypeCharts"
		></wasao-accounting-bases>

		<wasao-filing-status-summary
			[class.hidden]="filingStatusData === false"
			[snapshotId]="snapshotId"
			[govTypeCode]="govTypeCode"
			[isInteractive]="isInteractive"
			[displayDensity]="displayDensity"
			[filerReportClickAction]="filerReportClickAction"
			(hasData)="filingStatusData = $event"
			sizeGroup="governmentTypeCharts"
		></wasao-filing-status-summary>


		<wasao-app-government-body-type
			[govTypeCode]="govTypeCode"
			[displayDensity]="displayDensity"
			sizeGroup="governmentTypeCharts"
		></wasao-app-government-body-type>

		<wasao-app-government-body-origins
			[govTypeCode]="govTypeCode"
			[displayDensity]="displayDensity"
			sizeGroup="governmentTypeCharts"
		></wasao-app-government-body-origins>

		<wasao-app-government-treasurers
			[govTypeCode]="govTypeCode"
			[displayDensity]="displayDensity"
			sizeGroup="governmentTypeCharts"
		></wasao-app-government-treasurers>
	</div>
</ng-container>


