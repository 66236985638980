import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges, OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {faChevronCircleDown, faDownload, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Tab} from 'app/shared/models/tab';
import {LoggerService} from 'app/shared/services/logger.service';
import {TabService} from 'app/shared/services/tab.service';
import {GradientService} from 'app/shared/services/gradient.service';
import {MenuService} from 'app/shared/services/menu.service';
import {DxPivotGridComponent} from 'devextreme-angular';
import Scrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import Tooltip from 'devextreme/ui/tooltip';
import {UserService} from '../../shared/services/user.service';
import {User} from '../../shared/models/user';
import {TrackEService} from '../../shared/services/tracks/track-e.service';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {exportPivotGrid} from 'devextreme/excel_exporter';
import {Fill, Font, Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';

import {ReportSummaryService} from '../../shared/services/report-summary.service';
import {fitBase64, saoBase64} from './logo';
import {PivotGridService} from '../../shared/services/pivot-grid.service';
import {TrackAService} from '../../shared/services/tracks/track-a.service';
import {CellService} from '../../shared/services/cell.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';
import * as govColors from '../../../../sao-patterns/src/tokens/government-colors.js';

@Component({
	selector: 'app-pivot-grid',
	templateUrl: './pivot-grid.component.html',
	styleUrls: ['./pivot-grid.component.scss']
})
export class PivotGridComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@ViewChild(DxPivotGridComponent, {static: true}) private pivotGrid: DxPivotGridComponent;

	@Input() pivotGridData: PivotGridDataSource;
	@Input() tab: Tab;
	@Input() summary: any;
	@Output() updated = new EventEmitter<any>();
	@Output() ready = new EventEmitter<boolean>();
	@Output() loaded = new EventEmitter<DxPivotGridComponent>();

	barsDescriptionTarget: Element;
	barsDescriptionId: number;

	isDataLoaded = false;
	currentContextMenuEvent: any;
	isComparisonDialogVisible = false;

	columnHeaderCells: Array<any> = [];
	columnSubHeaderCells: Array<any> = [];
	icons = {
		download: faDownload,
		launcher: faChevronCircleDown,
		close: faTimes,
	};
	snapshotId: SnapshotId;
	operatingResultsAnalysisSubSectionIdsToNegate: Array<string>;
	currentSnapshot: any;
	color = govColors;

	private _user: User;

	constructor(
		private logger: LoggerService,
		private tabService: TabService,
		private gradientService: GradientService,
		private reportSummaryService: ReportSummaryService,
		private menuService: MenuService,
		private userService: UserService,
		private trackA: TrackAService,
		private trackE: TrackEService,
		private snapshotService: SnapshotService,
		private pivotGridService: PivotGridService,
		private cellService: CellService
	) {
		this.logger.log(this);
		this.userService.user.subscribe(user => this._user = user);
	}

	ngOnInit() {
		this.pivotGridService.registerInstance(this);
	}

	ngAfterViewInit() {
		this.loaded.emit(this.pivotGrid);
	}

	ngOnChanges(changes: SimpleChanges): void {
		// When the tab is updated, check to see if the snapshot id changed so we can update the operatingResultsSubSections
		if (changes.tab?.currentValue && changes.tab.currentValue.snapshotId !== this.snapshotId) {
			this.snapshotId = changes.tab.currentValue.snapshotId;
			this.operatingResultsAnalysisSubSectionIdsToNegate = this.snapshotService
				.getCollection(this.snapshotId, 'operatingResultsSubSections')
				.filter(x => x.display === 'Negative')
				.map(x => x.id);
		}
	}

	ngOnDestroy(): void {
		this.pivotGridService.deregisterInstance(this);
	}

	customExport = () => {
		// Create workbook and worksheet
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(this.summary.subtitle.replace(/:/g, ' '));
		worksheet.unprotect();

		// global styles
		const font = {color: {argb: 'ff333333'}};
		const boldFont = {bold: true, color: {argb: 'ff333333'}};
		const headerFont: Partial<Font> = {name: 'Roboto Condensed Light', color: {argb: 'ffffffff'}};
		const borderStyle = {style: 'thin', color: {argb: 'ffdddddd'}};
		const lightGrayFill: Fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: 'fff8f8f8'}
		};
		// total columns
		let numColumns = 0;
		// number of columns just in the row headers
		let numRowHeaders = 0;

		exportPivotGrid({
			component: this.pivotGrid.instance,
			worksheet: worksheet,
			topLeftCell: 'A3',

			// https://js.devexpress.com/Documentation/ApiReference/Common/Object_Structures/ExcelPivotGridCell/
			customizeCell: ({pivotCell, excelCell}) => {
				excelCell.font = font;

				// Keep track of the number of columns
				if (pivotCell.area === 'column') {
					numColumns = Math.max(numColumns, pivotCell.columnIndex + 1);
				}

				// Keep track of the number of row headers
				if (pivotCell.area === 'row') {
					numRowHeaders = Math.max(numRowHeaders, pivotCell.columnIndex + 1);
				}

				// color the total/grand total rows
				// pivotCell.type indicates column, row, or data cell.
				const isInGrandOrTotalRow = ['T', 'GT'].includes(pivotCell.type)
					// rowType indicates the type of row a data cell appears in (e.g. 'T' === total)
					|| ['T', 'GT'].includes(pivotCell.rowType);
				if (isInGrandOrTotalRow) {
					excelCell.fill = lightGrayFill;
				}

				if (pivotCell.area === 'row' && pivotCell.type === 'GT' && this.tab.track.id === 'e') {
					// todo implement trimean link for applicable scenarios
					excelCell.value = {
						'richText': [
							{
								'font': {
									'size': 11,
								},
								'text': 'Statistics\n'
							}, {
								'font': {
									'bold': true,
									'size': 9,
									'color': {'argb': 'FFE8702D'},
								}, 'text':
									'\n* Trimean: A weighted average of the distribution\'s median and its two quartiles.\n '
							}, {
								'font': {
									'size': 9,
									'color': {'argb': 'FFE8702D'},
								}, 'text':
									'See '
							}, {
								'font': {
									'italic': true,
									'underline': true,
									'size': 9,
									'color': {'argb': 'FFE8702D'},
								},
								'text': 'Trimean in Wikipedia, The Free Encyclopedia ',
								'hyperlink': 'https://en.wikipedia.org/w/index.php?title=Trimean&oldid=1029934022'
							}, {
								'font': {
									'size': 9,
									'color': {'argb': 'FFE8702D'},
								}, 'text':
									'for more information:\n'
							}, {
								'font': {
									'size': 9,
									'italic': true,
									'color': {'argb': 'FFE8702D'},
								}, 'text':
									'https://en.wikipedia.org/w/index.php?title=Trimean&oldid=1029934022'
							},
						],
						hyperlink: 'https://en.wikipedia.org/w/index.php?title=Trimean&oldid=1029934022',
						tooltip: 'Wikipedia'
					};
					this.logger.log('excel export detected statistics cell', pivotCell);
				}

				excelCell.border = {
					bottom: borderStyle,
					left: borderStyle,
					right: borderStyle,
					top: borderStyle
				};
				if (this.tab.track.id === 'e') {
					return this.trackE.formatExcelCell(this.pivotGrid, {pivotCell, excelCell});
				} else if (this.tab.track.id === 'a' && this.tab.report.canViewEmptyCells) {
					return this.trackA.formatOSPIExcelCell(this.pivotGrid, {pivotCell, excelCell}, boldFont);
				}
			}
		}).then(() => {
			// only the column headers
			const numColumnHeaders = numColumns - numRowHeaders;
			const saoLogo = workbook.addImage({
				base64: saoBase64,
				extension: 'png'
			});
			worksheet.addImage(saoLogo, {
				tl: {col: 0, row: 0},
				ext: {width: 396, height: 72}
			});
			// merge logo cells (2 high for title+subtitle, and as wide as header rows in PG)
			// merge by start row, start column, end row, end column
			worksheet.mergeCells(1, 1, 2, numRowHeaders);
			// change first column width to fit logo
			worksheet.getColumn('A').width = 60;
			// merge title cells
			worksheet.mergeCells(1, numRowHeaders + 1, 1, numColumns);
			// merge subtitle cells
			worksheet.mergeCells(2, numRowHeaders + 1, 2, numColumns);

			const fileName = this.summary.title.replace(/:/g, ',');
			const title = this.summary.heading + ': ' + this.summary.subtitle;
			const subtitle = this.summary.info;

			// Cell Style : Fill and Border
			const headerFill: Fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {argb: 'ff3957AA'}
			};

			// configure title and subtitle formatting
			const headerRows = worksheet.getRows(1, 2);
			headerRows.forEach(r => {
				// copy the object so we don't inadvertently change properties later
				r.font = Object.assign({}, headerFont);
				r.alignment = {vertical: 'middle', wrapText: true};

				// Fill in header cells (this technically fills in 1 more than we need but 🤷‍)
				r.getCell(1).fill = headerFill;
				r.getCell(numRowHeaders + 1).fill = headerFill;
			});
			// set height to accomodate image
			worksheet.getRow(1).height = 40;

			const titleCell = worksheet.getRow(1).getCell(numRowHeaders + 1);
			titleCell.value = title;
			titleCell.font.size = 15;
			titleCell.font.bold = true;
			titleCell.alignment = {horizontal: 'left', vertical: 'middle'};
			const subtitleCell = worksheet.getRow(2).getCell(numRowHeaders + 1);
			subtitleCell.value = subtitle;
			subtitleCell.alignment = {horizontal: 'left', vertical: 'middle'};

			// FIT logo goes in corner cell
			worksheet.unMergeCells('A3');
			worksheet.mergeCells('A3:A4');
			const cornerCell = worksheet.getCell('A3');
			const fitLogo = workbook.addImage({
				base64: fitBase64,
				extension: 'png'
			});
			worksheet.addImage(fitLogo, {
				tl: {col: 0, row: 2},
				ext: {width: 135, height: 40},
			});
			cornerCell.fill = headerFill;

			// colorize top two column headers
			const gradient = this.gradientService.generate(
				this.color.neutral.dark, this.color.neutral.medium, numColumnHeaders
			);
			// start at row 3 and grab two rows
			worksheet.getRows(3, 2).forEach(row => {
				// iterate over the gradient. This should contain the same number of items as numColumnHeaders
				gradient.forEach((color, index) => {
					// Get the cell by skipping past the number of rowHeaders (plus one, because getCell is 1-based)
					// and then skip to the index to apply the appropriate color from the gradient
					const cell = row.getCell(numRowHeaders + 1 + index);
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						// ugh, exceljs won't read hashed hex codes, because that's not a standard, is it? Split the
						// string on # and take the second part of the string
						fgColor: {argb: color.split('#')[1]},
					};
					cell.font = {color: {argb: 'ffffffff'}};
				});
			});

			// create excel file
			workbook.xlsx.writeBuffer()
				.then(function (buffer: BlobPart) {
					saveAs(new Blob([buffer], {type: 'application/octet-stream'}), [fileName + '.xlsx']);
				});
		});
	};


	onContentReady = (event) => {
		// add gradient to top column
		this.paintTopColumn(event);

		if (this.pivotGridData) {
			// ContentReady should fire twice. Only signal parent that we're ready once ctrl.pivotGridData
			// has been populated to be sure filters are loaded from storage AND fields have been
			// rebuilt (ie, includes ids to use for querying via PivotGridDataSource.field('id'))
			this.ready.emit(this.isDataLoaded = true);
		}

		// set scrollbars to always visible
		const scrollable = Scrollable.getInstance(event.element.querySelector('.dx-pivotgrid-area-data.dx-scrollable'));
		scrollable.option('showScrollbar', 'always');
		// this.logger.log(`PivotGrid.onContentReady`, event, scrollable);
	};

	load = () => {
		this.logger.log(
			'PivotGrid.customLoad',
			this.tab,
			`Has state? ${this.tab.pivotGridState != null}`,
			`track and report ${this.tab.track.id} ${this.tab.report.id}`
		);
		// If there is no state on the tab, then conditionally expand some rows
		// Also sanity check the PivotGridDataSource to verify a field from Operating Results Analysis is present since
		// this will fire twice when performing navigation (e.g. Government Profile -> Report on a tab that previously
		// had a Report loaded
		if (this.tab.pivotGridState == null && this.pivotGridData.field('operatingResultsTopLevelId') != null) {
			// Do not set the fields to keep from overwriting the "new" field config
			this.tab.pivotGridState = {rowExpandedPaths: []};
			// conditionally expand rows
			this.expandOperatingResultsRows(this.tab.pivotGridState);
		}
		return this.tab.pivotGridState;
	};

	save = gridState => {
		this.logger.log('PivotGrid.customSave', this.tab, gridState);
		// customSave gets called in undesired scenarios
		const isPopulated = Object.keys(gridState).some(v => gridState[v].length > 0);
		if (isPopulated) {
			this.tab.pivotGridState = gridState;
			this.tabService.save(this.tab);
		}
	};

	// Spec http://saosp/WorkingSites/FIT/Bonanza/FIT%20Reporting%20-%20Data%20Browser%20Specification.xlsm A27:B28
	expandOperatingResultsRows = (pivotGridState) => {
		if (this.tab.report.id === 'operatingResultsAnalysisGovernmental') {
			pivotGridState.rowExpandedPaths.push(['G_CHG']); // must expand top level first
			pivotGridState.rowExpandedPaths.push(['G_CHG', 'G_NETREV']);
			pivotGridState.rowExpandedPaths.push(['G_CHG', 'G_NETOTH']);
			pivotGridState.rowExpandedPaths.push(['G_CHG', 'G_NETSPEC']);
		} else if (this.tab.report.id === 'operatingResultsAnalysisEnterprise') {
			pivotGridState.rowExpandedPaths.push(['E_CHG']);
			pivotGridState.rowExpandedPaths.push(['E_CHG', 'E_NETOP']);
			pivotGridState.rowExpandedPaths.push(['E_CHG', 'E_NETNOP']);
			pivotGridState.rowExpandedPaths.push(['E_CHG', 'E_NETOTH']);
		}
	};

	// HTML rendering
	// https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxPivotGrid/Configuration/#onCellPrepared
	onCellPrepared(event: any) {
		this.attachEvents(event);

		this.accumulateColumnHeaderCells(event);
		// this.logger.log(`pg cell`, event.cell);
		// get the drill down (facts) - this is async/Promise
		this.getDrillDown(event.cell).load().then((rows: any) => {
			if (this.tab.report.financialsDatasetSource === 'OSPI') {
				this.trackA.formatOSPIPivotGridCell(event);
			} else if (this.tab.track.id === 'e') {
				this.trackE.formatPivotGridCell(event);
				// Operating Results Analysis need to "negate" certain values. This is all hard-coded, made on numerous assumptions
				// and therefore fragile. Change with extreme caution!
			} else if (this.tab.report.id === 'operatingResultsAnalysisEnterprise' || this.tab.report.id === 'operatingResultsAnalysisGovernmental') {
				// TODO - possibly add currencySign: 'accounting' once available in the Intl.NumberFormat options
				const formatter = Intl.NumberFormat('en-US', {
					style: 'currency',
					maximumFractionDigits: 0,
					minimumFractionDigits: 0,
					currency: 'USD'
				});
				// assumes snapshot.operatingResultsSubSections.ids are unique values throughout the system, including any other
				// field's values within Operating Results Analysis (e.g. E_OPEXP, E_NOPEXP, etc)
				const isInSubSectionToBeNegated = event.cell.rowPath?.filter(x => this.operatingResultsAnalysisSubSectionIdsToNegate.includes(x))?.length > 0;
				if (isInSubSectionToBeNegated && event.cell.value != null) {
					event.cellElement.innerHTML = formatter.format(event.cell.value * -1);
				}
			}
		});
	}

	private getDrillDown = (cell: any) =>
		this.pivotGridData.createDrillDownDataSource(cell);

	contextMenu = event => {
		this.logger.log('PivotGrid.onContextMenuPreparing', event);
		this.menuService.generate(event, this);
	};

	private createTooltip = (cellElement, text: string) => {
		const container = document.createElement('div');
		cellElement.appendChild(container);
		const tooltip = new Tooltip(container, {
			target: cellElement,
			visible: false,
			showEvent: 'mouseenter',
			hideEvent: 'mouseleave',
			contentTemplate: content => {
				const label = document.createElement('div');
				label.innerHTML = `${text}`;
				content.appendChild(label);
			}
		});
	};

	accumulateColumnHeaderCells(event) {
		// column area, no grand totals
		if (event.area === 'column' && event.cell.type === 'D') {
			if (event.rowIndex === 0 && event.columnIndex === 0 && this.columnHeaderCells.length > 0) {
				// this.logger.log('PivotGrid.accumulateColumnHeaderCells reset');
				this.columnHeaderCells = [];
				this.columnSubHeaderCells = [];
			}
			if (event.rowIndex === 0) {
				// this.logger.log('PivotGrid.accumulateColumnHeaderCells header detected', event);
				this.columnHeaderCells.push(event);
			}
			if (event.rowIndex === 1) {
				// this.logger.log('PivotGrid.accumulateColumnHeaderCells subheader detected', event);
				this.columnSubHeaderCells.push(event);
			}
		}
	}

	paintTopColumn(event: any) {

		const gradient = this.gradientService.generate(
			this.color.neutral.dark, this.color.neutral.medium, this.columnHeaderCells.length
		);

		this.columnHeaderCells.forEach((cell, index) => {
			cell.cellElement.style.color = 'white';
			cell.cellElement.style.background = gradient[index];
			cell.cellElement.classList.add('first');
		});

		const subGradient = this.gradientService.generate(
			this.color.neutral.dark, this.color.neutral.medium, this.columnSubHeaderCells.length
		);

		this.columnSubHeaderCells.forEach((cell, index) => {
			cell.cellElement.style.color = 'white';
			cell.cellElement.style.background = subGradient[index];
			cell.cellElement.classList.add('first');
		});
	}

	setIsComparisonDialogVisible(value): void {
		this.isComparisonDialogVisible = value;
	}

	shouldShowPopover(): boolean {
		return this._user !== null &&
			this.isDataLoaded &&
			this.tab.report.id !== 'debtAndLiabilities' && // no options for this report
			this.tab.showPopupNotification &&
			(
				typeof this._user.hasReadReportPopupNotification === 'undefined' ||
				this._user.hasReadReportPopupNotification === false
			);
	}

	closePopover(): void {
		this.userService.setHasReadReportPopupNotification(true);
		this.tab.showPopupNotification = false;
		this.tabService.save(this.tab);
	}

	/**
	 * @param onCellPreparedEvent
	 */
	attachEvents = (onCellPreparedEvent) => {
		const field = this.cellService.getRelatedFieldForCell(onCellPreparedEvent, this.pivotGridData);
		// attach BARS account descriptions
		if (this.cellService.isBARSAccountField(field?.name)) {
			// console.log('attaching for bars context', field.name);
			const cellTextElement = onCellPreparedEvent.cellElement.querySelectorAll(':scope > span')[0];
			cellTextElement.addEventListener('mouseenter', this.accountHover(onCellPreparedEvent));
			cellTextElement.addEventListener('mouseleave', this.accountLeave);
		}
	}

	accountHover = (onCellPreparedEvent) => {
		return (event) => {
			this.barsDescriptionTarget = event.target;
			this.barsDescriptionId = onCellPreparedEvent.cell.path[onCellPreparedEvent.cell.path.length - 1];
		};
	}

	accountLeave = () => {
		this.barsDescriptionTarget = null;
		this.barsDescriptionId = null;
	}

}
