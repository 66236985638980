import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wasao-annual-filing-explanation',
  templateUrl: './annual-filing-explanation.component.html',
  styleUrls: ['./annual-filing-explanation.component.scss']
})
export class AnnualFilingExplanationComponent implements OnInit {

	@Input() dueDate: Date;

  constructor() { }

  ngOnInit(): void {
  }

}
