import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundCategoryLabelPipe } from './pipes/fund-category.pipe';



@NgModule({
	declarations: [FundCategoryLabelPipe],
	exports: [
		FundCategoryLabelPipe
	],
	imports: [
		CommonModule
	]
})
export class FundServiceModule { }
