<div class="dx-field">
	<div class="dx-field-label">From the dropdown</div>
	<div class="dx-field-value">
		<dx-lookup
			class="account-chooser-freeform__lookup"
			[dataSource]="lookup.dataSource"
			[searchExpr]="['displayName', 'displayCode']"
			[displayExpr]="displayExpr"
			[value]="initialSelection?.key"
			[disabled]="false"
			valueExpr="key"
			placeholder="Type an account code or category name to filter the list"
			(onSelectionChanged)="lookupOnSelectionChanged($event)"
			(onInitialized)="this.lookup.component = $event.component"
		>
			<dxo-drop-down-options
				[closeOnOutsideClick]="true"
				[showTitle]="false"
			></dxo-drop-down-options>

			<div *dxTemplate="let data of 'item'">
				<span
					(mouseenter)="showBARSAccountTooltip($event, data)"
					(mouseleave)="hideBARSTooltip()"
				>
					<span *ngIf="showAccountCodes && data.displayCode">{{ data.displayCode }} - </span>
					<span>{{ data.displayName }}</span>
				</span>
			</div>
		</dx-lookup>
	</div>
</div>
<div class="dx-field">
	<div class="dx-field-label">OR</div>
	<div class="dx-field-value">
	</div>
</div>
<div class="dx-field">
	<div class="dx-field-label">
		From the hierarchy
	</div>
	<div class="dx-field-value hiTree">
		<dx-tree-view
			[dataSource]="treeView.dataSource"
			dataStructure="plain"
			parentIdExpr="parentKey"
			activeStateEnabled="true"
			keyExpr="key"
			selectByClick="true"
			[searchExpr]="['displayName', 'displayCode']"
			hasItemsExpr="hasChildren"
			selectionMode="single"
			(onItemSelectionChanged)="treeViewOnItemSelectionChanged($event)"
			(onInitialized)="treeView.component = $event.component"
		>
			<div class="account-chooser-freeform__account-item" *dxTemplate="let data of 'item'">
				<span (mouseenter)="showBARSAccountTooltip($event, data)" (mouseleave)="hideBARSTooltip()">
					<span class="account-chooser-freeform__account-item__name">{{ data.displayName }}</span>
					<span *ngIf="showAccountCodes && data.displayCode" class="account-chooser-freeform__account-item__code"> ({{ data.displayCode }})</span>
				</span>
			</div>
		</dx-tree-view>
	</div>
</div>
<wasao-bars-description [id]="tooltipBARSAccountId" [target]="tooltipBARSAccountTarget"></wasao-bars-description>
