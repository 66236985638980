import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Population} from './models/population';
import {map, switchMap} from 'rxjs/operators';
import {ODataCollectionResult} from '../odata-collection-result';
import {PopulationRanking} from '../../info/rankings/models/ranking';
import {ExternalCommonServiceApiService} from '../external-common-service-api/external-common-service-api.service';
import {ShareInProgress} from '../../decorators/share-in-progress';

@Injectable({
	providedIn: 'root'
})
export class ExternalLGDataService {

	private apiUrl = environment?.apis?.externalLGData ?? `https://portal.sao.wa.gov/gisdata/api/v2`;

	constructor(
		private httpClient: HttpClient,
		private commonService: ExternalCommonServiceApiService
	) {
	}

	/**
	 * Get Populations for all governments and years.
	 * @param filter string - ODataFilter
	 */
	@ShareInProgress
	getPopulations(filter?: string): Observable<Array<Population>> {
		const url = this.getUrlWithFilter('Populations', filter);
		return this.httpClient.get<ODataCollectionResult<Population>>(url)
			.pipe(map(result => result.value));
	}

	@ShareInProgress
	getPopulationsForMCAG(mcag: string): Observable<Array<Population>> {
		return this.getPopulations(`mcag eq '${mcag}'`);
	}

	@ShareInProgress
	getPopulationRankingsForGovernment(mcag: string, startYear: number, endYear: number) {
		return this.commonService.getEntityDetails(mcag).pipe(
			switchMap(government =>
				this.getPopulationRankingsByGovType(government.GovTypeCode, startYear, endYear, `mcag eq '${mcag}'`)
			)
		);
	}

	/**
	 * Gets population rankings by government type
	 *
	 * @param govTypeCode
	 * @param startYear
	 * @param endYear
	 * @param filter
	 */
	@ShareInProgress
	getPopulationRankingsByGovType(govTypeCode: string, startYear: number, endYear: number, filter?: string) {
		const url = this.getUrlWithFilter(`Populations/GetRankings(startYear=${startYear},endYear=${endYear},govType=\'${govTypeCode}\')`, filter);
		const request      = this.httpClient.get<ODataCollectionResult<PopulationRanking>>(url);

		return request.pipe(map((result) => result.value));
	}

	/**
	 * Generates a full url based on path with optional filter applied.
	 * @param path
	 * @param filter
	 * @private
	 */
	private getUrlWithFilter(path: string, filter: string) {
		let url = `${this.apiUrl}/${path}`;
		if (filter) {
			url += `?$filter=${filter}`;
		}
		return url;
	}
}
