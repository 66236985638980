import {SummaryYearItem} from './summary-year-item';

export abstract class SummaryItem {
	years: Array<SummaryYearItem> = [];

	/**
	 * The year of data that should be used for single-year displays of this data.
	 *  (E.g. BARSYearUsed for SAOAnnualFiling)
	 */
	displayYear: number;

	/**
	 * Convenience method that links to the SummaryYearItem with the isDisplayYear flag set to true
	 */
	get displayYearSummary() {
		return this.years?.find(x => x.year === this.displayYear);
	}
}
