<div class="toolbar">
	<div>
		<dx-button
			text="Generate"
			(onClick)="generate()"
		></dx-button>
	</div>
	<div *ngIf="hasExistingRecords">
		<fa-icon [icon]="warningIcon" class="warning"></fa-icon>
		Local Government Shapes already exist for this vintage year. Generating a new set will overwrite the existing ones.
	</div>
</div>
<div class="grid-container">
	<dx-data-grid
		[dataSource]="dataSource"
		[disabled]="isLoading"
	>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxi-column dataField="mcag" caption="Government" dataType="string">
			<dxo-lookup [dataSource]="governmentsLookup" valueExpr="MCAG" displayExpr="EntityName"></dxo-lookup>
		</dxi-column>
	</dx-data-grid>
</div>
<wasao-loading [isLoading]="isLoading" message="Generating shapes..."></wasao-loading>
