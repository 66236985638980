import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {DataSourceService} from 'app/shared/services/data-source.service';
import {FilterBuilderService} from 'app/shared/services/filter-builder.service';
import {LoggerService} from 'app/shared/services/logger.service';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {
	AdjustmentType, FundReset,
	Projection,
	ProjectionEdit,
	ProjectionEditRow,
	ProjectionEditYear,
	ProjectionRow
} from './projection';
import {Schedule1Detail} from 'app/shared/models/schedules/schedule1-detail';
import {StorageService} from 'app/shared/services/storage.service';
import {TabService} from 'app/shared/services/tab.service';
import {User} from 'app/shared/models/user';
import {UserService} from 'app/shared/services/user.service';
import {FilingStatusService} from '../../modules/services/filing-status-service/filing-status.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class ProjectionService {

	constructor(
		private dataSource: DataSourceService,
		private filterBuilder: FilterBuilderService,
		private snapshotService: SnapshotService,
		private logger: LoggerService,
		private storageService: StorageService,
		private filingStatus: FilingStatusService,
		private tabService: TabService,
		private userService: UserService
	) {
		this.userService.user.subscribe(user => {
			this._user = user;
			this.getAll().then(result => this.projections.next(this._projections = result));
		});
	}

	private accountLevels = ['primeId', 'basicAccountId', 'subAccountId', 'elementId', 'subElementId'];

	public drawerIsOpen                     = new Subject<boolean>();
	public resetEditor      				= new Subject<FundReset>();
	public editingMultipleFunds             = new Subject<boolean>();
	public editorFundValue                  = new Subject<string>();
	private _projections: Array<Projection> = [];
	public projections                      = new BehaviorSubject<Array<Projection>>([]);
	private _user: User;

	loadedProjection: Projection = null;
	savedProjectionId                       = new Subject<number>();

	launch = () => {
		this.getAll().then(result => this.projections.next(this._projections = result));
		this.drawerIsOpen.next(true);
	}

	/**
	 * Projection data sources can only be built for the latest snapshot, so this is handled internally.
	 * This returns a single year (BarsYearUsed) so that all other calculations can be based off of that.
	 * Note: The ProjectionEditRow.id field (and parentId field) are of the format '[{bars account id}][|{expenditure object id}]'
	 * e.g., '1902' for the primeId level expenditures, '|40/50' for the expenditureObjectId Level,
	 *       '999|40/50' for levels below expenditure object, '999' for revenue rows
	 */
	buildEditorDataSource = (mcag: string, projectionCount: number): Promise<ProjectionEdit> => {

		const dataSource = new Promise<ProjectionEdit>((resolve, reject) => {
			this.snapshotService.getSnapshot().subscribe(snapshot => {
				this.getBaseYear(mcag, snapshot.id).subscribe( displayYear => {
					this.dataSource.getStore(snapshot.id, 'Schedule1Details').load({
						filter:
							this.filterBuilder.groupOperationByObjectKey({
								mcag: mcag,
								year: [displayYear - 1, displayYear]
							})
					}).then((schedule1Details: Array<Schedule1Detail>) => {
						const result = new ProjectionEdit();
						result.baseYear = displayYear;
						const accountDescriptors = snapshot.detail.accountDescriptors;
						const expenditureObjects = snapshot.detail.expenditureObjects;
						schedule1Details.filter(s1Row => s1Row.fundNumber && s1Row.amount && s1Row.amount !== 0).forEach(s1Row => {
							s1Row = new Schedule1Detail(s1Row);

							// Get or create projection edit rows for this fund.  Since this method also handles creation of records, the first time a fund is
							// created, only provide the base year's fund name since they may have changed since prior year
							const fundProjectionRows = result.getProjectionEditRows(s1Row.fundNumber, s1Row.year === result.baseYear ? s1Row.fundName : null, s1Row.year === result.baseYear ? s1Row.fundTypeId : null);
							let rowExpenditureObjectId: string = null;

							// Our base and previous years balance rows in the treelist editor are based on annual filing data and will not be editable
							if (s1Row.isBeginningBalance() || s1Row.isEndingBalance()) {
								let code: string;
								let name: string;
								let category: string;
								if (s1Row.isBeginningBalance()) {
									code = 'BEGIN';
									name = 'Beginning Balances';
									category = '000';
								} else {
									code = 'END';
									name = 'Ending Balances';
									category = '999';
								}

								const projectionRow = fundProjectionRows.find(x => x.id === this.generateCompoundAccountId(code));
								if (projectionRow) {
									projectionRow.plusUpAmountsForDataLoad(s1Row, result.baseYear, true);
								} else {
									const defaultProjectionYears = this.prepareBalanceProjectionYears(projectionCount);
									// Create a new row
									const row = new ProjectionEditRow();
									row.id = this.generateCompoundAccountId(code);
									row.rowType = 'balances';
									row.hasChildren = false;
									row.name = name;
									row.nameWithCategory = name;
									row.categoryDisplay = category;
									row.priorYearAmount = s1Row.year === result.baseYear - 1 ? s1Row.amount : null;
									row.baseYearAmount = s1Row.year === result.baseYear ? s1Row.amount : null;
									row.difference = 0;
									row.futureYears = defaultProjectionYears;

									fundProjectionRows.push(row);
								}

							} else if (s1Row.isRevenueOrOtherIncrease() || s1Row.isExpenditureOrOtherDecrease()) {

								// We insert any Exependiture Object Id as a level in the tree editor just below the 500 - Expenditures Prime Account
								if (s1Row.isExpenditureOrOtherDecrease()) {
									rowExpenditureObjectId = s1Row.expenditureObjectId;

									// Find expenditure object row if it exists already in the editor data being prepared
									const projectionRow = fundProjectionRows
										.find(x => x.id === this.generateCompoundAccountId(null, rowExpenditureObjectId));
									if (projectionRow) {
										projectionRow.plusUpAmountsForDataLoad(s1Row, result.baseYear);
									} else { // Create new expenditure object row in the editor data
										const expenditureObject = expenditureObjects.find(eo => eo.id === rowExpenditureObjectId);
										if (expenditureObject) {
											const defaultProjectionYears = this.prepareDefaultProjectionYears(s1Row, result.baseYear, projectionCount);
											// Create a new row
											const row = new ProjectionEditRow();
											row.id = this.generateCompoundAccountId(null, rowExpenditureObjectId);
											row.rowType = 'expenditureObjectId';
											row.parentId = '1902';  // Hardcoded to Expenditure Prime Id
											row.hasChildren = true;
											row.name = expenditureObject.name;
											row.nameWithCategory = `${expenditureObject.name} (${rowExpenditureObjectId})`;
											row.categoryDisplay = rowExpenditureObjectId;
											row.priorYearAmount = s1Row.year === result.baseYear - 1 ? s1Row.amount : null;
											row.baseYearAmount = s1Row.year === result.baseYear ? s1Row.amount : null;
											row.difference = 0;
											row.futureYears = defaultProjectionYears;

											fundProjectionRows.push(row);
										}
									}
								}

								// look at every prescribed account/bars level on the schedule1Detail row and create an editor row if it does not exist
								for (const level of this.accountLevels) {
									const schedule1BarsAccountForLevel = s1Row[level];
									if (schedule1BarsAccountForLevel) {
										// if the level is not null, look up the account in the account descriptors
										const account = accountDescriptors.find(a => a.id === schedule1BarsAccountForLevel);
										if (account) { // this should never be falsy
											const projectionRow = fundProjectionRows
												.find(x => x.id === this.generateCompoundAccountId(account.id, rowExpenditureObjectId));
											// if row found, plus up amounts
											if (projectionRow) {
												projectionRow.plusUpAmountsForDataLoad(s1Row, result.baseYear);
											} else { // otherwise add new row
												const defaultProjectionYears = this.prepareDefaultProjectionYears(s1Row, result.baseYear, projectionCount);

												// Create a new row
												const row = new ProjectionEditRow();
												row.id = this.generateCompoundAccountId(account.id, rowExpenditureObjectId);
												row.expenditureObjectId = rowExpenditureObjectId;
												row.rowType = level;
												row.parentId = this.generateCompoundAccountId(account.parentId);
												row.hasChildren = account.hasChildren;
												row.name = account.name;
												row.nameWithCategory = `${account.name} (${account.categoryDisplay})`;
												row.categoryDisplay = account.categoryDisplay;
												row.priorYearAmount = s1Row.year === result.baseYear - 1 ? s1Row.amount : null;
												row.baseYearAmount = s1Row.year === result.baseYear ? s1Row.amount : null;
												row.difference = 0;
												row.futureYears = defaultProjectionYears;

												// Override parent for basicAccountId level if expenditure
												if (rowExpenditureObjectId && level !== 'primeId') {
													if (level === 'basicAccountId') {
														row.parentId = this.generateCompoundAccountId(null, rowExpenditureObjectId);
													} else {
														row.parentId = this.generateCompoundAccountId(account.parentId, rowExpenditureObjectId);
													}
												}

												if (level === 'primeId' && s1Row.isRevenueOrOtherIncrease()) {
													row.name = 'Revenues and Other Increases';
													row.nameWithCategory = row.name;
												} else if (level === 'primeId' && s1Row.isExpenditureOrOtherDecrease()) {
													row.name = 'Expenditures and Other Decreases';
													row.nameWithCategory = row.name;
												}

												fundProjectionRows.push(row);
											}

											// If we've hit the lowest level for this Schedule 1 record, then stop processing Bars levels
											if (s1Row.barsAccountId === account.id) {
												break;
											}
										}
									}
								}
							}
						});

						// TODO - Add spacers (nice to have)

						// Once data loading & transformation is complete, then calculate balances for projection years
						result.calculateBalances();

						// For any rows that are not editable and are not balances, set AdjustmentType to null so adjustment values are hidden
						result.funds.forEach(f => f.editRows
							.filter(r => !r.isEditable && r.rowType !== 'balances')
							.forEach(r => r.futureYears
								.forEach(y => {
									y.adjustmentType = null;
									y.adjustmentValue = null;
									y.amount = null;
								})));

						resolve(result);
					});
				});
			});
		});

		return dataSource;
	}

	getBaseYear(mcag: string, snapshotId: SnapshotId) {
		return this.filingStatus.getFilingYearForDisplay(mcag, snapshotId);
	}

	/**
	 * Generates the compound id we will use on the ProjectEditRow & ProjectionRow
 	 */
	generateCompoundAccountId = (accountId: number | string, expenditureObjectId: string = null): string => {
		const accountString = accountId?.toString();
		if (expenditureObjectId && accountString !== '1902') {
			return `${accountString ?? ''}|${expenditureObjectId}`;
		} else {
			return accountString;
		}
	}

	/**
	 * Prepares default projection years for initial data load or resets (applies to editable rows in the treelist)
	 * @param s1Row
	 * @param baseYear
	 * @param projectionCount
	 */
	prepareDefaultProjectionYears = (s1Row: Schedule1Detail, baseYear: number, projectionCount: number) => {
		const defaultProjectionYears = new Array<ProjectionEditYear>();
		// if it's the prior year that's encountered first, we need to set to 0
		// to keep from counting the prior year value
		const baseYearAmount = s1Row.year === baseYear ? s1Row.amount : 0;
		for (let i = 0; i < projectionCount; i++) {
			defaultProjectionYears.push(new ProjectionEditYear(baseYearAmount));
		}
		return defaultProjectionYears;
	}

	/**
	 * Prepares balance row (non-editable) projection years for initial data load or resets
	 * @param s1Row
	 * @param baseYear
	 * @param projectionCount
	 */
	prepareBalanceProjectionYears = (projectionCount: number) => {
		const defaultProjectionYears = new Array<ProjectionEditYear>();
		for (let i = 0; i < projectionCount; i++) {
			defaultProjectionYears.push(new ProjectionEditYear(null, null, AdjustmentType.CALCULATED_READONLY));
		}
		return defaultProjectionYears;
	}

	/**
	 * Prepares zero amount (non-editable) projection years for initial data load or resets. These rows have a zero or null amount for
	 * the base year, but we still need to show them since the prior year has data and we need the balances to foot.
	 * @param s1Row
	 * @param baseYear
	 * @param projectionCount
	 */
	prepareZeroProjectionYears = (projectionCount: number) => {
		const defaultProjectionYears = new Array<ProjectionEditYear>();
		for (let i = 0; i < projectionCount; i++) {
			defaultProjectionYears.push(new ProjectionEditYear(null, null, null));
		}
		return defaultProjectionYears;
	}

	/**
	 * Saves the completed projection
	 * @param projection
	 */
	save(projection: Projection) {
		projection.userId = this._user.id;
		return this.storageService.projections.put(projection).then(id => {
			const index = this._projections.findIndex((p) => p.id === id);
			if (index === -1) {
				this._projections.push(projection);
			} else {
				this._projections = this._projections.map(x => x.id === id ? projection : x);
			}
			this.projections.next(this._projections);
		});
	}

	/**
	 * Retrieves the specified projection for use in either the editor or to apply to an LGFRS report
	 * @param projectionId
	 */
	get(projectionId = -1) {
		return this.storageService.projections.get(projectionId);
	}

	/** Retrieves all projections for a given user.
	 */
	getAll() {
		const projections = this.storageService.projections
			.filter(x => x.userId === this._user.id)
			.toArray();
		return projections;
	}

	/** Retrieves all projections for a given user, mcag, and base year.
	 */
	getFor(mcag: string, year: number) {
		return this.storageService.projections
			.filter(x => x.userId === this._user.id)
			.filter(x => x.mcag === mcag)
			.filter(x => x.baseYear === year)
			.toArray();
	}

	/** Deletes a specified projection
	 */
	delete(projectionId: number) {
		this.storageService.projections.delete(projectionId).then(() => {
			this.tabService.removeProjectionFromTabs(projectionId);
			this._projections = this._projections.filter(projection => projection.id !== projectionId);
			this.projections.next(this._projections);
		});
	}

	resetEditedFunds (funds: FundReset) {
		this.resetEditor.next(funds);
	}

	getProjectionRow(projection: Projection, s1Row: Schedule1Detail, yearIndex: number): ProjectionRow {
		let projectionRow: ProjectionRow;
		const isExpenditure = s1Row.primeId === 1902;
		// Check for matches based on BARS account
		for (const level of this.accountLevels) {
			let lookupId = s1Row[level].toString();
			if (level !== 'primeId') {
				lookupId = this.generateCompoundAccountId(s1Row[level], isExpenditure ? s1Row.expenditureObjectId : null);
			}
			projectionRow = projection.adjustmentRows
				.find(r => r.rowType === level && r.fundNumber === s1Row.fundNumber && r.accountId === lookupId && r.futureYearAdjustments[yearIndex] != null);
			if (projectionRow) {
				break;
			}
		}

		// Check with expenditure object alone if no projection row found yet
		if (!projectionRow && isExpenditure) {
			const lookupId = this.generateCompoundAccountId(null, s1Row.expenditureObjectId);
			projectionRow = projection.adjustmentRows
				.find(r => r.rowType === 'expenditureObjectId' && r.fundNumber === s1Row.fundNumber && r.accountId === lookupId && r.futureYearAdjustments[yearIndex] != null);
		}

		return projectionRow;
	}

	loadProjectionForEdit(projection: Projection) {
		this.loadedProjection = projection;
	}
}
