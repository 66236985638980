import { Injectable } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { FieldService } from 'app/shared/services/field.service';
import { FormatService } from 'app/shared/services/format.service';
import { Track } from './track-interface';
import { Field } from 'app/shared/models/field';
import { Tab } from 'app/shared/models/tab';
import { SortService } from '../sort.service';
import { PrimaryGovernmentPipe } from '../../pipes/primary-government.pipe';

@Injectable({
	providedIn: 'root'
})
export class TrackB2Service implements Track {

	constructor(
		private format: FormatService,
		private user: UserService,
		private field: FieldService,
		private sort: SortService,
		private primaryGovernment: PrimaryGovernmentPipe
	) {
		this.summary = this.summary.bind(this);
		this.revenues = this.revenues.bind(this);
		this.revenuesWithOthers = this.revenuesWithOthers.bind(this);
		this.expenditures = this.expenditures.bind(this);
		this.expendituresWithOthers = this.expendituresWithOthers.bind(this);
	}

	presets() {
		return [{
			key: 'Expenditure Object Comparison By Year',
			requires: { report: ['expenditures', 'expendituresWithOthers'] },
			fields: [
				{ name: 'expenditureObject', row: 0 },
				{ name: 'year', column: 0 },
				{ name: 'government', column: 1 },
				{ name: 'fundCategory', column: 2 },
				{ name: 'fundType', column: 3 }
			]
		}];
	}

	summary(tab: Tab): Array<Field> {
		const self = this;

		// TODO: refactor further to eliminate redundant configurations
		return this.field.getBaseFields(tab, [
			new Field({
				id: 'year',
				name: 'year',
				dataField: 'year',
				caption: 'Year',
				sortingMethod: self.sort.byString,
				selector: function (data) {
					return self.format.getYearText(data, tab);
				},
				groupingConfig: {
					required: true,
					expanded: true,
					column: {
						defaultOrder: 0
					}
				}
			}),
			new Field({
				id: 'government',
				name: 'government',
				dataField: 'mcag',
				caption: 'Government',
				groupingConfig: {
					required: true,
					expanded: true,
					column: {
						defaultOrder: 1
					}
				},
				sortingMethod: function (a, b) {
					const prime = self.primaryGovernment.transform(tab);
					return self.sort.sortGovernment(a, b, prime);
				},
				selector: function (data) {
					return self.format.getGovernmentText(data, tab);
				}
			}),
			{
				name: 'standardSummary',
				width: 150,
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'Standard',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required: true,
					expanded: true,
					row: {
						fixed: true,
						defaultOrder: 0
					},
					mustBeBefore: ['standardAccount', 'standardSubAccount', 'standardElement', 'standardSubElement']
				},
				customizeText: function (data) {
					return self.format.getNameForSectionId(data, tab.snapshotId);
				}
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'Standard',
						id: 'account',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required: true,
					row: {
						fixed: true,
						defaultOrder: 1
					},
					mustBeAfter: ['standardSummary'],
					mustBeBefore: ['standardSubAccount', 'standardElement', 'standardSubElement']
				}
			},
			{
				name: 'standardSubAccount',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'Standard',
						id: 'subAccount',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required: true,
					row: {
						fixed: true,
						defaultOrder: 2
					},
					mustBeAfter: ['standardSummary', 'standardAccount'],
					mustBeBefore: ['standardElement', 'standardSubElement']
				}
			},
			{
				name: 'standardElement',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'Standard',
						id: 'element',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required: true,
					row: {
						fixed: true,
						defaultOrder: 3
					},
					mustBeAfter: ['standardSummary', 'standardAccount', 'standardSubAccount'],
					mustBeBefore: ['standardSubElement']
				}
			},
			{
				name: 'standardSubElement',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'Standard',
						id: 'subElement',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required: true,
					row: {
						fixed: true,
						defaultOrder: 4
					},
					mustBeAfter: ['standardSummary', 'standardAccount', 'standardSubAccount', 'standardElement']
				}
			},
			{
				name: 'debtCapitalExpSummary',
				width: 150,
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required: true,
					expanded: true,
					row: {
						fixed: true,
						defaultOrder: 0
					},
					mustBeBefore: ['debtCapitalExpAccount', 'debtCapitalExpSubAccount', 'debtCapitalExpElement', 'debtCapitalExpSubElement']
				},
				customizeText: function (data) {
					return self.format.getNameForSectionId(data, tab.snapshotId);
				}
			},
			{
				name: 'debtCapitalExpAccount',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'account',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required: true,
					row: {
						fixed: true,
						defaultOrder: 1
					},
					mustBeAfter: ['debtCapitalExpSummary'],
					mustBeBefore: ['debtCapitalExpSubAccount', 'debtCapitalExpElement', 'debtCapitalExpSubElement']
				}
			},
			{
				name: 'debtCapitalExpSubAccount',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'subAccount',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required: true,
					row: {
						fixed: true,
						defaultOrder: 2
					},
					mustBeAfter: ['debtCapitalExpSummary', 'debtCapitalExpAccount'],
					mustBeBefore: ['debtCapitalExpElement', 'debtCapitalExpSubElement']
				}
			},
			{
				name: 'debtCapitalExpElement',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'element',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required: true,
					row: {
						fixed: true,
						defaultOrder: 3
					},
					mustBeAfter: ['debtCapitalExpSummary', 'debtCapitalExpAccount', 'debtCapitalExpSubAccount'],
					mustBeBefore: ['debtCapitalExpSubElement']
				}
			},
			{
				name: 'debtCapitalExpSubElement',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'subElement',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required: true,
					row: {
						fixed: true,
						defaultOrder: 4
					},
					mustBeAfter: ['debtCapitalExpSummary', 'debtCapitalExpAccount', 'debtCapitalExpSubAccount', 'debtCapitalExpElement']
				}
			},
			new Field({
				id: 'expenditureObject',
				name: 'expenditureObject',
				dataField: 'expenditureObjectId',
				caption: 'Expenditure Object',
				width: 150,
				groupingConfig: {
					row: {},
					column: {}
				},
				transitions: {
					filterValues: function (currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					}
				},
				customizeText: function (data) {
					return self.format.getNameForExpenditureId(data, tab);
				}
			}),
			new Field({
				id: 'fundType',
				name: 'fundType',
				dataField: 'fundTypeId',
				caption: 'Fund Type',
				groupingConfig: {
					column: {
						defaultOrder: 3
					}
				},
				transitions: {
					filterValues: function (currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				sortingMethod: function (a, b) {
					return self.sort.sortFundTypes(a, b, tab.snapshotId);
				},
				customizeText: function (data) {
					return self.format.getFundTypeText(data, tab.snapshotId);
				}
			}),
			{
				name: 'fundCategory',
				groupingConfig: {
					column: {
						defaultOrder: 2
					}
				}
			}
		])
		.concat(self.field.getDummyDataField(tab));
	}

	revenues(tab: Tab): Array<Field> {
		const self = this;

		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				area: 'filter',
				filterValues: self.field.filterValuesDefaults['revenues']['summary']
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					required: false
				}
			},
			{
				name: 'debtCapitalExpSummary',
				area: 'filter',
				filterValues: self.field.filterValuesDefaults['revenues']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			},
			{
				name: 'fundCategory',
				groupingConfig: {
					column: {
						defaultOrder: 2
					}
				}
			}
		])
		.concat(self.field.getPrimeCategoryField({
			filterValues: self.field.filterValuesDefaults['revenues']['primeCategory']
		}))
		.concat(self.field.getYearField(tab))
		.concat(self.field.getGovernmentField(tab))
		.concat(self.field.getFundTypeField(tab, {
			groupingConfig: {
				column: {
					defaultOrder: 2
				},
				row: {}
			}
		}))
		.concat(self.field.functionalAccountFilterGroupFields(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
		.concat(self.field.getComparisonField(tab));
	}

	revenuesWithOthers(tab: Tab): Array<Field> {
		const self = this;
		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				filterValues: self.field.filterValuesDefaults['revenuesWithOthers']['summary']
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					required: false
				}
			},
			{
				name: 'debtCapitalExpSummary',
				filterValues: self.field.filterValuesDefaults['revenuesWithOthers']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			},
			{
				name: 'fundCategory',
				groupingConfig: {
					column: {
						defaultOrder: 2
					}
				}
			}
		])
		.concat(self.field.getPrimeCategoryField({
			filterValues: this.field.filterValuesDefaults['revenuesWithOthers']['primeCategory']
		}))
		.concat(self.field.getYearField(tab))
		.concat(self.field.getGovernmentField(tab, {
			groupingConfig: {
				row: {}
			}
		}))
		.concat(self.field.getFundTypeField(tab, {
			groupingConfig: {
				column: {
					defaultOrder: 3
				},
				row: {}
			}
		}))
		.concat(self.field.functionalAccountFilterGroupFields(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
		.concat(self.field.getComparisonField(tab));
	}

	expenditures(tab: Tab): Array<Field> {
		const self = this;

		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				filterValues: self.field.filterValuesDefaults['expenditures']['summary']
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					required: false
				}
			},
			{
				name: 'debtCapitalExpSummary',
				filterValues: self.field.filterValuesDefaults['expenditures']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			},
			{
				name: 'fundCategory',
				groupingConfig: {
					column: {
						defaultOrder: 2
					}
				}
			}
		])
		.concat(self.field.getPrimeCategoryField({
			filterValues: self.field.filterValuesDefaults['expenditures']['primeCategory']
		}))
		.concat(self.field.getYearField(tab))
		.concat(self.field.getGovernmentField(tab, {
			groupingConfig: {
				row: {}
			}
		}))
		.concat(self.field.getExpenditureObjectField(tab, {
			groupingConfig: {
				row: {
					advancedDefaultOrder: 4
				}
			}
		}))
		.concat(self.field.getFundTypeField(tab, {
			groupingConfig: {
				column: {
					defaultOrder: 3
				},
				row: {}
			}
		}))
		.concat(self.field.functionalAccountFilterGroupFields(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
		.concat(self.field.getComparisonField(tab))
		.concat(self.field.expenditureObjectFilterField());
	}

	expendituresWithOthers(tab: Tab): Array<Field> {
		const self = this;

		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				filterValues: self.field.filterValuesDefaults['expendituresWithOthers']['summary']
			},
			{
				name: 'debtCapitalExpSummary',
				filterValues: self.field.filterValuesDefaults['expendituresWithOthers']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			},
			{
				name: 'fundCategory',
				groupingConfig: {
					column: {
						defaultOrder: 2
					}
				}
			}
		])
		.concat(self.field.getPrimeCategoryField({
			filterValues: self.field.filterValuesDefaults['expendituresWithOthers']['primeCategory']
		}))
		.concat(self.field.getYearField(tab))
		.concat(self.field.getGovernmentField(tab, {
			groupingConfig: {
				row: {}
			}
		}))
		.concat(self.field.getExpenditureObjectField(tab, {
			groupingConfig: {
				row: {
					advancedDefaultOrder: 4
				}
			}
		}))
		.concat(self.field.getFundTypeField(tab, {
			groupingConfig: {
				column: {
					defaultOrder: 3
				},
				row: {}
			}
		}))
		.concat(self.field.functionalAccountFilterGroupFields(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
		.concat(self.field.expenditureObjectFilterField())
		.concat(self.field.getComparisonField(tab));
	}

	/**
	 * Debt & Liabilities report
	 * @param tab
	 */
	debtAndLiabilities = (tab: Tab): Array<Partial<Field>> => [
		...this.field.getAnnotationsFields(), // title/subtitle for export
		{
			id:             'year',
			name:           'year',
			dataField:      'year',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		},
		new Field({
			id: 'government',
			name: 'government',
			dataField: 'mcag',
			caption: 'Government',
			groupingConfig: {
				required: true,
				expanded: true,
				column: {
					defaultOrder: 0
				}
			},
			sortingMethod: (a, b) => {
				const prime = this.primaryGovernment.transform(tab);
				return this.sort.sortGovernment(a, b, prime);
			},
			selector: (data) => this.format.getGovernmentText(data, tab)
		}),
		{
			id:             'debtCategory',
			name:           'debtCategory',
			dataField:      'bonanzaCategoryId',
			caption:        'Category',
			groupingConfig: {
				expanded: true,
				row:      {
					fixed:        false,
					defaultOrder: 0
				},
			},
			sortingMethod:  (a, b) => this.sort.sortOnBonanzaSchedule9CategoriesSortOn(a, b, tab.snapshotId),
			customizeText:  (data) => this.format.getNameForSchedule9BonanzaCategoryId(
				data,
				tab?.snapshotId,
				tab?.pivotGridSettings?.showAccountCodes
			),
		},
		{
			id:             'debtType',
			name:           'debtType',
			dataField:      'bonanzaTypeId',
			caption:        'Type',
			groupingConfig: {
				row: {
					fixed:        false,
					defaultOrder: 1
				},
			},
			sortingMethod:  (a, b) => this.sort.sortOnBonanzaSchedule9TypesSortOn(a, b, tab.snapshotId),
			customizeText:  (data) => this.format.getNameForSchedule9BonanzaTypeId(
				data,
				tab?.snapshotId,
				tab?.pivotGridSettings?.showAccountCodes
			),
		},
		{
			id: 'debtCategoryItem',
			name: 'debtCategoryItem',
			dataField: 'debtCategoryItemId',
			caption: 'Debt Item',
			groupingConfig: {
				row: {
					fixed: false,
					defaultOrder: 2
				},
			},
			sortingMethod: (a, b) => this.sort.sortOnSnapshotSortOrder(tab.snapshotId, 'debtCategoryItems', a, b),
			customizeText:  (data) => this.format.nameLookup(
				data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes, 'debtCategoryItems'
			),
		},
		this.field.getMeasureField(tab),
		this.field.getComparisonField(tab)
	];
}
