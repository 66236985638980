import {ReportType} from './report-type-menu-option';

export enum ExploreType {
	individual = 'individual',
	comparison = 'comparison',
	governmentType = 'governmentType',
	category = 'category',
	financialCondition = 'financialCondition'
}

export interface ExploreByOption {
	id: string;
	name: string;
	supportedReportTypes: Readonly<Array<ReportType>>;
}

export const EXPLORE_BY_OPTIONS: Readonly<Array<Partial<ExploreByOption>>> = [
	{ id: 'individual',  name: 'By Individual Government',
		supportedReportTypes: [ ReportType.lgfrs, ReportType.ospi ]
	},
	{ id: 'comparison', name: 'By Comparison',
		supportedReportTypes: [ ReportType.lgfrs ]
	},
	{ id: 'governmentType', name: 'By Government Type',
		supportedReportTypes: [ ReportType.lgfrs, /* ReportType.ospi */ ]
	},
	{ id: 'category', name: 'By Category',
		supportedReportTypes: [ ReportType.lgfrs ]
	},
	{ id: 'financialCondition', name: 'By Financial Condition',
		supportedReportTypes: [ ReportType.lgfrs]
	}
] as const;
