import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ensureArray'
})
export class EnsureArrayPipe implements PipeTransform {

	transform(item: any): Array<any> {
		return Array.isArray(item)
			? item
			: [item];
	}

}
