<div class="stage" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">

	<div class="container">
		<div class="page">
			<div class="print-bar">
				<div>
					<dx-load-indicator [visible]="!isReadyToPrint"></dx-load-indicator>
				</div>
				<div class="u-margin-left-small">
					<span *ngIf="isReadyToPrint; then readyToPrint else waitingToPrint"></span>
					<ng-template #readyToPrint>Click here to print:</ng-template>
					<ng-template #waitingToPrint>Finishing loading graphics...</ng-template>
				</div>
				<dx-button
					text="Print"
					(click)="handlePrint()"
					type="default"
					[disabled]="!isReadyToPrint"
					class="u-margin-left-small"
				></dx-button>
			</div>

			<div class="header">
				<div class="fit-logo">
					<img src="assets/images/fit_isolated.svg" alt="FIT Logo"/>

					<div class="separator"></div>

					<div class="fit-text" href="#home">
						Financial<br/>
						Intelligence<br/>
						Tool
					</div>
				</div>
				<div class="sao-logo">
					<img src="assets/images/sao_seal_print.png"
						 alt="Seal of the Office of the Washington State Auditor"/>
				</div>
			</div>

			<div class="section">
				<div class="title ">
					Government Type Profile:
					<h1 class="entity">
						{{ liveGovTypeDetails?.GovTypeDesc }}
					</h1>
				</div>
			</div>

			<div class="subtitle">
				Report prepared: {{ today | date:'MMMM d, y, h:mm a' }}<br>
				For detailed, interactive analysis visit: {{ profileUrl }}
			</div>

			<h3>About this Government Type</h3>
			<wasao-government-type-information
				[govTypeCode]="govTypeCode"
				[snapshotId]="snapshotId"
				[isInteractive]=false
				[showAuditLink]=false
				[showPieCharts]=false
				displayDensity="print"
				[financialsDatasetSource]="financialsDatasetSource"
			></wasao-government-type-information>

			<ng-container *ngIf="govTypeCanHaveFinancials">
				<h3 [ngClass]="{ 'u-hidden': childrenThatHaveData.financesAtAGlance === false }">Finances at a
					Glance</h3>
				<wasao-finances-at-a-glance
					[ngClass]="{ 'u-hidden': childrenThatHaveData.financesAtAGlance === false }"
					[govTypeCode]="govTypeCode"
					[displayYear]="displayYear"
					[snapshotId]="snapshotId"
					[isInteractive]=false
					displayDensity="print"
					(hasData)="childHasData($event, 'financesAtAGlance')"
				></wasao-finances-at-a-glance>

				<h3>Financial Health Indicators</h3>
				<wasao-financial-health-indicator-statistics
					class="section"
					[snapshotId]="snapshotId"
					[govTypeCode]="govTypeCode"
					[isInteractive]=false
					displayDensity="print"
				></wasao-financial-health-indicator-statistics>

			</ng-container>
		</div>

		<div class="page">
			<ng-container *ngIf="govTypeCanHaveFinancials">
				<h3 [ngClass]="{ 'u-hidden': childrenThatHaveData.governmentalServices === false }">Governmental Services</h3>
				<wasao-governmental-services
					[ngClass]="{ 'u-hidden': childrenThatHaveData.governmentalServices === false }"
					[govTypeCode]="govTypeCode"
					[displayYear]="displayYear"
					[snapshotId]="snapshotId"
					(hasData)="childHasData($event, 'governmentalServices')"
					[isInteractive]=false
					displayDensity="print"
				></wasao-governmental-services>

				<h3 [ngClass]="{ 'u-hidden': childrenThatHaveData.enterpriseServices === false }">
					Enterprise Services</h3>
				<wasao-enterprise-services
					[ngClass]="{ 'u-hidden': childrenThatHaveData.enterpriseServices === false }"
					[govTypeCode]="govTypeCode"
					[snapshotId]="snapshotId"
					(hasData)="childHasData($event, 'enterpriseServices')"
					[isInteractive]=false
					[hasGovServices]="childrenThatHaveData.governmentalServices"
					displayDensity="print"
				></wasao-enterprise-services>

			</ng-container>

		</div>
	</div>
</div>
