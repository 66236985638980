import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FundCategoryPickerComponent} from './fund-category-picker.component';
import {DxSelectBoxModule} from 'devextreme-angular';


@NgModule({
	declarations: [
		FundCategoryPickerComponent
	],
	imports: [
		CommonModule,
		DxSelectBoxModule
	],
	exports: [
		FundCategoryPickerComponent
	]
})
export class FundCategoryPickerModule {
}
