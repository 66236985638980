import {FinancialSummarySection} from '../../../api/fit-api/models/financial-summary';

export class AmountsByCategory {

	label: string;

	/**
	 * 	Do not rely on this! Typically, categories do not cross fsSectionIds, but is not always true.
	 */
	fsSectionId: FinancialSummarySection;

	// TODO this should be standardized to a model
	accountSegment: 'basicAccount' | 'expenditureObject';

	amount: number;
}
