import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {ReusableGovernmentTypeInputs} from '../../reusable/models/reusable-government-type-inputs';
import {GenericCountType, GovernmentType} from '../../api/fit-api/models/government-type';
import {pieChart} from '../../../../../sao-patterns/src/tokens/government-colors.js';

@Component({
	selector:    'wasao-app-government-body-type',
	templateUrl: './government-body-type.component.html',
	styleUrls:   ['./government-body-type.component.scss']
})
export class GovernmentBodyTypeComponent extends ReusableGovernmentTypeInputs implements OnChanges {
	selectedGovernmentType: GovernmentType;
	governingBodyTypesSourceControl: Array<GenericCountType>;
	pieChartColors: Array<string> = pieChart;

	@Input() sizeGroup: string;

	constructor(
		private fitApi: FitApiService
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.govTypeCode?.currentValue) {
			this.isLoading = true;

			this.fitApi.getGovernmentTypes.subscribe(x => {
				this.selectedGovernmentType = x.find(element => element.code === this.govTypeCode);

				// replaces null values with 'no data' if activeCount is more than zero
				this.governingBodyTypesSourceControl = this.selectedGovernmentType.governingBodyTypes.map(e => {
					if (e.activeCount > 0) {
						if (e.name === null) {
							e.name = 'No Data';
						}
						return e;
					}
					else {
						return;
					}
				});
				this.isLoading                       = false;
			});
		}
	}

	customizeLabel = (pointInfo) => `${pointInfo.argumentText}\n(${pointInfo.valueText})`;

}
