import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserInterfaceService} from '../../../shared/services/user-interface.service';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {faMap, faTable} from '@fortawesome/free-solid-svg-icons';
import {Observable} from 'rxjs';
import {SummaryFilingStatus} from '../../services/filing-status-service/models/summary-filing-status';
import {HttpClient} from '@angular/common/http';
import {FeatureCollection} from 'geojson';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {DxSelectBoxComponent} from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import {Reusable} from '../../reusable/models/reusable';
import {environment} from '../../../../environments/environment';
import {ShareInProgress} from '../../decorators/share-in-progress';


class DropDownItem {
	name: string;
	value: any;
	disabled?: boolean;
}

interface MapLayers {
	countyShapes: FeatureCollection;
	countySummaryFilingStatuses: FeatureCollection;
}

type SummaryFilingStatusMode = 'before' | 'after';

@Component({
	selector: 'wasao-annual-filing-current-progress',
	templateUrl: './annual-filing-current-progress.component.html',
	styleUrls: ['./annual-filing-current-progress.component.scss']
})
export class AnnualFilingCurrentProgressComponent extends Reusable implements OnInit {

	@Input() isStandAlone = true;
	@Input() isBannerHidden = false;
	@Input() isCombined = false;
	@Input() resetFilter: Observable<void>;
	@ViewChild('govTypeSelectBox') govTypeSelectBox: DxSelectBoxComponent;

	isVisible = true;
	dataSource: any;
	submissionDueDate: Date;
	currentFilingYearForDisplay: number;
	yearlyFilingStatusForDisplay: SummaryFilingStatus;
	daysTilSubmissionDueDate: number;
	monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December'
	];
	beforeOrOnDueDate: boolean;
	governmentTypesDataSource: Array<DropDownItem> = [{name: 'All Government Types', value: null}];
	selectedGovernmentType: DropDownItem = {name: 'All Government Types', value: null};
	selectedCountyCode: DropDownItem = {name: 'All Counties', value: null};
	chartColorPalette = ['#4C9F83', '#FAB040', '#E8702D', 'rgba(76, 159, 131, 0.3)', 'rgba(250, 176, 64, .3)', 'rgba(232, 112, 45, .3)'];
	countyList = [];
	percentageTotal: number;
	mapShown = true;
	buttonClicked = 'map';
	math = Math;


	public icons = {
		map: faMap,
		table: faTable
	};

	mapLayers: MapLayers;
	// controls dataField and tooltips for pie layer
	summaryFilingStatusMode: SummaryFilingStatusMode;
	loadingMessage: string;

	constructor(
		private uiService: UserInterfaceService,
		private filingStatusService: FilingStatusService,
		private fitApi: FitApiService,
		private http: HttpClient,
		private route: ActivatedRoute
	) {
		super();

		// gov type dropdown
		this.fitApi.getGovernmentTypes.pipe(
			catchError(error => {
				throw new this.errorHandler(error);
			})
		).subscribe(x => {
			x.map(e => {
					// maps to dataSource disables dropdown items that don't have financial dataset source SAOAnnualFiling
					if (e.financialsDatasetSource === 'SAOAnnualFiling' && e.activeCount !== 0) {
						this.governmentTypesDataSource.push({name: e.description, value: e.code});
					} else {
						this.governmentTypesDataSource.push({name: e.description, value: e.code, disabled: true});
					}
				}
			);

			this.standAloneLogic();
		});

		this.filingStatusService.getCurrentYear().pipe(
			catchError(error => {
				throw new this.errorHandler(error);
			}),
			switchMap(currentYear => {
				// this is a shim so that we can test real data (this is the after state)
				// this.currentFilingYearForDisplay = 2020;

				// sets current year
				this.currentFilingYearForDisplay = currentYear;

				// sets dataSource, sets yearly filing for display, sets map
				this.updateDataSource();

				// get filing status and set submission date, days til submission, before due date boolean, and filing status mode
				return this.filingStatusService.getFilingDueDatesForMostGovernments(this.currentFilingYearForDisplay);
			})).subscribe(filingStatus => {
			this.submissionDueDate = new Date(filingStatus.maxAllowedSubmitDate);
			this.daysTilSubmissionDueDate = filingStatus.daysRemainingToSubmit;
			this.beforeOrOnDueDate = filingStatus.filingYearCondition === 'Open';
			this.summaryFilingStatusMode = this.beforeOrOnDueDate ? 'before' : 'after';
		});

		// gets a list of counties
		this.fitApi.getCounties.pipe(
			catchError(error => {
				throw new this.errorHandler(error);
			})
		).subscribe(x => {
			this.countyList = x;
		});

	}

	ngOnInit(): void {
		// reset select boxes (if stand alone, ignore)
		if (this.resetFilter) {
			this.resetFilter.subscribe(() => {
				this.govTypeSelectBox.value = null;
				this.toggleMap();
			});
		}
	}

	updateDataSource() {
		// sets data source
		this.filingStatusService.getSummarizedLiveFilingStatusesByCounty(this.currentFilingYearForDisplay, this.currentFilingYearForDisplay, this.selectedGovernmentType.value).pipe(
			tap(_ => {
				this.isLoading = true;
				this.loadingMessage = 'Loading Map Details';
			}),
			catchError(error => {
				throw new this.errorHandler(error);
			})
		).subscribe(
			element => {
				this.dataSource = element;
				this.isLoading = false;
			});

		// sets year filing status for display
		this.filingStatusService.getSummarizedLiveFilingStatusesByYear(this.currentFilingYearForDisplay, this.currentFilingYearForDisplay, this.selectedGovernmentType.value).pipe(
			catchError(error => {
				throw new this.errorHandler(error);
			})
		).subscribe(x => {
			x.map(e => this.yearlyFilingStatusForDisplay = e);
			this.percentageTotal = this.calculatePercentageOfFilers(this.yearlyFilingStatusForDisplay.timelyFilers, this.yearlyFilingStatusForDisplay.total);
		});

		// sets map layer
		this.getMapLayers(this.currentFilingYearForDisplay, this.selectedGovernmentType.value).pipe(
			catchError(error => {
				throw new this.errorHandler(error);
			})
		).subscribe(x => this.mapLayers = x);
	}

	toggleMap() {
		this.mapShown = true;
		this.buttonClicked = 'map';
	}

	toggleGrid() {
		this.mapShown = false;
		this.buttonClicked = 'grid';
	}

	onGovernmentTypeSelectionChanged(e) {
		this.selectedGovernmentType = e.selectedItem;
		this.updateDataSource();
	}

	/**
	 * Get the county shapes and associated summary filing statuses in pie chart form.
	 * @param year
	 * @param govTypeCode
	 */
	@ShareInProgress
	getMapLayers(year: number, govTypeCode?: string): Observable<MapLayers> {
		return this.filingStatusService.getSummarizedLiveFilingStatusesByCounty(year, year, govTypeCode)
			.pipe(
				switchMap(summaryFilingStatuses => {
					return this.http.get<FeatureCollection>(`${environment.base}/data/wa-counties/wa-counties.json`).pipe(
						map(x => this.createMapLayers(x, summaryFilingStatuses)),
					);
				})
			);
	}

	/**
	 * Creates two layers:
	 *  1. The county shapes.
	 *  2. A pie chart layer that contains the summaryFilingStatus information for each county in the geojson properties.
	 * @param featureCollection
	 * @param summaryFilingStatuses
	 * @private
	 */
	private createMapLayers(featureCollection: FeatureCollection, summaryFilingStatuses: Array<SummaryFilingStatus>): MapLayers {
		const countyShapes = featureCollection;
		// make sure we don't unintentionally overwrite any primitives from countyShapes
		const countySummaryFilingStatuses = Object.assign({}, featureCollection);
		// map to return new references to ensure we don't unintentionally change any features in countyShapes
		countySummaryFilingStatuses.features = countySummaryFilingStatuses.features.map(feature => {
			const countyCode = feature.properties['Code'];
			const summary = summaryFilingStatuses.find(x => x.countyCode === countyCode);
			return {
				type: 'Feature',
				// pull lat/lon off of properties to get the point the markers should show for each county
				geometry: {
					type: 'Point',
					coordinates: [feature.properties['longitude'], feature.properties['latitude']]
				},
				properties: {
					// create values to feed into pie charts, along with any tooltip
					before: summary ? [summary?.filed, summary?.started, summary?.notStarted] : null,
					beforeTooltip: this.getTooltip(feature.properties['NAME_ALT'], 'before', summary),
					after: summary ? [summary?.timelyFilers, summary?.lateFilers, summary?.nonFilers] : null,
					afterTooltip: this.getTooltip(feature.properties['NAME_ALT'], 'after', summary),
					// needed for click-through events
					countyCode: countyCode
				}
			};
		});
		return {countyShapes, countySummaryFilingStatuses};
	}

	private getTooltip(countyName: string, mode: SummaryFilingStatusMode, summaryFilingStatus: SummaryFilingStatus): string {
		let htmlString = `<strong>${countyName}</strong>\n`;
		if (mode === 'before') {
			htmlString += `Filed: ${summaryFilingStatus?.filed} (${this.calculatePercentageOfFilers(summaryFilingStatus?.filed, summaryFilingStatus?.total)}%)\n`
				+ `Started: ${summaryFilingStatus?.started} (${this.calculatePercentageOfFilers(summaryFilingStatus?.started, summaryFilingStatus?.total)}%)\n`
				+ `Not Started: ${summaryFilingStatus?.notStarted} (${this.calculatePercentageOfFilers(summaryFilingStatus?.notStarted, summaryFilingStatus?.total)}%)`;
		} else if (mode === 'after') {
			htmlString += `Timely Filers: ${summaryFilingStatus?.timelyFilers} (${this.calculatePercentageOfFilers(summaryFilingStatus?.timelyFilers, summaryFilingStatus?.total)}%)\n`
				+ `Late Filers: ${summaryFilingStatus?.lateFilers} (${this.calculatePercentageOfFilers(summaryFilingStatus?.lateFilers, summaryFilingStatus?.total)}%)\n`
				+ `Non-Filers: ${summaryFilingStatus?.nonFilers} (${this.calculatePercentageOfFilers(summaryFilingStatus?.nonFilers, summaryFilingStatus?.total)}%)`;
		}
		return htmlString;
	}

	mapClick = (event: any) => {
		// will need to know county that was clicked, as well as any govType (if not 'all')
		const countyCodeFromMarker = event.target.attribute('countyCode');
		const countyCodeFromCounties = event.target.attribute('Code');

		console.log('map was clicked', event, countyCodeFromMarker || countyCodeFromCounties, this.selectedGovernmentType);
		// see FilingStatus@filingReportFilter$ for model
		const untypedFilerReportFilters = {
			year: this.currentFilingYearForDisplay,
			govTypeCode: this.selectedGovernmentType.value,
			countyCodes: countyCodeFromMarker || countyCodeFromCounties,
			governmentStatus: 'Active'
		};
		this.filingStatusService.filingReportIsVisible();
		this.filingStatusService.filingReportIsLiveUnauthenticated();
		this.filingStatusService.filingReportFilter(untypedFilerReportFilters);
	};

	customizeMapTooltip = (arg) => {
		if (arg.layer.type === 'marker') {
			const text = arg.attribute(this.summaryFilingStatusMode + 'Tooltip');
			return {
				text
			};
		}
	};

	standAloneLogic() {
		const govTypeCode = this.route.snapshot.paramMap.get('govTypeCode') as string;

		if (govTypeCode) {
			this.selectedGovernmentType = this.governmentTypesDataSource.find(x => x.value === govTypeCode);
			this.updateDataSource();
		}

	}

	calculateDisplayValue = (cell) => {
		const county = this.countyList.find(x => x.countyCode === cell.countyCode);
		return county.countyName;
	};

	calculatePercentageOfFilers(file: number, total: number) {
		const decimal = (file / total);
		return parseFloat((decimal * 100).toFixed(1));
	}

	errorHandler(err: any) {
		notify({
			closeOnClick: true,
			displayTime: 10000,
			closeOnOutsideClick: true,
			type: 'error',
			message: err.message
		});
	}

}
