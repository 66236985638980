import {Injectable} from '@angular/core';
import {ExtractType} from './extract-type';
import {environment} from '../../../environments/environment';
import {ExtractYear} from './extract-year';
import {HttpClient} from '@angular/common/http';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {UserService} from '../../shared/services/user.service';
import {CodeDescription} from '../../modules/api/fit-api/models/code-description';
import {LocalGovernmentReference} from '../../modules/api/fit-api/models/snapshots/local-government-reference';
import {ShareInProgress} from '../../modules/decorators/share-in-progress';

@Injectable({
	providedIn: 'root'
})
export class DataExtractService {

	baseExtractUrl = environment.base + '/Extracts';
	snapshot: any;

	types: Array<ExtractType> = null;

	private defaultTypes: Array<ExtractType> = [
		new ExtractType(
			'fullDataExtracts',
			'Full Extracts',
			'Download full, single-year extracts of FIT data with reference tables.',
			'Data extracts for each filing year are available for download in Excel 2007 format (.xlsx).',
			true
		),
		new ExtractType(
			'OMWBEExtracts',
			'OMWBE Extracts',
			'Download two-year extracts of capital projects, supplies and other services expenditure totals used by OMWBE.',
			'Expenditure extracts used by the Office of Minority and Women\'s Business Enterprises (OMWBE) for each two-year period are available for download in Excel format (.xlsx). These extracts contain capital projects, supplies and other services expenditure totals for cities, towns, counties, ports, and transportation authorities from the latest published snapshot.',
		),
		new ExtractType(
			'censusDebtBalances',
			'US Census Debt Balances',
			'Download single-year extracts of debt balance totals for use by the US Census.',
			'Debt balance extracts for each filing year are available for download in comma-separated values format (.csv). These extracts contain data from the latest published snapshot for the selected year.',
		),
		new ExtractType(
			'censusRevenuesExpenditures',
			'US Census Revenues & Expenditures',
			'Download single-year extracts of revenues & expenditures for use by the US Census.',
			'Revenue and expenditure extracts for each filing year are available for download in comma-separated values format (.csv). These extracts contain data from the latest published snapshot for the selected year.',
		),
		new ExtractType(
			'debtServiceCoverageRatios',
			'State Partner Debt Service Coverage Ratios',
			'Download single-year extracts of debt service coverage ratios for use by SAO\'s state partners.',
			'Debt service coverage ratio (DSCR) extracts for each filing year are available for download in comma-separated values format (.csv). These extracts contain DSCR calculations as defined by SAO\'s state partners and use the latest published snapshot for the calculations.',
		)
	];

	private globalAccessTypes = [
		new ExtractType(
			'globalOperatingResults',
			'Global Operating Results (Worksheets)',
			'Download single-year worksheet of live FIT data, with built-in variance comparison formulas.',
			'A global operating results worksheet for each filing year (for GAAP governments) is available for download in Excel format (.xlsx) with built-in variance comparison formulas. These worksheets contain live data.',
			false,
			'worksheets'
		),
		new ExtractType(
			'financialHealthIndicators',
			'Financial Health Indicators',
			'Download year-over-year extracts of financial health indicators for internal agency use.',
			'Financial health indicator trend reports (by team, government type, individual government, or all governments) are available for download in Excel format (.xlsx). These reports use the latest published snapshot data.',
			true,
			'',
			false
		),
		new ExtractType(
			'pensionLiabilityVariance',
			'Pension Liability Variances',
			'Download multi-year extracts comparing pension liabilities as reported by the government and DRS.',
			'Participating Employer Financial Information (PEFI) variance reports (accessible by team, government type, individual government, or all governments) are available for download in Excel format (.xlsx). These reports use the live annual filing data and data from the Department of Retirement Systems (DRS).',
			true,
			'',
			false
		)
	];

	constructor(
		private http: HttpClient,
		private snapshotService: SnapshotService,
		private apiService: FitApiService,
		private userService: UserService,
		private fitApi: FitApiService,
	) {
		// set types array to types user has access to based on role
		this.userService.user.subscribe(user => {
			if (user.hasGlobalAccess()) {
				this.types = this.defaultTypes.concat(this.globalAccessTypes);
			} else {
				this.types = this.defaultTypes;
			}
		});
	}

	getExtractYears = (extractType: ExtractType): Observable<Array<ExtractYear>> => {
		switch (extractType?.id) {
			case 'fullDataExtracts':
				return this.getFullExtractYears();
			case 'OMWBEExtracts':
				return this.getOMWBEExtractYears();
			case 'censusDebtBalances':
				return this.getCensusYears('Debt');
			case 'censusRevenuesExpenditures':
				return this.getCensusYears('RevExp');
			case 'debtServiceCoverageRatios':
				return this.getDebtCoverageYears();
			case 'globalOperatingResults':
				return this.getGlobalOperatingYears();
		}

		throw new Error('Extract type undefined: ' + extractType);
	}

	private descendingYearSort = (a: ExtractYear, b: ExtractYear): -1 | 0 | 1 => a.year > b.year ? -1 : 1;

	@ShareInProgress
	private getFullExtractYears(): Observable<Array<ExtractYear>> {
		return this.snapshotService.getSnapshot()
			.pipe(
				filter(x => x != null),
				take(1),
				switchMap(snapshot => {
					this.snapshot = snapshot;
					return this.snapshotService.getYearsWithFullExtracts(snapshot.id).pipe(map(years => {
						const extractYears = new Array<ExtractYear>();
						years.sort().reverse().forEach(y => {
							const url = this.apiService.getAnnualFilingRoute(snapshot.id, `/FullExtract`) + `(year=${y})`;
							const extractYear = new ExtractYear(y, url, snapshot.id);
							extractYears.push(extractYear);
						});
						return extractYears;
					}));
				})
			);
	}

	@ShareInProgress
	private getOMWBEExtractYears(): Observable<Array<ExtractYear>> {
		return this.fitApi.getAnnualFilingSnapshot().pipe(map(snapshot => {
			this.snapshot = snapshot;
			// creates new array out of snapshot included years using just odd years, where a prior year exist
			const years = snapshot.detail.includedYears.filter((x, i, a) => x % 2 !== 0 && typeof a[i - 1] !== 'undefined');
			const extractYears = new Array<ExtractYear>();
				years.sort().reverse().forEach(y => {
					const url = `${this.baseExtractUrl}/OMWBE/(endYear=${y})`;
					const extractYear = new ExtractYear(y, url, snapshot.id, snapshot.dateCreated, `${y - 1}/${y}`);
					extractYears.push(extractYear);
				});
				return extractYears;
			}));

	}

	@ShareInProgress
	private getCensusYears (type: 'RevExp' | 'Debt'): Observable<Array<ExtractYear>> {
		return this.http.get<Array<ExtractYear>>(this.baseExtractUrl + '/Census')
			.pipe(
				map(x => {
					x.map(y => y.url = `${this.baseExtractUrl}/Census/${type}/${y.year}`);
					return x.sort(this.descendingYearSort);
				})
			);
	}

	@ShareInProgress
	private getDebtCoverageYears(): Observable<Array<ExtractYear>> {
		const baseUrl = this.baseExtractUrl + '/DebtCoverage';
		return this.http.get<Array<ExtractYear>>(baseUrl)
			.pipe(
				map(x => x.map(y => {
					y.url = `${baseUrl}/${y.year}`;
					y.dateCreated = null;
					return y;
				}).sort(this.descendingYearSort))
			);
	}

	@ShareInProgress
	private getGlobalOperatingYears(): Observable<Array<ExtractYear>> {
		const baseUrl = this.baseExtractUrl + '/GlobalOperatingResults';
		return this.http.get<Array<number>>(baseUrl)
			.pipe(
				map(x => x.map(y => new ExtractYear(y, `${baseUrl}/${y}`))),
			);
	}

	@ShareInProgress
	public getFHITeams(): Observable<Array<CodeDescription>> {
		return this.http.get<Array<CodeDescription>>(this.getFHITeamsUrl());
	}

	@ShareInProgress
	public getFHIGovernments(): Observable<Array<LocalGovernmentReference>> {
		return this.http.get<Array<LocalGovernmentReference>>(this.getFHIGovernmentsUrl());
	}

	@ShareInProgress
	public getFHIGovernmentTypes(): Observable<Array<CodeDescription>> {
		return this.http.get<Array<CodeDescription>>(this.getFHIGovTypeCodesUrl());
	}

	private getFHIBaseUrl = () => this.baseExtractUrl + '/FinancialHealthIndicators';

	public getFHITeamsUrl = () => this.getFHIBaseUrl() + '/Teams';
	public getFHIGovernmentsUrl = () => this.getFHIBaseUrl() + '/Governments';
	public getFHIGovTypeCodesUrl = () => this.getFHIBaseUrl() + '/GovTypeCodes';
	public getFHIAllUrl = () => this.getFHIBaseUrl() + '/All';


	private getPensionLiabilityVarianceBaseUrl = () => this.baseExtractUrl + '/PensionLiabilityVariances';
	public getPensionLiabilityVarianceTeamsUrl = () => this.getPensionLiabilityVarianceBaseUrl() + '/Teams';
	public getPensionLiabilityVarianceGovernmentsUrl = () => this.getPensionLiabilityVarianceBaseUrl() + '/Governments';
	public getPensionLiabilityVarianceGovTypeCodesUrl = () => this.getPensionLiabilityVarianceBaseUrl() + '/GovTypeCodes';
	public getPensionLiabilityVarianceAllUrl = () =>  this.getPensionLiabilityVarianceBaseUrl() + '/All';

	@ShareInProgress
	public getPensionLiabilityVarianceTeams(): Observable<Array<CodeDescription>> {
		return this.http.get<Array<CodeDescription>>(this.getPensionLiabilityVarianceTeamsUrl());
	}

	@ShareInProgress
	public getPensionLiabilityVarianceGovernments(): Observable<Array<LocalGovernmentReference>> {
		return this.http.get<Array<LocalGovernmentReference>>(this.getPensionLiabilityVarianceGovernmentsUrl());
	}

	@ShareInProgress
	public getPensionLiabilityVarianceGovernmentTypes(): Observable<Array<CodeDescription>> {
		return this.http.get<Array<CodeDescription>>(this.getPensionLiabilityVarianceGovTypeCodesUrl());
	}

}
