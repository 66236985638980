import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { MultiGovernmentChooserNoviceComponent } from './multi-government-chooser-novice.component';
import { SearchMethodsService } from './search-methods.service';
import { ColumnarAccordionComponent } from './columnar-accordion/columnar-accordion.component';
import { DxDataGridModule, DxSelectBoxModule } from 'devextreme-angular';
import { HintModule } from 'app/modules/ui/hint/hint.module';
import { TextSearchModule } from 'app/shared/components/text-search/text-search.module';

@NgModule({
	imports: [
		CommonModule,
		DxDataGridModule,
		HintModule,
		TextSearchModule,
		DxSelectBoxModule
	],
	declarations: [
		MultiGovernmentChooserNoviceComponent,
		ColumnarAccordionComponent,
		SearchFiltersComponent
	],
	providers: [
		SearchMethodsService
	],
	exports: [
		MultiGovernmentChooserNoviceComponent
	]
})
export class MultiGovernmentChooserNoviceModule { }
