<ul>
	<li>
		<button
			class="choice"
			type="button"
			(click)="service.setState(service.states.ShapefileUpload)"
		>{{ service.states.ShapefileUpload }}</button>
	</li>
	<li>
		<button
			class="choice"
			type="button"
			(click)="service.setState(service.states.TaxCodeAreaDistrictAssignments)"
		>{{ service.states.TaxCodeAreaDistrictAssignments }}</button>
	</li>
	<li>
		<button
			class="choice"
			type="button"
			(click)="service.setState(service.states.CrosswalkEditor)"
		>{{ service.states.CrosswalkEditor }}</button>
	</li>
	<li>
		<button
			class="choice"
			type="button"
			(click)="service.setState(service.states.LocalGovernmentShapeGenerator)"
		>{{ service.states.LocalGovernmentShapeGenerator }}</button>
	</li>
</ul>
