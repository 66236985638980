export class PivotGridSettings {
	// Globally-available settings
	showAccountCodes: boolean;

	// UnitsService
	measure: string;
	comparison: string;
	unit: string;

	// Report-specific
	showRowGrandTotals: boolean;
	showDebtServiceWithExpenditures: boolean;
	financialSummaryHierarchy = 'Standard';
	rowHeaderLayout = 'tree';
	showEmptySchoolsRows = true;

	// govtype-specific
	showPopulation: boolean;
	populationField: string;

	// Projection applied
	projectionId: number;
	projectionName: string;

	// Filing field - similar to population field
	filingField: string;
}
