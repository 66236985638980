<dx-popup
	[width]="600"
	[height]="600"
	[dragEnabled]="true"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	[closeOnOutsideClick]="true"
	title="Manage Resources">
	<div class="app-popup__wrapper">
		<div class="app-popup__inner">
			<button class="addResource" (click)="addResource()">
				<fa-icon [icon]="icons.plus"></fa-icon>
				New Item
			</button>
			<dx-list
				(onItemDeleting)="onItemDeleting($event)"
				(onItemReordered)="onItemReordered($event)"
				(searchValueChange)="searchValueChanged($event)"
				[allowItemDeleting]="allowDeletion"
				[dataSource]="resourcesDataSource"
				[height]="400"
				[itemDeleteMode]="itemDeleteMode"
				[searchEnabled]="true"
				[showScrollbar]="'always'"
				pageLoadMode="scrollBottom"
				searchExpr="title"
				searchMode="contains"
			>
				<div *dxTemplate="let item of 'item'">
					<div class="resource">
						{{ item.title }}
						<div class="edit">
							<button class="editResource" (click)="editResource(item.id)">
								<fa-icon [icon]="icons.edit"></fa-icon>
							</button>
						</div>
					</div>
				</div>
				<dxo-item-dragging *ngIf="reorderEnabled"
								   [allowReordering]="true"
				>
				</dxo-item-dragging>
			</dx-list>
		</div>
	</div>
	<wasao-loading [isLoading]="isLoading" [message]="loadingMessage"></wasao-loading>
</dx-popup>
