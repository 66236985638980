import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

/* tslint:disable no-console */
const noop = (): any => undefined;

@Injectable({
	providedIn: 'root'
})
export class LoggerService {
	readonly isDebug: boolean;

	constructor() {
		this.isDebug = !environment.production;
		// Allow debug override for debugging production
		try {
			const localStorageIsDebug = localStorage.getItem('debug');
			if (localStorageIsDebug === null) {
				return;
			}
			// localStorage is always serialized (primitives are string literals)
			this.isDebug = localStorageIsDebug === 'true';
		} catch (e) {
			// no localStorage available, nothing to do
		}
	}

	// TODO figure out why this bind nonsense is here? Seems complicated.
	get log() {
		return this.isDebug
			? console.log.bind(console)
			: noop;
	}

	get info() {
		return this.isDebug
			? console.info.bind(console)
			: noop;
	}

	get warn() {
		return console.warn.bind(console);
	}

	get error() {
		return console.error.bind(console);
	}

	get debug() {
		return this.isDebug
			? console.debug.bind(console)
			: noop;
	}

	table(obj: any, columns: Array<string>): void {
		if (!this.isDebug) {
			return;
		}

		if (Array.isArray(obj)) {
			// Browsers typically restrict to 1k rows (-1 for the header row)
			const chunkSize = 999;
			for (let i = 0; i < obj.length; i += chunkSize) {
				const chunk = obj.slice(i, i + chunkSize);
				console.table(chunk, columns);
			}
		}
	}

	// This works fine without bind
	time(label: string | any, instance?: string) {
		if (this.isDebug && typeof console.time === 'function') {
			console.time(this.generateTimeId(label, instance));
		}
	}

	timeEnd(label: string | any, instance?: string) {
		if (this.isDebug && typeof console.timeEnd === 'function') {
			console.timeEnd(this.generateTimeId(label, instance));
		}
	}

	private generateTimeId = (input: string | any, instance?: string): string => {
		instance = instance ? `#${instance}` : '';
		switch (typeof input) {
			case 'string':
				return `${input}${instance} took `;
			case 'object':
				return `${input.constructor.name}${instance} took `;
		}
	}
}
