import {Injectable} from '@angular/core';
import {TabService} from './tab.service';
import {Tab} from '../models/tab';
import {LoggerService} from './logger.service';
import {AnalyticsService} from './analytics.service';

@Injectable({
	providedIn: 'root'
})
export class HistoryService {

	private _tabs: Array<Tab>     = [];
	private _initialState: string = null;

	constructor(
		private tabService: TabService,
		private logger: LoggerService,
		private analytics: AnalyticsService
	) {
		// Attach the popstate event listener to onPopState (can't use @hostlistener in services)
		window.addEventListener('popstate', event => this.onPopState(event));

		// Attach observables
		this.tabService.tabs.subscribe(tabs => {
			// Store the initial state of our tabs if unset
			if (!this._initialState && tabs && tabs.length > 0) {
				this._initialState = JSON.stringify(tabs.map(t => t.export()));
			}

			this._tabs = tabs;
		});
	}

	/**
	 * Push state of our tabs to history
	 *
	 * @param url Optional URL
	 */
	public pushStateTabs(url = null): void {
		window.history.pushState(this.getTabStateObject(), null, url);
	}

	public replaceStateTabs(url = null): void {
		window.history.replaceState(this.getTabStateObject(), null, url);
		// analytics call to record the new tab being accessed
		const tab = this._tabs.find(x => x.selected);
		this.analytics.sendEvent(`entering state ${tab.state}`, tab);
	}

	private getTabStateObject = () => ({
		action: 'setTabs',
		data: JSON.stringify(this._tabs.map(t => t.export()))
	});

	/**
	 * Parse and act upon whatever the "reverse state" is
	 *
	 * @param event
	 */
	public onPopState(event): void {
		let data: string;

		// Revert to the initial load state if no history.state is set
		if (!event.state && this._initialState) {
			data = this._initialState;
		}
		// Otherwise, load from our next pushstated data
		else if (event.state && event.state.action && event.state.data) {
			data = event.state.data;
		}

		if (data) {
			this.tabService.loadFromHistory(data);
		}
	}

}
