import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GovernmentServiceModule} from '../../services/government-service/government-service.module';
import {ExternalCommonServiceApiModule} from '../external-common-service-api/external-common-service-api.module';


@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		ExternalCommonServiceApiModule,
		GovernmentServiceModule
	]
})
export class FitApiModule {
}
