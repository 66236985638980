import {Component, HostListener, OnInit} from '@angular/core';
import {UserInterfaceService} from './shared/services/user-interface.service';
import {TabService} from './shared/services/tab.service';
import {customTheme} from '../devextreme/dx.generic.custom-scheme';
import {currentTheme, registerTheme} from 'devextreme/viz/themes';
import {SnapshotId} from './modules/api/fit-api/models/snapshot-like';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	constructor() {
		// this loads in a custom theme for SVG-based widgets (dx viz library)
		registerTheme(customTheme, 'generic.light');
		currentTheme('FIT');
	}

}
