import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {
	faChartBar,
	faDivide, faDownload,
	faEquals,
	faExternalLinkSquareAlt, faMapMarkedAlt,
	faMinus,
	faPlus,
	faPrint, faShareAlt,
	faTimes
} from '@fortawesome/free-solid-svg-icons';
import {Tab} from 'app/shared/models/tab';
import {LoggerService} from 'app/shared/services/logger.service';
import {flatMap, map, sortBy} from 'lodash';
import {TabService} from 'app/shared/services/tab.service';
import {TabState} from 'app/shared/models/tab-state';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {TransitionService} from 'app/shared/services/transition.service';
import {PivotGridService} from 'app/shared/services/pivot-grid.service';
import {HistoryService} from 'app/shared/services/history.service';
import {first} from 'rxjs/operators';
import {IndicatorService} from '../../../../shared/services/indicator.service';
import {FitActionsService} from '../../../../shared/services/fit-actions.service';
import {IndicatorReportType} from '../../../api/fit-api/models/indicators/indicator-report-type';
import {FilingBasis} from '../../../api/fit-api/models/snapshots/filing-basis';
import {GovernmentType} from '../../../api/fit-api/models/snapshots/government-type';
import {combineLatest, forkJoin, of} from 'rxjs';
import {IndicatorReport} from '../../../api/fit-api/models/indicators/indicator-report';
import {UtilityService} from '../../../../shared/services/utility.service';
import {mean, median} from 'datalib';
import {Fill, Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {DxChartComponent, DxTreeListComponent} from 'devextreme-angular';

import {exportFromMarkup, getMarkup} from 'devextreme/viz/export';
import {fitBase64, saoBase64} from '../../../../components/pivot-grid/logo';
import {GradientService} from '../../../../shared/services/gradient.service';
import {SnapshotId} from '../../../api/fit-api/models/snapshot-like';
import * as govColors from '../../../../../../sao-patterns/src/tokens/government-colors.js';

enum IndicatorTabIds {
	Indicator = 'indicator',
	Data = 'data'
}

enum RowTypes {
	MeanMedian = 'meanMedian',
	SubCalculation = 'subCalc',
	FinancialRollupUnits = 'fru',
	Calculation = 'calc',
	ComparisonGovt = 'comparisonGovt'
}

@Component({
	selector: 'wasao-indicator',
	templateUrl: './indicator.component.html',
	styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {
	@ViewChild(DxTreeListComponent, { static: false }) dxTreeList: DxTreeListComponent;
	@ViewChild(DxChartComponent, { static: false }) dxChart: DxChartComponent;
	@Input() tab: Tab;

	fileName: string;
	snapshotIdText: string;
	government: any;
	years = [];
	treeListStore = [];
	chartStore = [];

	indicatorTabIds = IndicatorTabIds;
	indicatorTabs = [
		{
			id: IndicatorTabIds.Indicator,
			label: 'Financial Indicators'
		},
		{
			id: IndicatorTabIds.Data,
			label: 'Data and Formula'
		}
	];
	indicatorSelectedTab = IndicatorTabIds.Indicator;
	indicatorOperations = {};
	fruAccounts = [];
	rowTypes = RowTypes;
	measure: any = {
		name: '',
		unit: {},
		benchmark: {}
	};
	net: any = {
		name: '',
		unit: {}
	};
	hasNet = false;
	icons = {
		equals: faEquals,
		extLink: faExternalLinkSquareAlt,
		download: faDownload
	};
	chartTooltip: any = {
		enabled: true
	};
	labels = {
		mean: 'Mean/Average',
		median: 'Median',
		trimean: 'Trimean*',
	};
	indicatorReportType: IndicatorReportType;
	filingBasis: FilingBasis;
	govType: GovernmentType;
	comparisonIndicatorReports: Array<IndicatorReport> = [];
	comparisonFootnote: string;

	// Name to draw in legend when peers are set. Also indicates that a series should be drawn.
	peerName1: string;
	peerName2: string;
	peerName3: string;
	peerName4: string;
	peerName5: string;

	/**
	 * Holy crap, is this a hack. Indicators need to be refactored before _any_ further enhancements.
	 */
	lastKnownSnapshotId: SnapshotId;
	isLoading = false;
	color = govColors;
	isOSPIDataset = false;

	constructor(
		private logger: LoggerService,
		private decimalPipe: DecimalPipe,
		private currencyPipe: CurrencyPipe,
		private tabService: TabService,
		private snapshotService: SnapshotService,
		private transitionService: TransitionService,
		private pivotGridService: PivotGridService,
		private historyService: HistoryService,
		private indicatorService: IndicatorService,
		private fitActions: FitActionsService,
		private gradientService: GradientService,
		private utility: UtilityService,
	) {
	}

	ngOnInit() {
		// set boolean to hide unrelated information for schools
		this.isOSPIDataset = this.tab.indicator.financialsDatasetSource === 'OSPI';

		this.logger.info('Indicator Data:', this.tab.indicator, this);
		this.initializeIndicator();
		this.lastKnownSnapshotId = this.tab.snapshotId;
		this.tabService.tabs.subscribe((tabs) => {
			// detect if the snapshot is changing with this hack
			if (this.tab.snapshotId !== this.lastKnownSnapshotId) {
				this.logger.log(`IndicatorComponent detected snapshotId change. Fetching indicator to save on tab...`);
				this.isLoading = true;
				// record this as the new last known snapshot
				this.lastKnownSnapshotId = this.tab.snapshotId;
				// Manually fetch the indicator and save on the tab so that the tabs subscription kicks off again. We'll
				// run the initialization logic then.
				const indicatorGroups = this.indicatorService.getAnnualFilingIndicatorGroups(
					this.tab.getPrimaryGovernment().mcag,
					this.tab.snapshotId,
					this.tab.years[0],
					this.tab.years[1]);
				indicatorGroups.subscribe(result => {
					// flatten
					result = result.reduce((arr, val) => arr.concat(val.indicators), []);
					const indicator = result.find(x => x.instanceCode === this.tab.indicator.instanceCode);
					this.tab.indicator = indicator;
					// Ensure snapshot is loaded before kicking off save and running the else portion of this section (initializeIndicator)
					this.snapshotService.getSnapshot(this.tab.snapshotId).subscribe(snapshot => {
						this.tabService.save(this.tab);
					});
				});
			} else {
				if (this.tab.indicator.instanceCode !== undefined) { this.logger.log(`IndicatorComponent received tab update notification, redrawing Indicator...`, this.tab.indicator.instanceCode); }
				const thisTab = tabs.find((t) => t.id === this.tab.id);
				if (thisTab.state === TabState.indicator) {
					const comparisonMcags = this.tab.getNonPrimaryGovernments().map(g => g.mcag);
					const getComparisonIndicators = comparisonMcags.length === 0
						? of(new Array<IndicatorReport>())
						: this.indicatorService.getIndicatorReports(
							comparisonMcags,
							this.tab.snapshotId,
							this.tab.years[0],
							this.tab.years[1]);
					// Make sure we get the snapshot so it's cached for SnapshotService.findObject to use later.
					const getSnapshot = this.snapshotService.getSnapshot(this.tab.snapshotId);

					combineLatest([getSnapshot, getComparisonIndicators]).subscribe(([snapshot, comparisonResults]) => {
						this.comparisonIndicatorReports = comparisonResults;
						this.logger.log(`comparisonIndicatorReports:`, this.comparisonIndicatorReports);
						this.initializeIndicator();

						// sets filename for treelist and chart export
						const subtitleAndYearForFileName = `${this.tab.indicator.subTitle}, ${this.tab.years.length ? this.tab.years[0] : ''}-${this.tab.years.length ? this.tab.years[1] : ''}`;
						this.fileName = `${this.government?.entityNameWithDba}, ${this.tab.indicator.title}, ${subtitleAndYearForFileName}`;

						// sets snapshot text for treelist and chart export
						if (this.tab.snapshotId === 'live') {
							this.snapshotIdText = `${snapshot.name} (Generated: ${new Date().toLocaleDateString('en-US')})`;
						} else {
							this.snapshotIdText = snapshot.name;
						}

					});
				}
			}
		});
	}

	/**
	 * Get the filingBasis of the latest calculation (by year), where the filingBasis has a value
	 * @param calcs
	 */
	private getFilingBasisNameFromCalculations = (calcs: Array<any>): 'None' | 'Cash' | 'GAAP' => {
		const filingBasisName = calcs.sort((a, b) => b.year - a.year) // reverse sort
			.find(x => x.filingBasis != null)?.filingBasis;
		return ['Cash', 'GAAP'].includes(filingBasisName)
			? filingBasisName
			: 'None';
	};

	private getComparisonIndicatorReports() {
		return this.comparisonIndicatorReports.filter(i => i.instanceCode === this.tab.indicator.instanceCode);
	}

	getComparisonIndicatorReportMcags() {
		return [...new Set(this.getComparisonIndicatorReports().map(i => i.mcag))];
	}

	initializeIndicator() {
		const firstCalc = this.tab.indicator.calculations[0];
		const netSubCalc = firstCalc.subCalculations.find(c => c.isPartOfNetValue);
		const filingBasisName = this.getFilingBasisNameFromCalculations(this.tab.indicator.calculations);
		this.filingBasis = new FilingBasis(filingBasisName);
		// lowercase the report name that comes out of OData so that it matches our lowercase keys
		// GovernmentIndicators => governmentIndicators
		const lowercaseReportName = this.tab.indicator?.report?.replace(/^\w/, c => c.toLowerCase());
		this.indicatorReportType = new IndicatorReportType(lowercaseReportName);
		this.govType = this.snapshotService.findObject(this.tab.snapshotId, 'governmentTypes', this.tab.getPrimaryGovernment()?.govTypeCode, 'code');
		this.labels.mean = `${this.filingBasis.name} ${this.govType.description} Mean/Average`;
		this.labels.median = `${this.filingBasis.name} ${this.govType.description} Median`;
		this.labels.trimean = `${this.filingBasis.name} ${this.govType.description} Trimean*`;
		this.government = this.tab.getPrimaryGovernment();
		this.measure.unit = firstCalc.measureUnitInfo;
		this.measure.name = this.measure.unit.unit
			? `${this.tab.indicator.title} (${this.measure.unit.unitAbbreviation})`
			: this.tab.indicator.title;
		this.measure.benchmark = firstCalc.measureBenchmark;
		if (netSubCalc) {
			this.net.unit = firstCalc.netUnitInfo;
			this.net.name = `${netSubCalc.name} (${this.net.unit.unitAbbreviation})`;
		}

		this.chartTooltip.customizeTooltip = (point) => {
			const options = {
				text: ''
			};
			switch (point.seriesName) {
				case 'Benchmark':
					options.text = `Benchmark: value is ${this.measure.benchmark.comparatorSymbol} ${this.formatPointLabel(point.value, this.measure.unit)}`;
					break;

				case this.measure.name:
					options.text = this.formatPointLabel(point.value, this.measure.unit);
					break;

				case this.net.name:
					options.text = this.formatPointLabel(point.value, this.net.unit);
					break;

				case 'Missing Measure':
					options.text = this.resolveMissingMeasureReason(point.point.data);
					break;

				case this.labels.mean:
					options.text = this.formatPointLabel(point.value, this.measure.unit);
					break;

				case this.labels.median:
					options.text = this.formatPointLabel(point.value, this.measure.unit);
					break;

				case this.labels.trimean:
					options.text = this.formatPointLabel(point.value, this.measure.unit);
					break;

				case 'Comparison Governments’ Mean/Average':
					options.text = this.formatPointLabel(point.value, this.measure.unit);
					break;

				case 'Comparison Governments’ Median':
					options.text = this.formatPointLabel(point.value, this.measure.unit);
					break;
			}

			return options;
		};

		this.treeListStore = [];
		this.treeListStore = this.getTreeListStore();
		this.chartStore = [];
		this.chartStore = this.getChartStore();

		this.comparisonFootnote = this.getComparisonFootnote();

		// this.logger.info('Indicator TreeListStore:', this.treeListStore);
		this.logger.info('Indicator ChartStore:', this.chartStore);
		// this.logger.info('Government', this.government);

		this.years = [];
		for (let year = this.tab.years[0]; year <= this.tab.years[1]; year++) {
			this.years.push(`${year}`);
		}

		this.tab.title = `${this.government.entityNameWithDba}: ${this.tab.indicator.title}`;
		this.isLoading = false;
	}

	private getComparisonFootnote = (): string => {
		if (this.tab.getNonPrimaryGovernments().length > 1
			&& this.tab.getNonPrimaryGovernments().length !== this.getComparisonIndicatorReportMcags().length) {
			if (this.indicatorReportType.key === 'governmentalIndicators') {
				const governmentsNotIncluded = this.tab.getNonPrimaryGovernments()
					.filter(g => !this.getComparisonIndicatorReportMcags().find(mcag => mcag === g.mcag))
					.map(g => g.entityNameWithDba)
					.join(', ');
				return `This financial indicator is either not applicable or not available for the following selected peers: ${governmentsNotIncluded}`;
			} else {
				return 'Comparisons to other governments cannot be shown for enterprise fund indicators';
			}
		} else {
			return '';
		}
	};

	private resolveMissingMeasureReason = (dataRow): string => {
		const pointBasisDiffersFromIndicator = dataRow.filingBasis !== this.tab.indicator.accountingBasis;
		return pointBasisDiffersFromIndicator
			? `Filed as ${dataRow.filingBasis} Basis for the year ${dataRow.year}`
			: `Data not available for the year ${dataRow.year}`;
	};

	getChartStore() {

		function _findValue(collection: Array<any> = [], startIndex: number = 0, isLookingForward: boolean = true) {
			let distance = 0;
			if (isLookingForward) {
				for (let i = startIndex + 1; i < collection.length; i++) {
					distance++;
					if (collection[i].measure !== null) {
						return {
							value: collection[i].measure,
							distance
						};
					}
				}
			} else {
				for (let i = startIndex - 1; i >= 0; i--) {
					distance++;
					if (collection[i].measure !== null) {
						return {
							value: collection[i].measure,
							distance
						};
					}
				}
			}
			return {
				value: null,
				distance
			};
		}

		return this.tab.indicator.calculations.map((calc, i, calcs) => {
			if (calc.net) {
				this.hasNet = true;
			}
			const comparisonMcagResults = this.getComparisonIndicatorReportMcags();
			const comparisonMeasures = this.getComparisonIndicatorReports().filter(r => r.year === calc.year && r.measure !== null).map(r => r.measure);
			const value = {
				year: calc.year,
				measure: this.roundToPrecision(calc.measure, this.measure.unit.unitDecimalPrecision),
				measureUnit: calc.measureUnitInfo.unitAbbreviation,
				benchmark: calc.measureBenchmark ? calc.measureBenchmark.comparedTo : null,
				net: calc.measure !== null
					? Math.round(calc.net)
					: null,
				netUnit: calc.netUnitInfo ? calc.netUnitInfo.unitAbbreviation : null,
				missingMeasure: null,
				filingBasis: calc.filingBasis,
				// mean/median do not exist for enterprise
				mean: this.roundToPrecision(calc?.govTypeStats?.mean, this.measure.unit.unitDecimalPrecision),
				median: this.roundToPrecision(calc?.govTypeStats?.median, this.measure.unit.unitDecimalPrecision),
				trimean: this.roundToPrecision(calc?.govTypeStats?.trimean, this.measure.unit.unitDecimalPrecision),
				// Comparison governments' values
				comparisonMean: this.roundToPrecision(mean(comparisonMeasures), this.measure.unit.unitDecimalPrecision),
				comparisonMedian: this.roundToPrecision(median(comparisonMeasures), this.measure.unit.unitDecimalPrecision),

				// original method of putting an array of peers' measures on each year
				// comparisonMeasures: comparisonMcagResults.map(mcag => {
				// 	const reportRecord = this.getComparisonIndicatorReports().find(r => r.mcag === mcag && r.year === calc.year);
				// 	const government = this.tab.governments.find(g => g.mcag === mcag);
				// 	return { mcag: mcag, name: government.entityNameWithDba, measure: reportRecord.measure };
				// })
			};
			if (value.measure === null) {
				const nextValue = _findValue(calcs, i);
				const previousValue = _findValue(calcs, i, false);
				if (nextValue.value === null) {
					value.missingMeasure = this.roundToPrecision(previousValue.value, this.measure.unit.unitDecimalPrecision);
				} else if (previousValue.value === null) {
					value.missingMeasure = this.roundToPrecision(nextValue.value, this.measure.unit.unitDecimalPrecision);
				} else {
					const totalDistance = nextValue.distance + previousValue.distance;
					const valueDifference = nextValue.value - previousValue.value;
					const missingMeasure = previousValue.value + ((valueDifference / totalDistance) * previousValue.distance);
					value.missingMeasure = missingMeasure;
				}
			} else if (
				(calcs[i + 1] && calcs[i + 1].measure === null) ||
				(calcs[i - 1] && calcs[i - 1].measure === null)
			) {
				value.missingMeasure = value.measure;
			}

			// Setup peer measures (peerMeasure1...peerMeasure5)
			comparisonMcagResults.forEach((mcag, index) => {
				const reportRecord = this.getComparisonIndicatorReports().find(r => r.mcag === mcag && r.year === calc.year);
				value[`peerMeasure${index + 1}`] = reportRecord.measure;
			});
			// Set up peer names. This would be more efficient if it ran at the end instead of each year.
			for (let j = 0; j < 5; j++) {
				const mcag = (comparisonMcagResults)?.[j];
				const government = this.tab.governments.find(g => g.mcag === mcag);
				this[`peerName${j + 1}`] = government?.entityNameWithDba;
			}

			return value;
		});
	}

	getTreeListStore() {
		/**
		 * Since subCalc and fru names can collide, prefix them
		 */
		const subCalcPrefix = 'SUB_CAL_';
		const fruPrefix = 'FRU_';
		const keyDelimiter = '_';
		const storeObj: any = {};
		const indicator = this.tab.indicator;

		// Build storeObj - main objective is to turn indicator calculation list into rows of hierarchical data for the tree list
		indicator.calculations.forEach((calc) => {  // Each calculation object is a year (seen on the indicator table/grid view, this is a column for each year)
			const {year} = calc;
			let lastIndex = 0;
			calc.subCalculations.forEach((subCalc, i) => {  // Each indicator calculation formula is made up of a list of sub-calculations
				const subCalcKey = `${subCalcPrefix}${subCalc.order}`;
				// The view model for the indicator table/grid is row-based, so we need to transform the column-based object data into row objects
				// First, we build an intermediate object for each sub-calculation
				if (storeObj.hasOwnProperty(subCalcKey)) {  // Did we already create an object for this subcalc, but just have another year/column to add?
					storeObj[subCalcKey].columns[year] = {
						value: Math.round(subCalc.amount),
						unit: subCalc.financialRollupUnits.length > 0
							? 'dollar'
							: ''
					};
				} else {  // or create the sub-calc object if it doesn't already exist
					storeObj[subCalcKey] = {
						label: subCalc.name,
						order: subCalc.order,
						columns: {
							[year]: {
								value: Math.round(subCalc.amount),
								unit: subCalc.financialRollupUnits.length > 0
									? 'dollar'
									: ''
							}
						},
						operation: subCalc.operation,
						type: RowTypes.SubCalculation,
						fru: {}
					};
				}
				// Sub-calcs with more than one FRU will be shown as expandable rows on the grid/table view
				// Sub-calcs with only one FRU (or none where a constant), don't have any child rows
				if (subCalc.financialRollupUnits.length > 1) {  // Will have expandable FRU detail in the table/grid view
					sortBy(subCalc.financialRollupUnits, (fru) => fru.order).forEach((fru) => {
						const fruKey = `${fruPrefix}${subCalc.order}${(fru.order + '').padStart(2, '0')}`;
						if (storeObj[subCalcKey].fru.hasOwnProperty(fruKey)) {  // The FRU already exists in the subcalc fru object
							storeObj[subCalcKey].fru[fruKey].columns[year] = {
								value: Math.round(fru.amount),
								unit: 'dollar'
							};
						} else {  // The FRU does not already exist in the subcalc fru object
							storeObj[subCalcKey].fru[fruKey] = {
								label: fru.label,
								order: fru.order,
								columns: {
									[year]: {
										value: Math.round(fru.amount),
										unit: 'dollar'
									}
								},
								operation: fru.operation,
								information: fru.information,
								drilldownMin: fru.drilldownMin,
								drilldownMax: fru.drilldownMax,
								drilldownSearchLevel: fru.drilldownSearchLevel,
								type: RowTypes.FinancialRollupUnits,
								drilldownReport: fru.drilldownReport
							};
						}
					});
				} else if (subCalc.financialRollupUnits.length === 1) {  // No FRU detail is shown, so move FRU detail to subcalc object level
					const fru = subCalc.financialRollupUnits[0];
					storeObj[subCalcKey].information = fru.information;
					storeObj[subCalcKey].drilldownMin = fru.drilldownMin;
					storeObj[subCalcKey].drilldownMax = fru.drilldownMax;
					storeObj[subCalcKey].drilldownSearchLevel = fru.drilldownSearchLevel;
					storeObj[subCalcKey].drilldownReport = fru.drilldownReport;
				}

				lastIndex = i;
			});

			// Prepare the indicator total (measure) row of data
			this.addStoreObjTotalRow(storeObj, indicator.title, year, calc, calc.measure, ++lastIndex, RowTypes.Calculation);

			// Prepare a blank row of data
			this.addStoreObjTotalRow(storeObj, 'Blank Row', year, calc, null, ++lastIndex, RowTypes.MeanMedian);

			// Prepare the indicator mean/average row of data
			if (!this.isOSPIDataset) {this.addStoreObjTotalRow(storeObj, this.labels.mean, year, calc, calc?.govTypeStats?.mean, ++lastIndex, RowTypes.MeanMedian); }

			// Prepare the indicator median row of data
			if (!this.isOSPIDataset) {this.addStoreObjTotalRow(storeObj, this.labels.median, year, calc, calc?.govTypeStats?.median, ++lastIndex, RowTypes.MeanMedian); }

			// Prepare the indicator trimean row of data
			if (!this.isOSPIDataset) {this.addStoreObjTotalRow(storeObj, this.labels.trimean, year, calc, calc?.govTypeStats?.trimean, ++lastIndex, RowTypes.MeanMedian); }

			// Show comparison governments if provided
			const comparisonMcagResults = this.getComparisonIndicatorReportMcags();
			if (comparisonMcagResults.length !== 0) {
				// Prepare a blank row of data
				this.addStoreObjTotalRow(storeObj, 'Blank Row 2', year, calc, null, ++lastIndex, RowTypes.MeanMedian);

				comparisonMcagResults.forEach((mcag) => {
					const reportRecord = this.getComparisonIndicatorReports().find(i => i.mcag === mcag && i.year === year);
					const government = this.tab.governments.find(g => g.mcag === mcag);
					this.addStoreObjTotalRow(storeObj, 'Peer - ' + government.entityNameWithDba, year, calc, reportRecord.measure, ++lastIndex, RowTypes.ComparisonGovt);
				});

				if (comparisonMcagResults.length !== 1) {
					const measures = this.getComparisonIndicatorReports().filter(i => i.year === year && i.measure !== null).map(i => i.measure);
					const comparisonMean = mean(measures);
					const comparisonMedian = median(measures);
					// Prepare the indicator mean/average row of data for comparison governments
					this.addStoreObjTotalRow(storeObj, 'Comparison Governments’ Mean/Average', year, calc, comparisonMean, ++lastIndex, RowTypes.MeanMedian);

					// Prepare the indicator median row of data for comparison governments
					this.addStoreObjTotalRow(storeObj, 'Comparison Governments’ Median', year, calc, comparisonMedian, ++lastIndex, RowTypes.MeanMedian);
				}
			}

		});

		this.logger.info('Indicator storeObj:', storeObj);

		// This builds the ordered data in tree list source format and builds annotations and formula/operator info onto the data source
		return sortBy(flatMap(storeObj, (rowData, key) => {
			const row = {
				key,
				label: rowData.label,
				order: rowData.order,
				operation: rowData.order > 0 ? rowData.operation : null,
				type: rowData.type
			};
			this.indicatorOperations[key] = row.operation;
			this.setFruAccounts(rowData, key);
			Object.assign(row, rowData.columns);
			// Now, build rows for expandable FRU detail
			const rows = map(rowData.fru, (fru, fruKey) => {
				const fruRow = {
					key: fruKey,
					label: fru.label,
					parent: key,
					order: Number.parseFloat(`${rowData.order}.${((fru.order + 1) + '').padStart(2, '0')}`),
					operation: fru.order > 0 ? fru.operation : null,
					type: fru.type
				};
				this.indicatorOperations[fruKey] = fruRow.operation;
				this.setFruAccounts(fru, fruKey);
				return Object.assign(fruRow, fru.columns);
			});
			rows.push(row);
			return rows;
		}), (r) => r.order);
	}

	addStoreObjTotalRow(storeObj: any, key: string, year: any, calc: any, value: any, order: number, rowType: RowTypes): void {
		const label = key.includes('Blank Row') ? null : key;
		if (storeObj.hasOwnProperty(key)) {  // Row already exists
			storeObj[key].columns[year] = {
				value: this.decimalPipe.transform(value, `1.0-${this.measure.unit.unitDecimalPrecision}`),
				unit: calc.measureUnitInfo.unit
			};
		} else {  // Row does not already exists
			storeObj[key] = {
				label: label,
				order: order,
				columns: {
					[year]: {
						value: this.decimalPipe.transform(value, `1.0-${this.measure.unit.unitDecimalPrecision}`),
						unit: calc.measureUnitInfo.unit
					}
				},
				type: rowType
			};
		}
	}

	setFruAccounts = (fru, key) => {
		// make sure we are a true fru since subcals seem to be merged and treated similarly
		if (!fru.hasOwnProperty('drilldownMin')) {
			return;
		}

		// sets account range
		const text = `Annual Filing BARS Account ${fru.information}`;

		this.fruAccounts[key] = {
			text: text,
			drilldownMin: fru.drilldownMin,
			drilldownMax: fru.drilldownMax,
			drilldownSearchLevel: fru.drilldownSearchLevel,
			drilldownReport: fru.drilldownReport
		};
	};

	formatDataCell = ({value}) => {
		if (value && value.value !== null) {
			switch (value.unit) {

				case 'dollar':
					return typeof value.value === 'number'
						? this.currencyPipe.transform(value.value, 'USD', 'symbol', '1.0')
						: `$${value.value}`;

				case 'percent':
					return typeof value.value === 'number'
						? `${this.decimalPipe.transform(value.value, '1.0-2')}%`
						: `${value.value}%`;

				case 'day':
					return typeof value.value === 'number'
						? `${this.decimalPipe.transform(value.value, '1.0-1')}`
						: value.value;

				default:
					return value.value ? `${value.value}` : '';
			}
		} else {
			return '';
		}
	};

	formatPointLabel(text, unitInfo) {
		if (!unitInfo.unit) {
			return text;
		}
		if (unitInfo.unit === 'dollar' && typeof text === 'number') {
			return this.currencyPipe.transform(text, 'USD', 'symbol', '1.0');
		}
		const unit = unitInfo.unitAbbreviation.length > 1
			? ` ${unitInfo.unitAbbreviation}`
			: unitInfo.unitAbbreviation;
		return unitInfo.unitAbbreviationType === 'Prefix'
			? `${unit}${text}`
			: `${text}${unit}`;
	}

	goToProfile(mcag?: string) {
		this.fitActions.navigateToGovProfile(this.tab)(mcag);
	}

	goToIndicatorReport = (): void => {
		this.fitActions.navigateToIndicatorReport(this.tab)(this.indicatorReportType, this.filingBasis, this.govType.code);
	};

	getOperationIcon(rowKey) {
		switch (this.indicatorOperations[rowKey]) {
			case 'Add':
				return faPlus;

			case 'Subtract':
				return faMinus;

			case 'Multiply':
				return faTimes;

			case 'Divide':
				return faDivide;

			default:
				return '';
		}
	}

	getNetAxisLabel = ({valueText}) => {
		return this.formatPointLabel(valueText, this.net.unit);
	};

	getMeasureAxisLabel = ({valueText}) => {
		return this.formatPointLabel(valueText, this.measure.unit);
	};

	getTargetId = (id) => `#${id}`;

	private roundToPrecision(num: number, precision: number) {
		if (num == null) {
			return null;
		}
		const factor = Math.pow(10, precision);
		return Math.round(num * factor) / factor;
	}

	navigateToScheduleBrowser(cell) {
		this.historyService.pushStateTabs();
		const fru = this.fruAccounts[cell.key];
		const bars = this.snapshotService.getCollection(this.tab.snapshotId, 'accountDescriptors');

		const filter = [];
		// check each bars account in snapshot to see if it falls within string range for drilldownMin & drilldownMax
		bars.forEach(obj => {
			const isInRange = obj.acctSgmt === fru.drilldownSearchLevel
				&& obj.logicalAccount >= fru.drilldownMin // note: string compare
				&& obj.logicalAccount <= fru.drilldownMax;

			if (isInRange) {
				this.logger.log(obj);
				filter.push(this.resolveLineage(obj, bars));
			}
		});
		// TODO build new PivotGrid and apply filters
		this.logger.log('navigateToScheduleBrowser', fru, filter, bars);

		this.tabService.buildGovernmentBasedTab(this.tab, {
			reportId: fru.drilldownReport,
			governments: this.tab.governments,
			years: this.tab.years
		}, true).then(tab => {
			const fields = [
				{id: 'functionalAccountsGroup', name: 'functionalAccountsGroup', filterValues: filter},
				{id: 'fundGroupFilter', name: 'fundGroupFilter', filterValues: this.resolveFunds()}
			];
			// TODO apply fund
			this.pivotGridService.rebuildDataSource(tab, fields);
			this.tabService.setSelectedTabState(TabState.lgfrs);
			this.historyService.pushStateTabs();
		});
	}

	private resolveFunds() {
		return this.tab.indicator.funds.reduce((acc, val) => {
			acc.push([val.fundCategoryId, val.fundTypeId, val.fundNumber]);
			return acc;
		}, []);
	}

	// Builds a hierarchical filter for use with pivot grid.
	// E.g. bars ids: [92680, 5976] == logical account relationship: ['590', '59700']
	private resolveLineage = (account, accounts) => {
		const hierarchy = ['BasicAccount', 'SubAccount', 'Element', 'SubElement'];
		const filterValue = [account.id]; // set current account as base filter
		let node = account;
		// walk backwards through the tree to find the parent id
		for (let i = hierarchy.indexOf(account.acctSgmt); i > 0; i--) {
			const parent = accounts.find(x => x.id === node.parentId);
			if (!parent) {
				this.logger.error('IndicatorComponent: Cannot determine lineage for account', account);
				return;
			}
			filterValue.splice(0, 0, parent.id); // insert into beginning of array
			node = parent; // move up the hierarchy
		}

		return filterValue;
	};


	handleLegendClick = (event) => {
		this.logger.log(event);
		const series = event.target;
		series.isVisible() ? series.hide() : series.show();
	};

	exportIndicator() {
		if (this.indicatorSelectedTab === this.indicatorTabIds.Indicator) {
			this.exportChart();
		}
		if (this.indicatorSelectedTab === this.indicatorTabIds.Data) {
			this.exportTreelist();
		}
	}

	/** Chart svg export */
	exportChart() {
		// gets original size of chart for reset
		const originalSize = this.dxChart.instance.getSize();

		// adds titles, changes size for svg export
		this.dxChart.instance.option(
			{
				size: {height: 400, width: 1100},
				legend: {
					title:
					// Hack to add space before 'L' in Legend because it was washing out - JC
					{text: ' LEGEND', subtitle: {text: ''}},
					},
				animation: false
				});

		exportFromMarkup(this.prepareMarkup(), {
			fileName: this.fileName ? this.fileName : 'FHI Export',
			format: 'SVG',
		});

		// clears export changes made by svg export
		this.dxChart.instance.option({size: originalSize});
		this.dxChart.instance.option({legend: {title: {text: 'LEGEND', subtitle: {text: 'Click to Toggle', font: {size: 10, opacity: .8}}}}});
		this.dxChart.instance.resetOption('title');
	}

	prepareMarkup() {
		// add titles, subtitles
		const entityTitle = this.government?.entityNameWithDba ?? 'Government Entity: N/A';
		const indicatorType = this.tab.indicator.title ?? 'Indicator Type: N/A';
		const subtitleAndYear = `${this.tab.indicator.subTitle} ${this.tab.years.length ? this.tab.years[0] : ''}-${this.tab.years.length ? this.tab.years[1] : '' }` ?? 'N/A';
		const outlook = `Outlook: ${this.tab.indicator.outlookInfo.outlook}` ?? 'N/A';
		const benchmark = this.measure.benchmark ? `Benchmark: ${this.measure.benchmark.comparatorText} ${this.formatPointLabel(this.measure.benchmark.comparedTo, this.measure.unit)}` : 'Benchmark: N/A';
		const sourceAndNote = this.isOSPIDataset ? `Source: Office of Superintendent of Public Instruction (OSPI)` : `${this.snapshotIdText}`;
		const trimeanNote = this.isOSPIDataset ? '' : '*Trimean - a weighted average of the distribution\'s median and its two quartiles.';

		return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="575" width="1150">
			<g>
				<svg>
						<text text-anchor="middle" style="white-space: pre; font-size: 14px; font-family: Roboto, sans-serif; font-style: italic; cursor: default;">
						<tspan text-anchor="middle" style="white-space: pre; font-size: 30px; font-family: Roboto, sans-serif; fill: #026CA3; font-style: normal; cursor: default;" x="50%" y="30">${entityTitle}</tspan>
						<tspan x="50%" y="45">${indicatorType}</tspan>
						<tspan x="50%" y="60">${subtitleAndYear}</tspan>
						<tspan x="50%" y="75">${outlook}</tspan>
						<tspan x="50%" y="90">${benchmark}</tspan>
						<tspan x="50%" y="105">${sourceAndNote}</tspan>
						</text>
				</svg>
			</g>
			<g transform="translate(20,110)">${this.dxChart.instance.svg()}</g>
			<g>
				<svg>
					<text style="white-space: pre; font-size: 14px; font-family: Roboto, sans-serif; font-style: italic; cursor: default;">
						<tspan x="30" y="550">${trimeanNote}</tspan>
					</text>
				</svg>
			</g>`
			+ '</svg>';
	}

	/** Tree list excel export
	 This is a 'roll your own' pattern to export based on https://supportcenter.devexpress.com/ticket/details/t532057/dxtreelist-how-to-export-data-to-excel
	 As of 8/22 dev extreme doesn't offer out of the box .xlsx exports for tree list
	*/
	exportTreelist() {
		// add titles, subtitles
		const govName = this.government?.entityNameWithDba ?? 'Government Entity: N/A';
		const indicatorType = this.tab.indicator.title ?? 'Indicator Type: N/A';
		const subtitleAndYear = `${this.tab.indicator.subTitle} ${this.tab.years.length ? this.tab.years[0] : ''}-${this.tab.years.length ? this.tab.years[1] : '' }` ?? 'N/A';
		const outlook = `Outlook: ${this.tab.indicator.outlookInfo.outlook}` ?? 'N/A';
		const benchmark = this.measure.benchmark ? `Benchmark: ${this.measure.benchmark.comparatorText} ${this.formatPointLabel(this.measure.benchmark.comparedTo, this.measure.unit)}` : 'Benchmark: N/A';

		const title = `${govName} - ${indicatorType}`;
		const dataset = this.isOSPIDataset ? 'Source: Office of Superintendent of Public Instruction (OSPI)' : this.snapshotIdText;
		const sub = `${subtitleAndYear}; ${outlook}; ${benchmark}; ${dataset}`;

		const tree = this.dxTreeList.instance;
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Sheet1');

		// add two empty rows for title and subtitle
		worksheet.addRows([[], []]);

		// 'roll your own' logic
		const columns = tree.getVisibleColumns().filter(c => c.dataField);
		const header = this.getHeader(columns);
		worksheet.addRow(header);
		const data = this.getData(tree.getRootNode(), columns);
		data.map(row => worksheet.addRow(row));

		/** Formatting/styling **/
		// formats cells for dollar, percent, ratio, day
		worksheet.eachRow(function(row) {
			// data grid cell not typed properly, so had to change type to 'any' in order to access nested property
			row.eachCell(function(cell: any) {
				// checks if cell value is an object (if true, value gets formatted, if false it's a row title, don't do anything to it)
				if (typeof cell.value === 'object') {
					// cell number formatting logic

					// handles edge case where value is null when the unit is a dollar, percent, ratio, or day
					if (cell.value.value === null) { cell.value.value = 0; }

					// dollar case
					if (cell.value.unit === 'dollar') {
						cell.value = parseInt(cell.value.value, 10);
						cell.numFmt = '$#,##0';
					}
					// percent case
					else if (cell.value.unit === 'percent') {
						cell.value = parseFloat(cell.value.value);
						cell.numFmt = 'General%';
					}
					// ratio case
					else if (cell.value.unit === null) {
						cell.value = parseFloat(cell.value.value);
						cell.numFmt = 'General';
					}
					// day case
					else {
						cell.value = parseInt((cell.value.value.length > 3 ? cell.value.value.replace(/,/g, '') : cell.value.value), 10);
						cell.numFmt = '#,##0';
					}
				}
			});
		});

		/** Header / Row titles **/
		const saoLogo = workbook.addImage({
			base64: saoBase64,
			extension: 'png'
		});
		worksheet.addImage(saoLogo, {
			tl: {col: 0, row: 0},
			ext: {width: 396, height: 72},
			hyperlinks: {
				hyperlink: 'https://sao.wa.gov/',
				tooltip: 'https://sao.wa.gov/'
			}
		});

		if (!this.isOSPIDataset) {
			// merge title cells
			worksheet.mergeCells(1, 2, 1, header.length);
			// merge subtitle cells
			worksheet.mergeCells(2, 2, 2, header.length);
		} else {
			// Hard coded width for schools
			// merge title cells
			worksheet.mergeCells(1, 2, 1, 6);
			// merge subtitle cells
			worksheet.mergeCells(2, 2, 2, 6);
		}

		// Cell Style : Fill and Border
		const headerFill: Fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: 'ff3957AA'}
		};

		// configure title and subtitle formatting
		const headerRows = worksheet.getRows(1, 2);
		headerRows.forEach(r => {
			// copy the object so we don't inadvertently change properties later
			r.font = Object.assign({}, {name: 'Roboto Condensed Light', color: {argb: 'ffffffff'}});
			r.alignment = {vertical: 'middle', wrapText: true};


				r.getCell(1).fill = headerFill;
				r.getCell(4).fill = headerFill;
		});

		const titleCell = worksheet.getRow(1).getCell(2);
		titleCell.value = title;
		titleCell.font.size = 15;
		titleCell.font.bold = true;
		titleCell.alignment = {horizontal: 'left', vertical: 'middle'};
		const subtitleCell = worksheet.getRow(2).getCell(2);
		subtitleCell.value = sub;
		subtitleCell.alignment = {horizontal: 'left', vertical: 'middle'};

		// FIT logo goes in corner cell
		const cornerCell = worksheet.getCell('A3');
		const fitLogo = workbook.addImage({
			base64: fitBase64,
			extension: 'png'
		});
		worksheet.addImage(fitLogo, {
			tl: {col: 0, row: 1},
			ext: {width: 135, height: 40},
		});
		cornerCell.fill = headerFill;

		// colorize top two column headers
		const gradient = this.gradientService.generate(
			this.color.neutral.dark, this.color.neutral.medium, header.length - 1
		);
		// start at row 3 and grab one rows
		worksheet.getRows(3, 1).forEach(row => {
			// iterate over the gradient. This should contain the same number of items as numColumnHeaders
			gradient.forEach((color, index) => {
				// Get the cell by skipping past the number of rowHeaders (plus one, because getCell is 1-based)
				// and then skip to the index to apply the appropriate color from the gradient
				const cell = row.getCell(2 + index);
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					// ugh, exceljs won't read hashed hex codes, because that's not a standard, is it? Split the
					// string on # and take the second part of the string
					fgColor: {argb: color.split('#')[1]},
				};
				cell.font = {color: {argb: 'ffffffff'}};
			});
		});

		// sets column widths
		worksheet.columns.forEach(column => {
			column.width = 30;
		});

		// change first column width to fit logo
		worksheet.getColumn('A').width = 60;

		// set height to accomodate image
		worksheet.getRow(1).height = 100;

		// adds empty row above mean/median/trimean
		const columnA = worksheet.getColumn(1);
		columnA.eachCell({ includeEmpty: false }, function(cell, rowNumber) {
			if (cell.value === 'null') {
				worksheet.spliceRows(rowNumber, 1, []);
			}
		});

		// adds trimean hint to bottom of spreadsheet
		if (!this.isOSPIDataset) {
			worksheet.addRows([[], ['*Trimean - a weighted average of the distribution\'s median and its two quartiles.']]);
		}

		/** Formatting/styling ends**/

		// prints to xlsx
		workbook.xlsx.writeBuffer().then((buffer) => {
			saveAs(new Blob([buffer], { type: 'application/octet-stream' }), (this.fileName ? this.fileName : 'FHI Export') + '.xlsx');
		});
	}

	getHeader(columns) {
		const header = Array();
		for (let i = 0; i < columns.length; i++) {
			header[i] = columns[i].caption;
		}
		return header;
	}

	getRow(node, columns) {
		const row = Array();
		for (let i = 0; i < columns.length; i++) {
			let cell = node.data[columns[i].dataField];
			if (i === 0) {cell = '   '.repeat(node.level) + cell; }
		row[i] = cell;
		}
		return row;
	}

	getData(node, columns) {
		const rows = Array();
		if (node.level >= 0) {
			rows.push(this.getRow(node, columns));
		}
		for (let i = 0; i < node.children.length; i++) {
			this.getData(node.children[i], columns).map(row => rows.push(row));
		}
		return rows;
	}

}
