<ng-container>
	<div [ngClass]="{'scroll': !isCombined}">
		<div class="outer-container">
			<div *ngIf="!isBannerHidden" class="banner-container">
				<img class="banner-image" src="assets/images/fit_by_the_numbers_countdown_bg.png"
					 alt="header image"/>
				<div class="banner-txt">Annual Filing Trends</div>
				<div class="sao-container"><a href="https://sao.wa.gov/" target="_blank"><img
					class="sao-image" src="assets/images/fit_by_the_numbers_sao_seal.png"
					alt="header image"/></a>
				</div>
			</div>

			<div class="inner-container">
				<div class="filters-container" *ngIf="!isStandAlone">
					<div class="row">
						<div class="column">
							<div class="nested-left">
								<dx-select-box
									#govTypeSelectBox
									[width]="'100%'"
									[dataSource]="governmentTypesDataSource"
									valueExpr="value"
									displayExpr="name"
									(onSelectionChanged)="onGovernmentTypeSelectionChanged($event)"
									placeholder="Choose Government Type"
								></dx-select-box>
							</div>
						</div>
						<div class="column">
							<div class="nested-right">
								<dx-select-box
									#countyCodeSelectBox
									[width]="'100%'"
									[dataSource]="countyCodesDataSource"
									valueExpr="value"
									displayExpr="name"
									(onSelectionChanged)="onCountyCodeSelectionChanged($event)"
									placeholder="Choose County"
								></dx-select-box>
							</div>
						</div>
					</div>
				</div>

				<div class="gov-type-text"><h2>Annual reports by fiscal year
					{{selectedGovernmentType.name === 'All Government Types' ? 'for all' : '\nfor ' + selectedGovernmentType.name}}
					governments<sup>1</sup>
					{{selectedCountyCode.name === 'All Counties' ? '' : 'in ' + selectedCountyCode.name + ' County'}}
				</h2></div>


				<div class="percent-counts-container">
					<div *ngIf="!isStandAlone" class="toggle-container">
						<div
							[ngClass]="{'toggled' : buttonClicked === 'percent', 'untoggled': buttonClicked === 'counts'}">
							<button (click)="togglePercent()"
									[ngClass]="{'toggled' : buttonClicked === 'percent', 'untoggled': buttonClicked === 'counts'}">
								<fa-icon [icon]="icons.percent"></fa-icon>
								Percent
							</button>
						</div>

						<div
							[ngClass]="{'toggled' : buttonClicked === 'counts', 'untoggled': buttonClicked === 'percent'}">
							<button (click)="toggleCounts()"
									[ngClass]="{'toggled' : buttonClicked === 'counts', 'untoggled': buttonClicked === 'percent'}">
								<fa-icon [icon]="icons.hash"></fa-icon>
								Counts
							</button>
						</div>
					</div>
					<div [ngClass]="isStandAlone ? 'graph-item-stand-alone' : 'graph-item'">
						<dx-chart [dataSource]="dataSource"
								  (onPointClick)="pointClick($event)"
								  [palette]="chartColorPalette"
								  (onPointHoverChanged)="onPointHoverChanged($event)"

						>
							<dxo-tooltip
								[enabled]="true"
								[customizeTooltip]="customizeTooltip"
								zIndex="13000"
							>
							</dxo-tooltip>

							<dxo-size
								[height]="525">
							</dxo-size>
							<dxi-series valueField="timelyFilers" name="Timely Filers"
										tagField="Timely Filers"></dxi-series>
							<dxi-series valueField="lateFilers" name="Late Filers" tagField="lateFilers"></dxi-series>
							<dxi-series valueField="nonFilers" name="Non Filers" tagField="nonFilers"></dxi-series>

							<!--for current year-->
							<dxi-series valueField="filed" name="Filed²" tagField="filed"></dxi-series>
							<dxi-series valueField="started" name="Started" tagField="started"></dxi-series>
							<dxi-series valueField="notStarted" name="Not Started" tagField="notStarted"></dxi-series>

							<dxo-common-series-settings argumentField="year" type="{{selectedGraphOption}}">
							</dxo-common-series-settings>
							<dxi-value-axis>
								<dxo-grid [visible]="true"></dxo-grid>
							</dxi-value-axis>
							<dxo-argument-axis
								argumentType="string"
							>
							</dxo-argument-axis>
							<dxo-legend
								verticalAlignment="bottom"
								horizontalAlignment="center"
								itemTextPosition="top"
							>
							</dxo-legend>

						</dx-chart>
						<div *ngIf="isDataSourceEmpty" class="no-data">No Governments Exist for the Specified Filters</div>
					</div>
				</div>

				<div class="footer-container">
					<div *ngIf="isStandAlone && !isCombined" class="footer-item">
						<wasao-annual-filing-explanation
							[dueDate]="submissionDueDate"></wasao-annual-filing-explanation>
					</div>
					<div class="footer-item">
						<wasao-annual-filing-footnotes [numberOfNotes]="2"></wasao-annual-filing-footnotes>
					</div>
				</div>

				<div *ngIf="isStandAlone" class="footer-item">
					<wasao-annual-filing-footer></wasao-annual-filing-footer>
				</div>
			</div>
		</div>
	</div>
	<wasao-loading [isLoading]="isLoading" [message]="loadingMessage"></wasao-loading>
</ng-container>



