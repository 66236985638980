import {Component, Input, OnInit} from '@angular/core';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';
import {Reusable} from '../../reusable/models/reusable';
import {DataStory, DataStoryId} from './data-story';
import {DataStoryService} from './data-story.service';
import {DomSanitizer, SafeHtml, SafeUrl} from '@angular/platform-browser';

@Component({
	selector: 'wasao-data-story',
	templateUrl: './data-story.component.html',
	styleUrls: ['./data-story.component.scss']
})
export class DataStoryComponent extends Reusable implements OnInit {

	private _dataStoryId: DataStoryId;
	get dataStoryId() {
		return this._dataStoryId;
	}
	@Input() set dataStoryId(value: DataStoryId) {
		this._dataStoryId = value;
		this.dataStory = this.service.getDataStory(value);
		if (this.dataStory) {
			// sanitize the url so that we can bind it in the template
			this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataStory.videoUrl);
		}
	}
	dataStory: DataStory;
	videoUrl: SafeUrl;

	/**
	 * Will be executed when the user clicks the button in the popup.
	 */
	@Input() clickHandler: Function;
	@Input() clickHandlerText: string;

	icons = {
		youtube: faYoutube
	};

	isPopupVisible = false;

	constructor(
		private service: DataStoryService,
		private sanitizer: DomSanitizer
	) {
		super();
	}

	ngOnInit(): void {
	}

	showPopup() {
		this.isPopupVisible = true;
	}
}
