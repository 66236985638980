import { Component, OnInit, Input } from '@angular/core';
import { Tab } from 'app/shared/models/tab';
import {ReportId} from '../../models/report-id';
import {Reusable} from '../../../modules/reusable/models/reusable';

/**
 * @deprecated Legacy data stories component to display several panels at once. For the new paradigm, see modules/info/data-story
 */
@Component({
	selector: 'app-data-stories',
	templateUrl: './data-stories.component.html',
	styleUrls: ['./data-stories.component.scss']
})
// This is the least-reusable of all the Government Profile components because the current use cases are very specific
// to FIT. It is unclear what panels would want to be shown elsewhere, or where the click events should lead. A refactor
// will likely be needed if this is used outside FIT in the future.
export class DataStoriesComponent extends Reusable implements OnInit {
	// Not an ideal binding. External consumers should have no knowledge of Tab
	@Input() tabState: Tab['state'];
	@Input() defaultClickAction: () => void;

	isRevenuesVisible = false;
	isExpendituresVisible = false;

	constructor() {
		super();
	}

	ngOnInit() { }

	handleDefaultClick = () => this.defaultClickAction ? this.defaultClickAction() : undefined;
}
