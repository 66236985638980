import {GroupingConfig} from './grouping-config';

export class Field {
	id: string;
	name: string;
	dataField: string;
	caption: string;
	visible = true;
	groupingConfig: Partial<GroupingConfig>;
	area: string;
	areaIndex: number;
	summaryType: string;
	calculateCustomSummary: Function;
	sortingMethod: Function;
	selector: (data) => string | number;
	customizeText: (data) => string;
	transitions: any;
	sortOrder: 'asc' | 'desc';
	isMeasure: boolean;
	dataType: string;
	width: number;

	isAreaAllowed(area) {
		return area in this.groupingConfig;
	}

	isRequired() {
		return 'required' in this.groupingConfig && this.groupingConfig.required;
	}

	constructor(field) {
		Object.assign(this, field);
	}
}
