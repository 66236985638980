<span class="filer-report">
	<span class="filer-report__text">
		Filing status for specific governments can be found <a class="button" (click)="handleClickToShowFromTab()">here</a>.
		<!-- <a class="button" >
			<fa-icon [icon]="icons.downArrow"></fa-icon>
		</a> -->
	</span>

	<dx-popup
		maxHeight="90%"
		[title]="title"
		[(visible)]="isVisible"
		[closeOnOutsideClick]="true"
		[deferRendering]="false"
	>
		<div *dxTemplate="let data of 'content'" class="filer-report__popup">
			<div class="app-popup__wrapper">
				<div class="app-popup__inner">
					<dx-data-grid
						[dataSource]="dataSource"
						height="100%"
						[columnAutoWidth]="true"
						[allowColumnResizing]="true"
						[rowAlternationEnabled]="true"
						class="filer-report__data-grid"
					>
						<dxo-export enabled=true></dxo-export>
						<dxo-paging enabled=true pageSize=11></dxo-paging>
						<dxo-scrolling mode="infinite" showScrollbar="always"></dxo-scrolling>
						<dxo-filter-row [visible]=false></dxo-filter-row>
						<dxo-header-filter visible=true allowSearch=true></dxo-header-filter>
						<dxi-column
							name="year"
							dataField="year"
							dataType="number"
							fixed="true"
						>
							<dxo-header-filter [dataSource]="yearFilterValues"></dxo-header-filter>
						</dxi-column>
						<dxi-column
							name="filingYearCondition"
							dataField="filingYearCondition"
							dataType="string"
							[calculateDisplayValue]="getFilingYearConditionText"
							fixed="true"
							caption="Filing Year"
						>
							<dxo-header-filter [dataSource]="filingStatusService.filingYearConditions"></dxo-header-filter>
						</dxi-column>
						<dxi-column
							name="government"
							dataField="mcag"
							dataType="string"
							[calculateDisplayValue]="getGovernmentName"
							caption="Local Government"
							fixed="true"
						>
							<dxo-header-filter [dataSource]="governmentFilterValues"></dxo-header-filter>
						</dxi-column>
						<dxi-column
							name="governmentStatus"
							dataField="governmentStatus"
							dataType="string"
						>
							<dxo-header-filter [dataSource]="governmentStatusFilterValues"></dxo-header-filter>
						</dxi-column>
						<dxi-column
							name="governmentType"
							dataField="govTypeCode"
							dataType="string"
							[calculateDisplayValue]="getGovTypeDesc"
							headerCellTemplate="Government<br/>Type"
						>
							<dxo-header-filter [dataSource]="govTypeFilterValues"></dxo-header-filter>
						</dxi-column>
						<dxi-column
							name="locations"
							dataField="countyCodes"
							dataType="object"
							caption="Location(s)"
							[calculateDisplayValue]="getLocationNames"
							[calculateFilterExpression]="getLocationsFilterExpression"
						>
							<dxo-header-filter [dataSource]="locationFilterValues"></dxo-header-filter>
						</dxi-column>
						<dxi-column
							dataField="maxAllowedSubmitDate"
							dataType="date"
							headerCellTemplate='<span title="Required Filing Date">Required<br/>Filing Date</span>'
							[allowFiltering]=false
						></dxi-column>
						<dxi-column
							dataField="dateSubmitted"
							dataType="date"
							headerCellTemplate='<span title="Date Submitted">Date<br/>Submitted</span>'
							[allowFiltering]=false
						></dxi-column>
						<dxi-column
							name="filingCondition"
							dataField="filingCondition"
							dataType="string"
							headerCellTemplate="Filing<br/>Condition"
						>
							<dxo-lookup [dataSource]="filingConditionLookupDataSource" valueExpr="value" displayExpr="text"></dxo-lookup>
						</dxi-column>
						<dxi-column
							dataField="filingBasis"
							dataType="string"
							headerCellTemplate='<span title="Date Submitted">Filing<br/>Basis</span>'
						>
							<dxo-header-filter [dataSource]="filingBasisFilterValues"></dxo-header-filter>
						</dxi-column>
						<dxi-column
							dataField="daysLate"
							dataType="number"
							alignment="left"
							headerCellTemplate='<span title="Days Late">Days<br/>Late</span>'
							[allowFiltering]=false
						></dxi-column>
						<dxi-column
							dataField="pendingUpdates"
							dataType="boolean"
							width="110"
							headerCellTemplate="pendingUpdates"
						>
							<dxo-header-filter [dataSource]="booleanFilterValues"></dxo-header-filter>
						</dxi-column>
						<dxi-column
							dataField="noDataAvailable"
							dataType="boolean"
							width="110"
							headerCellTemplate="noData"
						>
							<dxo-header-filter [dataSource]="booleanFilterValues"></dxo-header-filter>
						</dxi-column>
						<div *dxTemplate="let info of 'pendingUpdates'">
							<span title="{{ info.column.caption }} (1)">
								Pending<br />Updates<sup>1</sup>
							</span>
						</div>
						<div *dxTemplate="let info of 'noData'">
							<span title="{{ info.column.caption }} (2)">
								No Data<br />Available<sup>2</sup>
							</span>
						</div>
					</dx-data-grid>
				</div>

				<div class="app-popup__pre-footer">
					<h4 class="u-margin-top">Notes:</h4>
					<ul class="filer-report__notes">
						<li>
							<sup>1</sup> Governments that have submitted their filings, but have since begun making updates without yet resubmitting are
							listed as "Pending Updates". The financial data for this filing year will therefore NOT be available in the reports.
						</li>
						<li>
							<sup>2</sup> In addition to the "Pending Updates" described above, governments may have filed manually (sent paper copies, etc.),
							not provided any financial detail lines in their online filing, or had no financial activity for a given year. Therefore, it is NOT possible to access financial data
							for these governments for these filing years in the reports.
						</li>
					</ul>
				</div>

				<div class="app-popup__footer">
					<dx-button
						text="Close"
						type="default"
						(onClick)="isVisible = false"
					></dx-button>
				</div>
			</div>
		</div>
	</dx-popup>
</span>
