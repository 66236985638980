import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LoggerService} from '../../../shared/services/logger.service';
import {ReusableGovernmentAndTypeInputs} from '../../reusable/models/reusable-government-and-type-inputs';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {AnnualFilingSummaryService} from './annual-filing-summary.service';
import {AnnualFilingSummaryItem} from './annual-filing-summary-item';
import {catchError, finalize} from 'rxjs/operators';
import {ReportId} from '../../../shared/models/report';
import {User} from '../../../shared/models/user';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Component({
	selector:    'wasao-annual-filing-summary',
	templateUrl: './annual-filing-summary.component.html',
	styleUrls:   ['./annual-filing-summary.component.scss']
})
export class AnnualFilingSummaryComponent extends ReusableGovernmentAndTypeInputs implements OnChanges {
	@Input() snapshotId: SnapshotId;
	@Input() reportTypeClickAction: (reportId: ReportId, displayYear?: number, fieldTransformations?: Array<any>) => void;

	items: Array<AnnualFilingSummaryItem>;
	user: User;

	constructor(
		filingStatus: FilingStatusService,
		fitApi: FitApiService,
		logger: LoggerService,
		private service: AnnualFilingSummaryService
	) {
		super(filingStatus, fitApi, logger);
	}

	handleReportTypeClick = (reportId: ReportId, displayYear?: number) => {
		if (typeof this.reportTypeClickAction === 'function') {
			this.reportTypeClickAction(reportId, displayYear);
		}
	};

	/**
	 * Wrap the report click action for call by child component. This will fire every digest cycle when called from the
	 * hs template, so we should clean this up at some point.
	 * @param reportId
	 * @param displayYear
	 */
	getReportTypeClickHandler = (reportId: ReportId, displayYear?: number) => {
		return () => this.reportTypeClickAction(reportId, displayYear);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.callFunctionWithSpecificity(changes, this.updateAmounts);
	}

	updateAmounts = (specificity: GovernmentSpecificity) => {
		this.isLoading = true;
		this.service.getItems(specificity, this.snapshotId)
			.pipe(
				finalize(() => this.isLoading = false)
			)
			.subscribe(items => {
				this.items = items.filter(x => !x.report.beta || this.showBeta);
				const anyItemHasAmount = items.find(x => x.amount != null) !== null;
				this.hasData.emit(anyItemHasAmount);
			});
	}

}
