import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {LoggerService} from '../../services/logger.service';
import {DxRangeSliderComponent, DxSliderComponent} from 'devextreme-angular';
import {Years} from 'app/shared/models/years';
import {fromEvent, merge} from 'rxjs';
import {first, skipWhile, switchMap} from 'rxjs/operators';

@Component({
	selector:    'app-years',
	templateUrl: './years.component.html',
	styleUrls:   ['./years.component.scss']
})
export class YearsComponent implements OnChanges, AfterViewInit {

	@Input() mode: 'single' | 'multi';
	@Input() available: Array<any>;
	@Input() selections: Array<any>;
	@Output() change = new EventEmitter<Years>();
	@ViewChild(DxRangeSliderComponent) rangeSlider: DxRangeSliderComponent;
	@ViewChild(DxSliderComponent) slider: DxSliderComponent;
	min: number;
	max: number;
	start: number;
	end: number;

	constructor(
		private logger: LoggerService
	) {
		// this.logger.log(this);
	}

	ngAfterViewInit(): void {
		// Watch for user interaction to know when to emit value
		const rangeSliderElement = this.rangeSlider.instance.element();
		this.userInteractionsFor(rangeSliderElement).subscribe(() => {
			const value = this.rangeSlider.instance.option('value');
			// devextreme sends back Array<Number> instead of [number, number]
			this.change.emit([value[0], value[1]]);
		});

		const sliderElement = this.slider.instance.element();
		this.userInteractionsFor(sliderElement).subscribe(() => {
			const value = this.slider.instance.option('value');
			// emit year for both start and end
			this.change.emit([value, value]);
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		// this.logger.info(`${this.constructor.name}.ngOnChanges inputs:`, this.mode, this.available, this.selections);
		if (changes?.available?.currentValue) {
			this.min = Math.min(...changes.available.currentValue);
			this.max = Math.max(...changes.available.currentValue);
		}

		if (changes?.selections?.currentValue) {
			this.start = changes.selections.currentValue[0];
			this.end   = changes.selections.currentValue[1];
		}

		if (changes?.mode?.currentValue) {
			this.logger.log('mode change', changes.mode.currentValue);
		}

		this.logger.info(`${this.constructor.name}.ngOnChanges outputs:`, this.min, this.max, this.start, this.end);
	}

	/**
	 * Attach event handlers to know when a user *stops* interacting.
	 * @param element
	 */
	userInteractionsFor(element: Element) {
		/**
		 * Mouse interaction from user.
		 */
		const mouse = fromEvent(element, 'mousedown').pipe(
			// wait for mouseup anywhere on document and immediately unsubscribe to prevent further mouseup events
			switchMap(() => fromEvent(document, 'mouseup').pipe(first()))
		);

		/**
		 * Keyboard interaction from user (responding only to right/left arrows).
		 */
		const key = fromEvent(element, 'keydown').pipe(
			switchMap(() => fromEvent(document, 'keyup').pipe(
				skipWhile((x: KeyboardEvent) => (x.key !== 'ArrowLeft' && x.key !== 'ArrowRight')),
				first()
			))
		);

		/**
		 * Touch interaction from user.
		 */
		const touch = fromEvent(element, 'touchstart').pipe(
			switchMap(() => fromEvent(document, 'touchend').pipe(first()))
		);

		// Respond to any of these events
		return merge(
			mouse,
			key,
			touch
		);
	}

}
