import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DxLoadIndicatorModule} from 'devextreme-angular';
import { LoadingComponent } from './loading.component';


@NgModule({
	declarations: [LoadingComponent],
	imports: [
		CommonModule,
		DxLoadIndicatorModule
	],
	exports: [
		LoadingComponent
	]
})
export class LoadingModule {
}
