import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnnualFilingFootnotesComponent} from './annual-filing-footnotes.component';

@NgModule({
	declarations: [
		AnnualFilingFootnotesComponent
	],
	imports: [
		CommonModule,
	],
	exports: [
		AnnualFilingFootnotesComponent
	]
})
export class AnnualFilingFootnotesModule {
}
