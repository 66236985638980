import {Component, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {DxPopupComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import {UserInterfaceService} from '../../../shared/services/user-interface.service';
import {Resource} from '../../../shared/models/resource';
import {ResourcesService} from '../resources.service';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';

@Component({
	selector: 'app-resource-item',
	templateUrl: './resource-item.component.html',
	styleUrls: ['./resource-item.component.scss']
})
export class ResourceItemComponent implements OnInit {
	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	isVisible = true;
	private uiSubscription: Subscription;
	resource: Resource;
	urlSafe: SafeHtml;

	constructor(private uiService: UserInterfaceService, private resourceService: ResourcesService, private sanitizer: DomSanitizer) {
		this.uiSubscription = this.uiService.resourceItem$.subscribe(
			value => {
				if (value.id) {
					this.isVisible = value.visible;
					this.resourceService.getResourceItem(value.id).subscribe(e => {
						this.resource = e;
						// bypass sanitization for iframe and embedded videos
						this.urlSafe = sanitizer.bypassSecurityTrustHtml(this.resource.embed);
					});
				}
			});

	}

	ngOnInit(): void {

	}

	handleResourceLinkClick() {
		this.resource.link = this.resource.link.match(/^https?:/) ? this.resource.link : '//' + this.resource.link;
		window.open(this.resource.link, '_blank');
	}
}
