<dx-popover
	[target]="target"
	[visible]="target != null"
>
	<dxo-position my="left" at="right"></dxo-position>
	<div *dxTemplate="let data of 'content'" class="container">
		<div *ngIf="!isLoading && description" [innerHTML]="description"></div>
		<div *ngIf="!isLoading && !description">No description available</div>
		<wasao-loading [isLoading]="isLoading"></wasao-loading>
	</div>
</dx-popover>
