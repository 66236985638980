import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GovernmentInformationComponent} from './general-government-info/government-information.component';
import {DxLoadIndicatorModule, DxLoadPanelModule} from 'devextreme-angular';
import {HintModule} from '../../ui/hint/hint.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SharedComponentsModule} from '../../../shared/components/shared-components.module';
import {LoadingModule} from '../../ui/loading/loading.module';
import {MapModule} from '../../ui/map/map.module';


@NgModule({
	declarations: [GovernmentInformationComponent],
	imports: [
		CommonModule,
		DxLoadIndicatorModule,
		HintModule,
		FontAwesomeModule,
		DxLoadPanelModule,
		SharedComponentsModule,
		LoadingModule,
		MapModule
	],
	exports: [
		GovernmentInformationComponent
	]
})
export class GovernmentInformationModule {
}
