import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Tab} from '../../shared/models/tab';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {LoggerService} from '../../shared/services/logger.service';
import {DxPivotGridDataHeader} from './dx-pivot-grid-data-header';
import {PivotGridService} from '../../shared/services/pivot-grid.service';
import DataSource from 'devextreme/data/data_source';
import {IndicatorReport} from '../../modules/api/fit-api/models/indicators/indicator-report';
import {UtilityService} from '../../shared/services/utility.service';
import {HistoryService} from '../../shared/services/history.service';
import {TabService} from 'app/shared/services/tab.service';
import {FitActionsService} from '../../shared/services/fit-actions.service';
import {CustomProperties} from '../../modules/ui/map/models/custom-properties';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import * as colorImport from '../../../../sao-patterns/src/tokens/color.json';
import {Outlook} from './outlook';
import {TrackEService} from '../../shared/services/tracks/track-e.service';
import {DxPopupComponent} from 'devextreme-angular';
import DevExpress from 'devextreme';
import polarPointObject = DevExpress.viz.polarPointObject;

const defaultSelectedGovernment = {
	mcag: false
};

@Component({
	selector: 'app-indicator-report-map',
	templateUrl: './indicator-report-map.component.html',
	styleUrls: ['./indicator-report-map.component.scss']
})


export class IndicatorReportMapComponent implements OnInit {

	@Input() pivotGridData: PivotGridDataSource;
	@Input() tab: Tab;
	@Input() title: string;
	@Input() subTitle: string;

	@ViewChild('mapContainer') mapContainer: ElementRef;
	/**
	 * Wait until popup is drawn to set this to a px value since the map waits on this to occur before drawing.
	 */
	mapContainerWidth = 'auto';


	@ViewChild('tooltip') tooltip: TemplateRef<any>;
	tooltipContent: {
		mcag: string,
		govTypeCode: string,
		entityNameWithDba: string,
		governmentName: string,
		title: string;
		subTitle: string
		measure: number;
		unit: string;
		outlook: string;
		outlookAnnotation: string;
	};

	isVisible = false;
	isLoading: boolean;

	data: Array<IndicatorReport>;
	govTypeCode: string;
	customProperties: Array<CustomProperties>;

	@ViewChild(DxPopupComponent) popup: DxPopupComponent;

	/**
	 * User filters
	 */
	availableYears: Array<any>;
	year: number;
	/**
	 * Indicator Code
	 */
	availableIndicators: Array<any>;
	indicator: string;
	color = colorImport; // new with angular 13 upgrade
	availableOutlooks: Array<Outlook> = [
		{id: 'Good', color: this.color.status.okay},
		{id: 'Cautionary', color: this.color.status.cautionary},
		{id: 'Concerning', color: this.color.status.concerning},
		{id: 'Indeterminate', color: this.color.neutral.base}
	];
	outlooks: Array<Outlook>;



	public selectedGovernment: any = defaultSelectedGovernment;


	constructor(
		private tabService: TabService,
		private logger: LoggerService,
		private pivotGridService: PivotGridService,
		private utility: UtilityService,
		private historyService: HistoryService,
		private fitActions: FitActionsService,
		private trackEService: TrackEService
	) {
	}

	ngOnInit() {
		this.pivotGridService.getIndicatorReportMapVisibility(this.tab.id)
			.subscribe(x => {
				this.isVisible = x;
			});
	}

	onShown = (event) => {
		this.logger.log(`IndicatorReportMap onShown`, this.pivotGridData.filter());
		this.setMapContainerWidth();
		this.govTypeCode = this.tab.govTypes[0].code;
		this.isLoading = true;
		// Retrieve the same data source as the pivot grid is using
		new DataSource({
			store: this.pivotGridService.getStoreForDataSource(this.tab),
			filter: this.pivotGridData.filter(),
			paginate: false
		}).load().then((result: Array<IndicatorReport>) => {
			this.data = result;
			// Get unique set of years from data source and sort descending
			this.availableYears = Array.from(new Set(result.map(x => x.year)))
				.sort((a, b) => b - a);
			this.year = Math.max(...this.availableYears);
			this.availableIndicators = this.groupIndicatorsBySubtitle(result);
			this.indicator = this.getDefaultIndicator();
			this.updateCustomProperties();
			this.isLoading = false;
			// this.logger.log(`IndicatorReportMap data loaded`, this);
		}, error => {
			this.logger.error(`Unable to get Indicator Report`, error);
			this.isLoading = false;
		}).catch(error => {
			// Why doesn't this trigger if something fails in the load success callback??
			this.logger.error(`Unable to process results`, error);
			this.isLoading = false;
		});

	};

	onHiding = (event) => {
		this.pivotGridService.setIndicatorReportMapVisibility(this.tab.id, false);
	};

	/**
	 * Since the ratio of the map is dependant on the width, we need to set this width based on the available height.
	 * This is because the container is flex: 1, with other children 0 1 auto. Thus the mapContainer gets the remaining
	 * height.
	 */
	setMapContainerWidth() {
		// Gard implementation is 735 x 530 at max width
		const ratio = this.mapContainer.nativeElement.clientHeight / 530;
		// this.logger.log(`setMapContainerWidth`, this.mapContainer, ratio);
		this.mapContainerWidth = `${735 * ratio}px`;
		// wait until the next digest cycle before re-centering the popup
		setTimeout(() => this.popup.instance.repaint(), 0);
	}

	/**
	 * Update the custom properties passed into the Map component.
	 */
	updateCustomProperties(): void {
		// filter out year and indicator that doesn't match
		const filteredList = this.data.filter(row =>
			row.code === this.indicator &&
			row.year === this.year
		);
		this.customProperties = filteredList.map(row => {
			const isFilteredOut = this.outlooks?.length > 0 && !this.outlooks.map(x => x.id).includes(row.outlookInfo.outlook);
			const fillColor = this.availableOutlooks.find(x => x.id === row.outlookInfo.outlook).color;
			const value: CustomProperties = {
				mcag: row.mcag,
				properties: {}
			};
			if (fillColor && !isFilteredOut) {
				value.properties.fillColor = fillColor;
			}

			return value;
		});
	}


	groupIndicatorsBySubtitle(data: Array<IndicatorReport>): Array<{ key: string, items: Array<any> }> {
		const result = [];
		const groups = this.utility.groupBy(data, 'subTitle');
		for (const key of Object.keys(groups)) {
			// break into each group
			let group = groups[key];
			// sort inside each group on .code
			group = group.sort((a, b) => a.code.localeCompare(b.code));

			// get unique list of titles (they will be unique inside of each group)
			const items = Array.from(new Set(group.map(x => x.title)))
				.map(title => ({
						name: title,
						// lookup indicator code associated with that title
						id: group.find(x => x.title === title).code
					})
				);

			// push group to stack
			result.push({key: key, items: items});
		}
		return result;
	}

	getDefaultIndicator() {
		if (!Array.isArray(this.availableIndicators)) {
			this.logger.warn(`No indicators present, cannot get default.`);
			return null;
		}

		return this.availableIndicators[0]?.items[0].id;
	}

	/**
	 * Get all values at the given index.
	 * @param area 'rows' or 'columns' from pivotGridData https://js.devexpress.com/Documentation/ApiReference/Data_Layer/PivotGridDataSource/Methods/#getData
	 * @param index
	 */
	accumulatePivotGridDataValues = (area: Array<DxPivotGridDataHeader>, index: number) => {
		let stack = area;
		for (let i = 0; i < index; i++) {
			// This would be more clear with Array.flatMap! Thanks IE!
			stack = stack.reduce((acc, x) => acc.concat(x.children), []);
		}
		// Make values unique using Set
		return Array.from(new Set(stack.map(x => x.value)));
	};

	// pass as binding to Map so that it can retrieve the content for popup
	updateTooltip = (mcag: string, title: string): void => {
		// need to lookup in the datasource where this.year & this.indicator & mcag
		const indicator = this.data.find(row =>
			row.mcag === mcag &&
			row.year === this.year &&
			row.code === this.indicator
		);

		this.tooltipContent = {
			mcag: indicator.mcag,
			govTypeCode: indicator.govTypeCode,
			entityNameWithDba: title,
			governmentName: title,
			title: indicator.title,
			subTitle: indicator.subTitle,
			measure: this.trackEService.formatMeasure(indicator.measure, indicator?.measureUnitInfo?.unitDecimalPrecision),
			unit: indicator?.measureUnitInfo?.unitAbbreviation,
			outlook: indicator.outlookInfo.outlook,
			outlookAnnotation: indicator.outlookInfo.annotation
		};
	};

	handleGovernmentSelect(mcag?: string) {
		console.log('Selected Gov: ' + JSON.stringify(this.tooltipContent));
		mcag = this.tooltipContent.mcag;
		this.fitActions.navigateToGovProfile(this.tab)(mcag);
		this.isVisible = !this.isVisible;
	}

}
