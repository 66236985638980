import { Component, OnInit, ViewChild, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { LoggerService } from 'app/shared/services/logger.service';
import { IconDefinition, faFilter, faCheck } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'app/shared/services/user.service';

@Component({
	selector: 'app-expenditure-object-chooser',
	templateUrl: './expenditure-object-chooser.component.html',
	styleUrls: ['./expenditure-object-chooser.component.scss']
})
export class ExpenditureObjectChooserComponent implements OnInit, OnChanges {
	@ViewChild(DxPopupComponent) popup: DxPopupComponent;
	@Input() snapshot: any;
	@Input() selections: Array<string>;
	selectionValues: Array<string>;
	@Output() changed = new EventEmitter<any>();

	icons = {
		active: faCheck,
		inactive: faFilter
	};
	icon: IconDefinition;
	isAvailable = false;
	store: any;
	showAccountCodes: boolean;

	constructor(
		private logger: LoggerService,
		private userService: UserService
	) {
		this.userService.user.subscribe(u => this.showAccountCodes = u.showTechnical);
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		this.logger.log('ExpenditureObjectChooser.$onChanges', changes);
		if (changes.snapshot && changes.snapshot.currentValue) {
			this.store = changes.snapshot.currentValue.detail.expenditureObjects;
		}
		if (changes.selections && changes.selections.currentValue) {
			this.selectionValues = changes.selections.currentValue.filter(x => x !== null);
		}
	}

	displayExpr = (item) => {
		return item.name + (this.showAccountCodes ? ' (' + item.id + ')' : '');
	}

	onChanged = (value: Array<number>) => {
		this.logger.log('event change', value);
		// Need to add null to selections to preserve phantom row display
		if (Array.isArray(value) && value.length > 0) {
			value.push(null);
		}
		this.changed.emit(value);
	}

}
