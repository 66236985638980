import {Injectable} from '@angular/core';
import {FilingStatusServiceModule} from './filing-status-service.module';
import {
	FilingCondition,
	FilingDueDates,
	FilingStatus,
	FilingYearCondition
} from '../../api/fit-api/models/filing-status';
import {ChartDataRow} from './models/chart-data-row';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SummaryFilingStatus} from './models/summary-filing-status';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: FilingStatusServiceModule
})
export class FilingStatusService {

	constructor(
		private fitApi: FitApiService
	) {
	}
	// Used by different pivot grids summarization to determine which filing conditions to count as 'filer'
	readonly filerConditions = [FilingCondition.Timely, FilingCondition.Late, FilingCondition.Filed];

	filingReportIsVisible$ = new BehaviorSubject<boolean>(false);
	filingReportIsLiveUnauthenticated$ = new BehaviorSubject<boolean>(false);
	// todo this needs to be typed/constructed
	filingReportFilter$ = new BehaviorSubject<FilingStatus>({
		countyCodes: null,
		dateSubmitted: undefined,
		daysLate: 0,
		filingBasis: undefined,
		govTypeCode: null,
		maxAllowedSubmitDate: undefined,
		mcag: '',
		noActivity: false,
		noDataAvailable: false,
		pendingUpdates: false,
		snapshotId: undefined,
		governmentStatus: '',
		year: null,
		filingYearCondition: null,
		filingCondition: null
	});

	filingYearConditions = Object.keys(FilingYearCondition)
		.map(key => ({text: FilingYearCondition[key], value: key}));

	filingReportIsVisible = () => this.filingReportIsVisible$.next(true);
	filingReportIsLiveUnauthenticated = () => this.filingReportIsLiveUnauthenticated$.next(true);
	// todo this needs to be typed
	filingReportFilter = (value) => this.filingReportFilter$.next(value);

	/**
	 * Flatten filing statuses into a data source appropriate for feeding into a chart series.
	 * @param filingStatuses
	 */
	getChart(filingStatuses: Array<FilingStatus>): Array<ChartDataRow> {
		return filingStatuses.reduce((acc, status) => {
			let record: ChartDataRow;
			if (this.noFiling(status)) {
				record = this.getObjectRef(acc, 'status', 'No Filing');
			} else if (this.filedOnTime(status)) {
				record = this.getObjectRef(acc, 'status', 'Filed On Time');
			} else if (this.filedLate(status)) {
				record = this.getObjectRef(acc, 'status', 'Filed Late');
			}
			record.count = record.count ? record.count += 1 : 1;
			return acc;
		}, new Array<ChartDataRow>());
	}

	/**
	 * Returns an existing object from an array, or adds a new object to array and returns reference.
	 */
	private getObjectRef<T extends any, K extends keyof T>(arr: Array<T>, keyName: K, keyValue: T[K]) {
		let ref = arr.find(x => x[keyName] === keyValue);
		if (!ref) {
			ref = {} as T;
			ref[keyName] = keyValue;
			arr.push(ref);
		}
		return ref;
	}

	filedOnTime(filingStatus: FilingStatus): boolean {
		return filingStatus.filingCondition === FilingCondition.Timely;
	}

	filedLate(filingStatus: FilingStatus): boolean {
		return filingStatus.filingCondition === FilingCondition.Late;
	}

	noFiling(filingStatus: FilingStatus): boolean {
		return filingStatus.filingCondition === FilingCondition.None;
	}

	filed(filingStatus: FilingStatus): boolean {
		return this.filerConditions.includes(filingStatus.filingCondition);
	}

	isActive(filingStatus: FilingStatus): boolean {
		return filingStatus.governmentStatus === 'Active';
	}

	/**
	 * Determine the "current" filing year by seeing if the due date has passed (as compared to the snapshot creation date)
	 * or if filing was already submitted for the "current" year on the snapshot. If the due date has not passed and the filing
	 * is not yet submitted for the BARS Year, then use the prior year for displaying the government's profile, etc.
	 * @param snapshotDateCreated
	 * @param filingStatusForBarsYear
	 */
	private getFilingYear(snapshotDateCreated: Date, filingStatusForBarsYear: FilingStatus): number {
		if (snapshotDateCreated > filingStatusForBarsYear?.maxAllowedSubmitDate) {
			return filingStatusForBarsYear?.year;
		} else {
			if (filingStatusForBarsYear?.dateSubmitted) {
				return filingStatusForBarsYear?.year;
			} else {
				return filingStatusForBarsYear?.year - 1;
			}
		}
	}

	/**
	 * Determine the "current" filing year to display for a given government and snapshot.
	 * @param mcag
	 * @param snapshotId
	 */
	getFilingYearForDisplay(mcag: string, snapshotId?: SnapshotId): Observable<number> {
		return this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				if (mcag && snapshotId) {
					return this.getFilingStatusForYear(mcag, snapshot.barsYearUsed, snapshotId).pipe(
						map(result => this.getFilingYear(snapshot.dateCreated, result))
					);
				} else {
					return of(null);
				}
			})
		);
	}

	/**
	 * Find the filing status record for the provided year.
	 * @param mcag
	 * @param year
	 * @param snapshotId
	 */
	getFilingStatusForYear(mcag: string, year: number, snapshotId: SnapshotId): Observable<FilingStatus> {
		return this.fitApi.getFilingStatusesForMCAG(mcag, snapshotId).pipe(
			map(results => results?.find(x => x.year === year))
		);
	}

	/**
	 * Get a summarized filing status for GovType that includes the number of active governments, number that filed on
	 * time, and number that filed but have a pending update.
	 * @param govTypeCode
	 * @param snapshotId
	 * @param year
	 */
	getSummarizedFilingStatuses = (govTypeCode: string, snapshotId?: SnapshotId, year?: number): Observable<SummaryFilingStatus> => {
		return this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				const filter = `(year eq ${year ?? snapshot.barsYearUsed} and govTypeCode eq '${govTypeCode}')`;
				return this.fitApi.getFilingStatuses(filter, snapshot.id).pipe(
					map(result =>
						result.reduce((counts, row) => {
							counts.addToSummary(row);
							return counts;
						}, new SummaryFilingStatus(year))
					)
				);
			})
		);
	};

	/**
	 * Get an array of summarized filing statuses for year range, that includes total number of fliers, number that filed on
	 * time, number that filed late and number that didn't file at all
	 * @param beginningYear
	 * @param endingYear
	 * @param govTypeCode
	 * @param countyCode
	 */
	getSummarizedLiveFilingStatusesByYear = (beginningYear: number, endingYear: number, govTypeCode?: string, countyCode?: number): Observable<SummaryFilingStatus[]> => {
		const filter = `(year ge ${beginningYear} and year le ${endingYear})`;
		// creates new array (initial array for reduce function) based on range
		const summaryArr = [];
		for (let i = beginningYear; i <= endingYear; i++) {
			summaryArr[i] = new SummaryFilingStatus(i);
		}

		return this.fitApi.getLiveAndUnauthenticatedFilingStatuses(filter).pipe(
			map(result => {
				if (govTypeCode) {
					result = result.filter(row => row.govTypeCode === govTypeCode);
				}
				if (countyCode) {
					result = result.filter(row => typeof row.countyCodes !== 'number' ? row.countyCodes.includes(countyCode) : row.countyCodes === countyCode);
				}
				return result.reduce((summaries: SummaryFilingStatus[], filingStatusRow) => {
					summaries[filingStatusRow.year].addToSummary(filingStatusRow);
					return summaries;
				}, summaryArr);
			})
		);
	};

	/**
	 * Get an array of summarized filing statuses BY COUNTY for year range, that includes total number of fliers, number that filed on
	 * time, number that filed late and number that didn't file at all
	 * @param beginningYear
	 * @param endingYear
	 * @param govTypeCode
	 */
	getSummarizedLiveFilingStatusesByCounty = (beginningYear: number, endingYear: number, govTypeCode?: string): Observable<SummaryFilingStatus[]> => {
		const filter = `(year ge ${beginningYear} and year le ${endingYear})`;
		return this.fitApi.getLiveAndUnauthenticatedFilingStatuses(filter).pipe(
			map(result => {
				if (govTypeCode) {
					result = result.filter(row => row.govTypeCode === govTypeCode);
				}
				return result.reduce((summaries: SummaryFilingStatus[], filingStatusRow) => {
					let summaryRecord = summaries.find(sfs => sfs.year === filingStatusRow.year && sfs.countyCode === filingStatusRow.countyCodes[0]);
					if (!summaryRecord) {
						summaryRecord = new SummaryFilingStatus(filingStatusRow.year, filingStatusRow.countyCodes[0]);
						summaries.push(summaryRecord);
					}
					summaryRecord.addToSummary(filingStatusRow);
					return summaries;
				}, []);
			})
		);
	};

	/**
	 * Get a date representing the max allowed submission date for any given year for the majority of governments;
	 * this happens to be for fiscal year ending 12/31 governments, but we did not want to hard code it
	 * @param filingYear
	 */
	getFilingDueDatesForMostGovernments = (filingYear: number): Observable<FilingDueDates> => {
		return this.fitApi.getAnnualFilingDueDates.pipe(
			map(filingDatesList => {
				const filingDatesForYear = filingDatesList.filter(filingDate => filingDate.year === filingYear);
				const sortedFilingDates = filingDatesForYear.sort((a, b) => (b.activeGovernments) - (a.activeGovernments));
				return sortedFilingDates[0];
			})
		);
	};

	/**
	 * Get current filing year based on today's date
	 */
	getCurrentYear = (): Observable<number> => {
		return this.fitApi.getAnnualFilingDueDates.pipe(map(filingDatesList => {
			const filingDatesForYear = filingDatesList.filter(filingDate => filingDate.year < new Date(filingDatesList[0].todaysDate).getFullYear());
			const sortedFilingDates = filingDatesForYear.sort((a, b) => (b.year) - (a.year));
			return sortedFilingDates[0].year;
		}));
	};


}
