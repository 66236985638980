export enum AdminNotifyHubMethod {
	taskBegin = 'TaskBegin',
	taskProgress = 'TaskProgress',
	taskEnd = 'TaskEnd'
}

export class AdminNotifyHubPayload {
	type: 'custom' | 'error' | 'info' | 'success' | 'warning';
	message: string;
}

export class AdminNotifyHubProgress {
	type: string;
	value: number;
	max: number;
}