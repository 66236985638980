<div *ngIf="snapshotState === 'current'" class="taskbar__snapshot-status">
	<fa-icon [icon]="icons.check"></fa-icon>
	Data is Current
</div>

<div *ngIf="snapshotState === 'none' || snapshotState === 'ospi'" class="taskbar__snapshot-status taskbar__snapshot-status--warning">
	No snapshot data available
</div>

<div
	*ngIf="snapshotState === 'live'"
	class="taskbar__snapshot-status taskbar__snapshot-status--live"
>
	<fa-icon [icon]="icons.live"></fa-icon>
	Data is Live
</div>

<button
	*ngIf="snapshotState === 'historical'"
	class="taskbar__snapshot-status taskbar__snapshot-status--historical"
	(click)="updateSnapshotToLatest()"
	title="Click to update to latest snapshot data."
>
	<fa-icon [icon]="icons.history"></fa-icon>
	Data is Historical
</button>

<div class="taskbar__snapshot-summary">

		<ng-container *ngIf="snapshotState === 'live'">
			Live (unpublished).
		</ng-container>
		<ng-container *ngIf="snapshotState !== 'live'">
			Published: {{ dataset?.dateCreated | date : 'longDate' }}.
		</ng-container>

		<wasao-hint icon="question">
			<ul class="taskbar__snapshot-summary-detail">
				<li>{{ dataset?.name }}</li>
				<li>{{ dataset?.description }}</li>
				<li>{{ dataset?.comment }}</li>
				<li *ngIf="isDebug && snapshotState !== 'ospi'">Snapshot Id: {{ dataset?.id }}</li>
			</ul>
		</wasao-hint>

</div>

<app-filer-report
	*ngIf="snapshotState !== 'ospi'"
	[tab]="tab"
	[snapshot]="dataset"
></app-filer-report>

<div class="taskbar__disclaimer">
	<em>
		<a class="button" (click)="showDisclaimer()">{{ disclaimer.tiny }}</a>
	</em>
</div>

