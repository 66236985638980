import {Component, Input, OnInit} from '@angular/core';

/**
 * Shows the user a loading indicator while a process is taking place.
 * To constrain to the dimensions of an Angular component, set position: relative on the :host.
 * E.g. SCSS
 * :host { position: relative; }
 */
@Component({
	selector: 'wasao-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
	/**
	 * Bind to your variable to show or hide the loading indicator.
	 */
	@Input() isLoading: boolean;

	/**
	 * Optional message to display to the user while loading.
	 */
	@Input() message: string;

	/**
	 * Draws a rounded border for the background of the loading indicator. Useful for overlaying on select boxes and
	 * dropdowns.
	 */
	@Input() roundedBorder: boolean;

	/**
	 * Show shading (gray-out) behind the indicator. Defaults to true.
	 */
	@Input() shading = true;

	constructor() {
	}

	ngOnInit() {
	}
}
