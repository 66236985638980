import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { LoggerService } from '../services/logger.service';
import { environment } from 'environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	logger: LoggerService;
	errorUrl: string;

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector, private http: HttpClient) {
		this.logger = this.injector.get(LoggerService);
		this.errorUrl = environment.apis.error;
	}

    handleError(error: Error | HttpErrorResponse) {
        let message;
        let stackTrace;

        if (error instanceof HttpErrorResponse) {
			// Server Error
            message = this.getServerMessage(error);
        } else {
            // Client Error
            message = this.getClientMessage(error);
			stackTrace = this.getClientStack(error);
        }

        // Always log errors
		this.logger.error(message, stackTrace);
		if(environment.production || environment.test) {
			this.postError(message, stackTrace);
		}
	}

	private getClientMessage = (error: Error): string => error.message || error.toString();
	private getClientStack = (error: Error): string => error.stack;
	private getServerMessage = (error: Error): string => error.message;

	private postError = (message: string, stackTrace: string) => {
		// AspNetCore has a complicated issue with posting primitives, so pass an object instead
		let postObj = { Message: message + '\n' + stackTrace };
		this.http.post<any>(this.errorUrl, postObj);
	}
}