import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {LoggerService} from './logger.service';
import {Toast} from '../models/toast';

@Injectable({
	providedIn: 'root'
})
/**
 * Provides communication between unrelated components
 */
export class UserInterfaceService {
	// application root events
	overlayActive       = new BehaviorSubject<boolean>(false);
	activeComponentName = new BehaviorSubject<string>(null);
	isSnapshotLoading   = new BehaviorSubject<boolean>(false);

	// dxPopup events
	disclaimer       = new BehaviorSubject<boolean>(false);
	login            = new BehaviorSubject<boolean>(false);
	firstTime        = new BehaviorSubject<boolean>(false);
	accountSettings$ = new BehaviorSubject<boolean>(false);
	dataExtract$     = new BehaviorSubject<boolean>(false);
	about$           = new BehaviorSubject<boolean>(false);
	testUserHarness$ = new BehaviorSubject<boolean>(false);
	feedback$        = new BehaviorSubject<boolean>(false);
	reportMenu$      = new BehaviorSubject<boolean>(false);
	filerReport$     = new BehaviorSubject<boolean>(false);
	noSpecialAccess  = new BehaviorSubject<boolean>(false);
	richText$		 = new BehaviorSubject<boolean>(false);
	reportIssue$	 = new BehaviorSubject<boolean>(false);
	manageResources$ = new BehaviorSubject<boolean>(false);
	createEditResource$ = new BehaviorSubject<{visible: boolean, id: number }>({visible: false, id: null });
	resourceItem$ 	 = new BehaviorSubject<{visible: boolean, id: number }>({visible: false, id: null });
	shapefileLoader = new BehaviorSubject<boolean>(false);
	interactiveFilingStatistics$ = new BehaviorSubject<boolean>(false);
	publishLgcsSnapshot$ = new BehaviorSubject<boolean>(false);
	populationImporter = new BehaviorSubject<boolean>(false);


	toast            = new Subject<Toast>();

	constructor(
		private logger: LoggerService
	) {
	}

	/**
	 * Allows a component to request focus while notifying other components of its request
	 * @param requestor - Please provide this.constructor
	 */
	requestApplicationFocus(requestor: object) {
		this.logger.log(`UserInterfaceService.requestFocus: Focus requested by ${requestor.constructor.name}`);
		this.overlayActive.next(true);
		this.activeComponentName.next(requestor.constructor.name);
	}

	releaseApplicationFocus() {
		this.logger.log(`UserInterfaceService.requestFocus: Focus being released.`);
		this.overlayActive.next(false);
		this.activeComponentName.next(null);
	}

	showDisclaimer      = () => this.disclaimer.next(true);
	showLogin           = () => this.login.next(true);
	showFirstTime       = () => this.firstTime.next(true);
	showAccountSettings = () => this.accountSettings$.next(true);
	showDataExtracts     = () => this.dataExtract$.next(true);
	showAbout           = () => this.about$.next(true);
	showTestUserHarness = () => this.testUserHarness$.next(true);
	showFeedback        = () => this.feedback$.next(true);
	showReportMenu      = () => this.reportMenu$.next(true);
	showFilerReport     = () => this.filerReport$.next(true);
	showNoSpecialAccess = () => this.noSpecialAccess.next(true);
	showRichText        = () => this.richText$.next(true);
	reportIssue         = () => this.reportIssue$.next(true);
	manageResources		= () => this.manageResources$.next(true);
	createEditResource  = (id?: number) => this.createEditResource$.next({visible: true, id});
	resourceItem		= (id?: number) => this.resourceItem$.next({visible: true, id});
	showShapefileLoader = () => this.shapefileLoader.next(true);
	showPublishLgcsSnapshot = () => this.publishLgcsSnapshot$.next(true);
	interactiveFilingStatistics 		= () => this.interactiveFilingStatistics$.next(true);
	showPopulationImporter = () => this.populationImporter.next(true);

	showToast(type: Toast['type'], message: string, displayTime = 4000) {
		this.toast.next({
			type:        type,
			message:     message,
			displayTime: displayTime
		});
	}
}
