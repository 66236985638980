import {Injectable} from '@angular/core';
import {UserService} from 'app/shared/services/user.service';
import {Field} from 'app/shared/models/field';
import {Tab} from 'app/shared/models/tab';
import {UnitsService} from './units.service';
import {SortService} from './sort.service';
import {FormatService} from './format.service';
import {PrimaryGovernmentPipe} from '../pipes/primary-government.pipe';
import {LoggerService} from './logger.service';
import {TabService} from './tab.service';
import {User} from '../models/user';
import {Subscription} from 'rxjs';
import {FundCategoryId} from '../../modules/services/fund-service/models/fund-category';

@Injectable({
	providedIn: 'root'
})
export class FieldService {

	private defaultRowWidth            = 325;
	private user: User;
	private userSubscription: Subscription;
	private readonly annotationsFields = [
		{
			id:            'reportTitle',
			name:          'reportTitle',
			caption:       'Report Title',
			area:          'column',
			areaIndex:     0,
			visible:       false,
			expanded:      true,
			selector:      () => 'reportTitle',
			customizeText: () => 'title'
		}, {
			id:            'reportComments',
			name:          'reportComments',
			caption:       'Report Comments',
			area:          'column',
			areaIndex:     1,
			visible:       false,
			expanded:      true,
			selector:      () => 'reportComments',
			customizeText: () => 'comments'
		}, {
			id:            'reportDisclaimer',
			name:          'reportDisclaimer',
			caption:       'Report Disclaimer',
			area:          'column',
			areaIndex:     2,
			visible:       false,
			expanded:      true,
			selector:      () => 'reportDisclaimer',
			customizeText: () => 'disclaimer'
		}
	];

	readonly filterValuesDefaults = {
		revenues:                  {primeCategory: [1, null], summary: [20, null]},
		revenuesWithOthers:        {primeCategory: [1, null], summary: [20, 25, null]},
		expenditures:              {primeCategory: [1902, null], summary: [30, null]},
		expendituresWithOthers:    {primeCategory: [1902, null], summary: [30, 35, null]},
		schoolsBalanceSheet:       {reportNo: ['001']},
		schoolsStatementRevExp:    {reportNo: ['002']},
		schoolsRevenuesWithOthers: {reportNo: ['009']},
		schoolsExpenditures:       {reportNo: ['002'], itemCategory: ['Expenditure']},
		schoolsLiabilities:        {reportNo: ['001'], itemCategory: ['Liability']},
		schoolsRevenues:           {reportNo: ['002'], itemCategory: ['Revenue']},
		schoolsLongTermLiabilities: { reportNo: ['013'] }
	};

	// Setting these defaults will exclude Internal Service funds
	readonly defaultFunds = [
		[null], // always catch phantom records, regardless of filter
		[1], // All Governmental funds (FundCategory 1)
		[2, 4] // Enterprise funds (Fund Category 2, Fund Type 4)
	];

	/**
	 * Can be used to supply to TransitionService to set a specific view.
	 */
	readonly expenditureObjectPresetTransformation = [
		{name: 'standardAccount', area: null, areaIndex: null},
		{name: 'standardSubAccount', area: null, areaIndex: null},
		{name: 'standardElement', area: null, areaIndex: null},
		{name: 'standardSubElement', area: null, areaIndex: null},
		{name: 'expenditureObject', area: 'row', areaIndex: 0}
	];

	constructor(
		private userService: UserService,
		private units: UnitsService,
		private format: FormatService,
		private sort: SortService,
		private primaryGovernment: PrimaryGovernmentPipe,
		private logger: LoggerService,
		private tabService: TabService
	) {
		this.userSubscription = this.userService.user.subscribe(user => this.user = user);
	}

	/**
	 * Get transformation for providing to TransitionService.
	 *
	 * @param fundCategoryId
	 */
	getFundGroupFilterTransformation = (fundCategoryId: FundCategoryId): any => {
		if (fundCategoryId == null) {
			return null;
		}
		const filterValues = [[fundCategoryId], [null]];
		return {id: 'fundGroupFilter', name: 'fundGroupFilter', filterValues};
	};

	private modifyFields(fields: Array<Field>, modifiedFields: Array<Field>): Array<Field> {
		modifiedFields.forEach((mod) => {
			const fieldIndex = fields.findIndex((f) => f.name === mod.name);
			if (fieldIndex < 0) {
				fields.push(mod);
			}
			else {
				fields[fieldIndex] = this.assignField(fields[fieldIndex], mod);
			}
		});

		return fields;
	}

	/**
	 * Use Object.assign to merge field settings. If present, the groupingConfig
	 * object is assigned into the resulting field before the rest of the field is assigned.
	 * @param {Field} rootField - the base field
	 * @param {Object} modifiedField - the field data to be merged into the base field
	 */
	private assignField(rootField: Field, modifiedField: any) {
		// make a copy to prevent side-effects
		const rootFieldCopy     = Object.assign({}, rootField);
		const modifiedFieldCopy = Object.assign({}, modifiedField);

		if (modifiedFieldCopy.hasOwnProperty('groupingConfig')) {
			Object.assign(rootFieldCopy.groupingConfig, modifiedFieldCopy.groupingConfig);
			delete modifiedFieldCopy.groupingConfig;
			return Object.assign(rootFieldCopy, modifiedFieldCopy);
		}
		else {
			return Object.assign(rootFieldCopy, modifiedFieldCopy);
		}
	}

	/**
	 * Get base fields for pivot table and merge any modifications provided into the resulting fields.
	 * modifiedFields are shallow merged into the base fields EXCEPT for the groupingConfig
	 * object which is shallow merged itself before the rest of the field is merged.
	 * @param {Tab} tab - Associated tab object
	 * @param {Array<any>} modifiedFields - Field objects to be assigned into the base fields.
	 * @return {Array<Field>} An array of the modified base fields.
	 */
	getBaseFields(tab: Tab, modifiedFields: Array<any>): Array<Field> {
		const self       = this;
		const baseFields = [
			// region financialSummaryHierarchy.Standard
			new Field({
				id:             'standardSummary',
				name:           'standardSummary',
				dataField:      'fsSectionId',
				caption:        'Summary',
				area:           'filter',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'summary',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					}
				}
			}),
			new Field({
				id:             'standardAccount',
				name:           'standardAccount',
				dataField:      'basicAccountId',
				caption:        'Account',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'account',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required:     true,
					row:          {
						defaultOrder: 0
					},
					mustBeBefore: ['standardSubAccount', 'standardElement', 'standardSubElement']
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortOnCategoryDisplay(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes);
				}
			}),
			new Field({
				id:             'standardSubAccount',
				name:           'standardSubAccount',
				dataField:      'subAccountId',
				caption:        'Sub-Account',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'subAccount',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					row:          {
						defaultOrder: 1
					},
					mustBeAfter:  ['standardAccount'],
					mustBeBefore: ['standardElement', 'standardSubElement']
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortOnCategoryDisplay(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes);
				}
			}),
			new Field({
				id:             'standardElement',
				name:           'standardElement',
				dataField:      'elementId',
				caption:        'Element',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'element',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					row:          {
						defaultOrder: 2
					},
					mustBeAfter:  ['standardAccount', 'standardSubAccount'],
					mustBeBefore: ['standardSubElement']
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortOnCategoryDisplay(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes);
				}
			}),
			new Field({
				id:             'standardSubElement',
				name:           'standardSubElement',
				dataField:      'subElementId',
				caption:        'Sub-Element',
				groupingConfig: {
					alternate:   {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'subElement',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					row:         {
						defaultOrder: 3
					},
					mustBeAfter: ['standardAccount', 'standardSubAccount', 'standardElement']
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortOnCategoryDisplay(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes);
				}
			}),
			// endregion

			// region financialSummaryHierarchy.debtCapitalExp
			new Field({
				id:             'debtCapitalExpSummary',
				name:           'debtCapitalExpSummary',
				dataField:      'debtCapitalExp.fsSectionId',
				caption:        'Summary',
				area:           'filter',
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						id:          'summary',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			}),
			new Field({
				id:             'debtCapitalExpAccount',
				name:           'debtCapitalExpAccount',
				dataField:      'debtCapitalExp.basicAccountId',
				caption:        'Account',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'account',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					row:          {
						defaultOrder: 0
					},
					mustBeBefore: ['debtCapitalExpSubAccount', 'debtCapitalExpElement', 'debtCapitalExpSubElement']
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortOnCategoryDisplay(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes);
				}
			}),
			new Field({
				id:             'debtCapitalExpSubAccount',
				name:           'debtCapitalExpSubAccount',
				dataField:      'debtCapitalExp.subAccountId',
				caption:        'Sub-Account',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'subAccount',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					row:          {
						defaultOrder: 1
					},
					mustBeAfter:  ['debtCapitalExpAccount'],
					mustBeBefore: ['debtCapitalExpElement', 'debtCapitalExpSubElement']
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortOnCategoryDisplay(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes);
				}
			}),
			new Field({
				id:             'debtCapitalExpElement',
				name:           'debtCapitalExpElement',
				dataField:      'debtCapitalExp.elementId',
				caption:        'Element',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'element',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					row:          {
						defaultOrder: 2
					},
					mustBeAfter:  ['debtCapitalExpAccount', 'debtCapitalExpSubAccount'],
					mustBeBefore: ['debtCapitalExpSubElement']
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortOnCategoryDisplay(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes);
				}
			}),
			new Field({
				id:             'debtCapitalExpSubElement',
				name:           'debtCapitalExpSubElement',
				dataField:      'debtCapitalExp.subElementId',
				caption:        'Sub-Element',
				groupingConfig: {
					alternate:   {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'subElement',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					row:         {
						defaultOrder: 3
					},
					mustBeAfter: ['debtCapitalExpAccount', 'debtCapitalExpSubAccount', 'debtCapitalExpElement']
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortOnCategoryDisplay(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes);
				}
			}),
			// endregion

			new Field({
				id:             'fundCategory',
				name:           'fundCategory',
				dataField:      'fundCategoryId',
				caption:        'Fund Category',
				groupingConfig: {
					column: {
						defaultOrder: 1
					},
					row:    {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				customizeText:  function(data) {
					return self.format.getFundCategoryText(data, tab.snapshotId);
				}
			})
		];

		return self.getAnnotationsFields()
			.concat(self.modifyFields(baseFields, modifiedFields))
			.concat(self.getFundGroupFilterFields())
			.concat(self.getMeasureField(tab));
	}

	getYearField(tab: Tab, modifications = null): Field {
		const self  = this;
		const field = new Field({
			id:             'year',
			name:           'year',
			dataField:      'year',
			caption:        'Year',
			sortingMethod:  self.sort.byString,
			selector:       function(data) {
				return self.format.getYearText(data, tab);
			},
			groupingConfig: {
				required: true,
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		});
		if (modifications) {
			return this.assignField(field, modifications);
		}
		return field;
	}

	getGovernmentField(tab: Tab, modifications = null): Field {
		const self  = this;
		const field = new Field({
			id:             'government',
			name:           'government',
			dataField:      'mcag',
			caption:        'Government',
			groupingConfig: {
				required: true,
				expanded: true,
				column:   {
					defaultOrder: 1
				}
			},
			sortingMethod:  function(a, b) {
				const prime = self.primaryGovernment.transform(tab);
				return self.sort.sortGovernment(a, b, prime);
			},
			selector:       function(data) {
				return self.format.getGovernmentText(data, tab);
			}
		});
		if (modifications) {
			return this.assignField(field, modifications);
		}
		return field;
	}

	getGovernmentTypeField(tab: Tab, modifications = null): Field {
		const self  = this;
		const field = new Field({
			id:             'governmentType',
			name:           'governmentType',
			dataField:      'govTypeCode',
			caption:        'Government Type',
			width:          150,
			groupingConfig: {
				row:    {},
				column: {}
			},
			transitions:    {
				filterValues: function(currentValue) {
					return self.getValueOrDefault(currentValue, []);
				}
			},
			customizeText:  function(data) {
				return self.format.getNameForGovtTypeCode(data, tab);
			}
		});
		if (modifications) {
			return this.assignField(field, modifications);
		}
		return field;
	}

	getPrimeCategoryField(modifications = null) {
		const field = new Field({
			id:        'primeCategory',
			name:      'primeCategory',
			dataField: 'primeId',
			area:      'filter' // Always in filter area hidden from user
		});
		if (modifications) {
			return this.assignField(field, modifications);
		}
		return field;
	}

	getPeerGroupField(tab: Tab, modifications = null) {
		const self  = this;
		const field = new Field({
			id:             'peerGroup',
			name:           'peerGroup',
			caption:        'Peer Group',
			groupingConfig: {
				required: true,
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			},
			selector:       function(data) {
				return self.format.groupPeers(data, tab);
			},
			sortingMethod:  function(a, b) {
				return self.sort.sortPeerGroup(a, b);
			}
		});
		if (modifications) {
			return this.assignField(field, modifications);
		}
		return field;
	}

	getExpenditureObjectField(tab: Tab, modifications = null) {
		const self  = this;
		const field = new Field({
			id:             'expenditureObject',
			name:           'expenditureObject',
			dataField:      'expenditureObjectId',
			caption:        'Expenditure Object',
			width:          150,
			groupingConfig: {
				row:    {},
				column: {}
			},
			transitions:    {
				filterValues: function(currentValue) {
					return self.getValueOrDefault(currentValue, []);
				}
			},
			customizeText:  function(data) {
				return self.format.getNameForExpenditureId(data, tab);
			}
		});
		if (modifications) {
			return this.assignField(field, modifications);
		}
		return field;
	}

	getFundTypeField(tab: Tab, modifications = null) {
		const self  = this;
		const field = new Field({
			id:             'fundType',
			name:           'fundType',
			dataField:      'fundTypeId',
			caption:        'Fund Type',
			groupingConfig: {
				column: {
					defaultOrder: 1
				}
			},
			transitions:    {
				filterValues: function(currentValue) {
					return self.getValueOrDefault(currentValue, []);
				} // Retains current filter values
			},
			sortingMethod:  function(a, b) {
				return self.sort.sortFundTypes(a, b, tab.snapshotId);
			},
			customizeText:  function(data) {
				return self.format.getFundTypeText(data, tab.snapshotId);
			}
		});
		if (modifications) {
			return this.assignField(field, modifications);
		}
		return field;
	}

	/**
	 * Sets field based on report parameters
	 * @param field DevExtreme PivotGridDataSourceField
	 */
	setGroupingAreaAndIndex(field: any) {
		const groupingConfig          = field.groupingConfig;
		const isInactiveAlternate     = groupingConfig && groupingConfig.alternate && !groupingConfig.alternate.active;
		// shift fields by number of fields in annotations
		const annotationsFieldsOffset = this.annotationsFields.length;
		// Only process fields with groupingConfigs that either have no alternate configuration *or* do, and are also active
		if (groupingConfig && !isInactiveAlternate) {
			// look through all properties in field.groupingConfig
			for (const property in groupingConfig) {
				// look to see if row or column key exists, and is not null
				if (['row', 'column'].includes(property) && groupingConfig[property] !== null) {
					if (typeof groupingConfig[property].defaultOrder !== 'undefined') {
						if (property === 'row' && field.width === undefined) {
							field.width = this.defaultRowWidth;
						}

						// If the default order is null, do not set area data
						if (groupingConfig[property].defaultOrder === null) {
							return;
						}

						field.area      = property;
						field.areaIndex = groupingConfig[property].defaultOrder + annotationsFieldsOffset;

					}
					else if (typeof groupingConfig[property].advancedDefaultOrder !== 'undefined' && this.user.showTechnical) {
						if (property === 'row' && field.width === undefined) {
							field.width = this.defaultRowWidth;
						}

						// If the default order is null, do not set area data
						if (groupingConfig[property].advancedDefaultOrder === null) {
							return;
						}

						field.area      = property;
						field.areaIndex = groupingConfig[property].advancedDefaultOrder + annotationsFieldsOffset;
					}
				}
				else if (property === 'expanded') {
					// autoexpand
					field.expanded = groupingConfig[property];
				}
			}
		}
	}

	/**
	 * Get value where not null or undefined, otherwise defaultValue.
	 * @param value
	 * @param defaultValue
	 */
	getValueOrDefault(value: any, defaultValue: any) {
		// if value is null or undefined (coerced)
		return value == null
			? defaultValue
			: value;
	}

	getAnnotationsFields(): Array<Field> {
		return [
			new Field({
				id:            'reportTitle',
				name:          'reportTitle',
				caption:       'Report Title',
				area:          'column',
				areaIndex:     0,
				visible:       false,
				expanded:      true,
				selector:      () => 'reportTitle',
				customizeText: () => 'title'
			}),
			new Field({
				id:            'reportComments',
				name:          'reportComments',
				caption:       'Report Comments',
				area:          'column',
				areaIndex:     1,
				visible:       false,
				expanded:      true,
				selector:      () => 'reportComments',
				customizeText: () => 'comments'
			}),
			new Field({
				id:            'reportDisclaimer',
				name:          'reportDisclaimer',
				caption:       'Report Disclaimer',
				area:          'column',
				areaIndex:     2,
				visible:       false,
				expanded:      true,
				selector:      () => 'reportDisclaimer',
				customizeText: () => 'disclaimer'
			})
		];
	}

	/**
	 * Changes to alternate structure for FinancialSummaryHierarchy
	 *
	 * @param pivotGridDataSource
	 * @param tab
	 * @param structure
	 */
	changeFinancialSummaryHierarchy(pivotGridDataSource, tab, structure) {
		const pivotGridFields = pivotGridDataSource.fields();
		this.logger.log('FieldService.changeFinancialSummaryHierarchy', structure, pivotGridFields);

		// Get the current active structure for financialSummaryHierarchy
		const currentStructure = pivotGridFields.find(v => {
			return v.groupingConfig
				&& v.groupingConfig.alternate
				&& v.groupingConfig.alternate.association === 'financialSummaryHierarchy'
				&& v.groupingConfig.alternate.active === true;
		})?.groupingConfig?.alternate?.structure;

		if (structure === currentStructure) {
			this.logger.log('FieldService.changeFinancialSummaryHierarchy structure states are identical: ('
				+ currentStructure + '). Skipping.');
			return;
		}

		// Extract all pivotGridFields that contain groupingConfig.alternate.association === 'financialSummaryHierarchy'
		const financialSummaryHierarchyFields = pivotGridFields.filter(v => {
			return v.groupingConfig
				&& v.groupingConfig.alternate
				&& v.groupingConfig.alternate.association === 'financialSummaryHierarchy';
		}).reduce((acc, field) => {
			const arrayPerStructure = field.groupingConfig.alternate.structure;
			acc[arrayPerStructure]  = acc[arrayPerStructure] || [];
			acc[arrayPerStructure].push(field);
			return acc;
		}, {});

		// Transition structure to currentStructure, finally deleting area properties from structure when complete
		if (financialSummaryHierarchyFields[currentStructure]) {
			financialSummaryHierarchyFields[currentStructure].forEach(field => {
				const targetFieldRef = financialSummaryHierarchyFields[structure].find(function(v) {
					return v.groupingConfig.alternate.id === field.groupingConfig.alternate.id;
				});

				if (!targetFieldRef) {
					this.logger.error('FieldService.changeFinancialSummaryHierarchy: No target field found!',
						{alternateConfig: field.groupingConfig.alternate, fields: financialSummaryHierarchyFields});
					return false;
				}

				// $log.debug('FieldService.changeFinancialSummaryHierarchy attempting to modify', targetFieldRef, field);

				// Copy filterValues for filters
				if (field.area === 'filter') {
					targetFieldRef.filterValues = field.filterValues;
					delete field.filterValues;
					this.logger.log('FieldService.changeFinancialSummaryHierarchy: Copied a filter to ' + targetFieldRef.name);
					// otherwise transition area properties
				}
				else if (field.area) {
					targetFieldRef.area      = field.area;
					targetFieldRef.areaIndex = field.areaIndex;
					delete field.area;
					delete field.areaIndex;
					this.logger.log('FieldService.changeFinancialSummaryHierarchy: Copied area/index to ' + targetFieldRef.name);
				}
				else {
					// Not assigned to an area, noop
				}

				// Set active properties
				targetFieldRef.groupingConfig.alternate.active = true;
				delete targetFieldRef._initProperties;  // Workaround recursive issue with _initProperties
				pivotGridDataSource.field(targetFieldRef.name, targetFieldRef);
				field.groupingConfig.alternate.active = false;
				delete field._initProperties; // Workaround recursive issue with _initProperties
				pivotGridDataSource.field(field.name, field);
			});
		}

		tab.pivotGridSettings.financialSummaryHierarchy = structure;

		this.tabService.save(tab);

		this.logger.log('FieldService.changeFinancialSummaryHierarchy postprocessing', {
			pivotGridDataSource: pivotGridDataSource.fields()
		});
	}

	/**
	 * Get the fund filter hierarchy. Set the fundGroupFilter's filter property to a jagged array to filter on
	 * the hierarchy. Pass in a fundsFilter to override the default.
	 * @param fundsFilter
	 */
	getFundGroupFilterFields(fundsFilter?: Array<Array<number>>): Array<Field> {
		const self = this;
		const defaultFunds = fundsFilter ?? this.defaultFunds;
		return [
			new Field({
				id:             'fundGroupFilter',
				name:           'fundGroupFilter',
				caption:        'Fund Group Filter',
				area:           'filter', // Always in filter area hidden from user
				groupName:      'fundFilter',
				userFilterable: true,
				transitions:    {
					// Funds are maintained across reports
					filterValues: function(currentValue, hasReportChanged) {
						return self.getValueOrDefault(currentValue, defaultFunds);
					}
				}
			}),
			new Field({
				id:         'fundGroupFilter_fundCategory',
				name:       'fundGroupFilter_fundCategory',
				dataField:  'fundCategoryId',
				area:       'filter', // Always in filter area hidden from user
				groupName:  'fundFilter',
				groupIndex: 0
			}),
			new Field({
				id:         'fundGroupFilter_fundType',
				name:       'fundGroupFilter_fundType',
				dataField:  'fundTypeId',
				area:       'filter', // Always in filter area hidden from user
				groupName:  'fundFilter',
				groupIndex: 1
			}),
			new Field({
				id:         'fundGroupFilter_fund',
				name:       'fundGroupFilter_fund',
				dataField:  'fundNumber',
				area:       'filter', // Always in filter area hidden from user
				groupName:  'fundFilter',
				groupIndex: 2
			})
		];
	}

	// Base field will get written over depending on track and reportId
	getMeasureField(tab: Tab): Field {
		const field = new Field({
			id:          'measure',
			name:        'measure',
			area:        'data',
			areaIndex:   0,
			isMeasure:   true,
			caption:     'Measure', // default that will be overwritten with getMeasureFieldPartial
			dataField:   'amount', // default that will be overwritten with getMeasureFieldPartial
			summaryType: 'sum'  // default that will be overwritten with getMeasureFieldPartial
		});

		return Object.assign(field, this.units.getMeasureFieldPartial(tab));
	}

	getOSPIEmptyFundYearFilterField(tab: Tab): Field {
		return new Field({
			id:           'emptyFundYear',
			name:         'emptyFundYear',
			area:         'filter',
			selector:     data => this.isOSPIEmptyFundYear(data),
			filterValues: tab?.pivotGridSettings?.showEmptySchoolsRows ? [true, false] : [false]
		});
	}

	/**
	 * OSPI data rows within a Fund/Year combination that has no data
	 * @param data
	 */
	isOSPIEmptyFundYear = (data) => !data.fundHasAnyData;

	getOSPIEmptyAmountFilterField(tab: Tab): Field {
		return new Field({
			id:           'emptyNonHeaderAmount',
			name:         'emptyNonHeaderAmount',
			area:         'filter',
			selector:     data => this.isOSPIEmptyNonHeaderAmounts(data),
			filterValues: tab?.pivotGridSettings?.showEmptySchoolsRows ? [true, false] : [false]
		});
	}

	/**
	 * OSPI data rows that are empty (null amounts), but aren't headers
	 * @param data
	 */
	isOSPIEmptyNonHeaderAmounts = (data) => data.itemType !== 'Header' && data.amount === null;

	getDummyDataField(tab: Tab): Field {
		return new Field({
			id:                    'dummy',
			name:                  'dummy',
			isMeasure:             true,
			visible:               false,
			area:                  'data',
			areaIndex:             1,
			calculateSummaryValue: this.units.calculateSummaryValueForPhantomYearDummy(tab)
		});
	}

	getComparisonField(tab: Tab): Field {
		const field = new Field({
			id:          'comparison',
			name:        'comparison',
			area:        'data',
			areaIndex:   1,
			isMeasure:   true,
			caption:     'Comparison', // TODO - not used?
			dataField:   'amount', // TODO - not used?
			summaryType: 'sum'
		});

		return Object.assign(field, this.units.getComparisonFieldPartial(tab));
	}

	expenditureObjectFilterField() {
		const self = this;
		return [
			new Field({
				id:             'expenditureObjectFilter',
				name:           'expenditureObjectFilter',
				dataField:      'expenditureObjectId',
				caption:        'Expenditure Object',
				userFilterable: true,
				area:           'filter', // Always in filter area hidden from user
				transitions:    {
					// ExpenditureObjects are maintained across reports
					filterValues: function(currentValue, hasReportChanged) {
						return self.getValueOrDefault(currentValue, []);
					}
				}
			})
		];
	}

	functionalAccountFilterGroupFields(tab: Tab, alternate: string = null) {
		const self             = this;
		const currentStructure = alternate || 'Standard';

		const groupName = alternate
			? alternate + 'FunctionalAccountsGroup'
			: 'functionalAccountsGroup';

		const functionalAccountsDataFields = [
			'basicAccountId',
			'subAccountId',
			'elementId',
			'subElementId'
		];

		const filterGroupField = new Field({
			id:             groupName,
			name:           groupName,
			groupName:      groupName,
			area:           'filter',
			userFilterable: true,
			caption:        'Functional Category',
			groupingConfig: {
				alternate: {
					association: 'financialSummaryHierarchy',
					structure:   currentStructure,
					id:          'functionalAccountsGroup',
					active:      tab.pivotGridSettings.financialSummaryHierarchy === currentStructure
				}
			},
			transitions:    {
				// 20190110 MSL - No longer throwing out filterValues when report changes
				// Should only change in ReportMenu, which should keep selections sane.
				// All other context-menu navigations should send to Summary report, which does not have this filter
				filterValues: function(currentValue, hasReportChanged) {
					return self.getValueOrDefault(currentValue, []);
				}
			}
		});

		const filterDataFields = functionalAccountsDataFields.map(function(value, index) {
			return new Field({
				id:         groupName + '_' + value,
				name:       groupName + '_' + value,
				dataField:  (alternate ? alternate + '.' : '') + value,
				area:       'filter',
				groupName:  groupName,
				groupIndex: index
			});
		});

		const result = [filterGroupField].concat(filterDataFields);

		return result;
	}

	getFieldFilterValues(pivotGridDataSource, id) {
		const field = this.getFieldById(pivotGridDataSource, id);
		return (field && field.filterValues) || null;
	}

	getActiveAlternateField(pivotGridDataSource, association, id) {
		const fields = pivotGridDataSource.fields();
		const stack  = fields.filter(function(v) {
			return v.groupingConfig
				&& v.groupingConfig.alternate
				&& v.groupingConfig.alternate.association === association
				&& v.groupingConfig.alternate.active === true;
		});

		const targetField = stack.find(function(v) {
			return v.groupingConfig.alternate.id === id;
		});

		if (!targetField) {
			return null;
		}

		// $log.debug('FieldService.getActiveAlternateField', stack, targetField);

		return this.getFieldById(pivotGridDataSource, targetField.id);
	}

	getFieldById(pivotGridDataSource, id) {
		if (!this.isValidPivotGridDataSource(pivotGridDataSource)) {
			throw new Error('FieldService.getFieldById: pivotGridDataSource is not valid.');
		}

		const index = this.getFieldIndexById(pivotGridDataSource, id);
		if (index < 0) {
			return null;
		}

		return pivotGridDataSource.field(index);
	}

	setFieldById(pivotGridDataSource, id, object) {
		const fieldIndex = this.getFieldIndexById(pivotGridDataSource, id);
		if (fieldIndex === -1) {
			this.logger.warn(`FieldService.setFieldById: No field with id of ${id}. No changes will be made.`);
			return;
		}

		// Ensure there are no recursive references
		delete object._initProperties;
		pivotGridDataSource.field(fieldIndex, object);
	}

	isValidPivotGridDataSource(dataSource) {
		return dataSource
			&& typeof dataSource.fields === 'function';
	}

	getFieldIndexById(pivotGridDataSource, id) {
		let index;
		// $log.debug('getFieldIndexById', pivotGridDataSource.fields());
		if (!this.isValidPivotGridDataSource(pivotGridDataSource)) {
			return null;
		}

		index = pivotGridDataSource.fields().findIndex(function(v) {
			return v.id === id;
		});

		return index;
	}

	getFieldNameForPopulationDisplay(pivotGridDataSource, tab: Tab) {
		// Cannot get metrics if no governments, or if snapshot is live (no endpoint)
		if (tab.snapshotId === 'live' || (tab?.governments?.length ?? null) < 1) {
			return null;
		}

		const anyAllowedGovTypes = tab.governments.some(gov => ['06', '07'].some(code => gov.govTypeCode === code));

		if (!anyAllowedGovTypes) {
			return null;
		}

		// A unique filing is determined by a year & government combination and we need to determine the field
		// in this pivot grid configuration that represents that unique filing
		return this.getFieldNameForUniqueFilingDisplay(pivotGridDataSource, tab);
	}

	public getFieldNameForUniqueFilingDisplay(pivotGridDataSource, tab: Tab) {
		if ((tab?.governments?.length ?? null) < 1) {
			return null;
		}

		const yearField = this.getFieldById(pivotGridDataSource, 'year');
		const govField = this.getFieldById(pivotGridDataSource, 'government');

		if (yearField && yearField.area && (!govField || !govField.area)) {
			return tab.governments.length === 1
				? 'year'
				: null;
		} else if (govField && govField.area && (!yearField || !yearField.area)) {
			return tab.years[1] === null || tab.years[0] === tab.years[1]
				? 'government'
				: null;
		} else if (yearField && govField && yearField.area !== govField.area) {
			return null;
		} else if (yearField && govField && govField.area === yearField.area) {
			return govField.areaIndex > yearField.areaIndex
				? 'government'
				: 'year';
		} else {
			return null;
		}
	}

	/**
	 * Ensure FundCategory is present, and expand parent so that FundCategory is shown
	 *
	 * @param pivotGridData
	 * @param currentTab
	 */
	updateFromDisplayOptions(pivotGridData, currentTab: Tab) {
		if (currentTab.pivotGridSettings.comparison.indexOf('percentOfColumnTotal') > -1) {
			const fields   = pivotGridData.fields();
			// get fundType field
			const fundType = this.getFieldById(pivotGridData, 'fundType');
			if (!fundType) {
				this.logger.warn('ScheduleBrowser.updateDisplayOptions: No fundType field was found.');
			}
			else {
				// get lowest column field that is not fundType
				const otherColumnMembers = fields.filter(v => v.area === 'column' && v.id !== 'fundType');
				let lastColumnMember     = null;

				if (otherColumnMembers.length > 0) {
					// get the item with the highest areaIndex
					lastColumnMember = otherColumnMembers.reduce(function(prev, current) {
						return prev.areaIndex > current.areaIndex ? prev : current;
					});
				}

				// $log.debug('ScheduleBrowser.updateDisplayOptions: otherColumnMembers', otherColumnMembers, lastColumnMember);

				// set fundType to lowest column areaIndex
				fundType.area       = 'column';
				const nextAreaIndex = (lastColumnMember
					&& typeof lastColumnMember.areaIndex !== 'undefined'
					&& lastColumnMember.areaIndex > -1)
					? lastColumnMember.areaIndex + 1 : 0;
				fundType.areaIndex  = nextAreaIndex;
				this.setFieldById(pivotGridData, 'fundType', fundType);

				// expand member above, if exists
				if (lastColumnMember) {
					pivotGridData.expandAll(lastColumnMember.caption);
				}
			}

		}

		// Replace related columns and reload the grid
		this.setFieldById(pivotGridData, 'measure', this.getMeasureField(currentTab));
		this.setFieldById(pivotGridData, 'comparison', this.getComparisonField(currentTab));
		if (currentTab?.report?.financialsDatasetSource === 'OSPI') {
			this.setFieldById(pivotGridData, 'emptyNonHeaderAmount', this.getOSPIEmptyAmountFilterField(currentTab));
			if (currentTab.report.id !== 'schoolsLongTermLiabilities') {
				this.setFieldById(pivotGridData, 'emptyFundYear', this.getOSPIEmptyFundYearFilterField(currentTab));
			}
		}

		if (['a', 'b1', 'b2', 'c'].includes(currentTab.track.id)) {
			this.changeFinancialSummaryHierarchy(
				pivotGridData,
				currentTab,
				currentTab.pivotGridSettings.showDebtServiceWithExpenditures
					? 'debtCapitalExp'
					: 'Standard'
			);
		}

		// Ensure metrics are loaded (e.g. population for each government)
		this.tabService.loadGovernmentMetricsForTab(currentTab).then(() => {
			// If unit was changed to perCapita, turn on show population
			if (currentTab.pivotGridSettings.unit.indexOf('capita') > -1) {
				currentTab.pivotGridSettings.showPopulation = true;
			}
		});

		// Update the current tab in the TabService
		this.tabService.save(currentTab).then(() => pivotGridData.load());
	}
}
