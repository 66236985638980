import {CategoryLevel} from './category-level';
import {ReportSummaryItem} from './report-summary-item';

export class CategorySummaryItem extends ReportSummaryItem {
	/**
	 * Friendly name/label
	 */
	category: string;

	sortOrder: number;

	/**
	 * Any PivotGrid field updates that need to be applied to the Report to slice the data into this Category view.
	 */
	fieldTransformations: any;
}
