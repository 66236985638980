import {Injectable} from '@angular/core';
import {Field} from 'app/shared/models/field';
import {FormatService} from '../format.service';
import {Tab} from '../../models/tab';
import {UtilityService} from '../utility.service';
import {SortService} from '../sort.service';
import {FieldService} from '../field.service';
import {LoggerService} from '../logger.service';
import {mean, median} from 'datalib';

import {DxPivotGridComponent} from 'devextreme-angular';

@Injectable({
	providedIn: 'root'
})
export class TrackEService {

	statisticsFootnote = '<div class="footnote">\n' +
		'<div style="padding-bottom: 10px">Statistics</div>' +
		'<div><sub style="font-size: 65%">' +
		'*Trimean - a weighted average of the distribution\'s median and its two quartiles.<br> ' +
		'See ' +
		'<a href="https://en.wikipedia.org/w/index.php?title=Trimean">\n' +
		'<i>Trimean</i> in Wikipedia, The Free Encyclopedia ' +
		'</a>' +
		'for more information.' +
		'</sub></div>\n' +
		'</div>';

	constructor(
		private format: FormatService,
		private utility: UtilityService,
		private sort: SortService,
		private fieldService: FieldService,
		private logger: LoggerService
	) {
	}

	presets() {
		return [];
	}

	governmentalIndicators = (tab: Tab): Array<Partial<Field>> => {
		return [
			...this.fieldService.getAnnotationsFields(),
			{
				id: 'year',
				name: 'year',
				caption: 'Year',
				dataField: 'year',
				groupingConfig: {
					column: {
						defaultOrder: 0,
						required: true
					},
					expanded: true
				}
			},
			{
				id: 'indicator',
				name: 'indicator',
				caption: 'Indicator',
				dataField: 'title',
				customizeText: data => this.format.getIndicatorTitleWithUnits(data, tab.filingBasis, tab.report?.id, tab.snapshotId),
				sortingMethod: (a, b) => this.sort.sortIndicator(a, b, tab.filingBasis, tab.report.id, tab.snapshotId),
				groupingConfig: {
					column: {
						defaultOrder: 2,
						required: true
					},
					expanded: true
				}
			},
			{
				id: 'indicatorFilter',
				name: 'indicatorFilter',
				area: 'filter',
				dataField: 'title',
				transitions: {
					filterValues: currentValue => currentValue ?? []
				}
			},
			{
				id: 'government',
				name: 'government',
				caption: 'Government',
				dataField: 'mcag',
				customizeText: data => this.format.getGovernmentText(data, tab),
				sortingMethod: (a, b) => this.sort.sortGovernment(a, b),
				groupingConfig: {
					row: {
						required: true,
						defaultOrder: 0
					},
					expanded: true
				}
			},
			{
				id: 'funds',
				name: 'funds',
				caption: 'Funds',
				dataField: 'subTitle',
				sortOrder: 'desc',
				groupingConfig: {
					column: {
						defaultOrder: 1,
						required: true
					},
					expanded: true
				}
			},
			{
				id: 'governmentType',
				name: 'governmentType',
				caption: 'Government Type',
				dataField: 'govTypeCode',
				customizeText: data => this.format.getNameForGovtTypeCode(data, tab),
				groupingConfig: {
					column: {},
					row: {}
				}
			},
			{
				id: 'measure',
				name: 'measure',
				dataField: 'measure',
				caption: 'Measure',
				area: 'data',
				summaryType: 'sum',
				isMeasure: true,
				areaIndex: 0
			}
		];
	};

	enterpriseFundIndicators = (tab: Tab): Array<Partial<Field>> => {
		return [
			...this.fieldService.getAnnotationsFields(),
			{
				id: 'year',
				name: 'year',
				caption: 'Year',
				dataField: 'year',
				groupingConfig: {
					column: {
						defaultOrder: 0,
						required: true
					},
					expanded: true
				}
			},
			{
				id: 'indicator',
				name: 'indicator',
				caption: 'Indicator',
				dataField: 'title',
				customizeText: data => this.format.getIndicatorTitleWithUnits(data, tab.filingBasis, tab.report?.id, tab.snapshotId),
				sortingMethod: (a, b) => this.sort.sortIndicator(a, b, tab.filingBasis, tab.report.id, tab.snapshotId),
				groupingConfig: {
					column: {
						defaultOrder: 1
					},
					row: {},
					required: true,
					expanded: true
				}
			},
			{
				id: 'indicatorFilter',
				name: 'indicatorFilter',
				area: 'filter',
				dataField: 'title',
				transitions: {
					filterValues: currentValue => currentValue ?? []
				}
			},
			{
				id: 'government',
				name: 'government',
				caption: 'Government',
				dataField: 'mcag',
				customizeText: data => this.format.getGovernmentText(data, tab),
				sortingMethod: (a, b) => this.sort.sortGovernment(a, b),
				groupingConfig: {
					row: {
						required: true,
						defaultOrder: 0
					},
					mustBeBefore: ['fund'],
					expanded: true
				}
			},
			{
				id: 'fund',
				name: 'fund',
				caption: 'Fund',
				dataField: 'subTitle',
				groupingConfig: {
					row: {
						defaultOrder: 1,
						required: true
					},
					mustBeAfter: ['government']
				}
			},
			{
				id: 'governmentType',
				name: 'governmentType',
				caption: 'Government Type',
				dataField: 'govTypeCode',
				customizeText: data => this.format.getNameForGovtTypeCode(data, tab),
				groupingConfig: {
					column: {},
					row: {}
				}
			},
			{
				id: 'measure',
				name: 'measure',
				dataField: 'measure',
				caption: 'Measure',
				area: 'data',
				summaryType: 'sum',
				isMeasure: true,
				areaIndex: 0
			}
		];
	};

	/**
	 * Format value with given precision, defaulting to no decimals if precision not provided.
	 * @param value
	 * @param precision
	 */
	formatMeasure = (value: number, precision: number = null): number => {
		if (typeof value === 'number') {
			return Number(value.toFixed(precision ?? 0));
		} else {
			return null;
		}
	};

	/**
	 * Apply formatting to the cells, independent of values used for sorting.
	 * @param event - DevExtreme PivotGrid.onCellPrepared event
	 */
	formatPivotGridCell = event => {
		// this.logger.log(`cell`, event.cell);
		const ds = event.component.getDataSource()
			.createDrillDownDataSource(event.cell);
		ds.paginate(false);
		return ds.load()
			.then(rows => {
				// this.logger.log(`drilldown facts`, rows);
				// https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxPivotGrid/Pivot_Grid_Cell/
				const isGrandTotal = event.cell.rowType === 'GT' || event.cell.columnType === 'GT';
				const isDataCell = event.cell.columnType === 'D' && event.cell.rowType === 'D';
				const isHeader = event.cell.type != null; // Only row/column headers have 'type'

				// custom formatting for Grant Total cell (bottom left)
				if (event.area === 'row' && event.cell.type === 'GT') {
					// if no rows, just show "Statistics". Otherwise, show statistics footnote
					event.cellElement.innerHTML = rows.length > 0 ? this.statisticsFootnote : 'Statistics';
					return;
				}

				// If a single value, then format the cell appropriately
				if (rows.length === 1 && !isGrandTotal) {
					// But only if data cell
					if (isDataCell) {
						const singleRow = rows[0];
						// this.logger.log(`singleRow`, singleRow);
						event.cellElement.innerHTML = this.formatMeasure(singleRow.measure, singleRow.measureUnitInfo?.unitDecimalPrecision) ?? '';
						// IE 10/11 doesn't support multiple parameters like sane browsers. 🙄🙄🙄
						event.cellElement.classList.add('indicator');
						event.cellElement.classList.add(singleRow.outlookInfo?.outlook?.toLowerCase());
					} else {
						// otherwise blank it out (grand totals with only one record behind)
						event.cellElement.innerHTML = '';
					}
					// If multiple rows make up cell
				} else {
					// and it's a grand total, calculate the mean, median, trimean, & counts
					if (isGrandTotal) {
						const precision = rows[0].measureUnitInfo?.unitDecimalPrecision;
						const measureValues = rows.filter(x => x.measure != null).map(x => x.measure);  // Don't count measures that are null
						const count = measureValues.length;
						const measureMean = this.formatMeasure(mean(measureValues), precision);
						const measureMedian = this.formatMeasure(median(measureValues), precision);
						const measureTrimean = this.formatMeasure(this.utility.trimean(measureValues), precision);
						if (count === 0) {
							event.cellElement.innerHTML = `Mean: - <br/>Median: - <br/>Trimean*: - <br/>(${count} indicators)`;
						} else {
							event.cellElement.innerHTML = `Mean: ${measureMean}<br/>Median: ${measureMedian}<br/>Trimean*: ${measureTrimean}<br/>(${count} indicators)`;
						}
						// if it's not grand total or a header, then blank it out (this catches data cells that are collapsed)
					} else if (!isHeader) {
						event.cellElement.innerHTML = '';
					}
				}
			});
	};

	/**
	 * Apply formatting to the excel cells */
	formatExcelCell = (component: DxPivotGridComponent, options) => {
		// this.logger.log(`cell`, event.cell);
		const {excelCell, pivotCell} = options;
		const ds = component.instance.getDataSource()
			.createDrillDownDataSource(options.pivotCell);
		ds.paginate(false);
		return ds.load()
			.then(rows => {
				// this.logger.log(`drilldown facts`, rows);
				// https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxPivotGrid/Pivot_Grid_Cell/
				const isGrandTotal = pivotCell.rowType === 'GT' || pivotCell.columnType === 'GT';
				const isDataCell = pivotCell.columnType === 'D' && pivotCell.rowType === 'D';
				const isHeader = pivotCell.type != null; // Only row/column headers have 'type'
				// If a single value, then format the cell appropriately
				if (rows.length === 1 && !isGrandTotal) {
					// But only if data cell
					if (isDataCell) {
						const singleRow = rows[0];
						// this.logger.log(`singleRow`, singleRow);
						singleRow.area = this.formatMeasure(singleRow.measure, singleRow.measureUnitInfo?.unitDecimalPrecision) ?? '';
						// "Cautionary" | "Concerning" | "Indeterminate" | "Good"
						switch (singleRow.outlookInfo?.outlook) {
							case 'Good':
								excelCell.fill = {
									type: 'pattern',
									pattern: 'solid',
									fgColor: {argb: 'bcd199'}
								};
								break;
							case 'Indeterminate':
								excelCell.fill = {
									type: 'pattern',
									pattern: 'solid',
									fgColor: {argb: 'c9c9c7'}
								}; break;
							case 'Cautionary':
								excelCell.fill = {
									type: 'pattern',
									pattern: 'solid',
									fgColor: {argb: 'fad795'}
								}; break;
							case 'Concerning':
								excelCell.fill = {
									type: 'pattern',
									pattern: 'solid',
									fgColor: {argb: 'f2af89'}
								}; break;
						}
					} else {
						// otherwise blank it out (grand totals with only one record behind)
					}
					// If multiple rows make up cell
				} else {
					// and it's a grand total, calculate the mean, median, counts
					if (isGrandTotal) {
						const precision = rows[0].measureUnitInfo?.unitDecimalPrecision;
						const measureValues = rows.filter(x => x.measure != null).map(x => x.measure);  // Don't count measures that are null
						const count = measureValues.length;
						const measureMean = this.formatMeasure(mean(measureValues), precision);
						const measureMedian = this.formatMeasure(median(measureValues), precision);
						const measureTrimean = this.formatMeasure(this.utility.trimean(measureValues), precision);
						if (count === 0) {
							excelCell.value = `Mean: - \n Median: - \n Trimean*: - \n (${count} indicators)`;
						} else {
							excelCell.value = `Mean: ${measureMean} \n Median: ${measureMedian} \n Trimean*: ${measureTrimean} \n (${count} indicators)`;
						}
						// if it's not grand total or a header, then blank it out (this catches data cells that are collapsed)
					} else if (!isHeader) {
						// event.cellElement.innerHTML = '';
					}
				}
			});
	};
}
