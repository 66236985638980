import {Component, ViewChild} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {faExclamationTriangle, faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {PopulationImporterService} from '../population-importer.service';
import {PopulationImporterState} from '../models/population-importer-state';
import {finalize} from 'rxjs/operators';
import {DxFileUploaderComponent} from 'devextreme-angular';

@Component({
	selector: 'app-population-records',
	templateUrl: './population-records.component.html',
	styleUrls: ['./population-records.component.scss']
})
export class PopulationRecordsComponent {

	@ViewChild(DxFileUploaderComponent) dxFileUploader: DxFileUploaderComponent;
	isLoading = false;
	dataSource: DataSource;
	// Use to notify the user that they will be overwriting existing records
	hasExistingRecords: boolean;
	vintageYear: number;
	governmentsLookup: any;
	warningIcon = faExclamationTriangle;
	populationFile: File;

	icons = {
		extLink: faExternalLinkAlt,
	};

	constructor(private service: PopulationImporterService) {
		this.service.vintageYear.subscribe(year => {
			this.vintageYear = year;
			this.dataSource = this.service.getODataDataSource(PopulationImporterState.Records, year);
			this.dataSource.store().on('loaded', this.setHasExistingRecords);
		});
		this.service.getGovernmentsLookup.subscribe(governments => this.governmentsLookup = governments);
	}

	setHasExistingRecords = (records: Array<any>) => {
		this.hasExistingRecords = records.length > 0;
	}

	upload() {
		this.isLoading = true;
		this.service.uploadPopulationFile(this.vintageYear, this.populationFile)
			.pipe(
				finalize(() => this.isLoading = false)
			)
			.subscribe({
				next: value => {
					this.populationFile = null;
					this.dxFileUploader.instance.removeFile(0);
					this.dataSource.reload();
				},
				error: error => this.service.handleError(error)
			});
	}

	valueChanged(event: any) {
		this.populationFile = event.value?.[0];
	}

}
