<ng-container *ngIf="isVisible">
	<div class="sidebar__button">
		<fa-icon class="sidebar__icon" [icon]="icon"></fa-icon>
		Your Entities

		<ul class="your-entities" *ngIf="yourEntities">
			<li *ngFor="let government of yourEntities">
				<a href="#" (click)="openEntityInNewTab(government, $event)">
					{{ government.name }}
				</a>
			</li>
		</ul>
	</div>
</ng-container>
