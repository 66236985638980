import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import {LoggerService} from 'app/shared/services/logger.service';
import {SnapshotService} from '../../services/snapshot.service';
import {UserService} from '../../services/user.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import {AccountingCategoryType} from '../../../modules/services/accounting-category/models/accounting-category-type';
import {AccountingCategoryService} from '../../../modules/services/accounting-category/accounting-category.service';
import {AccountingCategory} from '../../../modules/services/accounting-category/models/accounting-category';

@Component({
	selector:    'app-account-chooser-freeform',
	templateUrl: './account-chooser-freeform.component.html',
	styleUrls:   ['./account-chooser-freeform.component.scss']
})
export class AccountChooserFreeformComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

	@Input() showAccountCodes: boolean;
	// selection indicates that a submit button should be present and onChange is not triggered until that click event
	@Input() initialSelection: AccountingCategory;
	@Input() snapshot: any;
	@Output() changed           = new EventEmitter<AccountingCategory>();
	private destroy             = new Subject<void>();
	private userHasGlobalAccess = false;

	// Keeps track of the bars account description to show in the tooltip
	tooltipBARSAccountId: number;
	tooltipBARSAccountTarget: Element;

	lookup: LookupInterface = {
		component:      null,
		dataSource:     null,
		skipNextUpdate: false,
		selection:      null,
		setValue(val): void {
			this.skipNextUpdate = true;
			this.selection      = val;
		}
	};

	treeView: TreeViewInterface = {
		component:  null,
		dataSource: null
	};

	constructor(
		private logger: LoggerService,
		private snapshotService: SnapshotService,
		private userService: UserService,
		private accountingCategoryService: AccountingCategoryService
	) {
	}

	ngOnInit(): void {
		this.userService.user
			.pipe(takeUntil(this.destroy))
			.subscribe(user => {
				this.userHasGlobalAccess = user.hasGlobalAccess();
				this.treeView.dataSource = this.getDataSource();
				this.lookup.dataSource   = this.getDataSource();
			});
	}

	ngAfterViewInit(): void {
		if (this.initialSelection) {
			this.treeView.component.selectItem(this.initialSelection);
		}
	}

	ngOnDestroy(): void {
		this.destroy.next();
		this.destroy.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.initialSelection?.currentValue && this.treeView.component) {
			this.treeView.component.selectItem(changes.initialSelection.currentValue);
		}

		// Update datasources on snapshot change
		if (changes.snapshot?.currentValue !== changes.snapshot?.previousValue) {
			this.treeView.dataSource = this.getDataSource();
			this.lookup.dataSource   = this.getDataSource();
		}
	}

	private getDataSource(): DataSource {
		let accountingCategories: Array<AccountingCategory>;

		// FS Sections & BARS Accounts
		const financialSummarySections: Array<AccountingCategory> = this.snapshot.detail.financialSummarySections
			.filter(s => this.isAllowedFSSection(s.id))
			.map(section => new AccountingCategory(
				AccountingCategoryType.financialSummarySection,
				`${AccountingCategoryType.financialSummarySection}|${section.id}`,
				0,
				true,
				null,
				true,
				section.id,
				section.sortOrder,
				section.name
			)).sort((a, b) => this.sortByProperty(a, b, 'sortOrder'));
		
		const accounts = this.snapshot.detail.accountDescriptors
			.filter(a => this.isAllowedFSSection(a.fsSectionId))
			.sort((a, b) => this.sortByProperty(a, b, 'categoryDisplay'))
			.map(account => {
				let parentKey: string;
				if (account.acctSgmt === 'BasicAccount') {
					parentKey = `${AccountingCategoryType.financialSummarySection}|${account.fsSectionId}`;
				}
				else {
					parentKey = `${AccountingCategoryType.bars}|${account.parentId}`;
				}

				return new AccountingCategory(
					AccountingCategoryType.bars,
					`${AccountingCategoryType.bars}|${account.id}`,
					parentKey,
					account.hasChildren,
					account.acctSgmt,
					false,
					account.id,
					account.categoryDisplay,
					account.name,
					account.categoryDisplay
				);
			});

		accountingCategories = financialSummarySections.concat(accounts);
		
		// Debt & Liability chart of accounts
		if (this.userHasGlobalAccess) {
			const schedule9Categories = this.snapshot.detail.bonanzaSchedule9Categories
				.sort((a, b) => this.sortByProperty(a, b, 'sortOrder'))
				.map(category => new AccountingCategory(
					AccountingCategoryType.debtCategory,
					`${AccountingCategoryType.debtCategory}|${category.id}`,
					0,
					true,
					null, // Deprecated; only for existing Schedule01 based logic
					false, // Deprecated; only for existing Schedule01 based logic
					category.id,
					category.sortOrder,
					category.name
				));

			const schedule9Types = this.snapshot.detail.bonanzaSchedule9Types
				.sort((a, b) => this.sortByProperty(a, b, 'sortOrder'))
				.map(type => new AccountingCategory(
					AccountingCategoryType.debtType,
					`${AccountingCategoryType.debtType}|${type.id}`,
					`${AccountingCategoryType.debtCategory}|${type.bonanzaSchedule9CategoryId}`,
					true,
					null, // Deprecated; only for existing Schedule01 based logic
					false, // Deprecated; only for existing Schedule01 based logic
					type.id,
					type.sortOrder,
					type.name
				));

			const debtCategoryItems = this.snapshot.detail.debtCategoryItems
				.filter(item => item.active)
				.sort((a, b) => this.sortByProperty(a, b, 'sortOrder'))
				.map(item => new AccountingCategory(
					AccountingCategoryType.debtItem,
					`${AccountingCategoryType.debtItem}|${item.id}`,
					`${AccountingCategoryType.debtType}|${item.bonanzaTypeId}`,
					false,
					null, // Deprecated; only for existing Schedule01 based logic
					false, // Deprecated; only for existing Schedule01 based logic
					item.id,
					item.sortOrder,
					item.name,
					item.categoryDisplay
				));

			accountingCategories = accountingCategories.concat(schedule9Categories, schedule9Types, debtCategoryItems);
		}

		return new DataSource({
			store: accountingCategories,
		});
	}

	private isAllowedFSSection = (fsSectionId: string | number): boolean =>
		this.accountingCategoryService.allowedSections.findIndex(v => v === fsSectionId) > -1;

	private sortByProperty = (a, b, property: string): number =>
		(a[property] > b[property]) ? 1 : ((a[property] < b[property]) ? -1 : 0);

	lookupOnSelectionChanged(event): void {
		if (this.lookup.skipNextUpdate) {
			this.lookup.skipNextUpdate = false;
			return;
		}

		if (event.selectedItem !== null && this.treeView.component) {
			this.changed.emit(event.selectedItem);
			this.treeView.component.unselectAll();
			this.treeView.component.selectItem(event.selectedItem);
		}
	}

	treeViewOnItemSelectionChanged(event): void {
		if (event.itemData !== null) {
			this.changed.emit(event.itemData);
			this.lookup.component.option('value', event.itemData.key);
		}
	}

	/**
	 *
	 * @param event MouseEvent
	 * @param data AccountDescriptor +
	 */
	showBARSAccountTooltip = (event: any, data: AccountingCategory) => {
		// only show for bars accounts
		if (data.type === AccountingCategoryType.bars) {
			this.tooltipBARSAccountId     = data.id;
			this.tooltipBARSAccountTarget = event.target;
		}
	}

	hideBARSTooltip = () => {
		this.tooltipBARSAccountId     = null;
		this.tooltipBARSAccountTarget = null;
	}

	displayExpr = (category: AccountingCategory): string => {
		if (!category) {
			return '';
		}

		const displayCode = category.displayCode || '';
		return displayCode && this.showAccountCodes
			? displayCode + ' - ' + category.displayName
			: category.displayName;
	}

}

/**
 * Local interfaces
 */
interface LookupInterface {
	component: any;
	dataSource: DataSource;
	skipNextUpdate: boolean;
	selection: any;
	setValue: Function;
}

interface TreeViewInterface {
	component: any;
	dataSource: DataSource;
}
