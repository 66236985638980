import {Injectable} from '@angular/core';
import {Tab} from 'app/shared/models/tab';
import {TabState} from 'app/shared/models/tab-state';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {SnapshotService} from './snapshot.service';
import DataSource from 'devextreme/data/data_source';
import {DataSourceService} from './data-source.service';
import {FilterBuilderService} from './filter-builder.service';
import {LoggerService} from './logger.service';
import {FormatService} from './format.service';
import {PivotGridSettings} from '../models/pivot-grid-settings';
import {StorageService} from 'app/shared/services/storage.service';
import {UserService} from './user.service';
import {User} from '../models/user';
import {map, skipWhile, switchMap} from 'rxjs/operators';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {FilingStatusService} from '../../modules/services/filing-status-service/filing-status.service';
import {REPORTS} from '../models/report';
import {DatasetSource} from '../../modules/api/fit-api/models/datasets/dataset-source';
import {AccountingCategoryType} from '../../modules/services/accounting-category/models/accounting-category-type';
import {TransitionService} from './transition.service';
import {UnitsService} from './units.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class TabService {

	private _tabs: Array<Tab> = [];
	public tabs               = new BehaviorSubject<Array<Tab>>([]);
	private _areTabsLoaded    = false;
	public areTabsLoaded      = new BehaviorSubject<Boolean>(this._areTabsLoaded);
	private user: User;

	availableReports = REPORTS;

	availableTracks  = [
		{id: 'a', name: 'Explore data for an individual local government'},
		{id: 'b1', name: 'Compare government data to peers'},
		{id: 'b2', name: 'Compare data between governments'},
		{id: 'c', name: 'Explore summary data across multiple governments'},
		{
			id:                        'd',
			name:                      'Generate summaries by government function ranked by local governments',
			suppressGovtMetricLoading: true
		},
		{id: 'e', name: 'Indicator Reports', suppressGovtMetricLoading: true}
	];

	/**
	 * Default year ranges to use for particular tab states.
	 * TODO this could be better suited to a ts model (how do we declare these restrictions against TabState for type checking?)
	 */
	private defaultNumberOfYears: Partial<Record<TabState, number>> = {
		indicator: 5,
		lgfrs:     4
	};

	constructor(
		private snapshotService: SnapshotService,
		private dataSource: DataSourceService,
		private filterBuilder: FilterBuilderService,
		private log: LoggerService,
		private format: FormatService,
		private storageService: StorageService,
		private userService: UserService,
		private filingStatus: FilingStatusService,
		private fitApi: FitApiService,
		private unitsService: UnitsService
	) {
		this.init();
	}

	private init() {
		// watch for changes to the user and pull out the related tabs if the user.id changes
		const snapshotSub = this.snapshotService.getSnapshot();
		const schoolsSub = this.snapshotService.getSchools();
		const userSub     = this.userService.user;
		combineLatest([snapshotSub, schoolsSub, userSub]).subscribe(([snapshot, schools, user]) => {
			// do not process if snapshot is not populated, or if user has not materially changed
			if (!snapshot || !schools || (user && this.user && user.id === this.user.id)) {
				return;
			}
			this.user = user;
			this.areTabsLoaded.next(false);
			this.storageService.tabs.filter(x => x.userId === user.id).toArray().then(tabs => {
				if (tabs.length === 0) {
					this.populateEmptyTabs(snapshot.id);
				}
				else {
					// In case no tab is selected (can happen with rapid deletes)
					if (!tabs.find(x => x.selected)) {
						tabs[0].selected = true;
					}
					this._tabs = tabs; // update private array
					this.tabs.next(this._tabs); // emit result
					this.areTabsLoaded.next(true);
				}
			});
		});
	}

	/**
	 * Loads tabs from history object, needs to "rehydrate" from JSON object
	 *
	 * @param tabData
	 * @return Promise
	 */
	public loadFromHistory(tabData: string): Promise<Array<Tab>> {
		return new Promise(resolve => {
			const newTabs          = new Array<Tab>();
			const processedTabData = JSON.parse(tabData) as Array<Tab>;

			processedTabData.forEach(t => {
				const a = Object.assign(new Tab(), t) as Tab;
				newTabs.push(a.hydrateFromHistoryStorage());
			});

			this.storageService.transaction('rw', this.storageService.tabs, () => {
				// Delete tabs no longer used and store new tabs state in dexie
				return this.storageService.tabs.where('id').noneOf(this._tabs.map(t => t.id)).delete().then(() => {
					// Export tabs for storage. Inline keys will be used.
					return this.storageService.tabs.bulkPut(newTabs.map(x => x.export())).then(() => {
						// Only emit new tabs if transaction is successful
						this._tabs = newTabs;
						this.tabs.next(this._tabs);

						// // Formerly, we were just replacing this._tabs. This new logic attempts to find and
						// // replace only changed properties, which is a lot better on memory and load time.
						// // This filter will ensure only tabs that should still exist show up in the array
						// this._tabs = this._tabs.filter(tab => {
						// 	// Align this._tabs and newTabs tab via id
						// 	const newTab = newTabs.filter(n => n.id === tab.id);
						//
						// 	if (newTab && newTab.length > 0 && newTab[0]) {
						// 		// Traverse the newTab's properties
						// 		for (let prop in newTab[0]) {
						// 			if (Object.prototype.hasOwnProperty.call(newTab[0], prop) && Object.prototype.hasOwnProperty.call(tab, prop)) {
						//
						// 				// If the properties are different, update them
						// 				if (tab[prop] !== newTab[0][prop]) {
						// 					tab[prop] = newTab[0][prop];
						// 				}
						// 			}
						// 		}
						//
						// 		return tab;
						// 	}
						// });
						//
						// this.tabs.next(this._tabs);
					});
				});
			}).then(() => resolve(newTabs));
		});
	}

	/**
	 * Creates a new hype tab if none exist
	 *
	 * @param snapshotId
	 */
	private async populateEmptyTabs(snapshotId: SnapshotId) {
		const newTab      = new Tab();
		newTab.snapshotId = snapshotId;

		return await this.add(newTab, true).then(() => this.areTabsLoaded.next(true));
	}

	/**
	 * Add a tab
	 *
	 * @param tab
	 * @param setSelected
	 */
	add(tab: Tab, setSelected = true): Promise<Tab | null> {
		tab.userId = this.user.id;

		return new Promise((resolve, reject) => {
			this.storageService.tabs.add(tab.export()).then(id => { // add to database
				Object.assign(tab, {id}); // push autoincrement id onto tab
				this._tabs.push(tab); // Add to private array
				if (setSelected === true) { // do not emit yet if tab is to be selected
					this.setSelectedTab(tab);
				}
				else {
					this.tabs.next(this._tabs); // emit to subscribers
				}

				resolve(tab);
			}, rejectReason => {
				this.log.error('TabService: Could not add tab! This tab may not exist on reload.', rejectReason);
				reject(null);
			});
		});
	}

	/**
	 * Save tab
	 *
	 * @param tab
	 */
	save(tab: Tab): Promise<Tab | null> {
		return new Promise((resolve, reject) => {
			const existing = this._tabs.find(x => x.id === tab.id);

			if (!existing) {
				const error = `TabService: Cannot find Tab with id of ${tab.id}`;
				this.log.error(error, tab);
				reject(null);
				return;
			}

			this.storageService.tabs.update(tab.id, tab.export()).then(updated => {
				if (updated) { // 1 if update successful, 0 if not found or identical data
					const existingTab = this._tabs.find(x => x.id === tab.id); // find in private array
					Object.assign(existingTab, tab); // assign, in case object reference has been broken
					this.tabs.next(this._tabs); // emit to subscribers
				}

				resolve(tab);
			});
		});
	}

	/**
	 * Delete a tab
	 *
	 * @param tab
	 * @return Promise<any>
	 */
	delete(tab: Tab): Promise<Array<Tab>> {
		return new Promise(resolve => {
			this.storageService.tabs.delete(tab.id).then(() => {
				this._tabs = this._tabs.filter(x => x.id !== tab.id); // filter to prevent breaking array reference
				this.tabs.next(this._tabs); // emit to subscribers

				resolve(this._tabs);
			});
		});
	}

	removeProjectionFromTabs = (projectionId: number) => {
		this.storageService.transaction('rw', this.storageService.tabs, () => {
			this.storageService.tabs
				.filter(tab => tab.pivotGridSettings && tab.pivotGridSettings.projectionId === projectionId)
				.toArray()
				.then(tabs => {
					tabs = tabs.map(tab => {
						delete tab.pivotGridSettings.projectionId;
						delete tab.pivotGridSettings.projectionName;
						return tab.export();
					});
					this.storageService.tabs.bulkPut(tabs);
				});
		}).then(() => {
			this.storageService.tabs.filter(tab => tab.userId === this.user.id).toArray().then(tabs => {
				this._tabs = tabs;
			});
		});
	};

	/**
	 * Get the active segment
	 *
	 * @param tab
	 * @return any|null
	 */
	getActiveSegment(tab: Tab) {
		if (!tab.category) {
			this.log.info('TabService.getActiveSegment: No category object found on tab.', tab);
			return null;
		}

		return tab.category.barsSegment;
	}

	/**
	 * Find the current tab
	 *
	 * @return Tab
	 */
	getSelectedTab(): Tab {
		return this._tabs.find(x => x.selected);
	}

	/**
	 * Sets the currently selected tab
	 *
	 * @param tab
	 * @return Promise
	 */
	setSelectedTab(tab: Tab) {
		return new Promise(resolve => {
			const currentSelection = this.getSelectedTab();
			if (currentSelection && currentSelection.id === tab.id) {
				return;
			}

			let selectedTab = null;

			// run selected updates in transaction and then emit
			this.storageService.transaction('rw', this.storageService.tabs, () => {
				this._tabs.forEach(t => {
					t.selected = t.id === tab.id;

					if (t.selected) {
						selectedTab = tab;
					}

					this.storageService.tabs.update(t.id, t.export());
				});
			}).then(() => {
				this.tabs.next(this._tabs);
				resolve(selectedTab);
			});
		});
	}

	/**
	 * Set the selected tab's state
	 *
	 * @param state
	 * @return Tab
	 */
	setSelectedTabState(state: TabState): Promise<any> {
		return new Promise((resolve, reject) => {
			const currentTab = this.getSelectedTab();

			if (currentTab) {
				currentTab.state = state;

				// TODO: maybe combine with the functionality in setSelectedTab
				this.storageService.transaction('rw', this.storageService.tabs, () => {
					this.storageService.tabs.update(currentTab.id, currentTab.export());
				}).then(() => this.tabs.next(this._tabs));

				resolve(currentTab);
			}
			else {
				reject('No tab selected');
			}
		});
	}

	getCombinedSnapshotsForUserAccess(live, snapshots: Array<any>, tab: Tab): Array<any> {
		// determine whether user has access to the mcag on the tab. Evaluate to null
		// for tabs that have no government set
		const mcag = tab?.governments?.length === 1
			? tab.governments[0]?.mcag
			: null;
		const userHasAccessToMcag = this.user.hasAccessToMcag(mcag);
		if (tab.canUseLive(this.user.hasGlobalAccess()) && userHasAccessToMcag) {
			// insert live first, snapshots are ordered by id at the odata call
			return [live, ...snapshots];
		}
		else {
			return snapshots;
		}
	}

	/**
	 * Tracks A & B
	 * @param tab The tab you want to build into a Government Based Report Tab
	 * @param tabUpdates Properties to update on the tab (required: governments, reportId, trackId, years)
	 * @param autosave Automatically save the tab after updating if the tab is valid
	 * @returns Tab
	 */
	async buildGovernmentBasedTab(tab?: Tab, tabUpdates: any = {}, autosave = false) {
		// Create new tab if none is provided
		const isNewTab = !tab;
		if (isNewTab) {
			tab = await this.buildBlankTab('', TabState.lgfrs);
		}

		tabUpdates.trackId           = tabUpdates.trackId || 'a';
		tabUpdates.reportId          = tabUpdates.reportId || 'expenditures';
		tabUpdates.state             = TabState.lgfrs;
		tabUpdates.pivotGridSettings = new PivotGridSettings();

		tab = this.mergeTabUpdates(tab, tabUpdates);

		Object.assign(tab.pivotGridSettings, {
			showRowGrandTotals: tab.report.showRowGrandTotals,
			rowHeaderLayout: tab.report?.rowHeaderLayout || 'tree',
			// default debtAndLiabilities to endingBalance (ending property)
			// measure:            tab.report.id === 'debtAndLiabilities' ? 'endingBalance' : 'amounts',
			measure: this.unitsService.getDefaultMeasure(tab),
			unit: 'dollars',
			comparison: this.unitsService.getDefaultComparison(tab)
		});

		if (this.validateTab(tab, ['governments', 'report', 'track'])) {
			this.autosave(tab, autosave, isNewTab);
		}

		return tab;
	}

	/**
	 * Track C, only government type (summaries)
	 * @param tab The tab you want to build into a Summary Based Tab
	 * @param tabUpdates Properties to update on the tab (required: reportId, trackId, govTypes, locations, years)
	 * @param autosave Automatically save the tab after updating if the tab is valid
	 * @returns Tab
	 */
	async buildSummaryBasedTab(tab?: Tab, tabUpdates: any = {}, autosave = false) {
		const isNewTab = !tab;
		if (isNewTab) {
			tab = await this.buildBlankTab('', TabState.lgfrs).then(t => tab = t);
		}

		tabUpdates.trackId    = tabUpdates.trackId ?? 'c';
		tabUpdates.reportId   = tabUpdates.reportId ?? 'summary';
		tabUpdates.state      = TabState.lgfrs;
		tab.pivotGridSettings = new PivotGridSettings();
		Object.assign(tab.pivotGridSettings, {
			measure:    this.unitsService.getDefaultMeasure(tab),
			unit:       'dollars',
			comparison: this.unitsService.getDefaultComparison(tab),
		});

		tab = this.mergeTabUpdates(tab, tabUpdates);
		delete tab.governments;

		if (this.validateTab(tab, ['track', 'report'])) {
			this.autosave(tab, autosave, isNewTab);
		}

		return tab;
	}

	/**
	 * Track D
	 * @param tab The tab you want to build into a Ranking Based Tab
	 * @param tabUpdates Properties to update on the tab (required: govTypes, locations, category, reportId, trackId, years)
	 * @param autosave Automatically save the tab after updating if the tab is valid
	 * @returns Tab
	 */
	async buildRankingBasedTab(tab?: Tab, tabUpdates: any = {}, autosave = false) {
		const isNewTab = !tab;
		if (isNewTab) {
			tab = await this.buildBlankTab('', TabState.lgfrs);
		}

		tabUpdates.trackId  = tabUpdates.trackId || 'd';
		// resolve proper report based on category
		const schedule09CategoryTotalsCategories = [
			AccountingCategoryType.debtCategory,
			AccountingCategoryType.debtType,
			AccountingCategoryType.debtItem
		];
		tabUpdates.reportId = schedule09CategoryTotalsCategories.includes(tabUpdates.category.type)
			? 'schedule09CategoryTotals' : 'schedule01CategoryTotals';
		tabUpdates.state    = TabState.lgfrs;

		tab = this.mergeTabUpdates(tab, tabUpdates);

		// TODO: implement
		// AnalyticsService.sendEvent(currentTab, 'track');

		tab.pivotGridSettings = new PivotGridSettings();
		Object.assign(tab.pivotGridSettings, {
			showRowGrandTotals: tab.report.showRowGrandTotals,
			rowHeaderLayout: tab.report?.rowHeaderLayout || 'tree',
			measure:            this.unitsService.getDefaultMeasure(tab),
			unit:               'dollars',
			comparison:         this.unitsService.getDefaultComparison(tab),
		});

		if (this.validateTab(tab, ['track', 'report', 'category'])) {
			this.autosave(tab, autosave, isNewTab);
		}

		return tab;
	}

	/**
	 * Indicator
	 * @param tab The tab you want to build into an Indicator tab
	 * @param tabUpdates Properties to update on the tab (required: indicator, governments, years)
	 * @param autosave Automatically save the tab after updating if the tab is valid
	 * @return Tab
	 */
	async buildIndicatorBasedTab(tab?: Tab, tabUpdates: any = {}, autosave = false) {
		const isNewTab = !tab;
		if (isNewTab) {
			tab = await this.buildBlankTab('', TabState.indicator);
		}

		tabUpdates.state = TabState.indicator;

		tab = this.mergeTabUpdates(tab, tabUpdates);

		if (this.validateTab(tab, ['indicator', 'governments'])) {
			this.autosave(tab, autosave, isNewTab);
		}

		return tab;
	}

	async buildIndicatorReportTab(tab?: Tab, tabUpdates: any = {}, autosave = false) {
		const isNewTab = !tab;
		if (isNewTab) {
			tab = await this.buildBlankTab('Indicator Report', TabState.lgfrs);
		}

		tabUpdates.trackId  = 'e';
		tabUpdates.state    = TabState.lgfrs;
		// Ensure these are removed
		delete tab.indicator;
		delete tab.indicatorGroupsState;

		tab = this.mergeTabUpdates(tab, tabUpdates);

		if (this.validateTab(tab, ['filingBasis', 'report'])) {
			this.autosave(tab, autosave, isNewTab);
		}

		tab.pivotGridSettings = new PivotGridSettings();

		return tab;
	}

	/**
	 * Profile tab
	 * @param tab The tab you want to build into a Government Profile tab
	 * @param tabUpdates Properties to update on the tab (required: governments, years)
	 * @param autosave Automatically save the tab after updating if the tab is valid
	 * @return Tab
	 */
	async buildProfileTab(tab?: Tab, tabUpdates: any = {}, autosave = false) {
		const isNewTab = !tab;
		if (isNewTab) {
			tab = await this.buildBlankTab('', TabState.profile);
		}

		tabUpdates.state = TabState.profile;
		// Ensure these are removed
		delete tab.indicator;
		delete tab.indicatorGroupsState;
		delete tab.report;

		// remove secondary governments
		const baseline = tab.getPrimaryGovernment();
		tab.governments = [baseline];

		tab = this.mergeTabUpdates(tab, tabUpdates);

		if (this.validateTab(tab, ['governments'])) {
			this.autosave(tab, autosave, isNewTab);
		}

		// this.snapshotService.selectedGovernmentForLiveSnapshot.next(tab.getPrimaryGovernment());

		return tab;
	}

	/**
	 * Type Profile tab
	 *
	 * @param tab The tab you want to build into a Government Type Profile tab
	 * @param tabUpdates Properties to update on the tab (required: govTypes)
	 * @param autosave Automatically save the tab after updating if the tab is valid
	 * @return Tab
	 */
	async buildTypeProfileTab(tab?: Tab, tabUpdates: any = {}, autosave = false): Promise<Tab | null> {
		const isNewTab = !tab;
		if (isNewTab) {
			tab = await this.buildBlankTab(null, TabState.typeProfile);
		}

		tabUpdates.state = TabState.typeProfile;

		tab = this.mergeTabUpdates(tab, tabUpdates);
		// can't have governments
		delete tab.governments;

		if (this.validateTab(tab, ['govTypes'])) {
			await this.autosave(tab, autosave, isNewTab);
		}

		return tab;
	}

	/**
	 * Create a tab without a defined track
	 *
	 * @param title
	 * @param state
	 * @param isSelected
	 * @return Tab
	 */
	async buildBlankTab(title = 'New Tab', state: TabState = TabState.hype, isSelected = false): Promise<Tab | null> {
		const newTab             = new Tab(title, state);
		newTab.snapshotId        = this.snapshotService.latestId;
		newTab.track             = this.availableTracks[0];
		newTab.report            = this.availableReports[0];
		newTab.pivotGridSettings = new PivotGridSettings();
		newTab.governments       = [];
		newTab.years             = [2014, 2016];

		Object.assign(newTab.pivotGridSettings, {
			showRowGrandTotals: newTab.report.showRowGrandTotals,
			rowHeaderLayout: newTab.report?.rowHeaderLayout || 'tree',
			measure:            'amounts',
			unit:               'dollars',
			comparison:         'none'
		});

		return await this.add(newTab, isSelected);
	}

	/**
	 * Create a tab from share url data
	 *
	 * @param data
	 */
	async buildTabFromShareUrlData(data) {
		const newTab = new Tab();

		Object.assign(newTab, data);
		newTab.snapshotId = this.snapshotService.latestId;

		return await this.add(newTab, true);
	}

	/**
	 * Create a government profile tab
	 *
	 * @param mcag
	 * @param snapshotId
	 */
	buildNewGovernmentProfileTab(mcag: string, snapshotId: SnapshotId = null): Promise<Tab | null> {
		return new Promise<Tab | null>((resolve, reject) => {
			if (!mcag) {
				this.log.error('TabService.buildNewGovernmentProfileTab: no mcag provided.');
				reject(null);
			}

			this.areTabsLoaded.subscribe(isLoaded => {
				if (!isLoaded) {
					return;
				}

				this.log.info(`Tabs are loaded: ${isLoaded}`);

				this.buildBlankTab(`New Tab from MCAG ${mcag}`, TabState.profile, true).then(newTab => {
					// If snapshotId was left null, default to newTab's snapshot ID
					if (!snapshotId) {
						snapshotId = newTab.snapshotId;
					}

					this.log.info(`Creating new government profile with mcag ${mcag} and snapshot ${snapshotId}`);

					this.snapshotService.getSnapshot(snapshotId).pipe(
						skipWhile(snapshot => snapshot == null)
					).subscribe(snapshot => {
						if (!snapshot) {
							this.log.error(`Could not find snapshot: ${snapshotId}`);
							reject(null);
							return;
						}

						this.filingStatus.getFilingYearForDisplay(mcag, snapshotId).subscribe(displayYear => {
							this.loadGovernmentDetailInformation(snapshot.id, mcag).then((gov: any) => {
								newTab.snapshotId  = snapshot.id;
								newTab.governments = [gov];
								newTab.years       = [(displayYear - 4), displayYear];
								newTab.title       = gov.entityNameWithDba;

								this.save(newTab).then(t => resolve(t));
							});
						});
					});
				});
			});
		});
	}

	private mergeTabUpdates(tab: Tab, tabUpdates: any): Tab {
		if (tabUpdates.hasOwnProperty('reportId')) {
			tab.report = this.availableReports.find(v => v.id === tabUpdates.reportId);
			delete tabUpdates.reportId;
		}
		if (tabUpdates.hasOwnProperty('trackId')) {
			tab.track = this.availableTracks.find(v => v.id === tabUpdates.trackId);
			delete tabUpdates.trackId;
		}
		if (tabUpdates.hasOwnProperty('governments')) {
			tab.governments = this.removeExtraneousGovernmentData(tabUpdates.governments);
			delete tabUpdates.governments;
		}

		return Object.assign(tab, tabUpdates);
	}

	private validateTab(tab: Tab, required: Array<string> = []): boolean {
		let isValid = true;
		required.forEach((prop) => {
			if (!tab.hasOwnProperty(prop) || !tab[prop]) {
				this.log.error(`Was expecting a value for ${prop} on this tab object:`, tab);
				isValid = false;
			}
		});
		return isValid;
	}

	private async autosave(tab: Tab, autosave: boolean, isNewTab: boolean) {
		if (autosave && isNewTab) {
			return await this.add(tab);
		}
		else if (autosave) {
			return await this.save(tab);
		}
	}

	/**
	 * Removes unnecessary data before saving in storage
	 *
	 * @param governments
	 */
	removeExtraneousGovernmentData(governments) {
		return governments.map(v => ({
			mcag:              v.mcag,
			entityName:        v.entityName, // Possible future use
			entityNameWithDba: v.entityNameWithDba, // Always use for display
			lookupNameWithDba: v.lookupNameWithDba, // Always use for lists and sorting
			govTypeCode:       v.govTypeCode,
			countyCodes:       v.countyCodes,
			prime:             v.prime,
			financialsDatasetSource: v.financialsDatasetSource
		}));
	}

	/**
	 * Places any government metrics needed onto Tab's governments array.
	 * @param tab
	 */
	loadGovernmentMetricsForTab(tab: Tab): Promise<any> {
		const self = this;

		return new Promise((res, rej) => {
			if (!tab) {
				this.log.warn('TabService.loadGovernmentMetricsForTab: A Tab must be supplied to perform this action.');
				res(null);
				return;
			}

			// Cannot get metrics if no governments, or if snapshot is live (no endpoint)
			if (tab.snapshotId === 'live' || tab.governments?.length < 1) {
				this.log.log('TabService.loadGovernmentMetricsForTab: Disallowed scenario.');
				res(null);
				return;
			}

			// If all governments on the tab have populated metrics, return that result
			if (!tab.governments?.some(function(v) {
				return !v.metrics;
			})) {
				res(tab.governments);
				return;
			}

			// Otherwise, fire a callback to get the metrics and place on tab.governments model
			new DataSource({
				store:  self.dataSource.getStore(tab.snapshotId, 'GovernmentMetrics'),
				filter: self.filterBuilder.group('mcag', tab.governments.map(function(v) {
					return v.mcag;
				})),
				group:  'mcag'
			}).load().then(function(data) {
				tab.governments.forEach(function(v) {
					const record = data.find(function(d) {
						return d.key === v.mcag;
					});
					v.metrics    = record.items;
				});
				res(tab.governments);
			});
		});
	}

	/**
	 * @param snapshotId
	 * @param mcag
	 */
	loadGovernmentDetailInformation(snapshotId, mcag) {
		if (!mcag) {
			this.log.warn('TabService.loadGovernmentInformation: An MCAG must be supplied to perform this action.');
		}

		const self = this;

		return new Promise((res, rej) => {
			// Fire a callback to get the detailed government info
			new DataSource({
				store:  self.dataSource.getStore(snapshotId, 'LocalGovernments'),
				filter: self.filterBuilder.single('mcag', mcag)
			}).load().then(function(data) {
				const record = data.find(function(d) {
					return d.mcag === mcag;
				});
				res(record);
			});
		});
	}

	/**
	 * @param tab
	 * @param govText
	 * @param year
	 */
	setBaselineGovernment(tab: Tab, govText = null, year = null) {
		const self = this;
		// sanity check
		if (govText != null && !['b1', 'b2'].includes(tab.track.id)) {
			self.log.warn('TabService.setBaselineGovernment: Cannot set a baseline for track ' + tab.track.id);
			return;
		}

		// iterate through all governments and set prime by comparing display text against originating function
		tab.governments.forEach(function(gov) {
			const mockData = {mcag: gov.mcag, year: year};
			gov.prime      = self.format.getGovernmentText(mockData, tab) === govText;
		});
		// $log.debug('TabService.setBaselineGovernment', tab);
	}

	/**
	 * @param tab
	 * @param govText
	 */
	deleteGovernment(tab, govText) {
		const self  = this;
		const index = tab.governments.findIndex(function(gov) {
			const mockData = {mcag: gov.mcag};
			return self.format.getGovernmentText(mockData, tab) === govText;
		});

		if (index < 0) {
			self.log.warn('TabService.deleteGovernment: Government ' + govText + ' not found in tab.', tab);
			return null;
		}

		const deletedMCAG = tab.governments[index].mcag;
		tab.governments.splice(index, 1);
		return deletedMCAG;
	}

	/**
	 * Gets an initial year range for a given tab state using the display year as the latest year.
	 * If display year is not provided, use bars year (for SAO Annual Filing) or latest year (for OSPI)
	 * @param state
	 * @param financialsDatasetSource
	 * @param snapshotId - required to fetch the barsYearUsed to get the latest year
	 * @param displayYear - pass specific displayYear to use
	 */
	getInitialYearsRangeForTabState = (state: TabState, financialsDatasetSource: DatasetSource, snapshotId?: SnapshotId, displayYear?: number): Observable<[number, number]> => {
		let obs: Observable<Array<number>> = of(null);
		switch (financialsDatasetSource) {
			case 'OSPI': {
				obs = this.fitApi.getOSPIDataset(true).pipe(map(schools => schools.detail.includedYears));
				break;
			}
			case 'SAOAnnualFiling': {
				obs = this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(map(snapshot => snapshot.detail.includedYears));
				break;
			}
		}

		return obs.pipe(map(includedYears => {
			const numberOfYears = this.defaultNumberOfYears[state];
			// if state does not have a value, set year range to null
			if (!numberOfYears) {
				return null;
			}

			let startYear, endYear: number;
			if (displayYear) {
				startYear = displayYear - numberOfYears + 1;
				endYear   = displayYear;
			} else {
				// return range beginning with the number of years (+1) subtracted from barsYearUsed and ending with barsYearUsed
				startYear = Math.max(...includedYears) - numberOfYears + 1;
				endYear   = Math.max(...includedYears);
			}

			// Only allow start years within includedYears
			startYear = Math.max(startYear, Math.min(...includedYears));

			// Ensure exact type
			const arr       = [startYear, endYear] as [number, number];
			return arr;
		}));
	};

	/**
	 * Experimental. The intention is to allow a consumer to get updates on when the snapshotId changes, regardless of
	 * the current tab.
	 */
	getCurrentTabSnapshotId = (): Observable<SnapshotId> => {
		return this.tabs.pipe(switchMap(tabs => {
			const currentTab = tabs.find(x => x.selected);
			return currentTab != null
				? currentTab.snapshotIdSubject
				: of(null);
		}));
	}

}
