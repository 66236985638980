import {Component, OnInit, Input, EventEmitter, Output, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {distinctUntilChanged, debounceTime, filter} from 'rxjs/operators';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector:    'app-text-search',
	templateUrl: './text-search.component.html',
	styleUrls:   ['./text-search.component.scss']
})
export class TextSearchComponent implements OnInit, OnDestroy {
	@Input() placeholder      = 'Type a name to filter the list';
	@Input() hideSearchButton = false;
	@Output() changed         = new EventEmitter<string>();

	public searchTerm = new Subject<string>();
	public searchIcon = faSearch;
	private minLength = 3;
	private delay     = 500;

	constructor() {
	}

	ngOnInit() {
		// Create an observable that dxTextBox can update
		this.searchTerm.pipe(
			distinctUntilChanged(),
			filter(v => v.length === 0 || v.length >= this.minLength), // do not send results less than 3 chars
			debounceTime(this.delay)
		).subscribe(
			result => this.changed.emit(result) // emit value to parent component
		);
	}

	ngOnDestroy() {
		this.searchTerm.unsubscribe();
	}

}
