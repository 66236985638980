<div class="container">
	<div *ngFor="let item of items">
		<button (click)="handleReportTypeClick(item.report.id, displayYear)">
			<span class="icon">
				<wasao-badge *ngIf="item.report.beta" text="beta"></wasao-badge>
				<img src="assets/images/annual_filing.svg" [alt]="item.report.name" />
			</span>
			{{ item.report.name }}<br />
			<ng-container *ngIf="item.report.canBeSummed && item.amount == null">
				(no data)
			</ng-container>
			<ng-container *ngIf="item.amount">
				{{ item.amount | currency:'USD':'symbol':'1.0-0' }}
			</ng-container>
		</button>
		<div class="more-info">
			<wasao-hint
				*ngIf="item.report.hintInformation"
				icon="infoCircle"
				[hintInformation]="item.report.hintInformation"
			></wasao-hint>
			<wasao-data-story
				[dataStoryId]="item.report.id"
				[clickHandler]="getReportTypeClickHandler(item.report.id, displayYear)"
				[clickHandlerText]="'Explore ' + item.report.name"
			></wasao-data-story>
		</div>
	</div>
</div>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
