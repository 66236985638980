import {Component, Input, OnChanges, OnInit, SecurityContext, SimpleChanges} from '@angular/core';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {AccountDescriptorService} from '../../services/account-descriptor-service/account-descriptor.service';
import {AccountDescriptor} from '../../api/fit-api/models/snapshots/account-descriptor';
import {Subject} from "rxjs";
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Component({
	selector: 'wasao-bars-description',
	templateUrl: './bars-description.component.html',
	styleUrls: ['./bars-description.component.scss']
})
export class BarsDescriptionComponent implements OnInit, OnChanges {
	@Input() id: number;
	@Input() snapshotId: SnapshotId;
	@Input() target: Element;

	description: string;
	isLoading: boolean;
	private barsAccountChanged = new Subject<{ id: number, snapshotId: SnapshotId }>();

	constructor(
		private sanitizer: DomSanitizer,
		private accountDescriptors: AccountDescriptorService
	) {
		// Respond to barsAccountChanged by retrieving the proper bars account and sending to setDescription for
		//  security clearance.
		this.barsAccountChanged.asObservable().pipe(
			tap(() => this.isLoading = true),
			// switchMap to cancel the previous call to accountDescriptors.get if still it has not resolved yet
			switchMap(inputs => this.accountDescriptors.get(inputs.id, inputs.snapshotId)
				.pipe(finalize(() => this.isLoading = false))
			),
		).subscribe(this.setDescription);
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.id) {
			this.barsAccountChanged.next({ id: this.id, snapshotId: this.snapshotId });
		}
	}

	setDescription = (descriptor: AccountDescriptor) => {
		// clear the string for html rendering
		this.description = descriptor?.description
			? this.sanitizer.sanitize(SecurityContext.HTML, descriptor.description)
			: null;
	}

}
