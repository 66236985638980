<dx-popup
	[width]="600"
	[height]="800"
	[dragEnabled]="true"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	(onHidden)="onHidden()"
	title="Create/Edit Resource">

	<div class="app-popup__wrapper">
		<div class="app-popup__inner">
			<form (submit)="handleSubmit($event)">
				<dx-validation-group>
					<app-tab-bar
						[items]="items"
						hasCheck=true
						[selectedId]="resource.type"
						(onSelect)="onResourceTypeChange($event)"
					></app-tab-bar>

					<label class="labels" for="title">Title</label>
					<div class="inputs">
						<dx-text-box id="title"
									 [(ngModel)]="resource.title" name="title">
							<dx-validator>
								<dxi-validation-rule
									type="required"
									message="Title is required"
								></dxi-validation-rule>
							</dx-validator>
						</dx-text-box>
					</div>


					<!--embed-->
					<div *ngIf="resource.type === 'Embed'">

						<label class="labels" for="embed">Embed</label>
						<div class="note">Only code from trusted sources should be entered. Users should only create
							embed code resources while using a trusted device (e.g., SAO domain devices with antivirus
							enabled). If there are any questions or concerns about embed code or what sources should be
							trusted, please contact IS before enabling the embed code in FIT.
						</div>
						<div class="inputs">
							<dx-text-area [height]="130" id="embed"
										  [(ngModel)]="resource.embed" name="embed">
								<dx-validator>
									<dxi-validation-rule
										type="required"
										message="Embed code is required"
									></dxi-validation-rule>
								</dx-validator>
							</dx-text-area>
						</div>


						<label class="labels" for="description">Description</label>
						<div class="inputs">
							<dx-text-area [height]="130" id="description"
										  [(ngModel)]="resource.description" name="description"></dx-text-area>
						</div>
					</div>

					<!--link-->
					<div *ngIf="resource.type === 'Link'">

						<label class="labels" for="link">Link</label>
						<div class="inputs">
							<dx-text-box id="link"
										 [(ngModel)]="resource.link" name="link">
								<dx-validator>
									<dxi-validation-rule
										type="required"
										message="Link is required"
									></dxi-validation-rule>
								</dx-validator>
							</dx-text-box>
						</div>


						<label class="labels" for="link-text">Link Text</label>
						<div class="inputs">
							<dx-text-box id="link-text"
										 [(ngModel)]="resource.linkText" name="link-text">
								<dx-validator>
									<dxi-validation-rule
										type="required"
										message="Link text is required"
									></dxi-validation-rule>
								</dx-validator>
							</dx-text-box>
						</div>

						<label class="labels">Image</label>
						<div id="fileuploader-container">
							<div *ngIf="resource.image else elseBlock">
								<div class="row">
									<div class="column-left">
										<img width="200" height="200" alt='picture'
											 src='data:image/jpeg;base64,{{resource.image}}'>
									</div>
									<div class="column-right">
										<dx-button id="removeSavedFile"
												   text="Remove File"
												   type="default"
												   (onClick)="deleteSavedImage()"></dx-button>
									</div>
								</div>
							</div>

							<ng-template #elseBlock>
								<dx-file-uploader
									(onUploadError)="onUploadError($event)"
									(onUploaded)="onUploaded($event)"
									[allowCanceling]="true"
									[allowedFileExtensions]="['.jpg', '.gif', '.png']"
									[uploadFile]="uploadFile"
									name="image"
									uploadMode="instantly"
								>
									<dx-validator>
										<dxi-validation-rule
											type="required"
											message="Image upload required"
										></dxi-validation-rule>
									</dx-validator>
								</dx-file-uploader>
								<div class="note">Allowed file extensions: .jpg, .gif, .png. Images should be 4:3 aspect
									ratio .
								</div>
							</ng-template>
						</div>
					</div>
					<div class="validationMessage">
						<dx-validation-summary></dx-validation-summary>
					</div>
					<div class="submit">
						<div *ngIf="resource.id else elseBlock">
							<dx-button
								[disabled]="disabled"
								id="editButton"
								text="Edit"
								type="success"
								[useSubmitBehavior]="true"
							>
							</dx-button>
						</div>
					</div>
					<div class="submit">
						<ng-template #elseBlock>
							<dx-button
								[disabled]="disabled"
								id="createButton"
								text="Create"
								type="success"
								[useSubmitBehavior]="true"
							>
							</dx-button>
						</ng-template>
					</div>
				</dx-validation-group>
			</form>
		</div>
	</div>
	<wasao-loading [isLoading]="isLoading" [message]="loadingMessage"></wasao-loading>
</dx-popup>
