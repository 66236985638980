import {Component} from '@angular/core';
import {PopulationImporterService} from './population-importer.service';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {PopulationImporterState} from './models/population-importer-state';
@Component({
	selector: 'app-population-importer',
	templateUrl: './population-importer.component.html',
	styleUrls: ['./population-importer.component.scss']
})
export class PopulationImporterComponent {

	isVisible: boolean;
	state: PopulationImporterState;
	title: string;
	vintageYear: number;

	constructor(
		public service: PopulationImporterService,
		private ui: UserInterfaceService
	) {
		this.ui.populationImporter.subscribe(x => this.isVisible = x);
		this.service.state.subscribe(x => {
			this.state = x;
			this.updateTitle();
		});
		this.service.vintageYear.subscribe(x => {
			this.vintageYear = x;
			this.updateTitle();
		});
	}

	updateTitle = (): void => {
		const vintageYearText = this.vintageYear
			? ` for ${this.vintageYear}`
			: '';
		this.title = `Population Importer: ${this.state}${vintageYearText}`;
	}

	back = (event): void => {
		if (this.state === PopulationImporterState.Records) {
			// MaintainVintage goes back to Vintages and clears out year
			this.service.setState(this.service.states.Vintages, null);
		}
	}

	close = (): void => { this.isVisible = false; };

}
