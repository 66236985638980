<ng-container *ngIf="dataSource?.length || !hideWhenContentEmpty">
	<dx-chart
		[dataSource]="dataSource"
		[palette]="barChartColors"
		resolveLabelOverlapping="stack"
	>
		<dxo-animation [enabled]="isInteractive"></dxo-animation>
		<dxo-size [height]="displayDensity === 'print' ? 350 : undefined"></dxo-size>
		<dxi-series valueField="revenues" name="Revenues" axis="dollars"></dxi-series>
		<dxi-series valueField="expenditures" name="Expenditures" axis="dollars"></dxi-series>
		<dxo-common-series-settings
			argumentField="name"
			type="bar"
			[hoverMode]="isInteractive ? 'allArgumentPoints' : 'none'"
			selectionMode="allArgumentPoints">
			<dxo-label
				[visible]="true"
				format="currency">
			</dxo-label>
		</dxo-common-series-settings>
		<dxi-value-axis name="dollars">
			<dxo-label format="currency largeNumber"></dxo-label>
		</dxi-value-axis>
		<dxo-argument-axis argumentType="string">
			<dxo-label overlappingBehavior="stagger"></dxo-label>
		</dxo-argument-axis>
		<dxo-legend
			[hoverMode]="isInteractive ? 'includePoints' : 'none'"
			verticalAlignment="bottom"
			horizontalAlignment="center"
		></dxo-legend>
	</dx-chart>

	<div class="caption">
		<span class="title">{{ fundCategoryId | fundCategoryLabel }} Revenues</span> ${{ totalRevenues | number: '1.0-0' }} vs.<br />
		<span class="title">{{ fundCategoryId | fundCategoryLabel }} Expenditures</span> ${{ totalExpenditures | number: '1.0-0' }}<br />
		FY {{ singleYearToDisplay }}
	</div>
	<ng-container *ngIf="underlyingDataClickAction && isInteractive">
		<br />
		<dx-button
			text="See Underlying Data"
			type="default"
			(click)="handleUnderlyingDataClick()"
		>
		</dx-button>
		<br />
		<wasao-hint
			icon="infoCircle"
			hintInformation="seeTrendData"
		></wasao-hint>
	</ng-container>
</ng-container>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
