import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Years} from 'app/shared/models/years';
import {LoggerService} from 'app/shared/services/logger.service';
import DataSource from 'devextreme/data/data_source';
import {DataSourceService} from 'app/shared/services/data-source.service';
import {DxSelectBoxComponent} from 'devextreme-angular';
import {UtilityService} from 'app/shared/services/utility.service';

@Component({
	selector: 'app-indicator-instance-selector',
	templateUrl: './indicator-instance-selector.component.html',
	styleUrls: ['./indicator-instance-selector.component.scss']
})
export class IndicatorInstanceSelectorComponent implements OnInit, OnChanges {
	@Input() snapshot: any;
	@Input() selection: string;
	@Input() mcag: string;
	@Input() years: Years;
	@Output() changed = new EventEmitter<string>();
	@Output() accountingBasisChanged = new EventEmitter<string>();
	@ViewChild(DxSelectBoxComponent, { static: true }) dxSelectBox: DxSelectBoxComponent;
	data: any;
	isLoading = false;
	placeholder = 'Select...';
	disabled = false;

	constructor(
		private logger: LoggerService,
		private dataSourceService: DataSourceService,
		private utility: UtilityService
	) {	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isMaterialChange(changes) && this.hasRequiredParameters()) {
			this.refreshDataSource();
		}
	}

	private isMaterialChange = (changes: SimpleChanges) => {
		const mcagChanged = changes.mcag && changes.mcag.currentValue !== changes.mcag.previousValue;
		const yearsChanged = changes.years && changes.years.currentValue
			&& !this.utility.areArrayValuesEqual(changes.years.currentValue, changes.years.previousValue);
		return mcagChanged || yearsChanged;
	}

	private hasRequiredParameters = () => this.snapshot && this.years && this.years.length;

	ngOnInit() { }

	refreshDataSource = () => {
		if (!this.mcag) {
			this.disabled = true;
			this.selection = null;
			this.placeholder = 'Choose a baseline government';
			return;
		}
		this.isLoading = true;
		const dataSource = new DataSource({
			store: this.dataSourceService.getIndicatorGroupsFor(this.snapshot.id, this.mcag, this.years),
			select: ['group'],
			expand: ['indicators($select=instanceCode,title,subTitle,accountingBasis)'],
			paginate: false,
			postProcess: data => this.referenceObjectProperties(data)
		});
		dataSource.load().then(data => {
			this.data = data;
			if (data?.length) {
				// send accounting basis to ReportMenu
				this.accountingBasisChanged.emit(data[0].indicators[0].accountingBasis);
				this.disabled = false;
				this.placeholder = 'Select...';
			} else {
				this.selection = null;
				this.placeholder = 'No indicators for this government';
				this.disabled = true;
			}
			this.isLoading = false;
		});
	}

	onValueChange = (dxEvent: any) => {
		if (!dxEvent.event) { // ignore changes not initiated by user
			return;
		}
		this.changed.emit(dxEvent.value);
	}

	/** Set references for dxSelectBox's group feature
	 * https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxSelectBox/Configuration/#grouped
	*/
	referenceObjectProperties(data: any): any {
		data.map(record => {
			record.key = record.group;
			record.items = record.indicators;
			return record;
		});
		return data;
	}

	formatSelectionTitle = (item: any) => {
		return item ? `${item.title}: ${item.subTitle}` : null;
	}

}
