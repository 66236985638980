import {Injectable} from '@angular/core';
import {User, Claim} from 'app/shared/models/user';
import {ReplaySubject, Observable, forkJoin, of, AsyncSubject, BehaviorSubject} from 'rxjs';
import {StorageService} from 'app/shared/services/storage.service';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from './logger.service';
import {UserInterfaceService} from './user-interface.service';
import {catchError} from 'rxjs/operators';
import {Live} from '../../modules/api/fit-api/models/live';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {ShareInProgress} from '../../modules/decorators/share-in-progress';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private _user: User;
	private _loggedInUser: User;
	/** User id 1 gets created automatically in StorageService and is treated as the "global" or guest user. */
	private _globalId = 1;
	/** Subscribe to be notified of changes to active User */
	user              = new ReplaySubject<User>(1); 	// only emit latest

	private _disclaimerHashSubject = new AsyncSubject<number>();
	private _disclaimerHash: number;

	constructor(
		private storageService: StorageService,
		private http: HttpClient,
		private logger: LoggerService,
		private uiService: UserInterfaceService,
		private fitApi: FitApiService
	) {
		// check if user is logged in
		this.getUserClaim().subscribe((claim: Claim) => {
			// user is not logged in when contactID === 0
			if (claim.contactID === 0) {
				this.loadGlobalUser().then(this.bootstrapUser);
			}
			// otherwise, user is logged in, so load their claim info
			else {
				this.loadUserFromClaim(claim).then(this.bootstrapUser);
			}
		});
	}

	private bootstrapUser = () => {
		// TODO replace with loading dialog
		this.uiService.requestApplicationFocus(this);
		this.logger.info(`${this.constructor.name}: Waiting on Global User and Disclaimer Hash...`);

		forkJoin({
			globalUser:     this.getGlobalUser(),
			liveSnapshot:   this.fitApi.getAnnualFilingSnapshot('live')
		}).pipe(catchError(error => of(error)))
			.subscribe(result => {
				this.logger.info(`${this.constructor.name}: Global User and Disclaimer Hash received.`);
				this.uiService.releaseApplicationFocus();

				// if the active user has a claim, but no role that allows advanced FIT features, show dialog
				if (!this._user.disableNoSpecialAccess && this._user.claim && !this._user.hasAnyFitAccessRole) {
					this.uiService.showNoSpecialAccess();
				}

				const areUnsetOptions = typeof this._user.showTechnical === 'undefined'
					|| typeof this._user.autoUpdateSnapshots === 'undefined';
				const areOptionsSame  = this._user.showTechnical === result.globalUser.showTechnical
					&& this._user.autoUpdateSnapshots === result.globalUser.autoUpdateSnapshots;

				if (areUnsetOptions || !areOptionsSame) {
					this.uiService.showFirstTime();
				}

				// check whether or not the 'FIT by the numbers' infographic should be shown based on the global user settings
				if (this.shouldInteractiveFilingStatisticsBeShown(result.globalUser)) {
					this.uiService.interactiveFilingStatistics();
				}

				// Update the user's mcag restrictions via the /Live route
				if (result.liveSnapshot) {
					const live                    = result.liveSnapshot as Live;
					this._user.isRestrictedByMcag = live.isRestrictedByMcag;
					this._user.allowedMcags       = live.allowedMcags;
					this.user.next(this._user);
				}
			});
	};

	@ShareInProgress
	private getUserClaim() {
		return this.http.get(`${environment.base}/saml2/GetUserProfile`);
	}

	private loadGlobalUser() {
		return this.storageService.users.get(this._globalId).then(user => {
			this._user = user;
			this.user.next(this._user);
		});
	}

	/**
	 * Load or create a local user from claim information
	 * @param claim
	 */
	private loadUserFromClaim(claim: Claim) {
		// filter to see if this contactID exists in user table
		return this.storageService.users.filter(
			(user: User) => user.claim && user.claim.contactID === claim.contactID
		).first().then((user: User) => {
			// if so, update the claim information
			user.claim         = claim;
			this._loggedInUser = user;
			this.save(user);
		}).catch(reason => {
			this.logger.info(`User with contactID ${claim.contactID} not found in localdb. Creating...`);
			// otherwise, create this user locally
			const user         = new User();
			user.claim         = claim;
			this._loggedInUser = user;
			this.save(user);
		});
	}

	private getGlobalUser = () => {
		return new Observable<User>(subscriber => {
			this.storageService.users.get(this._globalId).then(user => {
				subscriber.next(user);
				subscriber.complete();
			}).catch(reason => {
				this.logger.error(`${this.constructor.name}.getGlobalUser: Failed to retrieve user!`, reason);
				subscriber.complete();
			});
		});
	};

	// test user has claims, but no contactID
	private isTestUser = (user: User) =>
		user.claim && !user.claim.contactID;

	// logged in user always has a claim and contactID
	private isLoggedInUser = (user: User) => {
		if (!this._loggedInUser) {
			return false;
		}
		return user.claim && user.claim.contactID === this._loggedInUser.claim.contactID;
	};

	/**
	 * Check the given user to see if the 'FIT by the numbers' pop-up has been viewed
	 */
	private shouldInteractiveFilingStatisticsBeShown = (user: User): boolean => {
		let expiryDate;
		let updateDays = 14; // days after last viewing that the user should be shown the pop-up again
		const todayDate = new Date();

		// Unique logic for the month of May
		if (todayDate.getMonth() === 4) {
			if (todayDate.getDate() <= 31 && todayDate.getDate() >= 27) {
				updateDays = 1;
			} else {
				updateDays = 7;
			}
		}

		if (!user.fitByTheNumbers?.lastViewDate) {
			expiryDate = Date.now();
		} else {
			const acceptanceDate = new Date(parseInt(user.fitByTheNumbers.lastViewDate, 10));
			expiryDate = acceptanceDate.setDate(acceptanceDate.getDate() + updateDays);
		}

		if (Date.now() >= expiryDate.valueOf()) {
			return true;
		}
		return false;

	}

	// #region public methods

	getAll() {
		return this.storageService.users.toArray();
	}

	getTestableUsers() {
		return this.storageService.users.filter(user =>
			this.isTestUser(user) || this.isLoggedInUser(user)
		).toArray();
	}

	// updateActiveUser = false allows us to save a user without triggering an update to the application (TestUserHarness)
	save(user: User, updateActiveUser = true) {
		return this.storageService.users.put(user).then(key => {
			if (key && updateActiveUser) {
				// Dexie.Table.put replaces or *adds*. Ensure we have the reference to user in case new record.
				this._user = user;
				this.user.next(user);
			}
		});
	}

	/** Retrieve the specified user from storage and emit via this.user */
	setUser(id: number) {
		return this.storageService.users.get(id).then(user => {
			this._user = user;
			this.user.next(user);
		});
	}

	/**
	 * Sets the fit by the numbers last view date
	 *
	 */
	setFitByTheNumbersViewed(): void {
		const fitByTheNumbers = {
			lastViewDate: Date.now().toString(),
		};
		// also update the global user
		if (!this._user.isLocalUser) {
			this.storageService.users.update(this._globalId, {fitByTheNumbers: fitByTheNumbers});
		}
		this._user.fitByTheNumbers = fitByTheNumbers;
		this.save(this._user);
	}

	setHasReadReportPopupNotification(value: boolean) {
		this._user.hasReadReportPopupNotification = value;
		// also update the global user
		if (!this._user.isLocalUser) {
			this.storageService.users.update(this._globalId, {hasReadReportPopupNotification: value});
		}
		this._user.hasReadReportPopupNotification = value;
		return this.save(this._user);
	}

	setShowTechnical(showTechnical: boolean) {
		this._user.showTechnical = showTechnical;
		// also update the global user
		if (!this._user.isLocalUser) {
			this.storageService.users.update(this._globalId, {showTechnical: showTechnical});
		}
		return this.save(this._user);
	}

	setAutoUpdateSnapshots(autoUpdateSnapshots: boolean) {
		this._user.autoUpdateSnapshots = autoUpdateSnapshots;
		// also update the global user
		if (!this._user.isLocalUser) {
			this.storageService.users.update(this._globalId, {autoUpdateSnapshots: autoUpdateSnapshots});
		}
		return this.save(this._user);
	}

	setDisableNoSpecialAccess = (value: boolean) => {
		this._user.disableNoSpecialAccess = value;
		return this.save(this._user);
	};

	/**
	 * Login user with an optional return URL.
	 * In dev environments, this will attempt to use the loginUser defined in environment (see SSO documentation for requirements)
	 */
	login(returnUrl: string = null) {
		const params = [];
		if (returnUrl) {
			params.push(`returnUrl=${returnUrl}`);
		}
		if (environment.dev && environment.loginUser) {
			params.push(`email=${environment.loginUser}`);
		}

		let url = `${environment.base}/saml2/login`;
		params.forEach((param, index) => {
			const prefix = index === 0 ? '?' : '&';
			url          = `${url}${prefix}${param}`;
		});
		window.location.href = url;
	}

	/** Log the current SSO user out and swap in global user. */
	logout() {
		this.http.get(`${environment.base}/service/logout`).subscribe(response => {
			this.loadGlobalUser();
		});
	}

	// #endregion public methods

}
