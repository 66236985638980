import {ActivatedRoute} from '@angular/router';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

export class RouteAction {

	/**
	 * 	@deprecated - Components should use the Angular Router to return to the base url, i.e. Router.navigateByUrl('')
	 * Erases URL from the browser utilizing replaceState, so a user can be linked
	 * to a specific route, and when they either refresh or go back in their browser
	 * the action is not repeated indefinitely.
	 */
	eraseUrl() {
		window.history.replaceState(null, null, '/');
	}

}
