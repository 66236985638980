import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {LoggerService} from '../../../shared/services/logger.service';
import {UtilityService} from '../../../shared/services/utility.service';
import {FormatService} from '../../../shared/services/format.service';
import {forkJoin, of} from 'rxjs';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {SortService} from '../../../shared/services/sort.service';
import {RankingTrend} from './models/ranking-trend';
import {FinancialRanking, PopulationRanking} from './models/ranking';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {ExternalLGDataService} from '../../api/external-lgdata/external-lgdata.service';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class RankingService {
	constructor(
		private logger: LoggerService,
		private utility: UtilityService,
		private format: FormatService,
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private sortService: SortService,
		private externalLGData: ExternalLGDataService
	) {
	}

	/**
	 * Get an array of RankingTrends
	 *
	 * @param mcag
	 * @param year
	 * @param snapshotId
	 * @return The year (for reference, in case none was provided) and the rankings array with all records
	 * from year and priorYear (year - 1).
	 */
	getRankingsTrend(mcag: string, year?: number, snapshotId?: SnapshotId): Promise<{ year: number, rankings: Array<RankingTrend> }> {
		return new Promise<{ year: number, rankings: Array<RankingTrend> }>(resolve => {
			this.fitApi.getAnnualFilingSnapshot(snapshotId).subscribe(snapshot => {
				const displayYear = year ? of(year) : this.filingStatus.getFilingYearForDisplay(mcag, snapshotId);
				displayYear.subscribe(y => {
					// rankings require a current year and a prior year
					const currentYear = y;
					const priorYear   = y - 1;

					// Get both with FundCategory resolution (for summed Fund Category amounts) and without FundCategory resolution
					// (for summing bars accounts across FundCategory).
					const handleFinances = result => {
						const groups = this.utility.groupByKeys(['fundCategoryId', 'fsSectionId', 'basicAccountId'])(result);

						const groupsAsArray: Array<Array<FinancialRanking>> = [];
						Object.keys(groups).forEach(key => groupsAsArray.push(groups[key]));

						return groupsAsArray.map(group => {
							// after grouping, should only contain as many results as there are year
							const current = group.find(x => x.year === currentYear);
							const prior   = group.find(x => x.year === priorYear);

							return {
								name:           this.getRankingName(snapshot.id, group[0].fundCategoryId, group[0].fsSectionId, group[0].basicAccountId),
								format:         'currency',
								currentYear:    currentYear,
								currentRanking: current ? current.rank : null,
								currentMeasure: current ? current.amount : null,
								priorYear:      priorYear,
								priorRanking:   prior ? prior.rank : null,
								priorMeasure:   prior ? prior.amount : null,
								fundCategoryId: group[0].fundCategoryId,
								fsSectionId:    group[0].fsSectionId,
								basicAccountId: group[0].basicAccountId,
							} as RankingTrend;
						});
					};

					const withFundCategory    = this.fitApi.getFinancialRankingsForGovernment(mcag, true, priorYear, currentYear, snapshotId).pipe(
						map(handleFinances));
					const withoutFundCategory = this.fitApi.getFinancialRankingsForGovernment(mcag, false, priorYear, currentYear, snapshotId).pipe(
						map(handleFinances));

					// population rankings
					const population = this.externalLGData.getPopulationRankingsForGovernment(mcag, priorYear, currentYear).pipe(map(result => {
						const groups = this.utility.groupByKeys(['govTypeCode'])(result.filter(ranking => ranking.population));

						const groupsAsArray: Array<Array<PopulationRanking>> = [];
						Object.keys(groups).forEach(key => groupsAsArray.push(groups[key]));

						return groupsAsArray.map(group => {
							// after grouping, should only contain as many results as there are year
							const current = group.find(x => x.year === currentYear);
							const prior   = group.find(x => x.year === priorYear);

							return {
								name:           'Population',
								format:         'number',
								currentYear:    currentYear,
								currentRanking: current ? current.rank : null,
								currentMeasure: current ? current.population : null,
								priorYear:      priorYear,
								priorRanking:   prior ? prior.rank : null,
								priorMeasure:   prior ? prior.population : null,
								population:     group[0].population,
							} as RankingTrend;
						});
					}));

					forkJoin({
						withFundCategory:    withFundCategory,
						withoutFundCategory: withoutFundCategory,
						populationRankings:  population
					}).subscribe(result => {
						// Sort rankings by compound sort
						const rankings = result.withFundCategory
							.concat(result.withoutFundCategory)
							.concat(result.populationRankings)
							.sort((a, b) => this.compoundSort(a, b, snapshot.id));

						resolve({year: currentYear, rankings: rankings});
					});
				});
			});
		});
	}

	/**
	 * TODO have author (S Looney) add comments
	 *
	 * @param snapshotId
	 * @param fundCategoryId
	 * @param fsSectionId
	 * @param basicAccountId
	 * @param population
	 */
	private getRankingName(snapshotId, fundCategoryId, fsSectionId, basicAccountId): string {
		let name = '';

		if (fundCategoryId) {
			name = this.format.getFundCategoryText(fundCategoryId, snapshotId);
			if (name === 'Proprietary') {
				name = 'Enterprise';  // Duane's spreadsheet specifies this terminology
			}
		}

		if (basicAccountId) {
			name = name + (name.length > 0 ? ' ' : '')
				+ this.format.getNameForBARSId(basicAccountId, snapshotId)
				+ ' (' + this.format.getNameForSectionId(fsSectionId, snapshotId) + ')';
		}
		else {
			let text = this.format.getNameForSectionId(fsSectionId, snapshotId);
			if (fundCategoryId === 2 && text === 'Expenditures') {
				text = text + '/Expenses';
			}
			name = name + (name.length > 0 ? ' ' : '') + text;
		}

		return name;
	}

	/**
	 * This sorts based on:
	 *  1. The value of .format
	 *	2. FundCategory.sortorder ?? 99 (nulls at the bottom of the sorted list; Governmental, then Enterprise)
	 * 	3. FSSectionId.sortorder (revenues are first, then expenditures)
	 * 	4. BasicAccountId.sortorder (category display or account)
	 * http://saoapolytfsweb:8080/tfs/SAOCollection/BonanzaUI/_workitems/edit/12112
	 */
	compoundSort = (a, b, snapshotId) => {
		const formatType = this.sortService.sortFormatType(a, b);
		if (formatType === 0) { // format is same for both a and b, go to next check
			const fundCategory = this.sortService.sortFundCategories(a.fundCategoryId, b.fundCategoryId, snapshotId, 99);
			if (fundCategory === 0) { // fundCategory is same for both a and b, go to next check
				const fsSection = this.sortService.sortFsSection(a.fsSectionId, b.fsSectionId, snapshotId);
				if (fsSection === 0) { // fsSection is the same for both a and b, go to next check
					return this.sortService.sortOnCategoryDisplay(a.basicAccountId, b.basicAccountId, snapshotId);
				}
				return fsSection;
			}
			return fundCategory;
		}
		return formatType;
	}
}
