import {Component, OnInit} from '@angular/core';
import {ShareUrlService} from '../../shared/services/share-url.service';
import {environment} from '../../../environments/environment';

@Component({
	selector:    'app-share-url',
	templateUrl: './share-url.component.html',
	styleUrls:   ['./share-url.component.scss']
})
export class ShareUrlComponent implements OnInit {

	public placeholder = '';

	constructor(
		public shareUrlService: ShareUrlService,
	) {
		this.placeholder = `${window.location.protocol}//${window.location.host}${window.location.pathname}share/`;
	}

	ngOnInit() {
	}

	/**
	 * Handle focus event
	 *
	 * @param event
	 */
	handleFocus(event) {
		if (!this.shareUrlService.currentShareUrl) {
			this.shareUrlService.storeTabData(event);
		}
	}

}
