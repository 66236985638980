import {Component, Input, OnInit} from '@angular/core';
import {Reusable} from '../../../reusable/models/reusable';
import {SnapshotId} from '../../../api/fit-api/models/snapshot-like';

@Component({
	selector: 'wasao-badges',
	templateUrl: './badges.component.html',
	styleUrls: ['./badges.component.scss']
})
export class BadgesComponent extends Reusable implements OnInit {
	@Input() mcag: string;
	@Input() govTypeCode: string;
	@Input() displayYear: number;
	@Input() snapshotId: SnapshotId;
	@Input() onTimeFilerClickAction: () => void;

	constructor() {
		super();
	}

	ngOnInit() {
	}

}
