import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IncomeByFundComponent} from './income-by-fund.component';
import {FitApiModule} from '../../api/fit-api/fit-api.module';
import {FinancialSummaryServiceModule} from '../../services/financial-summary-service/financial-summary-service.module';
import {DxButtonModule, DxChartModule} from 'devextreme-angular';
import {HintModule} from '../../ui/hint/hint.module';
import {LoadingModule} from '../../ui/loading/loading.module';
import {FundServiceModule} from '../../services/fund-service/fund-service.module';

@NgModule({
	declarations: [IncomeByFundComponent],
	imports: [
		CommonModule,
		FitApiModule,
		FinancialSummaryServiceModule,
		DxChartModule,
		DxButtonModule,
		HintModule,
		LoadingModule,
		FundServiceModule
	],
	exports: [IncomeByFundComponent]
})
export class IncomeByFundModule {
}
