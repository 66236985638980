import {Injectable} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {FinancialSummary, FinancialSummarySection} from '../../api/fit-api/models/financial-summary';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {TotalAmounts} from './models/total-amounts';
import {forkJoin, Observable, of} from 'rxjs';
import {CategorySummaryItem} from './models/category-summary-item';
import {FundCategoryId} from '../fund-service/models/fund-category';
import {Report, ReportId, REPORTS} from '../../../shared/models/report';
import {FinancialSummaryServiceModule} from './financial-summary-service.module';
import {EnterpriseTotalAmount} from './models/enterprise-total-amount';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {FilingStatusService} from '../filing-status-service/filing-status.service';
import {Schedule9Service} from '../schedule-9-service/schedule-9.service';
import {FinancialReportRow} from '../../api/fit-api/models/schools/financial-report-row';
import {CategoryTypeToReportMap} from './models/category-type-to-report-map';
import {CategoryType} from './models/category-type';
import {SummaryYearItem} from './models/summary-year-item';
import {ReportSummaryItem} from './models/report-summary-item';
import {AmountsByCategory} from './models/amounts-by-category';
import {FieldService} from '../../../shared/services/field.service';
import {FormatService} from '../../../shared/services/format.service';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: FinancialSummaryServiceModule
})
/**
 * Provides financial information for individual governments and government types by useful summarizations:
 *  1. Totals by Report (a specific report
 *  2. Totals by Categories within a CategoryType (somewhat arbitrary grouping, e.g. abstract "revenues")
 *  3. Totals by Funds within a FundCategory (Governmental vs. Enterprise) -- todo not yet implemented
 */
export class FinancialSummaryService {

	/**
	 * Reports that are eligible to be summarized.
	 */
	eligibleReports = REPORTS.filter(x => x.canBeSummed);

	categoryTypeToReportMap: CategoryTypeToReportMap = {
		revenues: { SAOAnnualFiling: 'revenues', OSPI: 'schoolsRevenuesWithOthers' },
		expenditures: { SAOAnnualFiling: 'expenditures', OSPI: 'schoolsExpenditures' }
	};

	constructor(
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private schedule9Service: Schedule9Service,
		private fieldService: FieldService,
		private formatService: FormatService
	) {
	}

	/**
	 * Get top-level totals by report for the provided government or government type.
	 * @param specificity
	 * @param snapshotId - only SAOAnnualFiling, specifies the snapshotId, defaults to latest
	 * @param fundCategoryId - only SAOAnnualFiling, filters amounts on this fundCategory only
	 */
	getTotalsByReport(specificity: GovernmentSpecificity, snapshotId?: SnapshotId, fundCategoryId?: FundCategoryId) {
		return this.fitApi.getDatasetFromSpecificity(specificity).pipe(
			switchMap(datasetSource => {
				switch (datasetSource) {
					case 'SAOAnnualFiling':
						return this.getTotalsByReportAnnualFiling(specificity, snapshotId, fundCategoryId);
					case 'OSPI':
						return this.getTotalsByReportOSPI(specificity);
					default:
						throw new Error('FinancialSummaryService.getSummaryItems: Cannot get items for DatasetSource '
							+ datasetSource);
				}
			})
		);
	}

	private getTotalsByReportAnnualFiling(
		specificity: GovernmentSpecificity,
		snapshotId?: SnapshotId,
		fundCategoryId?: FundCategoryId
	): Observable<Array<ReportSummaryItem>> {
		return this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				const summaries = specificity.type === 'government'
					? this.fitApi.getFinancialSummariesForMcag(specificity.id, snapshotId)
					: this.fitApi.getFinancialSummariesForGovType(specificity.id, snapshotId);
				const displayYear = specificity.type === 'government'
					? this.filingStatus.getFilingYearForDisplay(specificity.id, snapshotId)
					: of(snapshot.barsYearUsed);
				const schedule9 = this.schedule9Service.getTotalAmounts(specificity, snapshotId);

				return forkJoin({ summaries, displayYear, schedule9 }).pipe(
					map(result => {
						// process summary endpoint
						const items = result.summaries.reduce((acc, row) => {
							// If fundCategory is provided, do not process other fundCategories
							if (fundCategoryId !== undefined && row.fundCategoryId !== fundCategoryId) {
								return acc;
							}

							const applicableReportSummaryItems = this.findOrInitSAOAnnualFilingReportSummaryItems(row, acc, result.displayYear);
							applicableReportSummaryItems.forEach(item => {
								let ref = item.years.find(x => x.year === row.year);
								if (!ref) {
									ref = new SummaryYearItem();
									ref.year = row.year;
									item.years.push(ref);
								}
								ref.amount = this.initOrAdd(ref.amount, row.amount);
							});

							return acc;
						}, new Array<ReportSummaryItem>());

						// process schedule9/debt. GovType specificity will return null result, so skip as we do not support
						if (result.schedule9 != null) {
							const debtItem = new ReportSummaryItem();
							debtItem.displayYear = result.displayYear;
							debtItem.report = this.eligibleReports.find(x => x.id === 'debtAndLiabilities');
							result.schedule9.forEach(row => {
								let ref = debtItem.years.find(x => x.year === row.year);
								if (!ref) {
									ref = new SummaryYearItem();
									ref.year = row.year;
									debtItem.years.push(ref);
								}
								ref.amount = this.initOrAdd(ref.amount, row.totalEndings);
							});
							items.push(debtItem);
						}

						// Sort the reports so they can be displayed (top-to-bottom, left-to-right, etc)
						return items.sort((a, b) => a.report.sortOrder - b.report.sortOrder);
					})
				);
			})
		);
	}

	private findOrInitSAOAnnualFilingReportSummaryItems(row: FinancialSummary, items: Array<ReportSummaryItem>, displayYear: number): Array<ReportSummaryItem> {
		const applicableReports = this.eligibleReports.filter(report =>
			report.financialsDatasetSource === 'SAOAnnualFiling'
			&& report.fsSectionIds?.includes(row.fsSectionId)
		);
		return this.findOrInitReportSummaryItems(applicableReports, items, displayYear);
	}

	private getTotalsByReportOSPI(specificity: GovernmentSpecificity): Observable<Array<ReportSummaryItem>> {
		const reports = specificity.type === 'government'
			? this.fitApi.getOSPIFinancialReports(`mcag eq '${specificity.id}'`)
			: of(null);
		const displayYear = this.fitApi.getOSPIDisplayYear();
		const longTermLiabilities = this.fitApi.getOSPILongTermLiabilities(`mcag eq '${specificity.id}'`);
		return forkJoin({ reports, displayYear, longTermLiabilities }).pipe(map(join => {

			const items = join.reports.reduce((acc, row) => {
				const applicableReportSummaryItems = this.findOrInitOSPIReportSummaryItems(row, acc, join.displayYear);
				applicableReportSummaryItems.forEach(item => {
					let ref = item.years.find(x => x.year === row.fy);
					if (!ref) {
						ref = new SummaryYearItem();
						ref.year = row.fy;
						item.years.push(ref);
					}
					ref.amount = row.itemType === 'Total' ? this.initOrAdd(ref.amount, row.amount) : ref.amount;
				});

				return acc;
			}, new Array<ReportSummaryItem>());

			// long term liabilities separately. GovType specificity will return null result, so skip as we do not support
			if (join.longTermLiabilities != null) {
				const debtItem = new ReportSummaryItem();
				debtItem.displayYear = join.displayYear;
				debtItem.report = this.eligibleReports.find(x => x.id === 'schoolsLongTermLiabilities');
				// We are only interested in the Ending Outstanding Debt.
				//  Options are columnOrder === 4, or columnName === 'Ending Outstanding Debt'
				const longTermLiabilitiesEnding = join.longTermLiabilities.filter(x =>
					x.columnOrder === 4 &&
					x.reportNo === '013' &&
					x.itemType === 'GrandTotal'
				);
				longTermLiabilitiesEnding.forEach(row => {
					let ref = debtItem.years.find(x => x.year === row.fy);
					if (!ref) {
						ref = new SummaryYearItem();
						ref.year = row.fy;
						debtItem.years.push(ref);
					}

					ref.amount = this.initOrAdd(ref.amount, row.amount);
				});
				items.push(debtItem);
			}

			return items.sort((a, b) => a.report.sortOrder - b.report.sortOrder);
		}));
	}

	private findOrInitOSPIReportSummaryItems(row: FinancialReportRow, items: Array<ReportSummaryItem>, displayYear: number): Array<ReportSummaryItem> {
		const applicableReports = this.eligibleReports.filter(report =>
			report.financialsDatasetSource === 'OSPI'
			&& report.reportNo === row.reportNo
			&& (report.itemCategories === undefined || report.itemCategories.includes(row.itemCategory))
		);
		return this.findOrInitReportSummaryItems(applicableReports, items, displayYear);
	}

	/**
	 * Given a list of Reports, find an existing ReportSummaryItem, or create a new one in the items array.
	 * @param reports
	 * @param items
	 * @param displayYear
	 * @private
	 */
	private findOrInitReportSummaryItems(reports: Array<Report>, items: Array<ReportSummaryItem>, displayYear: number) {
		const applicableItems = new Array<ReportSummaryItem>();

		reports.forEach(report => {
			let ref = items.find(x => x.report.id === report.id);

			// item not found, create and push onto stack
			if (!ref) {
				ref = new ReportSummaryItem();
				ref.displayYear = displayYear;
				ref.report = report;
				items.push(ref);
			}

			applicableItems.push(ref);
		});

		return applicableItems;
	}

	getTotalsByCategory(
		specificity: GovernmentSpecificity,
		categoryType: CategoryType,
		fundCategoryId?: FundCategoryId,
		snapshotId?: SnapshotId
	): Observable<Array<CategorySummaryItem>> {
		return this.fitApi.getDatasetFromSpecificity(specificity).pipe(
			switchMap(datasetSource => {
				switch (datasetSource) {
					case 'SAOAnnualFiling':
						return this.getTotalsByCategoryAnnualFiling(specificity, categoryType, fundCategoryId, snapshotId);
					case 'OSPI':
						return this.getTotalsByCategoryOSPI(specificity, categoryType, fundCategoryId);
					default:
						throw new Error('FinancialSummaryService.getSummaryItems: Cannot get items for DatasetSource '
							+ datasetSource);
				}
			})
		);
	}

	private getTotalsByCategoryAnnualFiling(
		specificity: GovernmentSpecificity,
		categoryType: CategoryType,
		fundCategoryId?: FundCategoryId,
		snapshotId?: SnapshotId
	): Observable<Array<CategorySummaryItem>> {
		return this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				const summaries = specificity.type === 'government'
					? this.fitApi.getFinancialSummariesForMcag(specificity.id, snapshotId)
					: this.fitApi.getFinancialSummariesForGovType(specificity.id, snapshotId);
				const displayYear = specificity.type === 'government'
					? this.filingStatus.getFilingYearForDisplay(specificity.id, snapshotId)
					: of(snapshot.barsYearUsed);

				return forkJoin([displayYear, summaries]).pipe(
					map( ([dy, financialSummaries]) => {
						// only one report type for this whole CategoryType
						const report = this.eligibleReports.find(
							x => x.id === this.categoryTypeToReportMap[categoryType]['SAOAnnualFiling']
						);

						const items = financialSummaries.reduce((acc, row) => {
							// If fundCategory is provided, do not process other fundCategories
							if (fundCategoryId !== undefined && row.fundCategoryId !== fundCategoryId) {
								return acc;
							}

							// if this row does not belong to the report in question, do not process
							if (!report.fsSectionIds?.includes(row.fsSectionId)) {
								return acc;
							}

							const category = this.getCategoryName(row, snapshot);
							// find or create the item
							let item = acc.find(x => x.category === category);
							if (!item) {
								item = new CategorySummaryItem();
								item.report = report; // report that categories came from
								item.displayYear = dy;
								item.category = category;
								// todo this does not support cases where fundCategoryId is not provided
								// get the transformations needed to view this report
								let fieldTransformations = [];
								if (this.isExpenditureObject(row)) {
									fieldTransformations = fieldTransformations.concat(
										this.fieldService.expenditureObjectPresetTransformation
									);
								}
								if (fundCategoryId) {
									fieldTransformations.push(
										this.fieldService.getFundGroupFilterTransformation(fundCategoryId)
									);
								}
								item.fieldTransformations = fieldTransformations;
								acc.push(item);
							}

							// find or create the year reference
							let year = item.years.find(x => x.year === row.year);
							if (!year) {
								year = new SummaryYearItem();
								year.year = row.year;
								item.years.push(year);
							}

							year.amount = this.initOrAdd(year.amount, row.amount);
							return acc;

						}, new Array<CategorySummaryItem>());

						return items;
					})
				);
			})
		);
	}

	private getTotalsByCategoryOSPI(
		specificity: GovernmentSpecificity,
		categoryType: CategoryType,
		fundCategoryId?: FundCategoryId,
	): Observable<Array<CategorySummaryItem>> {
		// Do not support GovType
		if (specificity.type === 'governmentType') {
			return of(null);
		}
		const summaries = this.fitApi.getOSPIFinancialReports(`mcag eq '${specificity.id}'`);
		const displayYear = this.fitApi.getOSPIDisplayYear();
		return forkJoin({ summaries, displayYear}).pipe(map(result => {
			// only one report type for this whole CategoryType
			const report = this.eligibleReports.find(
				x => x.id === this.categoryTypeToReportMap[categoryType]['OSPI']
			);

			return result.summaries.reduce((acc, row) => {
				// If fundCategory is provided, do not process other fundCategories
				if (fundCategoryId !== undefined && row.fundCategoryId !== fundCategoryId) {
					return acc;
				}

				// if this row does not belong to the report in question, do not process
				const reportHasNoItemCategoriesOrRowHasCategory =
					report.itemCategories == null || report.itemCategories?.includes(row.itemCategory);

				// kick out any non-Detail items and any that do not belong to this report
				if (!reportHasNoItemCategoriesOrRowHasCategory || row.itemType !== 'Detail' || row.reportNo !== report.reportNo) {
					return acc;
				}

				// get category name from OSPI detail.reportItems and group by multiYearSortOrder
				const categoryName = this.formatService.getOSPICollectionObject(
					'reportItems', report.reportNo, 'title', 'multiYearSortOrder',
					{ value: row.multiYearSortOrder }
				);

				let item = acc.find(x => x.category === categoryName);
				if (!item) {
					item = new CategorySummaryItem();
					item.report = report; // report that categories came from
					item.displayYear = result.displayYear;
					item.category = categoryName;
					item.sortOrder = row.multiYearSortOrder;
					acc.push(item);
				}

				// find or create the year reference
				let year = item.years.find(x => x.year === row.fy);
				if (!year) {
					year = new SummaryYearItem();
					year.year = row.fy;
					item.years.push(year);
				}

				year.amount = this.initOrAdd(year.amount, row.amount);
				return acc;

			}, new Array<CategorySummaryItem>())
			// sort the categories by their sortOrder
			.sort((a, b) => a.sortOrder - b.sortOrder);
		}));
	}

	/**
	 * todo implement to support EnterpriseServices when we refactor it - not needed until then
	 */
	getTotalsByFundCategory() {
		throw new Error('Not implemented');
	}

	// region deprecated functions

	/**
	 * Sum revenues and expenditures. If no year provided, use snapshot.barsYearUsed.
	 * @param specificity
	 * @param snapshotId
	 * @param year
	 * @param fundCategoryId - filters on this category (i.e. Enterprise or Governmental) never used?
	 * @deprecated
	 */
	getTotalAmountsForYear = (specificity: GovernmentSpecificity, snapshotId?: SnapshotId, year?: number, fundCategoryId?: FundCategoryId): Observable<TotalAmounts> => {
		return this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(
			switchMap(snapshot => {
				const displayYear = year ? of(year) : (specificity.type === 'government' ? this.filingStatus.getFilingYearForDisplay(specificity.id, snapshotId) : of(snapshot.barsYearUsed));

				// call proper endpoint
				const summaries = specificity.type === 'government'
					? this.fitApi.getFinancialSummariesForMcag(specificity.id, snapshot.id)
					: this.fitApi.getFinancialSummariesForGovType(specificity.id, snapshot.id);

				return forkJoin([displayYear, summaries]).pipe(
					map(([y, sums]) => sums
						.filter(x => x.year === y && this.filterOnFundCategory(x, fundCategoryId))
						.reduce((acc, row) => {
							acc.year = row.year;
							this.slotIntoSummary(acc, row);
							return acc;
						}, new TotalAmounts())
					)
				);
			})
		);
	};

	/**
	 * Adds a FinancialSummary endpoint row to a TotalAmounts model (aggregates amounts)
	 * @param model
	 * @param data
	 * @deprecated
	 */
	private slotIntoSummary = (model: TotalAmounts, data: FinancialSummary): void => {
		// Only process revenues and expenditures
		if (![FinancialSummarySection.revenues, FinancialSummarySection.expenditures].includes(data.fsSectionId)) {
			return;
		}
		const mode            = this.toTitleCase(FinancialSummarySection[data.fsSectionId]);
		// init/add to total (governmental and enterprise)
		model[`total${mode}`] = this.initOrAdd(model[`total${mode}`], data.amount);
		// governmental amounts all go in one bucket
		if (data.fundCategoryId === FundCategoryId.governmental) {
			model[`totalGovernmental${mode}`] = this.initOrAdd(model[`totalGovernmental${mode}`], data.amount);
		}
		else if (data.fundCategoryId === FundCategoryId.proprietary) {
			// sum total of all enterprise funds
			model[`totalEnterprise${mode}`] = this.initOrAdd(model[`totalEnterprise${mode}`], data.amount);
			// also sum each individual fund
			let objRef                      = model[`enterprise${mode}`].find(x => x.fund === data.fund);
			if (!objRef) {
				objRef = new EnterpriseTotalAmount();
				model[`enterprise${mode}`].push(objRef);
			}
			objRef.amount = this.initOrAdd(objRef.amount, data.amount);
			objRef.fund   = data.fund;
			objRef.name   = data.latestFundName;
		}
	};

	/**
	 * Add addition to base, or initialize with addition.
	 * @param base
	 * @param addition
	 */
	initOrAdd = (base: number, addition: number) =>
		base == null ? addition : base + addition;

	/**
	 * @deprecated
	 * @param value
	 */
	private toTitleCase = (value: string) =>
		value == null ? null : value.charAt(0).toUpperCase() + value.slice(1);

	/**
	 * Get totals for govType or mcag.
	 * @param specificity
	 * @param snapshotId
	 * @param fundCategoryId
	 * @deprecated Use getTotalsByReport for generic reports (not SAOAnnualFiling specific)
	 */
	getTotalAmounts = (specificity: GovernmentSpecificity, fundCategoryId?: FundCategoryId, snapshotId?: SnapshotId): Observable<Array<TotalAmounts>> => {
		const request = specificity.type === 'government'
			? this.fitApi.getFinancialSummariesForMcag(specificity.id, snapshotId)
			: this.fitApi.getFinancialSummariesForGovType(specificity.id, snapshotId);
		return request.pipe(
			map(result => result
				.filter(row => this.filterOnFundCategory(row, fundCategoryId))
				.reduce((acc, row) => {
					// if year already exists in acc, use that
					// if not, create
					let objInAcc = acc.find(x => x.year === row.year);
					if (!objInAcc) {
						objInAcc      = new TotalAmounts();
						objInAcc.year = row.year;
						acc.push(objInAcc);
					}
					this.slotIntoSummary(objInAcc, row);
					return acc;
				}, new Array<TotalAmounts>())
				.sort((a, b) => a.year - b.year)
			)
		);
	};

	/**
	 * Gets amounts grouped by category label. ReportType and FundCategory can be passed to narrow results. If no
	 * specific year provided, will use barsYearUsed from snapshot.
	 * @param specificity
	 * @param reportType
	 * @param fundCategoryId
	 * @param snapshotId
	 * @param year
	 * @deprecated use getTotalsByCategory
	 */
	getAmountsByCategoryForYear = (specificity: GovernmentSpecificity, reportType?: ReportId, fundCategoryId?: FundCategoryId, snapshotId?: SnapshotId, year?: number): Observable<Array<AmountsByCategory>> =>
		this.fitApi.getAnnualFilingSnapshot(snapshotId).pipe(switchMap(snapshot => {
				const summaries = specificity.type === 'government'
					? this.fitApi.getFinancialSummariesForMcag(specificity.id, snapshotId)
					: this.fitApi.getFinancialSummariesForGovType(specificity.id, snapshotId);

				const displayYear = year ? of(year) : (specificity.type === 'government' ? this.filingStatus.getFilingYearForDisplay(specificity.id, snapshotId) : of(snapshot.barsYearUsed));

				return forkJoin([displayYear, summaries]).pipe(
					map( ([y, financialSummaries]) => {
						financialSummaries = financialSummaries.filter(row =>
							row.year === y
							&& this.filterOnReportType(row, reportType)
							&& this.filterOnFundCategory(row, fundCategoryId)
						);
						return this.groupByCategoryName(financialSummaries, snapshot);
					})
				);
			})
		);

	groupByCategoryName = (financialSummaries: Array<FinancialSummary>, snapshot: any): Array<AmountsByCategory> =>
		financialSummaries.reduce((accumulator, row) => {
			const label  = this.getCategoryName(row, snapshot);
			let objInAcc = accumulator.find(x => x.label === label);
			if (!objInAcc) {
				objInAcc = new AmountsByCategory();
				accumulator.push(objInAcc);
			}
			objInAcc.accountSegment = this.isExpenditureObject(row) ? 'expenditureObject' : 'basicAccount';
			objInAcc.label          = label;
			// objInAcc.fsSectionId    = row.fsSectionId;
			objInAcc.amount         = this.initOrAdd(objInAcc.amount, row.amount);
			return accumulator;
		}, new Array<AmountsByCategory>())
	;

	private getCategoryName = (financialSummary: FinancialSummary, snapshot: any): string => {
		// Lookup the appropriate snapshot crosswalk record to get the name
		const record = this.isExpenditureObject(financialSummary)
			? snapshot.detail.expenditureObjects.find(x => x.id === financialSummary.expenditureObjectId)
			: snapshot.detail.accountDescriptors.find(x => x.id === financialSummary.barsAccountId);

		return record?.name;
	};

	/**
	 * We are expenditureObject level if the fsSectionId === 30 and the expenditureObjectId is not null.
	 * @param financialSummary
	 */
	private isExpenditureObject = (financialSummary: FinancialSummary) =>
		financialSummary.fsSectionId === FinancialSummarySection.expenditures
		&& financialSummary.expenditureObjectId != null;

	private filterOnReportType = (financialSummary: FinancialSummary, reportType: ReportId): boolean =>
		reportType == null || financialSummary.fsSectionId === FinancialSummarySection[reportType];

	private filterOnFundCategory = (financialSummary: FinancialSummary, fundCategoryId: FundCategoryId): boolean =>
		fundCategoryId == null || financialSummary.fundCategoryId === fundCategoryId;

	// endregion
}
