<a href="https://www.sao.wa.gov/">
	<img src="assets/images/sao_applications_logo.svg" class="applications-logo" alt="Office of the Washington State Auditor" />
</a>
<h1 class="fit-logo">
	<img src="assets/images/fit_logo_white.svg" alt="Financial Intelligence Tool Logo" (click)="launchNewHypeTab()" />
</h1>
<ul class="actions">
	<li class="action">
		<app-user-dropdown></app-user-dropdown>
	</li>
</ul>

<!-- <div class="header" [class.bg-test]="isTest">
	<div class="header__banner">
		<div class="header__banner__logo" (click)="launchNewHypeTab()">
			<img src="assets/images/fit_isolated.svg" alt="FIT Logo" />

			<div class="header__banner__separator"></div>

			<div class="lgfrs-title" href="#home">
				Financial<br/>
				Intelligence<br/>
				Tool
			</div>
		</div>

		 <div class="header__title-breadcrumbs">
			<app-breadcrumb></app-breadcrumb>
		</div>
	</div>
</div> -->
