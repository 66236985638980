import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DxPopupComponent} from 'devextreme-angular';
import {faDollarSign} from '@fortawesome/free-solid-svg-icons';
import {Tab} from 'app/shared/models/tab';
import {LoggerService} from 'app/shared/services/logger.service';
import {UnitsService} from 'app/shared/services/units.service';
import {PivotGridSettings} from 'app/shared/models/pivot-grid-settings';
import {FieldService} from 'app/shared/services/field.service';
import {PivotGridService} from 'app/shared/services/pivot-grid.service';

@Component({
	selector:    'app-display-options',
	templateUrl: './display-options.component.html',
	styleUrls:   ['./display-options.component.scss']
})
export class DisplayOptionsComponent implements OnInit {
	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	@Input() tab: Tab;
	@Input() reportId: string;
	@Input() lastUpdate: any;
	@Output() submitted = new EventEmitter<PivotGridSettings>();

	icon                       = faDollarSign;
	availableMetrics: any;
	transientSelections        = new PivotGridSettings();
	isDebtCapitalToggleVisible = false;

	constructor(
		private logger: LoggerService,
		private units: UnitsService,
		private fieldService: FieldService,
		private pivotGridService: PivotGridService
	) {
	}

	ngOnInit() {
		this.logger.log(this);
	}

	// Refresh data sources every time the popup is shown
	onShowing = (event) => {
		this.refreshMetricsFromTab();
		this.refreshAvailableMetrics();
		this.refreshIsDebtCapitalToggleVisible();
	};

	refreshMetricsFromTab() {
		// Create a copy of only the metric-related pivotGridSettings for manipulation
		// This is so we do not affect these properties before submitting
		Object.assign(
			this.transientSelections,
			{showDebtServiceWithExpenditures: false},
			this.tab.pivotGridSettings
		);
	}

	refreshAvailableMetrics = () => {
		this.availableMetrics = this.units.getAvailable(this.tab);
	};

	refreshIsDebtCapitalToggleVisible = () => {
		this.pivotGridService.getDataSource(this.tab).subscribe((dataSource) => {
			this.isDebtCapitalToggleVisible =
				// For reports that have expenditure object as field
				this.fieldService.getFieldById(dataSource, 'expenditureObjectFilter') !== null
				// Except that Govt Function report must also have Expenditure selected as category
				&& !(this.tab && this.tab.report.id === 'schedule01CategoryTotals' && ![30, 35].includes(this.tab.category.id));
		});
	};

	handleSubmit() {
		this.submitted.emit(this.transientSelections);
		this.popup.instance.hide();
	}

}
