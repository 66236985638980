<div class="dx-fieldset">
	<div class="dx-field">
		<div class="dx-field-label">Select an accounting basis of...</div>
		<div class="dx-field-value">
			<wasao-filing-basis-picker
				placeholder="Choose an accounting basis (required)..."
				(changed)="filingBasisChanged($event)"
			></wasao-filing-basis-picker>
		</div>
	</div>
	<div class="dx-field">
		<div class="dx-field-label">...for report...</div>
		<div class="dx-field-value">
			<dx-select-box
				[dataSource]="indicatorReportTypes"
				placeholder="Choose a report (required)..."
				displayExpr="name"
				(onValueChanged)="indicatorReportTypeChanged($event.value)"
			></dx-select-box>
		</div>
	</div>
	<div class="dx-field">
		<div class="dx-field-label">...for selected government types...</div>
		<div class="dx-field-value">
			<app-government-types
				[selections]="govTypes"
				[disabled]="!meetsGovTypeRequirements"
				[disabledGovTypes]="disabledGovTypes"
				(changed)="govTypesChanged($event)"
				placeholder="Choose from a list of government types (required)..."
			></app-government-types>
		</div>
	</div>
</div>

<div class="field-buttons">
	<div class="field-buttons__left">
		<dx-button
			type="normal"
			text="Back"
			(click)="handleBack($event)"
		></dx-button>
	</div>
	<div class="field-buttons__right">
		<dx-button
			type="default"
			[text]="submitLabel"
			[disabled]="!meetsSubmitRequirements"
			(click)="handleSubmit()"
		></dx-button>
	</div>
</div>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
