import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LoggerService} from '../../../shared/services/logger.service';
import { HintInformation, HintInformationType } from 'app/shared/models/hint-information';
import { GovernmentInfoService } from 'app/shared/services/government-info.service';
import {ExternalCommonServiceApiService} from '../../api/external-common-service-api/external-common-service-api.service';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {Entities} from '../../api/external-common-service-api/models/entities';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {UserService} from '../../../shared/services/user.service';
import {GovernmentType} from '../../api/fit-api/models/government-type';
import {forkJoin, Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {convertSnapshotIdString, SnapshotId} from '../../api/fit-api/models/snapshot-like';

class ChildrenThatHaveData {
	badges: boolean = null;
	genGovInfo: boolean = null;
	financesAtAGlance: boolean = null;
	indicators: boolean = null; // TODO enable this when refactoring IndicatorGroups
	governmentalServices: boolean = null;
	enterpriseServices: boolean = null;
}

@Component({
	selector: 'wasao-government-tear-sheet',
	templateUrl: './government-tear-sheet.component.html',
	styleUrls: ['./government-tear-sheet.component.scss']
})
export class GovernmentTearSheetComponent implements OnInit, OnChanges {

	@Input() mcag: string;
	@Input() snapshotId: SnapshotId;

	private destroy = new Subject<void>();

	canShowFinancials = false;
	canShowIndicators = false;
	govInfoLive: Entities;
	childrenThatHaveData = new ChildrenThatHaveData();
	isReadyToPrint = false;
	today = new Date();
	profileUrl: string;

	displayYear: number;
	currentGovernmentType: GovernmentType;
	showBeta: boolean;
	userHasGlobalAccess = false;
	userHasSchoolAccess = false;

	// TODO remove when refactoring indicators out
	public indicatorGroups = [];

	public hintInfo = HintInformation;
	public hintInfoType = HintInformationType.Plain;

	constructor(
		private logger: LoggerService,
		private commonData: ExternalCommonServiceApiService,
		private govInfoService: GovernmentInfoService,
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private route: ActivatedRoute,
		private userService: UserService
	) {
		this.userService.user.pipe(takeUntil(this.destroy)).subscribe(user => {
			this.showBeta = user.hasAnyFitAccessRole;
			this.userHasGlobalAccess = user.hasGlobalAccess();
			this.userHasSchoolAccess = user.hasAccessToAnySchool();
			this.setCanShowFinancials();
		});
	}

	ngOnInit() {
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.mcag = params.get('mcag');
			this.snapshotId = convertSnapshotIdString(params.get('snapshotId'));
			this.updateData();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.mcag?.currentValue || changes.snapshotId?.currentValue) {
			this.updateData();
		}
	}

	updateData() {
		this.profileUrl = `${location.origin}${location.pathname.replace('tear-sheet', 'profile')}`;
		const governmentInfo = this.commonData.getEntityDetails(this.mcag);
			// .subscribe(government => {
		// 	this.infoLive = government;
		// });

		const governmentTypes = this.fitApi.getGovernmentTypes;

		forkJoin({ governmentInfo, governmentTypes }).pipe(takeUntil(this.destroy)).subscribe(result => {
			this.govInfoLive = result.governmentInfo;
			this.currentGovernmentType = result.governmentTypes.find(x => x.code === this.govInfoLive.GovTypeCode);

			this.setCanShowFinancials();
			this.setCanShowIndicators(this.mcag);
			this.removeNonApplicableChildren();

			const getYearForDisplay = this.currentGovernmentType.financialsDatasetSource === 'OSPI'
				? this.fitApi.getOSPIDisplayYear()
				: this.filingStatus.getFilingYearForDisplay(this.mcag, this.snapshotId);


			// todo refactor/encapsulate into IndicatorGroups
			getYearForDisplay.pipe(takeUntil(this.destroy)).subscribe(displayYear => {
				this.displayYear = displayYear;
				const years = [displayYear - 4, displayYear];
				if (this.currentGovernmentType.financialsDatasetSource === 'OSPI') {
					this.govInfoService.getSchoolsIndicatorGroups(this.mcag, years)
						.pipe(finalize(() => this.childHasData(true, 'indicators')))
						.subscribe(x => {
							this.indicatorGroups = x;
							// filters out indicator groups that are indeterminate
							this.indicatorGroups = this.indicatorGroups.filter(outlook => !(outlook.outlookInfo.outlook === 'Indeterminate'));
						} );
				} else {
					this.govInfoService.getAnnualFilingIndicatorGroups(this.snapshotId, this.mcag, years)
						.pipe(finalize(() => this.childHasData(true, 'indicators')))
						.subscribe(x => {
							this.indicatorGroups = x;
							// filters out indicator groups that are indeterminate
							this.indicatorGroups = this.indicatorGroups.filter(outlook => !(outlook.outlookInfo.outlook === 'Indeterminate'));
						} );
				}

			});
		});
	}

	private removeNonApplicableChildren() {
		// Remove childrenThatHaveData keys related to financial data if govType is not capable of having data
		// This allows the loading message to finish
		if (!this.canShowFinancials) {
			delete this.childrenThatHaveData.governmentalServices;
			delete this.childrenThatHaveData.enterpriseServices;
			delete this.childrenThatHaveData.financesAtAGlance;
		}
	}

	/**
	 * Evaluate and set canShowFinancials flag based on defined criteria
	 */
	setCanShowFinancials(): void {
		// PUBLIC ACCESS
		// this.canShowFinancials = this.currentGovernmentType.financialsDatasetSource !== null;


		// NON-PUBLIC ACCESS
		// always show financial sections if the data set is Annual Filing based, OR
		this.canShowFinancials = this.currentGovernmentType?.financialsDatasetSource === 'SAOAnnualFiling'
			// dataset is OSPI *only* if the user has global access or schools access
			|| (this.currentGovernmentType?.financialsDatasetSource === 'OSPI' && (this.userHasGlobalAccess || this.userHasSchoolAccess ));
	}

	/**
	 * Evaluate and set canShowIndicators flag based on defined criteria
	 */
	setCanShowIndicators(mcag: string): void {
		this.userService.user.pipe(takeUntil(this.destroy)).subscribe(user => {
			if (this.currentGovernmentType.financialsDatasetSource === 'SAOAnnualFiling') {
				this.canShowIndicators = this.currentGovernmentType.canHaveIndicators;
			} else if (this.currentGovernmentType.financialsDatasetSource === 'OSPI') {
				this.canShowIndicators = this.currentGovernmentType.canHaveIndicators && (user.hasAccessToMcag(mcag));
			} else {
				this.canShowIndicators = false;
			}
		});
	}

	handlePrint() {
		window.print();
	}

	childHasData = (event: boolean, id: keyof ChildrenThatHaveData): void => {
		// if all keys are defined, then set isReadyToPrint to true
		this.childrenThatHaveData[id] = event;
		this.logger.log(`${this.constructor.name} ChildrenThatHaveData`, this.childrenThatHaveData);
		// iterate and check for !null
		this.isReadyToPrint = Object.keys(this.childrenThatHaveData).every(x =>
			this.childrenThatHaveData[x] !== null
		);
	}

}
