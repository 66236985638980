export class TransitionEvent {
	name = 'Undefined Event';
	action: any;
	actionValue: any;
	evaluations: Array<any>;

	events = [
		{
			name: 'Change Report', action: 'ChangeReport', changes: [
				new Change('Comparison')
			]
		},
		{
			name: 'Change Years', action: 'ChangeYears', changes: [
				new Change('Track', false),
				new Change('Comparison', false),
				new Change('RemoveBaseline')
			]
		},
		{
			name: 'Remove Baseline', action: 'RemoveBaseline', changes: [
				new Change('Track', false),
				new Change('Comparison', false)
			]
		},
		{
			name: 'Set Baseline', action: 'SetBaseline', changes: [
				new Change('Track', false),
				new Change('Years'),
				new Change('Comparison', false)
			]
		},
		{ name: 'Remove Government', action: 'RemoveGovernment', changes: [] },
		{
			name: 'Show summary analysis of this government category', action: 'NavigateToTrackD', changes: [
				new Change('Track'),
				new Change('Report')
			]
		},
		{
			name: 'Change Snapshot', action: 'ChangeSnapshot', changes: [
				new Change('DataSource')
			]
		},
		{
			name: 'Change Account Category', action: 'ChangeAccountCategory', changes: [
				new Change('DataSource', false)
			]
		}
	];

	constructor(name: string, actionValue?: any) {
		const event = this.events.find(e => e.name === name);
		if (!event) {
			throw new Error(`TransitionEvent: No event named ${name}`);
		}

		Object.assign(this, event);
		this.actionValue = actionValue;
	}
}

export class Change {
	constructor(
		public name: string,
		public promptsUser: boolean = true
	) { }
}
