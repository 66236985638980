import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GovernmentBodyOriginsComponent} from './government-body-origins.component';
import {DxButtonModule, DxPieChartModule} from 'devextreme-angular';
import {LoadingModule} from '../../ui/loading/loading.module';


@NgModule({
	declarations: [
		GovernmentBodyOriginsComponent
	],
	imports: [
		CommonModule,
		DxPieChartModule,
		DxButtonModule,
		LoadingModule,
	],
	exports: [
		GovernmentBodyOriginsComponent
	]
})
export class GovernmentBodyOriginsModule {
}
