export class Schedule9TotalAmounts {
	/**
	 * The year summarized.
	 */
	year?: number;

	/**
	 * Total endings
	 */
	totalEndings?: number;
}
