import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {maxComparisonPeers, minComparisonPeers, PeerSetsService} from '../../shared/services/peer-sets.service';
import {faBuilding, faLock, faTimes} from '@fortawesome/free-solid-svg-icons';
import {PeerSet} from '../../shared/models/peer-set';
import {SnapshotService} from '../../shared/services/snapshot.service';
import DataSource from 'devextreme/data/data_source';
import {DataSourceService} from '../../shared/services/data-source.service';
import {FilterBuilderService} from '../../shared/services/filter-builder.service';
import {Peer} from '../../shared/models/peer';
import {Subscription} from 'rxjs';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {LoggerService} from '../../shared/services/logger.service';
import {DxDataGridComponent} from 'devextreme-angular';
import {GovernmentType} from '../../modules/api/fit-api/models/government-type';

@Component({
	selector:    'app-peer-set-selector',
	templateUrl: './peer-set-selector.component.html',
	styleUrls:   ['./peer-set-selector.component.scss']
})
export class PeerSetSelectorComponent implements OnInit, OnChanges, OnDestroy {

	private _peers: Array<Peer>;
	@Input() get peers(): Array<Peer> {
		return this._peers;
	}
	set peers(peers: Array<Peer>) {
		this._peers = peers;
		// update DataGrid selections anytime peers change
		this.selectedRowKeys = peers.map(x => x.mcag);
		// and disable canChangeGovType if there is a selection
		this.canChangeGovType = peers?.length < 1;
		this.evaluateMaxPeers();
		if (peers?.length > 0) {
			// Actually set the dropdown (also filters the list)
			this.filter.govTypeCode = peers[0].govTypeCode;
			this.applyFilters();
		}
	}

	@Output() peersChange = new EventEmitter<Array<Peer>>();

	// datagrid selection
	public selectedRowKeys: string[];

	// datasource for filter
	governmentTypes: Array<GovernmentType>;

	// evaluated in template
	public maxPeersInPeerSet   = maxComparisonPeers;
	public locationSelections: Array<any>;
	public snapshot: any;
	hasMaxPeers = false;

	// DataSource
	public store: any;
	public filter = {
		name: '',
		metricYear: null as number,
		govTypeCode: null as string,
		locationCodes: [] as Array<number>
	};
	canChangeGovType = true;

	private snapshotSubscription: Subscription;
	public isLoading = false;

	public icons = {
		close:    faTimes,
		building: faBuilding,
		lock:     faLock,
	};

	constructor(
		private peerSetsService: PeerSetsService,
		private snapshotService: SnapshotService,
		private dataSource: DataSourceService,
		private filterBuilder: FilterBuilderService,
		private api: FitApiService,
		private logger: LoggerService
	) {
		// Always use latest snapshot
		this.snapshotSubscription = this.snapshotService.getSnapshot().subscribe(value => {
			this.snapshot  = value;
			if (this.snapshot != null) {
				this.filter.metricYear = this.snapshot.barsYearUsed;
			}
		});

		this.store = new DataSource({
			store: this.dataSource.getStore(
				this.snapshot.id,
				'GovernmentMetrics',
				{ $expand: 'localGovernment' }
			),
			filter: this.getFilter()
		});

		// exclude schools data
		this.api.getGovernmentTypes.subscribe(result => {
			this.governmentTypes = result.filter(x => x.financialsDatasetSource === 'SAOAnnualFiling');
		});
	}

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) { }

	ngOnDestroy() {
		this.snapshotSubscription.unsubscribe();
		this.store.dispose();
	}

	getFilter(): Array<any> {
		if (!this.snapshot) { // components may trigger eval before snapshot is loaded
			return;
		}

		const filterArray = [];

		if (this.filter.govTypeCode) {
			filterArray.push(['localGovernment/govTypeCode', '=', this.filter.govTypeCode]);
		}
		if (this.filter.name) {
			filterArray.push(['localGovernment/lookupNameWithDba', 'contains', this.filter.name]);
		}
		if (this.locationSelections?.length > 0) {
			filterArray.push([`localGovernment/countyCodes/any(c: c in (${this.locationSelections.join(',')}))`, '=', true]);
		}
		filterArray.push(['localGovernment/status', '=', 'Active']);
		filterArray.push(['year', '=', this.filter.metricYear]);
		// exclude schools data
		filterArray.push(['localGovernment/financialsDatasetSource', '=', 'SAOAnnualFiling']);

		return filterArray;
	}

	applyFilters(): void {
		this.isLoading = true;
		const filterArray = this.getFilter();

		if (filterArray.length === 0) {
			return;
		}

		this.store.filter(filterArray);
		this.store.load().then(
			() => this.isLoading = false,
			() => this.isLoading = false
		);
	}

	/**
	 * Add selected Peers to PeerSet list. DataGrid interaction happens here.
	 *
	 * @param event
	 */
	evaluateSelectedPeers(event): void {
		const peers = event.selectedRowsData.map(p => ({
			entityNameWithDba: p.localGovernment.entityNameWithDba,
			entityName: p.localGovernment.entityName,
			mcag: p.localGovernment.mcag,
			govTypeCode: p.localGovernment.govTypeCode,
			prime: this.peers.find(x => x.mcag === p.mcag)?.prime ?? false
		}));
		this.evaluateMaxPeers();
		this.peersChange.emit(peers);
	}

	evaluateMaxPeers() {
		this.hasMaxPeers = this.peers.filter(x => x.prime !== true).length >= maxComparisonPeers;
	}

	/**
	 * Update locations
	 *
	 * @param locationCodes
	 */
	updateFilterLocations(locationCodes: Array<number>) {
		this.locationSelections = locationCodes;
		this.applyFilters();
	}

	/**
	 * Update filtered name
	 *
	 * @param name
	 */
	updateFilterName(name: string): void {
		this.filter.name = name;
		this.applyFilters();
	}

	updateFilterGovType(event): void {
		this.applyFilters();
	}

}
