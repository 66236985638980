import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'compactCategoryName'
})
export class CompactCategoryNamePipe implements PipeTransform {

	private shortNameReplacements = [
		[' and ', ' & '],
		['Miscellaneous', 'Misc.'],
		['Revenues', ''],
		['Government', 'Gov\'t'],
		['Other Decreases', 'Other'],
		['Economic', 'Econ.'],
		['Environment', 'Environ.']
	];

	private wordWrapReplacements = [
		['Goods & Services', '\nGoods & Services'],
		['Intergovernmental', 'Inter-\ngovernmental'],
		['Licenses & Permits', 'Licenses\n& Permits'],
		['Fines & Penalties', 'Fines &\nPenalties'],
		['Culture & ', 'Culture &\n'],
		['Natural & ', 'Natural &\n'],
		['Social Services', 'Social\n Services'],
		['Depreciation & ', 'Depreciation &\n'],
		['Debt Service ', 'Debt Service\n'],
		['Interfund Pmts ', 'Interfund Pmts\n']
	];

	transform(value: string, applyWordWrap?: boolean): string {

		value = this.replace(value, this.shortNameReplacements);

		if (applyWordWrap) {
			value = this.replace(value, this.wordWrapReplacements);
		}

		return value;
	}

	private replace = (input: string, arr: Array<Array<string>>) => {
		arr.forEach(replacement =>
			input = input.split(replacement[0]).join(replacement[1])
		);
		return input;
	}

}
