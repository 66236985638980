import {DatasetSource} from './datasets/dataset-source';
import {FundType} from '../../../services/fund-service/models/fund-type';
import {FundCategory} from '../../../services/fund-service/models/fund-category';
import {Live, LiveWithDetail} from './live';
import {Snapshot, SnapshotWithDetail} from './snapshot';
import {County} from './snapshots/county';
import {AccountDescriptor} from './snapshots/account-descriptor';
import {AccountingBasis} from '../../../ui/accounting-basis-picker/accounting-basis';
import {FilingBasis} from './snapshots/filing-basis';
import {DebtGroup} from './snapshots/debt-group';
import {ReferenceLookup} from './snapshots/reference-lookup';
import {BonanzaSchedule9Type} from './snapshots/bonanza-schedule-9-type';
import {DebtCategoryItem} from './snapshots/debt-category-item';
import {LocalGovernmentReference} from './snapshots/local-government-reference';
import {IndicatorName} from './snapshots/indicator-name';
import {OperatingResultsSection} from './snapshots/operating-results-section';
import {OperatingResultsSubSection} from './snapshots/operating-results-sub-section';
import {GovernmentType} from './government-type';

export type SnapshotId = 'live' | number;

/**
 * Used to process url parameters, which are always strings.
 * @param value
 */
export function convertSnapshotIdString(value?: string): SnapshotId {
	if (value === 'live') {
		return value;
	} else {
		const id = parseInt(value, 10);
		return isNaN(id) ? null : id;
	}
}

// conditional types (input determines return type)
// typescript still hasn't implemented control flow analysis, so this can't handle null/undefined
//  https://stackoverflow.com/questions/64209542/typescript-conditional-is-null
export type SnapshotLikeType<T extends SnapshotId> = T extends 'live' ? Live : Snapshot;
export type SnapshotLikeDetailType<T extends SnapshotId> = T extends 'live' ? LiveWithDetail : SnapshotWithDetail;

/**
 * Represents the base for Snapshot and Live. Once the Dataset refactoring is done, there will likely also be a
 *  superclass to represent OSPI with a few less properties, or maybe this one goes away completely.
 */
export abstract class SnapshotLike {
	id: SnapshotId;
	name: string;
	description: string;
	comment: string;
	barsYearUsed: number;
	dateCreated: Date;
	source: DatasetSource;
}

/**
 * Snapshot and Live detail models.
 */
export abstract class SnapshotLikeDetail {
	barsYear: number;
	includedYears: Array<number>;
	counties: Array<County>;
	accountDescriptors: Array<AccountDescriptor>;
	accountingBases: Array<AccountingBasis>;
	filingBases: Array<FilingBasis>;
	financialSummarySections: Array<ReferenceLookup<number>>;
	expenditureObjects: Array<ReferenceLookup<number>>;
	debtGroups: Array<DebtGroup>;
	bonanzaSchedule9Categories: Array<ReferenceLookup<number>>;
	bonanzaSchedule9Types: Array<BonanzaSchedule9Type>;
	debtCategoryItems: Array<DebtCategoryItem>;
	fundTypes: Array<FundType>;
	fundCategories: Array<FundCategory>;
	governmentTypes: Array<GovernmentType>;
	governments: Array<LocalGovernmentReference>;
	indicatorNames: Array<IndicatorName>;
	operatingResultsTopLevels: Array<ReferenceLookup<string>>;
	operatingResultsSections: Array<OperatingResultsSection>;
	operatingResultsSubSections: Array<OperatingResultsSubSection>;
}
