import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GovernmentBodyTypeComponent} from './government-body-type.component';
import {DxButtonModule, DxPieChartModule} from 'devextreme-angular';
import {LoadingModule} from '../../ui/loading/loading.module';

@NgModule({
	declarations: [GovernmentBodyTypeComponent],
	imports:      [
		CommonModule,
		DxPieChartModule,
		DxButtonModule,
		LoadingModule,
	],
	exports:      [
		GovernmentBodyTypeComponent
	]
})
export class GovernmentBodyTypeModule {
}
