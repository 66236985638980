import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { FieldService } from './field.service';

@Injectable({
	providedIn: 'root'
})
export class GroupingEditorService {

	constructor(
		private log: LoggerService,
		private field: FieldService
	) { }

	generate(fields) {
		const self = this;
		// Filter out any fields that do not have groupingConfigs or are part of an alternate, but not active
		const allowedFields = fields.filter(function (v) {
			const isInactiveAlternate = v.groupingConfig
				&& v.groupingConfig.alternate
				&& !v.groupingConfig.alternate.active;

			return v.groupingConfig
				&& v.area !== 'filter'
				&& !isInactiveAlternate;
		});

		const groupedFields = allowedFields.reduce(function (acc, field) {
			acc[self.getAreaKey(field)] = acc[self.getAreaKey(field)] || [];
			acc[self.getAreaKey(field)].push(field);
			return acc;
		}, { available: [], columns: [], rows: [] });

		for (const key in groupedFields) {
			if (!groupedFields.hasOwnProperty(key)) {
				continue;
			}

			groupedFields[key].sort(function (a, b) {
				return a.areaIndex - b.areaIndex;
			});
		}

		this.log.info('GroupingEditorService.generate', groupedFields);

		return groupedFields;
	}

	process(source, updatedFields) {
		// $log.debug('GroupingEditorService.process before', source.fields());
		// We used to create a copy...
		const result = source;
		// shift fields by number of fields in annotations
		const annotationsFieldsOffset = this.field.getAnnotationsFields().length;

		// Remove areas/indexes for anything in the available list
		updatedFields.available.forEach(function (v) {
			const field = result.field(v.name);
			delete field.area;
			delete field.areaIndex;
		});

		// Set appropriate indexes for columns and rows
		updatedFields.rows.forEach(function (v, i) {
			result.field(v.name, { area: 'row', areaIndex: i + annotationsFieldsOffset });
		});
		updatedFields.columns.forEach(function (v, i) {
			result.field(v.name, { area: 'column', areaIndex: i + annotationsFieldsOffset });
		});

		// $log.debug('GroupingEditorService.process after', result.fields());
		return result;
	}

	getAreaKey(field) {
		if (!field.area) {
			return 'available';
		}
		if (field.area === 'column') {
			return 'columns';
		}
		if (field.area === 'row') {
			return 'rows';
		}

		throw new Error('GroupingEditorService.getAreaKey: Invalid area definition. Field: ' + field.id + ', area ' + field.area);
	}

	isAllowedInArea(area, field) {
		let result = false;

		if (field) {
			const keys = Object.keys(field.groupingConfig);
			// Look for key in groupingConfig of column or row and match against areas
			// row(+s) === rows, column(+s) === columns
			result = keys.findIndex(function (v) {
				return v + 's' === area;
			}) > -1;

			// If area is available, check to make sure we aren't required
			if (area === 'available' && !field.groupingConfig.required) {
				result = true;
			}
		}

		return result;
	}

	allowedAreas(field) {
		const result = [];
		if (field && field.groupingConfig) {
			if (field.groupingConfig.required) {
				result.push('available');
			}
			if (field.groupingConfig.column) {
				result.push('columns');
			}
			if (field.groupingConfig.row) {
				result.push('rows');
			}
		}

		return result;
	}

	/**
	 * Checks hierarchy constraints using Field.groupingConfig.mustBeAfter and mustBeBefore.
	 * @param {Array} targetArray The array being dropped into.
	 * @param {Number} targetIndex The index the field is being dropped into.
	 * @param {Field} field The field being dropped.
	 * @returns {boolean}
	 */
	isAllowedHierarchy(targetArray, targetIndex, field) {
		// No constraints for field
		if (!field.groupingConfig.mustBeBefore && !field.groupingConfig.mustBeAfter) {
			return true;
		}

		// Create a copy before operating on targetArray to prevent side-effects.
		const shadowArray = targetArray.slice();
		// Since the drop event is still in progress, the targetArray has not been
		// altered. Slice the current field out to prevent evaluation of its own constraints.
		const currentFieldIndex = targetArray.findIndex(function (v) {
			return v.id === field.id;
		});
		if (currentFieldIndex) {
			shadowArray.splice(currentFieldIndex, 1);
		}

		// Split into two lists to evaluate respective constraints
		const before = shadowArray.slice(0, targetIndex);
		const after = shadowArray.slice(targetIndex, targetArray.length);

		// $log.debug('GroupingEditorService.isAllowedHierarchy', before, after);

		return this.isFieldValidPositionInHierarchy(field, before, 'Before')
			&& this.isFieldValidPositionInHierarchy(field, after, 'After');
	}

	isFieldValidPositionInHierarchy(field, array, direction) {
		// $log.debug('GroupingEditorService.isFieldValidPositionInHierarchy', field, array, direction);
		// return field's mustBe{direction} does not contain ids in array
		const property = 'mustBe' + direction;
		return !(field.groupingConfig[property]
			&& field.groupingConfig[property].some(function (f) {
				return array.some(function (a) {
					return a.id === f;
				});
			}));
	}

	isFixed(field) {
		const areas = ['row', 'column'];
		return field.groupingConfig
			&& areas.some(function (v) {
				return field.groupingConfig[v]
					&& field.groupingConfig[v].fixed;
			});
	}

	isNextFieldInHierarchy(arr, index) {
		// No more elements to process
		if (arr.length <= index + 1) {
			return false;
		}

		const current = arr[index];
		const next = arr[index + 1];

		// $log.debug('GroupingEditorService.isNextFieldInHierarchy', current, next);

		// Check next's mustBeAfter to see if current's id is found there
		return next
			&& next.groupingConfig
			&& Array.isArray(next.groupingConfig.mustBeAfter)
			&& next.groupingConfig.mustBeAfter.findIndex(function (v) {
				return v === current.id;
			}) > -1;
	}
}
