import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnnualFilingStandaloneFilingStatisticsComponent} from './annual-filing-standalone-filing-statistics.component';
import {
	AnnualFilingCurrentProgressModule
} from '../annual-filing-current-progress/annual-filing-current-progress.module';
import {AnnualFilingStatusTrendModule} from '../annual-filing-status-trend/annual-filing-status-trend.module';


@NgModule({
	declarations: [
		AnnualFilingStandaloneFilingStatisticsComponent
	],
	imports: [
		CommonModule,
		AnnualFilingCurrentProgressModule,
		AnnualFilingStatusTrendModule,
	],
	exports: [
		AnnualFilingStandaloneFilingStatisticsComponent
	]
})
export class AnnualFilingStandaloneFilingStatisticsModule {
}
