<ng-container>
	<div class="question-container">
		<p>Are you a local government and have a question about filing?</p>
		<p>Contact <a href="mailto:LGCSfeedback@sao.wa.gov">LGCSfeedback@sao.wa.gov</a></p>
	</div>
	<div class="footer-container">
		<div class="footer-item">
			To view the fully interactive version of this infographic, visit <a href="https://portal.sao.wa.gov/FIT/" target="_blank">portal.sao.wa.gov/FIT</a> and select "Interactive Filing Statistics" from FIT’s menu, today!
		</div>
		<div class="sao-icon-image-item">
		<img width="250" src="assets/images/fit_by_the_numbers_sao_icon.png"
			 alt="sao icon"/>
		</div>
	</div>
</ng-container>

