import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OnTimeFilerBadgeComponent} from './on-time-filer-badge/on-time-filer-badge.component';
import {DxLoadIndicatorModule} from 'devextreme-angular';
import { BadgesComponent } from './badges/badges.component';
import {LoadingModule} from '../../ui/loading/loading.module';
import { BadgeComponent } from './badge/badge.component';


@NgModule({
	declarations: [
		BadgesComponent,
		OnTimeFilerBadgeComponent,
		BadgeComponent
	],
	imports: [
		CommonModule,
		DxLoadIndicatorModule,
		LoadingModule
	],
	exports: [
		BadgesComponent,
		OnTimeFilerBadgeComponent,
		BadgeComponent
	]
})
export class BadgesModule {
}
