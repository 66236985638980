import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ShareUrlService} from '../../../shared/services/share-url.service';
import {LoggerService} from '../../../shared/services/logger.service';
import {RouteAction} from '../route-action';

@Component({
	selector:  'app-new-share-url-tab',
	template:  '',
	styleUrls: []
})
export class NewShareUrlTabComponent extends RouteAction implements OnInit {

	constructor(
		private log: LoggerService,
		private route: ActivatedRoute,
		private shareUrlService: ShareUrlService,
		private router: Router
	) {
		super();
	}

	/**
	 * Open share url based on the hash param, and replace the history state to '/'
	 */
	ngOnInit() {
		const hash = this.route.snapshot.paramMap.get('hash');

		this.log.log(`Creating new shareUrl tab based on hash: ${hash}`);

		this.shareUrlService.openShareUrl(hash).then(() => this.router.navigateByUrl(''));
	}

}
