import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {map} from 'rxjs/operators';
import {Resource} from '../../shared/models/resource';
import {Subject} from 'rxjs';
import {ResourceChanged} from './resource-changed';
import {ShareInProgress} from '../../modules/decorators/share-in-progress';
import {ODataCollectionResult} from '../../modules/api/odata-collection-result';
import {ODataResult} from '../../modules/api/odata-result';

@Injectable({
	providedIn: 'root'
})
export class ResourcesService {

	constructor(
		private http: HttpClient
	) {
	}

	private url = (environment?.base ?? 'https://portal.sao.wa.gov/fit') + '/api/Resources';

	resourceChanged = new Subject<ResourceChanged>();

	@ShareInProgress
	getResources() {
		return this.http.get<ODataCollectionResult<Resource>>(`${this.url}?$orderby=sortOrder asc`).pipe(map((result) => result.value));
	}

	@ShareInProgress
	getResourceItem(id) {
		const specificURl = `${this.url}(${id})`;
		return this.http.get<ODataResult<Resource>>(specificURl);
	}

	editResourceItem(resource) {
		const specificURl = `${this.url}(${resource.id})`;
		return this.http.patch<Resource>(specificURl, resource);
	}

	createNewResource(resource) {
		return this.http.post<Resource>(this.url, resource);
	}

	deleteResource(id) {
		const specificURl = `${this.url}(${id})`;
		return this.http.delete<Resource>(specificURl);
	}

	orderResources(resource) {
		const specificURl = `${this.url}/SortOrder`;
		return this.http.post<Resource>(specificURl, resource);
	}

}
