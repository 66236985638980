import {Component, Input, OnInit} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {UserService} from '../../shared/services/user.service';
import {DataSourceService} from '../../shared/services/data-source.service';
import {HistoryService} from '../../shared/services/history.service';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {FilterBuilderService} from '../../shared/services/filter-builder.service';
import {LoggerService} from '../../shared/services/logger.service';
import {FitActionsService} from '../../shared/services/fit-actions.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector:    'app-sidebar-entities',
	templateUrl: './sidebar-entities.component.html',
	styleUrls:   ['./sidebar-entities.component.scss']
})
export class SidebarEntitiesComponent implements OnInit {

	@Input() label = 'Your Entities';
	@Input() icon;

	public isVisible = false;
	public yourEntities: Array<any>;

	constructor(
		private snapshotService: SnapshotService,
		private historyService: HistoryService,
		private userService: UserService,
		private dataSource: DataSourceService,
		private filterBuilder: FilterBuilderService,
		private logger: LoggerService,
		public fitActions: FitActionsService,
	) {
	}

	ngOnInit() {
		// Get the latest snapshot, which is what we'll use for creating a datasource
		this.snapshotService.getSnapshot().subscribe(latestSnapshot => {
			// Get the current user
			this.userService.user.subscribe(user => {
				// Should show "Your Entities" flag if the user has allowed mcags, but not if they can view all mcags
				this.isVisible = user.allowedMcags?.length > 0 && !user.hasGlobalAccess();

				// Fetch gov't data via mcags
				if (this.isVisible) {
					this.getGovernmentViaMcag(user.allowedMcags, latestSnapshot.id)
						.then((data: Array<any>) => this.yourEntities = data)
						.catch(error => this.logger.error('Could not retrieve "Your Entities" data', error));
				}
			});
		});
	}

	/**
	 * Fetch governments via the mcags the user is allowed to view
	 *
	 * @param mcags
	 * @param snapshotId
	 */
	getGovernmentViaMcag = (mcags: Array<string>, snapshotId: SnapshotId): Promise<Array<any>> => new Promise((res, rej) => {
		new DataSource({
			store:  this.dataSource.getStore(snapshotId, 'LocalGovernments'),
			filter: this.filterBuilder.group('mcag', mcags),
		}).load()
			.then(data => res(data))
			.catch(error => rej(error));
	});

	/**
	 * Open this entity in a new tab
	 *
	 * @param government
	 * @param event
	 */
	openEntityInNewTab(government: any, event: Event): void {
		event.preventDefault();
		this.historyService.replaceStateTabs();
		this.fitActions.createGovProfile(government);
		this.historyService.pushStateTabs();
	}

}
