import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TabService} from './tab.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoggerService} from './logger.service';

@Injectable({
	providedIn: 'root'
})
export class ShareUrlService {

	public shortUrlBase: string;
	public currentShareUrl: string = null;
	private baseUrl                = '';
	private endPoints              = {
		add: `AddSharedTabData`,
		get: `GetSharedTabData(key='$1')`
	};

	constructor(
		private http: HttpClient,
		private tabService: TabService,
		private log: LoggerService,
	) {
		this.shortUrlBase = environment.apis.app;
		this.baseUrl      = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
	}

	/**
	 * Opens a shareUrl link based off the key (aka: hash)
	 *
	 * @param key
	 */
	openShareUrl(key: string): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			if (key) {
				this.log.info('share-url.service: handleShareUrl() key:', key);

				// Request tab's data
				this.http.get(`${this.shortUrlBase}/${this.endPoints.get.replace('$1', key)}`, {
					headers:        new HttpHeaders({
						'Content-Type': 'text/plain',
						'Accept':       'text/plain'
					}),
					reportProgress: false,
				}).subscribe((result: { data: any }) => {
					this.tabService.areTabsLoaded.subscribe(tabsLoaded => {
						if (tabsLoaded) {
							this.tabService.buildTabFromShareUrlData(result.data).then(tab => {
								this.tabService.setSelectedTab(tab);
								resolve(true);
							});
						}
					});
				}, error => {
					const e = `Error getting share url: ${error}`;
					this.log.error(e);
					reject(e);
				});

				this.currentShareUrl = `${this.baseUrl}?shareUrl=${key}`;
			}
		});
	}

	/**
	 * Send tab data to storage api
	 *
	 * @param event
	 */
	storeTabData = (event: any) => {
		const url  = `${this.shortUrlBase}/${this.endPoints.add}`;
		const data = this.prepareDataForDatabase();

		if (data) {
			this.http.post(url, JSON.stringify({
				data: data
			}), {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Accept':       'application/json'
				}),
			}).subscribe(result => {
				const key = result['value'];

				// TODO: Enable swap these two lines with the 3rd once the api service redirects
				// const getUrl = this.endPoints.get.replace('$1', key);
				// this.currentShareUrl = `${this.shortUrlBase}/${getUrl}`;
				this.currentShareUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}share/${key}`;

				if (event) {
					const element = event.element.querySelector('input');

					// Normally, I would never... but it seems you must with this hack
					setTimeout(function() {
						if (element) {
							element.focus();
							element.select();
						}
					}, 100);
				}
			}, error => {
				this.log.error(`Error storing share url: ${error}`);
			});
		}
	}

	/**
	 * Prep data to be sent to DB
	 *
	 * @return Object
	 */
	prepareDataForDatabase() {
		const tab = this.tabService.getSelectedTab();

		if (tab) {
			return <Object>{
				selected:          tab.selected,
				title:             tab.title,
				years:             tab.years,
				governments:       tab.governments,
				govTypes:          tab.govTypes,
				locations:         tab.locations,
				category:          tab.category,
				report:            tab.report,
				track:             tab.track,
				pivotGridSettings: tab.pivotGridSettings,
				pivotGridState:    tab.pivotGridState,
				summary:           tab.summary,
				state:             tab.state,
				initialFields:     tab.initialFields,
				indicator:         tab.indicator
			};
		}
		else {
			return null;
		}
	}

}
