import {Component, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../shared/services/user.service';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {LoggerService} from '../../shared/services/logger.service';
import {UtilityService} from '../../shared/services/utility.service';
import {DxPopupComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import {faInfoCircle, faExternalLinkAlt, faEraser} from '@fortawesome/free-solid-svg-icons';
import {User} from 'app/shared/models/user';
import {StorageService} from '../../shared/services/storage.service';
import {confirm} from 'devextreme/ui/dialog';

@Component({
	selector:      'app-account-settings',
	encapsulation: ViewEncapsulation.None,
	templateUrl:   './account-settings.component.html',
	styleUrls:     ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnDestroy {

	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
	@ViewChild('content') content: TemplateRef<any>;
	private uiSubscription: Subscription;
	private userSubscription: Subscription;
	user: User;

	private message = {
		delete: {
			title: 'Delete app data?',
			body:  'Are you sure you want to delete all app data? This action cannot be undone.',
		}
	};

	isVisible = false;
	icons     = {
		infoCircle: faInfoCircle,
		external:   faExternalLinkAlt,
		eraser:     faEraser,
	};

	constructor(
		private userService: UserService,
		private uiService: UserInterfaceService,
		private storageService: StorageService,
		private logger: LoggerService,
		private utility: UtilityService
	) {
		this.uiSubscription   = this.uiService.accountSettings$.subscribe(
			value => this.isVisible = value
		);
		this.userSubscription = this.userService.user.subscribe(user => this.user = user);
	}

	ngOnDestroy() {
		this.uiSubscription.unsubscribe();
		this.userSubscription.unsubscribe();
	}

	onPlainTalkChange(e) {
		this.userService.setShowTechnical(e.value);
	}

	onAutoUpdateChange(e) {
		this.userService.setAutoUpdateSnapshots(e.value);
	}

	closeFirstTime() {
		this.isVisible = false;
	}

	deleteAppData() {
		confirm(this.message.delete.body, this.message.delete.title).then((confirmed) => {
			if (confirmed) {
				this.storageService.delete().then(() => {
					location.reload();
				});
			}
		});
	}

}
