import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FinancialSummaryService} from '../../services/financial-summary-service/financial-summary.service';
import {LoggerService} from '../../../shared/services/logger.service';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {ReusableGovernmentAndTypeInputs} from '../../reusable/models/reusable-government-and-type-inputs';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {ReportId} from '../../../shared/models/report';
import {finalize} from 'rxjs/operators';
import {ReportSummaryItem} from '../../services/financial-summary-service/models/report-summary-item';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Component({
	selector:    'wasao-finances-at-a-glance',
	templateUrl: './finances-at-a-glance.component.html',
	styleUrls:   ['./finances-at-a-glance.component.scss']
})
export class FinancesAtAGlanceComponent extends ReusableGovernmentAndTypeInputs implements OnInit, OnChanges {
	@Input() snapshotId: SnapshotId;
	@Input() reportTypeClickAction: (reportId: ReportId, displayYear?: number, fieldTransformations?: Array<any>) => void;

	items: Array<ReportSummaryItem>;

	/**
	 * Defines the reports that can be displayed on this component.
	 * @private
	 */
	private allowedReports: Array<ReportId> = [
		'revenues', 'expenditures', 'debtAndLiabilities',
		'schoolsRevenuesWithOthers', 'schoolsExpenditures',
	 // 'schoolsLiabilities'
	];

	constructor(
		filingStatus: FilingStatusService,
		fitApi: FitApiService,
		logger: LoggerService,
		private financialSummary: FinancialSummaryService
	) {
		super(filingStatus, fitApi, logger);
	}

	handleReportTypeClick = (reportId: ReportId, displayYear?: number) => {
		if (typeof this.reportTypeClickAction === 'function') {
			this.reportTypeClickAction(reportId, displayYear);
		}
	};

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.callFunctionWithSpecificity(changes, this.updateAmounts);
	}

	updateAmounts = (specificity: GovernmentSpecificity) => {
		this.isLoading = true;

		this.financialSummary.getTotalsByReport(specificity, this.snapshotId)
			.pipe(finalize(() => this.isLoading = false))
			.subscribe(summaries => {
				// Filter by allowed reports for this component and beta features
				this.items = summaries.filter(summary =>
					this.isAllowedReport(summary)
					&& (!summary.report.beta || this.showBeta)
				);

				this.hasData.emit(this.items?.length > 0);
		});
	}

	isAllowedReport = (item: ReportSummaryItem): boolean => this.allowedReports.includes(item.report.id);

}
