export enum HintInformationType {
	Technical = 'tech',
	Plain = 'plain'
}

export const HintInformation = {
	tbd: {
		[HintInformationType.Technical]: '> Technical: Info TBD <',
		[HintInformationType.Plain]: '> Plain: Info TBD <'
	},
	gaapAccounting: {
		[HintInformationType.Technical]: 'Generally accepted accounting principles are uniform standards and guidelines for financial accounting and reporting that are nationally recognized. These rules govern the form and content of the accounting records and financial statements of an entity. The primary authoritative body on GAAP for state and local governments is the Government Accounting Standards Board (GASB). The GAAP BARS manual is a document explaining accounting and reporting requirements for local governments.',
		[HintInformationType.Plain]: 'Generally Accepted Accounting Principles, or GAAP, is the nationally recognized method of how and when to record financial transactions. The rules in GAAP are established by the Government Accounting Standards Board (GASB). Washington governments can choose to report using GAAP or another alternative. The BARS GAAP manual explains the requirements for this type of accounting and reporting.'
	},
	cashAccounting: {
		[HintInformationType.Technical]: 'Cash basis is an accounting method that typically records revenue at the time cash is received and expenditures when cash is disbursed. The accounting system operates like a checkbook. The cash basis BARS manual prescribes and supports this alternative “other comprehensive basis of accounting” for local governments. Governments may choose to report following this basis of accounting, and it is often followed by the smaller local governments in this state.',
		[HintInformationType.Plain]: 'Cash basis is an accounting method that typically records revenue at the time cash is received and expenditures when cash is disbursed. The accounting system operates like a checkbook. Governments may choose to report following this basis of accounting, and it is often followed by the smaller local governments in this state. The BARS Cash Basis manual is a document explaining this type of accounting and reporting.'
	},
	annualF196: {
		[HintInformationType.Technical]: 'School district annual financial statement data is prepared and submitted by the District to the Office of the Superintendent of Public Instruction in accordance with WAC 392-117-035. FIT sources this data once per year.',
		[HintInformationType.Plain]: 'School district annual financial statement data is prepared and submitted by the District to the Office of the Superintendent of Public Instruction in accordance with WAC 392-117-035. FIT sources this data once per year. '
	},
	schoolsCash: {
		[HintInformationType.Technical]: 'Cash basis accounting for school districts focuses on cash inflows and outflows. It is an accounting method that records revenue at the time cash is received and expenditures when cash is disbursed. The accounting system operates like a checkbook. The schools accounting manual prescribes and supports this “other comprehensive basis of accounting.” It is jointly maintained by the Office of Superintendent of Public Instruction (OSPI) and the Office of the Washington State Auditor.',
		[HintInformationType.Plain]: 'Cash basis accounting for school districts is an accounting method that records revenue at the time cash is received and expenditures when cash is disbursed. The accounting system operates like a checkbook. The schools accounting manual explains this type of accounting and reporting. The Office of Superintendent of Public Instruction (OSPI) and the Office of the Washington State Auditor work together to maintain this manual.'
	},
	schoolsModifiedAccrual: {
		[HintInformationType.Technical]: 'The other comprehensive basis of accounting for school districts involves reporting governmental fund financial statements on the modified accrual basis with no district wide financial statements. The modified accrual basis of accounting has a current financial resource measurement focus, which means it does not include some long term activity such as capital assets or long term debt. The schools accounting manual prescribes and supports this “other comprehensive basis of accounting.” It is jointly maintained by the Office of Superintendent of Public Instruction (OSPI) and the Office of the Washington State Auditor.',
		[HintInformationType.Plain]: 'The modified accrual method of accounting reports the district’s financial statements on the modified accrual basis. In this method there are no district-wide financial statements. This accounting method recognizes current revenues and expenditures. This means it does not include some long term activity such as capital assets or long term debt. The schools accounting manual explains this type of accounting and reporting. The Office of Superintendent of Public Instruction (OSPI) and the Office of the Washington State Auditor work together to maintain this manual.'
	},
	schoolsRevenuesWithOthers: {
		[HintInformationType.Technical]: 'Revenue is money received by a government that is used to support the activities it provides. This money comes primarily from taxes, grants and other local, state and federal sources. “Other financing sources” is money derived from other activities, such as debt issuance or the sale of capital assets.',
		[HintInformationType.Plain]: 'Revenue is money received by a government that is used to support the activities it provides. This money comes primarily from taxes, grants and other local, state and federal sources. “Other financing sources” is money derived from other activities, such as debt issuance or the sale of capital assets.'
	},
	schoolsExpenditures: {
		[HintInformationType.Technical]: 'For the purposes of this system, expenditures are the money spent by a government for salaries, benefits, supplies and services.',
		[HintInformationType.Plain]: 'For the purposes of this system, expenditures are the money spent by a government for salaries, benefits, supplies and services.'
	},
	schoolsBalanceSheet: {
		[HintInformationType.Technical]: 'This represents data on assets, deferred outflows, liabilities, deferred inflows and residual fund balance for this entity.',
		[HintInformationType.Plain]: 'This represents data on assets, deferred outflows, liabilities, deferred inflows and residual fund balance for this entity.'
	},
	schoolsStatementRevExp: {
		[HintInformationType.Technical]: 'This represents all inflows and outflows that are available for this entity.',
		[HintInformationType.Plain]: 'This represents all inflows and outflows that are available for this entity.'
	},
	totalFinancialSummary: {
		[HintInformationType.Technical]: 'This is all the financial data that is available for this entity.',
		[HintInformationType.Plain]: 'This is all the financial data that is available for this entity.'
	},
	seeTrendData: {
		// "What's typical" has been postponed
		// [HintInformationType.Technical]: `Choose to totalsTrend the existing subject government or choose what's typical, and see how the subject government compares to other similar governments based on automatically selected five possible peers. You can modify the peer group if you choose.`,
		// [HintInformationType.Plain]: `Choose to either view the trends for your government or to see what's typical for other governments like yours. By viewing “What’s Typical”, you can compare your government to five other similar governments. These similar governments are randomly selected, but you can choose others.`
		[HintInformationType.Technical]: 'View the underlying data and see how it changes year-to-year.',
		[HintInformationType.Plain]: 'View the underlying data and see how it changes year-to-year.'
	},
	filterFunds: {
		[HintInformationType.Technical]: 'Use this control to select specific funds.',
		[HintInformationType.Plain]: 'Use this control to select specific funds.'
	},
	filterCategories: {
		[HintInformationType.Technical]: 'Use this control to select specific account categories.',
		[HintInformationType.Plain]: 'Use this control to select specific account categories.'
	},
	filterLocations: {
		[HintInformationType.Technical]: 'Use this control to select specific county locations.',
		[HintInformationType.Plain]: 'Use this control to select specific county locations.'
	},
	filterExpenditureObjects: {
		[HintInformationType.Technical]: 'Use this control to select specific expenditure objects.',
		[HintInformationType.Plain]: 'Use this control to select specific expenditure objects.'
	},
	financialFitnessGuideline: {
		[HintInformationType.Technical]: 'Guidelines from SAO based on audit analysis and financial management best practices. They are provided solely for the purposes of financial analysis and are not standards or audit criteria.',
		[HintInformationType.Plain]: `These guidelines are from the Washington State Auditor's Office. They are based on best practices of audit analysis and financial management. They are not standards or rules for audits. They are only for use in financial analysis. `
	},
	exploreIndividualGovernment: {
		[HintInformationType.Technical]: 'You may select your report by individual government or by government type.',
		[HintInformationType.Plain]: 'You may select your report by individual government or by government type.'
	},
	exploreFinancialCondition: {
		[HintInformationType.Technical]: 'Access financial indicator measures for governments that file using GAAP or Cash based accounting.',
		[HintInformationType.Plain]: 'Access financial indicator measures for governments that file using GAAP or Cash based accounting.',
	},
	baselineGovernment: {
		[HintInformationType.Technical]: 'This is your subject government that you can then select peer comparisons for.',
		[HintInformationType.Plain]: 'This is your subject government that you can then compare to peers.'
	},
	revenues: { // #48
		[HintInformationType.Technical]: 'External resources received by a government and used to support the activities it provides. Revenue encompasses money received primarily from taxes, fees, charges and grants.',
		[HintInformationType.Plain]: 'Revenue is money received by a government that is used to support the activities it provides. This money comes primarily from taxes, fees, charges and grants.'
	},
	expenditures: { // #49
		[HintInformationType.Technical]: 'For the purposes of this system, expenditures are money spent by a government for salaries, benefits, supplies and services.',
		[HintInformationType.Plain]: 'For the purposes of this system, expenditures are the money spent by a government for salaries, benefits, supplies and services.'
	},
	debtAndLiabilities: { // #94
		[HintInformationType.Technical]: 'Outstanding obligations reported by the government.',
		[HintInformationType.Plain]: 'Outstanding obligations reported by the government.'
	},
	operatingResultsAnalysisEnterprise: { // #95
		[HintInformationType.Technical]: 'Schedule 01 data categorized similar to the Statement of Revenues, Expenditures, and Changes in Net Position for review.',
		[HintInformationType.Plain]: 'Schedule 01 data categorized similar to the Statement of Revenues, Expenditures, and Changes in Net Position for review.'
	},
	operatingResultsAnalysisGovernmental: { // #96
		[HintInformationType.Technical]: 'Schedule 01 data categorized similar to the Statement of Revenues, Expenditures, and Changes in Fund Balance for review.',
		[HintInformationType.Plain]: 'Schedule 01 data categorized similar to the Statement of Revenues, Expenditures, and Changes in Fund Balance for review.'
	},
	report: {
		[HintInformationType.Technical]: 'Choose between different ways to look at the numbers. For example, you can see a summary of the data, or view the data independently by revenues or expenditures.',
		[HintInformationType.Plain]: 'Choose between different ways to look at the numbers. For example, you can see a summary of the data, or view the data independently by revenues or expenditures.'
	},
	indicatorCode: {
		[HintInformationType.Technical]: 'Choose between different applicable financial health indicators. GAAP or cash basis indicators are shown based on the accounting basis used by the government when they filed (for the latest year in the selected range).',
		[HintInformationType.Plain]: 'Choose between different applicable financial health indicators. Specific indicators are shown based on the method of accounting (GAAP or Cash) used by the government when they filed (for the latest year in the selected range).'
	},
	reportType: {
		[HintInformationType.Technical]: 'Select "Financial Health Indicators" to view specific financial health metrics for a given local government. Choose "Annual Filing Data" to view raw annual filing data filed by local governments.',
		[HintInformationType.Plain]: 'Select "Financial Health Indicators" to view specific financial health metrics for a given local government. Choose "Annual Filing Data" to view raw annual filing data filed by local governments.'
	},
	financialHealthIndicators: {
		[HintInformationType.Technical]: 'Having a particular Outlook does not necessarily indicate financial stability or stress. We designed the indicators to alert viewers to potential financial health concerns by comparing the ratios to our recommended thresholds. In many cases, further inquiry is recommended and it is important to point out governments may have measures different than our guidelines.',
		[HintInformationType.Plain]: 'Having a particular Outlook does not necessarily indicate financial stability or stress. We designed the indicators to alert viewers to potential financial health concerns by comparing the ratios to our recommended thresholds. In many cases, further inquiry is recommended and it is important to point out governments may have measures different than our guidelines.'
	},
	annualFilingData: {
		[HintInformationType.Technical]: 'Data in FIT is certified by the entity as part of their annual report filing process under state law (RCW 43.09.230) and is sourced from their most recent submission as of the selected snapshot date. Data presented in FIT is subject to change as an entity submits or updates their financial data.',
		[HintInformationType.Plain]: 'Data in FIT is certified by the entity as part of their annual report filing process under state law (RCW 43.09.230) and is sourced from their most recent submission as of the selected snapshot date. Data presented in FIT is subject to change as an entity submits or updates their financial data.'
	},
	enrollment: {
		[HintInformationType.Technical]: 'Headcount means the count of the individual students. Each enrolled student is one headcount. This is the average monthly Headcount of all grade levels for the school year indicated in parentheses.',
		[HintInformationType.Plain]: 'Headcount means the count of the individual students. Each enrolled student is one headcount. This is the average monthly Headcount of all grade levels for the school year indicated in parentheses.'
	},
	enrollmentTotal: {
		[HintInformationType.Technical]: 'Headcount means the count of the individual students. Each enrolled student is one headcount. This is the sum of all the average monthly Headcounts of all grade levels for all schools in the state for the school year indicated in parentheses.',
		[HintInformationType.Plain]: 'Headcount means the count of the individual students. Each enrolled student is one headcount. This is the sum of all the average monthly Headcounts of all grade levels for all schools in the state for the school year indicated in parentheses.'
	}
};
