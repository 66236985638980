import {Observable, share} from 'rxjs';

/**
 * Shares requests that are already in progress by memoizing arguments and storing the Observable in a Map with the rxjs
 *  share operator applied.
 * @param target
 * @param memberName
 * @param descriptor
 * @constructor
 */
export function ShareInProgress<T> (
	// Containing class
	target: Object,
	// the name of the method; may only be used on strings (not symbols) because we need to guarantee a key for memoization
	memberName: string,
	// TypedPropertyDescriptor<T> types descriptor.value as T
	// Can only be applied to a function that returns an Observable
	descriptor: TypedPropertyDescriptor<(...args: any[]) => Observable<T>>
) {
	// Store the memoizied function for use on subsequent calls
	const memoizedObservables = new Map<typeof memberName, Observable<T>>();
	// Make sure the function exists
	const originalFunction = descriptor?.value;
	if (!descriptor.value) {
		throw new Error(`ShareInProgress decorator found no function to decorate.`);
	}
	descriptor.value = function(...args: any[]): Observable<T> {
		// Memoize based on the args that get passed in
		const key = args.join('_');
		let observable = memoizedObservables.get(key);
		if (!observable) {
			observable = originalFunction
				// Call original function with parameters
				.apply(this, args)
				.pipe(
					// Share any in-progress requests
					share()
				);
			// Store for subsequent calls matching signature
			memoizedObservables.set(key, observable);
		}
		return observable;
	};
}
