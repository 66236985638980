import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapComponent} from './map.component';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';
import {environment} from 'environments/environment';
import {GovernmentServiceModule} from '../../services/government-service/government-service.module';
import {LoadingModule} from '../loading/loading.module';
import {DxButtonModule} from 'devextreme-angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';


@NgModule({
	declarations: [MapComponent],
	imports: [
		CommonModule,
		NgxMapboxGLModule.withConfig({accessToken: environment.mapboxAccessToken}),
		GovernmentServiceModule,
		LoadingModule,
		DxButtonModule,
		FontAwesomeModule
	],
	exports: [MapComponent]
})
export class MapModule {
}
