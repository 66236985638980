import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilingStatusSummaryComponent} from './filing-status-summary.component';
import {DxButtonModule, DxPieChartModule} from 'devextreme-angular';
import {LoadingModule} from '../../ui/loading/loading.module';
import {FilingStatusServiceModule} from '../../services/filing-status-service/filing-status-service.module';


@NgModule({
	declarations: [
		FilingStatusSummaryComponent
	],
	imports: [
		CommonModule,
		DxPieChartModule,
		LoadingModule,
		FilingStatusServiceModule,
		DxButtonModule
	],
	exports: [
		FilingStatusSummaryComponent
	]
})
export class FilingStatusSummaryModule {
}
