import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextSearchComponent } from './text-search.component';
import { DxTextBoxModule } from 'devextreme-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	imports: [
		CommonModule,
		DxTextBoxModule,
		FontAwesomeModule
	],
	declarations: [TextSearchComponent],
	exports: [TextSearchComponent]
})
export class TextSearchModule { }
