import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wasao-annual-filing-footnotes',
  templateUrl: './annual-filing-footnotes.component.html',
  styleUrls: ['./annual-filing-footnotes.component.scss']
})
export class AnnualFilingFootnotesComponent implements OnInit {

	@Input() numberOfNotes: number;

  constructor() { }

  ngOnInit(): void {
  }

}
