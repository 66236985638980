const fontFamilyObj = { family: '"Roboto", sans-serif' };
const fontObj = { font: fontFamilyObj };
export const customTheme = {
	name: 'FIT',
	barGauge: {
		label: fontObj,
		legend: fontObj,
		title: fontObj,
		tooltip: fontObj
	},
	pie: {
		commonSeriesSettings: { label: fontObj },
		legend: fontObj,
		title: fontObj,
		tooltip: fontObj
	},
	chart: {
		commonAnnotationSettings: fontObj,
		commonAxisSettings: { label: fontObj, title: fontObj, },
		commonSeriesSettings: { label: fontObj, },
		crosshair: { label: fontObj },
		legend: { font: fontFamilyObj, title: fontObj },
		title: { font: fontFamilyObj, subtitle: fontObj },
		tooltip: fontObj
	},
	gauge: {
		loadingIndicator: { fontObj },
		title: { font: fontFamilyObj, subtitle: fontObj },
		tooltip: fontObj,
		valueIndicator: { text: fontObj }
	}
};