import {Injectable} from '@angular/core';
import {TransitionService} from 'app/shared/services/transition.service';
import ODataStore from 'devextreme/data/odata/store';
import ArrayStore from 'devextreme/data/array_store';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import DataSource from 'devextreme/data/data_source';
import {LoggerService} from './logger.service';
import {Tab} from '../models/tab';
import {ReplaySubject, Subject} from 'rxjs';
import {TabService} from './tab.service';
import {StorageService} from './storage.service';
import {Projection, ProjectionRow} from 'app/components/projection/projection';
import {SnapshotService} from './snapshot.service';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {ProjectionService} from '../../components/projection/projection.service';
import {UtilityService} from './utility.service';
import {FinancialSummarySection} from '../../modules/api/fit-api/models/financial-summary';
import {AnalyticsService} from './analytics.service';
import {PivotGridComponent} from '../../components/pivot-grid/pivot-grid.component';
import {UnitsService} from './units.service';
import {Filter, FilterGroup, ODataQueryBuilderService} from './o-data-query-builder.service';
import {FilingStatusService} from '../../modules/services/filing-status-service/filing-status.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class PivotGridService {
	private dataSources      = new Array<ReplaySubject<PivotGridDataSource>>();
	private chartsVisibility = new Array<ReplaySubject<boolean>>();
	private indicatorReportMap = new Array<ReplaySubject<boolean>>();
	private instances = new Array<PivotGridComponent>();

	public isApplyingProjection = new Subject<boolean>();

	constructor(
		private transitionService: TransitionService,
		private logger: LoggerService,
		private tabService: TabService,
		private storageService: StorageService,
		private projectionService: ProjectionService,
		private snapshotService: SnapshotService,
		private utility: UtilityService,
		private apiService: FitApiService,
		private analytics: AnalyticsService,
		private unitsService: UnitsService,
		private oDataQueryBuilder: ODataQueryBuilderService,
		private filingStatusService: FilingStatusService
	) {
	}

	/**
	 * Get instance by TabId
	 * @param tabId
	 */
	getInstance = (tabId: number): PivotGridComponent =>
		this.instances.find(x => x.tab?.id === tabId);

	/**
	 * Register the instance in the instances array for future retrieval.
	 * @param pivotGrid
	 */
	registerInstance = (pivotGrid: PivotGridComponent) =>
		this.instances.push(pivotGrid);

	/**
	 * Remove the PivotGridComponent from the array.
	 * @param pivotGrid
	 */
	deregisterInstance = (pivotGrid: PivotGridComponent) =>
		this.instances = this.instances.filter(x => x !== pivotGrid);

	getChartVisibility(tab: Tab): ReplaySubject<boolean> {
		const exists = this.chartsVisibility[tab.id];
		if (exists) {
			return exists;
		}

		const ref = this.chartsVisibility[tab.id] = new ReplaySubject<boolean>(1);
		ref.next(false);
		return ref;
	}

	setChartVisibility(tab: Tab, isVisible: boolean) {
		const visibility = this.chartsVisibility[tab.id];
		if (visibility) {
			visibility.next(isVisible);
		}
	}

	getIndicatorReportMapVisibility(tabId: number): ReplaySubject<boolean> {
		return this.getOrInitIndicatorReportMapVisibility(tabId);
	}

	setIndicatorReportMapVisibility(tabId: number, isVisible: boolean): void {
		return this.getOrInitIndicatorReportMapVisibility(tabId).next(isVisible);
	}

	private getOrInitIndicatorReportMapVisibility(tabId: number): ReplaySubject<boolean> {
		return this.indicatorReportMap[tabId] = this.indicatorReportMap[tabId]
			?? new ReplaySubject<boolean>(1);
	}

	getDataSource(tab: Tab): ReplaySubject<PivotGridDataSource> {
		const exists = this.dataSources[tab.id];
		if (exists) {
			return exists;
		}

		const ref          = this.dataSources[tab.id] = new ReplaySubject<PivotGridDataSource>(1);
		const projectionId = (tab.pivotGridSettings && tab.pivotGridSettings.projectionId) || -1;
		this.storageService.projections.get(projectionId).then(projection => {
			const initialFields = tab.initialFields || [];
			delete tab.initialFields;
			if (projection) {
				this.isApplyingProjection.next(true);
			}


			this.buildPivotGridDataSource(tab, initialFields, projection)
				.then(dataSource => {
					ref.next(dataSource);

				})
				.catch((error: Error) => this.logger.error(error))
				.finally(() => this.isApplyingProjection.next(false));
		});

		return ref;
	}

	/**
	 * Completely rebuilds the data source, including url. Pass fields to preserve. Otherwise resets fields.
	 * @param tab
	 * @param fields Pass existing fields to preserve, otherwise will reset
	 */
	rebuildDataSource(tab: Tab, fields: Array<any> = []): void {
		let ref = this.dataSources[tab.id];
		if (!ref) {
			ref = this.dataSources[tab.id] = new ReplaySubject<PivotGridDataSource>(1);
		}

		// clear out any saved states (this handles the case when a ScheduleBrowser inits after having saved PivotGridState)
		delete tab.pivotGridState;
		const projectionId = tab.pivotGridSettings.projectionId || -1;
		this.storageService.projections.get(projectionId).then(projection => {
			if (projection) {
				this.isApplyingProjection.next(true);
			}
			this.buildPivotGridDataSource(tab, fields, projection)
				.then(dataSource => {
					// this.tabService.save(tab);

					ref.next(dataSource);
				})
				.catch((error: Error) => this.logger.error(error))
				.finally(() => this.isApplyingProjection.next(false));
		}).catch(error => this.logger.error(error));
	}

	private buildPivotGridDataSource(tab: Tab, fields: Array<any> = [], projection?: Projection): Promise<PivotGridDataSource> {
		return new Promise((resolve, reject) => {
			// Don't do this. Report Menu needs to be able to get a data source when switching from Indicators -> LGFRS
			// if (!tab.track?.id || !tab.report?.id) {
			// 	reject(`Tab's track and report objects must be present to generate a PivotGrid.`);
			// }
			const store = this.getStoreForDataSource(tab);
			const trackId = tab?.track?.id;
			const reportId = tab?.report?.id;

			if (projection) {
				// rewrite store with projection data
				this.getStoreWithProjection(tab, store, projection).then(arrayStore => {
					resolve(new PivotGridDataSource({
						store:          arrayStore,
						retrieveFields: false,
						fields:         this.transitionService.transitionFields(fields, tab, trackId, reportId, false),
						onLoadError:    e => this.logger.error(e)
					}));
				}).catch((error: Error) => {
					const reason = new Error('Failed to get store with projection.');
					reason.stack = error.stack;
					reject(reason);
				});
			}
			// Used for both schedule01CategoryTotals and schedule09CategoryTotals
			else if (trackId === 'd') {
				this.getStoreWithFilingStatusPhantomRecords(tab, store).then(arrayStore => {
					resolve(new PivotGridDataSource({
						store:          arrayStore,
						retrieveFields: false,
						fields:         this.transitionService.transitionFields(fields, tab, trackId, reportId, false),
						onLoadError:    e => this.logger.error(e)
					}));
				}).catch((error: Error) => {
					const reason = new Error('Failed to get store with phantom rows.');
					reason.stack = error.stack;
					reject(reason);
				});
			}
			else {
				resolve(new PivotGridDataSource({
					store,
					retrieveFields: false,
					fields:         this.transitionService.transitionFields(fields, tab, trackId, reportId, false),
					filter:         this.transitionService.getPivotGridFilterExpression(tab),
					onLoadError:    e => this.logger.error(e)
				}));
			}
		});
	}

	public getStoreForDataSource(tab: Tab): ODataStore | ArrayStore {
		const segment = this.transitionService.getSegment(tab);
		if (!segment) {
			return new ArrayStore();
		}

		let url = tab.report.financialsDatasetSource === 'OSPI'
			? this.apiService.getOSPIRoute(`/${segment}`)
			: this.apiService.getAnnualFilingRoute(tab.snapshotId, `/${segment}`);

		// hack to use forCountyCodes endpoints
		if (['c', 'd'].includes(tab.track.id) && tab.locations && tab.locations.length) {
			const countyCodes = tab.locations.map(x => x.countyCode);
			if (tab.report.id === 'schedule09CategoryTotals') {
				url += `ForCountyCodes(countyCodes=[${countyCodes}])`;
			} else {
				url += `/ForCountyCodes(countyCodes=[${countyCodes}])`;
			}

		}

		return new ODataStore({
			url:     url,
			key:     'id', // must have key for PivotGrid to operate
			keyType: 'Int32',
			beforeSend: (options) => {
				options.timeout = 120000;
			}
		});
	}

	/** Writes projection data into store */
	private getStoreWithProjection(tab: Tab, store: ODataStore | ArrayStore, projection: Projection): Promise<ArrayStore> {
		return new Promise((resolve, reject) => {
			const dataSource = new DataSource({
				store:  store,
				filter: this.transitionService.getPivotGridFilterExpression(tab)
			});

			dataSource.load().then(data => {
				if (!data.find(x => x.year === projection.baseYear)) {
					reject(new Error(`${this.constructor.name}.getStoreWithProjection does not contain baseYear ${projection.baseYear}`));
				}

				// only copy the base year forward for projections
				const newDataStoreRows = data.filter(x => x.year === projection.baseYear).reduce((result, row) => {
					if (row.fsSectionId !== FinancialSummarySection.beginningBalances && row.fsSectionId !== FinancialSummarySection.endingBalances && row.fsSectionId !== FinancialSummarySection.balanceSheet) { // Don't generate projected balances records here...handled in next step below
						const projectionRows = new Array<ProjectionRow>();
						// create a new row per year in projection
						for (let i = 0; i < projection.count; i++) {
							const projectionRow = this.projectionService.getProjectionRow(projection, row, i);
							projectionRows.push(projectionRow);
							const newRow = Object.assign({}, row);
							newRow.year = projection.baseYear + i + 1;
							newRow.isProjection = true;
							newRow.amount = this.aggregateProjectionAmount(row.amount, i, projectionRows);
							result.push(newRow);
						}
					}

					return result;
				}, []);

				const concatenatedData = data.concat(newDataStoreRows);

				// Add beginning and ending balance records
				const balancesData = this.generateBalancesRecords(concatenatedData, projection);

				resolve(new ArrayStore({data: concatenatedData.concat(balancesData)}));
			});
		});
	}

	/** Writes data w/ phantom rows into store for track D
	 * 1) Makes call to filing status api, finds any filing statuses where the government is inactive in year range given (doesn't have a record for that year) and creates a 'blank' filing status record for each of these mcags. Then, concatenates that list back to the original filing status response array.
	 * 2) Creates an array for distinct combination of year + fundCategoryId + fundTypeId that exist in data source.
	 * 3) Creates phantom record for every filing status that doesn't exist in the data source based on the distinct combo array in step 2. Then, concatenates that list back to the original data source.
	 *
	 * @param tab
	 * @param store
	 *
	 * Things to note about function:
	 * a) Extra phantom rows are created for mcag, fundtype, fundcategory combos. They're amount value is null. This is on purpose, it overall makes logic simpler to execute.
	 * b) Null value objects (phantom rows) have an extra property, governmentStatus.
	 * ^^^ (a) and (b) are handled in our custom aggregate function, calculateSummaryValueForPhantomYearForTrackD
	 * */
	private getStoreWithFilingStatusPhantomRecords(tab: Tab, store: ODataStore | ArrayStore): Promise<ArrayStore> {
		return new Promise((resolve, reject) => {
			const dataSource = new DataSource({
				store:  store,
				// pagination needs to be manually set to false here because if group expression is not set it defaults to true
				paginate: false,
				filter: this.transitionService.getPivotGridFilterExpression(tab)
			});

			// sets up filter and snapshotId for filing status call, makes call
			const snapshotId = tab.snapshotId;

			// gov type filter
			const govTypeCodeFilter = new FilterGroup(tab.govTypes.map(govType => new Filter('govTypeCode', 'eq', govType.code)));

			// county codes filter (if county codes exist)
			let countyCodeFilter1;
			if (tab.locations) {
				countyCodeFilter1 = new FilterGroup(tab.locations.map(location => new Filter('countyCodes', 'in', location.countyCode)));
			}

			// years filter
			const yearsFilter = new FilterGroup([new Filter('year', 'ge', tab.years[0]), new Filter('year', 'le', tab.years[1])]);

			// add filters together
			const filter = new this.oDataQueryBuilder.ODataQueryBuilder()
				.addFilterGroup(govTypeCodeFilter.filters, 'or')
				.addFilterGroup(countyCodeFilter1?.filters, 'or')
				.addFilterGroup(yearsFilter.filters, 'and')  // This is a year range test and so both conditions in this group must be true
				.toQueryString();

			this.apiService.getFilingStatuses(filter, snapshotId).subscribe(filingStatuses => {
				dataSource.load().then(data => {

					if (!data) {
						reject(new Error(`Failed to get '${tab?.report?.id}' store`));
					}

					// 0) Remove any inactive rows without filings from the results, so that governments that were inactive for the entire year range
					// won't appear in the pivot grid data source array;
					// this also handles cases where a government was inactive, but still filed (which can/could occur)
					const activeFilingStatuses = filingStatuses
						.filter(fs => fs.governmentStatus !== 'Inactive' || this.filingStatusService.filerConditions.includes(fs.filingCondition));

					// Create range of years based on tab years to be used in steps 1 and 2
					const rangeOfYears = this.utility.yearsToRange(tab.years);

					// 1) Add filing statuses for years that don't exist in year range (are inactive for that fiscal year)
					const filingStatusesWithInactiveYearsAttached = this.filingStatusesWithInactiveYearsAttachedBasedOnYearRange(rangeOfYears, activeFilingStatuses, snapshotId);

					// 2) Distinct list of year + fundCategoryId + fundTypeId from dataSource
					const distinctPropsFromDataSource = this.generateListOfDistinctPropsFromDataSource(data, rangeOfYears);

					// 3) Create phantom rows based on schedule 9 or schedule 1 category totals
					let phantomRows;
					if (tab?.report?.id === 'schedule09CategoryTotals') {
						phantomRows = this.generatePhantomRowArrayForSchedule9s(filingStatusesWithInactiveYearsAttached, distinctPropsFromDataSource);
					} else {
						phantomRows = this.generatePhantomRowArrayForSchedule1s(filingStatusesWithInactiveYearsAttached, distinctPropsFromDataSource);
					}

					// Concatenate phantom rows with data source
					data = data.concat(phantomRows);

					resolve(new ArrayStore({data: data}));
				});
			});
		});
	}

	/** Adds records that don't exist in filing status list for that year (are inactive) to filing statuses list based on years range
	 * @param rangeOfYears
	 * @param filingStatuses
	 * @param snapshotId
	 */
	private filingStatusesWithInactiveYearsAttachedBasedOnYearRange(rangeOfYears: number[], filingStatuses: any[], snapshotId: SnapshotId) {
		// 1) Create array of filing statuses grouped by mcag, create empty filing status array
		const filingStatusesByMcag: any[] = Object.entries(this.utility.groupBy(filingStatuses, 'mcag')).map(x => x[1]);
		const inactiveFilingStatuses = [];

		// 2) Loop over all statuses, compare where records don't exist in year range given, create 'blank' status records for those years for that mcag
		filingStatusesByMcag.forEach(arrayOfFilingStatuses => {
			const yearsForMcagGroup = arrayOfFilingStatuses.map(fs => fs.year);
			const yearsNotInGroup = rangeOfYears.filter(year => !yearsForMcagGroup.includes(year));
			yearsNotInGroup.forEach(phantomYear => {
				inactiveFilingStatuses.push(
					{ 	countyCodes: arrayOfFilingStatuses[0].countyCodes,
						dateSubmitted: null,
						daysLate: null,
						filingBasis: null,
						filingCondition: null,
						filingYearCondition: null,
						govTypeCode: arrayOfFilingStatuses[0].govTypeCode,
						governmentStatus: 'Inactive',
						maxAllowedSubmitDate: null,
						mcag: arrayOfFilingStatuses[0].mcag,
						noActivity: null,
						noDataAvailable: null,
						pendingUpdates: null,
						snapshotId: snapshotId,
						year: phantomYear
					}
				);
			});
		});

		// 3) Concatenate list of 'blank' filing statuses with filing statuses list
		return filingStatuses.concat(inactiveFilingStatuses);
	}

	/** Creates an array for distinct combination of year + fundCategoryId + fundTypeId that exist in data source
	 * @param data
	 * @param rangeOfYears
	 */
	private generateListOfDistinctPropsFromDataSource(data: any, rangeOfYears: number[]) {
		// 1) Filter distinct objects in array by year + fundCategoryId + fundTypeId combo
		const distinctProps = data.filter((value, index, self) =>
				index === self.findIndex((t) => (
					t.year === value.year && t.fundCategoryId === value.fundCategoryId && t.fundTypeId === value.fundTypeId
				))
		)
			// 2) Reduce array of objects to just above properties
			.reduce((acc, curVal) => acc.concat({year: curVal.year, fundCategoryId: curVal.fundCategoryId, fundTypeId: curVal.fundTypeId}), []);


		// 3) If year in range isn't in distinct list, create combo for that year
		const yearsNotInDistinctProps = rangeOfYears.filter(( year1 ) => !distinctProps.some(({ year: year2 }) => year2 === year1));
		const formattedYearsNotInDistinctProps = yearsNotInDistinctProps.map(yearsNotIn =>  {
			return {
				year: yearsNotIn, fundCategoryId: null, fundTypeId: null};
		});

		return distinctProps.concat(formattedYearsNotInDistinctProps);
	}

	/** Creates phantom rows by iterating over distinct combos list and adding phantom filing status records by year
	 * returns schedule 1 summary object model
	 * @param filingStatuses
	 * @param distinctPropsFromDataSource
	 */
	private generatePhantomRowArrayForSchedule1s(filingStatuses: any[], distinctPropsFromDataSource: any[]) {
		const phantomRows = [];
		filingStatuses.forEach((fs) => {
			distinctPropsFromDataSource.forEach((distinct) => {
					if (distinct.year === fs.year) {
						phantomRows.push(
							{ 	amount: null,
								barsAccountId: null,
								basicAccountId: null,
								countyCodes: fs.countyCodes,
								debtCapitalExp: null,
								elementId: null,
								expenditureObjectId: null,
								filingCondition: fs.filingCondition,
								fsSectionId: null,
								fundCategoryId: distinct.fundCategoryId,
								fundTypeId: distinct.fundTypeId,
								govTypeCode: fs.govTypeCode,
								id: null,
								mcag: fs.mcag,
								pendingUpdates: fs.pendingUpdates,
								primeId: null,
								subAccountId: null,
								subElementId: null,
								year: distinct.year,
								governmentStatus: fs.governmentStatus
							});
					}
				});
		});
		return phantomRows;
	}

	/** Creates phantom rows by iterating over distinct combos list and adding phantom filing status records by year
	 * returns schedule 9 summary object model
	 * @param filingStatuses
	 * @param distinctPropsFromDataSource
	 */
	private generatePhantomRowArrayForSchedule9s(filingStatuses: any[], distinctPropsFromDataSource: any[]) {
		const phantomRows = [];
		filingStatuses.forEach((fs) => {
			distinctPropsFromDataSource.forEach((distinct) => {
				if (distinct.year === fs.year) {
					phantomRows.push(
						{ 	additions: null,
							beginning: null,
							bonanzaCategoryId: null,
							bonanzaTypeId: null,
							countyCodes: fs.countyCodes,
							debtCategoryItemId: null,
							debtGroupId: null,
							description: null,
							ending: null,
							filingCondition: fs.filingCondition,
							fund: null,
							fundCategoryId: distinct.fundCategoryId,
							fundTypeId: distinct.fundTypeId,
							govTypeCode: fs.govTypeCode,
							id: null,
							maturityDate: null,
							mcag: fs.mcag,
							pendingUpdates: fs.pendingUpdates,
							reductions: null,
							year: distinct.year,
							governmentStatus: fs.governmentStatus
						});
				}
			});
		});

		return phantomRows;
	}

	private generateBalancesRecords(data: Array<any>, projection: Projection) {
		const fundNumbers = this.utility.groupBy(data, 'fundNumber');
		const balancesData = [];
		for (const fundNumber of Object.keys(fundNumbers)) { // Iterate through the funds and calculate beginning and ending balances
			const fundRows = fundNumbers[fundNumber];
			const baseYearEndingBalances = fundRows.filter(r => r.year === projection.baseYear && r.basicAccountId === 1903);
			if (baseYearEndingBalances?.length > 0) {
				const balanceRowTemplate = baseYearEndingBalances[0];  // This is the template we will use for our new records for this fund
				let endingBalance = baseYearEndingBalances.reduce((sum, row) => sum + row.amount, 0);  // Start with the base year's ending balance
				for (let i = 0; i < projection.count; i++) {
					const projectionYear = projection.baseYear + i + 1;

					// Create projection year's beginning balance from prior year's ending balance
					const newBeginBalanceRow = Object.assign({}, balanceRowTemplate);
					newBeginBalanceRow.amount = endingBalance;
					newBeginBalanceRow.year = projectionYear;
					newBeginBalanceRow.barsAccountId = 2; // This is the id for BARS Account 308
					newBeginBalanceRow.fsSectionId = FinancialSummarySection.beginningBalances;
					newBeginBalanceRow.primeId = 1;
					newBeginBalanceRow.basicAccountId = newBeginBalanceRow.barsAccountId;
					newBeginBalanceRow.subAccountId = newBeginBalanceRow.barsAccountId;
					newBeginBalanceRow.elementId = newBeginBalanceRow.barsAccountId;
					newBeginBalanceRow.subElementId = newBeginBalanceRow.barsAccountId;
					newBeginBalanceRow.lineDesc = 'Projection beginning balance';
					// TODO newBeginBalanceRow.debtCapitalExp = null;
					newBeginBalanceRow.isProjection = true;
					balancesData.push(newBeginBalanceRow);

					// Update ending balance
					const increases = fundRows.filter(r => r.year === projectionYear && r.primeId === 1 && r.basicAccountId !== 2)
						.reduce((sum, row) => sum + row.amount, 0);
					const decreases = fundRows.filter(r => r.year === projectionYear && r.primeId === 1902 && r.basicAccountId !== 1903)
						.reduce((sum, row) => sum + row.amount, 0);
					endingBalance = endingBalance + increases - decreases;

					// Create projection year's ending balance
					const newEndBalanceRow = Object.assign({}, balanceRowTemplate);
					newEndBalanceRow.amount = endingBalance;
					newEndBalanceRow.year = projectionYear;
					newEndBalanceRow.barsAccountId = 1903; // This is the id for BARS Account 508
					newEndBalanceRow.fsSectionId = FinancialSummarySection.endingBalances;
					newEndBalanceRow.primeId = 1902;
					newEndBalanceRow.basicAccountId = newEndBalanceRow.barsAccountId;
					newEndBalanceRow.subAccountId = newEndBalanceRow.barsAccountId;
					newEndBalanceRow.elementId = newEndBalanceRow.barsAccountId;
					newEndBalanceRow.subElementId = newEndBalanceRow.barsAccountId;
					newEndBalanceRow.lineDesc = 'Projection ending balance';
					// TODO newEndBalanceRow.debtCapitalExp = null;
					newEndBalanceRow.isProjection = true;
					balancesData.push(newEndBalanceRow);
				}
			}
		}

		return balancesData;
	}

	private aggregateProjectionAmount = (baseAmount: number, yearIndex: number, projectionRows: Array<ProjectionRow>) => {
		let result = baseAmount;
		// if there are projectionRows matching bars account, apply the projection amount
		// assumes there are always a fixed amount of futureYearAdjustments
		for (let i = 0; i <= yearIndex; i++) {
			const adjustment = projectionRows[i] ? projectionRows[i].futureYearAdjustments[i] : 0.0;
			result *= (1 + adjustment ?? 0);
		}

		return result;
	};

	/**
	 * Apply saved Projection to LGFRS report on tab
	 * @param tab
	 * @param projection
	 */
	applyToPivotGridTab(tab: Tab, projection: Projection) {
		tab.years                            = this.getFilingYearsForReport(projection.baseYear);
		tab.pivotGridSettings.projectionId   = projection.id;
		tab.pivotGridSettings.projectionName = projection.name;
		this.tabService.save(tab);
		this.analytics.sendEvent(`adding projection`, tab);
		this.rebuildDataSource(tab);
	}

	/**
	 * Remove Projection from LGFRS report on tab
	 * @param tab
	 */
	removeFromPivotGridTab(tab: Tab) {
		delete tab.pivotGridSettings.projectionId;
		delete tab.pivotGridSettings.projectionName;
		this.tabService.save(tab);
		this.analytics.sendEvent(`removing projection`, tab);
		this.rebuildDataSource(tab);
	}

	/**
	 * Gets the years of filing data to display on report
	 * @param baseYear
	 */
	private getFilingYearsForReport = (baseYear: number): [number, number] => [baseYear - 1, baseYear];

	getSettings(tab, user) {
		const settings = {
			showPopulation:            false,
			showAccountCodes:          user.showTechnical,
			showOptionsDrawer:         true,
			measure:                   this.unitsService.getDefaultMeasure(tab),
			comparison:                this.unitsService.getDefaultComparison(tab),
			unit:                      'dollars', // TODO change to support different tracks,
			financialSummaryHierarchy: 'Standard',
			projectionId:              tab.pivotGridSettings?.projectionId,
			projectionName:            tab.pivotGridSettings?.projectionName
		};

		return Object.assign(settings, tab.pivotGridSettings);
	}
}
