import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from './logger.service';
import {environment} from 'environments/environment';
import {map} from 'rxjs/operators';
import {SnapshotService} from './snapshot.service';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {Filter, FilterGroup} from './o-data-query-builder.service';
import {IndicatorReport} from '../../modules/api/fit-api/models/indicators/indicator-report';
import {of} from 'rxjs';
import {ShareInProgress} from '../../modules/decorators/share-in-progress';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';
import {ODataCollectionResult} from '../../modules/api/odata-collection-result';
import {IndicatorGroup} from '../../modules/api/fit-api/models/indicators/indicator-group';

@Injectable({
	providedIn: 'root'
})
export class IndicatorService {

	private apiUrl: string;

	constructor(
		private http: HttpClient,
		private logger: LoggerService,
		private apiService: FitApiService
	) {
		this.apiUrl = environment.apis.app;
	}

	// Annual Filing Indicators
	@ShareInProgress
	getAnnualFilingIndicatorGroups(mcag: string, snapshotId: SnapshotId, startYear: string | number, endYear: string | number) {
		if (!mcag || !snapshotId || !startYear || !endYear) {
			this.logger.error(`Argument missing from getIndicatorGroups; mcag: ${mcag}, snapshotId: ${snapshotId}, startYear: ${startYear}, endYear: ${endYear}`);
		}
		return this.http.get<ODataCollectionResult<IndicatorGroup>>(this.apiService.getAnnualFilingRoute(snapshotId, `/IndicatorGroups/For(mcag='${mcag}',startYear=${startYear},endYear=${endYear})`))
			.pipe(map((result: any) => result.value));
	}

	getIndicatorReports(mcags: Array<string>, snapshotId: SnapshotId, startYear: string | number, endYear: string | number) {
		if (!mcags || !snapshotId || !startYear || !endYear) {
			this.logger.error(`Argument missing from getIndicatorGroups; mcags: ${mcags}, snapshotId: ${snapshotId}, startYear: ${startYear}, endYear: ${endYear}`);
		}

		const mcagsFilter = new FilterGroup(mcags.map(mcag => new Filter('mcag', 'eq', mcag))).toQueryString();
		const filter = `$filter=report eq 'GovernmentalIndicators' and year ge ${startYear} and year le ${endYear} and ${mcagsFilter}`;
		return this.apiService.getIndicatorReports(snapshotId, filter);
	}

	// Schools Indicators
	getSchoolsIndicatorGroups(mcag: string, startYear: string | number, endYear: string | number) {
		if (!mcag || !startYear || !endYear) {
			this.logger.error(`Argument missing from getIndicatorGroups; mcag: ${mcag}, startYear: ${startYear}, endYear: ${endYear}`);
		}
		return this.http.get<ODataCollectionResult<IndicatorGroup>>(this.apiService.getOSPIRoute(`/IndicatorGroups/For(mcag='${mcag}',startYear=${startYear},endYear=${endYear})`))
			.pipe(map((result: any) => result.value));
	}
}
