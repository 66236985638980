<ul
	class="tagbox"
	[class.tagbox--empty]="!selections?.length"
	dndDropzone
	[dndDisableIf]="dropzoneDisabled"
	(dndDrop)="onDrop($event)"
	(dndDragover)="onDragOver($event)"
>
	<li *ngIf="!selections?.length">
		No governments selected. Find one below, drag and drop a government here, or load a saved peer set.
	</li>
	<li
		*ngFor="let government of selections"
		class="government"
		dndEffectAllowed="move"
		[dndDraggable]="government"
	>
		<span class="grouping-editor__remove" (click)="remove(government)"><fa-icon [icon]="icons.close"></fa-icon></span>
		{{ government.entityNameWithDba || government.mcag }}
	</li>
	<li #placeholder class="dndPlaceholder government" dndPlaceholderRef>&nbsp;</li>
</ul>

<div *ngIf="selections?.length < maxSelections" class="search">
	<dx-autocomplete
		class="autocomplete"
		[dataSource]="dataSource"
		displayValue="entityName"
		searchExpr="entityName"
		searchEnabled=true
		placeholder="Type entity name..."
		(onItemClick)="selectBoxChanged($event)"
	>
		<dx-validator>
			<dxi-validation-rule type="custom" [validationCallback]="validationCallback"></dxi-validation-rule>
		</dx-validator>
		<div *dxTemplate="let item of 'item'">
			{{ item.entityName }}
		</div>
	</dx-autocomplete>
	<!-- Removing until this functionalty has actually been implemented properly -->
	<!-- <span class="find">or <button class="u-padding-none" (click)="handleFindAnEntity($event)">find an entity</button></span> -->
</div>
