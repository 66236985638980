<div class="government-types">
	<dx-tag-box
		class="government-types__tag-box"
		[disabled]="disabled"
		[items]="store"
		[value]="selections"
		noDataText="Loading selections..."
		[placeholder]="placeholder"
		displayExpr="description"
		valueExpr="code"
		showClearButton=true
		(onOpened)="handleOpen($event)"
		(onValueChanged)="handleTagboxChanged($event)"
	>
		<dx-validator *ngIf="validationCallback">
			<dxi-validation-rule type="custom" [validationCallback]="validationCallback" [message]="validationMessage"
								 [reevaluate]="true"></dxi-validation-rule>
		</dx-validator>
	</dx-tag-box>

	<dx-popup
		class="government-types__popup"
		[(visible)]="isPopupVisible"
		showTitle=true
		title="Pick some government types to include! {{isTrackD ? maxGovTypeDisclaimerForTrackD: ''}}"
	>
		<div *dxTemplate="let data of 'content'">

			<dx-load-panel
				[visible]="isLoading"
				message="One moment while we load Government Types..."
				shadingColor="rgba(255, 255, 255, 0.8);"
				[container]="popup__inner"
			></dx-load-panel>

			<div class="app-popup__wrapper">
				<div #popup__inner id="popup__inner" class="app-popup__inner" appScrollableShadow>
					<p class="u-text-align-center" *ngFor="let error of errors">{{ error }}</p>

					<div class="checkbox-grid">
						<div class="checkbox-grid__list">
							<div class="checkbox-container" *ngFor="let item of store">
								<ng-container *ngIf="isDisabled(item)">
									<div class="dsbld">
										<div class="di">
											<wasao-hint [icon]="icons.ban" showOn="hover" iconColor="#dddddd">
												{{ getDisabledReason(item.code) }}
											</wasao-hint>
										</div>
										<label>
											{{ item.description }} ({{ item.activeCount }} active)
										</label>
									</div>
									<div>
										<button (click)="goToProfile(item)">
											<wasao-hint [icon]="icons.extLink" showOn="hover">
												Go to profile for <br>{{ govTypesService.getPlural(item.description) }}
											</wasao-hint>
										</button>
									</div>

								</ng-container>
								<ng-container *ngIf="!isDisabled(item)">
									<dx-check-box
										[text]="displayFormat(item)"
										[disabled]="isDisabled(item)"
										[value]="selections?.includes(item.code)"
										(onValueChanged)="checkItem(item, $event)"
									></dx-check-box>

									<button (click)="goToProfile(item)">
										<wasao-hint [icon]="icons.extLink" showOn="hover">
											Go to profile for <br>{{ govTypesService.getPlural(item.description) }}
										</wasao-hint>
									</button>
								</ng-container>

							</div>
						</div>
					</div>
				</div>

				<div class="app-popup__footer">
					<dx-button
						type="default"
						text="Done"
						(onClick)="this.isPopupVisible = false"
					></dx-button>
				</div>
			</div>

		</div>
	</dx-popup>
</div>
