import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BarsDescriptionComponent} from './bars-description.component';
import {DxPopoverModule} from 'devextreme-angular';
import {LoadingModule} from "../loading/loading.module";


@NgModule({
	declarations: [
		BarsDescriptionComponent
	],
    imports: [
        CommonModule,
        DxPopoverModule,
        LoadingModule
    ],
	exports: [
		BarsDescriptionComponent
	]
})
export class BarsDescriptionModule {
}
