<dx-popup
	class="first-time-popup"
	width="560"
	height="auto"
	maxHeight="95%"
	[dragEnabled]="true"
	[showCloseButton]="false"
	[(visible)]="isVisible">
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<div class="first-time-popup__content">
					<h1>Pleased to meet you!</h1>

					<p>
						Since it’s your first time here, let’s set your preferences.
						<br />(You can change these at any time by going to your account settings.)
					</p>
				</div>

				<div class="first-time-popup__actions">
					<div class="first-time-popup__action">
						<h4>Auto-update data to see latest published version:</h4>

						<dx-tooltip
							target="#first-time-popup__auto-update-on"
							position="bottom"
							showEvent="dxhoverstart"
							hideEvent="dxhoverend">
							<div *dxTemplate="let data = data of 'content'">
								A tool tip about auto updating snapshots
							</div>
						</dx-tooltip>

						<fa-icon [icon]="icons.infoCircle" id="first-time-popup__auto-update-on"></fa-icon>
						<app-switch
							[(value)]="autoUpdateSnapshots"
						></app-switch>
						<fa-icon [icon]="icons.infoCircle" id="first-time-popup__auto-update-off"></fa-icon>

						<dx-tooltip
							target="#first-time-popup__auto-update-off"
							position="bottom"
							showEvent="dxhoverstart"
							hideEvent="dxhoverend">
							<div *dxTemplate="let data = data of 'content'">
								A tool tip about auto updating snapshots
							</div>
						</dx-tooltip>
					</div>

					<div class="first-time-popup__action">
						<h4>Display tips, hints, and explanations in:</h4>

						<fa-icon [icon]="icons.infoCircle" id="first-time-popup__plaintalk"></fa-icon>
						<app-switch
							falseLabel="Plaintalk"
							trueLabel="Technical Language"
							[(value)]="showTechnical"
						></app-switch>
						<fa-icon [icon]="icons.infoCircle" id="first-time-popup__technical-language"></fa-icon>

						<dx-tooltip
							target="#first-time-popup__plaintalk"
							position="bottom"
							showEvent="dxhoverstart"
							hideEvent="dxhoverend">
							<div *dxTemplate="let data = data of 'content'">
								We&rsquo;ll use everyday language to communicate financial concepts, where possible. This option is designed for users who are less experienced with public finance&rsquo;s unique terminology.
							</div>
						</dx-tooltip>

						<dx-tooltip
							target="#first-time-popup__technical-language"
							position="bottom"
							showEvent="dxhoverstart"
							hideEvent="dxhoverend">
							<div *dxTemplate="let data = data of 'content'">
								We&rsquo;ll use technical language to show financial concepts. This option is for users who are experienced with public finance vocabulary.
							</div>
						</dx-tooltip>
					</div>
				</div>
			</div>

			<div class="app-popup__footer">
				<dx-button
					text="Finish"
					type="default"
					(onClick)="close($event)"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
