<dx-tree-view
	class="tree-view"
	[dataSource]="store"
	dataStructure="tree"
	keyExpr="key"
	height="auto"
	showCheckBoxesMode="normal"
	(onItemSelectionChanged)="onSelectionChanged($event)"
>
	<div *dxTemplate="let item of 'item'">
		<span class="name">{{ item.name ? item.name : item.fundNumber }}</span>
		<span class="code" *ngIf="showAccountCodes && item.fundNumber"> ({{ item.fundNumber }})</span>
	</div>
</dx-tree-view>
