<div class="government-type-chooser">
	<div class="dx-fieldset">
		<div class="dx-field">
			<div class="dx-field-label">{{ govtTypeLabel }}</div>
			<div class="dx-field-value">
				<app-government-types
					[disabledGovTypes]="disabledGovTypes"
					(changed)="updateFilterTypes($event)"
				></app-government-types>
			</div>
		</div>
		<div class="dx-field">
			<div class="dx-field-label">
				{{ locationLabel }}
			</div>
			<div class="dx-field-value">
				<app-locations
					(changed)="updateFilterLocations($event)"
					[store]="snapshot?.detail?.counties"
					[selections]="locationSelections"
				></app-locations>
			</div>
		</div>
		<div class="field-buttons">
			<div class="field-buttons__left">
				<dx-button
					type="normal"
					text="Back"
					(click)="handleBack($event)"
				></dx-button>
			</div>
			<div class="field-buttons__right">
				<dx-button
					type="default"
					text="View data for these government types and locations"
					(click)="handleSubmit()"
				></dx-button>
			</div>
		</div>
	</div>

	<dx-load-panel [visible]="isLoading" message="Loading snapshot data..."></dx-load-panel>
</div>
