import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {
	FinancialHealthIndicatorStatisticGroup,
	FinancialHealthIndicatorStatistics
} from './financial-health-indicator-statistics';
import {IndicatorGroup} from '../../api/fit-api/models/indicators/indicator-group';
import {LoggerService} from '../../../shared/services/logger.service';
import { environment } from 'environments/environment';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {SnapshotId} from '../../api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class FinancialHealthIndicatorStatisticsService {

	constructor(
		private fitApi: FitApiService,
		private logger: LoggerService
	) {
	}

	getIndicatorStatistics = (specificity: GovernmentSpecificity, snapshotId?: SnapshotId): Observable<Array<FinancialHealthIndicatorStatistics>> => {
		if (specificity.type === 'government') {
			throw new Error('Single government mode is not supported.');
		}
		return this.fitApi.getIndicatorReportGroups(specificity.id, specificity.year, snapshotId).pipe(
			map(result => {
				const transform = result.reduce((accumulator, currentRow) => {
					// resolve group
					const groupTranslation = this.getGroupTranslation(currentRow);
					// find or create new record based on group
					let record = accumulator.find(x => x.group === groupTranslation && x.filingBasis === currentRow.filingBasis);
					if (!record) {
						record = new FinancialHealthIndicatorStatistics(groupTranslation, currentRow.filingBasis);
						record.year = specificity.year;
						accumulator.push(record);
					}
					// update count based on this row
					const outlookKeyName = currentRow.outlookInfo.outlook?.toLowerCase();
					record[outlookKeyName]++;
					record.indicatorGroups.push(currentRow);
					return accumulator;
				}, new Array<FinancialHealthIndicatorStatistics>());

				// this.printIndeterminateReport(transform);
				this.logger.log('getIndicatorStatistics', transform);

				return transform.sort(this.compareGroup);
			})
		);
	}

	/**
	 * Resolves the FinancialHealthIndicatorStatisticGroup from an IndicatorGroup. I.e., categorizes 4xx funds into
	 *  'Enterprise Funds', or passes through 'All Governmental Funds' and 'General Fund'.
	 * @param data
	 * @private
	 */
	private getGroupTranslation(data: IndicatorGroup): FinancialHealthIndicatorStatisticGroup {
		if (data.group === 'All Governmental Funds' || data.group === 'General Fund') {
			return data.group;
		} else if (data.group.match(/^4\d{2}/)) {
			return 'Enterprise Funds';
		} else {
			this.logger.warn(`No group could be matched for ${data.group}`);
		}
	}

	private compareGroup(a: FinancialHealthIndicatorStatistics, b: FinancialHealthIndicatorStatistics): number {
		const groupMap = {
			'General Fund': 0,
			'All Governmental Funds': 1,
			'Enterprise Funds': 2
		};
		return groupMap[b.group] - groupMap[a.group];
	}

	/**
	 * Prints a table of the statistics for analysis
	 * @param stats
	 */
	printIndeterminateReport(stats: Array<FinancialHealthIndicatorStatistics>): void {
		if (environment.production) {
			return;
		}

		const columns = ['group', 'mcag', 'report', 'filingBasis'];
		const data = stats.flatMap(x => x.indicatorGroups);
		this.logger.table(data, columns);
	}
}
