export const Disclaimer = {
	tiny: 'Data sources and limitations',
	full: ['<p>The Office of the State Auditor does not audit or verify the data before publishing. Questions about the data should be directed to the local government or state/federal agency responsible for it. The data displayed in FIT is provided by the following sources:</p><p><b>Non-school district data</b> is provided by each local government, certified by each as complete, accurate and in conformity with the Budgeting, Accounting and Reporting Systems Manual.</p><p><b>School district data</b> is provided by the Office of Superintendent of Public Instruction (OSPI) and conforms to the Accounting Manual for Public School Districts.</p><p><b>Population data</b> is provided by the Office of Financial Management.</p><p><b>Tax boundary shapefiles data</b> is provided by the Department of Revenue and the US Census Bureau.</p><p>The State of Washington nor any of its agencies, officers or employees does not warrant the accuracy, reliability or timeliness of this information and shall not be liable for any losses caused by reliance on the accuracy, reliability or timeliness of such information.</p>'],
	limitations: [
		{
			paragraph: 'Data presented in FIT is subject to change as local governments submit or update their financial data. The data presented includes the following: General Fund, Special Revenue Funds, Debt Service Funds, Capital Project Funds, Enterprise Funds, and debt and liability information. Missing data or large variances from year to year might exist due to:',
			list: ['Local governments not submitting all required information',
				'A newly created local government having no previous history',
				'A failure to report annually, as required by state law',
				'Annexations, incorporations or changes in the activities of local governments']
		},
		{
			paragraph: 'Snapshots of annual filing data are published periodically by the State Auditor’s Office, based on annual report information submitted by all local governments as of a particular date. Updates are published on or after March 31, June 30, September 30 and December 31 each year. The snapshot date can be seen in the upper-right corner of the FIT screen.'
		}]
};
