import {Component, Input} from '@angular/core';
import {Tab} from '../../shared/models/tab';
import {FitActionsService} from '../../shared/services/fit-actions.service';

@Component({
	selector:    'app-hype',
	templateUrl: './hype.component.html',
	styleUrls:   ['./hype.component.scss']
})
export class HypeComponent {
	@Input() tab: Tab;

	constructor(public fitActions: FitActionsService) { }

}
