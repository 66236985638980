import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Report, REPORTS} from '../../shared/models/report';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {of} from 'rxjs';
import {AvailableOperatingResultsAnalyses} from '../../modules/api/fit-api/models/available-operating-results-analyses';
import {ReportType} from '../../shared/models/report-type-menu-option';
import {ExploreType} from '../../shared/models/explore-by-option';
import {DxRangeSliderComponent, DxSelectBoxComponent} from 'devextreme-angular';

@Component({
	selector: 'app-report-selector',
	templateUrl: './report-selector.component.html',
	styleUrls: ['./report-selector.component.scss']
})
export class ReportSelectorComponent implements OnInit, OnChanges {

	@Input() mcag: string;
	@Input() showBetaForUser: boolean;
	@Input() reportType: ReportType;
	@Input() exploreType: ExploreType;
	@Input() selectedReportId: string;
	@ViewChild(DxSelectBoxComponent) selectBox: DxSelectBoxComponent;
	@Output() changed = new EventEmitter();

	isLoading = false;
	reports = REPORTS;
	availableReports: Readonly<Array<Partial<Report>>>;

	constructor(
		private fitApi: FitApiService
	) {
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.generateAvailableReports();

	}

	generateAvailableReports() {
		this.isLoading = true;
		// If individual explore type, get the filed funds flag to filter applicable operating results analysis
		const availableOperatingResults = this.exploreType === ExploreType.individual && this.mcag
			? this.fitApi.getAvailableOperatingResults(this.mcag)
			: of(null);
		availableOperatingResults.subscribe(result => {
			this.availableReports = REPORTS
				.filter(this.beta)
				.filter(this.supportedExploreTypes)
				.filter(this.supportedReportType)
				.filter(report => this.financialStatements(report, result));
			this.deselectInvalidReport();
			this.isLoading = false;
		});
	}

	private deselectInvalidReport() {
		if (!this.availableReports.some(report => report.id === this.selectedReportId)) {
			this.selectBox.value = null;
		}
	}

	/**
	 * Remove beta features when showBetaForUser is not true.
	 * @param report
	 */
	private beta = (report: Report): boolean => report.beta === true ? this.showBetaForUser : true;

	/**
	 * Remove reports that are not supported by the provided ExploreTypes.
	 * @param report
	 */
	private supportedExploreTypes = (report: Report): boolean => report.supportedExploreTypes.includes(this.exploreType);

	/**
	 * Remove reports that are not supported by the provided ReportType.
	 * @param report
	 */
	private supportedReportType = (report: Report): boolean => report.supportedReportType === this.reportType;

	/**
	 * If we are an Operating Results Analysis Report, make sure the appropriate filed funds are present.
	 * @param report
	 * @param available
	 */
	private financialStatements = (report: Partial<Report>, available: AvailableOperatingResultsAnalyses): boolean => {
		if (report.id === 'operatingResultsAnalysisEnterprise') {
			return available?.enterprise === true;
		}
		else if (report.id === 'operatingResultsAnalysisGovernmental') {
			return available?.governmental === true;
		}

		return true;
	}

}
