import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, share, throwError} from 'rxjs';
import {catchError, map, shareReplay, skip, skipWhile, switchMap, takeWhile, tap} from 'rxjs/operators';
import {LoggerService} from './logger.service';
import {UserInterfaceService} from './user-interface.service';
import {SnapshotDetail} from '../../modules/api/fit-api/models/snapshot';
import {User} from '../models/user';
import {UserService} from './user.service';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {IndicatorName} from '../../modules/api/fit-api/models/snapshots/indicator-name';
import {CodeDescription} from '../../modules/api/fit-api/models/code-description';
import {ShareInProgress} from '../../modules/decorators/share-in-progress';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';
import {ODataResult} from '../../modules/api/odata-result';
import {ODataCollectionResult} from '../../modules/api/odata-collection-result';

@Injectable({
	providedIn: 'root'
})
export class SnapshotService {
	public latestId: SnapshotId;
	private snapshots: BehaviorSubject<Array<any>>;
	private _snapshots: Array<any>                        = [];
	private snapshotsDetails: Array<BehaviorSubject<any>> = [];

	private schoolsDetails: BehaviorSubject<any>;

	constructor(
		private http: HttpClient,
		private logger: LoggerService,
		private uiService: UserInterfaceService,
		private userService: UserService,
		private apiService: FitApiService,
	) {
	}

	/**
	 * @deprecated A significant amount of logic in this service is handled (better) by FitApiService. Remove
	 *  this old logic in favor of making calls directly to those methods. E.g. sorting is already performed
	 * 	on the odata call by $orderby=id desc. Caching behavior is also implemented in FitApiService with a private
	 * 	array and shareReplay on each object within the array.
	 *
	 * Can call subscribe or value; initial value is null
	 */
	getSnapshots(): BehaviorSubject<Array<any>> {
		if (!this.snapshots) {
			this.snapshots = new BehaviorSubject<Array<any>>(null); // initial value
			this.uiService.isSnapshotLoading.next(true);
			const url = this.apiService.getAnnualFilingRoute();

			this.http.get(url).pipe(
				map((v: any) => v.value), // snapshots are located in OData .value property
				tap(v => this.setLatestId(v)), // set the latestId any time this runs
			).subscribe(result => {
				const snapshots = result;

				this.uiService.isSnapshotLoading.next(false);
				this._snapshots = snapshots.reverse();
				this.snapshots.next(this._snapshots);
			});
		}

		return this.snapshots;
	}

	/**
	 * MSL 20200424 - Previously deprecated... Not quite that simple. The PivotGrid needs to be able to make
	 * *synchronous* lookups against the snapshot detail. Calling this method first puts the result in a BehaviorSubject
	 * so that the PivotGrid sorting/customizeText/selector functions can perform lookups.
	 * old deprecation note:
	 * Should go straight to FitApiService, which leverages its own ReplaySubject
	 * Public interface, ensures getSnapshots is called first so that latestId is populated
	 * Can call subscribe or value; initial value is null
	 *
	 * @param id
	 */
	getSnapshot(id?: SnapshotId): BehaviorSubject<any> {
		if (id) {
			return this.getSnapshotObservable(id);
		}

		// if no id provided, ensure getSnapshots has populated this.latestId before creating the snapshotObservable
		const observable = this.getSnapshots()
			.pipe(
				// mergeMap allows mapping values into next observable
				// no value is needed as getSnapshots' tap handles setting this.latestId
				skipWhile(val => val === null), // do not respond to initial null value
				switchMap(() => this.getSnapshotObservable()),
			);

		return <BehaviorSubject<any>>observable;
	}

	getCollection(snapshotId: SnapshotId, path: string) {
		const snapshot = this.getSnapshot(snapshotId).value;
		if (snapshot == null) {
			this.logger.warn(`SnapshotService: snapshot is null: ${snapshotId}. Has it been loaded from the server yet?`);
		}
		const collection = snapshot?.detail[path];
		if (!collection || !Array.isArray(collection)) {
			this.logger.warn(`SnapshotService: ${path} was not found on snapshot ${snapshotId}`);
		}
		return collection;
	}

	findIndicatorName = (
		snapshotId: SnapshotId,
		name: string,
		filingBasis: FilingBasis,
		reportId: 'governmentalIndicators' | 'enterpriseIndicators'
	): IndicatorName => {
		if (!name || !filingBasis || !reportId) {
			this.logger.error(`name, filingBasis and reportId are required to use this function.`);
		}
		const indicatorNames = this.getCollection(snapshotId, 'indicatorNames');
		const ODataReportName = reportId.charAt(0).toUpperCase() + reportId.slice(1);
		return indicatorNames.find(x => {
			return x.filingBasis === filingBasis.name
			&& x.report === ODataReportName
			&& x.name === name;
		});
	};

	// returns the requested data record
	findObject(snapshotId: SnapshotId, path: string, keyValue: any, keyName = 'id'): any {
		const collection = this.getCollection(snapshotId, path);
		if (typeof collection[0][keyName] === 'undefined') {
			this.logger.warn(`SnapshotService: key ${keyName} was not found on object.`);
			return null;
		}

		return collection.find(v => v[keyName] === keyValue);
	}

	// Retrieves the observable from the cache or creates a new one
	private getSnapshotObservable(id?: SnapshotId): BehaviorSubject<any> {
		id = id || this.latestId;
		// Cache observable
		if (!this.snapshotsDetails[id]) {
			this.uiService.isSnapshotLoading.next(true);
			this.snapshotsDetails[id] = new BehaviorSubject<any>(null); // initial value
			const url                 = this.apiService.getAnnualFilingRoute(id, '?$expand=detail');
			this.http.get<ODataResult<any>>(url).pipe(
				// .retryWhen() TODO do we want to retry in the ErrorEvent case?
				catchError(this.apiService.handleUnauthorized)
			).subscribe(
				result => {
					this.uiService.isSnapshotLoading.next(false);
					this.snapshotsDetails[id].next(result);
					// can't do this here because it interferes with skipWhile, above
					// this.snapshotsDetails[id].complete();
				}
			);
		}

		return this.snapshotsDetails[id];
	}

	// Retrieves the observable from the cache or creates a new one
	private getSchoolsObservable(): BehaviorSubject<any> {
		// Cache observable
		if (!this.schoolsDetails) {
			this.uiService.isSnapshotLoading.next(true);
			this.schoolsDetails = new BehaviorSubject<any>(null); // initial value
			const url           = this.apiService.getOSPIRoute(`?$expand=detail`);
			this.http.get<ODataResult<any>>(url).pipe(
				// .retryWhen() TODO do we want to retry in the ErrorEvent case?
				catchError(this.apiService.handleUnauthorized)
			).subscribe(
				result => {
					this.uiService.isSnapshotLoading.next(false);
					this.schoolsDetails.next(result);
					// can't do this here because it interferes with skipWhile, above
					// this.schoolsDetails.complete();
				}
			);
		}

		return this.schoolsDetails;
	}

	getSchools = () => this.getSchoolsObservable();

	getOSPICollection(path: string) {
		const schools = this.getSchoolsObservable().value;
		if (schools == null) {
			this.logger.warn(`SnapshotService: schools is null. Has it been loaded from the server yet?`);
		}
		const collection = schools?.detail[path];
		if (!collection || !Array.isArray(collection)) {
			this.logger.warn(`SnapshotService: ${path} was not found on schools`);
		}
		return collection;
	}

	private setLatestId(snapshots) {
		this.latestId = this.findLatestId(snapshots);
	}

	private findLatestId(snapshots): number {
		return Math.max(...Array.from(snapshots, (o: any) => o.id));
	}

	@ShareInProgress
	getYearsWithFullExtracts (snapshotId: number) {
		const url = this.apiService.getAnnualFilingRoute(snapshotId);
		return this.http.get<ODataCollectionResult<number>>(`${url}/GetFullExtractYears`).pipe(map((result) => result.value));
	}

}
