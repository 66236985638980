import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/route-actions/home/home.component';
import {NewShareUrlTabComponent} from './components/route-actions/new-share-url-tab/new-share-url-tab.component';
import {NewGovernmentProfileTabComponent} from './components/route-actions/new-government-profile-tab/new-government-profile-tab.component';
import {NewGovernmentTypeProfileTabComponent} from './components/route-actions/new-government-type-profile-tab/new-government-type-profile-tab.component';
import {GovernmentTearSheetComponent} from './modules/info/government-tear-sheet/government-tear-sheet.component';
import {GovernmentTypeTearSheetComponent} from './modules/info/government-type-tear-sheet/government-type-tear-sheet.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AnnualFilingStatusTrendComponent} from './modules/filings/annual-filing-status-trend/annual-filing-status-trend.component';
import {
	AnnualFilingCurrentProgressComponent
} from './modules/filings/annual-filing-current-progress/annual-filing-current-progress.component';
import {
	AnnualFilingStandaloneFilingStatisticsComponent
} from './modules/filings/annual-filing-standalone-filing-statistics/annual-filing-standalone-filing-statistics.component';

const appRoutes: Routes = [
	{path: '', component: DashboardComponent},
	{path: 'share/:hash', component: NewShareUrlTabComponent},
	{path: 'government-profile/:mcag/:snapshotId', component: NewGovernmentProfileTabComponent},
	{path: 'government-profile/:mcag', component: NewGovernmentProfileTabComponent},
	{path: 'government-type-profile/:govTypeCode/:snapshotId', component: NewGovernmentTypeProfileTabComponent},
	{path: 'government-type-profile/:govTypeCode', component: NewGovernmentTypeProfileTabComponent},
	{path: 'government-tear-sheet/:mcag/:snapshotId', component: GovernmentTearSheetComponent},
	{path: 'government-tear-sheet/:mcag', component: GovernmentTearSheetComponent},
	{path: 'government-type-tear-sheet/:govTypeCode/:snapshotId', component: GovernmentTypeTearSheetComponent},
	{path: 'government-type-tear-sheet/:govTypeCode', component: GovernmentTypeTearSheetComponent},
	{path: 'annual-filing-status-trend', component: AnnualFilingStatusTrendComponent},
	{path: 'annual-filing-status-trend/gov-type-code/:govTypeCode', component: AnnualFilingStatusTrendComponent},
	{path: 'annual-filing-status-trend/county-code/:countyCode', component: AnnualFilingStatusTrendComponent},
	{path: 'annual-filing-status-trend/county-name/:countyName', component: AnnualFilingStatusTrendComponent},
	{path: 'annual-filing-current-progress', component: AnnualFilingCurrentProgressComponent},
	{path: 'annual-filing-current-progress/gov-type-code/:govTypeCode', component: AnnualFilingCurrentProgressComponent},
	{path: 'annual-filing-standalone-filing-statistics', component: AnnualFilingStandaloneFilingStatisticsComponent},
	{path: 'annual-filing-standalone-filing-statistics/gov-type-code/:govTypeCode', component: AnnualFilingStandaloneFilingStatisticsComponent}
];

@NgModule({
	declarations: [],
	imports:      [
		CommonModule,
		RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
	],
	exports:      [
		RouterModule
	]
})
export class AppRoutingModule {
}
