import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { LoggerService } from 'app/shared/services/logger.service';

@Component({
	selector: 'app-checkbox-grid',
	templateUrl: './checkbox-grid.component.html',
	styleUrls: ['./checkbox-grid.component.scss']
})
export class CheckboxGridComponent implements OnInit {
	@Input() store: Array<any>;
	@Input() selections: Array<any>;
	/** 
	 * If key is defined, selections are assumed to be an array of keys, and component will
	 * emit an array of keys in return
	 */
	@Input() key: string;
	@Input() displayExpr: (item: any) => Function;
	@Output() changed = new EventEmitter<any>();

	constructor(private logger: LoggerService) { }

	ngOnInit() {
		this.logger.log(this);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.logger.log(changes);
	}

	isSelected = (item: any) => {
		// if null or empty, no need to evaluate
		if(!Array.isArray(this.selections) || this.selections.length === 0) {
			return false;
		}
		// if key is defined, expect selections to only be keys
		// otherwise compare whole object
		const isSelected = this.key
			? this.selections.findIndex(x => x === item[this.key]) > -1
			: this.selections.includes(item);
		return isSelected;
	}

	checkItem = (item: any, event: any) => {
		// make sure selections is an array
		let result = Array.isArray(this.selections) ? this.selections.slice() : [];
		const indexInSelections = this.key
			? this.selections.findIndex(i => i === item[this.key])
			: this.selections.findIndex(i => i === item);
		if(event.value === false) {
			// uncheck
			if (indexInSelections > -1) {
				// make a copy without affecting source array
				result.splice(indexInSelections, 1);
			} else {
				// do nothing if selection not found
			}
		} else if(event.value === true) {
			// check
			if (indexInSelections === -1) {
				const value = this.key ? item[this.key] : item;
				result.push(value);
			} else {
				// do nothing if already present
			}
		} else {
			// do nothing for undefined
		}
		
		this.changed.emit(result);
	}

}
