import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {
	DxPopoverModule,
	DxTemplateModule,
	DxTextBoxModule,
	DxLoadPanelModule,
	DxTagBoxModule,
	DxPopupModule,
	DxCheckBoxModule,
	DxButtonModule,
	DxVectorMapModule,
	DxDataGridModule,
	DxSelectBoxModule,
	DxScrollViewModule,
	DxLookupModule,
	DxTreeViewModule,
	DxRangeSliderModule,
	DxSliderModule,
	DxProgressBarModule,
	DxToastModule,
	DxTooltipModule, DxLoadIndicatorModule, DxValidatorModule, DxListModule,
} from 'devextreme-angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {GovernmentTypesComponent} from './government-types/government-types.component';
import {CheckboxGridComponent} from './checkbox-grid/checkbox-grid.component';
import {LocationsComponent} from './locations/locations.component';
import {SingleGovernmentChooserComponent} from './single-government-chooser/single-government-chooser.component';
import {PeerSearchComponent} from './peer-search/peer-search.component';
import {TagCloudComponent} from './tag-cloud/tag-cloud.component';
import {MultiGovernmentChooserAdvancedComponent} from './multi-government-chooser-advanced/multi-government-chooser-advanced.component';
import {MultiGovernmentChooserNoviceModule} from './multi-government-chooser-novice/multi-government-chooser-novice.module';
import {FilterLauncherComponent} from './filter-launcher/filter-launcher.component';
import {DxFormatPipe} from 'app/shared/pipes/dx-format.pipe';
import {UniquePipe} from 'app/shared/pipes/unique.pipe';
import {ExceptPipe} from 'app/shared/pipes/except.pipe';
import {EnsureArrayPipe} from 'app/shared/pipes/ensure-array.pipe';
import {YearsComponent} from './years/years.component';
import {GovernmentTypeChooserComponent} from './government-type-chooser/government-type-chooser.component';
import {PrimaryGovernmentPipe} from '../pipes/primary-government.pipe';
import {AccountChooserFreeformComponent} from './account-chooser-freeform/account-chooser-freeform.component';
import {HintModule} from '../../modules/ui/hint/hint.module';
import {TextSearchModule} from 'app/shared/components/text-search/text-search.module';
import {LauncherComponent} from './launcher/launcher.component';
import {AccountChooserComponent} from './account-chooser/account-chooser.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {ToastComponent} from './toast/toast.component';
import {HintComponent} from '../../modules/ui/hint/hint.component';
import {GovernmentMapComponent} from './government-map/government-map.component';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';
import {TotalAmountPipe} from '../pipes/total-amount.pipe';
import {DataStoriesComponent} from './data-stories/data-stories.component';
import {TabBarComponent} from 'app/shared/components/tab-bar/tab-bar.component';
import {ScrollableShadowDirective} from '../directives/scrollable-shadow.directive';
import {BarsDescriptionModule} from '../../modules/ui/bars-description/bars-description.module';
@NgModule({
	imports: [
		CommonModule,
		DxPopoverModule,
		DxTemplateModule,
		DxTextBoxModule,
		DxLoadPanelModule,
		DxTagBoxModule,
		DxPopupModule,
		DxCheckBoxModule,
		DxButtonModule,
		DxVectorMapModule,
		DxDataGridModule,
		DxSelectBoxModule,
		DxScrollViewModule,
		DxLookupModule,
		DxTreeViewModule,
		DxSliderModule,
		DxTooltipModule,
		DxProgressBarModule,
		FontAwesomeModule,
		MultiGovernmentChooserNoviceModule,
		HintModule,
		TextSearchModule,
		DxRangeSliderModule,
		DxToastModule,
		NgxMapboxGLModule,
		DxValidatorModule,
		DxListModule,
		BarsDescriptionModule
	],
	declarations: [
		GovernmentTypesComponent,
		CheckboxGridComponent,
		LocationsComponent,
		SingleGovernmentChooserComponent,
		PeerSearchComponent,
		TagCloudComponent,
		MultiGovernmentChooserAdvancedComponent,
		FilterLauncherComponent,
		YearsComponent,
		GovernmentTypeChooserComponent,
		AccountChooserFreeformComponent,
		LauncherComponent,
		AccountChooserComponent,
		ProgressBarComponent,
		ToastComponent,
		GovernmentMapComponent,
		DataStoriesComponent,
		TotalAmountPipe,
		TabBarComponent,
		ScrollableShadowDirective
	],
	exports:      [
		CheckboxGridComponent,
		PeerSearchComponent,
		YearsComponent,
		GovernmentTypeChooserComponent,
		AccountChooserFreeformComponent,
		GovernmentTypesComponent,
		LocationsComponent,
		AccountChooserFreeformComponent,
		MultiGovernmentChooserNoviceModule,
		MultiGovernmentChooserAdvancedComponent,
		LauncherComponent,
		AccountChooserComponent,
		SingleGovernmentChooserComponent,
		TextSearchModule,
		ProgressBarComponent,
		ToastComponent,
		HintComponent,
		GovernmentMapComponent,
		DataStoriesComponent,
		TotalAmountPipe,
		TabBarComponent,
		TabBarComponent,
		ScrollableShadowDirective
	],
	providers:    [
		DxFormatPipe,
		UniquePipe,
		ExceptPipe,
		EnsureArrayPipe,
		PrimaryGovernmentPipe,
		DecimalPipe,
		TotalAmountPipe
	]
})
export class SharedComponentsModule {
}
