import { Component, TemplateRef, ViewChild } from '@angular/core';
import {DxPopupComponent} from 'devextreme-angular';
import {UserService} from '../../shared/services/user.service';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {LoggerService} from '../../shared/services/logger.service';
import {UtilityService} from '../../shared/services/utility.service';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {User} from 'app/shared/models/user';

@Component({
	selector:    'app-first-time',
	templateUrl: './first-time.component.html',
	styleUrls:   ['./first-time.component.scss']
})
export class FirstTimeComponent {

	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
	@ViewChild('content') content: TemplateRef<any>;

	user: User;
	showTechnical: boolean;
	autoUpdateSnapshots: boolean;

	isVisible = false;
	icons     = {
		infoCircle: faInfoCircle
	};

	constructor(
		private userService: UserService,
		private uiService: UserInterfaceService,
		private logger: LoggerService,
		private utility: UtilityService,
	) {
		this.uiService.firstTime.subscribe(value => this.isVisible = value);
		this.userService.user.subscribe(this.onUserUpdated);
	}

	onUserUpdated = (user: User) => {
		this.user                = Object.assign({}, user);
		// coerce undefined
		this.showTechnical       = this.user.showTechnical || false;
		this.autoUpdateSnapshots = this.user.autoUpdateSnapshots || true;
	}

	close(event) {
		this.userService.setShowTechnical(this.showTechnical);
		this.userService.setAutoUpdateSnapshots(this.autoUpdateSnapshots);
		this.isVisible = false;
	}

}
