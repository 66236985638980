import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {environment} from '../../../environments/environment';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {catchError, filter, skipWhile,  take} from 'rxjs/operators';
import {forkJoin, Observable, Subscription} from 'rxjs';
import notify from 'devextreme/ui/notify';
import {FilingMetric} from '../../modules/api/fit-api/models/filing-metrics';
import {FilingStatusService} from '../../modules/services/filing-status-service/filing-status.service';
import {FilingCondition, FilingStatus} from '../../modules/api/fit-api/models/filing-status';
import {GovernmentMetric} from '../../modules/api/fit-api/models/government-metric';
import CustomStore from 'devextreme/data/custom_store';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {FormatService} from '../../shared/services/format.service';
import {FormatDateTimeFlag, UtilityService} from '../../shared/services/utility.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';
import {Snapshot} from '../../modules/api/fit-api/models/snapshot';

@Component({
	selector:    'app-publish-lgcs-snapshot',
	templateUrl: './publish-lgcs-snapshot.component.html',
	styleUrls:   ['./publish-lgcs-snapshot.component.scss']
})
export class PublishLgcsSnapshotComponent implements OnInit, OnDestroy {

	public isVisible: boolean;
	public filingStatuses: Array<{
		total: number;
		year: number;
		label: string;
		snapshots: Array<Snapshot>;
	}>                           = [];
	public toggleNextButton 	 = false;
	public jobQueueUrl           = `${environment.base}/hangfire/jobs/processing`;
	public snapshots: Array<any>;
	public disableBaselineToggle = true;

	public getTimeSubscription: Subscription;
	public getSnapshotSubscription: Subscription;
	public getFilingStatusesSubscription: Subscription;

	private dateTimeFlagDay  = FormatDateTimeFlag.Day;
	private dateTimeFlagDate = FormatDateTimeFlag.Date;
	private dateTimeFlagTime =  FormatDateTimeFlag.Time;

	public state: 'inputs' | 'confirmation' | 'complete' = 'inputs';

	public milestone: {
		snapshotName?: string,
		snapshotCode?: string,
		description?: string,
	} = {};

	public formFields: {
		barsYear?: number,
		baseline: boolean,
		publishingComments?: string,
	} = {
		baseline: true,
	};

	public icons = {
		infoCircle: faInfoCircle
	};

	selectedBarsYear: number;
	selectedSnapshot?: any;
	selectedSnapshotId?: SnapshotId;
	totalRevenuesForPublishingSnapshot: number = null;
	totalFilersForPublishingSnapshot: number = null;

	// Observable used to feed stats table pivot grid
	dataSource = new Observable<Array<FilingMetric>>(subscriber => {
		forkJoin([this.fitApi.getFilingStatuses(undefined, this.selectedSnapshotId),
			this.fitApi.getGovernmentMetrics(undefined, this.selectedSnapshotId),
			this.fitApi.getFilingStatuses(undefined, 'live'),
			this.fitApi.getGovernmentMetrics(undefined, 'live'),
			// this caches snapshot detail - used for gov type code customize text function in pivot grid
			this.snapshotService.getSnapshot(this.selectedSnapshotId).pipe(skipWhile(x => x === null), take(1))
		])
			.pipe(catchError(e => {
				notify('There was an error loading the stats table pivot grid.', 'error', 3000);
				return e;
			}))
			.subscribe(([filingStatusesSnapshot, metricsSnapshot, filingStatusesLive, metricsLive]) => {
				// handles live filing data up to and including the selected BARS year
				filingStatusesLive = filingStatusesLive.filter(( obj ) => obj.year <= this.selectedBarsYear);

				// creates Filing Metric arrays
				const liveFilingMetrics: Array<FilingMetric> = this.mergeFilingMetrics(filingStatusesLive, metricsLive);
				const snapshotFilingMetrics: Array<FilingMetric> = this.selectedSnapshotId ? this.mergeFilingMetrics(filingStatusesSnapshot, metricsSnapshot) : [];

				// adds 'Live' tag to live filing status snapshot id
				liveFilingMetrics.forEach(x => x.snapshotId = 'live');

				// aggregate totals to publish
				const publishYearFilingMetrics = liveFilingMetrics.filter(( obj ) => obj.year === this.selectedBarsYear);
				this.totalRevenuesForPublishingSnapshot = publishYearFilingMetrics.reduce(function (acc, obj) { return obj.totalRevenues ? acc + obj.totalRevenues : acc; }, 0);
				this.totalFilersForPublishingSnapshot = publishYearFilingMetrics.filter(obj => (this.filingStatusService.filerConditions.includes(obj.filingCondition) && obj.pendingUpdates === false)).length;

				// concatenates Filing Metric arrays used for pivot grid
				const filingMetrics: Array<FilingMetric> = liveFilingMetrics.concat(snapshotFilingMetrics);

				subscriber.next(filingMetrics);
				subscriber.complete();

			});
	});

	pivotGridDataSource = new PivotGridDataSource ({
		fields: [{
			caption: 'Snapshot Id',
			dataField: 'snapshotId',
			area: 'row',
			summaryType: 'sum',
			sortOrder: 'desc',
			customizeText:  (data) => {
				if (data.value === 'live') {
					return 'Live';
				} else {
					const dateTimeCreated = new Date (this.selectedSnapshot.dateCreated);
					return `Snapshot - ${this.selectedSnapshot.barsYearUsed} (${this.utilityService.formatDateTimeToPacificTimeZone(dateTimeCreated, this.dateTimeFlagDate)}; Id: ${this.selectedSnapshot.id})`;
				}
			}
		}, {
			caption: 'Year',
			dataField: 'year',
			area: 'row',
			sortBySummaryField: 'snapshotId',
			sortOrder: 'desc'
		},
			{
				caption: 'Gov Type',
				dataField: 'govTypeCode',
				area: 'row',
				sortBySummaryField: 'year',
				sortOrder: 'asc',
				customizeText:  (data) => {
					return this.formatService.getNameForGovtTypeCode(data, undefined, this.selectedSnapshotId);
				}
			},
			{
				area: 'column',
				caption: 'Filers',
				selector() {
					return '';
				}
			},
			{
				area: 'data',
				caption: '# of Filers',
				summaryType: 'custom',
				calculateCustomSummary: (options) => {
					switch (options.summaryProcess) {
						case 'start':
							options.totalValue = 0;
							break;
						case 'calculate':
							if (this.filingStatusService.filerConditions.includes(options.value.filingCondition)) {
								options.totalValue++;
							}
							break;
					}
				}
			},
			{
				area: 'data',
				caption: '# w/ Pending Updates²',
				summaryType: 'custom',
				calculateCustomSummary: (options) => {
					switch (options.summaryProcess) {
						case 'start':
							options.totalValue = 0;
							break;
						case 'calculate':
							if (this.filingStatusService.filerConditions.includes(options.value.filingCondition) && options.value.pendingUpdates === true) {
								options.totalValue++;
							}
							break;
					}
				}
			},
			{
				area: 'data',
				caption: '# of Filers w/Data² ',
				summaryType: 'custom',
				calculateCustomSummary: (options) => {
					switch (options.summaryProcess) {
						case 'start':
							options.totalValue = 0;
							break;
						case 'calculate':
							if (this.filingStatusService.filerConditions.includes(options.value.filingCondition) && options.value.pendingUpdates === false) {
								options.totalValue++;
							}
							break;
					}
				}
			},
			{
				caption: 'Total Revenue¹',
				dataField: 'totalRevenues',
				dataType: 'number',
				summaryType: 'sum',
				format: {
					type: 'currency largeNumber',
					precision: 1,
					currency: 'USD'
				},
				area: 'data',
			},
			{
				area: 'data',
				caption: '# of Non filers',
				summaryType: 'custom',
				calculateCustomSummary: (options) => {
					switch (options.summaryProcess) {
						case 'start':
							options.totalValue = 0;
							break;
						case 'calculate':
							if (options.value.filingCondition === FilingCondition.None) {
								options.totalValue++;
							}
							break;
					}
				}
			}],
		store: new CustomStore ({
			load: (loadOptions) => {
				return this.dataSource.toPromise();
			}
		})
	});

	constructor(
		private ui: UserInterfaceService,
		private fitApi: FitApiService,
		private snapshotService: SnapshotService,
		private filingStatusService: FilingStatusService,
		private formatService: FormatService,
		private utilityService: UtilityService,
	) {
		this.ui.publishLgcsSnapshot$.subscribe(x => this.isVisible = x);
	}

	/**
	 * Fetch snapshots and fetch the latest filing status years with total filings
	 */
	ngOnInit(): void {
		this.getSnapshotSubscription = this.snapshotService.getSnapshots()
			.pipe(filter(s => s !== null))
			.subscribe(snapshots => {
				this.snapshots = snapshots;

				this.getFilingStatusesSubscription = this.fitApi.getFilingStatusesYearsAndTotals().subscribe(data => {
					this.filingStatuses = data.map(fs => ({
						total: fs.total,
						year: fs.year,
						label: `${fs.year} [${fs.total} ${(fs.total === 1) ? 'filing' : 'filings'}]`,
						snapshots: this.snapshots.filter(s => s.barsYearUsed === fs.year)
					}));

				});
			});
	}

	/**
	 * Reset and calculate formFields
	 */
	updateFilingStatusSelection = (): void => {
		this.getTimeSubscription = this.fitApi.getTime().subscribe(dateTimeCreated => {

			const today = new Date(dateTimeCreated);

			this.milestone.snapshotCode = null;
			this.milestone.snapshotName = null;
			this.milestone.description  = null;
			const year                  = this.formFields.barsYear;
			this.selectedBarsYear   	= year;

			const year10Ago             = year - 10;
			const filingStatus          = this.filingStatuses?.find(b => b.year === year);

			if (filingStatus) {

				this.milestone.snapshotCode = `MILE${this.formFields.barsYear}`;
				this.disableBaselineToggle  = filingStatus.snapshots.length === 0;

				// If there are no other snapshots for the year selected, baseline must be true, otherwise allow the user to toggle
				if (this.disableBaselineToggle) {
					this.formFields.baseline = true;
				}

				const createdOrRevised = this.disableBaselineToggle ? 'created' : 'revised';

				this.milestone.snapshotName = `${filingStatus.year} Filing Milestone Snapshot (${createdOrRevised} ${this.utilityService.formatDateTimeToPacificTimeZone(today, this.dateTimeFlagTime)})`;

				this.milestone.description =
					`This ${year} milestone snapshot contains annual report data submitted to the State Auditor’s Office by local governments as of ` +
					`${this.utilityService.formatDateTimeToPacificTimeZone(today, this.dateTimeFlagDay)}. Milestone snapshots are published after periods of significant annual report filing activity. Typically, this occurs shortly ` +
					`after filing deadlines which vary by local government according to their adopted fiscal calendar.  The data in this snapshot includes ` +
					`annual reports from ${year10Ago}-${year} and is structured according to the ${year} BARS Manual.` +
					`for all local governments.`;

				// clear selected snapshot, disable next button until reload() successfully fires
				this.selectedSnapshotId = null;
				this.selectedSnapshot = null;
				this.toggleNextButton = false;

				// select most recent snapshot
				if (filingStatus.snapshots.length !== 0) {
					this.selectedSnapshot = filingStatus.snapshots.reduce(function (prev, current) {
						return (prev.id > current.id) ? prev : current;
					});
					this.selectedSnapshotId = this.selectedSnapshot.id;
				}

				// load pivot grid, when promise returns, toggle 'next' button
				this.pivotGridDataSource.reload().then(r => this.toggleNextButton = true);

			}
		});
	};

	next = (): string => this.state = 'confirmation';
	back = (): string => this.state = 'inputs';

	/**
	 * POST data to the PublishNewSnapshot endpoint and handle errors
	 */
	submit = (): Subscription =>
		this.fitApi.publishNewSnapshot({
			publishBARSYear: this.formFields.barsYear,
			milestoneCode: this.milestone.snapshotCode,
			milestoneName: this.milestone.snapshotName,
			description: this.milestone.description,
			// 'comment' is a non-nullable field on backend publish process
			comment: this.formFields.publishingComments ?? '',
			type: 'Milestone',
			baseline: this.formFields.baseline,
			totalRevenues: this.totalRevenuesForPublishingSnapshot,
			filersWithData: this.totalFilersForPublishingSnapshot
		})
			.pipe(catchError(e => {
				notify('There was an error submitting this LGCS Snapshot.', 'error', 3000);
				return e;
			}))
			.subscribe(() => this.state = 'complete');

	/**
	 * Reset the form. Utilized when closing the popup
	 */
	reset = (): void => {
		this.state      = 'inputs';
		this.formFields = {baseline: true};
		this.milestone  = {};
	}

	/**
	 * Returns array of filing statuses combined with government metric's revenue, based on year and mcag.
	 * This object is now typed as 'FilingMetric' (basically filing status with revenue)
	 * Note: can only pass one array at a time (either snapshot or live, can't handle both at the same time)
	 * @param filingStatuses
	 * @param metrics
	 */
	mergeFilingMetrics(filingStatuses: Array<FilingStatus>, metrics: Array<GovernmentMetric>): Array<FilingMetric> {
		const filingMetrics = [];
		filingStatuses.forEach((filingStatus) => {
			const filingMetric: FilingMetric = filingStatus;
			if (this.filingStatusService.filerConditions.includes(filingStatus.filingCondition)) {
				if (metrics.find(x => x.year === filingStatus.year && x.mcag === filingStatus.mcag)) {
					filingMetric.totalRevenues = metrics.find(x => x.year === filingStatus.year && x.mcag === filingStatus.mcag).revenues;
				} else {
					filingMetric.totalRevenues = null;
				}
			 }
			filingMetrics.push(filingMetric);
		});
		return filingMetrics;
	}

	ngOnDestroy() {
		this.getSnapshotSubscription.unsubscribe();
		this.getFilingStatusesSubscription.unsubscribe();
		this.getTimeSubscription.unsubscribe();
	}

}
