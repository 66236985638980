import {Component} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {ShapefileLoaderService} from '../shapefile-loader.service';
import {ShapefileLoaderState} from '../models/shapefile-loader-state';
import {finalize} from 'rxjs/operators';

@Component({
	selector: 'app-local-government-shape-generator',
	templateUrl: './local-government-shape-generator.component.html',
	styleUrls: ['./local-government-shape-generator.component.scss']
})
export class LocalGovernmentShapeGeneratorComponent {

	dataSource: DataSource;
	isLoading = false;
	// Use to notify the user that they will be overwriting existing records
	hasExistingRecords: boolean;
	warningIcon = faExclamationTriangle;
	vintageYear: number;
	governmentsLookup: any;

	constructor(
		private service: ShapefileLoaderService
	) {
		this.service.vintageYear.subscribe(year => {
			this.vintageYear = year;
			this.dataSource = this.service.getODataDataSource(ShapefileLoaderState.LocalGovernmentShapeGenerator, year);
			this.dataSource.store().on('loaded', this.setHasExistingRecords);
		});
		this.service.getGovernmentsLookup.subscribe(governments => this.governmentsLookup = governments);
	}

	setHasExistingRecords = (records: Array<any>): void => {
		this.hasExistingRecords = records.length > 0;
	}

	generate(): void {
		this.isLoading = true;
		this.service.generateShapes(this.vintageYear)
			.pipe(
				finalize(() => this.isLoading = false)
			)
			.subscribe(result => {
				this.dataSource.reload();
			}, error => this.service.handleError(error));
	}

}
