import { Years } from 'app/shared/models/years';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {ReportType} from '../../shared/models/report-type-menu-option';
import {ExploreType} from '../../shared/models/explore-by-option';
import {AccountingCategory} from '../../modules/services/accounting-category/models/accounting-category';

export class Composite {
	reportType: ReportType = null;
	explore: ExploreType = null;
	baselineGovernment = null;
	includedGovernments = [];
	govTypes: Array<string> = null;
	locations = [];
	report = null;
	indicatorCode = null;
	years: Years = [null, null];
	availableYears: Array<number> = [];
	funds = [];
	hasFunds = false;
	categories = [];
	hasCategories = false;
	category: AccountingCategory = null;
	expenditureObjects = [];
	hasExpenditureObjects = false;
	guideline = null;
	filingBasis: FilingBasis = null;
	indicatorNames: Array<string> = [];
}

export class Indicator {
	reportType = ReportType.indicator;
	baselineGovernment = null;
	includedGovernments = [];
	indicatorCode = null;
	years: Years = [null, null];
	availableYears: Array<number> = [];
	// guideline = null;
}

export class OSPIIndividual {
	reportType = ReportType.ospi;
	explore: ExploreType = null;
	baselineGovernment = null;
	report = null;
	years: Years = [null, null];
	availableYears: Array<number> = [];
}

export class Individual {
	reportType = ReportType.lgfrs;
	explore: ExploreType = null;
	baselineGovernment = null;
	report = null;
	years: Years = [null, null];
	availableYears: Array<number> = [];
	funds = null;
	hasFunds = false;
	categories = null;
	hasCategories = false;
	expenditureObjects = null;
	hasExpenditureObjects = false;
}

export class Comparison {
	reportType = ReportType.lgfrs;
	explore: ExploreType = null;
	baselineGovernment = null;
	includedGovernments = [];
	report = null;
	years: Years = [null, null];
	availableYears: Array<number> = [];
	funds = null;
	hasFunds = false;
	categories = null;
	hasCategories = false;
	expenditureObjects = null;
	hasExpenditureObjects = false;
}

export class GovernmentType {
	reportType = ReportType.lgfrs;
	explore: ExploreType = null;
	govTypes: Array<string> = null;
	locations = null;
	years: Years = [null, null];
	availableYears: Array<number> = [];
	funds = null;
	hasFunds = false;
	categories = null;
	hasCategories = false;
	report = null;
}

export class Category {
	reportType = ReportType.lgfrs;
	explore: ExploreType = null;
	govTypes: Array<string> = null;
	locations = null;
	years: Years = [null, null];
	availableYears: Array<number> = [];
	funds = null;
	hasFunds = false;
	category: AccountingCategory = null;
}

export class FinancialCondition {
	reportType = ReportType.lgfrs;
	explore: ExploreType = null;
	report = null;
	indicatorNames: Array<string> = [];
	filingBasis: FilingBasis = null;
	govTypes: Array<string> = null;
	years: Years = [null, null];
	availableYears: Array<number> = [];
}

export class UserFilterField {
	id: string;
	filterValues: Array<any>;
}

// Maps to the report menu model to use given Report Type and Explore By
export const REPORT_TYPE_EXPLORE_BY_MODEL_MAP = {
	'lgfrs': {
		'individual': Individual,
		'comparison': Comparison,
		'governmentType': GovernmentType,
		'category': Category,
		'financialCondition': FinancialCondition
	},
	'ospi': {
		'individual': OSPIIndividual,
	}
};
