import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndicatorComponent} from './indicator/indicator.component';
import {IndicatorGroupsComponent} from './indicator-groups/indicator-groups.component';
import {SharedComponentsModule} from '../../../shared/components/shared-components.module';
import {
	DxButtonModule,
	DxChartModule,
	DxCircularGaugeModule,
	DxSparklineModule,
	DxTooltipModule,
	DxTreeListModule
} from 'devextreme-angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {KatexModule} from 'ng-katex';
import {LoadingModule} from '../../ui/loading/loading.module';


@NgModule({
	declarations: [
		IndicatorComponent,
		IndicatorGroupsComponent
	],
	imports: [
		CommonModule,
		SharedComponentsModule,
		DxChartModule,
		DxTreeListModule,
		FontAwesomeModule,
		DxTooltipModule,
		KatexModule,
		DxCircularGaugeModule,
		DxSparklineModule,
		LoadingModule,
		DxButtonModule
	],
	exports: [
		IndicatorComponent,
		IndicatorGroupsComponent
	]
})
export class IndicatorsModule {
}
