<dx-button *ngIf="argumentField === 'filingBasis'" (click)="back()">
	Back
</dx-button>
<dx-chart
	[dataSource]="dataSource"
	[rotated]="true"
	(onPointClick)="isInteractive ? onPointClick($event) : ''"
	(onPointHoverChanged)="isInteractive ? onPointHoverChanged($event): ''"
	[stickyHovering]="false"
	[ngClass]="{ 'chart-tear': displayDensity === 'print' }"
>
	<dxo-title [text]="title">
		<dxo-subtitle [text]="displayDensity !== 'print' ? subtitle : ''"></dxo-subtitle>
	</dxo-title>
<!--	<dxo-export [enabled]="true"></dxo-export>-->
	<dxi-series valueField="good" color="#8DB153" name="Good"></dxi-series>
	<dxi-series valueField="cautionary" color="#F6B333" name="Cautionary"></dxi-series>
	<dxi-series valueField="concerning" color="#E8702D" name="Concerning"></dxi-series>
	<!-- <dxi-series valueField="indeterminate" color="#979693" name="Indeterminate"></dxi-series> -->
	<dxo-common-series-settings [argumentField]="argumentField" type="stackedBar">
		<!-- Removes filingBasis delineation in Group view -->
		<dxo-aggregation [enabled]="true" method="sum"></dxo-aggregation>
<!--		<dxo-label [visible]="true"></dxo-label>-->
	</dxo-common-series-settings>
	<dxo-argument-axis [aggregateByCategory]="true">
		<dxo-title [text]="argumentTitle"></dxo-title>
	</dxo-argument-axis>
	<dxo-value-axis [showZero]="true" [allowDecimals]="false">
		<dxo-title [text]="valueTitle"></dxo-title>
	</dxo-value-axis>
	<dxo-legend>
		<dxo-title text="Outlook"></dxo-title>
	</dxo-legend>
	<dxo-tooltip [enabled]="isInteractive" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
	<dxo-loading-indicator [enabled]="true" [show]="isLoading"></dxo-loading-indicator>
</dx-chart>
