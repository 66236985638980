<div class="u-padding">
	<h2>Welcome to FIT!</h2>

	<h4>
		Our goal is to make Washington State’s public financial data transparent and easy-to-use -- for everyone. Start exploring the data by selecting a menu item on the left...
	</h4>

	<app-data-stories
		[tabState]="tab.state"
		[defaultClickAction]="fitActions.launchWizard(tab)"
	></app-data-stories>
</div>
