<div class="uploader">
	<dx-file-uploader
		[disabled]="hasExistingRecords === undefined || isLoading"
		name="file"
		uploadMode="useForm"
		selectButtonText="Select OFM Population spreadsheet"
		[allowedFileExtensions]="['.xlsx']"
		accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		(onValueChanged)="valueChanged($event)"
	>
	</dx-file-uploader>
	<div *ngIf="populationFile">
		<div>{{ populationFile.name }}, {{ populationFile.size / 1024 / 1024 | number:'1.0-2' }}MB</div>

		<div *ngIf="hasExistingRecords === true">
			<fa-icon [icon]="warningIcon" class="warning"></fa-icon>
			Population data already exists for this vintage year. Uploading a new file will overwrite these records.
		</div>
	</div>
	<a href="https://ofm.wa.gov/washington-data-research/population-demographics/population-estimates/april-1-official-population-estimates" target="_blank" rel="noopener noreferrer">
		Visit OFM to download population estimates <fa-icon [icon]="icons.extLink"></fa-icon>
	</a>
	<div>
		<dx-button
			[disabled]="!populationFile"
			text="Upload"
			(onClick)="upload()"
		></dx-button>
	</div>
</div>
<div class="grid-container">
	<dx-data-grid
		[dataSource]="dataSource"
		[disabled]="isLoading"
		allowColumnResizing="true"
	>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-editing [allowUpdating]="true" mode="cell" startEditAction="click"></dxo-editing>
		<dxo-export [enabled]="true"></dxo-export>
		<dxi-column width="125" dataField="year" [allowEditing]="false" dataType="string" cssClass="readonly"></dxi-column>
		<dxi-column width="125" dataField="typeFilter" dataType="string" [allowEditing]="false" cssClass="readonly"></dxi-column>
		<dxi-column width="125" dataField="county" [allowEditing]="false" dataType="string" cssClass="readonly"></dxi-column>
		<dxi-column width="350" dataField="governmentName" caption="OFM Government Name" [allowEditing]="false" dataType="string" cssClass="readonly"></dxi-column>
		<dxi-column width="350" dataField="mcag" caption="SAO Government Mapping" dataType="string">
			<dxo-lookup [dataSource]="governmentsLookup" valueExpr="MCAG" displayExpr="EntityName" [allowClearing]="true"></dxo-lookup>
		</dxi-column>
		<dxi-column dataField="population" dataType="number" [allowEditing]="false" cssClass="readonly"></dxi-column>
	</dx-data-grid>
</div>
<wasao-loading [isLoading]="isLoading"></wasao-loading>
