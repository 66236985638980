<ng-template #content>
	<div [innerHTML]=disclaimer.full>
	</div>
</ng-template>

<dx-popup class="disclaimer"
	width="50%"
	height="auto"
	maxHeight="95%"
	dragEnabled=false
	[showCloseButton]=false
	title="Financial Intelligence Tool (FIT) Data Sources and Limitations"
	[(visible)]="isVisible"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<div class="disclaimer__content" *ngTemplateOutlet="content"></div>
			</div>

			<div class="app-popup__footer">
				<dx-button
					text="OK"
					type="default"
					(onClick)="closeDisclaimer()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
