import {Pipe, PipeTransform} from '@angular/core';
import {FundCategoryId} from '../models/fund-category';

@Pipe({
	name: 'fundCategoryLabel'
})
export class FundCategoryLabelPipe implements PipeTransform {

	transform(value: FundCategoryId, ...args: any[]): any {
		switch (value) {
			case FundCategoryId.governmental:
				return 'Governmental';
			case FundCategoryId.proprietary:
				return 'Enterprise';
		}

		return value;
	}

}
