import { DebtCapitalExp } from './debt-capital-exp';
import {FundCategoryId} from '../../../modules/services/fund-service/models/fund-category';
import {FinancialSummarySection} from '../../../modules/api/fit-api/models/financial-summary';
import {SnapshotId} from '../../../modules/api/fit-api/models/snapshot-like';

export class Schedule1Detail {
	id: number;
	amount: number;
	snapshotId: SnapshotId;
	entityName: string;
	mcag: string;
	govTypeCode: string;
	countyCodes: Array<number>;
	year: number;
	fundCategoryId: FundCategoryId;
	fundTypeId: number;
	fundNumber: string;
	fundName: string;
	barsAccountId: number;
	fsSectionId: number;
	expenditureObjectId: string;
	primeId: number;
	debtCapitalExp: DebtCapitalExp;

	constructor(args?: Partial<Schedule1Detail>) {
		Object.assign(this, args);
	}

	isBeginningBalance() {
		const beginningBalances = [FinancialSummarySection.beginningBalances];
		return beginningBalances.filter( ro => ro === this.fsSectionId ).length > 0;
	}

	isRevenueOrOtherIncrease() {
		const revenuesAndOtherIncreases = [FinancialSummarySection.revenues, FinancialSummarySection.otherIncreases];
		return revenuesAndOtherIncreases.filter( ro => ro === this.fsSectionId ).length > 0;
	}

	isExpenditureOrOtherDecrease() {
		const expendituresAndOtherIncreases = [FinancialSummarySection.expenditures, FinancialSummarySection.otherDecreases];
		return expendituresAndOtherIncreases.filter( ro => ro === this.fsSectionId ).length > 0;
	}

	isEndingBalance() {
		const endingBalances = [FinancialSummarySection.endingBalances];
		return endingBalances.filter( ro => ro === this.fsSectionId ).length > 0;
	}
}
