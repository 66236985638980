import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LoggerService} from '../../../shared/services/logger.service';
import {HintInformation, HintInformationType} from 'app/shared/models/hint-information';
import {GovTypes} from '../../api/external-common-service-api/models/gov-types';
import {ExternalCommonServiceApiService} from '../../api/external-common-service-api/external-common-service-api.service';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {Entities} from '../../api/external-common-service-api/models/entities';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {ReportId} from '../../../shared/models/report-id';
import {FundCategoryId} from '../../services/fund-service/models/fund-category';
import {convertSnapshotIdString, SnapshotId} from '../../api/fit-api/models/snapshot-like';

class ChildrenThatHaveData {
	financesAtAGlance: boolean = null;
	governmentalServices: boolean = null;
	enterpriseServices: boolean = null;
}

@Component({
	selector: 'app-government-type-tear-sheet',
	templateUrl: './government-type-tear-sheet.component.html',
	styleUrls: ['./government-type-tear-sheet.component.scss']
})
export class GovernmentTypeTearSheetComponent implements OnInit, OnChanges {
	@Input() govTypeCode: string;
	@Input() snapshotId: SnapshotId;

	liveGovTypeDetails: GovTypes;

	govTypeCanHaveFinancials = false;
	childrenThatHaveData = new ChildrenThatHaveData();
	isReadyToPrint = false;
	today = new Date();
	profileUrl: string;
	displayYear: number;
	financialsDatasetSource: string;

	constructor(
		private logger: LoggerService,
		private commonData: ExternalCommonServiceApiService,
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private route: ActivatedRoute,
	) {
	}

	ngOnInit() {
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.govTypeCode = params.get('govTypeCode');
			this.snapshotId = convertSnapshotIdString(params.get('snapshotId'));
			this.updateData();
		});
		this.logger.log('SNAP: ' + this.snapshotId);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.govTypeCode?.currentValue || changes.snapshotId?.currentValue) {
			this.updateData();
		}
	}

	updateData() {

		this.commonData.getGovTypeDetailsForGovType(this.govTypeCode).subscribe(
			govTypeCode => this.liveGovTypeDetails = govTypeCode
		);

		this.fitApi.getAnnualFilingSnapshot(this.snapshotId).subscribe(snapshot => {
			const govTypeFlags            = snapshot.detail.governmentTypes.find(x => x.code === this.govTypeCode);
			this.financialsDatasetSource = govTypeFlags.financialsDatasetSource;

			this.govTypeCanHaveFinancials = govTypeFlags.canHaveFinancials;
			// don't show financial section if datasource is OSPI
			if (govTypeFlags.financialsDatasetSource === 'OSPI') { this.govTypeCanHaveFinancials = false; }

			if (!this.govTypeCanHaveFinancials) {
				this.isReadyToPrint = true;
			}
		});

		this.profileUrl = `${location.origin}${location.pathname.replace('tear-sheet', 'profile')}`;
	}

	handlePrint() {
		window.print();
	}

	childHasData = (event: boolean, id: keyof ChildrenThatHaveData): void => {
		// if all keys are defined, then set isReadyToPrint to true
		this.logger.log(`${this.constructor.name} ChildrenThatHaveData`, this.childrenThatHaveData);
		this.childrenThatHaveData[id] = event;
		// iterate and check for !null
		this.isReadyToPrint = Object.keys(this.childrenThatHaveData).every(x =>
			this.childrenThatHaveData[x] !== null
		);
	};


}
