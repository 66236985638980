<dx-popup
	[title]="title"
	[(visible)]="isVisible"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<ng-container [ngSwitch]="state">
					<app-population-vintages *ngSwitchCase="service.states.Vintages"></app-population-vintages>
					<app-population-records *ngSwitchCase="service.states.Records"></app-population-records>
				</ng-container>
			</div>
			<div class="app-popup__footer">
				<dx-button
					text="Back"
					[disabled]="state === service.states.Vintages"
					(click)="back($event)"
				></dx-button>
				<dx-button
					text="Close"
					type="default"
					(click)="close()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
