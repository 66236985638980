<ng-container *ngIf="!disabledForGovType && (dataSource?.length || !hideWhenContentEmpty)">
	<dx-pie-chart
			[dataSource]="dataSource"
			[palette]="pieChartColors"
			[sizeGroup]="sizeGroup || null"
			resolveLabelOverlapping="shift"
			type="doughnut"
		>
			<dxo-animation [enabled]="isInteractive"></dxo-animation>
			<dxo-size [height]="displayDensity === 'print' ? 280 : undefined"></dxo-size>
			<dxo-legend [visible]="false"></dxo-legend>
			<dxi-series argumentField="status" valueField="count" [hoverMode]="isInteractive ? 'pointOnly' : 'none'">
				<dxo-label visible=true [customizeText]="customizeLabel">
					<dxo-connector [visible]="true" width="1"></dxo-connector>
				</dxo-label>
			</dxi-series>
		</dx-pie-chart>
		<div class="caption">
			<span class="title">Filing Statuses for {{ liveGovTypeInfo?.GovTypeDesc }} Governments</span>
			<br/>
			(FY {{ singleYearToDisplay }})
		</div>
		<ng-container *ngIf="filerReportClickAction || isInteractive">
			<br/>
			<dx-button
				text="See Filing Statuses"
				type="default"
				(click)="handleFilerReportClick()"
			>
			</dx-button>
			<br/>
		</ng-container>


</ng-container>


<wasao-loading [isLoading]="isLoading"></wasao-loading>
