import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'stringList'
})
export class StringListPipe implements PipeTransform {

	transform(array: Array<any>, prop: string = null, separator: string = ','): any {
		if (!array) {
			return '';
		}

		let text = '';
		for (let i = 0; i < array.length; i++) {
			text += prop ? array[i][prop] : array[i];
			text += i === array.length - 1 ? '' : `${separator} `;
		}

		return text;
	}

}
