export class IndicatorReportType {
	id: number;
	key: string;
	name: string;
	sortOrder: number;

	// Allows IndicatorReportType to be populated by key or id, depending on the data source
	// OData emits strings for enum values in models, but emits the underlying number when aggregated
	// Can we type this by getting the values from the const array below?
	constructor(value?: 1 | 2 | 'governmentalIndicators' | 'enterpriseFundIndicators') {
		let idOrKey: 'id' | 'key';
		switch (typeof value) {
			case 'number': idOrKey = 'id'; break;
			case 'string': idOrKey = 'key'; break;
			case 'undefined': return;
		}
		Object.assign(this, INDICATOR_REPORT_TYPES.find(x => x[idOrKey] === value));
	}
}

/**
 * Hardcoded values avoid expensive lookups.
 */
export const INDICATOR_REPORT_TYPES: Readonly<Array<IndicatorReportType>> = [
	{ id: 1, key: 'governmentalIndicators', name: 'Governmental Indicators', sortOrder: 1 },
	{ id: 2, key: 'enterpriseFundIndicators', name: 'Enterprise Fund Indicators', sortOrder: 2 }
] as const;
