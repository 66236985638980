import {EnterpriseTotalAmount} from './enterprise-total-amount';

export class TotalAmounts {
	/**
	 * The year summarized.
	 */
	year?: number;

	/**
	 * Total Revenues across all Fund Categories.
	 */
	totalRevenues?: number;

	/**
	 * Total Expenditures across all Fund Categories.
	 */
	totalExpenditures?: number;

	/**
	 * Total Revenues for Governmental Funds.
	 */
	totalGovernmentalRevenues?: number;

	/**
	 * Total Expenditures for Governmental Funds.
	 */
	totalGovernmentalExpenditures?: number;

	/**
	 * Total Revenues for all Enterprise funds.
	 */
	totalEnterpriseRevenues?: number;

	/**
	 * Total Expenditures for all Enterprise funds.
	 */
	totalEnterpriseExpenditures?: number;

	/**
	 * Enterprise/Proprietary Revenues by Fund.
	 */
	enterpriseRevenues = new Array<EnterpriseTotalAmount>();

	/**
	 * Enterprise/Proprietary Expenditures by Fund.
	 */
	enterpriseExpenditures = new Array<EnterpriseTotalAmount>();
}
