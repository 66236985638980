<ng-container *ngIf="dataSource?.length || !hideWhenContentEmpty">
	<dx-chart
		[dataSource]="displayDensity === 'print' ? dataSource?.slice(-5) : dataSource"
		[palette]="barChartColors"
		resolveLabelOverlapping="stack"
	>
		<dxo-animation [enabled]="isInteractive"></dxo-animation>
		<dxo-size [height]="displayDensity === 'print' ? 350 : undefined"></dxo-size>
		<dxi-series valueField="totalEnterpriseRevenues" name="Revenues" axis="dollars"></dxi-series>
		<dxi-series valueField="totalEnterpriseExpenditures" name="Expenditures" axis="dollars"></dxi-series>
		<dxo-common-series-settings
			argumentField="year"
			type="bar"
			[hoverMode]="isInteractive ? 'allArgumentPoints' : 'none'"
			selectionMode="allArgumentPoints"
		>
			<dxo-label
				[visible]="true"
			><dxo-format type="currency"></dxo-format>
			</dxo-label>
		</dxo-common-series-settings>
		<dxi-value-axis name="dollars">
			<dxo-format type="currency largeNumber"></dxo-format>
		</dxi-value-axis>
		<dxo-argument-axis argumentType="string">
			<dxo-label overlappingBehavior="stagger"></dxo-label>
		</dxo-argument-axis>
		<dxo-legend
			[hoverMode]="isInteractive ? 'includePoints' : 'none'"
			verticalAlignment="bottom"
			horizontalAlignment="center"
		></dxo-legend>
	</dx-chart>

	<div class="caption">
		<span class="title">{{ fundCategoryId | fundCategoryLabel }} Revenues</span> vs.<br />
		<span class="title">{{ fundCategoryId | fundCategoryLabel }} Expenditures</span><br />
	</div>
	<ng-container *ngIf="underlyingDataClickAction && isInteractive">
		<br />
		<dx-button
			text="See Underlying Data"
			type="default"
			(click)="handleUnderlyingDataClick()"
		>
		</dx-button>
		<br />
		<wasao-hint
			icon="infoCircle"
			hintInformation="seeTrendData"
		></wasao-hint>
	</ng-container>
</ng-container>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
