import {Component, OnInit, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataSourceService} from '../../shared/services/data-source.service';
import {Tab} from '../../shared/models/tab';
import {FilterBuilderService} from '../../shared/services/filter-builder.service';
import {TabService} from '../../shared/services/tab.service';
import {LoggerService} from '../../shared/services/logger.service';
import {UnitsService} from '../../shared/services/units.service';
import {FormatService} from '../../shared/services/format.service';
import {UtilityService} from '../../shared/services/utility.service';
import {CurrencyPipe, PercentPipe} from '../../../../node_modules/@angular/common';
import {ODataQueryBuilderService} from '../../shared/services/o-data-query-builder.service';
import {environment} from 'environments/environment';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {CellService} from '../../shared/services/cell.service';
import {TabState} from 'app/shared/models/tab-state';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {ShareInProgress} from '../../modules/decorators/share-in-progress';

@Component({
	selector:    'app-comparison-dialog',
	templateUrl: './comparison-dialog.component.html',
	styleUrls:   ['./comparison-dialog.component.scss']
})
export class ComparisonDialogComponent implements OnInit, OnChanges {

	@Input() summary: any  = {};
	@Input() tab: Tab;
	@Input() isVisible     = false;
	@Input() pivotGridData = null;
	@Input() event         = null;
	@Output() visible      = new EventEmitter<any>();
	current: any           = {};
	snapshot               = null;
	contextualAnnotations  = null;
	isLoading              = false;
	requestInProgress      = null;
	apiURL: string;
	targetGovernment: any;

	constructor(
		private dataSource: DataSourceService,
		private filterBuilder: FilterBuilderService,
		private tabService: TabService,
		private log: LoggerService,
		private units: UnitsService,
		private format: FormatService,
		private utilities: UtilityService,
		private currency: CurrencyPipe,
		private oDataQueryBuilder: ODataQueryBuilderService,
		private http: HttpClient,
		private snapshotService: SnapshotService,
		private cellService: CellService,
		private percentage: PercentPipe,
		private apiService: FitApiService,
	) {
		this.apiURL = environment.apis.app;
	}

	ngOnInit() {
		this.snapshotService.getSnapshot(this.tab.snapshotId).subscribe((snapshot) => {
			this.snapshot = snapshot;
		});
	}

	ngOnChanges(changes) {
		const self = this;
		// Do not process if click event has not been passed
		if (!changes.event || !changes.event.currentValue) {
			return;
		}

		this.isLoading         = true;
		this.requestInProgress = null; // cancel processing results of any other callbacks in progress

		// map clicked cell values to their respective field objects
		const clickedCells = this.cellService.getComprisingCellValues(this.event);
		// Gather items we will need later
		const current: any = this.current = {};
		current.year       = this.getYear(clickedCells);
		current.mcag       = this.getMCAG(clickedCells);
		// Callback to get govTypeCode (specifically for track d)
		this.getLocalGovernment(current.mcag).then(govRecord => {
			this.targetGovernment     = govRecord;
			current.govTypeCode       = govRecord.govTypeCode;
			current.summary           = {id: this.getSummaryId(clickedCells)};
			current.account           = {id: this.getLowestAccountId(clickedCells)};
			current.expenditureObject = {id: this.getExpenditureObjectId(clickedCells)};

			// set titles while waiting
			const dataCell                 = clickedCells.find(v => v.field.area === 'data');
			this.current.governmentName    = this.getGovernmentName(current.mcag);
			this.current.summary           = this.getSummaryObject(current.summary.id);
			this.current.account           = this.getAccountObject(current.account.id);
			this.current.expenditureObject = this.getExpenditureObject(current.expenditureObject.id);
			this.current.title             = this.getTitle();
			this.current.subtitle          = this.getSubtitle(dataCell.field.name);
			this.current.totals            = null;

			// gather Filters to use for OData calls
			const filters: any    = {};
			filters.tab           = this.getFSGroupFilter();
			filters.category      = this.getByCategoryFilters();
			filters.optionsDrawer = this.getOptionsDrawerFilters();
			// translate mcag to govTypeCode filter
			filters.column        = this.getClickEventFilters(clickedCells.filter(v => v.field.area === 'column'))
				.map(this.transformMCAGtoGovType);
			filters.row           = this.getClickEventFilters(clickedCells.filter(v => v.field.area === 'row'))
				.map(this.transformMCAGtoGovType);
			filters.required      = [
				new this.oDataQueryBuilder.Filter('year', 'eq', current.year),
				new this.oDataQueryBuilder.Filter('govTypeCode', 'eq', current.govTypeCode)
			];

			this.setContextualAnnotations(filters);

			this.log.info('ComparisonDialog.$onChanges gathering data', current, filters, dataCell);

			// Measure is clicked
			if (dataCell.field.name === 'measure') {
				this.requestInProgress = Date.now();
				this.getODataExtensionRequest(filters, this.requestInProgress, true).subscribe(function(result: any) {
					// Do not process unless we are the request in progress
					if (result.config.id !== self.requestInProgress) {
						return;
					}

					current.totals = self.getSimilar(result.data.value, dataCell.cellValue);
					Object.assign(self.current, current);
					self.isLoading = false;
				});
			}
			// comparison is clicked; % total scenario
			if (dataCell.field.name === 'comparison') {
				this.requestInProgress = Date.now();
				forkJoin([
					this.getODataExtensionRequest(filters, this.requestInProgress, true),
					this.getODataExtensionRequest(filters, this.requestInProgress)
				]).subscribe(function(promises: Array<any>) {
					// Do not process unless we are the request in progress
					if (promises[0].config.id !== self.requestInProgress) {
						return;
					}
					const account = promises[0].data.value;
					const summary = promises[1].data.value;

					let combined = account.map(function(aItem) {
						aItem.accountAmount = aItem.amount;
						const sItem         = summary.find(function(v) {
							return v.mcag === aItem.mcag;
						});
						aItem.summaryAmount = sItem.amount;
						delete aItem.amount;
						aItem.percentOfTotal = aItem.accountAmount / aItem.summaryAmount;
						aItem.value          = self.percentage.transform(aItem.percentOfTotal, '1.1-1');
						// aItem.value = percent;
						return aItem;
					});

					// diff = gov.percent - prime.percent
					const primePercent = combined.find(function(v) {
						return v.mcag === current.mcag;
					}).percentOfTotal;
					combined           = combined.map(function(v) {
						v.diff = v.percentOfTotal - primePercent;
						return v;
					}).sort(function(a, b) {
						// sort on absolute diff
						return Math.abs(a.diff) - Math.abs(b.diff);
					}).slice(0, 6) // top 6
						.sort(function(a, b) {
							// sort on diff
							return a.percentOfTotal - b.percentOfTotal;
						})
						.reverse()
						.map((v) => {
							v.entityName = self.getGovernmentName(v.mcag);
							return v;
						});

					current.totals = combined;
					Object.assign(self.current, current);
					self.isLoading = false;
				});
			}
		});
	}

	setContextualAnnotations = (filters) => {
		const combined = filters.row.concat(filters.column);
		this.log.info('ComparisonDialog.setContextualAnnotations', combined);
		this.contextualAnnotations = '';
		let before                 = this.summary.filters.length ? ', ' : '';
		const fundCategory         = combined.find(function(v) {
			return v.key === 'fundCategoryId';
		});
		if (fundCategory) {
			this.contextualAnnotations += before + 'Fund Category: '
				+ this.snapshot.detail.fundCategories.find(function(v) {
					return v.id === fundCategory.value;
				}).name;
			before = ', ';
		}
		const fundType = combined.find(function(v) {
			return v.key === 'fundTypeId';
		});
		if (fundType) {
			this.contextualAnnotations += before + 'Fund Type: '
				+ this.snapshot.detail.fundTypes.find(function(v) {
					return v.id === fundType.value;
				}).name;
			before = ', ';
		}
		const expObj = combined.find(function(v) {
			return v.key === 'expenditureObjectId';
		});
		if (expObj) {
			this.contextualAnnotations += before + 'Expenditure Object: '
				+ this.snapshot.detail.expenditureObjects.find(function(v) {
					return v.id === expObj.value;
				}).name;
		}
	}

	/**
	 * Generates an $http object to return transformed Schedule1 records suitable for comparison
	 * @param {object} contextFilters - an object containing arrays of Filter to process
	 * @param {number} requestId - an int representing the request id to check before perfoming post-processing
	 * @param {boolean} [includeRowFilters] - include the row filters in the query
	 * @returns {$http}
	 */
	@ShareInProgress
	getODataExtensionRequest(contextFilters, requestId, includeRowFilters = null) {
		const builder = new this.oDataQueryBuilder.ODataQueryBuilder()
			.addFilterGroup(contextFilters.tab, 'or')
			.addFilterGroup(contextFilters.category, 'and')
			.addFilterGroup(contextFilters.column, 'and')
			.addFilterGroup(contextFilters.required, 'and');

		contextFilters.optionsDrawer.forEach(function(f) {
			builder.addFilterGroup(f);
		});

		if (includeRowFilters) {
			builder.addFilterGroup(contextFilters.row, 'and');
		}

		const url = this.apiService.getAnnualFilingRoute(this.tab.snapshotId, `/Schedule1s/AmountsByMcag?$filter=` + builder.toQueryString());
		if (url.length > 2000) {
			this.log.warn('ComparisonDialog.getODataExtensionRequest: Url is too long!');
		}

		this.log.info('ComparisonDialog.getODataExtensionRequest', url);

		// return this.http.get(url, { params: { id: requestId } });
		return this.http.get(url)
			.pipe(map((v) => {
				return {
					data:   v,
					config: {
						id: requestId
					}
				};
			}));
		/* {
			method: 'GET',
			url: url,
			id: requestId
		} */
	}

	/// region getters

	getUnderlyingYearValue = (text) => {
		if (Number.isInteger(text)) {
			return Number(text);
		}

		// slice out anything after the space
		return text.indexOf(' ')
			? Number(text.split(' ')[0])
			: text;
	}

	getUnderlyingGovernmentValue = (text) => {
		const self = this;
		// track d requires a lookup to find MCAG since it will not be on the tab
		if (this.tab.track.id === 'd') {
			const result = this.snapshotService.findObject(this.tab.snapshotId, 'governments', text, 'entityNameWithDba');
			return result.mcag;
		}

		// Can only have 1 year since multigovernment tracks do not support multiyear
		const year   = this.tab.years[0];
		const result = this.tab.governments.find(function(v) {
			return self.format.getGovernmentText({mcag: v.mcag, year: year}, self.tab) === text;
		});

		return result.mcag;
	}

	getClickEventFilters = (arr) => {
		const self   = this;
		const result = arr.filter(function(v) {
			// filter out fields without dataField or are in the data area
			return v.field.dataField && v.field.area !== 'data';
		}).reduce(function(acc, value, index) {
			// collect all values that can be determined from the click
			// selector is always present as DevExpress writes its own in lieu of a user-defined callback
			// Function name for default DevExpress selector is 'fieldSelector'
			// This name changes to 'selector' when user-defined
			if (value.field.selector.name === 'fieldSelector') {
				acc.push(value);
				return acc;
			}

			// call special handler for fields with user-defined selectors
			const func = self['getUnderlying' + self.utilities.uppercaseFirst(value.field.name) + 'Value'];
			if (func) {
				value.cellValue = func(value.cellValue);
			}

			acc.push(value);
			return acc;
		}, []);

		return result.map(function(v) {
			// create a new array of Filters
			const filter = new self.oDataQueryBuilder.Filter(self.utilities.dotToSlash(v.field.dataField), 'eq', v.cellValue);
			return filter;
		});
	}

	/**
	 * Converts MCAG filter to GovTypeCode
	 * Relies on this.targetGovernment being populated by click event at call time
	 * @param item
	 * @returns {*}
	 */
	transformMCAGtoGovType = (item) => {
		if (item.key === 'mcag') {
			item.key   = 'govTypeCode';
			item.value = this.targetGovernment.govTypeCode;
		}
		return item;
	}

	/**
	 * Gets the year present in the clickedCells, or assume single year scenario and pull from tab
	 * @param clickedCells
	 * @returns {number}
	 */
	getYear = (clickedCells) => {
		let year   = 0;
		const cell = clickedCells.find(function(v) {
			return v.field.name === 'year';
		});
		if (cell) {
			year = this.getUnderlyingYearValue(cell.cellValue);
		}
		else {
			year = this.tab.years[0];
		}

		return year;
	}

	/**
	 * Get the MCAG present in clickedCells, or assume single government scenario and pull from tab
	 * @param clickedCells
	 * @returns {string}
	 */
	getMCAG = (clickedCells) => {
		let mcag  = '';
		const gov = clickedCells.find(function(v) {
			return v.field.name === 'government';
		});
		if (gov) {
			mcag = this.getUnderlyingGovernmentValue(gov.cellValue);
		}
		else {
			mcag = this.tab.governments[0].mcag;
		}

		return mcag;
	}

	/**
	 * Get govTypeCode from tab.
	 * @param mcag
	 * @returns {*}
	 */
	getLocalGovernment = (mcag): Promise<any> => {
		return new Promise((res, rej) => {
			new DataSource({
				store:  this.dataSource.getStore(this.tab.snapshotId, 'LocalGovernments'),
				filter: this.filterBuilder.single('mcag', mcag)
			}).load().then(data => {
				const record = data.find(m => m.mcag);
				res(record);
			});
		});
	}

	/**
	 * Retrieves the account.id of the lowest account present in clickedCells
	 * @param clickedCells
	 * @returns {*}
	 */
	getLowestAccountId = (clickedCells) => {
		const accounts         = ['basicAccountId', 'subAccountId', 'elementId', 'subElementId'];
		const clickedAccountId = clickedCells.reduce(function(acc, value, idx, arr) {
			const any = accounts.find(function(v) {
				return value.field.dataField // Baseline selector does not have dataField
					&& value.field.dataField.indexOf(v) > -1;
			});
			return any ? value.cellValue : acc;
		}, -1);

		// Test for track d category since account/FS Summary are removed from pivotgrid
		if (clickedAccountId === -1 && this.tab.category && this.tab.category?.isSection) {
			return this.tab.category?.id;
		}

		return clickedAccountId;
	}

	getSummaryId = (clickedCells) => {
		const summaryField = clickedCells.find(function(value) {
			return ['standardSummary', 'debtCapitalExpSummary'].includes(value.field.name);
		});

		// Test for track d category since account/FS Summary are removed from pivotgrid
		if (!summaryField?.cellValue && this.tab.category && this.tab.category?.isSection) {
			return this.tab.category?.id;
		}

		return (summaryField && summaryField.cellValue) || null;
	}

	getExpenditureObjectId = clickedCells => {
		const cell = clickedCells.find(v => v.field.id === 'expenditureObject');
		return cell && cell.cellValue;
	}

	// Combine and return non-empty arrays
	getOptionsDrawerFilters = () => {
		return [
			this.getAccountGroupFilter(),
			this.getFundGroupFilter(),
			this.getExObjGroupFilter()
		].reduce(function(acc, val) {
			if (Array.isArray(val) && val.length > 1) {
				acc.push(val);
			}
			return acc;
		}, []);
	}

	getAccountGroupFilter = () => {
		const self      = this;
		const fieldName = this.tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
			? 'functionalAccountsGroup'
			: 'DebtCapitalExpFunctionalAccountsGroup';

		const field  = this.pivotGridData.field(fieldName);
		const filter = field && field.area === 'filter' && field.filterValues;
		let levels   = ['basicAccountId', 'subAccountId', 'elementId', 'subElementId'];

		if (fieldName !== 'functionalAccountsGroup') {
			levels = levels.map(function(v) {
				return self.tab.pivotGridSettings.financialSummaryHierarchy + '/' + v;
			});
		}

		return this.getFilterArray(filter, levels);
	}

	getFSGroupFilter = () => {
		const self      = this;
		const fieldName = this.tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
			? 'standardSummary'
			: 'debtCapitalExpSummary';

		const field  = this.pivotGridData.field(fieldName);
		const filter = field && field.area === 'filter' && field.filterValues;
		let levels   = ['fSSectionId'];

		if (fieldName !== 'standardSummary') {
			levels = levels.map(function(v) {
				return self.tab.pivotGridSettings.financialSummaryHierarchy + '/' + v;
			});
		}

		return this.getFilterArray(filter, levels);
	}

	getFundGroupFilter = () => {
		const field  = this.pivotGridData.field('fundGroupFilter');
		const filter = field && field.area === 'filter' && field.filterValues;
		const levels = ['fundCategoryId', 'fundTypeId'];

		return this.getFilterArray(filter, levels);
	}

	getExObjGroupFilter = () => {
		const field  = this.pivotGridData.field('expenditureObjectFilter');
		const filter = field && field.area === 'filter' && field.filterValues;
		const levels = ['expenditureObjectId'];

		return this.getFilterArray(filter, levels);
	}

	// Combine and return non-empty arrays
	getByCategoryFilters = () => {
		return this.getFSCategoryFilter().concat(this.getAccountCategoryFilter());
	}

	// For track d, get selected FS Section category; track d does not yet support debt-capital FSSection handling,
	// but if added that logic would need to be included here
	getFSCategoryFilter = () => {
		const filter = this.tab?.category?.isSection ? [this.tab?.category?.id] : [];
		const levels = ['fSSectionId'];
		return this.getFilterArray(filter, levels);
	}

	// For track d, get selected Account category; track d does not yet support debt-capital FSSection handling,
	// but if added that logic would need to be included here
	getAccountCategoryFilter = () => {
		let filter = [];
		let levels = [];
		if (this.tab?.category && !this.tab?.category?.isSection) {
			filter = [this.tab.category.id];
			levels = [this.tab.category.barsSegment + 'Id'];
		}
		return this.getFilterArray(filter, levels);
	}

	/**
	 * Returns an array of Filters from PivotGrid filter values
	 * @param {Array} selections - array where each entry represents an array of values where the last entry is the selected value
	 * @param {Array} levels - array where each entry maps to an index of the inner arrays of selections
	 * @returns {Array.<Filter>}
	 */
	getFilterArray = (selections, levels) => {
		const self   = this;
		const result = [];
		if (!selections) {
			return result;
		}

		selections.forEach(function(selection, index, arr) {
			// level index is the last index in selection array
			// or the first item if selection is not an array
			const level = Array.isArray(selection)
				? levels[selection.length - 1]
				: levels[0];

			// value is the last item in the array
			// or just the selection if it is not an array
			const value = Array.isArray(selection)
				? selection.slice(-1)[0]
				: selection;

			result.push(new self.oDataQueryBuilder.Filter(level, 'eq', value));
		});

		return result;
	}

	/// endregion

	/// region post-processing

	/**
	 * Retrieve the closest (including prime) governments by difference from prime
	 * @param arr
	 * @param baslineValue
	 * @param {int} [count=6]
	 * @returns {Array.<*>}
	 */
	getSimilar = (arr, baslineValue, count = null) => {
		count        = count || 6;
		const result = arr.map((v) => {
			v.value = this.currency.transform(v.amount, 'USD', 'symbol', '0.0-0');
			// calculate difference
			v.diff  = v.amount - baslineValue;
			return v;
		}).sort(function(a, b) {
			// sort by absolute difference (ie, prime/0 at top)
			return Math.abs(a.diff) - Math.abs(b.diff);
		}).slice(0, count) // take top count
			.sort(function(a, b) {
				// sort again by amount
				return a.amount - b.amount;
			}).reverse()
			.map((v) => {
				v.entityName = this.getGovernmentName(v.mcag);
				return v;
			});

		this.log.info('ComparisonDialog.getSimilar', result);
		return result;
	}

	getGovernmentName = (mcag: string) => {
		let tabNameLookup = this.tab.governments?.find(v => v.mcag === mcag);
		if (tabNameLookup) {
			return tabNameLookup.entityNameWithDba;
		}
		else {
			tabNameLookup = this.snapshotService.findObject(this.tab.snapshotId, 'governments', mcag, 'mcag');
			return tabNameLookup?.entityNameWithDba || mcag;
		}
	}

	getAccountObject = (accountId) => {
		return this.snapshot.detail.accountDescriptors.find(function(account) {
			return account.id === accountId;
		});
	}

	getSummaryObject = (summaryId) => {
		return this.snapshot.detail.financialSummarySections.find(function(summary) {
			return summary.id === summaryId;
		});
	}

	getExpenditureObject = exObjId => {
		const obj = this.snapshot.detail.expenditureObjects.find(x => x.id === exObjId);
		return obj || null;
	}

	getTitle = () => {
		let t: string;
		if (this.current.account) {
			t = this.current.account.name;
		}
		else if (this.current.summary) {
			t = this.current.summary.name;
		}
		else if (this.current.expenditureObject) {
			t = this.current.expenditureObject.name;
		}
		else {
			this.log.warn(`ComparisonDialog.getTitle: Could not determine title. No BARS account, summary or expenditure object defined.`);
		}

		if (this.summary.filters) {
			t += ' *';
		}

		return t;
	}

	getSubtitle = (targetFieldName) => {
		const self = this;
		let result = 'Peers by ';

		if (targetFieldName === 'measure') {
			result += self.units.config.measures.find(function(v) {
				return v.key === self.tab.pivotGridSettings.measure;
			}).name;
			result += ' in ';
			result += self.units.config.units.find(function(v) {
				return v.key === self.tab.pivotGridSettings.unit;
			}).name;
		}
		else if (targetFieldName === 'comparison') {
			result += self.units.config.comparisons.find(function(v) {
				return v.key === self.tab.pivotGridSettings.comparison;
			}).name;
			result += ' ' + self.tab.report.name;
		}
		else {
			result = '';
			this.log.warn('ComparisonDialog.updateSubtitle: Clicked field was not measure or comparison. No logic defined.');
		}

		return result;
	}

	performFinancialSummary(event) {
		this.log.info('ComparisonDialog.performFinancialSummary', event);
		if (event.target.classList.contains('disabled')) {
			return;
		}
		// Instead of using the TabReuse Modal here, just create new tab as we promised on the comparison dialog
		this.generateTab('new');
	}

	generateTab(replaceOrNew) {
		const self   = this;
		// Create new tab for track b1 with identified similar governments
		const store  = this.dataSource.getStore(this.tab.snapshotId, 'LocalGovernments');
		const filter = this.filterBuilder.group('mcag', this.current.totals.map(function(v) {
			return v.mcag;
		}));
		// Callback to get local governments with totals.MCAGs
		new DataSource({
			store:  store,
			filter: filter
		}).load().then(function(result) {
			result.forEach(function(d) {
				d.prime = self.current.mcag === d.mcag;
			});

			if (replaceOrNew === 'replace') {
				self.tabService.delete(self.tab);
			}
			const newTab = new Tab('New Tab', TabState.lgfrs, self.tab.snapshotId);
			self.tabService.buildGovernmentBasedTab(newTab, {
				trackId:               'b1',
				reportId:              'summary',
				governments:           result,
				years:                 [self.current.year, null],
			});
			self.tabService.add(newTab);
		});
	}

	handleOnHidden() {
		this.visible.emit(false);
	}

	close() {
		this.isVisible = false;
	}

}
