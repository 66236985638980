import {Component, Input, OnInit} from '@angular/core';
import {FundCategoryId} from '../../services/fund-service/models/fund-category';
import {LoggerService} from '../../../shared/services/logger.service';
import {ReusableGovernmentAndTypeInputs} from '../../reusable/models/reusable-government-and-type-inputs';
import {ReportId} from '../../../shared/models/report-id';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {FitApiService} from '../../api/fit-api/fit-api.service';

@Component({
	selector: 'wasao-governmental-services',
	templateUrl: './governmental-services.component.html',
	styleUrls: ['./governmental-services.component.scss']
})
export class GovernmentalServicesComponent extends ReusableGovernmentAndTypeInputs implements OnInit {
	@Input() trendDataClickAction: (reportId: ReportId, displayYear?: number, fieldTransformations?: Array<any>) => void;

	reportTypes = ReportId;
	fundCategories = FundCategoryId;

	constructor(
		filingStatus: FilingStatusService,
		fitApi: FitApiService,
		logger: LoggerService
	) {
		super(filingStatus, fitApi, logger);
	}

	ngOnInit() {
	}

}
