<dx-popup
	width="50%"
	height="auto"
	title="Login"
	[dragEnabled]="false"
	[showCloseButton]="true"
	[(visible)]="isVisible">
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__inner">
			<p>
				If you are with the Office of the Washington State Auditor or a local government within Washington state, you can use your SAW or ADFS login to view your live filing data and save your session across devices.
			</p>

			<div class="actions">
				<div class="action">
					<dx-button text="Continue as a Guest User" (onClick)="continueAsGuest()"></dx-button>
				</div>
	
				<div class="action">
					<dx-button text="Login with SAW or ADFS" (onClick)="login()"></dx-button>
				</div>
			</div>
		</div>
	</div>
</dx-popup>
