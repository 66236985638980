import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export class TabBarItem {
	constructor(
		public id: string | number,
		public label: string,

		/**
		 * Not set is implicitly visible.
		 */
		public visible?: boolean) {}
}

@Component({
	selector: 'app-tab-bar',
	templateUrl: './tab-bar.component.html',
	styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent implements OnInit {

	/** The list of available options to display */
	@Input() items: Array<TabBarItem> = [];
	/** Pass the id of the TabBarOption */
	@Input() selectedId: TabBarItem['id'];
	/** This event fires when the user interacts with the widget */
	@Output() onSelect = new EventEmitter<TabBarItem['id']>();

	/** Whether to show a checkmark on the selected item or not */
	@Input() hasCheck = false;
	public checkIcon = faCheck;

	constructor() {	}

	ngOnInit() {}

	selectItem = (selectionId: TabBarItem['id']) => this.onSelect.emit(selectionId);

}
