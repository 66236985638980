import {common} from 'environments/common';

export const environment = {
	// common definitions - Note that spread operator does not work in AOT
	version: common.version,
	mapboxAccessToken: common.mapboxAccessToken,
	googleTrackingId: common.googleTrackingId,

	production: false,
	test: true,
	dev: false,
	testUserHarness: true,
	// Used in dev environments to automatically login a given SSO user given an email address
	// and GUID from SubscriptionServices table. Format: `${email}&GUID=${GUID}`
	// DO NOT SET THIS TO ANYTHING OTHER THAN NULL IN NON DEV ENVIRONMENTS
	loginUser: null,
	base: 'https://legacy.fit.thinkfellow.com',
	apis: {
		error: 'https://legacy.fit.thinkfellow.com/Error/LogJavaScriptError',
		app: 'https://legacy.fit.thinkfellow.com/api',
		externalLGData: 'https://common.fit.thinkfellow.com/ExternalLGData/api/v2',
		externalLGDataLegacy: 'https://common.fit.thinkfellow.com/ExternalLGData/api/v1',
		mapbox: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
		externalCommonService: 'https://portal.sao.wa.gov/ExternalCommonservice/api',
		auditReports: 'https://www.sao.wa.gov/reports-data/audit-reports/'
	},
	reportAnIssueEmail: 'michelle.julien@thinkfellow.com'
};
