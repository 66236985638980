import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuditKPIsComponent} from './audit-kpis.component';
import {LoadingModule} from '../../ui/loading/loading.module';

@NgModule({
	declarations: [AuditKPIsComponent],
	imports:      [
		CommonModule,
		LoadingModule,
	],
	exports:      [
		AuditKPIsComponent,
	]
})
export class AuditKPIsModule {
}
