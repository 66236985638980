<div class="sidebar-drawer" [ngClass]="tabOpen ? 'sidebar-drawer--open' : ''">
	<button class="sidebar-drawer__tab" (click)="toggleTab()">
		<fa-icon [icon]="icons.close"></fa-icon>
		More from SAO
	</button>

	<ul>
		<li><a href="#">Trending Reports</a></li>
		<li><a href="#">Top Reports</a></li>
		<li><a href="#">Cash vs. GAAP</a></li>
		<li><a href="#">Top 10 Lists</a></li>
	</ul>
</div>

<!-- this should be a hostlistener -->
<!-- <div class="sidebar-drawer__click-off" [ngClass]="{'sidebar-drawer__click-off--active' : this.tabOpen }" (click)="toggleTab()"></div> -->
