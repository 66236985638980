<div class="filters">
	<div class="filter" [title]="!canChangeGovType ? 'Cannot be changed once a government is selected (all governments in a peer set must be of the same government type)' : ''">
		<dx-select-box
			[disabled]="!canChangeGovType"
			showClearButton="true"
			[dataSource]="governmentTypes"
			[(value)]="filter.govTypeCode"
			displayExpr="description"
			valueExpr="code"
			placeholder="Select a government type"
			(onValueChanged)="updateFilterGovType($event)"
		></dx-select-box>
	</div>

	<div class="filter">
		<app-text-search
			[placeholder]="'Government name'"
			(changed)="updateFilterName($event)"
			[hideSearchButton]=true
		></app-text-search>
	</div>

	<div class="filter">
		<label>Filter by Location:</label>
		<app-locations
			(changed)="updateFilterLocations($event)"
			[store]="snapshot?.detail?.counties"
			[selections]="locationSelections"
		></app-locations>
	</div>
</div>

<dx-data-grid
	class="government-chooser"
	[dataSource]="store"
	[selectedRowKeys]="selectedRowKeys"
	(onSelectionChanged)="evaluateSelectedPeers($event)"
	[disabled]="isLoading || hasMaxPeers"
	hoverStateEnabled=false
	rowAlternationEnabled=true
	columnAutoWidth=true
	allowColumnResizing=true
	noDataText="Nothing matches the filters you have chosen. Please remove some filters and try again.">
	<dxo-scrolling mode="infinite"></dxo-scrolling>
	<dxo-load-panel delay=0></dxo-load-panel>
	<dxo-paging pageSize=20></dxo-paging>
	<dxo-selection
		[allowSelectAll]=false
		showCheckBoxesMode="always"
		mode="multiple"
	></dxo-selection>
	<dxi-column dataField="localGovernment.lookupNameWithDba" caption="Name" sortOrder="asc" cellTemplate="governmentName" width="320"></dxi-column>
	<dxi-column dataField="revenues" caption="Revenues" format="currency"></dxi-column>
	<dxi-column dataField="expenditures" caption="Expenditures" format="currency"></dxi-column>
	<dxi-column dataField="population" caption="Population"></dxi-column>
	<div *dxTemplate="let cell of 'governmentName'">
		{{ cell.text }}
	</div>
</dx-data-grid>
