import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Tab} from 'app/shared/models/tab';
import {SnapshotService} from './snapshot.service';
import {ValueConverter} from '@angular/compiler/src/render3/view/template';
import {LoggerService} from './logger.service';
import {IndicatorReport} from '../../modules/api/fit-api/models/indicators/indicator-report';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {FilingCondition} from '../../modules/api/fit-api/models/filing-status';
import {UtilityService} from './utility.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class FormatService {

	constructor(
		private number: DecimalPipe,
		private snapshot: SnapshotService,
		private fitApi: FitApiService,
		private logger: LoggerService,
		private utility: UtilityService
	) {
	}

	/**
	 * Look through the collection specified in the snapshot detail and match where id === data, then return the name,
	 *  optionally returning the account code as well.
	 * @param data Either a number or a PivotGridDataSource.fields.customizeText object
	 *  https://js.devexpress.com/Documentation/ApiReference/Data_Layer/PivotGridDataSource/Configuration/fields/#customizeText
	 * @param snapshotId
	 * @param showAccountCodes Whether to user has turned on account codes (lookup via categoryDisplay)
	 * @param collection The collection within snapshot detail to search
	 * @param accountCodeProperty The property to return for account codes. Defaults to categoryDisplay which is used
	 *  by the BARS chart of accounts.
	 */
	nameLookup(
		data: any | number,
		snapshotId: SnapshotId,
		showAccountCodes = false,
		collection: string,
		accountCodeProperty = 'categoryDisplay'
	): string {
		const lookup      = this.snapshot.findObject(snapshotId, collection, typeof data === 'number' ? data : data.value);
		let formattedName = null;
		if (lookup) {
			const accountCode = lookup?.[accountCodeProperty];
			const accountCodeDisplay = accountCode
				? ` (${accountCode})`
				: '';
			formattedName = showAccountCodes
				? `${lookup.name}${accountCodeDisplay}`
				: lookup.name;
		}

		return formattedName ?? data.valueText;
	}

	// Lookup BARS name in Snapshot.detail for given BARS Id
	// using showAccountCodes
	getNameForBARSId = (data: any | number, snapshotId: SnapshotId, showAccountCodes = false): string =>
		this.nameLookup(data, snapshotId, showAccountCodes, 'accountDescriptors');

	getNameForSchedule9BonanzaCategoryId = (data: any | number, snapshotId: SnapshotId, showAccountCodes = false): string =>
		this.nameLookup(data, snapshotId, showAccountCodes, 'bonanzaSchedule9Categories');

	getNameForSchedule9BonanzaTypeId = (data: any | number, snapshotId: SnapshotId, showAccountCodes = false): string =>
		this.nameLookup(data, snapshotId, showAccountCodes, 'bonanzaSchedule9Types');

	/**
	 * Given an IndicatorReport data record, generate the title with the UoM appended.
	 * @param data
	 * @param filingBasis
	 * @param reportId
	 * @param snapshotId
	 */
	getIndicatorTitleWithUnits = (
		data: { value: any, valueText: any },
		filingBasis: FilingBasis,
		reportId: 'governmentalIndicators' | 'enterpriseIndicators',
		snapshotId: SnapshotId
	) => {
		let fieldLabel = data.value;
		const indicatorName = this.snapshot.findIndicatorName(snapshotId, fieldLabel, filingBasis, reportId);
		if (!indicatorName) {
			this.logger.warn('Indicator was not found!', fieldLabel, filingBasis, reportId);
			return fieldLabel;
		}
		if (indicatorName.measureUnitInfo?.unitAbbreviation) {
			fieldLabel += ` (${indicatorName.measureUnitInfo.unitAbbreviation})`;
		}
		return fieldLabel;
	};

	// Lookup expenditure object text in Snapshot.detail for given Object Id
	// using showAccountCodes
	getNameForExpenditureId(data: any, tab: Tab) {
		const lookup      = this.snapshot.findObject(tab.snapshotId, 'expenditureObjects', data.value);
		let formattedName = null;
		if (lookup) {
			formattedName = tab.pivotGridSettings.showAccountCodes
				? `${lookup.name} (${lookup.id})`
				: lookup.name;
		}

		return formattedName || (typeof data === 'number' ? data : data.valueText);
	}

	// Lookup government type text in Snapshot.detail for given Type Id
	// TODO remove dependency on tab
	getNameForGovtTypeCode(data: any, tab?: Tab, snapshotId?: SnapshotId) {
		snapshotId = snapshotId ?? tab.snapshotId;
		const lookup = this.snapshot.findObject(snapshotId, 'governmentTypes', data.value, 'code');

		return lookup ? lookup.description : data.valueText;
	}

	getNameForSectionId(data: any | number, snapshotId: SnapshotId) {
		const lookup = this.snapshot.findObject(snapshotId, 'financialSummarySections', typeof data === 'number' ? data : data.value);
		return lookup ? lookup.name : (typeof data === 'number' ? data : data.valueText);
	}

	getFundCategoryText(data: any | number, snapshotId: SnapshotId) {
		const lookup = this.snapshot.findObject(snapshotId, 'fundCategories', typeof data === 'number' ? data : data.value);
		return lookup ? lookup.name : (typeof data === 'number' ? data : data.valueText);
	}

	getFundTypeText(data: any, snapshotId: SnapshotId) {
		const lookup = this.snapshot.findObject(snapshotId, 'fundTypes', data.value);
		return lookup ? lookup.name : data.valueText;
	}

	getFundSelector(data: any) {
		return `${data.fundNumber || data.fund || ''}|${data.fundName || ''}`;
	}

	getFundText(data: any, tab: Tab) {
		if (!data.value) {
			return '';
		}

		let [fundNumber, fundName] = data.value.split('|');
		fundName                   = fundName || fundNumber;
		const code                 = tab.pivotGridSettings.showAccountCodes && fundNumber
			? ` (${fundNumber})`
			: '';
		return fundName + code;
	}

	getDebtGroupText(data: any, tab: Tab) {
		const lookup = this.snapshot.findObject(tab.snapshotId, 'debtGroups', data.value);
		return lookup ? lookup.name : data.valueText;
	}

	groupPeers(data: any, tab: Tab) {
		const isPrime = tab.governments.some(v => v.mcag === data.mcag && v.prime === true);

		return isPrime ? 'Baseline Government' : 'Comparison Governments';
	}

	// Customize text for government field taking into account tab "population" settings
	getGovernmentText(data: any, tab: Tab) {
		// value is for call from customizeText, mcag is for call from selector (data row)
		const mcag   = data.value || data.mcag;
		const govObj = this.snapshot.findObject(tab.snapshotId, 'governments', mcag, 'mcag');
		if (!govObj) {
			this.logger.warn(`FormatService.getGovernmentText: No lookup available for mcag ${mcag} in SnapshotDetail.governments`);
			return mcag;
		}

		const govName = govObj.entityNameWithDba;

		// If the filing is missing, display why; this will override displaying population
		const isFilingFieldGovernment = tab.pivotGridSettings.filingField === 'government';
		const missingFilingCondition = this.getMissingFilingCondition(data);
		if (isFilingFieldGovernment && missingFilingCondition !== null) {
			return govName + ` (${missingFilingCondition})`;
		}

		// If not showing population, just return name
		if (!tab.pivotGridSettings.showPopulation || tab.pivotGridSettings.populationField !== 'government') {
			return govName;
		}
		const population = this.getPopulation(data, tab);
		return population
			? govName + ' (' + this.number.transform(population) + ' ppl)'
			: govName + ' (no pop data)';
	}

	// Customize text for year field taking into account tab "population" settings
	getYearText(data: any, tab: Tab) {
		if (data.isProjection) {
			return `${data.year} (projection)`;
		}

		// If the filing is missing, display why; this will override displaying population
		const isFilingFieldYear = tab.pivotGridSettings.filingField === 'year';
		const missingFilingCondition = this.getMissingFilingCondition(data);
		if (isFilingFieldYear && missingFilingCondition !== null) {
			return data.year + ` (${missingFilingCondition})`;
		}

		// If not showing population, just return name
		if (!tab.pivotGridSettings.showPopulation || tab.pivotGridSettings.populationField !== 'year') {
			return data.year ?? data.fy;
		}

		const population = this.getPopulation(data, tab);
		return population
			? data.year + ' (' + this.number.transform(population) + ' ppl)'
			: data.year + ' (no pop data)';
	}

	getMissingFilingCondition(data: any) {
		// Check to see if the government was even active for the given year
		if (data.filingCondition === FilingCondition.NotActive) {
			return 'not active';
		}

		// If the government hasn't filed, indicator that after the year
		const hasNotFiledForYear = data.filingCondition === FilingCondition.None
			|| data.filingCondition === FilingCondition.Started
			|| data.filingCondition === FilingCondition.NotStarted;
		if (hasNotFiledForYear) {
			return 'no filing';
		}

		// If the government has their filing open for edit, indicate that after year
		if (data.pendingUpdates === true) {
			return 'pending updates';
		}

		return null;
	}

	// Returns the filing status info text or numeric amount for Track D
	// custom aggregation generated by UnitsService.calculateSummaryValueForPhantomYearForTrackD()
	getTextForTrackDSummariesWithPhantomRows(unit: any) {
		return (cellInfo: any) => {
			const split = cellInfo.value === undefined ? '' : cellInfo?.value.split('|', 2);
			if (split && split[0] && split[0] !== '') {
				return split[0];
			} else if (split && split[1] && split[1] !== '') {
				// To handle negative numbers properly, remove leading zeros
				const value = split[1].replace(/^0+/, '');
				return this.utility.formatCurrency(unit, +value);
			} else {
				return '';
			}
		};
	}

// Returns the population from the tab according to the year and MCAG values in the data row.
	getPopulation(data: any, tab: Tab) {
		const target = tab.governments.find(function(v) {
			return v.mcag === data.mcag;
		});

		const metrics = target.metrics.find(function(v) {
			return v.year === data.year;
		});

		if (!target || !metrics) {
			return null;
		}

		// Display Population, unless normalization is for uninc
		return tab.pivotGridSettings.unit === 'capitaPerDollarUnincorp'
			? metrics.populationUninc
			: metrics.population;
	}

	getOSPICollectionObject(collectionName: string, reportNo: string, returnKey: string, lookupKey: string | number, lookupData: { value: any }, showItemCode: boolean = false) {
		if (!lookupData.value) {
			return '';
		}
		const collection = this.snapshot.getOSPICollection(collectionName);
		if (!collection) {
			this.logger.warn(`FormatService: No schools collection named '${collectionName}'. Returning value ${lookupData.value}.`);
			return lookupData.value;
		}
		const object = collection.find(v => v[lookupKey] === lookupData.value && (reportNo === null || v['reportNo'] === reportNo));
		if (!object) {
			this.logger.warn(`FormatService: No object found in '${collectionName}' matching ${lookupKey} === ${lookupData.value} and reportNo === ${reportNo}. Returning value ${lookupData.value}.`);
			return lookupData.value;
		}
		const code = object['displayCode'] || object['code'];
		return showItemCode && code ? `${code}. ${object[returnKey]}` : object[returnKey];
	}

	/**
	 * Generically get the name of the given id from collection in Snapshot Detail.
	 * @param snapshotId
	 * @param collection - name of array on Snapshot Detail
	 * @param value
	 */
	getNameFromSnapshot = (snapshotId: SnapshotId, collection: string, value: string | number) =>
		this.snapshot.findObject(snapshotId, collection, value)?.name;
}
