import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { TabReuseModal } from '../models/tab-reuse-modal';

@Injectable({
	providedIn: 'root'
})
export class TabReuseService {

	tabReuse = new TabReuseModal({});
	observable: Observable<TabReuseModal>;
	modalObserver: Observer<TabReuseModal>;
	clientObserver: Observer<string>;

	constructor() {
		const self = this;
		this.observable = Observable.create((observer) => {
			self.modalObserver = observer;
			self.triggerNext();
		});
	}

	triggerNext() {
		this.modalObserver.next(this.tabReuse);
	}

	openModal(config = {}) {
		const self = this;
		this.tabReuse = new TabReuseModal(config);
		this.tabReuse.isVisible = true;
		this.triggerNext();
		return Observable.create((observer) => {
			self.clientObserver = observer;
		});
	}

	closeModal(action: string) {
		this.tabReuse = new TabReuseModal({});
		this.tabReuse.isVisible = false;
		this.clientObserver.next(action);
		this.triggerNext();
	}

	setModal(config = {}) {
		this.tabReuse = new TabReuseModal(config);
		this.triggerNext();
	}
}
