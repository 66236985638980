<div class="dx-field">
	<div class="dx-field-label">Name:</div>
	<div class="dx-field-value">
		<dx-text-box
			id="name"
			[(value)]="name"
			(onChange)="onNameChange()"
		>
			<dx-validator>
				<dxi-validation-rule type="required" message="Please provide a name for this projection."></dxi-validation-rule>
			</dx-validator>
		</dx-text-box>
	</div>
</div>

<div class="dx-field">
	<div class="dx-field-label">Notes:</div>
	<div class="dx-field-value">
		<dx-text-area
			id="notes"
			[(value)]="notes"
			(onChange)="exportProjection()"
		></dx-text-area>
	</div>
</div>

<div class="dx-field">
	<div class="dx-field-label">Within Fund:</div>
	<div class="dx-field-value projection-editor-fund">
		<dx-select-box
			[dataSource]="funds"
			valueExpr="fundNumber"
			[disabled]="funds?.length === 1"
			[displayExpr]="getAnnotatedFundName"
			[searchEnabled]="true"
			[(value)]="fundValue"
			(onValueChanged)="onFundChanged($event)"
		></dx-select-box>
		<div *ngIf="showGAAPEnterpriseFundNote" class="gaap-enterprise-note">
			Note: Beginning and Ending Balance calculations for projection years currently include accounts (391-393, 591, 593-596) and thus may not represent ending net position.
		</div>
	</div>
</div>



<div class="editor-container">
	<dx-tree-list
		[dataSource]="dataSet"
		keyExpr="id"
		parentIdExpr="parentId"
		columnAutoWidth=true
		(onEditorPreparing)="onEditorPreparing($event)"
		(onRowUpdating)="onRowUpdating($event)">
		<dxo-editing
			[allowUpdating]=true
			mode="cell"
		></dxo-editing>
		<dxo-sorting
			mode="none"
		></dxo-sorting>
		<dxo-scrolling mode="standard" showScrollbar="always"></dxo-scrolling>

		<dxi-column
			[dataField]="showAccountCodes ? 'nameWithCategory' : 'name'"
			caption="Account"
			alignment="left"
			calculateSortValue="categoryDisplay"
			[sortIndex]="0"
			sortOrder="asc"
			[minWidth]="180"
			[allowEditing]=false
			cellTemplate="cellTemplate"
			fixed=true
		></dxi-column>

		<dxi-column
			dataField="priorYearAmount"
			[caption]="projection && projection.baseYear - 1"
			dataType="number"
			format="currency"
			[allowEditing]=false
			cellTemplate="cellTemplate"
		></dxi-column>

		<dxi-column
			[calculateCellValue]="priorToBaseDifference"
			[caption]="'Change \u2192'"
			format="percent"
			[allowEditing]=false
			cellTemplate="cellTemplate">
			<dxo-format type="percent" precision="2"></dxo-format>
		</dxi-column>

		<dxi-column
			dataField="baseYearAmount"
			[caption]="projection && projection.baseYear"
			dataType="number"
			format="currency"
			[allowEditing]=false
			cellTemplate="cellTemplate"
		></dxi-column>

		<ng-container *ngFor="let year of projectionYears; let i = index">
			<dxi-column
				[dataField]="'futureYears[' + i + '].adjustmentValue'"
				[caption]="'Adjustment \u2192'"
				[editorOptions]="{ format: { type: 'percent', precision: 2 } }"

				[allowEditing]=true
				cellTemplate="adjustmentTemplate"
			>
				<dxo-format type="percent" precision="2"></dxo-format>
			</dxi-column>

			<dxi-column
				[dataField]="'futureYears[' + i + '].amount'"
				[caption]="year"
				[editorOptions]="{ format: 'currency' }"
				format="currency"
				cellTemplate="cellTemplate"
			></dxi-column>
		</ng-container>

		<div *dxTemplate="let cell of 'cellTemplate'" [ngClass]="cell.data.rowType">
			{{ cell.text }}
		</div>

		<div *dxTemplate="let cell of 'adjustmentTemplate'" class="adjustment" [ngClass]="getAdjustmentCellClass(cell)">
			{{ cell.text }}
		</div>
	</dx-tree-list>

	<div class="actions">
		<fa-icon title="Add Another Year" *ngIf="projectionCount < 5" class="add action" [icon]="addIcon" (click)="addYear()"></fa-icon>
		<fa-icon title="Remove Last Year" *ngIf="projectionCount > 1" class="remove action" [icon]="removeIcon" (click)="removeYear()"></fa-icon>
	</div>
</div>
