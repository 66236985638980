<div class="container" *ngIf="dataSource?.length || !hideWhenContentEmpty">
	<dx-data-grid
		[dataSource]="dataSource"
		rowAlternationEnabled=true
		columnAutoWidth=true
	>
		<dxi-column dataField="label" caption=""></dxi-column>
		<dxi-column
			dataField="governmentTypeAmount"
			caption="Statewide"
			format="percent"
			sortOrder="desc"
			[calculateCellValue]="calculateGovTypeAmount"
		></dxi-column>
		<dxi-column
			dataField="thisGovernmentAmount"
			caption="This Government"
			format="percent"
			[calculateCellValue]="calculateThisGovernmentAmount"
		></dxi-column>
	</dx-data-grid>

	<div class="caption">Governmental {{ reportType | titlecase }} <br /> (with {{ this.govTypeDesc }} statewide averages)</div>
</div>

<wasao-loading [isLoading]="isLoading"></wasao-loading>
