<div class="command-bar__row">
	<div class="command-bar__cell">
		<button
			*ngIf="isLoaded && (currentTab.state === 'lgfrs' || currentTab.state === 'government-profile' || currentTab.state === 'indicator')"
			class="command-bar__icon-button"
			(click)="isCopyLinkVisible = true"
			title="Share"
		>
			<fa-icon [icon]="icons.share"></fa-icon>
		</button>
		<button
			*ngIf="isLoaded && currentTab.state === 'lgfrs'"
			class="command-bar__icon-button"
			(click)="handleCustomExport()"
			title="Download"
		>
			<fa-icon [icon]="icons.download"></fa-icon>
		</button>
		<button
			*ngIf="isReportChartAvailable"
			class="command-bar__icon-button"
			(click)="showReportChart()"
			title="Report Chart"
		>
			<fa-icon [icon]="icons.chart"></fa-icon>
		</button>

		<button
			*ngIf="isIndicatorMapAvailable"
			class="command-bar__icon-button"
			(click)="showIndicatorReportMap()"
			title="Map"
		>
			<fa-icon [icon]="icons.map"></fa-icon>
		</button>
		<!-- For Later:
				<button class="command-bar__icon-button" (click)="print()">
					<fa-icon [icon]="icons.print"></fa-icon>
				</button>
		-->
	</div>

	<div class="command-bar__cell command-bar__menu-buttons" *ngIf="isLoaded">
		<app-display-options
			*ngIf="currentTab.state === 'lgfrs' && currentTab.track.id !== 'e'"
			[tab]="currentTab"
			[reportId]="currentTab.report.id"
			(submitted)="updateFromDisplayOptions($event)"
			class="u-margin-right-tiny">
		</app-display-options>

		<app-report-layout-builder
			*ngIf="currentTab.state === 'lgfrs'"
			[tab]="currentTab"
			[snapshot]="currentSnapshot"
			[store]="pivotGridData"
			[lastUpdate]="pivotGridUpdate"
			(submit)="handleReportLayoutBuilderSubmit($event)"
			class="u-margin-right-tiny"
		></app-report-layout-builder>

		<app-projection-launcher
			*ngIf="isProjectionEnabled"
			class="u-margin-right-tiny"
			[tab]="currentTab"
		></app-projection-launcher>

		<dx-button
			*ngIf="currentTab.state === 'lgfrs' || currentTab.state === 'indicator'"
			class="button--nonoperative"
			(onClick)="showReportMenu($event)"
		>
			<div *dxTemplate="let data of 'content'">
				<fa-icon class="dx-icon u-margin-right-tiny" [icon]="reportMenuIcon"></fa-icon>
				Report Menu
			</div>
		</dx-button>
	</div>
<!--	<div class="command-bar__cell">
		<app-dataset-selector></app-dataset-selector>
	</div>
-->
	<!-- TODO - hide the snapshot selector for schools until dataset selector is ready -->
	<div *ngIf="!isDisplayingOSPI">
		<div class="command-bar__cell" *ngIf="currentTab?.state !== 'wizard' && snapshots.length">
			<label>
				Displaying data from:
			</label>
			<dx-select-box
				*ngIf="!isLoadingSnapshots"
				[items]="snapshots"
				[displayExpr]="displayExpr"
				valueExpr="id"
				itemTemplate="items"
				[value]="currentSnapshotId"
				(onValueChanged)="updateSnapshot($event)"
				[width]="250"
			>
				<div class="multiline" *dxTemplate="let item of 'items'">
					<div>{{ item.name }}</div>
					<div *ngIf="(item.totalRevenues !== undefined && item.filersWithData !== undefined) && (item.totalRevenues !== 0 && item.filersWithData !== 0)">
						<div>Total Revenues: {{item.totalRevenues | currency : 'USD' : 'symbol' : '1.0-0' }}</div>
						<div>Filers w/ Data: {{item.filersWithData | number}}</div>
					</div>
				</div>
			</dx-select-box>
			<dx-load-indicator [visible]="isLoadingSnapshots"></dx-load-indicator>
		</div>
	</div>
</div>

<dx-popup
	title="Share a link to page"
	[(visible)]="isCopyLinkVisible"
	(onShowing)="getShareUrl()"
	width="400px"
	height="200px"
>
	<div *dxTemplate="let data of 'content'" class="u-padding">
		<label>
			Link to this page:
		</label>
		<app-share-url></app-share-url>
		<p class="u-font-size-small u-margin-top-small">Copy this link to share this tab with others.</p>
	</div>
</dx-popup>
