import {FundCategory} from './fund-category';

export enum FundTypeId {
	general = 0,
	specialRevenue = 1,
	debtService = 2,
	capitalProjects = 3,
	enterprise = 4,
	permanent = 7
}

export class FundType {
	categoryId: FundCategory;
	id: FundTypeId;
	name: string;
	sortOrder: number;
}
