<div class="text-search">
	<dx-text-box
		valueChangeEvent="keyup"
		(onValueChanged)="searchTerm.next($event.value)"
		[placeholder]="placeholder"
		showClearButton=true
		class="text-search__input"
	></dx-text-box>

	<button class="text-search__button" type="button" *ngIf="!hideSearchButton">
		<fa-icon [icon]="searchIcon"></fa-icon>
	</button>
</div>
