import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
	name: 'totalAmount'
})
export class TotalAmountPipe implements PipeTransform {

	constructor(private decimalPipe: DecimalPipe) { }

	transform(amount: number | string): string {
		if (typeof amount === 'number') {
			return '$' + this.decimalPipe.transform(amount, '1.0-0');
		} else {
			return amount;
		}
	}

}
