import {Component, Input, SimpleChanges, OnChanges} from '@angular/core';
import {FitApiService} from 'app/modules/api/fit-api/fit-api.service';
import {map, switchMap} from 'rxjs/operators';
import {Reusable} from 'app/modules/reusable/models/reusable';
import {FilingStatusService} from '../../../services/filing-status-service/filing-status.service';
import {of} from 'rxjs';
import {SnapshotId} from '../../../api/fit-api/models/snapshot-like';

@Component({
	selector: 'wasao-on-time-filer-badge',
	templateUrl: './on-time-filer-badge.component.html',
	styleUrls: ['./on-time-filer-badge.component.scss']
})
export class OnTimeFilerBadgeComponent extends Reusable implements OnChanges {
	@Input() mcag: string;
	/**
	 * Specify the year to use to display this component. Otherwise, will use default display year logic.
	 */
	@Input() displayYear?: number;
	@Input() snapshotId: SnapshotId;
	/**
	 * Action to perform when Gov Type link is clicked.
	 */
	@Input() badgeClickAction: () => void;

	yearsRunning: number;

	constructor(
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges) {
		// Recalculate yearsRunning when mcag, displayYear, or snapshotId changes.
		if (changes?.mcag?.currentValue !== changes?.mcag?.previousValue
		 || changes?.snapshotId?.currentValue !== changes?.snapshotId?.previousValue
		 || changes?.displayYear?.currentValue !== changes?.displayYear?.previousValue) {
			this.isLoading = true;
			this.getYearsRunning().subscribe(result => {
				this.yearsRunning = result;
				this.hasData.emit(this.yearsRunning != null);
				this.isLoading = false;
			});
		}
	}

	/**
	 * Calculate the number of years the entity has filed on-time, going backwards and stopping when the first late
	 * or not-submitted is encountered.
	 */
	private getYearsRunning() {
		return this.fitApi.getFilingStatusesForMCAG(this.mcag, this.snapshotId).pipe(switchMap(filingStatuses => {
			const _displayYear = this.displayYear ? of(this.displayYear) : this.filingStatus.getFilingYearForDisplay(this.mcag, this.snapshotId);
			return _displayYear.pipe(map(y => {
				// filter out records for years greater than displayYear
				filingStatuses = filingStatuses.filter(s => s.year <= y);
				// reverse sort
				filingStatuses = filingStatuses.sort((a, b) => b.
					year - a.year);
				let yearsRunning = 0;
				for (let i = 0; i < filingStatuses.length; i++) {
					const filingStatus = filingStatuses[i];
					// daysLate OR no dateSubmitted will break the streak.
					// Since yearsRunning is initialized to 0, this handles the case where the government has not filed
					// their latest year yet.
					if (filingStatus.daysLate === null && filingStatus.dateSubmitted) {
						yearsRunning++;
					} else {
						break;
					}
				}
				return yearsRunning;
			}));
		}));
	}

	handleBadgeClick = () => this.badgeClickAction();

}
