import {Injectable} from '@angular/core';
import {Tab} from 'app/shared/models/tab';
import {FormatService} from './format.service';
import {PrimaryGovernmentPipe} from '../pipes/primary-government.pipe';
import {LoggerService} from './logger.service';
import {EnsureArrayPipe} from 'app/shared/pipes/ensure-array.pipe';
import {FilingStatusService} from '../../modules/services/filing-status-service/filing-status.service';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
export class UnitsService {

	/** todo this should be moved into Report model, or typed here based on that model
	 * Common
	 *     requires is an object containing keys that correspond to functions to be called by convention when this
	 *     service evaluates items that are available for a given context (based on tab settings).
	 *     See: getAvailable() and `requires` region
	 *     The value of the keys can be a single string or an array of strings.
	 *
	 * @type {{measures: [*], comparisons: [*], units: [*]}}
	 * measures
	 *     Provides the dataField that the PivotGrid should point to in the ODataStore.
	 * comparisons
	 *     Object keys are used by the service to call custom summary functions. See: getComparisonFieldPartial()
	 *     Formatting can be provided here for percent-based comparisons which will override unit formatting.
	 * units
	 *     Object keys are used by the service to call custom PivotGrid selector functions. See: getUnitFieldPartial()
	 *     Provides formatting (type and precision, e.g. 'currency', 2)
	 */
	config = {
		measures:    [
			{
				name:      'Amounts',
				key:       'amounts',
				dataField: 'amount',
				summaryType: 'sum',
				requires:  {
					report: [
						'summary', 'expenditures', 'expendituresWithOthers', 'revenues', 'revenuesWithOthers',
						'schoolsBalanceSheet', 'schoolsStatementRevExp', 'schoolsRevenuesWithOthers',
						'schoolsExpenditures', 'schoolsLiabilities', 'schoolsRevenues', 'schoolsGeneralFundExpenditures',
						'schoolsLongTermLiabilities'
					]}
			},
			{name: 'Ending Balance', key: 'endingBalance', dataField: 'ending', summaryType: 'sum', requires: {
				report: ['debtAndLiabilities']
			}},
			{ name: 'Measure', key: 'measure', dataField: 'measure', requires: { track: 'e' }},
			{ name: 'Amounts', key: 'amountsForTrackD', dataField: undefined, summaryType: 'custom', aggregationAmountField: 'amount', requires:  {
					report: ['schedule01CategoryTotals']}
			},
			{ name: 'Ending Balance', key: 'endingBalanceForTrackD', dataField: undefined, summaryType: 'custom', aggregationAmountField: 'ending', requires:  {
					report: ['schedule09CategoryTotals']}
			}
		],
		comparisons: [
			{name: 'no comparison', key: 'none', isFieldVisible: false},
			{name: '% Difference', key: 'differenceByPercent', format: 'percent', precision: 0, requires: {track: 'b1'}},
			{name: '$ Difference', key: 'differenceByDollars', requires: {track: 'b1'}},
			{
				name:               '% of Total',
				key:                'percentOfColumnTotal',
				format:             'percent',
				precision:          1,
				summaryDisplayMode: 'percentOfColumnGrandTotal',
				requires:           {report: ['expenditures', 'expendituresWithOthers', 'revenues', 'revenuesWithOthers', 'schedule01CategoryTotals', 'debtAndLiabilities', 'schedule09CategoryTotals']}
				// Schools cannot easily support this option since the pivot grid is not doing the totalling
			}
		],
		units:       [
			{name: '$ Dollars', key: 'dollars', format: 'currency', precision: 0},
			{
				name:      '$ Per Capita',
				key:       'capitaPerDollar',
				format:    'currency',
				precision: 2,
				requires:  {
					metric: 'population',
					report: ['summary', 'expenditures', 'expendituresWithOthers', 'revenues', 'revenuesWithOthers', 'schedule01CategoryTotals', 'debtAndLiabilities']
				}
			},
			{
				name:      '$ Per Capita (unincorporated)',
				key:       'capitaPerDollarUnincorp',
				format:    'currency',
				precision: 2,
				requires:  {
					metric: 'populationUninc',
					report: ['summary', 'expenditures', 'expendituresWithOthers', 'revenues', 'revenuesWithOthers', 'schedule01CategoryTotals', 'debtAndLiabilities']
				}
			},
			{name: '$K Thousands', key: 'dollarsK', format: 'currency thousands', precision: 1, requires: {
					report: ['summary', 'expenditures', 'expendituresWithOthers', 'revenues', 'revenuesWithOthers', 'schedule01CategoryTotals', 'debtAndLiabilities',
						'schoolsBalanceSheet', 'schoolsStatementRevExp', 'schoolsRevenuesWithOthers', 'schoolsExpenditures', 'schoolsLiabilities', 'schoolsRevenues', 'schoolsGeneralFundExpenditures']
			}},
			{name: '$M Millions', key: 'dollarsM', format: 'currency millions', precision: 1, requires: {
					report: ['summary', 'expenditures', 'expendituresWithOthers', 'revenues', 'revenuesWithOthers', 'schedule01CategoryTotals', 'debtAndLiabilities',
						'schoolsBalanceSheet', 'schoolsStatementRevExp', 'schoolsRevenuesWithOthers', 'schoolsExpenditures', 'schoolsLiabilities', 'schoolsRevenues', 'schoolsGeneralFundExpenditures']
			}}
		]
	};

	constructor(
		private format: FormatService,
		private primaryGovernment: PrimaryGovernmentPipe,
		private logger: LoggerService,
		private ensureArray: EnsureArrayPipe,
		private filingStatusService: FilingStatusService
	) {
	}

	/**
	 * @deprecated todo Move logic to Report model
	 * @param tab
	 */
	getDefaultComparison(tab: Tab) {
		switch (tab?.track?.id) {
			case 'b1':
				return 'differenceByPercent';
			default:
				return 'none';
		}
	}

	/**
	 * @deprecated todo Move logic to Report model
	 * @param tab
	 */
	getDefaultMeasure(tab: Tab) {
		if (tab?.track?.id === 'e') {
			return 'measure';
		}
		else if (tab?.report?.id === 'schedule01CategoryTotals') {
			return 'amountsForTrackD';
		}
		else if (tab?.report?.id === 'schedule09CategoryTotals') {
			return 'endingBalanceForTrackD';
		}
		else if (tab?.report?.id === 'debtAndLiabilities') {
			return 'endingBalance';
		}
		return 'amounts';
	}

	// Get the current object that corresponds to the type (key of self.config) based on the related tab setting.
	getConfigItem(type: string, tab: Tab) {
		let result = null;

		const arrayOfType = this.config[`${type}s`];
		if (!Array.isArray(arrayOfType)) {
			throw new Error(`UnitsService.getConfigItem: Type ${type} is not known.`);
		}

		result = arrayOfType.find((v) => v.key === tab.pivotGridSettings[type]) || null;

		if (!result) {
			throw new Error(`UnitsService.getConfigItem: No type '${type}' with key '${tab.pivotGridSettings[type]}'.`);
		}

		return result;
	}

	// Returns available units and measures based on tab settings.
	getAvailable(tab) {
		// $log.debug('UnitsService.getAvailable begin', tab, transientSelections);

		// `Requires` filtering (currently operates as `or` operation)
		const result = {};

		// Look through each key in config object... (measures, comparisons, units)
		for (const key in this.config) {
			if (!this.config.hasOwnProperty(key)) {
				continue;
			}

			// Accumulate each config item that passes all requires tests
			result[key] = this.config[key].filter(i => this.itemCanPassRequirements(i, tab));
		}

		return result;
	}

	// region `requires` tests

	/**
	 * Test a configItem to ensure it passes all requires tests. Note that it is up to each
	 * canPassRequirement function to implement the logic for that specific key. I.e.
	 * canPassRequirementMetric may only require one metric to be present anywhere on the tab,
	 * where canPassRequirementReport only passes with a specific report
	 */
	itemCanPassRequirements(configItem, tab) {
		const self = this;
		// if no requires object, then obj passes test
		if (!configItem.requires) {
			return configItem;
		}

		// Ensure all keys in requires can pass their respective functions
		return Object.keys(configItem.requires).every(function(key) {
			// get the function name by convention. E.g., report => canPassRequirementReport()...
			const func  = 'canPassRequirement' + key.charAt(0).toUpperCase() + key.slice(1);
			// then pass in the items to test against
			const items = configItem.requires[key];
			// e.g. self.canPassRequirementReport(tab, ['expenditures', 'revenues'])
			// this would return true if the current state of the tab had the report set to revenues or expenditures
			return self[func](tab, items);
		});
	}

	canPassRequirementMetric(tab, items) {
		// Metrics can only be used with governments
		if (!Array.isArray(tab.governments)) {
			this.logger.warn('UnitsService.canPassRequirementMetric: Metrics can only be used in conjunction with governments.');
			return false;
		}

		items = this.ensureArray.transform(items);
		// Iterate over array items...
		return items.some(function(item) {
			// then look through each government...
			return tab.governments.some(function(gov) {
				// and see if the government metrics contain...
				return gov.metrics && gov.metrics.some(function(metric) {
					// a value for the item we're iterating for any year in the range
					return metric.year >= tab.years[0] // ge than first year selection
						&& (!tab.years[1] || metric.year <= tab.years[1]) // no second year, or le second year
						&& metric[item]; // and key is populated
				});
			});
		});
	}

	canPassRequirementReport(tab, items) {
		items = this.ensureArray.transform(items);

		return items.some(i => i === tab.report.id);
	}

	canPassRequirementTrack(tab, items) {
		items = this.ensureArray.transform(items);

		return items.some(i => i === tab.track.id);
	}

	// endregion

	// region Field Partials

	getMeasureFieldPartial(tab: Tab) {
		// $log.debug('MetricsService.getMeasureFieldPartial', tab);
		const measureObject = this.getConfigItem('measure', tab);
		const trackId = tab?.track?.id;

		const result = {
			dataField:             	measureObject.dataField,
			caption:               	measureObject.name,
			summaryType: 		    measureObject.summaryType,
			calculateSummaryValue:  null,
			calculateCustomSummary: null,
			customizeText:          null
		};

		// Append per capita units to label
		const unit = this.getConfigItem('unit', tab);
		if (['capitaPerDollar', 'capitaPerDollarUnincorp'].includes(unit.key)) {
			result.caption = measureObject.name + ' (per capita)';
		}

		// Used for track D summarization
		if (trackId === 'd') {
			result.calculateCustomSummary = this.calculateSummaryValueForPhantomYearForTrackD(measureObject.aggregationAmountField);
			result.customizeText = this.format.getTextForTrackDSummariesWithPhantomRows(unit);
		} else {
			result.calculateSummaryValue = this.calculateSummaryValueForPhantomYear(tab);
		}

		Object.assign(result, this.getUnitsFieldPartial(tab));

		return result;
	}

	getUnitsFieldPartial(tab: Tab) {
		const unit = this.getConfigItem('unit', tab);
		const self = this;
		const trackId = tab?.track?.id;

		// Track D path removes selector in order to utilize calculateCustomSummary
		if (trackId === 'd') {
			return {
				format: {
					type: unit.format,
					precision: unit.precision
				}
			};
		} else {
			return {
				selector: function (data) {
					// E.g. self.dollars(tab, data)
					// This particular scenario simply returns the data field provided in the config.
					return self[tab.pivotGridSettings.unit](tab, data);
				},
				format: {
					type: unit.format,
					precision: unit.precision
				}
			};
		}
	}

	getComparisonFieldPartial = (tab: Tab) => {
		const comparison = this.getConfigItem('comparison', tab);
		const unit       = this.getConfigItem('unit', tab);
		const self       = this;

		// Hide and exit for fields that should not be displayed, i.e. 'none' comparison
		if (comparison.isFieldVisible === false) {
			return {
				visible:               false,
				calculateSummaryValue: this.calculateSummaryValueForPhantomYear(tab)
			};
		}

		// Cross-government comparisons are inherently single-year so we set
		// null if a range is present
		const year                  = tab.years[1] !== null ? null : tab.years[0];
		const primaryGovernment     = this.primaryGovernment.transform(tab);
		const primaryGovernmentText = primaryGovernment
			? this.format.getGovernmentText({mcag: primaryGovernment.mcag, year: year}, tab)
			: null;

		const result: any = {
			caption: comparison.name
		};

		const calculationFunction = self[tab.pivotGridSettings.comparison];

		if (comparison.summaryDisplayMode) {
			result.calculateSummaryValue = this.calculateSummaryValueForPhantomYear(tab, comparison.summaryDisplayMode);
			result.customizeText         = (cell) => cell.valueText;
		}
		else if (calculationFunction) {
			result.calculateSummaryValue = summaryCell => {
				// if cell belongs to primary government, return nulls (which are not shown in pivotGrid)
				// https://js.devexpress.com/Documentation/16_2/ApiReference/UI_Widgets/dxPivotGrid/Summary_Cell/#valuefield
				if (summaryCell.value('government') === primaryGovernmentText) {
					return null;
				}

				// This hides any summary rows added to force non-filed years to appear
				if (self.hidePhantomYearSummary(summaryCell, tab)) {
					return null;
				}

				// E.g. self.differenceByPercent(summaryCell, primaryGovernment)
				return calculationFunction(summaryCell, primaryGovernmentText);
			};
		}
		else {
			throw new Error('UnitsService.getComparisonField was called on a configuration object that contains neither ' +
				'summaryDisplayMode nor valid calculation function of key self.' + comparison.key + '()');
		}

		Object.assign(result, this.getUnitsFieldPartial(tab));
		const isPercent = comparison.format && comparison.format.indexOf('percent') > -1;

		// Override unit partial for percentages
		if (isPercent) {
			result.format = {
				type:      comparison.format,
				precision: comparison.precision
			};
		}

		// Insert + for positive values in comparison mode
		if (!comparison.summaryDisplayMode) {
			result.customizeText = function(cell) {
				let formatted = cell.valueText;

				// Add plus sign for positive values
				if (cell.value >= 0.01) {
					const currencyIndex = cell.valueText.indexOf('$');
					if (currencyIndex > -1) {
						formatted = '+$' + formatted.slice(currencyIndex + 1);
					}
					else {
						formatted = '+' + formatted;
					}
				}

				return formatted;
			};
		}

		return result;
	}

	// OData API returns null Schedule 1 records for governments that were active in a year, but did not file.
	// This function detects the summary rows or columns in the pivot grid that result and that have null
	// values in the summary fields; these phantom records have values for Year, mcag, CountyCode, GovType, etc.
	hidePhantomYearSummary(summaryCell, tab: Tab) {
		const hasFieldAsParent = function(sumCell, direction, fieldName) {
			const parent = sumCell.parent(direction);

			// Have we reached the end without finding the fieldName?
			if (parent == null || parent.field(direction) == null) {
				return false;
			}

			// Did we find the fieldName?
			if (parent.field(direction).name === fieldName) {
				return true;
			}

			// Continue on to the next parent
			return hasFieldAsParent(parent, direction, fieldName);
		};

		const hasNullSummaryWithSiblings = function(sumCell, direction) {

			const parent         = sumCell.parent(direction);
			let childrenOfParent = null;
			if (parent) {
				childrenOfParent = parent.children(direction);
			}

			if (
				sumCell.field(direction)
				&& sumCell.value(sumCell.field(direction).dataField) === null
				&& childrenOfParent && childrenOfParent.length > 1
			) {
				return true;
			}

			if (parent == null) {
				return false;
			}

			// Continue on to the next parent
			return hasNullSummaryWithSiblings(parent, direction);
		};

		// Test for Year actually in row/column hierarchy as parent (above summarycell) or single year set at the tab level
		const yearAsParentInRow = hasFieldAsParent(summaryCell, 'row', 'year') || tab.years[1] === null || tab.years[1] === tab.years[0];
		const yearAsParentInCol = hasFieldAsParent(summaryCell, 'column', 'year') || tab.years[1] === null || tab.years[1] === tab.years[0];

		// Test for Government actually in row/column hierarchy as parent (above summarycell) or single government set at the tab level
		const govtAsParentInRow =
			      hasFieldAsParent(summaryCell, 'row', 'government')
			      || (Array.isArray(tab.governments) && tab.governments.length === 1);
		const govtAsParentInCol =
			      hasFieldAsParent(summaryCell, 'column', 'government')
			      || (Array.isArray(tab.governments) && tab.governments.length === 1);

		return (summaryCell.field('row') && hasNullSummaryWithSiblings(summaryCell, 'row') && !(yearAsParentInRow && govtAsParentInRow))
			|| (summaryCell.field('column') && hasNullSummaryWithSiblings(summaryCell, 'column') && !(yearAsParentInCol && govtAsParentInCol));
	}

	// Replacement calculateSummaryValue logic for all data/measure fields
	calculateSummaryValueForPhantomYear(tab: Tab, summaryDisplayMode = null) {
		const self = this;

		return function(sumCell) {
			const summaries = {
				percentOfColumnGrandTotal: function(e) {
					return calculatePercentValue(e.value(), e.grandTotal('row').value());
				},
				percentOfGrandTotal:       function(e) {
					return calculatePercentValue(e.value(), e.grandTotal().value());
				}
			};

			const isDefined = function(object) {
				return null !== object && void 0 !== object;
			};

			const calculatePercentValue = function(value, totalValue) {
				let result = value / totalValue;
				if (!isDefined(value) || isNaN(result)) {
					result = null;
				}
				return result;
			};

			if (self.hidePhantomYearSummary(sumCell, tab)) {
				return null;
			}
			else {
				if (!summaryDisplayMode) {
					return sumCell.value(); // This only works for non-summaryMode cases
				}
				else {
					return summaries[summaryDisplayMode](sumCell);
				}
			}

		};
	}

	calculateSummaryValueForPhantomYearDummy(tab: Tab, summaryDisplayMode = null) {
		const self = this;
		return function(sumCell) {
			const summaries = {
				percentOfColumnGrandTotal: function(e) {
					return calculatePercentValue(e.value(), e.grandTotal('row').value());
				},
				percentOfGrandTotal:       function(e) {
					return calculatePercentValue(e.value(), e.grandTotal().value());
				}
			};

			const isDefined = function(object) {
				return null !== object && void 0 !== object;
			};

			const calculatePercentValue = function(value, totalValue) {
				let result = value / totalValue;
				if (!isDefined(value) || isNaN(result)) {
					result = null;
				}
				return result;
			};

			if (self.hidePhantomYearSummary(sumCell, tab)) {
				return null;
			}
			else {
				if (!summaryDisplayMode) {
					return sumCell.value(); // This only works for non-summaryMode cases
				}
				else {
					return summaries[summaryDisplayMode](sumCell);
				}
			}
		};
	}

	/** Cell summary logic for track D
	 * 1) Sets summary total to 'No Data' to start
	 * 2) Calculates summary. If the cell value has an amount, starts aggregation. If it doesn't have an amount, checks if aggregation total is a number, if it is, moves on to the next
	 * cell value calculation. If the aggregation total isn't an amount, replaces summary total with correct string based on filing condition, pending updates, or government status (logic in spec).
	 */
	calculateSummaryValueForPhantomYearForTrackD(aggregationAmountField: string) {
		return (options) => {
				switch (options.summaryProcess) {
					case 'start':
						options.totalValue = 'No Data';
						break;
					case 'calculate':
						// if current row has value, replace 'no data' with number and add amount or just add amount
						// aggregationAmountField represents the amount property that needs to be summarized, for sched 1 - amount, for sched 9 - ending
						if (options.value[aggregationAmountField]) {
							if (options.totalValue === 'No Data') {options.totalValue = 0; }
							options.totalValue += options.value[aggregationAmountField];
						} else {
							// If current row doesn't have value, check if the total value is a number.
							// If so, do nothing. If not, check filing condition and replace total value with updated text.
							if (typeof options.totalValue === 'number') {
								return;
							} else {
									if (this.filingStatusService.filerConditions.includes(options.value.filingCondition)) {
										if (options.value.pendingUpdates) {
											options.totalValue = 'Pending Updates';
										}
									} else {
										if (options.value.governmentStatus === 'Active') {
											options.totalValue = 'No Filing';
										} else {
											options.totalValue = 'Inactive';
										}
									}
								}
							}
						break;
					case 'finalize':
						// We need to format into a text field in order for the sorting to operate
						// properly on the mix of text and numeric aggregation values
						// format as 'XXXXXXX|999999999999.99'
						if (typeof options.totalValue === 'number') {
							options.totalValue = '|' + String(options.totalValue.toFixed(2)).padStart(15, '0');
						}
						else if (options.totalValue === 'No Data') {
							// handles 'No Data' case, annotates 'No Data' as '0'
							options.totalValue = '|' + String(Number(0).toFixed(2)).padStart(15, '0');
						}
						else {
							options.totalValue = options.totalValue + '|';
						}
						break;
				}
		};
	}

	// endregion

	// region Comparisons

	// Return the corresponding value for the Primary Government to use as a baseline
	// primaryGovernment is the currently-displayed value generated by the government
	// field's selector
	getBaselineValue(summaryCell, primaryGovernment) {
		// $log.debug('UnitsService.getBaselineValue', summaryCell, primaryGovernment);

		// Currently only supports column. How do we determine if it needs to look through rows?
		const direction = 'column';
		// Get path to the top, with values for the summaryCell in question
		const path      = this.getFieldPathWithValues(summaryCell, direction);
		// start at the top
		let baseline    = summaryCell.grandTotal(direction);

		// Walk down path to the lowest level, swapping out any necessary values
		path.forEach(function(p) {
			let childValue = p.value;
			if (p.name === 'peerGroup') {
				childValue = 'Baseline Government';
			}
			if (p.name === 'government') {
				childValue = primaryGovernment;
			}

			baseline = baseline?.child(direction, childValue);
		});

		return (baseline && baseline.value()) || 0;
	}

	// Accumulates path and value to the "top" of a given direction starting from a given summaryCell
	getFieldPathWithValues(summaryCell, direction, path = []) {
		const field = summaryCell.field(direction);
		if (field === null) {
			return path;
		}
		else { // no more parents
			this.getFieldPathWithValues(summaryCell.parent(direction), direction, path);
		}

		const parent = summaryCell.field(direction).name;
		path.push({
			name:  parent,
			value: summaryCell.value(parent)
		});

		return path;
	}

	differenceByDollars = (summaryCell, primaryGovernment) => {
		const baseVal = this.getBaselineValue(summaryCell, primaryGovernment);
		return summaryCell.value()
			? summaryCell.value() - baseVal
			: null;
	}

	differenceByPercent = (summaryCell, primaryGovernment) => {
		// $log.debug('UnitsService.differenceByPercent', summaryCell, primaryGovernment);
		const baseVal = this.getBaselineValue(summaryCell, primaryGovernment);
		return summaryCell.value() && baseVal !== 0
			? -(1 - (summaryCell.value() / baseVal))
			: null;
	}

	// endregion

	// region Units

	dollars(tab, data) {
		const measure = this.getConfigItem('measure', tab);
		return data[measure.dataField];
	}

	dollarsK = (tab, data) => this.dollars(tab, data);

	dollarsM = (tab, data) => this.dollars(tab, data);

	capitaPerDollar(tab, data) {
		const pop = this.format.getPopulation(data, tab);
		return this.dollars(tab, data) / pop;
	}

	capitaPerDollarUnincorp(tab, data) {
		const pop = this.format.getPopulation(data, tab);
		return this.dollars(tab, data) / pop;
	}

	// endregion
}
