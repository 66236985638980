import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import * as signalR from '@aspnet/signalr';
import dxNotify from 'devextreme/ui/notify';
import { AdminNotifyHubPayload, AdminNotifyHubMethod, AdminNotifyHubProgress } from 'app/shared/models/admin-notify-hub';
import { environment } from 'environments/environment';
import { UserService } from './user.service';
import { Subject } from 'rxjs';
import { UserInterfaceService } from './user-interface.service';
import { Toast } from '../models/toast';

@Injectable({
	providedIn: 'root'
})
export class AdminNotifyHubService {

	private messageTimeout = 5000;
	private endpoint = `${environment.base}/notify/admin`;
	private connection = new signalR.HubConnectionBuilder()
		.configureLogging(signalR.LogLevel.Information)
		.withUrl(this.endpoint)
		.build();

	public dataExtractProgress = new Subject<AdminNotifyHubProgress>();

	constructor(
		private logger: LoggerService,
		private userService: UserService,
		private uiService: UserInterfaceService
	) {
		this.userService.user.subscribe(user => {
			// Start the websocket connection if the user is FIT Admin
			if(user.hasAdminRole) {
				this.connection.start()
					.then(() => this.logger.info(`${this.constructor.name} started listening to ${this.endpoint}`))
					.catch(err => this.logger.error);
			} else {
				// stop if we were previously started
				if(this.connection.state) {
					this.connection.stop()
						.then(() => this.logger.info(`${this.constructor.name} stopped listening to ${this.endpoint}`));
				}
			}
		});

		this.connection.on(AdminNotifyHubMethod.taskBegin, payload => this.onTaskBegin(payload));
		this.connection.on(AdminNotifyHubMethod.taskProgress, payload => this.onTaskProgress(payload));
		this.connection.on(AdminNotifyHubMethod.taskEnd, payload => this.onTaskEnd(payload));
	}

	private onTaskBegin = (payload: AdminNotifyHubPayload) => {
		this.logger.info(`${this.constructor.name}: A task began`, payload);
		this.notify(payload);
	}

	private onTaskProgress = (payload: AdminNotifyHubProgress) => {
		this.logger.info(`${this.constructor.name}: Received progress`, payload);
		if(payload.type === 'DataExtracts') {
			this.dataExtractProgress.next(payload);
		}
	}

	private onTaskEnd = (payload: AdminNotifyHubPayload) => {
		this.logger.info(`${this.constructor.name}: A task ended`, payload);
		this.notify(payload);
	}

	private notify = (payload: AdminNotifyHubPayload) => {
		let displayTime = this.messageTimeout;
		if(!payload.message) {
			return;
		}
		if(payload.type === 'success') {
			displayTime = 30000;
		}

		this.uiService.showToast(payload.type, payload.message, displayTime);
	}

}
