import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DxPopupComponent} from 'devextreme-angular';
import {Subject, Subscription} from 'rxjs';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {UserService} from '../../shared/services/user.service';

@Component({
	selector: 'app-interactive-filing-statistics',
	templateUrl: './interactive-filing-statistics.component.html',
	styleUrls: ['./interactive-filing-statistics.component.scss']
})
export class InteractiveFilingStatisticsComponent implements OnInit {
	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	closePopup: Subject<void> = new Subject<void>();

	isVisible = true;
	private uiSubscription: Subscription;

	constructor(private uiService: UserInterfaceService, private userService: UserService) {
		this.uiSubscription = this.uiService.interactiveFilingStatistics$.subscribe(
			value => this.isVisible = value);
	}

	ngOnInit(): void {

	}

	onShowing() {
		this.userService.setFitByTheNumbersViewed();
	}

	onHidden() {
		this.closePopup.next();
	}
}
