<dx-popup
	class="peer-set-viewer"
	maxWidth="60%"
	height="98%"
	[dragEnabled]="false"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	[deferRendering]="true"
	[title]="'Your Saved Peer Sets (' + myPeerSets.length + ')'"
>
	<div *dxTemplate="let data of 'content'" class="app-popup__wrapper">
		<form class="form" action="#" (submit)="submit($event)">
			<app-tab-bar
				[items]="tabBarItems"
				[selectedId]="selectedTabBarId"
				[hasCheck]="true"
				(onSelect)="handleTabBarSelect($event)"
			></app-tab-bar>
			<div class="app-popup__inner">

				<ng-container *ngIf="selectedTabBarId === 'existing'">
					<ng-container *ngIf="myPeerSets && myPeerSets.length > 0">
						<div class="peer-set" *ngFor="let peerSet of myPeerSets">
							<div class="title">
								<h4>{{ peerSet.name }}</h4>

								<span class="actions">
								<button href="#" (click)="edit(peerSet)">
									<fa-icon [icon]="icons.edit"></fa-icon>
								</button>
								<button href="#" (click)="confirmDelete(peerSet)">
									<fa-icon [icon]="icons.delete"></fa-icon>
								</button>
								<button href="#" (click)="copy(peerSet)">
									<fa-icon [icon]="icons.copy"></fa-icon>
								</button>
							</span>
							</div>

							<p class="peer-set__description" *ngIf="peerSet.description">
								{{ peerSet.description }}
							</p>

							<ol class="peer-set__items">
								<li
									*ngFor="let peer of peerSet.peers"
									class="peer-set__item"
								>
									<fa-icon class="peer-set__icon peer-set__icon--building" [icon]="icons.building"></fa-icon>
									<span>{{ peer.entityNameWithDba }}</span>
									<wasao-badge *ngIf="peer.prime" text="Baseline"></wasao-badge>
								</li>
								<li *ngIf="isSelectMode" class="select">
									<dx-button (click)="select(peerSet)" type="default">Select</dx-button>
								</li>
							</ol>
						</div>
					</ng-container>

					<p *ngIf="!myPeerSets || myPeerSets.length === 0">
						You haven't created any peer sets yet. Start by
						<button href="#" (click)="create()">creating one</button>.
					</p>
				</ng-container>

				<ng-container *ngIf="selectedTabBarId === 'create' || selectedTabBarId === 'edit'">
					<app-peer-set-editor
						[peerSet]="currentPeerSet"
					></app-peer-set-editor>
				</ng-container>
			</div>
			<div class="app-popup__footer">
				<dx-validation-summary></dx-validation-summary>
				<dx-button *ngIf="selectedTabBarId === 'existing'" type="default" (click)="isVisible = false">Done</dx-button>
				<dx-button *ngIf="selectedTabBarId !== 'existing'" type="default" useSubmitBehavior="true">Save Peer Set</dx-button>
			</div>
		</form>
	</div>
</dx-popup>
