<ng-container>
	<div [ngClass]="{'scroll': !isCombined}">
		<div class="outer-container">

			<div *ngIf="!isBannerHidden" class="banner-container">
				<img class="banner-image" src="assets/images/fit_by_the_numbers_countdown_bg.png"
					 alt="header image"/>

				<div *ngIf="beforeOrOnDueDate" class="banner-txt">Annual Filing Countdown</div>
				<div *ngIf="!beforeOrOnDueDate" class="banner-txt-after-due-date">Annual Filing Performance</div>
				<div *ngIf="!beforeOrOnDueDate" class="banner-ribbon">{{currentFilingYearForDisplay}} is Closed!</div>
				<div *ngIf="isStandAlone" class="sao-container"><a href="https://sao.wa.gov/" target="_blank"><img
					class="sao-image" src="assets/images/fit_by_the_numbers_sao_seal.png"
					alt="header image"/></a>
				</div>

				<div *ngIf="beforeOrOnDueDate">
					<div class="calender-txt">
						<div class="calendar">
							<div class="month background">{{monthNames[submissionDueDate?.getMonth()]}}</div>
							<div class="date">{{submissionDueDate?.getDate()}}<span class="date-after">th</span></div>
							<div class="redDue">DUE DATE</div>
						</div>
					</div>

					<div class="countdown-container">
					<span class="flipclock">
						{{daysTilSubmissionDueDate}}
    				</span>
					</div>
					<div class="flipclock-txt">
						<span *ngIf="daysTilSubmissionDueDate === 1 else moreThanOneDay">
						<div>DAY</div>
						<div>LEFT</div>
					</span>
						<ng-template #moreThanOneDay>
					<span>
						<div>DAYS</div>
						<div>LEFT</div>
					</span>
						</ng-template>
					</div>
				</div>


				<div *ngIf="!beforeOrOnDueDate" class="percentage-container">
					<span class="flipclock">
						{{math.round(percentageTotal)}}%
					</span>
					<div class="flipclock-txt-percentage">
						<div>FILED<sup>3</sup></div>
						<div>ON TIME</div>
					</div>
				</div>
			</div>

			<div class="inner-container">
				<div class="gov-type-dropdown" *ngIf="!isStandAlone">
					<dx-select-box
						#govTypeSelectBox
						[width]="'100%'"
						[dataSource]="governmentTypesDataSource"
						valueExpr="value"
						displayExpr="name"
						(onSelectionChanged)="onGovernmentTypeSelectionChanged($event)"
						placeholder="Choose Government Type"
					></dx-select-box>
				</div>

				<div class="gov-type-text"><h2>Annual reports filed as of today
					{{selectedGovernmentType.name === 'All Government Types' ? 'for all' : '\nfor ' + selectedGovernmentType.name}}
					governments<sup>1</sup>...</h2></div>

				<div class="column-containers">
					<div *ngIf="beforeOrOnDueDate">
						<div class="row">
							<div class="column"><h2 class="green">{{yearlyFilingStatusForDisplay?.filed}}</h2></div>
							<div class="column"><h2 class="yellow">{{yearlyFilingStatusForDisplay?.started}}</h2></div>
							<div class="column"><h2 class="red">{{yearlyFilingStatusForDisplay?.notStarted}}</h2></div>
						</div>
						<div class="row">
							<div class="column"><h2>Filed<sup>2</sup></h2></div>
							<div class="column"><h2>Started</h2></div>
							<div class="column"><h2>Not Started</h2></div>
						</div>
					</div>

					<div *ngIf="!beforeOrOnDueDate">
						<div class="row">
							<div class="column"><h2 class="green">{{yearlyFilingStatusForDisplay?.timelyFilers}}</h2></div>
							<div class="column"><h2 class="yellow">{{yearlyFilingStatusForDisplay?.lateFilers}}</h2>
							</div>
							<div class="column"><h2 class="red">{{yearlyFilingStatusForDisplay?.nonFilers}}</h2></div>
						</div>
						<div class="row">
							<div class="column"><h2>Timely Filers</h2></div>
							<div class="column"><h2>Late Filers</h2></div>
							<div class="column"><h2>Non-Filers</h2></div>
						</div>
					</div>
				</div>

				<div class="map-grid-container">
					<div *ngIf="!isStandAlone" class="toggle-container">
						<div
							[ngClass]="{'toggled' : buttonClicked === 'map', 'untoggled': buttonClicked === 'grid'}">
							<button (click)="toggleMap()"
									[ngClass]="{'toggled' : buttonClicked === 'map', 'untoggled': buttonClicked === 'grid'}">
								<fa-icon [icon]="icons.map"></fa-icon>
								Map
							</button>
						</div>

						<div
							[ngClass]="{'toggled' : buttonClicked === 'grid', 'untoggled': buttonClicked === 'map'}">
							<button (click)="toggleGrid()"
									[ngClass]="{'toggled' : buttonClicked === 'grid', 'untoggled': buttonClicked === 'map'}">
								<fa-icon [icon]="icons.table"></fa-icon>
								Table
							</button>
						</div>
					</div>

					<div class="map-grid-item">
						<div *ngIf="mapShown else gridShown">
							<dx-vector-map id="vectorMap"
										   [bounds]="[-124.763068, 49.002494, -116.915989, 45.543541]"
										   [zoomingEnabled]="false"
										   [panningEnabled]="false"
										   (onClick)="mapClick($event)"
							>
								<dxo-size
									[height]="525"
								>
								</dxo-size>
								<dxo-background
									borderColor="transparent"
								></dxo-background>
								<dxo-control-bar [enabled]="false"></dxo-control-bar>
								<dxo-tooltip
									[enabled]="true"
									[customizeTooltip]="customizeMapTooltip"
									zIndex="10000"
								></dxo-tooltip>
								<dxi-layer name="counties" [dataSource]="mapLayers?.countyShapes"
										   [hoverEnabled]="false"></dxi-layer>
								<dxi-layer
									name="summaryFilingStatuses"
									elementType="pie"
									[dataSource]="mapLayers?.countySummaryFilingStatuses"
									[dataField]="summaryFilingStatusMode"
									[palette]="chartColorPalette"
								></dxi-layer>
							</dx-vector-map>
						</div>
					</div>

					<ng-template #gridShown>
						<dx-data-grid
							[dataSource]="dataSource"
							[height]="525"
							[width]="'100%'"
						>

							<dxi-column dataField="year"></dxi-column>
							<dxi-column caption="County" dataField="countyCode" sortOrder="asc"
										[calculateDisplayValue]="calculateDisplayValue"></dxi-column>

							<dxi-column [visible]="!beforeOrOnDueDate" dataField="lateFilers"></dxi-column>
							<dxi-column [visible]="!beforeOrOnDueDate" dataField="nonFilers"></dxi-column>
							<dxi-column [visible]="!beforeOrOnDueDate" dataField="filers"></dxi-column>


							<dxi-column [visible]="beforeOrOnDueDate" dataField="filed"></dxi-column>
							<dxi-column [visible]="beforeOrOnDueDate" dataField="started"></dxi-column>
							<dxi-column [visible]="beforeOrOnDueDate" dataField="notStarted"></dxi-column>

						</dx-data-grid>
					</ng-template>
				</div>

				<div *ngIf="beforeOrOnDueDate" class="footer-container">
					<div class="footer-item">
						<wasao-annual-filing-explanation
							[dueDate]="submissionDueDate"></wasao-annual-filing-explanation>
					</div>
					<div class="footer-item">
						<wasao-annual-filing-footnotes [numberOfNotes]="2"></wasao-annual-filing-footnotes>
					</div>
				</div>

				<div *ngIf="!beforeOrOnDueDate" class="footer-container">
					<div class="footer-item">
						<wasao-annual-filing-explanation
							[dueDate]="submissionDueDate"></wasao-annual-filing-explanation>
					</div>
					<div class="footer-item">
						<wasao-annual-filing-footnotes [numberOfNotes]="3"></wasao-annual-filing-footnotes>
					</div>
				</div>

				<div *ngIf="isStandAlone && !isCombined" class="footer-item">
					<wasao-annual-filing-footer></wasao-annual-filing-footer>
				</div>
			</div>
		</div>
	</div>
	<wasao-loading [isLoading]="isLoading" [message]="loadingMessage"></wasao-loading>
</ng-container>
