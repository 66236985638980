<div class="toolbar">
	<div>
		<dx-button
			text="Copy from prior year"
			(onClick)="copyFromPrevious()"
		></dx-button>
	</div>
	<div *ngIf="hasExistingRecords === true">
		<fa-icon [icon]="warningIcon" class="warning"></fa-icon>
		A crosswalk has already been created for this vintage year. Copying from a prior year will overwrite any records
		that have been changed since the prior year data was last copied.
	</div>
</div>
<div class="grid-container">
	<dx-data-grid
		[dataSource]="dataSource"
		[disabled]="isLoading"
		allowColumnResizing="true"
	>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-editing [allowUpdating]="true" mode="cell" startEditAction="click"></dxo-editing>
		<dxo-export [enabled]="true"></dxo-export>
		<dxi-column width="125" dataField="countyId" [allowEditing]="false" caption="County" dataType="number" cssClass="readonly">
			<dxo-lookup [dataSource]="countiesLookup" valueExpr="Value" displayExpr="Text"></dxo-lookup>
		</dxi-column>
		<dxi-column width="125" dataField="districtType" [allowEditing]="false" dataType="string" cssClass="readonly"></dxi-column>
		<dxi-column width="125" dataField="districtId" [allowEditing]="false" dataType="string" cssClass="readonly"></dxi-column>
		<dxi-column width="350" dataField="mcag" caption="Government" dataType="string">
			<dxo-lookup [dataSource]="governmentsLookup" valueExpr="MCAG" displayExpr="EntityName" [allowClearing]="true"></dxo-lookup>
		</dxi-column>
		<dxi-column width="200" dataField="status" dataType="string">
			<dxo-lookup [dataSource]="crosswalkStatuses" valueExpr="id" displayExpr="label"></dxo-lookup>
		</dxi-column>
		<dxi-column dataField="notes" dataType="string"></dxi-column>
	</dx-data-grid>
</div>
<wasao-loading [isLoading]="isLoading"></wasao-loading>
