import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Tab} from 'app/shared/models/tab';
import {LoggerService} from 'app/shared/services/logger.service';
import {ProjectionService} from '../projection.service';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {DxSelectBoxComponent, DxTreeListComponent} from 'devextreme-angular';
import {
	AdjustmentType,
	FundReset,
	Projection,
	ProjectionEdit,
	ProjectionEditRow,
	ProjectionEditYear
} from '../projection';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Fund} from '../../../modules/services/fund-service/models/fund';
import {FitApiService} from '../../../modules/api/fit-api/fit-api.service';
import {FundTypeId} from '../../../modules/services/fund-service/models/fund-type';

@Component({
	selector: 'app-projection-editor',
	templateUrl: './projection-editor.component.html',
	styleUrls: ['./projection-editor.component.scss']
})
export class ProjectionEditorComponent implements OnInit, AfterViewInit {

	// presentation
	@ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;
	@ViewChild(DxSelectBoxComponent, { static: false }) fundSelectBox: DxSelectBoxComponent;
	addIcon = faChevronRight;
	removeIcon = faChevronLeft;

	// bindings
	@Input() tab: Tab;
	@Output() projectionUpdate = new EventEmitter<Projection>();

	/** Projection result */
	projection: ProjectionEdit;
	/** Active set in editor - one fund at a time */
	dataSet = Array<ProjectionEditRow>();
	year: number;
	projectionYears: Array<number>;
	showAccountCodes = true; // TODO implement User options
	projectionCount = 3; // default
	name: string;
	notes: string;
	funds: Array<Fund>;
	fundValue: string;
	showGAAPEnterpriseFundNote = false;

	constructor(
		private fitApi: FitApiService,
		private service: ProjectionService,
		private logger: LoggerService,
		private snapshotService: SnapshotService
	) {
		this.service.resetEditor.subscribe(funds => this.resetFunds(funds));
		this.service.savedProjectionId.subscribe( id => this.projection.savedProjectionId = id);
	}

	ngOnInit() {
		this.snapshotService.getSnapshot().subscribe(snapshot => {
			this.service.getBaseYear(this.tab.governments[0].mcag, snapshot.id).subscribe(baseYear => {
				this.setYears(baseYear, this.projectionCount);
			});
		});
	}

	setYears = (baseYear: number, count: number) => {
		const result = [];
		for (let i = 1; i <= count; i++) {
			result.push(baseYear + i);
		}
		this.year = baseYear;
		this.projectionCount = count;
		this.projectionYears = result;
	};

	ngAfterViewInit() {
		this.treeList.instance.beginCustomLoading('Loading...');

		this.service.buildEditorDataSource(this.tab.governments[0].mcag, this.projectionCount).then(result => {
			this.prepareProjectionEditorView(result);
			if (this.service.loadedProjection) {
				this.loadProjectionForEdit(this.service.loadedProjection);
				this.service.loadedProjection = null;
			}
			this.treeList.instance.endCustomLoading();
		},
		reject => {
			this.logger.error('projection.service buildDataSource promise rejected: ', reject);
			this.treeList.instance.endCustomLoading();
		});
	}

	/**
	 * Standard prep for editor view component once data is loaded
	 * @param newEditor
	 */
	prepareProjectionEditorView(newEditor: ProjectionEdit) {
		this.projection = newEditor;
		this.funds = newEditor.funds
			.map(f => new Fund(f.fund.fundNumber, f.fund.fundName, f.fund.fundTypeId))
			.sort((a, b) => a.fundNumber?.localeCompare(b.fundNumber));
		this.service.editingMultipleFunds.next(this.funds?.length > 1);

		// Use first fund in the result list
		this.fundValue = this.funds[0].fundNumber;
		this.service.editorFundValue.next(this.fundValue);
		this.dataSet = newEditor.funds[0].editRows;
		this.setGAAPEnterpriseNoteFlag();
	}

	setGAAPEnterpriseNoteFlag() {
		// Get Filing Basis for base year & set note flag
		this.fitApi.getFilingStatusesForMCAG(this.tab.governments[0].mcag, this.tab.snapshotId).subscribe(
			result => {
				const filingBasis = result?.find(x => x.year === this.year)?.filingBasis;
				const fundTypeId = this.funds?.find(f => f.fundNumber === this.fundValue)?.fundTypeId;
				this.showGAAPEnterpriseFundNote = filingBasis === 'GAAP' && fundTypeId === FundTypeId.enterprise;
			},
			error => {
				this.showGAAPEnterpriseFundNote = false;
			}
		);
	}

	/** Returns the digit in the pattern array[0].property */
	extractIndexFromString = (key: string) =>
		Number(/[\d]/.exec(key)[0]);

	/**
	 * newData is the data that is being updated, oldData is a reference to the row.
	 * Assumes only ONE value per change event
	 */
	onRowUpdating = event => {
		const projectionRow = <ProjectionEditRow>event.oldData;
		// dx sends back the updated value bound to arrays strangely
		// e.g. 'futureYears[0]'.adjustmentValue instead of updating futureYears[0].adjustmentValue
		// Can only be one new key in newData
		if (Object.keys(event.newData).length > 1) {
			throw new Error('Can only change 1 value per update.');
		}
		const key = Object.keys(event.newData)[0];
		const index = this.extractIndexFromString(key); // returns null if property name is not an array w/ index
		const update = event.newData[key];

		// Handle case where the update might be to the amount field.  In this case, we translate this to a percentage
		// and proceed as we would if the user entered a percentage adjustment
		let adjustment = update.adjustmentValue;
		if (update.amount === 0 || update.amount) {
			// user entered a $ amount, convert to percentage
			const priorYearAmount = this.getPriorYearAmount(projectionRow, index);
			adjustment = (update.amount / priorYearAmount) - 1;
		}

		// Propagate this user change to all parts of the editor that are affected
		this.propagateRowPercentAdjustment(event.oldData, index, adjustment);
		// Recalculate the beginning and ending balances
		ProjectionEditRow.calculateBalances(this.dataSet);
		// Repaint the fund select box (to show the annotations for user edits)
		this.fundSelectBox.instance.repaint();
		// Export/emit savable Projection data to parent component
		this.exportProjection();
	};

	/**
	 * Disable editing for cells that are flagged as not editable
	 * @param event
	 */
	onEditorPreparing = event => {
		const projectionRow = <ProjectionEditRow>event.row.data;
		if (!projectionRow.isEditable) {
			event.editorOptions.disabled = true;
		}
	};

	// Annotate fund name in dropdown if there are user edits on that fund
	getAnnotatedFundName = (fund) => {
		if (fund && this.projection.hasUserEditsForFund(fund.fundNumber)) {
			return fund.fundFullName + ' (has projection adjustments)';
		}
		return fund?.fundFullName;
	}

	/**
	 * For adjustment cells, returns type of adjustment as class name
	 * @param cell
	 */
	getAdjustmentCellClass = (cell) => {
		let result = '';
		const index = this.extractIndexFromString(cell.column.dataField);
		// make sure we do not process an index that doesn't exist in the dataSource anymore
		if (index !== null && cell.data.futureYears[index]) {
			result = cell.data.futureYears[index].adjustmentType;
		}

		return result;
	};

	/**
	 * Calculate difference between base year and prior year
	 * @param cell
	 */
	priorToBaseDifference = (cell: ProjectionEditRow) =>
		(cell.baseYearAmount / cell.priorYearAmount) - 1;

	/**
	 * Handle user changes selected Fund; switch out data source for treelist
	 * @param event
	 */
	onFundChanged = (event) => {
		this.dataSet = this.projection.getProjectionEditRows(event.value);
		this.service.editorFundValue.next(event.value);
		this.setGAAPEnterpriseNoteFlag();
	}

	/**
	 * Propagates all effects of a change to the adjustments in a row
	 * 1) Properly update that projection year
	 * 2) Inherit this user update down through children
	 * 3) Rollup new totals through ancestors
	 * 4) Update future projection years as needed
	 * @param updatingRow
	 * @param projectionYearIndex
	 * @param percent
	 * @private
	 */
	private propagateRowPercentAdjustment(updatingRow: ProjectionEditRow, projectionYearIndex: number, percent: number) {
		const updatedProjectionEditYear: ProjectionEditYear = updatingRow.futureYears[projectionYearIndex];
		const priorYearAmount = this.getPriorYearAmount(updatingRow, projectionYearIndex);
		updatedProjectionEditYear.changePercentAdjustment(percent, AdjustmentType.USER, priorYearAmount);

		this.descendantsInherit(updatingRow, projectionYearIndex);
		this.ancestorRollup(updatingRow, projectionYearIndex);

		// process next projection years if present & applicable
		this.updateFutureProjectionYears(updatingRow, projectionYearIndex, percent, AdjustmentType.USER);
	}

	/**
	 * Get prior year amount for given projection year index.  Handles case where prior year may be the base year (for the first projection year)
	 * @param updatingRow
	 * @param projectionYearIndex
	 * @private
	 */
	private getPriorYearAmount (updatingRow: ProjectionEditRow, projectionYearIndex: number): number {
		if (projectionYearIndex > 0) {  // Use prior projection year amount
			return updatingRow.futureYears[projectionYearIndex - 1].amount;
		} else {  // Use base year amount
			return updatingRow.baseYearAmount;
		}
	}

	/**
	 * Make updates to future projection years when user makes a change in an earlier year
	 * @param updatingRow
	 * @param projectionYearIndex
	 * @param percent
	 * @param adjustmentType
	 * @private
	 */
	private updateFutureProjectionYears (updatingRow: ProjectionEditRow, projectionYearIndex: number, percent: number = null, adjustmentType: AdjustmentType = null ) {
		const currentProjectionYear: ProjectionEditYear = updatingRow.futureYears[projectionYearIndex];
		const nextProjectionYearIndex: number = projectionYearIndex + 1;
		if (nextProjectionYearIndex < updatingRow.futureYears.length) {
			const nextProjectionYear: ProjectionEditYear = updatingRow.futureYears[nextProjectionYearIndex];
			if (adjustmentType === AdjustmentType.USER && nextProjectionYear.adjustmentType === AdjustmentType.DEFAULT) {
				nextProjectionYear.changePercentAdjustment(percent, AdjustmentType.USER, currentProjectionYear.amount);
				this.descendantsInherit(updatingRow, nextProjectionYearIndex);
				this.ancestorRollup(updatingRow, nextProjectionYearIndex);
				this.updateFutureProjectionYears(updatingRow, nextProjectionYearIndex, percent, adjustmentType);
			} else {
				// Recalculate future projection years without changing adjustment types
				const ultimateParent = this.getUltimateParent(updatingRow);
				this.descendantsRecalculate(ultimateParent, nextProjectionYearIndex);
				this.updateFutureProjectionYears(updatingRow, nextProjectionYearIndex);
			}
		}
	}

	/**
	 * Gets the ultimate parent (row with no parent) given a child row
	 * @param row
	 * @private
	 */
	private getUltimateParent(row: ProjectionEditRow) {
		if (row.parentId) {
			const parent = this.dataSet.find(node => node.id === row.parentId);
			return this.getUltimateParent(parent);
		} else {
			return row;
		}
	}

	/**
	 * Handles a user edit to a parent...we must inherit that percentage adjustment to all child rows)
	 * @param parent
	 * @param index
	 * @private
	 */
	private descendantsInherit(parent: ProjectionEditRow, index: number) {
		const parentPercent: number = parent.futureYears[index].adjustmentValue;
		if (parent.hasChildren) {
			const children = this.dataSet.filter(node => node.parentId === parent.id && node.baseYearAmount !== null);
			children.forEach(child => {
				const projectionEditYear: ProjectionEditYear = child.futureYears[index];
				const priorYearAmount = this.getPriorYearAmount(child, index);
				projectionEditYear.changePercentAdjustment(parentPercent, AdjustmentType.INHERITED, priorYearAmount);
				this.descendantsInherit(child, index);
			});
		}
	}

	/**
	 * Handles a simple recalculate of a tree of rows;
	 * Recalculates amount (from prior year and percent adjustement) if the adjustment type is not Calculated;
	 * Recalculates amount (from children's amounts) and then recalculated the percentage if adjustment type is Calculated
	 * @param parent
	 * @param index
	 * @private
	 */
	private descendantsRecalculate(parent: ProjectionEditRow, index: number) {
		const projectionEditYear: ProjectionEditYear = parent.futureYears[index];
		if (parent.hasChildren) {
			const children = this.dataSet.filter(node => node.parentId === parent.id && node.baseYearAmount !== null);
			children.forEach(child => {
				this.descendantsRecalculate(child, index);
			});
			if (projectionEditYear.adjustmentType === AdjustmentType.CALCULATED) {
				// Calculate totals & percentage from sibling's summed forecast and update percentage as well as amount
				const forecastTotal = children.reduce((prev, cur) => prev + cur.futureYears[index].amount, 0);
				projectionEditYear.amount = forecastTotal;
				const priorYearAmount = this.getPriorYearAmount(parent, index);
				const calcPercent = (forecastTotal - priorYearAmount) / priorYearAmount;
				projectionEditYear.adjustmentValue = calcPercent;
			} else {
				const priorYearAmount = this.getPriorYearAmount(parent, index);
				projectionEditYear.applyPercentAdjustment(priorYearAmount);
			}
		} else {
			const priorYearAmount = this.getPriorYearAmount(parent, index);
			projectionEditYear.applyPercentAdjustment(priorYearAmount);
		}
	}

	/**
	 * Rolls up changes to ancestors of a row that the user has changed.  This method assumes that all child rows have
	 * already been updated before it is called
	 * @param child
	 * @param index
	 * @private
	 */
	private ancestorRollup(child: ProjectionEditRow, index: number) {
		if (child.parentId != null) {
			const parent = this.dataSet.find(node => node.id === child.parentId); // Find parent of updated row
			const parentProjectionEditYear: ProjectionEditYear = parent.futureYears[index];
			const siblings = this.dataSet.filter(node => node.parentId === parent.id && node.baseYearAmount !== null); // Find all siblings

			// Switch inherited siblings to user entered for consistency; leave default or zero adjustments as-is
			if (parentProjectionEditYear.adjustmentType !== AdjustmentType.CALCULATED
			 	&& child.futureYears[index].adjustmentType !== AdjustmentType.INHERITED) {
					siblings.forEach(sibling => {
						if (sibling.futureYears[index].adjustmentType === AdjustmentType.INHERITED) {
							sibling.futureYears[index].adjustmentType = AdjustmentType.USER;
						}
					});
			}

			// Calculate totals & percentage from sibling's summed forecast
			const forecastTotal = siblings.reduce((prev, cur) => prev + cur.futureYears[index].amount, 0);
			parentProjectionEditYear.amount = forecastTotal;

			if (child.futureYears[index].adjustmentType === AdjustmentType.USER
				|| child.futureYears[index].adjustmentType === AdjustmentType.CALCULATED) {
				const priorYearAmount = this.getPriorYearAmount(parent, index);
				const calcPercent = priorYearAmount === forecastTotal ? 0.00 : (forecastTotal - priorYearAmount) / priorYearAmount;
				parentProjectionEditYear.adjustmentValue = calcPercent;
				parentProjectionEditYear.adjustmentType = AdjustmentType.CALCULATED;
			}

			this.ancestorRollup(parent, index);
		}
	}


	/**
	 * Prepares savable projection that can be applied to LGFRS reports for parent component(s);
	 * We only need to save the user edit rows (this is the most efficient and we can handle more easily
	 * when applying to the LGFRS reports
	 */
	exportProjection() {
		const savableProjection = this.projection.generateProjection(this.tab.governments[0].mcag, this.name, this.notes);
		this.projectionUpdate.emit(savableProjection);
	}

	/**
	 * Load saved projection into projection editor
	 * @param projection
	 */
	loadProjectionForEdit = (projection: Projection) => {
		// Adjust years as needed
		while (projection.count > this.projectionCount) {
			this.addYear(false);
		}
		while (projection.count < this.projectionCount) {
			this.removeYear(false);
		}

		// Apply all saved adjustments to editor
		projection.adjustmentRows.forEach(adjustment => {
			// Locate applicable row in editor
			const editorRow = this.projection.funds.find(f => f.fund?.fundNumber === adjustment?.fundNumber)
				.editRows.find(r => r.rowType === adjustment.rowType && r.id === adjustment.accountId);
			if (editorRow) {
				editorRow.futureYears.forEach((y, index) => {
					if (adjustment.futureYearAdjustments[index]) {
						// Propagate this user change to all parts of the editor that are affected
						this.propagateRowPercentAdjustment(editorRow, index, adjustment.futureYearAdjustments[index]);
					}
				});
			}
		});

		// Recalculate the beginning and ending balances
		this.projection.calculateBalances();

		// Preserve storage id for future saves & load other editable values
		this.projection.savedProjectionId = projection.id;
		this.name = projection.name;
		this.notes = projection.notes;
	}

	/**
	 * Adds a new projection year to the editor
	 */
	addYear(wasEdited: boolean = true) {
		this.projection.funds.forEach(f => f.editRows.forEach(r =>  {
			const latestAdjustment = r.futureYears[r.futureYears.length - 1];
			const newProjectionYear = new ProjectionEditYear(null, latestAdjustment.adjustmentValue, latestAdjustment.adjustmentType);
			if (r.rowType !== 'balances') {
				newProjectionYear.applyPercentAdjustment(latestAdjustment.amount);
			}
			r.futureYears.push(newProjectionYear);
		}));
		this.projection.calculateBalances();
		this.setYears(this.year, this.projectionCount + 1);
		if (wasEdited) {
			this.exportProjection();
		}
	}

	/**
	 * Removes latest projection year from the editor
	 */
	removeYear(wasEdited: boolean = true) {
		this.projection.funds.forEach(f => f.editRows.forEach(r =>  r.futureYears.pop()));
		this.setYears(this.year, this.projectionCount - 1);
		if (wasEdited) {
			this.exportProjection();
		}
	}

	/**
	 * Reset the edited values for funds
	 */
	resetFunds = (funds: FundReset) => {
		if (funds === 'all') {
			this.projection.funds.forEach(f => {
				this.resetFundEdits(f.fund.fundNumber);
			});
		} else {
			this.resetFundEdits(this.fundValue);
		}
		this.projection.calculateBalances();
		this.fundSelectBox.instance.repaint();
		this.exportProjection();
	}

	/**
	 * Reset the edited values for a specified fund.
	 * Calling method must still emit results back to parent component
	 * @param fundNumber
	 */
	private resetFundEdits = (fundNumber: string) => {
		this.projection.funds.find(f => f.fund.fundNumber === fundNumber)
			.editRows.filter(r => r.rowType !== 'balances').forEach(r => {
				r.futureYears.filter(y => y.adjustmentType !== null).forEach( (y, index) => {
					y.amount = null;
					y.adjustmentType = AdjustmentType.DEFAULT;
					y.adjustmentValue = 0;
					const priorYearAmt = this.getPriorYearAmount(r, index);
					y.applyPercentAdjustment(priorYearAmt);
				});
			});
	}

	/**
	 * If user changes name during edit of saved projection, switch to Create mode and clear out saved id
	 */
	onNameChange() {
		if (this.projection.savedProjectionId) {
			delete this.projection.savedProjectionId;
		}
		this.exportProjection();
	}
}
