import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ReportId} from '../../../shared/models/report-id';
import {FundCategoryId} from '../../services/fund-service/models/fund-category';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {GovernmentService} from '../../services/government-service/government.service';
import {ReusableGovernmentAndTypeInputs} from '../../reusable/models/reusable-government-and-type-inputs';
import {GovernmentSpecificity} from '../../reusable/models/government-specificity';
import {LoggerService} from '../../../shared/services/logger.service';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';

@Component({
	selector: 'wasao-enterprise-services',
	templateUrl: './enterprise-services.component.html',
	styleUrls: ['./enterprise-services.component.scss']
})
export class EnterpriseServicesComponent extends ReusableGovernmentAndTypeInputs implements OnInit, OnChanges {
	@Input() trendDataClickAction: (reportId: ReportId, displayYear?: number, fieldTransformations?: Array<any>) => void;
	@Input() hasGovServices: boolean;

	reportTypes = ReportId;
	fundCategories = FundCategoryId;

	showExpenditureAmountsByCategory: boolean;

	constructor(
		filingStatus: FilingStatusService,
		fitApi: FitApiService,
		logger: LoggerService,
		private governmentService: GovernmentService,
	) {
		super(filingStatus, fitApi, logger);
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		this.callFunctionWithSpecificity(changes, this.setExpenditureObjectFlag);
	}

	setExpenditureObjectFlag = (specificity: GovernmentSpecificity) => {
		if (specificity.type === 'government') {
			this.fitApi.getLocalGovernment(specificity.id, this.snapshotId).subscribe(localGovernment =>
				this.showExpenditureAmountsByCategory = this.governmentService.isSpecialPurposeDistrict(localGovernment.govTypeCode)
			);
		} else if (specificity.type === 'governmentType') {
			this.showExpenditureAmountsByCategory = this.governmentService.isSpecialPurposeDistrict(specificity.id);
		}
	}

}
