import {ReportId} from '../../../shared/models/report';

/**
 * Represents the keys that can be used for DataStories. Currently only ReportIds are supported.
 */
export type DataStoryId = ReportId;

export class DataStory {
	id: DataStoryId;
	title: string;
	description: string;
	videoUrl: string;
}

export const DataStories: Array<DataStory> = [
	{
		id: 'revenues',
		title: 'Where does the money come from?',
		description: 'The money to operate a government comes from a variety of sources. General purpose governments like cities and counties have three main sources: taxes, charges for services and grants. Special purpose governments like water districts or hospitals, depend on fees and charges related to the service they provide. Discover the main revenue sources for your government.',
		videoUrl: 'https://www.youtube.com/embed/P4U-ygOjJXQ'
	},
	{
		id: 'expenditures',
		title: 'Where does the money go?',
		description: 'General purpose governments like cities and counties spend money on many different services, like transportation, parks, public safety, public health, social services and others.  Special purpose governments typically spend money on a single service and most of that is spent on operational costs like salaries and benefits.  Research how your government spends its money.',
		videoUrl: 'https://www.youtube.com/embed/siEi-kEyRQY'
	},
	{
		id: 'schoolsRevenuesWithOthers',
		title: 'Where does the money come from?',
		description: 'The money to operate a government comes from a variety of sources. General purpose governments like cities and counties have three main sources: taxes, charges for services and grants. Special purpose governments like water districts or hospitals, depend on fees and charges related to the service they provide. Discover the main revenue sources for your government.',
		videoUrl: 'https://www.youtube.com/embed/P4U-ygOjJXQ'
	},
	{
		id: 'schoolsExpenditures',
		title: 'Where does the money go?',
		description: 'General purpose governments like cities and counties spend money on many different services, like transportation, parks, public safety, public health, social services and others.  Special purpose governments typically spend money on a single service and most of that is spent on operational costs like salaries and benefits.  Research how your government spends its money.',
		videoUrl: 'https://www.youtube.com/embed/siEi-kEyRQY'
	},
];

