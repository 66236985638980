import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {Subscription} from 'rxjs';
import {Tab} from 'app/shared/models/tab';
import {TransitionEvent} from 'app/shared/models/transition-event';
import {PivotGridService} from '../../shared/services/pivot-grid.service';
import {ReportSummaryService} from '../../shared/services/report-summary.service';
import {FieldService} from '../../shared/services/field.service';
import {TabService} from '../../shared/services/tab.service';
import {PrimaryGovernmentPipe} from '../../shared/pipes/primary-government.pipe';
import {TransitionService} from 'app/shared/services/transition.service';
import {LoggerService} from 'app/shared/services/logger.service';
import {UserService} from 'app/shared/services/user.service';
import {User} from 'app/shared/models/user';
import {environment} from '../../../environments/environment';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {DxPivotGridComponent} from 'devextreme-angular';
import {first} from 'rxjs/operators';
import {HistoryService} from '../../shared/services/history.service';

// TODO most (all?) methods that apply updates from other components should now be performed by the ReportMenuComponent
// and should be removed

@Component({
	selector:    'app-schedule-browser',
	templateUrl: './schedule-browser.component.html',
	styleUrls:   ['./schedule-browser.component.scss']
})
export class ScheduleBrowserComponent implements OnInit, OnDestroy {
	@ViewChild('title', {static: true}) title;
	@ViewChild('narrative') narrative;
	@ViewChild('grid') grid;

	@Input() tab: Tab;

	user: User;
	primaryGovtInfo: any = {
		id:                '42',
		name:              'Seattle',
		entityNameWithDba: 'Seattle, The City of',
		website:           'seattle.gov',
		acctBasisTypeDesc: 'Very careful',
		fiscalYearEnd:     'Today',
		status:            'finished',
		govTypeDesc:       'City?',
		zip:               '98105',
		currentValue:      {
			govTypeCode: '42'
		}
	};
	snapshotSubscription: Subscription;
	snapshot             = null;
	isLoading            = false;
	isApplyingProjection = false;
	pivotGridData        = null;
	private pivotGridDataSourceSubscription: Subscription;
	pivotGridUpdate      = null;
	tabUpdate            = null;
	summary: any         = {};
	isDebug              = false;
	// feels like a hack, but can't find any way to require an interface using ng-content to communicate
	// with renderer (see: LauncherComponent)
	accountsFreeformIsVisible = false;
	pivotGridComponent: DxPivotGridComponent;

	constructor(
		private snapshotService: SnapshotService,
		private userService: UserService,
		private pivotGridService: PivotGridService,
		private reportSummary: ReportSummaryService,
		private fieldService: FieldService,
		private tabService: TabService,
		private primaryGovernment: PrimaryGovernmentPipe,
		private transition: TransitionService,
		private logger: LoggerService,
		private historyService: HistoryService,
	) {
		this.isDebug        = !environment.production;
		this.updateSummary  = this.updateSummary.bind(this);
		this.updateBaseline = this.updateBaseline.bind(this);
		this.pivotGridService.isApplyingProjection.subscribe(value => {
			this.isApplyingProjection = value;
		});
	}

	ngOnDestroy() {
		this.logger.log(`${this.constructor.name} is being destroyed...`);
		this.pivotGridDataSourceSubscription.unsubscribe();
	}

	ngOnInit() {
		this.logger.info(`ScheduleBrowserComponent.ngOnInit: initializing`, this.tab);
		this.isLoading = true;
		// this assumes that user resolves immediately
		this.userService.user.subscribe(u => this.user = u);
		this.snapshotService.getSnapshot(this.tab.snapshotId).subscribe(snapshot => {
			this.snapshot                        = snapshot;
			this.pivotGridDataSourceSubscription = this.pivotGridService.getDataSource(this.tab).subscribe(dataSource => {
				this.onPivotGridDataSourceUpdate(dataSource);
			});
		});
		this.tab.snapshotIdSubject.subscribe(snapshotId => {
			if (snapshotId !== (this.snapshot && this.snapshot.id)) {
				this.snapshotService.getSnapshot(snapshotId).pipe(first()).subscribe(snapshot => this.snapshot = snapshot);
				const fields = (this.pivotGridData && this.pivotGridData.fields()) || [];
				this.pivotGridService.rebuildDataSource(this.tab, fields);
			}
		});
	}

	onPivotGridDataSourceUpdate = (dataSource: PivotGridDataSource): void => {
		this.logger.info(`ScheduleBrowserComponent.onPivotGridDataSourceUpdate: new data source received.`);
		this.pivotGridData = dataSource;
		this.tabService.save(this.tab);
		this.pivotGridData.on('changed', this.updateSummary);  // The 'changed' event here means when the data source has finished loading

		this.tab.pivotGridSettings.populationField = this.fieldService.getFieldNameForPopulationDisplay(this.pivotGridData, this.tab);
		this.tab.pivotGridSettings.filingField = this.fieldService.getFieldNameForUniqueFilingDisplay(this.pivotGridData, this.tab);

		this.tabService.loadGovernmentMetricsForTab(this.tab).then(() => {
			this.tabUpdate = Date.now();
		});
		this.updateBaseline();
		this.isLoading = false;
	}

	updateSummary() {
		this.summary         = this.reportSummary.update(this.tab, this.snapshot, this.pivotGridData);
		this.pivotGridUpdate = Date.now();
		this.logger.info('schedule-browser, summary: ', this.summary);
	}

	updateBaseline() {
		const self   = this;
		// Update titles
		self.summary = self.reportSummary.update(self.tab, self.snapshot, self.pivotGridData);
		// Update baseline EIS information
		const prime  = self.primaryGovernment.transform(self.tab);
		if (prime) {
			self.tabService
				.loadGovernmentDetailInformation(self.tab.snapshotId, prime.mcag)
				.then(function(data) {
					self.primaryGovtInfo = data;
				});
		}
		else {
			delete self.primaryGovtInfo;
		}
	}

	updateReport({event, cancel}) {
		const self = this;
		this.logger.info('ScheduleBrowser.updateReport', event);

		const transitionEvent = new TransitionEvent('Change Report', event.value);
		this.transition.executeTransition(transitionEvent, this.pivotGridData, this.tab)
			.then(function(result) {
					self.logger.info('ScheduleBrowser.updateReport: executed transition. Result is ', result);
					if (result === 'success') {
						// notify filters
						self.pivotGridUpdate = Date.now();
					}
					else {
						// Revert to old report selection
						cancel();
					}
				}
			);
	}

	handleYearsChange(event) {
		const self = this;
		this.logger.info('ScheduleBrowser.updateYears', event);
		const transitionEvent = new TransitionEvent('Change Years', event.event.value);
		this.transition.executeTransition(transitionEvent, self.pivotGridData, self.tab)
			.then(function(result) {
					if (result === 'success') {
						// replaced inline, notify filters
						self.pivotGridUpdate = Date.now();
					}
					else {
						event.cancel();
					}
				}
			);
	}

	handleGoToProfile() {
		this.historyService.replaceStateTabs();
		this.tabService.buildProfileTab(this.tab, {
			title: this.primaryGovtInfo.entityNameWithDba
		}, true).then(() => this.historyService.pushStateTabs());
	}

	handlePivotGridLoaded(pivotGrid) {
		this.pivotGridComponent = pivotGrid;
		this.logger.info('schedule-browser.handlePivotGridLoaded');
	}

	updateFromDebtCapitalFilter(data) {
		this.logger.log('ScheduleBrowser.updateDebtCapitalFilter: Value is ', data);
		const structure = data === true ? 'debtCapitalExp' : 'Standard';
		this.fieldService.changeFinancialSummaryHierarchy(this.pivotGridData, this.tab, structure);
		this.pivotGridData.load();
		this.refreshFilters();
	}

	refreshFilters() {
		this.pivotGridUpdate = Date.now();
	}

	updateFromAccountFilter(data) {
		if (!data) {
			return;
		}

		this.logger.log('ScheduleBrowser.updateFromAccountFilter', data);

		const activeFunctionalAccountsGroup = this.fieldService.getActiveAlternateField(
			this.pivotGridData, 'financialSummaryHierarchy', 'functionalAccountsGroup'
		);
		this.fieldService.setFieldById(this.pivotGridData, activeFunctionalAccountsGroup.id, {filterValues: data});
		this.pivotGridData.load();
	}

	updateFromAccountFreeform(data) {
		this.logger.log('ScheduleBrowser.updateAccountFreeform', data);
		if (!data) {
			return;
		}

		// this.filterPopups.accountsFreeform = false;

		const transitionEvent = new TransitionEvent('Change Account Category', data);
		this.transition.executeTransition(transitionEvent, this.pivotGridData, this.tab);
		this.accountsFreeformIsVisible = false;
	}

	/**
	 * Should the chart be visible?
	 */
	get chartVisible(): boolean {
		const hiddenReports = ['summary', 'schedule01CategoryTotals', 'debtAndLiabilities'];

		return this.tab.state === 'lgfrs' &&
			!hiddenReports.includes(this.tab.report.id);
	}

}
