export class TabReuseModal {
	title = '';
	prompt = 'Okay, great! Do you want to replace what you were working on in this tab or create a new one?';
	newHint = 'We\'ll use most of your current selections to set up a new tab for this report.'
	+ ' You can always return to this tab later if you want.';
	replaceHint = 'We\'ll use most of your current selections to modify the current tab for this report.';
	isVisible = false;

	constructor(config: any = {}) {
		this.title = config.title || this.title;
		this.prompt = config.prompt || this.prompt;
		this.newHint = config.newHint || this.newHint;
		this.replaceHint = config.replaceHint || this.replaceHint;
		this.isVisible = config.isVisible;
	}
}
