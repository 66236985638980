import {FilingCondition, FilingStatus} from '../../../api/fit-api/models/filing-status';

export class SummaryFilingStatus {
	year: number;

	countyCode: number;

	total = 0; // represents total active number of governments

	filers = 0; // represents total active number of filers
	timelyFilers = 0; // represents total active number of timely filers
	lateFilers = 0; // represents total active number of late filers

	filed = 0; // represents current number of filers
	started = 0; // represents current number of filers started
	notStarted = 0; // represents current number of filers not started

	nonFilers = 0; // represents total number of non filers

	filersWithPendingUpdates = 0;

	addToSummary(row: FilingStatus) {

			this.total++;

			switch (row.filingCondition) {
				case FilingCondition.Timely:
					this.filers++;
					this.timelyFilers++;
					break;
				case FilingCondition.Late:
					this.filers++;
					this.lateFilers++;
					break;
				case FilingCondition.None:
					this.nonFilers++;
					break;
				case FilingCondition.Filed:
					this.filed++;
					break;
				case FilingCondition.Started:
					this.started++;
					break;
				case FilingCondition.NotStarted:
					this.notStarted++;
					break;
				default:
					break;
			}

			 if (row.pendingUpdates) {
					this.filersWithPendingUpdates++;
			 }

	}

	constructor(year: number, countyCode?: number) {
		this.year = year;
		this.countyCode = countyCode;
	}

}


