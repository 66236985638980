import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoggerService } from 'app/shared/services/logger.service';

@Component({
	selector: 'app-single-government-chooser',
	templateUrl: './single-government-chooser.component.html',
	styleUrls: ['./single-government-chooser.component.scss']
})
export class SingleGovernmentChooserComponent implements OnInit {
	@Input() store: any;
	@Output() changed = new EventEmitter<any>();

	constructor(private logger: LoggerService) { }

	ngOnInit() { }

	handleChange = data => this.changed.emit(data);
}
