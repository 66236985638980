import {Component, Input, OnChanges, OnInit, AfterViewInit, SimpleChanges, ViewChild} from '@angular/core';
import {Tab} from '../../shared/models/tab';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {DataSourceService} from 'app/shared/services/data-source.service';
import {TabService} from 'app/shared/services/tab.service';
import {FilterBuilderService} from 'app/shared/services/filter-builder.service';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {LoggerService} from 'app/shared/services/logger.service';
import {GeoDataService} from 'app/shared/services/geo-data.service';
import {centerOfMass, bbox} from '@turf/turf';
import {HistoryService} from '../../shared/services/history.service';
import {MapService} from '../../modules/ui/map/map.service';

import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {FitActionsService} from '../../shared/services/fit-actions.service';
import {GovernmentMetric} from '../../modules/api/fit-api/models/government-metric';
import {GovernmentType} from '../../modules/api/fit-api/models/snapshots/government-type';
import {GovernmentTypesService} from '../../shared/components/government-types/government-types.service';
import {LngLatLike} from 'mapbox-gl';
import {ExternalCommonServiceApiService} from '../../modules/api/external-common-service-api/external-common-service-api.service';
import {of} from 'rxjs';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {MandatoryFieldsEntity} from '../../modules/api/external-common-service-api/models/view-models/mandatory-fields-entity';
import {DxDataGridComponent} from 'devextreme-angular';
import {GovernmentLocation} from '../../modules/ui/map/models/government-location';

@Component({
	selector: 'app-government-search-map',
	templateUrl: './government-search-map.component.html',
	styleUrls: ['./government-search-map.component.scss']
})
export class GovernmentSearchMapComponent implements OnInit, OnChanges {

	@Input() tab: Tab;
	@ViewChild(DxDataGridComponent) dxDataGrid: DxDataGridComponent;

	private filter = {
		govTypeCodes: [] as Array<string>,
		mcags: [] as Array<string>
	};
	govClickAction;

	icons = {
		extLink: faExternalLinkAlt,
	};

	public selectedGovernmentTypeCode: string;
	public marker: LngLatLike;


	public address;

	selectedMCAG: string;

	/**
	 * Governments from ExternalCommonService in MandatoryFields format, filtered by available Government Types in FIT
	 */
	dataGridDataSource = new DataSource({
		load: (loadOptions) => {
			return this.fitApi.getFITGovernments().toPromise();
		},
		key: 'MCAG',
		loadMode: 'raw' // perform operations locally vs sending to server
	});

	/**
	 * Government Types
	 */
	governmentTypesDataSource = new DataSource({
		load: (loadOptions) => {
			return this.fitApi.getGovernmentTypes.toPromise();
		},
		loadMode: 'raw', // perform operations locally vs sending to server
		key: 'code'
	});

	addressAutocompleteSource = new DataSource({
		store: new CustomStore({
			key: 'id',
			load: (options) => {
				const {searchValue} = options;
				return this.geoDataService.getAddressList(searchValue).toPromise();
			}
		}),
		sort: 'place_name'
	});

	constructor(
		private snapshotService: SnapshotService,
		private tabService: TabService,
		private dataSource: DataSourceService,
		private filterBuilder: FilterBuilderService,
		private logger: LoggerService,
		private geoDataService: GeoDataService,
		private historyService: HistoryService,
		private mapService: MapService,
		public fitActions: FitActionsService,
		public govTypesService: GovernmentTypesService,
		private fitApi: FitApiService
	) {
	}

	ngOnInit() {
		this.govClickAction = this.fitActions.navigateToGovProfile(this.tab);
	}

	ngOnChanges(changes: SimpleChanges) {
	}

	/**
	 * The Government Type selection is changed.
	 * @param data
	 */
	governmentTypeChanged = (data) => {
		// value comes in as single selection for selectbox
		const arrayOrNull = { govTypeCodes: data.value ? [data.value] : null };
		this.setDataGridFilter(arrayOrNull);
		this.selectedMCAG = null;
		this.selectedGovernmentTypeCode = data.value;
	}

	/**
	 * Selection formatting
	 * @param item
	 */
	getGovTypeDisplay(item) {
		return item ? `${item.description} (${item.activeCount} active)` : '';
	}

	dataGridSelectionChanged(event: MandatoryFieldsEntity) {
		this.logger.log('update from data grid', event);
		this.selectedMCAG = event?.MCAG;
	}

	getGovernmentDisplayName(row: MandatoryFieldsEntity) {
		return row.EntityDBA ?? row.EntityName;
	}

	/**
	 * Set filter on the government data grid, preserving any existing filters. (Pass null to clear)
	 * @param values
	 */
	setDataGridFilter(values: { mcags?: Array<string>, govTypeCodes?: Array<string> }) {
		Object.assign(this.filter, values);
		const filterExpression = this.filterBuilder.groupOperationByObjectKey({
			MCAG: this.filter.mcags,
			GovTypeCode: this.filter.govTypeCodes
		});
		this.dataGridDataSource.filter(filterExpression);
		this.dataGridDataSource.load();
	}

	// Gets triggered when a user types clicks on an autocomplete suggestion
	handleAddressSelect(event) {
		if (event.selectedItem !== null) {
			this.marker = event.selectedItem?.geometry?.coordinates;
			this.dxDataGrid.instance.beginCustomLoading('Locating governments at this location...');
			this.geoDataService.getIntersectingGovernments(this.marker).subscribe((govs: Array<any>) => {
				this.setDataGridFilter({ mcags: govs.map(x => x.mcag) });
				this.dxDataGrid.instance.endCustomLoading();
			});
		} else {
			this.marker = null;
			this.setDataGridFilter({ mcags: null });
		}
	}

	goToTypeProfile(item) {
		this.fitActions.navigateToGovTypeProfile()(item.code, item.description);
	}

	mapGovernmentSelected(event: GovernmentLocation) {
		this.logger.log('user selected', event);
		if (event !== null) {
			this.dxDataGrid.instance.selectRows([event.mcag], false);
		}
	}

	mapPopupClosed() {
		this.dxDataGrid.instance.deselectAll();
		// set to null since dx sets to undefined during deselect
		this.selectedMCAG = null;
	}
}
