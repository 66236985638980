import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, of, Subject} from 'rxjs';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {TabBarItem} from '../../../shared/components/tab-bar/tab-bar.component';
import {LocalGovernmentReference} from '../../../modules/api/fit-api/models/snapshots/local-government-reference';
import {CodeDescription} from '../../../modules/api/fit-api/models/code-description';
import {FitApiService} from '../../../modules/api/fit-api/fit-api.service';
import {DataExtractService} from '../data-extract.service';
import {catchError} from 'rxjs/operators';
import {PensionLiabilityVarianceMode} from './pension-liability-variance-mode';

@Component({
  selector: 'app-pension-liability-variance',
  templateUrl: './pension-liability-variance.component.html',
  styleUrls: ['./pension-liability-variance.component.scss']
})
export class PensionLiabilityVarianceComponent implements OnDestroy, OnInit {

	private destroy = new Subject<void>();

	public icons = {
		download: faDownload
	};

	isLoading = false;
	snapshot: any;

	modes = PensionLiabilityVarianceMode;
	selectedMode: PensionLiabilityVarianceMode;

	availableModes: Array<TabBarItem> = [];

	governments: Array<LocalGovernmentReference>;  // Governments to select from
	governmentTypes: Array<CodeDescription>; // Government Types to select from
	teams: Array<CodeDescription>; // Teams to select from

	selectedValue: string;

	buttonUrl: string = null;

	constructor(
		private fitApi: FitApiService,
		private service: DataExtractService
	) { }

	ngOnInit() {
		this.isLoading = true;
		forkJoin([
			this.fitApi.getAnnualFilingSnapshot(),
			this.service.getPensionLiabilityVarianceTeams().pipe(catchError(error => of(null))),
			this.service.getPensionLiabilityVarianceGovernmentTypes().pipe(catchError(error => of(null))),
			this.service.getPensionLiabilityVarianceGovernments().pipe(catchError(error => of(null)))
		]).subscribe(([latestSnapshot, teams, govTypes, governments]) => {
			this.snapshot = latestSnapshot;

			this.teams = teams;
			if (teams !== null) {
				this.availableModes.push(new TabBarItem(PensionLiabilityVarianceMode.Team, 'Team'));
			}

			this.governmentTypes = govTypes;
			if (govTypes) {
				this.availableModes.push(new TabBarItem(PensionLiabilityVarianceMode.GovernmentType, 'Government Type'));
			}

			this.governments = governments;
			if (governments) {
				this.availableModes.push(new TabBarItem(PensionLiabilityVarianceMode.Government, 'Government'));
			}

			this.availableModes.push(new TabBarItem(PensionLiabilityVarianceMode.All, 'All'));
			// Set default selection
			if (this.availableModes.length > 0) {
				this.selectedMode = this.availableModes[0].id as PensionLiabilityVarianceMode;
			}

			this.isLoading = false;
		});
	}

	displayExpr(item: CodeDescription): string {
		if (!item) {
			return '';
		}
		return `${item.description} (${item.code})`;
	}

	displayGovernmentExpr(item: LocalGovernmentReference): string {
		if (!item) {
			return '';
		}
		return `${item.lookupNameWithDba} (${item.mcag})`;
	}

	onModeChange = (mode: PensionLiabilityVarianceMode): void => {
		this.selectedMode = mode;
		this.buttonUrl = null;

		if (this.selectedMode === PensionLiabilityVarianceMode.All) {
			this.renderButtonUrl();
		}
	};

	selectionChanged(event) {
		this.selectedValue = event.selectedItem?.code ?? event.selectedItem?.mcag;
		this.renderButtonUrl();
	}

	renderButtonUrl() {
		if (this.selectedMode === PensionLiabilityVarianceMode.All) {
			this.buttonUrl = this.service.getPensionLiabilityVarianceAllUrl();
		}
		if (this.selectedValue) {
			switch (this.selectedMode) {
				case PensionLiabilityVarianceMode.Team:
					this.buttonUrl = this.service.getPensionLiabilityVarianceTeamsUrl() + '/' + this.selectedValue;
					break;
				case PensionLiabilityVarianceMode.GovernmentType:
					this.buttonUrl = this.service.getPensionLiabilityVarianceGovTypeCodesUrl() + '/' + this.selectedValue;
					break;
				case PensionLiabilityVarianceMode.Government:
					this.buttonUrl = this.service.getPensionLiabilityVarianceGovernmentsUrl() + '/' + this.selectedValue;
					break;
			}
		}
	}

	ngOnDestroy() {
		this.destroy.next();
		this.destroy.complete();
	}

	downloadClick() {
		location.href = this.buttonUrl;
	}

}
