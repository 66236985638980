import {Injectable} from '@angular/core';
import {GovernmentServiceModule} from './government-service.module';

@Injectable({
	providedIn: GovernmentServiceModule
})
export class GovernmentService {

	constructor() {
	}

	/**
	 * Gov Type Codes that are considered General Government.
	 */
	private generalGovernmentTypes = ['06', '07'];

	/**
	 * State Governments are statewide/do not have county associations.
	 * @param govTypeCode
	 */
	isStateGovernment = (govTypeCode: string): boolean =>
		govTypeCode?.indexOf('S') === 0;

	/**
	 * Special Purpose Districts have a single function, and therefore have only one Basic BARS account and typically
	 * only one (enterprise) fund.
	 * @param govTypeCode
	 */
	isSpecialPurposeDistrict = (govTypeCode: string): boolean =>
		!this.generalGovernmentTypes?.includes(govTypeCode)
			&& !this.isStateGovernment(govTypeCode);

	/**
	 * A General Government can perform multiple functions and typically has many Basis BARS accounts.
	 * @param govTypeCode
	 */
	isGeneralGovernment = (govTypeCode: string): boolean =>
		this.generalGovernmentTypes.includes(govTypeCode);

	isCounty = (govTypeCode: string): boolean => govTypeCode === '06';
}
