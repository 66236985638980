import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TabReuseService } from '../../shared/services/tab-reuse.service';
import { TabReuseModal } from 'app/shared/models/tab-reuse-modal';

@Component({
	selector: 'app-tab-reuse-modal',
	templateUrl: './tab-reuse-modal.component.html',
	styleUrls: ['./tab-reuse-modal.component.scss']
})
export class TabReuseModalComponent implements OnInit {

	// @Output() action = new EventEmitter<any>();
	modalData: TabReuseModal;
	popupComponent = null;

	constructor(
		private tabReuse: TabReuseService
	) { }

	ngOnInit() {
		const self = this;
		this.tabReuse.observable.subscribe((config) => {
			self.modalData = config;
		});
	}

	handleAction(action: string) {
		// 'cancel' action is sent everytime modal hides; only trigger closeModal if modal is still open
		if (action === 'cancel' && !this.modalData.isVisible) {
			return;
		}
		this.tabReuse.closeModal(action);
	}

	handlePopupInit(event) {
		this.popupComponent = event.component;
	}

}
