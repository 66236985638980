<dx-popup
	class="account-settings-popup"
	width="580"
	height="auto"
	[dragEnabled]="false"
	[showCloseButton]="true"
	[(visible)]="isVisible"
	title="Account Settings">
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner account-settings-popup__actions">
				<div class="account-settings-popup__action">
					<h4 class="u-margin-bottom-tiny">Auto-update data to see latest published version:</h4>

					<fa-icon [icon]="icons.infoCircle" id="account-settings-popup__auto-update-on"></fa-icon>
					<dx-tooltip
						target="#account-settings-popup__auto-update-on"
						position="bottom"
						showEvent="dxhoverstart"
						hideEvent="dxhoverend">
						<div *dxTemplate="let data = data of 'content'">
							A tool tip about auto updating snapshots
						</div>
					</dx-tooltip>
					<app-switch
						(onValueChanged)="onAutoUpdateChange($event)"
						[value]="user.autoUpdateSnapshots"
					></app-switch>
					<fa-icon [icon]="icons.infoCircle" id="account-settings-popup__auto-update-off"></fa-icon>
					<dx-tooltip
						target="#account-settings-popup__auto-update-off"
						position="bottom"
						showEvent="dxhoverstart"
						hideEvent="dxhoverend">
						<div *dxTemplate="let data = data of 'content'">
							A tool tip about auto updating snapshots
						</div>
					</dx-tooltip>
				</div>

				<div class="account-settings-popup__action">
					<h4 class="u-margin-bottom-tiny">Display tips, hints, and explanations in:</h4>

					<fa-icon [icon]="icons.infoCircle" id="account-settings-popup__plaintalk"></fa-icon>
					<dx-tooltip
						target="#account-settings-popup__plaintalk"
						position="bottom"
						showEvent="dxhoverstart"
						hideEvent="dxhoverend">
						<div *dxTemplate="let data = data of 'content'">
							We&rsquo;ll use everyday language to communicate financial concepts, where possible. This
							option is designed for users who are less experienced with public finance&rsquo;s unique
							terminology.
						</div>
					</dx-tooltip>
					<app-switch
						falseLabel="Plaintalk"
						trueLabel="Technical Language"
						(onValueChanged)="onPlainTalkChange($event)"
						[value]="user.showTechnical"
					></app-switch>
					<fa-icon [icon]="icons.infoCircle" id="account-settings-popup__technical-language"></fa-icon>
					<dx-tooltip
						target="#account-settings-popup__technical-language"
						position="bottom"
						showEvent="dxhoverstart"
						hideEvent="dxhoverend">
						<div *dxTemplate="let data = data of 'content'">
							We&rsquo;ll use technical language to show financial concepts. This option is for users who
							are experienced with public finance vocabulary.
						</div>
					</dx-tooltip>
				</div>

				<!-- <dx-button>
					<div *dxTemplate="let data of 'content'">
						<span>Change Password / Manage Entity Affiliation</span>
						<fa-icon [icon]="icons.external"></fa-icon>
					</div>
				</dx-button> -->

				<dx-button (click)="deleteAppData()">
					<div *dxTemplate="let data of 'content'">
						<span>Delete App Data</span>
						<fa-icon [icon]="icons.eraser"></fa-icon>
					</div>
				</dx-button>
			</div>

			<div class="app-popup__footer">
				<dx-button
					text="Save"
					type="default"
					(onClick)="closeFirstTime()"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
