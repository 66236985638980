import {FundTypeId} from './fund-type';

export class Fund {
	fundNumber: string;
	fundName: string;
	fundTypeId: FundTypeId;

	get fundFullName() {
		const fundName = this.fundName ? ` - ${this.fundName}` : ``;
		return `${this.fundNumber}${fundName}`;
	}

	constructor(fundNumber: string, fundName: string, fundTypeId: FundTypeId) {
		this.fundNumber = fundNumber;
		this.fundName = fundName;
		this.fundTypeId = fundTypeId;
	}
}
