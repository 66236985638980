<div class="header">
	<div class="left">
		<h2>
			Government Profile:
			<br />
			<span>
				{{ govInfoLive?.EntityName }}
				<ng-container *ngIf="govInfoLive?.Entity_Dba">({{ govInfoLive?.Entity_Dba }})</ng-container>
			</span>
		</h2>
	</div>

	<div class="right">
		<dx-button
			text="Generate Tear Sheet"
			type="default"
			(onClick)="navigateToTearSheet()">
		</dx-button>
	</div>

	<wasao-badges
		*ngIf="govInfoLive?.IsActive === 'Y'"
		hideWhenContentEmpty=true
		[snapshotId]="snapshotId"
		[mcag]="government?.mcag"
		[displayYear]="displayYear"
		[onTimeFilerClickAction]="clickActions.filerReport"
	></wasao-badges>
</div>

<h3>About this Government</h3>
<wasao-government-information
	class="section"
	[snapshotId]="snapshotId"
	[mcag]="government?.mcag"
	[displayYear]="displayYear"
	[govTypeClickAction]="clickActions.govTypeProfile"
	[govClickAction]="clickActions.govProfile"
	[canShowFinancials]="canShowFinancials"
></wasao-government-information>

<ng-container *ngIf="canShowFinancials">
	<ng-container *ngIf="govTypeIsSchool">
		<h3>
			Annual F-196 Data
			<wasao-hint
				icon="infoCircle"
				class="u-color-neutral-white u-margin-left-small"
				hintInformation="annualF196"
			></wasao-hint>
		</h3>
	</ng-container>
	<ng-container *ngIf="!govTypeIsSchool">
		<h3>
			Annual Filing Data
			<wasao-hint
				icon="infoCircle"
				class="u-color-neutral-white u-margin-left-small"
				hintInformation="annualFilingData"
			></wasao-hint>
		</h3>
	</ng-container>
	<wasao-annual-filing-summary
		class="section"
		[mcag]="government?.mcag"
		[displayYear]="displayYear"
		[snapshotId]="snapshotId"
		[reportTypeClickAction]="clickActions.govReport"
		[showBeta]="showBeta"
		hideWhenContentEmpty=true
	></wasao-annual-filing-summary>
</ng-container>

<div #financialHealthIndicators>
	<ng-container *ngIf="canShowIndicators && canShowFinancials">
		<h3>
			Financial Health Indicators
			<wasao-hint
				icon="infoCircle"
				class="u-color-neutral-white u-margin-left-small"
				hintInformation="financialHealthIndicators"
			></wasao-hint>
		</h3>
		<wasao-indicator-groups
			*ngIf="indicatorGroups?.length"
			class="section"
			[indicatorGroups]="indicatorGroups"
			[mcag]="government?.mcag"
			[displayYear]="displayYear"
			[snapshotId]="snapshotId"
			[tab]="tab"
		></wasao-indicator-groups>
	</ng-container>
</div>

<ng-container *ngIf="canShowFinancials">
	<h3>Finances at a Glance</h3>
	<wasao-finances-at-a-glance
		class="section"
		[mcag]="government?.mcag"
		[displayYear]="displayYear"
		[snapshotId]="snapshotId"
		[showBeta]="showBeta"
		[reportTypeClickAction]="clickActions.govReport"
	></wasao-finances-at-a-glance>
</ng-container>

<ng-container *ngIf="canShowFinancials">
	<h3>This Government&rsquo;s Ranking</h3>
	<wasao-rankings
		class="section"
		[snapshotId]="snapshotId"
		[mcag]="government?.mcag"
		[year]="displayYear"
		[seeDataClickAction]="clickActions.rankingReport"
	></wasao-rankings>
</ng-container>

<ng-container *ngIf="canShowFinancials">
	<h3 [ngClass]="{ 'u-hidden': !hasDataChildren.governmentalServices }">Governmental Services</h3>
	<wasao-governmental-services
		[ngClass]="{ 'u-hidden': !hasDataChildren.governmentalServices }"
		class="section"
		[mcag]="government?.mcag"
		[displayYear]="displayYear"
		[snapshotId]="snapshotId"
		[trendDataClickAction]="clickActions.govReport"
		(hasData)="this.hasDataChildren.governmentalServices = $event"
		hideWhenContentEmpty=true
	></wasao-governmental-services>

	<h3 [ngClass]="{ 'u-hidden': !hasDataChildren.enterpriseServices }">Enterprise Services</h3>
	<wasao-enterprise-services
		[ngClass]="{ 'u-hidden': !hasDataChildren.enterpriseServices }"
		class="section"
		[mcag]="government?.mcag"
		[displayYear]="displayYear"
		[snapshotId]="snapshotId"
		[trendDataClickAction]="clickActions.govReport"
		(hasData)="this.hasDataChildren.enterpriseServices = $event"
	></wasao-enterprise-services>
</ng-container>
