import {Component, ViewChild} from '@angular/core';
import {DxFileUploaderComponent} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {ShapefileLoaderService} from '../shapefile-loader.service';
import {ShapefileLoaderState} from '../models/shapefile-loader-state';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-tcadistrict-assignments',
  templateUrl: './tcadistrict-assignments.component.html',
  styleUrls: ['./tcadistrict-assignments.component.scss']
})
export class TCADistrictAssignmentsComponent {

	@ViewChild(DxFileUploaderComponent) dxFileUploader: DxFileUploaderComponent;
	dataSource: DataSource;
	isLoading = false;
	// Use to notify the user that they will be overwriting existing records
	hasExistingRecords: boolean;
	warningIcon = faExclamationTriangle;
	file: File;
	vintageYear: number;

	constructor(
		private service: ShapefileLoaderService
	) {
		this.service.vintageYear.subscribe(year => {
			this.vintageYear = year;
			this.dataSource = this.service.getODataDataSource(ShapefileLoaderState.TaxCodeAreaDistrictAssignments, year);
			this.dataSource.store().on('loaded', this.setHasExistingRecords);
		});
	}

	setHasExistingRecords = (records: Array<any>) => {
		this.hasExistingRecords = records.length > 0;
	}

	upload() {
		this.isLoading = true;
		this.service.uploadDistrictAssignments(this.vintageYear, this.file)
			.pipe(finalize(() => this.isLoading = false))
			.subscribe(
				result => {
					this.file = null;
					this.dxFileUploader.instance.removeFile(0);
					this.dataSource.reload();
				},
				error => this.service.handleError(error)
			);
	}

	valueChanged(event: any) {
		this.file = event.value?.[0];
	}

}
