import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
	DxButtonModule,
	DxChartModule,
	DxCircularGaugeModule,
	DxDataGridModule,
	DxLoadIndicatorModule,
	DxLoadPanelModule,
	DxPieChartModule,
	DxSparklineModule
} from 'devextreme-angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {SharedComponentsModule} from 'app/shared/components/shared-components.module';
import {GovernmentProfileComponent} from './government-profile/government-profile.component';
import {GovernmentTypeProfileComponent} from './government-type-profile/government-type-profile.component';
import {GovernmentInformationModule} from '../../modules/info/government-information/government-information.module';
import {AnnualFilingSummaryModule} from '../../modules/financials/annual-filing-summary/annual-filing-summary.module';
import {BadgesModule} from '../../modules/info/badges/badges.module';
import {FinancesAtAGlanceModule} from '../../modules/financials/finances-at-a-glance/finances-at-a-glance.module';
import {RankingsModule} from '../../modules/info/rankings/rankings.module';
import {IndicatorsModule} from '../../modules/financials/indicators/indicators.module';
import {GovernmentalServicesModule} from '../../modules/financials/governmental-services/governmental-services.module';
import {EnterpriseServicesModule} from '../../modules/financials/enterprise-services/enterprise-services.module';
import {GovernmentTypeInformationModule} from '../../modules/info/government-type-information/government-type-information.module';
import {GovernmentBodyTypeModule} from '../../modules/info/government-body-type/government-body-type.module';
import {GovernmentBodyOriginsModule} from '../../modules/info/government-body-origins/government-body-origins.module';
import {GovernmentTreasurersModule} from '../../modules/info/government-treasurers/government-treasurers.module';
import {
	FinancialHealthIndicatorStatisticsModule
} from '../../modules/financials/financial-health-indicator-statistics/financial-health-indicator-statistics.module';

@NgModule({
	declarations: [
		GovernmentProfileComponent,
		GovernmentTypeProfileComponent
	],
	imports: [
		CommonModule,
		DxButtonModule,
		DxSparklineModule,
		DxPieChartModule,
		DxChartModule,
		DxLoadPanelModule,
		DxCircularGaugeModule,
		DxDataGridModule,
		DxLoadIndicatorModule,
		FontAwesomeModule,
		SharedComponentsModule,
		GovernmentInformationModule,
		AnnualFilingSummaryModule,
		BadgesModule,
		FinancesAtAGlanceModule,
		RankingsModule,
		IndicatorsModule,
		GovernmentalServicesModule,
		EnterpriseServicesModule,
		GovernmentTypeInformationModule,
		GovernmentBodyTypeModule,
		GovernmentBodyOriginsModule,
		GovernmentTreasurersModule,
		FinancialHealthIndicatorStatisticsModule
	],
	exports: [
		GovernmentProfileComponent,
		GovernmentTypeProfileComponent
	]
})
export class GovernmentProfileModule { }
