import {Component, OnInit} from '@angular/core';
import {Tab} from 'app/shared/models/tab';
import {TabService} from '../../shared/services/tab.service';
import {SnapshotService} from '../../shared/services/snapshot.service';
import {LoggerService} from '../../shared/services/logger.service';
import {faChartLine, faCheck, faHistory, faSync} from '@fortawesome/free-solid-svg-icons';
import {switchMap, skipWhile} from 'rxjs/operators';
import {Disclaimer} from '../../shared/models/disclaimer';
import {UserInterfaceService} from '../../shared/services/user-interface.service';
import {UserService} from '../../shared/services/user.service';
import {combineLatest} from 'rxjs';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';

type SnapshotState = 'live' | 'current' | 'historical' | 'none' | 'ospi';

@Component({
	selector:    'app-taskbar',
	templateUrl: './taskbar.component.html',
	styleUrls:   ['./taskbar.component.scss']
})
export class TaskbarComponent implements OnInit {

	dataset: any;
	snapshotState: SnapshotState;
	tab: Tab;
	isDebug                   = true;
	icons                     = {
		sync:    faSync,
		check:   faCheck,
		history: faHistory,
		live:    faChartLine,
	};

	public disclaimer = Disclaimer;

	constructor(
		private tabService: TabService,
		private snapshotService: SnapshotService,
		private uiService: UserInterfaceService,
		private userService: UserService,
		private fitApi: FitApiService
	) {
	}

	ngOnInit() {
		this.tabService.tabs.subscribe(tabs => {
			this.tab = tabs.find(tab => tab.selected);
			const snapshotSub = this.snapshotService.getSnapshot(this.tab?.snapshotId).pipe(skipWhile(snapshot => !snapshot));
			const ospiSub = this.fitApi.getOSPIDataset().pipe(skipWhile(ospi => !ospi));
			combineLatest([snapshotSub, this.userService.user, this.fitApi.getGovernmentTypes, ospiSub]).subscribe(([snapshot, user, govTypeList, ospiDataset]) => {

				// TODO passing in govTypeList as param because we cant make service call in tabs model but this should probably be changed in next iteration
				const isDisplayingOSPI = this.tab?.isDisplayingOSPIDataset(user.hasGlobalAccess(), user.hasAccessToAnySchool(), govTypeList);

				if (isDisplayingOSPI) {
					this.snapshotState = 'ospi';
					this.dataset = ospiDataset;
				} else {
					this.snapshotState = this.getSnapshotState(snapshot);
					this.dataset = snapshot;
				}
			});
		});
	}

	updateSnapshotToLatest() {
		// TODO update this to match snapshot dropdown logic
		// * should we just change snapshotId with assignment?
		// * should we be calling save here since snapshot dropdown calls save on change?
		this.tab.snapshotIdSubject.next(this.snapshotService.latestId);
		this.tabService.save(this.tab);
	}

	getSnapshotState = (snapshot): SnapshotState => {
		if (!snapshot) {
			return 'none';
		}
		if (snapshot.id === 'live') {
			return 'live';
		}
		if (snapshot.id < this.snapshotService.latestId) {
			return 'historical';
		}
		if (snapshot.id === this.snapshotService.latestId) {
			return 'current';
		}
	}

	showDisclaimer = () => this.uiService.showDisclaimer();

}
