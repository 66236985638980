import { Injectable } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { FieldService } from 'app/shared/services/field.service';
import { FormatService } from 'app/shared/services/format.service';
import { Track } from './track-interface';
import { Field } from 'app/shared/models/field';
import { Tab } from 'app/shared/models/tab';
import { SortService } from '../sort.service';

@Injectable({
	providedIn: 'root'
})
export class TrackCService implements Track {

	constructor(
		private format: FormatService,
		private user: UserService,
		private field: FieldService,
		private sort: SortService
	) {
		this.summary = this.summary.bind(this);
		this.revenues = this.revenues.bind(this);
		this.revenuesWithOthers = this.revenuesWithOthers.bind(this);
		this.expenditures = this.expenditures.bind(this);
		this.expendituresWithOthers = this.expendituresWithOthers.bind(this);
	}

	presets() {
		return [{
			key: 'Expenditure Object by Year',
			requires: { report: ['expenditures', 'expendituresWithOthers'] },
			fields: [
				{ name: 'expenditureObject', row: 0 },
				{ name: 'year', column: 0 },
				{ name: 'fundCategory', column: 1 },
				{ name: 'fundType', column: 2 }
			]
		}];
	}

	summary(tab: Tab): Array<Field> {
		const self = this;

		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				width: 150,
				area: undefined,
				groupingConfig: {
					required: true,
					expanded: true,
					row: {
						fixed: true,
						defaultOrder: 0
					},
					mustBeBefore: ['standardAccount', 'standardSubAccount', 'standardElement', 'standardSubElement']
				},
				customizeText: function (data) {
					return self.format.getNameForSectionId(data, tab.snapshotId);
				}
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					row: {
						fixed: true,
						defaultOrder: 1
					},
					mustBeAfter: ['standardSummary']
				}
			},
			{
				name: 'standardSubAccount',
				groupingConfig: {
					required: true,
					row: {
						fixed: true,
						defaultOrder: 2
					},
					mustBeAfter: ['standardSummary', 'standardAccount']
				}
			},
			{
				name: 'standardElement',
				groupingConfig: {
					required: true,
					row: {
						fixed: true,
						defaultOrder: 3
					},
					mustBeAfter: ['standardSummary', 'standardAccount', 'standardSubAccount']
				}
			},
			{
				name: 'standardSubElement',
				groupingConfig: {
					required: true,
					row: {
						fixed: true,
						defaultOrder: 4
					},
					mustBeAfter: ['standardSummary', 'standardAccount', 'standardSubAccount', 'standardElement']
				}
			},
			{
				name: 'debtCapitalExpSummary',
				width: 150,
				area: undefined,
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						id: 'summary',
						structure: 'debtCapitalExp',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required: true,
					expanded: true,
					row: {
						fixed: true,
						defaultOrder: 0
					},
					mustBeBefore: ['debtCapitalExpAccount', 'debtCapitalExpSubAccount', 'debtCapitalExpElement', 'debtCapitalExpSubElement']
				},
				customizeText: function (data) {
					return self.format.getNameForSectionId(data, tab.snapshotId);
				}
			},
			{
				name: 'debtCapitalExpAccount',
				groupingConfig: {
					required: true,
					row: {
						fixed: true,
						defaultOrder: 1
					},
					mustBeBefore: ['debtCapitalExpSummary']
				}
			},
			{
				name: 'debtCapitalExpSubAccount',
				groupingConfig: {
					required: true,
					row: {
						fixed: true,
						defaultOrder: 2
					},
					mustBeAfter: ['debtCapitalExpSummary', 'debtCapitalExpAccount']
				}
			},
			{
				name: 'debtCapitalExpElement',
				groupingConfig: {
					required: true,
					row: {
						fixed: true,
						defaultOrder: 3
					},
					mustBeAfter: ['debtCapitalExpSummary', 'debtCapitalExpAccount', 'debtCapitalExpSubAccount']
				}
			},
			{
				name: 'debtCapitalExpSubElement',
				groupingConfig: {
					required: true,
					row: {
						fixed: true,
						defaultOrder: 4
					},
					mustBeAfter: ['debtCapitalExpSummary', 'debtCapitalExpAccount', 'debtCapitalExpSubAccount', 'debtCapitalExpElement']
				}
			},
			new Field({
				id: 'fundType',
				name: 'fundType',
				dataField: 'fundTypeId',
				caption: 'Fund Type',
				groupingConfig: {
					column: {
						defaultOrder: 1
					},
					row: {}
				},
				transitions: {
					filterValues: function (currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				sortingMethod: function (a, b) {
					return self.sort.sortFundTypes(a, b, tab.snapshotId);
				},
				customizeText: function (data) {
					return self.format.getFundTypeText(data, tab.snapshotId);
				}
			})
		])
		.concat(self.field.getYearField(tab, {
			groupingConfig: {
				required: false,
				expanded: true
			}
		}))
		.concat(self.field.getExpenditureObjectField(tab))
		.concat(self.field.getGovernmentTypeField(tab))
		.concat(self.field.getDummyDataField(tab));
	}

	revenues(tab: Tab): Array<Field> {
		const self = this;

		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				filterValues: self.field.filterValuesDefaults['revenues']['summary']
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					required: false
				}
			},
			{
				name: 'debtCapitalExpSummary',
				filterValues: self.field.filterValuesDefaults['revenues']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			},
			new Field({
				id: 'governmentType',
				name: 'governmentType',
				dataField: 'govTypeCode',
				caption: 'Government Type',
				width: 150,
				groupingConfig: {
					row: {},
					column: {}
				},
				transitions: {
					filterValues: function (currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					}
				},
				customizeText: function (data) {
					return self.format.getNameForGovtTypeCode(data, tab);
				}
			})
		])
		.concat(self.field.getPrimeCategoryField({
			filterValues: self.field.filterValuesDefaults['revenues']['primeCategory']
		}))
		.concat(self.field.getYearField(tab, {
			groupingConfig: {
				required: false,
				expanded: true
			}
		}))
		.concat(self.field.getGovernmentTypeField(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
		.concat(self.field.getComparisonField(tab));
	}

	revenuesWithOthers(tab: Tab): Array<Field> {
		const self = this;

		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				filterValues: self.field.filterValuesDefaults['revenuesWithOthers']['summary']
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					required: false
				}
			},
			{
				name: 'debtCapitalExpSummary',
				filterValues: self.field.filterValuesDefaults['revenuesWithOthers']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			}
		])
		.concat(self.field.getPrimeCategoryField({
			filterValues: self.field.filterValuesDefaults['revenuesWithOthers']['primeCategory']
		}))
		.concat(self.field.getYearField(tab, {
			groupingConfig: {
				required: false,
				expanded: true
			}
		}))
		.concat(self.field.getGovernmentTypeField(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
		.concat(self.field.getComparisonField(tab));
	}

	expenditures(tab: Tab): Array<Field> {
		const self = this;

		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				filterValues: self.field.filterValuesDefaults['expenditures']['summary']
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					required: false
				}
			},
			{
				name: 'debtCapitalExpSummary',
				filterValues: self.field.filterValuesDefaults['expenditures']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			}
		])
		.concat(self.field.getPrimeCategoryField({
			filterValues: self.field.filterValuesDefaults['expenditures']['primeCategory']
		}))
		.concat(self.field.getYearField(tab, {
			groupingConfig: {
				required: false,
				expanded: true
			}
		}))
		.concat(self.field.getExpenditureObjectField(tab, {
			groupingConfig: {
				row: {
					advancedDefaultOrder: 4
				}
			}
		}))
		.concat(self.field.getGovernmentTypeField(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
		.concat(self.field.expenditureObjectFilterField())
		.concat(self.field.getComparisonField(tab));
	}

	expendituresWithOthers(tab: Tab): Array<Field> {
		const self = this;

		return this.field.getBaseFields(tab, [
			{
				name: 'standardSummary',
				filterValues: self.field.filterValuesDefaults['expendituresWithOthers']['summary']
			},
			{
				name: 'standardAccount',
				groupingConfig: {
					required: false
				}
			},
			{
				name: 'debtCapitalExpSummary',
				filterValues: self.field.filterValuesDefaults['expendituresWithOthers']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure: 'debtCapitalExp',
						id: 'summary',
						active: tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			}
		])
		.concat(self.field.getPrimeCategoryField({
			filterValues: self.field.filterValuesDefaults['expendituresWithOthers']['primeCategory']
		}))
		.concat(self.field.getYearField(tab, {
			required: false,
			expanded: true
		}))
		.concat(self.field.getExpenditureObjectField(tab, {
			groupingConfig: {
				row: {
					advancedDefaultOrder: 4
				}
			}
		}))
		.concat(self.field.getGovernmentTypeField(tab, {
			transitions: undefined
		}))
		.concat(self.field.functionalAccountFilterGroupFields(tab))
		.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
		.concat(self.field.expenditureObjectFilterField())
		.concat(self.field.getComparisonField(tab));
	}
}
