
import {Injectable} from '@angular/core';
import {UserService} from 'app/shared/services/user.service';
import {FieldService} from 'app/shared/services/field.service';
import {FormatService} from 'app/shared/services/format.service';
import {Track} from './track-interface';
import {Field} from 'app/shared/models/field';
import {Tab} from 'app/shared/models/tab';
import {SortService} from '../sort.service';
import {UnitsService} from '../units.service';
import {LoggerService} from '../logger.service';
import {CellService} from '../cell.service';
import {DxPivotGridComponent} from 'devextreme-angular';
import {FinancialReportRow} from '../../../modules/api/fit-api/models/schools/financial-report-row';
import {OSPIFinancialReportRow} from '../../../modules/api/fit-api/models/schools/o-s-p-i-financial-report-row';

@Injectable({
	providedIn: 'root'
})
export class TrackAService implements Track {

	constructor(
		private format: FormatService,
		private user: UserService,
		private field: FieldService,
		private sort: SortService,
		private units: UnitsService,
		private logger: LoggerService,
		private cellService: CellService
	) {
		this.summary                = this.summary.bind(this);
		this.revenues               = this.revenues.bind(this);
		this.revenuesWithOthers     = this.revenuesWithOthers.bind(this);
		this.expenditures           = this.expenditures.bind(this);
		this.expendituresWithOthers = this.expendituresWithOthers.bind(this);
		this.debtAndLiabilities     = this.debtAndLiabilities.bind(this);
	}

	standardFormatWidth = 125;

	wideFormatWidth = 400;

	presets() {
		return [{
				key:      'Expenditure Object by Year',
				requires: {report: ['expenditures', 'expendituresWithOthers']},
				fields:   [
					{name: 'expenditureObject', row: 0},
					{name: 'year', column: 0},
					{name: 'fundCategory', column: 1},
					{name: 'fundType', column: 2},
					{name: 'fund', column: 3}
				]
			},
			{
				key:      'Expenditures by Program',
				requires: {report: ['schoolsGeneralFundExpenditures']},
				fields:   [
					{name: 'programCode', row: 0},
					{name: 'fy', column: 0}
				]
			},
			{
				key:      'Expenditures by Activity',
				requires: {report: ['schoolsGeneralFundExpenditures']},
				fields:   [
					{name: 'activityCode', row: 0},
					{name: 'fy', column: 0}
				]
			},
			{
				key:      'Expenditures by Object',
				requires: {report: ['schoolsGeneralFundExpenditures']},
				fields:   [
					{name: 'objectCode', row: 0},
					{name: 'fy', column: 0}
				]
			},
		];
	}

	summary(tab: Tab): Array<Field> {
		const self = this;

		// TODO: refactor further to eliminate redundant configurations
		return self.field.getBaseFields(tab, [
			new Field({
				id:             'year',
				name:           'year',
				dataField:      'year',
				caption:        'Year',
				sortingMethod:  self.sort.byString,
				selector:       function(data) {
					return self.format.getYearText(data, tab);
				},
				groupingConfig: {
					expanded: true,
					column:   {
						defaultOrder: 0
					}
				}
			}),
			{
				name:           'standardSummary',
				width:          175,
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'summary',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required:     true,
					expanded:     true,
					row:          {
						fixed:        true,
						defaultOrder: 0
					},
					mustBeBefore: ['standardAccount', 'standardSubAccount', 'standardElement', 'standardSubElement']
				},
				customizeText(data) {
					return self.format.getNameForSectionId(data, tab.snapshotId);
				}
			},
			{
				name:           'standardAccount',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'account',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required:     true,
					row:          {
						fixed:        true,
						defaultOrder: 1
					},
					mustBeAfter:  ['standardSummary'],
					mustBeBefore: ['standardSubAccount', 'standardElement', 'standardSubElement']
				}
			},
			{
				name:           'standardSubAccount',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'subAccount',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required:     true,
					row:          {
						fixed:        true,
						defaultOrder: 2
					},
					mustBeAfter:  ['standardSummary', 'standardAccount'],
					mustBeBefore: ['standardElement', 'standardSubElement']
				}
			},
			{
				name:           'standardElement',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'element',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required:     true,
					row:          {
						fixed:        true,
						defaultOrder: 3
					},
					mustBeAfter:  ['standardSummary', 'standardAccount', 'standardSubAccount'],
					mustBeBefore: ['standardSubElement']
				}
			},
			{
				name:           'standardSubElement',
				groupingConfig: {
					alternate:   {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'subElement',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					required:    true,
					row:         {
						fixed:        true,
						defaultOrder: 4
					},
					mustBeAfter: ['standardSummary', 'standardAccount', 'standardSubAccount', 'standardElement']
				}
			},
			{
				name:           'debtCapitalExpSummary',
				width:          150,
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						id:          'summary',
						structure:   'debtCapitalExp',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required:     true,
					expanded:     true,
					row:          {
						fixed:        true,
						defaultOrder: 0
					},
					mustBeBefore: ['debtCapitalExpAccount', 'debtCapitalExpSubAccount', 'debtCapitalExpElement', 'debtCapitalExpSubElement']
				},
				customizeText(data) {
					return self.format.getNameForSectionId(data, tab.snapshotId);
				}
			},
			{
				name:           'debtCapitalExpAccount',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'account',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required:     true,
					row:          {
						fixed:        true,
						defaultOrder: 1
					},
					mustBeAfter:  ['debtCapitalExpSummary'],
					mustBeBefore: ['debtCapitalExpSubAccount', 'debtCapitalExpElement', 'debtCapitalExpSubElement']
				}
			},
			{
				name:           'debtCapitalExpSubAccount',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'subAccount',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required:     true,
					row:          {
						fixed:        true,
						defaultOrder: 2
					},
					mustBeAfter:  ['debtCapitalExpSummary', 'debtCapitalExpAccount'],
					mustBeBefore: ['debtCapitalExpElement', 'debtCapitalExpSubElement']
				}
			},
			{
				name:           'debtCapitalExpElement',
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'element',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required:     true,
					row:          {
						fixed:        true,
						defaultOrder: 3
					},
					mustBeAfter:  ['debtCapitalExpSummary', 'debtCapitalExpAccount', 'debtCapitalExpSubAccount'],
					mustBeBefore: ['debtCapitalExpSubElement']
				}
			},
			{
				name:           'debtCapitalExpSubElement',
				groupingConfig: {
					alternate:   {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'subElement',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					},
					required:    true,
					row:         {
						fixed:        true,
						defaultOrder: 4
					},
					mustBeAfter: ['debtCapitalExpSummary', 'debtCapitalExpAccount', 'debtCapitalExpSubAccount', 'debtCapitalExpElement']
				}
			},
			new Field({
				id:             'expenditureObject',
				name:           'expenditureObject',
				dataField:      'expenditureObjectId',
				caption:        'Expenditure Object',
				width:          150,
				groupingConfig: {
					row:    {},
					column: {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					}
				},
				customizeText:  function(data) {
					return self.format.getNameForExpenditureId(data, tab);
				}
			}),
			new Field({
				id:             'fundType',
				name:           'fundType',
				dataField:      'fundTypeId',
				caption:        'Fund Type',
				groupingConfig: {
					column: {
						defaultOrder: 2
					},
					row:    {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFundTypes(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getFundTypeText(data, tab.snapshotId);
				}
			}),
			new Field({
				id:             'fund',
				name:           'fund',
				dataField:      'fundNumber',
				caption:        'Fund',
				groupingConfig: {
					column: {
						defaultOrder: 3
					},
					row:    {}
				},
				selector:       function(data) {
					return self.format.getFundSelector(data);
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFunds(a, b);
				},
				customizeText:  function(this, data) {
					return self.format.getFundText(data, tab);
				}
			})
		]).concat(self.field.getDummyDataField(tab));
	}

	revenues(tab: Tab): Array<Field> {
		const self = this;

		// TODO: refactor further to eliminate redundant configurations
		return this.field.getBaseFields(tab, [
			new Field({
				id:           'primeCategory',
				name:         'primeCategory',
				dataField:    'primeId',
				area:         'filter', // Always in filter area hidden from user
				filterValues: self.field.filterValuesDefaults['revenues']['primeCategory']
			}),
			new Field({
				id:             'year',
				name:           'year',
				dataField:      'year',
				caption:        'Year',
				sortingMethod:  self.sort.byString,
				selector:       function(data) {
					return self.format.getYearText(data, tab);
				},
				groupingConfig: {
					expanded: true,
					column:   {
						defaultOrder: 0
					}
				}
			}),
			{
				name:         'standardSummary',
				area:         'filter',
				filterValues: self.field.filterValuesDefaults['revenues']['summary']
			},
			{
				name:  'standardAccount',
				width: 300
			},
			{
				name:           'debtCapitalExpSummary',
				area:           'filter',
				filterValues:   self.field.filterValuesDefaults['revenues']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'summary',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			},
			{
				name:  'debtCapitalExpAccount',
				width: 300
			},
			new Field({
				id:             'fundType',
				name:           'fundType',
				dataField:      'fundTypeId',
				caption:        'Fund Type',
				groupingConfig: {
					column: {
						defaultOrder: 2
					},
					row:    {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFundTypes(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getFundTypeText(data, tab.snapshotId);
				}
			}),
			new Field({
				id:             'fund',
				name:           'fund',
				dataField:      'fundNumber',
				caption:        'Fund',
				groupingConfig: {
					column: {
						defaultOrder: 3
					},
					row:    {}
				},
				selector:       function(data) {
					return self.format.getFundSelector(data);
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFunds(a, b);
				},
				customizeText:  function(this, data) {
					return self.format.getFundText(data, tab);
				}
			}),
			new Field({
				id:             'lineDesc',
				name:           'lineDesc',
				dataField:      'lineDesc',
				caption:        'Line Item',
				groupingConfig: {
					row: {}
				}
			})
		])
			.concat(self.field.functionalAccountFilterGroupFields(tab))
			.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
			.concat(self.field.getComparisonField(tab));
	}

	revenuesWithOthers(tab: Tab): Array<Field> {
		const self = this;

		// TODO: refactor further to eliminate redundant configurations
		return this.field.getBaseFields(tab, [
			new Field({
				id:           'primeCategory',
				name:         'primeCategory',
				dataField:    'primeId',
				area:         'filter', // Always in filter area hidden from user
				filterValues: self.field.filterValuesDefaults['revenuesWithOthers']['primeCategory']
			}),
			new Field({
				id:             'year',
				name:           'year',
				dataField:      'year',
				caption:        'Year',
				sortingMethod:  self.sort.byString,
				selector:       function(data) {
					return self.format.getYearText(data, tab);
				},
				groupingConfig: {
					expanded: true,
					column:   {
						defaultOrder: 0
					}
				}
			}),
			{
				name:         'standardSummary',
				area:         'filter',
				filterValues: self.field.filterValuesDefaults['revenuesWithOthers']['summary']
			},
			{
				name:           'standardAccount',
				width:          300,
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'account',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					row:          {
						defaultOrder: 0
					},
					mustBeBefore: ['standardSubAccount', 'standardElement', 'standardSubElement']
				}
			},
			{
				name:         'debtCapitalExpSummary',
				area:         'filter',
				filterValues: self.field.filterValuesDefaults['revenuesWithOthers']['summary']
			},
			{
				name:  'debtCapitalExpAccount',
				width: 300
			},
			new Field({
				id:             'fundType',
				name:           'fundType',
				dataField:      'fundTypeId',
				caption:        'Fund Type',
				groupingConfig: {
					column: {
						defaultOrder: 2
					},
					row:    {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFundTypes(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getFundTypeText(data, tab.snapshotId);
				}
			}),
			new Field({
				id:             'fund',
				name:           'fund',
				dataField:      'fundNumber',
				caption:        'Fund',
				groupingConfig: {
					column: {
						defaultOrder: 3
					},
					row:    {}
				},
				selector:       function(data) {
					return self.format.getFundSelector(data);
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFunds(a, b);
				},
				customizeText:  function(this, data) {
					return self.format.getFundText(data, tab);
				}
			}),
			new Field({
				id:             'lineDesc',
				name:           'lineDesc',
				dataField:      'lineDesc',
				caption:        'Line Item',
				groupingConfig: {
					row: {}
				}
			})
		])
			.concat(self.field.functionalAccountFilterGroupFields(tab))
			.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
			.concat(self.field.getComparisonField(tab));
	}

	expenditures(tab: any): Array<Field> {
		const self = this;

		// TODO: refactor further to eliminate redundant configurations
		return this.field.getBaseFields(tab, [
			new Field({
				id:           'primeCategory',
				name:         'primeCategory',
				dataField:    'primeId',
				area:         'filter', // Always in filter area hidden from user
				filterValues: self.field.filterValuesDefaults['expenditures']['primeCategory']
			}),
			new Field({
				id:             'year',
				name:           'year',
				dataField:      'year',
				caption:        'Year',
				sortingMethod:  self.sort.byString,
				selector:       function(data) {
					return self.format.getYearText(data, tab);
				},
				groupingConfig: {
					expanded: true,
					column:   {
						defaultOrder: 0
					}
				}
			}),
			{
				name:         'standardSummary',
				area:         'filter',
				filterValues: self.field.filterValuesDefaults['expenditures']['summary']
			},
			{
				name:           'standardAccount',
				width:          300,
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'account',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					row:          {
						defaultOrder: 0
					},
					mustBeBefore: ['standardSubAccount', 'standardElement', 'standardSubElement']
				}
			},
			{
				name:           'debtCapitalExpSummary',
				area:           'filter',
				filterValues:   self.field.filterValuesDefaults['expenditures']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'summary',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			},
			{
				name:  'debtCapitalExpAccount',
				width: 300
			},
			new Field({
				id:             'expenditureObject',
				name:           'expenditureObject',
				dataField:      'expenditureObjectId',
				caption:        'Expenditure Object',
				width:          150,
				groupingConfig: {
					row:    {
						advancedDefaultOrder: 4
					},
					column: {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					}
				},
				customizeText:  function(data) {
					return self.format.getNameForExpenditureId(data, tab);
				}
			}),
			new Field({
				id:             'fundType',
				name:           'fundType',
				dataField:      'fundTypeId',
				caption:        'Fund Type',
				groupingConfig: {
					column: {
						defaultOrder: 2
					},
					row:    {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFundTypes(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getFundTypeText(data, tab.snapshotId);
				}
			}),
			new Field({
				id:             'fund',
				name:           'fund',
				dataField:      'fundNumber',
				caption:        'Fund',
				groupingConfig: {
					column: {
						defaultOrder: 3
					},
					row:    {}
				},
				selector:       function(data) {
					return self.format.getFundSelector(data);
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFunds(a, b);
				},
				customizeText:  function(this, data) {
					return self.format.getFundText(data, tab);
				}
			}),
			new Field({
				id:             'lineDesc',
				name:           'lineDesc',
				dataField:      'lineDesc',
				caption:        'Line Item',
				groupingConfig: {
					row: {}
				}
			})
		])
			.concat(self.field.functionalAccountFilterGroupFields(tab))
			.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
			.concat(self.field.getComparisonField(tab))
			.concat(self.field.expenditureObjectFilterField());
	}

	expendituresWithOthers(tab: any): Array<Field> {
		const self = this;

		// TODO: refactor further to eliminate redundant configurations
		return this.field.getBaseFields(tab, [
			new Field({
				id:           'primeCategory',
				name:         'primeCategory',
				dataField:    'primeId',
				area:         'filter', // Always in filter area hidden from user
				filterValues: self.field.filterValuesDefaults['expendituresWithOthers']['primeCategory']
			}),
			new Field({
				id:             'year',
				name:           'year',
				dataField:      'year',
				caption:        'Year',
				sortingMethod:  self.sort.byString,
				selector:       function(data) {
					return self.format.getYearText(data, tab);
				},
				groupingConfig: {
					expanded: true,
					column:   {
						defaultOrder: 0
					}
				}
			}),
			{
				name:         'standardSummary',
				aread:        'filter',
				filterValues: self.field.filterValuesDefaults['expendituresWithOthers']['summary']
			},
			{
				name:           'standardAccount',
				width:          300,
				groupingConfig: {
					alternate:    {
						association: 'financialSummaryHierarchy',
						structure:   'Standard',
						id:          'account',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'Standard'
					},
					row:          {
						defaultOrder: 0
					},
					mustBeBefore: ['standardSubAccount', 'standardElement', 'standardSubElement']
				}
			},
			{
				name:           'debtCapitalExpSummary',
				area:           'filter',
				filterValues:   self.field.filterValuesDefaults['expendituresWithOthers']['summary'],
				groupingConfig: {
					alternate: {
						association: 'financialSummaryHierarchy',
						structure:   'debtCapitalExp',
						id:          'summary',
						active:      tab.pivotGridSettings.financialSummaryHierarchy === 'debtCapitalExp'
					}
				}
			},
			{
				name:  'debtCapitalExpAccount',
				width: 300
			},
			new Field({
				id:             'expenditureObject',
				name:           'expenditureObject',
				dataField:      'expenditureObjectId',
				caption:        'Expenditure Object',
				width:          150,
				groupingConfig: {
					row:    {
						advancedDefaultOrder: 4
					},
					column: {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					}
				},
				customizeText:  function(data) {
					return self.format.getNameForExpenditureId(data, tab);
				}
			}),
			new Field({
				id:             'fundType',
				name:           'fundType',
				dataField:      'fundTypeId',
				caption:        'Fund Type',
				groupingConfig: {
					column: {
						defaultOrder: 2
					},
					row:    {}
				},
				transitions:    {
					filterValues: function(currentValue) {
						return self.field.getValueOrDefault(currentValue, []);
					} // Retains current filter values
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFundTypes(a, b, tab.snapshotId);
				},
				customizeText:  function(data) {
					return self.format.getFundTypeText(data, tab.snapshotId);
				}
			}),
			new Field({
				id:             'fund',
				name:           'fund',
				dataField:      'fundNumber',
				caption:        'Fund',
				groupingConfig: {
					column: {
						defaultOrder: 3
					},
					row:    {}
				},
				selector:       function(data) {
					return self.format.getFundSelector(data);
				},
				sortingMethod:  function(a, b) {
					return self.sort.sortFunds(a, b);
				},
				customizeText:  function(this, data) {
					return self.format.getFundText(data, tab);
				}
			}),
			new Field({
				id:             'lineDesc',
				name:           'lineDesc',
				dataField:      'lineDesc',
				caption:        'Line Item',
				groupingConfig: {
					row: {}
				}
			})
		])
			.concat(self.field.functionalAccountFilterGroupFields(tab))
			.concat(self.field.functionalAccountFilterGroupFields(tab, 'debtCapitalExp'))
			.concat(self.field.expenditureObjectFilterField())
			.concat(self.field.getComparisonField(tab));
	}

	/**
	 * Debt & Liabilities report
	 * @param tab
	 */
	debtAndLiabilities = (tab: Tab): Array<Partial<Field>> => [
		...this.field.getAnnotationsFields(), // title/subtitle for export
		{
			id:             'year',
			name:           'year',
			dataField:      'year',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		},
		{
			id:             'debtCategory',
			name:           'debtCategory',
			dataField:      'bonanzaCategoryId',
			caption:        'Category',
			groupingConfig: {
				expanded: true,
				row:      {
					fixed:        false,
					defaultOrder: 0
				},
			},
			sortingMethod:  (a, b) => this.sort.sortOnBonanzaSchedule9CategoriesSortOn(a, b, tab.snapshotId),
			customizeText:  (data) => this.format.getNameForSchedule9BonanzaCategoryId(
				data,
				tab?.snapshotId,
				tab?.pivotGridSettings?.showAccountCodes
			),
		},
		{
			id:             'debtType',
			name:           'debtType',
			dataField:      'bonanzaTypeId',
			caption:        'Type',
			groupingConfig: {
				row: {
					fixed:        false,
					defaultOrder: 1
				},
			},
			sortingMethod:  (a, b) => this.sort.sortOnBonanzaSchedule9TypesSortOn(a, b, tab.snapshotId),
			customizeText:  (data) => this.format.getNameForSchedule9BonanzaTypeId(
				data,
				tab?.snapshotId,
				tab?.pivotGridSettings?.showAccountCodes
			),
		},
		{
			id: 'debtCategoryItem',
			name: 'debtCategoryItem',
			dataField: 'debtCategoryItemId',
			caption: 'Debt Item',
			groupingConfig: {
				row: {
					fixed: false,
					defaultOrder: 2
				},
			},
			sortingMethod: (a, b) => this.sort.sortOnSnapshotSortOrder(tab.snapshotId, 'debtCategoryItems', a, b),
			customizeText:  (data) => this.format.nameLookup(
				data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes, 'debtCategoryItems'
			),
		},
		this.field.getMeasureField(tab),
		this.field.getComparisonField(tab)
	];

	operatingResultsAnalysisGovernmental = (tab: Tab): Array<Partial<Field>> => [
		...this.field.getAnnotationsFields(), // title/subtitle for export
		...this.field.getFundGroupFilterFields([]),
		{
			id:             'year',
			name:           'year',
			dataField:      'year',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		},
		{
			id:             'fundType',
			name:           'fundType',
			dataField:      'fundTypeId',
			caption:        'Fund Type',
			groupingConfig: {
				column: {
					defaultOrder: 1
				}
			},
			customizeText: data => this.format.getNameFromSnapshot(tab.snapshotId, 'fundTypes', data.value),
			sortingMethod: (a, b) => this.sort.sortFundTypes(a, b, tab.snapshotId)
		},
		{
			id:             'fund',
			name:           'fund',
			dataField:      'fundNumber',
			caption:        'Fund',
			groupingConfig: {
				column: {
					defaultOrder: 2
				},
				row:    {}
			},
			selector: data => this.format.getFundSelector(data),
			sortingMethod: (a, b) => this.sort.sortFunds(a, b),
			customizeText:  data => this.format.getFundText(data, tab)
		},
		{
			id: 'resultsTopLevel',
			name: 'resultsTopLevel',
			dataField: 'operatingResultsTopLevelId',
			caption: 'Results Top-Level',
			width: this.standardFormatWidth,
			customizeText: data => this.format.getNameFromSnapshot(tab.snapshotId, 'operatingResultsTopLevels', data.value),
			sortingMethod: (a, b) => this.sort.sortOnSnapshotSortOrder(tab.snapshotId, 'operatingResultsTopLevels', a.value, b.value),
			groupingConfig: {
				row: {
					defaultOrder: 0,
					fixed: true
				}
			}
		},
		{
			id: 'resultsSection',
			name: 'resultsSection',
			dataField: 'operatingResultsSectionId',
			caption: 'Results Section',
			width: this.standardFormatWidth,
			customizeText: data => this.format.getNameFromSnapshot(tab.snapshotId, 'operatingResultsSections', data.value),
			sortingMethod: (a, b) => this.sort.sortOnSnapshotSortOrder(tab.snapshotId, 'operatingResultsSections', a.value, b.value),
			groupingConfig: {
				row: {
					defaultOrder: 1,
					fixed: true
				}
			}
		},
		{
			id: 'resultsSubSection',
			name: 'resultsSubSection',
			dataField: 'operatingResultsSubSectionId',
			caption: 'Results Sub-Section',
			width: this.standardFormatWidth,
			customizeText: data => this.format.getNameFromSnapshot(tab.snapshotId, 'operatingResultsSubSections', data.value),
			sortingMethod: (a, b) => this.sort.sortOnSnapshotSortOrder(tab.snapshotId, 'operatingResultsSubSections', a.value, b.value),
			groupingConfig: {
				row: {
					defaultOrder: 2,
					fixed: true
				}
			}
		},
		{
			id:             'standardAccount',
			name:           'standardAccount',
			dataField:      'basicAccountId',
			caption:        'Account',
			width: this.standardFormatWidth,
			groupingConfig: {
				required:     true,
				row:          {
					defaultOrder: 3
				},
				mustBeBefore: ['standardSubAccount', 'standardElement', 'standardSubElement']
			},
			sortingMethod: (a, b) => this.sort.sortOnCategoryDisplay(a, b, tab.snapshotId),
			customizeText: data => this.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes)
		},
		{
			id:             'standardSubAccount',
			name:           'standardSubAccount',
			dataField:      'subAccountId',
			caption:        'Sub-Account',
			width: this.standardFormatWidth,
			groupingConfig: {
				row:          {
					defaultOrder: 4
				},
				mustBeAfter:  ['standardAccount'],
				mustBeBefore: ['standardElement', 'standardSubElement']
			},
			sortingMethod: (a, b) => this.sort.sortOnCategoryDisplay(a, b, tab.snapshotId),
			customizeText: data => this.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes)
		},
		{
			id:             'standardElement',
			name:           'standardElement',
			dataField:      'elementId',
			caption:        'Element',
			width: this.standardFormatWidth,
			groupingConfig: {
				row:          {
					defaultOrder: 5
				},
				mustBeAfter:  ['standardAccount', 'standardSubAccount'],
				mustBeBefore: ['standardSubElement']
			},
			sortingMethod: (a, b) => this.sort.sortOnCategoryDisplay(a, b, tab.snapshotId),
			customizeText: data => this.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes)
		},
		{
			id:             'standardSubElement',
			name:           'standardSubElement',
			dataField:      'subElementId',
			caption:        'Sub-Element',
			width: this.standardFormatWidth,
			groupingConfig: {
				row:         {
					defaultOrder: 6
				},
				mustBeAfter: ['standardAccount', 'standardSubAccount', 'standardElement']
			},
			sortingMethod: (a, b) => this.sort.sortOnCategoryDisplay(a, b, tab.snapshotId),
			customizeText: data => this.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes)
		},
		this.field.getComparisonField(tab),
		this.field.getMeasureField(tab)
	];

	operatingResultsAnalysisEnterprise = (tab: Tab): Array<Partial<Field>> => [
		...this.field.getAnnotationsFields(), // title/subtitle for export
		...this.field.getFundGroupFilterFields([]),
		{
			id:             'year',
			name:           'year',
			dataField:      'year',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		},
		{
			id:             'fund',
			name:           'fund',
			dataField:      'fundNumber',
			caption:        'Fund',
			groupingConfig: {
				column: {
					defaultOrder: 2
				},
				row:    {}
			},
			selector: data => this.format.getFundSelector(data),
			sortingMethod: (a, b) => this.sort.sortFunds(a, b),
			customizeText:  data => this.format.getFundText(data, tab)
		},
		{
			id: 'resultsTopLevel',
			name: 'resultsTopLevel',
			dataField: 'operatingResultsTopLevelId',
			caption: 'Results Top-Level',
			width: this.standardFormatWidth,
			customizeText: data => this.format.getNameFromSnapshot(tab.snapshotId, 'operatingResultsTopLevels', data.value),
			sortingMethod: (a, b) => this.sort.sortOnSnapshotSortOrder(tab.snapshotId, 'operatingResultsTopLevels', a.value, b.value),
			groupingConfig: {
				row: {
					defaultOrder: 0,
					fixed: true
				}
			}
		},
		{
			id: 'resultsSection',
			name: 'resultsSection',
			dataField: 'operatingResultsSectionId',
			caption: 'Results Section',
			width: this.standardFormatWidth,
			customizeText: data => this.format.getNameFromSnapshot(tab.snapshotId, 'operatingResultsSections', data.value),
			sortingMethod: (a, b) => this.sort.sortOnSnapshotSortOrder(tab.snapshotId, 'operatingResultsSections', a.value, b.value),
			groupingConfig: {
				row: {
					defaultOrder: 1,
					fixed: true
				}
			}
		},
		{
			id: 'resultsSubSection',
			name: 'resultsSubSection',
			dataField: 'operatingResultsSubSectionId',
			caption: 'Results Sub-Section',
			width: this.standardFormatWidth,
			customizeText: data => this.format.getNameFromSnapshot(tab.snapshotId, 'operatingResultsSubSections', data.value),
			sortingMethod: (a, b) => this.sort.sortOnSnapshotSortOrder(tab.snapshotId, 'operatingResultsSubSections', a.value, b.value),
			groupingConfig: {
				row: {
					defaultOrder: 2,
					fixed: true
				}
			}
		},
		{
			id:             'standardAccount',
			name:           'standardAccount',
			dataField:      'basicAccountId',
			caption:        'Account',
			width: this.standardFormatWidth,
			groupingConfig: {
				required:     true,
				row:          {
					defaultOrder: 3
				},
				mustBeBefore: ['standardSubAccount', 'standardElement', 'standardSubElement']
			},
			sortingMethod: (a, b) => this.sort.sortOnCategoryDisplay(a, b, tab.snapshotId),
			customizeText: data => this.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes)
		},
		{
			id:             'standardSubAccount',
			name:           'standardSubAccount',
			dataField:      'subAccountId',
			caption:        'Sub-Account',
			width: this.standardFormatWidth,
			groupingConfig: {
				row:          {
					defaultOrder: 4
				},
				mustBeAfter:  ['standardAccount'],
				mustBeBefore: ['standardElement', 'standardSubElement']
			},
			sortingMethod: (a, b) => this.sort.sortOnCategoryDisplay(a, b, tab.snapshotId),
			customizeText: data => this.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes)
		},
		{
			id:             'standardElement',
			name:           'standardElement',
			dataField:      'elementId',
			caption:        'Element',
			width: this.standardFormatWidth,
			groupingConfig: {
				row:          {
					defaultOrder: 5
				},
				mustBeAfter:  ['standardAccount', 'standardSubAccount'],
				mustBeBefore: ['standardSubElement']
			},
			sortingMethod: (a, b) => this.sort.sortOnCategoryDisplay(a, b, tab.snapshotId),
			customizeText: data => this.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes)
		},
		{
			id:             'standardSubElement',
			name:           'standardSubElement',
			dataField:      'subElementId',
			caption:        'Sub-Element',
			width: this.standardFormatWidth,
			groupingConfig: {
				row:         {
					defaultOrder: 6
				},
				mustBeAfter: ['standardAccount', 'standardSubAccount', 'standardElement']
			},
			sortingMethod: (a, b) => this.sort.sortOnCategoryDisplay(a, b, tab.snapshotId),
			customizeText: data => this.format.getNameForBARSId(data, tab?.snapshotId, tab?.pivotGridSettings?.showAccountCodes)
		},
		this.field.getComparisonField(tab),
		this.field.getMeasureField(tab)
	];

	/**
	 * Schools' Balance Sheet report
	 * @param tab
	 */
	schoolsBalanceSheet = (tab: Tab): Array<Partial<Field>> => [
		new Field({
			id:           'reportNo',
			name:         'reportNo',
			dataField:    'reportNo',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsBalanceSheet']['reportNo']
		}),
		this.field.getOSPIEmptyAmountFilterField(tab),
		this.field.getOSPIEmptyFundYearFilterField(tab),
		new Field({
			id:             'fy',
			name:           'fy',
			dataField:      'fy',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		}),
		new Field({
			id:             'fundCode',
			name:           'fundCode',
			dataField:      'fundCode',
			caption:        'Fund',
			width:          this.standardFormatWidth,
			groupingConfig: {
				column: {
					defaultOrder: 1
				},
				row:    {}
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('funds', null, 'description', 'code', value),
			sortingMethod: (a, b) => this.sort.sortOnOSPIFund(a, b),
		}),
		{
			id:             'multiYearSortOrder',
			name:           'multiYearSortOrder',
			dataField:      'multiYearSortOrder',
			caption:        'Report Item',
			groupingConfig: {
				row:      {
					required:     true,
					defaultOrder: 0
				},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('reportItems', '001', 'title', 'multiYearSortOrder', value),
		},
		this.field.getMeasureField(tab)
	];

	/**
	 * Schools Long Term Liabilities
	 * @param tab
	 */
	schoolsLongTermLiabilities = (tab: Tab): Array<Partial<Field>> => [
		new Field({
			id:           'reportNo',
			name:         'reportNo',
			dataField:    'reportNo',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsLongTermLiabilities']['reportNo']
		}),
		this.field.getOSPIEmptyAmountFilterField(tab),
		new Field({
			id:             'fy',
			name:           'fy',
			dataField:      'fy',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		}),
		new Field({
			id:             'column',
			name:           'column',
			dataField:      'columnOrder',
			caption:        'Column',
			// Note that this technically should be looking at both columnOrder and fy to retrieve the appropriate
			//  record, but functionally speaking these columns are codified in BARS and should not change year to year
			//  so looking up the first columnOrder should be sufficient. (Until it isn't.)
			customizeText: value => this.format.getOSPICollectionObject(
				'columns',
				'013',
				'name',
				'columnOrder',
				value
			),
			groupingConfig: {
				column:   {
					defaultOrder: 1
				}
			}
		}),
		{
			id:             'reportItem',
			name:           'reportItem',
			dataField:      'multiYearSortOrder',
			caption:        'Report Item',
			groupingConfig: {
				row:      {
					required:     true,
					defaultOrder: 0
				},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('reportItems', '013', 'title', 'multiYearSortOrder', value),
		},
		this.field.getMeasureField(tab)
	];

	/**
	 * Schools' Statement of Revenues, Expenditures, & Changes in Fund Balance report
	 * @param tab
	 */
	schoolsStatementRevExp = (tab: Tab): Array<Partial<Field>> => [
		new Field({
			id:           'reportNo',
			name:         'reportNo',
			dataField:    'reportNo',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsStatementRevExp']['reportNo']
		}),
		this.field.getOSPIEmptyAmountFilterField(tab),
		this.field.getOSPIEmptyFundYearFilterField(tab),
		new Field({
			id:             'fy',
			name:           'fy',
			dataField:      'fy',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		}),
		new Field({
			id:             'fundCode',
			name:           'fundCode',
			dataField:      'fundCode',
			caption:        'Fund',
			width:          this.standardFormatWidth,
			groupingConfig: {
				column: {
					defaultOrder: 1
				},
				row:    {}
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('funds', null, 'description', 'code', value),
			sortingMethod: (a, b) => this.sort.sortOnOSPIFund(a, b),
		}),
		{
			id:             'multiYearSortOrder',
			name:           'multiYearSortOrder',
			dataField:      'multiYearSortOrder',
			caption:        'Report Item',
			groupingConfig: {
				row:      {
					required:     true,
					defaultOrder: 0
				},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('reportItems', '002', 'title', 'multiYearSortOrder', value),
		},
		this.field.getMeasureField(tab)
	];

	/**
	 * Schools' Revenues with Other Financing Sources report
	 * @param tab
	 */
	schoolsRevenuesWithOthers = (tab: Tab): Array<Partial<Field>> => [
		new Field({
			id:           'reportNo',
			name:         'reportNo',
			dataField:    'reportNo',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsRevenuesWithOthers']['reportNo']
		}),
		this.field.getOSPIEmptyAmountFilterField(tab),
		this.field.getOSPIEmptyFundYearFilterField(tab),
		new Field({
			id:             'fy',
			name:           'fy',
			dataField:      'fy',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		}),
		new Field({
			id:             'fundCode',
			name:           'fundCode',
			dataField:      'fundCode',
			caption:        'Fund',
			width:          this.standardFormatWidth,
			groupingConfig: {
				column: {
					defaultOrder: 1
				},
				row:    {}
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('funds', null, 'description', 'code', value),
			sortingMethod: (a, b) => this.sort.sortOnOSPIFund(a, b),
		}),
		{
			id:             'multiYearSortOrder',
			name:           'multiYearSortOrder',
			dataField:      'multiYearSortOrder',
			caption:        'Report Item',
			groupingConfig: {
				row:      {
					required:     true,
					defaultOrder: 0
				},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('reportItems', '009', 'title', 'multiYearSortOrder', value, tab?.pivotGridSettings?.showAccountCodes),
		},
		this.field.getMeasureField(tab)
	];

	/**
	 * Schools' Expenditures Report
	 * @param tab
	 */
	schoolsExpenditures = (tab: Tab): Array<Partial<Field>> => [
		new Field({
			id:           'reportNo',
			name:         'reportNo',
			dataField:    'reportNo',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsExpenditures']['reportNo']
		}),
		this.field.getOSPIEmptyAmountFilterField(tab),
		this.field.getOSPIEmptyFundYearFilterField(tab),
		new Field({
			id:           'itemCategory',
			name:         'itemCategory',
			dataField:    'itemCategory',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsExpenditures']['itemCategory']
		}),
		new Field({
			id:             'fy',
			name:           'fy',
			dataField:      'fy',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		}),
		new Field({
			id:             'fundCode',
			name:           'fundCode',
			dataField:      'fundCode',
			caption:        'Fund',
			width:          this.standardFormatWidth,
			groupingConfig: {
				column: {
					defaultOrder: 1
				},
				row:    {}
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('funds', null, 'description', 'code', value),
			sortingMethod: (a, b) => this.sort.sortOnOSPIFund(a, b),
		}),
		{
			id:             'multiYearSortOrder',
			name:           'multiYearSortOrder',
			dataField:      'multiYearSortOrder',
			caption:        'Report Item',
			groupingConfig: {
				row:      {
					required:     true,
					defaultOrder: 0
				},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('reportItems', '002', 'title', 'multiYearSortOrder', value),
		},
		this.field.getMeasureField(tab)
	];

	/**
	 * Schools' Revenues Report
	 * @param tab
	 */
	schoolsRevenues = (tab: Tab): Array<Partial<Field>> => [
		new Field({
			id:           'reportNo',
			name:         'reportNo',
			dataField:    'reportNo',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsRevenues']['reportNo']
		}),
		this.field.getOSPIEmptyAmountFilterField(tab),
		this.field.getOSPIEmptyFundYearFilterField(tab),
		new Field({
			id:           'itemCategory',
			name:         'itemCategory',
			dataField:    'itemCategory',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsRevenues']['itemCategory']
		}),
		new Field({
			id:             'fy',
			name:           'fy',
			dataField:      'fy',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		}),
		new Field({
			id:             'fundCode',
			name:           'fundCode',
			dataField:      'fundCode',
			caption:        'Fund',
			width:          this.standardFormatWidth,
			groupingConfig: {
				column: {
					defaultOrder: 1
				},
				row:    {}
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('funds', null, 'description', 'code', value),
			sortingMethod: (a, b) => this.sort.sortOnOSPIFund(a, b),
		}),
		{
			id:             'multiYearSortOrder',
			name:           'multiYearSortOrder',
			dataField:      'multiYearSortOrder',
			caption:        'Report Item',
			groupingConfig: {
				row:      {
					required:     true,
					defaultOrder: 0
				},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('reportItems', '002', 'title', 'multiYearSortOrder', value),
		},
		this.field.getMeasureField(tab)
	];

	/**
	 * Schools' Liabilities Report
	 * @param tab
	 */
	schoolsLiabilities = (tab: Tab): Array<Partial<Field>> => [
		new Field({
			id:           'reportNo',
			name:         'reportNo',
			dataField:    'reportNo',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsLiabilities']['reportNo']
		}),
		this.field.getOSPIEmptyAmountFilterField(tab),
		this.field.getOSPIEmptyFundYearFilterField(tab),
		new Field({
			id:           'itemCategory',
			name:         'itemCategory',
			dataField:    'itemCategory',
			area:         'filter', // Always in filter area hidden from user
			filterValues: this.field.filterValuesDefaults['schoolsLiabilities']['itemCategory']
		}),
		new Field({
			id:             'fy',
			name:           'fy',
			dataField:      'fy',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		}),
		new Field({
			id:             'fundCode',
			name:           'fundCode',
			dataField:      'fundCode',
			caption:        'Fund',
			width:          this.standardFormatWidth,
			groupingConfig: {
				column: {
					defaultOrder: 1
				},
				row:    {}
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('funds', null, 'description', 'code', value),
			sortingMethod: (a, b) => this.sort.sortOnOSPIFund(a, b),
		}),
		{
			id:             'multiYearSortOrder',
			name:           'multiYearSortOrder',
			dataField:      'multiYearSortOrder',
			caption:        'Report Item',
			groupingConfig: {
				row:      {
					required:     true,
					defaultOrder: 0
				},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('reportItems', '001', 'title', 'multiYearSortOrder', value),
		},
		this.field.getMeasureField(tab)
	];

	/**
		 * Schools' General Fund Expenditures Detail Report
	 * @param tab
	 */
	schoolsGeneralFundExpenditures = (tab: Tab): Array<Partial<Field>> => [
		new Field({
			id:             'fy',
			name:           'fy',
			dataField:      'fy',
			caption:        'Year',
			sortingMethod:  this.sort.byString,
			selector:       data => this.format.getYearText(data, tab),
			groupingConfig: {
				expanded: true,
				column:   {
					defaultOrder: 0
				}
			}
		}),
		new Field({
			id:             'programCode',
			name:           'programCode',
			dataField:      'programCode',
			caption:        'Program',
			width: this.wideFormatWidth,
			groupingConfig: {
				column:    {},
				row:      {
					defaultOrder: 0
				},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('programs', null, 'description', 'code', value, tab?.pivotGridSettings?.showAccountCodes),
		}),
		new Field({
			id:             'activityCode',
			name:           'activityCode',
			dataField:      'activityCode',
			caption:        'Activity',
			width: this.wideFormatWidth,
			groupingConfig: {
				column:    {},
				row:      {},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('activities', null, 'description', 'code', value, tab?.pivotGridSettings?.showAccountCodes),
		}),
		new Field({
			id:             'objectCode',
			name:           'objectCode',
			dataField:      'objectCode',
			caption:        'Object',
			width: this.wideFormatWidth,
			groupingConfig: {
				column:    {},
				row:      {},
			},
			customizeText:  (value) => this.format.getOSPICollectionObject('objects', null, 'description', 'code', value, tab?.pivotGridSettings?.showAccountCodes),
		}),
		this.field.getMeasureField(tab)
	];

	/**
	 * Apply formatting to the cells, independent of values used for sorting.
	 * @param event - DevExtreme PivotGrid.onCellPrepared event
	 */
	formatOSPIPivotGridCell = event => {
		// this.logger.log(`cell`, event.cell);
		const ds = event.component.getDataSource()
			.createDrillDownDataSource(event.cell);
		ds.paginate(false);
		return ds.load()
			.then((detailRecords: Array<OSPIFinancialReportRow>) => {
				// this.logger.log(`drilldown facts`, detailRecords);
				// todo refactor, these methods should go in a DevExtreme helper class for consistency
				const isDataCell = event.cell.columnType === 'D' && event.cell.rowType === 'D';
				const isHeaderRow = event.cell.type != null && event.area === 'row'; // Only row/column headers have 'type'
				const isPivotGridGrandTotalRow = event.cell.rowType === 'GT' || event.cell.type === 'GT'; // Look for a pivot grid defined grand total row
				if (isPivotGridGrandTotalRow) {
					event.cellElement.classList.add('ospi-bold');
				} else	if (isDataCell || isHeaderRow) {
					// what is this doing?
					// If header, make sure to filter out any non-itemTypes, and only grab records that are this reportItem
					const filteredRecords = isDataCell ? detailRecords : detailRecords.filter(r => r.itemType !== null && r.multiYearSortOrder === event.cell.path[0]);
					// this.logger.log(`filtered records`, filteredRecords);
					const isHeaderItemType = OSPIFinancialReportRow.allRecordsHaveItemType(filteredRecords, 'Header');
					const isTotalItemType = OSPIFinancialReportRow.allRecordsHaveItemType(filteredRecords, 'Total');
					const isGrandTotalType = OSPIFinancialReportRow.allRecordsHaveItemType(filteredRecords, 'GrandTotal');

					if (isHeaderItemType || isTotalItemType || isGrandTotalType) {
						event.cellElement.classList.add('ospi-bold');
					}
				}

				if (event.cell.value === 0) {
					event.cellElement.innerHTML = '';
				}
			});
	};

	/**
	 * Apply formatting to the excel cells */
	formatOSPIExcelCell = (component: DxPivotGridComponent, options, boldFont) => {
		const {excelCell, pivotCell} = options;
		// this.logger.log(`cell`, pivotCell);
		const ds = component.instance.getDataSource()
			.createDrillDownDataSource(options.pivotCell);
		ds.paginate(false);
		return ds.load()
			.then((detailRecords: Array<OSPIFinancialReportRow>) => {
				// this.logger.log(`drilldown facts`, detailRecords);
				const isDataCell = pivotCell.columnType === 'D' && pivotCell.rowType === 'D';
				const isHeaderRow = pivotCell.type != null && pivotCell.area === 'row'; // Only row/column headers have 'type'
				const isPivotGridGrandTotalRow = pivotCell.rowType === 'GT' || pivotCell.type === 'GT'; // Look for a pivot grid defined grand total row
				if (isPivotGridGrandTotalRow) {
					excelCell.font = boldFont;
				} else if (isDataCell || isHeaderRow) {
					const filteredRecords = isDataCell ? detailRecords : detailRecords.filter(r => r.itemType !== null && r.multiYearSortOrder === pivotCell.path[0]);
					// this.logger.log(`filtered detailRecords`, filteredRecords);
					const isHeaderItemType = OSPIFinancialReportRow.allRecordsHaveItemType(filteredRecords, 'Header');
					const isTotalItemType = OSPIFinancialReportRow.allRecordsHaveItemType(filteredRecords, 'Total');
					const isGrandTotalType = OSPIFinancialReportRow.allRecordsHaveItemType(filteredRecords, 'GrandTotal');

					if (isHeaderItemType || isTotalItemType || isGrandTotalType) {
						excelCell.font = boldFont;
					}
				}

				if (pivotCell.value === 0) {
					excelCell.value = '';
				}
			});
	};
}
