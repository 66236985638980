import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { FieldService } from 'app/shared/services/field.service';
import { LoggerService } from '../../services/logger.service';
import { UserService } from 'app/shared/services/user.service';

@Component({
	selector: 'app-account-chooser',
	templateUrl: './account-chooser.component.html',
	styleUrls: ['./account-chooser.component.scss']
})
export class AccountChooserComponent implements OnInit, OnChanges {
	@ViewChild(DxTreeViewComponent, { static: true }) treeView: DxTreeViewComponent;
	@Input() selections: Array<Array<number>>;
	@Input() showAccountCodes: boolean;
	@Input() pivotGridData: any;
	@Input() reportId: string;
	@Input() snapshot: any;
	@Output() changed = new EventEmitter<any>();

	store: any;
	private topLevelParents = [1902, 1];

	constructor(
		private fieldService: FieldService,
		private logger: LoggerService,
		private userService: UserService
	) { }

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		const isEffectiveChange = (changes.pivotGridData && changes.pivotGridData.currentValue)
			|| (changes.reportId && changes.reportId.currentValue);

		if (isEffectiveChange) {
			this.store = this.getAvailableAccounts();
		}
	}

	getSummaryFilterForReport = (reportId: string) => {
		switch (reportId) {
			case 'revenues':
				return [20];
			case 'revenuesWithOthers':
				return [20, 25];
			case 'expenditures':
				return [30];
			case 'expendituresWithOthers':
				return [30, 35];
		}
	}

	getAvailableAccounts() {
		const accountField = this.fieldService.getActiveAlternateField(this.pivotGridData, 'financialSummaryHierarchy', 'account');
		const isShowDebtCapital = accountField && accountField.dataField.indexOf('debtCapitalExp') > -1;
		// get summary filter based on report
		const summaryFilter = this.getSummaryFilterForReport(this.reportId);

		// $log.debug('AccountChooser.getAvailableAccounts', primeCategoryFilter, revExpDisplayFilter, accountFilterValues);

		const accounts = this.snapshot.detail.accountDescriptors.filter(account =>
				// use debtCapital toggle to determine which tree to display
				summaryFilter.some(v => isShowDebtCapital ? v === account.dcfsSectionId : v === account.fsSectionId)
		).map(account => {
			account.selected = this.selections && this.selections.some(v => v[v.length - 1] === account.id);

			// If Debt Capital toggle is on, remap to alternate structure
			if (isShowDebtCapital) {
				// set parentId to null to make top-level
				account.parentId = this.topLevelParents.some(p => p === account.dcParentId)
					? null
					: account.dcParentId;
				account.hasChildren = account.dcHasChildren;
			} else {
				account.parentId = this.topLevelParents.some(p => p === account.parentId)
					? null
					: account.parentId;
			}

			return account;
		}).sort((a, b) => {
			if (isShowDebtCapital) {
				a = a.dcfsSectionId + a.categoryDisplay + a.id;
				b = b.dcfsSectionId + b.categoryDisplay + b.id;
			} else {
				a = a.fSSectionId + a.categoryDisplay + a.id;
				b = b.fSSectionId + b.categoryDisplay + b.id;
			}
			return (a > b) ? 1 : ((a < b) ? -1 : 0);
		});

		return accounts;
	}

	evaluateSelectionChanged(event) {
		// reset selections every time
		this.selections = [];
		this.selectPath(this.treeView.instance.getNodes());
		// Always include null as filter value to preserve phantom rows when another filter exists
		if (this.selections.length > 0) {
			this.selections.push([null]);
		}
		this.logger.log('AccountChooser.onItemSelectionChanged', this.selections);
		this.changed.emit(this.selections);
	}

	// Accumulates path (parent ids) for nodes that have `undefined` as their `selected` property,
	// finally emitting the path once selected === true is encountered
	// eg. [6, 7, 5459] indicates indeterminate states for 6 and 7, with 5459 being selected (true)
	selectPath(node: Array<any>, path = []) {
		node.forEach(v => {
			if (v.selected === true) {
				this.selections.push(path.concat(v.key));
			} else if (typeof v.selected === 'undefined' && v.children) {
				this.selectPath(v.children, path.concat(v.key));
			}
		});
	}
}
