<dx-select-box
	[disabled]="dataSource?.length < 1"
	[dataSource]="dataSource"
	[placeholder]="placeholder"
	valueExpr="name"
	[value]="value"
	displayExpr="name"
	(onSelectionChanged)="selectionChanged($event.selectedItem)"
></dx-select-box>
<dx-load-panel [visible]="isLoading"></dx-load-panel>
