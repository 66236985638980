import {Component, OnInit, ViewChild} from '@angular/core';
import {DxFileUploaderComponent, DxPopupComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import {UserInterfaceService} from '../../../shared/services/user-interface.service';
import {ResourcesService} from '../resources.service';
import {Resource} from '../../../shared/models/resource';
import notify from 'devextreme/ui/notify';
import {catchError, delay, tap} from 'rxjs/operators';
import {Reusable} from '../../../modules/reusable/models/reusable';
import {ResourceChanged} from '../resource-changed';
import {TabBarItem} from '../../../shared/components/tab-bar/tab-bar.component';

@Component({
	selector: 'app-create-edit-resource',
	templateUrl: './create-edit-resource.component.html',
	styleUrls: ['./create-edit-resource.component.scss']
})
export class CreateEditResourceComponent extends Reusable implements OnInit {
	@ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
	@ViewChild(DxFileUploaderComponent, {static: false}) fileUploader: DxFileUploaderComponent;

	isVisible = true;
	private uiSubscription: Subscription;
	resource: Resource;
	private imageFile: any;
	loadingMessage: string;
	disabled = false;
	items: Array<TabBarItem> = [{id: 'Link', label: 'Link'}, {
		id: 'Embed', label: 'Embed'
	}];

	constructor(private uiService: UserInterfaceService, private resourcesService: ResourcesService) {
		super();
		this.uiSubscription = this.uiService.createEditResource$.subscribe(
			value => {
				this.isVisible = value.visible;
				if (value.id) {
					this.resourcesService.getResourceItem(value.id).subscribe(e => this.resource = e);
				} else {
					this.resource = new Resource();
					this.resource.type = 'Link';
				}
			});
	}

	ngOnInit(): void {

	}

	uploadFile = (file: any) => {
		this.imageFile = file;
	};

	handleSubmit(e) {
		this.disabled = true;
		if (this.resource.id) {
			this.editResource();
		} else {
			this.createResource();
		}
		e.preventDefault();
	}


	editResource() {
		this.clearUnusedFields();
		this.resourcesService.editResourceItem(this.resource)
			.pipe(
				tap(_ => {
					this.isLoading = true;
					this.loadingMessage = 'Editing Resource';
				}),
				catchError(error => {
					this.disabled = false;
					throw new notify({
						closeOnClick: true,
						displayTime: 10000,
						closeOnOutsideClick: true,
						type: 'error',
						message: error.message
					});

				})
			).subscribe(result => {
			this.resourcesService.resourceChanged.next(new ResourceChanged(this.resource.id, 'edit', this.resource.title));
			this.isLoading = false;
			this.isVisible = false;
		});
	}

	createResource() {
		this.clearUnusedFields();
		this.resourcesService.createNewResource(this.resource)
			.pipe(
				tap(_ => {
					this.isLoading = true;
					this.loadingMessage = 'Adding new resource';
				}),
				catchError(error => {
					this.disabled = false;
					throw new notify({
						closeOnClick: true,
						displayTime: 10000,
						closeOnOutsideClick: true,
						type: 'error',
						message: error.message
					});
				})
			).subscribe(result => {
			this.resourcesService.resourceChanged.next(new ResourceChanged(result.id, 'create', result.title));
			this.isLoading = false;
			this.isVisible = false;
		});
	}

	clearUnusedFields() {
		if (this.resource.type === 'Link') {
			// clears embed data
			this.resource.embed = null;
			this.resource.description = null;
		}
		if (this.resource.type === 'Embed') {
			// clears link data
			this.resource.link = null;
			this.resource.linkText = null;
			this.resource.image = null;
		}
	}

	onUploaded(e: any) {
		if (e.file) {
			const reader = new FileReader();
			reader.readAsDataURL(e.file);
			reader.onload = () => {
				// takes out file type part of string
				this.resource.image = reader.result.toString().split(',')[1];
			};
		}

	}

	onUploadError(err: any) {
		notify({
			closeOnClick: true,
			displayTime: 10000,
			closeOnOutsideClick: true,
			type: 'error',
			message: err.message
		});
	}

	onHidden() {
		// clear uploader
		if (this.fileUploader) {
			this.fileUploader.instance.reset();
		}
		// clear image
		this.imageFile = null;

		this.disabled = false;
	}

	deleteImage() {
		if (this.fileUploader) {
			this.fileUploader.instance.removeFile(this.imageFile);
		}
	}

	deleteSavedImage() {
		this.resource.image = null;
	}

	onResourceTypeChange = (e): void => {
		this.resource.type = e;
	};

}
