<dx-popup
	class="modal bonanza-popup"
	height="auto"
	[width]="width"
	[title]="title"
	[showCloseButton]=false
	[visible]="isVisible"
	>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper">
			<div class="app-popup__inner">
				<div class="u-margin-bottom-none">
					<p>{{ content }}</p>
					<ng-container *ngIf="!hideQuestion">
						<hr />
						<div class="question">What would you like to do?</div>
					</ng-container>
				</div>
			</div>

			<div class="app-popup__footer">
				<dx-button
					*ngFor="let option of options"
					[type]="option.default ? 'default' : 'normal'"
					[text]="option.label"
					(onClick)="onConfirm($event, option)"
				></dx-button>
			</div>
		</div>
	</div>
</dx-popup>
