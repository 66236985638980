/**
 * @deprecated see src/app/shared/models/report.ts
 */
export enum ReportId {
	expenditures       = 'expenditures',
	revenues           = 'revenues',
	summary            = 'summary',
	debtAndLiabilities = 'debtAndLiabilities',
	operatingResultsAnalysisGovernmental = 'operatingResultsAnalysisGovernmental',
	operatingResultsAnalysisEnterprise = 'operatingResultsAnalysisEnterprise'
}
