import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {TabService} from 'app/shared/services/tab.service';
import {Tab} from 'app/shared/models/tab';
import {Subscription} from 'rxjs';
import {WizardService} from 'app/components/wizard/wizard.service';
import {LoggerService} from 'app/shared/services/logger.service';
import {DxTabPanelComponent} from 'devextreme-angular';
import {TabState} from 'app/shared/models/tab-state';
import {HistoryService} from '../../shared/services/history.service';
import {SnapshotService} from '../../shared/services/snapshot.service';

@Component({
	selector:    'app-tabs',
	templateUrl: './tabs.component.html',
	styleUrls:   ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnDestroy {
	@ViewChild(DxTabPanelComponent, {static: true}) dxTabPanel: DxTabPanelComponent;
	@ViewChild('newSearch', {static: true}) newSearch: ElementRef;

	public icons = {
		create: faPlusCircle,
		delete: faTimes
	};
	public tabs: Array<Tab>;
	public selectedTab: Tab;
	private tabPanelContent: Element;
	private tabsSubscription: Subscription;
	private createTab: Tab;

	constructor(
		private tabService: TabService,
		private wizardService: WizardService,
		private logger: LoggerService,
		private historyService: HistoryService,
		private snapshotService: SnapshotService,
	) {
		this.logger.log({Tabs: this});
		this.createTab           = new Tab('New Query');
		this.createTab.canCreate = true;
	}

	ngOnInit() {
		this.tabsSubscription = this.tabService.tabs.subscribe(this.onTabsUpdate);

		// TODO: check if user has been active in a day (or first time), if not, add a new hype tab
	}

	onTabsUpdate = (tabs: Array<Tab>) => {
		const selectedTab = tabs?.find(x => x.selected);
		if (selectedTab) {
			// ensure that the snapshot for the current tab is loaded before proceeding to ensure all lookup references
			// can be resolved
			this.snapshotService.getSnapshot(selectedTab.snapshotId).subscribe(snapshot => {
				// first value is null due to BehaviorSubject
				if (snapshot != null) {
					this.tabs = tabs;
					this.selectedTab = selectedTab;
					this.logger.info('tabs.component:onTabsUpdate tabs:', this.tabs, 'selected:', this.selectedTab);
				}
			});
		}
	};

	onContentReady = () => {
		const wrapper = this.dxTabPanel.instance.element().querySelector('.dx-tabs-wrapper');
		wrapper.after(this.newSearch.nativeElement);

		this.tabPanelContent = this.dxTabPanel.instance.element().querySelector('.tab-panel__content');
	};

	ngOnDestroy() {
		if (this.tabsSubscription) {
			this.tabsSubscription.unsubscribe();
		}
	}

	delete(event: Event, tab: Tab): void {
		this.logger.log('delete tab', event, tab);
		this.historyService.replaceStateTabs();
		event.stopPropagation(); // prevent DevExtreme from reacting to click event

		const index = this.tabs.findIndex(x => x.id === tab.id);

		// If the selected tab is being deleted AND there is another tab available...
		if (this.selectedTab.id === tab.id && this.tabs.length > 1) {
			if (index) {
				// Select the tab to the left
				this.select(this.tabs[index - 1]);
			}
			else {
				// If it's the first tab, select to the right
				this.select(this.tabs[1]);
			}
		}

		this.tabService.delete(tab).then(() => this.historyService.pushStateTabs());
	}

	launchNewSearch() {
		this.historyService.replaceStateTabs();
		this.tabService.buildBlankTab('New Tab', TabState.hype, true)
			.then(() => this.historyService.pushStateTabs());
	}

	selectionChanged = (event): void => {
		const tab = <Tab>(event.addedItems && event.addedItems[0]);
		this.select(tab);
	};

	private select = (tab: Tab): void => {
		if (tab.id === this.selectedTab.id) {
			return;
		}
		this.historyService.replaceStateTabs();
		this.tabService.setSelectedTab(tab).then(() => this.historyService.pushStateTabs());
	};

	getState = (tab: Tab) => tab.state;

	scrollTabPanelContent = (event: { x?: number, y?: number }) =>
		this.tabPanelContent.scrollTo(event.x || 0, event.y || 0);

}
