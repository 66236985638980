<div #newSearch class="new-search u-hidden-print">
	<button class="new-search__button" (click)="launchNewSearch()">
		<fa-icon [icon]="icons.create"></fa-icon>
		New Search
	</button>
</div>

<dx-tab-panel
	#tabPanel
	class="tab-panel"
	[class.tab-panel--fixed]="selectedTab && (selectedTab.state === 'lgfrs' || selectedTab.state === 'wizard')"
	height="100%"
	width="100%"
	[focusStateEnabled]="false"
	[scrollingEnabled]="false"
	[dataSource]="tabs"
	[selectedItem]="selectedTab"
	(onSelectionChanged)="selectionChanged($event)"
	(onContentReady)="onContentReady()"
>
	<div *dxTemplate="let tab of 'title'" class="tab-panel__title u-hidden-print">
		{{ tab.title }}
		<fa-icon *ngIf="tabs.length > 1" [icon]="icons.delete" (click)="delete($event, tab)" class="tab-panel__title__delete"></fa-icon>
	</div>

	<div *dxTemplate="let tab of 'item'" class="tab-panel__content">
		<div class="u-max-height-100">
			<div [ngSwitch]="tab.state" class="u-max-height-100">
				<app-wizard [tab]="tab" *ngSwitchCase="'wizard'"></app-wizard>
				<app-schedule-browser [tab]="tab" *ngSwitchCase="'lgfrs'"></app-schedule-browser>
				<app-government-search-map [tab]="tab" *ngSwitchCase="'government-search-map'" class="no-scroll"></app-government-search-map>
				<app-government-search-name [tab]="tab" *ngSwitchCase="'government-search-name'" class="no-scroll"></app-government-search-name>
				<app-government-profile
					[tab]="tab"
					*ngSwitchCase="'government-profile'"
					(scrollParent)="scrollTabPanelContent($event)">
				</app-government-profile>
				<app-government-type-profile [tab]="tab" *ngSwitchCase="'government-type-profile'"></app-government-type-profile>
				<wasao-indicator [tab]="tab" *ngSwitchCase="'indicator'"></wasao-indicator>
				<app-hype [tab]="tab" *ngSwitchDefault></app-hype>
			</div>
		</div>
	</div>
</dx-tab-panel>
