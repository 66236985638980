import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RankingsComponent} from './rankings.component';
import {HintModule} from '../../ui/hint/hint.module';
import {LoadingModule} from '../../ui/loading/loading.module';


@NgModule({
	declarations: [RankingsComponent],
	imports: [
		CommonModule,
		HintModule,
		LoadingModule
	],
	exports: [
		RankingsComponent
	]
})
export class RankingsModule {
}
