import {TabState} from './tab-state';
import {DatasetSource} from '../../modules/api/fit-api/models/datasets/dataset-source';

export enum ReportType {
	indicator = 'indicator',
	lgfrs = 'lgfrs',
	ospi = 'ospi'
}

export interface ReportTypeMenuOption {
	reportType: ReportType;
	tabState: TabState;
	financialsDatasetSource: DatasetSource;
	name: string;
	schools: boolean;
}

export const REPORT_TYPES: Readonly<Array<Partial<ReportTypeMenuOption>>> = [
	{
		reportType: ReportType.indicator,
		tabState: TabState.indicator,
		financialsDatasetSource: 'SAOAnnualFiling',
		name: 'Financial Health Indicators'
	},
	{
		reportType: ReportType.lgfrs,
		tabState: TabState.lgfrs,
		financialsDatasetSource: 'SAOAnnualFiling',
		name: 'Annual Filing Data'
	},
	{
		reportType: ReportType.ospi,
		tabState: TabState.lgfrs, // OSPI and SAO Annual Filing share the same tab state
		financialsDatasetSource: 'OSPI',
		name: 'Schools Financial Data',
		schools: true
	}
] as const;
