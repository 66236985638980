<div class="hint">
	<span class="hint__target" #target [style.color]="this.iconColor">
		<fa-icon *ngIf="iconDefinition" class="hint__target__icon" [icon]="iconDefinition"></fa-icon>
    	<span *ngIf="text" class="hint__target__text">{{ text }}</span>
	</span>

	<dx-popover
		class="hint__popover"
		position="right"
		closeOnOutsideClick="true"
		width="300"
		[target]="target"
		[showEvent]="showOn === 'click' ? 'click' : 'dxhoverstart'"
		[hideEvent]="showOn === 'click' ? null : 'dxhoverend'"
	>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="hintText; then text else content"></div>
			<ng-template #text>{{ hintText }}</ng-template>
			<ng-template #content>
				<ng-content></ng-content>
			</ng-template>
		</div>
	</dx-popover>
</div>
