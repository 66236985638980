import {PivotGridSettings} from './pivot-grid-settings';
import {BehaviorSubject} from 'rxjs';
import {TabState} from './tab-state';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {AccountingCategory} from '../../modules/services/accounting-category/models/accounting-category';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

/**
 * todo design and create Tab subclasses to ensure model integrity
 *  e.g. government-type based reports cannot have governments
 */
export class Tab {
	public id: number;
	public userId: number;
	public selected: boolean;
	public title: string;
	public years: [number, number];
	public governments: any[]; // track a, b1, b2 (includes prime/baseline)
	public govTypes: any[]; // track c & d; optional -or- GovTypeProfile; required, single
	public locations: any[]; // track c & d; optional
	public category: AccountingCategory; // track d; required
	public report: any; // track a, b1, b2 todo type as Report
	public indicator: any; // FIT Indicator
	public track: any; // any LGFRS report
	public filingBasis: FilingBasis;
	public pivotGridSettings: PivotGridSettings; // any LGFRS report
	public pivotGridState: any; // any LGFRS report
	public summary: any; // title, subtitle
	public state: TabState;
	public indicatorGroupsState: any = null;  // expanded group on govt profile (can be expanded by user or auto-expanded if only one group
	public navigatedFromIndicators = false; // set to true when leaving gov't profile by clicking on indicator
	public showPopupNotification = true;
	// for use by certain context menu options on LGFRS report
	// sets fields to be picked up when a new Tab/PivotGridDataSource is being generated from
	// an existing tab
	public initialFields: Array<any>;

	private _historySnapshotId: SnapshotId = null;
	private _snapshotId: BehaviorSubject<SnapshotId>;

	public canCreate = false;

	constructor(
		title           = 'New Tab',
		state: TabState = TabState.hype,
		snapshotId?: SnapshotId
	) {
		this.title      = this.title || title;
		this.state      = this.state || state;
		this.snapshotId = snapshotId;
	}

	get snapshotId(): SnapshotId {
		return this._snapshotId
			? this._snapshotId.getValue()
			: null;
	}

	set snapshotId(id: SnapshotId) {
		if (!this._snapshotId) {
			this._snapshotId = new BehaviorSubject(id);
		}
		else {
			// check value for change before emitting a new value
			if (this._snapshotId.getValue() !== id) {
				this._snapshotId.next(id);
			}
		}
		this._historySnapshotId = id;

		// this._snapshotId
		// 	? this._snapshotId.next(id)
		// 	: this._snapshotId = new BehaviorSubject(id);
	}

	get snapshotIdSubject(): BehaviorSubject<SnapshotId> {
		return this._snapshotId;
	}

	/**
	 * Hydrate from history storage
	 */
	hydrateFromHistoryStorage(): this {
		this.snapshotId = this._historySnapshotId;
		return this;
	}

	getPrimaryGovernment() {
		if (!this.governments) {
			return null;
		}

		const primeByFlag       = this.governments.find(x => x.prime);
		const primeByConvention = this.governments.length === 1 && this.governments[0];

		return primeByFlag || primeByConvention;
	}

	getNonPrimaryGovernments() {
		if (!this.governments || this.governments.length === 1) {
			return [];
		}

		return this.governments.filter(x => x.prime !== true);
	}

	export() {
		const tab = Object.assign({
			snapshotId: this.snapshotId
		}, this);
		delete tab._snapshotId;
		return tab;
	}

	/**
	 * Test to see if the current tab and User access scenario supports Live.
	 * @param hasGlobalAccessRole
	 */
	canUseLive(hasGlobalAccessRole): boolean {
		// set up tab states live is available on
		const isGovProfile = TabState.profile === this.state;
		const isTrackA = TabState.lgfrs === this.state && this.track.id === 'a';
		const isTrackD = TabState.lgfrs === this.state && this.track.id === 'd' && hasGlobalAccessRole;
		const isIndicator = TabState.indicator === this.state;
		const isIndicatorReport = TabState.lgfrs === this.state && this.track.id === 'e' && hasGlobalAccessRole;

		return isGovProfile || isTrackA || isTrackD || isIndicator || isIndicatorReport;
	}

	/**
	 * Test to see if the current tab is displaying OSPI dataset data.
	 * @param hasGlobalAccessRole
	 * @param hasAnySchoolAccess
	 * @param govTypes
	 */
	isDisplayingOSPIDataset(hasGlobalAccessRole: boolean, hasAnySchoolAccess: boolean, govTypes: Array<any>): boolean {

		const isReportOSPI = this.report?.financialsDatasetSource === 'OSPI';
		const isGovernmentProfileOSPI = TabState.profile === this.state && this.getPrimaryGovernment()?.financialsDatasetSource === 'OSPI';

		const govTypeOnTab = govTypes.find(govType => this.govTypes ? govType.code === this.govTypes[0] : null);
		const isGovernmentTypeProfileOSPI = TabState.typeProfile === this.state && govTypeOnTab?.financialsDatasetSource === 'OSPI';

		const isGovernmentIndicatorOSPI = TabState.indicator === this.state && this.getPrimaryGovernment()?.financialsDatasetSource === 'OSPI';

		const userHasOSPIAccess = hasGlobalAccessRole || hasAnySchoolAccess;
		const tabIsShowingOSPIData = isReportOSPI || isGovernmentProfileOSPI || isGovernmentTypeProfileOSPI || isGovernmentIndicatorOSPI;

		return userHasOSPIAccess && tabIsShowingOSPIData;
	}
}
