import {FilingBasisType} from '../../api/fit-api/models/snapshots/filing-basis';
import {IndicatorGroup} from '../../api/fit-api/models/indicators/indicator-group';

export type FinancialHealthIndicatorStatisticGroup = 'All Governmental Funds' | 'General Fund' | 'Enterprise Funds';

export class FinancialHealthIndicatorStatistics {
	good = 0;
	cautionary = 0;
	concerning = 0;
	indeterminate = 0;
	indicatorGroups = new Array<IndicatorGroup>();
	year: number;

	constructor(
		public group: FinancialHealthIndicatorStatisticGroup,
		public filingBasis: FilingBasisType
	) {
	}
}
