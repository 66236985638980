import {Injectable} from '@angular/core';
import {ReportMenuComponent} from 'app/components/report-menu/report-menu.component';
import {
	Category,
	Comparison,
	Composite,
	FinancialCondition,
	GovernmentType,
	Indicator,
	Individual, OSPIIndividual, REPORT_TYPE_EXPLORE_BY_MODEL_MAP,
	UserFilterField
} from 'app/components/report-menu/report-menu.models';
import {Tab} from 'app/shared/models/tab';
import {TabState} from 'app/shared/models/tab-state';
import {LoggerService} from './logger.service';
import {Years} from 'app/shared/models/years';
import {TransitionService} from './transition.service';
import {isEqual} from 'lodash';
import {TabService} from './tab.service';
import {PivotGridService} from './pivot-grid.service';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {FieldService} from './field.service';
import {UtilityService} from './utility.service';
import {IndicatorService} from './indicator.service';
import {first} from 'rxjs/operators';
import {UserInterfaceService} from './user-interface.service';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {REPORT_TYPES, ReportType} from '../models/report-type-menu-option';
import {EXPLORE_BY_OPTIONS, ExploreByOption, ExploreType} from '../models/explore-by-option';
import {DatasetSource} from '../../modules/api/fit-api/models/datasets/dataset-source';
import {Data} from '@angular/router';
import {AccountingCategory} from "../../modules/services/accounting-category/models/accounting-category";

/**
 * 1.  Relies on the following function naming conventions:
 * 1a. set{ModelProperty} - sets or resets the value (per above)
 * 1b. has{ModelProperty}Changed - checks to see if property is "dirty"
 *     This allows iteration over properties to check, or set/reset values.
 *
 * 2.  "Set" methods have two use cases:
 * 2a. Calling without a value "resets" the value to the original source (e.g., Tab).
 * 2b. Calling with value indicates a user interaction event. E.g. change years slider.
 *     Either sets the value on component.transients
 */

@Injectable({
	providedIn: 'root'
})
export class ReportMenuService {

	exploreToTrackMap = [
		{trackId: 'a', explore: 'individual'},
		{trackId: 'b1', explore: 'comparison'},
		{trackId: 'b2', explore: 'comparison'},
		{trackId: 'c', explore: 'governmentType'},
		{trackId: 'd', explore: 'category'},
		{trackId: 'e', explore: 'financialCondition'}
	];

	constructor(
		private logger: LoggerService,
		private transitionService: TransitionService,
		private tabService: TabService,
		private pivotGridService: PivotGridService,
		private fieldService: FieldService,
		private utility: UtilityService,
		private indicatorService: IndicatorService,
		private uiService: UserInterfaceService
	) {
	}

	getSetterFunctionForProperty = (property: string): Function => {
		const functionName = 'set' + property.charAt(0).toUpperCase() + property.slice(1);
		const functionRef = this[functionName];
		if (!functionRef) {
			throw Error(`${this.constructor.name}.getSetterFunctionForProperty: No setter found for ${property}.`);
		}
		return functionRef;
	};

	getChangedFunctionForProperty = (property: string): Function => {
		const functionName = 'has' + property.charAt(0).toUpperCase() + property.slice(1) + 'Changed';
		const functionRef = this[functionName];
		if (!functionRef) {
			throw Error(`${this.constructor.name}.getChangedFunctionForProperty: No change checking function found for ${property}.`);
		}
		return functionRef;
	};


	reset = (component: ReportMenuComponent): void => {
		// clear all transient properties
		component.transients = new Composite();
		// Report type sets the correct model that we'll need to get the ownProperties
		// which then sets the explore options. We'll exempt both from the looped calls.
		this.setReportType(component);
		const properties = Object.getOwnPropertyNames(component.model);
		for (let i = 0; i < properties.length; i++) {
			const property = properties[i];
			if (property !== 'reportType' && property !== 'explore') {
				// get and immediately run function with "valueless" overload to "reset"
				this.getSetterFunctionForProperty(property)(component);
			}
		}
		component.validateBaselineGovernment();
		component.validateCategoryFilter();
		component.validateExpenditureObjectsFilter();
		component.validateYears();
		component.updateDisabledGovTypes();
		this.setFinancialsDatasetSourceFilter(component);
		this.setGovTypeFilter(component);
		component.isDirty = false; // force clean
	};

	checkIsDirty = (component: ReportMenuComponent): boolean => {
		const properties = Object.getOwnPropertyNames(component.model);
		for (let i = 0; i < properties.length; i++) {
			const property = properties[i];
			if (this.getChangedFunctionForProperty(property)(component)) {
				return true;
			}
		}

		// passed all checks
		return false;
	};

	/**
	 * Sets the govType on any autocompletes to limit to "first" government type
	 */
	setGovTypeFilter(component: ReportMenuComponent) {
		const govs = this.getCombineGovernments(component);
		component.validations.govTypeFilter = govs.length ? govs[0].govTypeCode : null;
	}

	/**
	 * Sets the financials dataset filter.
	 */
	setFinancialsDatasetSourceFilter(component: ReportMenuComponent) {
		component.validations.financialsDatasetSource = component.transients.reportType
			? REPORT_TYPES
				.find(reportType => reportType.reportType === component.transients.reportType)
				.financialsDatasetSource
			: null;
	}

	/**
	 * Set component.currentModel so that HTML template knows which widgets to draw
	 */
	setReportType = (component: ReportMenuComponent, reportType?: ReportType): void => {
		if (reportType === undefined) {
			const state = component.tab.state;
			const datasetSource: DatasetSource = component.tab.report.financialsDatasetSource;
			if (!REPORT_TYPES.some(rt => rt.tabState === state && rt.financialsDatasetSource === datasetSource)) {
				throw Error(`${this.constructor.name}.setReportType: Invalid state & dataset source for ReportMenu: ${state} ${datasetSource}`);
			}
			reportType = REPORT_TYPES.find(rt => rt.tabState === state && rt.financialsDatasetSource === datasetSource).reportType;
		}
		component.transients.reportType = reportType;
		component.availableExploreByOptions = this.generateAvailableExploreByOptions(reportType);

		if (reportType === ReportType.indicator) {
			component.model = new Indicator();
			component.sources.availableYears = component.snapshot.detail.includedYears;
		}
		if (reportType === ReportType.lgfrs) {
			this.setExplore(component);
			// Manually set the funds filter to reflect the "default" when switching from Indicator to LGFRS
			this.setFunds(component, this.fieldService.defaultFunds);
			this.setHasFunds(component, true);
			component.sources.availableYears = component.snapshot.detail.includedYears;
		}
		if (reportType === ReportType.ospi) {
			this.setExplore(component);
			component.sources.availableYears = component.schoolsDataSetSource.detail.includedYears;
		}
		// also set the availableYears properly
		this.setAvailableYears(component);
	};

	hasReportTypeChanged = (component: ReportMenuComponent): boolean =>
		ReportType[component.transients.reportType] !== TabState[component.tab.state];

	generateAvailableExploreByOptions(reportType: ReportType): Array<Partial<ExploreByOption>> {
		return EXPLORE_BY_OPTIONS
			.filter(exploreByOption => this.reportType(exploreByOption, reportType));
	}

	/**
	 * Remove explore by options depending on the selected report type.
	 */
	private reportType = (exploreByOption: Partial<ExploreByOption>, reportType: ReportType): boolean => exploreByOption.supportedReportTypes.includes(reportType);

	setExplore = (component: ReportMenuComponent, exploreType?: ExploreType): void => {
		if (exploreType === undefined) {
			exploreType = this.getExploreTypeForTab(component.tab);
			component.previousModel = new REPORT_TYPE_EXPLORE_BY_MODEL_MAP[component.transients.reportType][exploreType];
		}

		component.model = new REPORT_TYPE_EXPLORE_BY_MODEL_MAP[component.transients.reportType][exploreType];
		component.transients.explore = exploreType;

		this.logger.log(`${this.constructor.name}: ExploreType set to ${exploreType}`, component);
	};

	private getExploreTypeForTab(tab: Tab): ExploreType {
		// default to Individual track (only scenario is coming from indicator)
		let trackId = tab.track?.id;
		if (!trackId) {
			trackId = tab.governments?.length > 1 ? 'b1' : 'a';
		}
		switch (trackId) {
			case 'a':
				return ExploreType.individual;
			case 'b1':
			case 'b2':
				return ExploreType.comparison;
			case 'c':
				return ExploreType.governmentType;
			case 'd':
				return ExploreType.category;
			case 'e':
				return ExploreType.financialCondition;
		}
	}

	hasExploreChanged = (component: ReportMenuComponent) => {
		const trackId = component.tab?.track?.id;
		const mapItem = this.exploreToTrackMap.find(x => x.trackId === trackId);
		return component.transients.explore !== mapItem?.explore;
	};

	setBaselineGovernment = (component: ReportMenuComponent, government?: any): void => {
		let result;
		if (government === undefined) {
			result = this.resolveBaselineFromTab(component.tab);
		} else {
			if (government) {
				government.prime = true;
			}
			result = government;
		}

		component.transients.baselineGovernment = result;
	};

	hasBaselineGovernmentChanged = (component: ReportMenuComponent): boolean => {
		const originalBaseline = this.resolveBaselineFromTab(component.tab);
		const originalMcag = originalBaseline && originalBaseline.mcag;
		const newMcag = component.transients.baselineGovernment && component.transients.baselineGovernment.mcag;
		return originalMcag !== newMcag;
	};

	private resolveBaselineFromTab(tab: Tab): any {
		// baseline is tab.governments.find(x => x.prime) for track b1
		if (tab.state === TabState.lgfrs && (!tab.track || !tab.track.id)) {
			throw Error(`${this.constructor.name}.resolveBaselineFromTab: No track is present on Tab with state 'lgfrs'. Cannot determine baseline.`);
		}
		// baseline for track a and indicators is only gov in array
		if (tab.state === TabState.indicator || tab.track.id === 'a') {
			return tab.governments[0];
		}
		// no baseline for tracks b2, c, d, e
		if (['b2', 'c', 'd', 'e'].includes(tab.track.id)) {
			return null;
		}
		if (tab.track.id === 'b1') {
			return tab.governments.find(x => x.prime);
		}

		throw Error(`${this.constructor.name}.resolveBaselineFromTab: Undefined behavior.`);
	}

	setIncludedGovernments = (component: ReportMenuComponent, governments?: Array<any>): void => {
		let result;
		if (governments === undefined) {
			result = this.resolveIncludedGovernmentsFromTab(component.tab);
		} else {
			if (Array.isArray(governments)) {
				// be sure to copy array so ngChanges is aware
				// also be sure array is made up of unique values
				result = governments.slice().filter((v, i, s) => s.indexOf(v) === i);
				result.forEach(x => delete x.prime); // remove any prime flags
			}
		}

		component.transients.includedGovernments = result;
	};

	hasIncludedGovernmentsChanged = (component: ReportMenuComponent): boolean => {
		let result;
		result = !this.utility.areArrayValuesEqual(
			this.resolveIncludedGovernmentsFromTab(component.tab)?.map(x => x.mcag),
			component.transients.includedGovernments?.map(x => x.mcag)
		);
		return result;
	};

	private resolveIncludedGovernmentsFromTab = (tab: Tab): Array<any> => {
		if (tab.state === TabState.lgfrs && (!tab.track || !tab.track.id)) {
			throw Error(`${this.constructor.name}.resolveIncludedGovernmentsFromTab: Tab has state 'lgfrs', but no track information.`);
		}
		// No included governments for track a, c, d, e
		if (tab.state === TabState.lgfrs && ['a', 'c', 'd', 'e'].includes(tab.track.id)) {
			return [];
		}
		// included governments for track b1 is !prime
		if (tab.state === TabState.indicator || (tab.state === TabState.lgfrs && tab.track.id === 'b1')) {
			return tab.getNonPrimaryGovernments();
		}
		// included governments for track b2 is all governments
		if (tab.state === TabState.lgfrs && tab.track.id === 'b2') {
			return tab.governments;
		}

		this.logger.error(`${this.constructor.name}.resolveIncludedGovernmentsFromTab: Undefined behavior.`);
	};

	private getKeysOrEmpty = (source: Array<any>, key: string): Array<any> =>
		Array.isArray(source) && source.length ? source.map(x => x[key]) : [];

	setGovTypes = (component: ReportMenuComponent, govTypes?: Array<string>): void => {
		// console.log(`setGovTypes tab govTypes right now are:`, component.tab?.govTypes);
		component.transients.govTypes = govTypes === undefined
			? this.getKeysOrEmpty(component.tab.govTypes, 'code')
			: govTypes;
	};

	hasGovTypesChanged = (component: ReportMenuComponent): boolean => {
		return component.transients.govTypes && (
			!isEqual(component.transients.govTypes, this.getKeysOrEmpty(component.tab.govTypes, 'code'))
		);
	};

	setLocations = (component: ReportMenuComponent, locations?: Array<number>): void => {
		component.transients.locations = locations === undefined
			? this.getKeysOrEmpty(component.tab.locations, 'countyCode')
			: locations;
	};

	hasLocationsChanged = (component: ReportMenuComponent): boolean => {
		return component.transients.locations && (
			!isEqual(component.transients.locations, this.getKeysOrEmpty(component.tab.locations, 'countyCode'))
		);
	};

	setReport = (component: ReportMenuComponent, reportId?: string): void => {
		component.transients.report = reportId === undefined
			? component.tab.report && component.tab.report.id
			: reportId;
	};

	hasReportChanged = (component: ReportMenuComponent): boolean => {
		return (component.tab.report && component.tab.report.id)
			!== component.transients.report;
	};

	// Most tracks do not change the query when a report changes, but Indicator Reports do
	hasIndicatorReportChanged = (component: ReportMenuComponent) => {
		return component.tab.track?.id === 'e'
			&& component.transients.report !== component.tab.report.id;
	}

	setFilingBasis = (component: ReportMenuComponent, filingBasis?: FilingBasis): void => {
		component.transients.filingBasis = filingBasis === undefined
			? component.tab.filingBasis
			: filingBasis;
	};

	hasFilingBasisChanged = (component: ReportMenuComponent): boolean => {
		return component.tab?.filingBasis?.id !== component.transients?.filingBasis?.id;
	};

	setIndicatorCode = (component: ReportMenuComponent, indicatorCode?: string): void => {
		component.transients.indicatorCode = indicatorCode === undefined
			? component.tab.indicator && component.tab.indicator.instanceCode
			: indicatorCode;
	};

	hasIndicatorCodeChanged = (component: ReportMenuComponent): boolean => {
		return (component.tab.indicator && component.tab.indicator.instanceCode)
			!== component.transients.indicatorCode;
	};

	setIndicatorNames = (component: ReportMenuComponent, indicatorNames: Array<string>): void => {
		let result;
		if (indicatorNames === undefined) {
			result = this.getIndicatorNamesPivotGrid(component.pivotGridData);
		} else {
			result = indicatorNames;
		}
		component.transients.indicatorNames = result;
	};

	private getIndicatorNamesPivotGrid = (pivotGridData: PivotGridDataSource) => {
		const field = pivotGridData.field('indicatorFilter');
		return field && field.filterValues;
	};

	hasIndicatorNamesChanged = (component: ReportMenuComponent): boolean => {
		const originalFilter = this.getIndicatorNamesPivotGrid(component.pivotGridData);
		const result = !isEqual(
			originalFilter,
			this.getKeysOrEmpty(component.transients.indicatorNames, 'id')
		);
		return result;
	};

	setYears = (component: ReportMenuComponent, value?: Years): void => {
		component.transients.years = value === undefined
			? component.tab.years
			: value;
	};

	hasYearsChanged = (component: ReportMenuComponent): boolean => {
		return component.transients.years && (
			component.tab.years[0] !== component.transients.years[0]
			|| component.tab.years[1] !== component.transients.years[1]
		);
	};

	setAvailableYears = (component: ReportMenuComponent, value?: Array<number>): void => {
		// do not allow indicators below 2011
		let available = component.sources.availableYears;
		if (component.transients.reportType === ReportType.indicator) {
			available = available.filter(x => x >= 2011);
		}

		component.transients.availableYears = value === undefined
			? available
			: value;
	};

	hasAvailableYearsChanged = (component: ReportMenuComponent): boolean => {
		return false;
	};

	setHasFunds = (component: ReportMenuComponent, value?: boolean): void => {
		if (value === undefined) {
			// nothing to do because setCategories calls this function explicitly
		} else {
			component.transients.hasFunds = value;
		}
	};

	hasHasFundsChanged = (component: ReportMenuComponent) => {
		// nothing to do, handled by hasFundsChanged
		return false;
	};

	setFunds = (component: ReportMenuComponent, funds?: Array<Array<number>>): void => {
		let result;
		if (funds === undefined) {
			result = this.getFundsFromPivotGrid(component.pivotGridData);
			if (result && result.length) {
				this.setHasFunds(component, true);
			}
		} else {
			result = funds;
		}

		component.transients.funds = result;
	};

	private getFundsFromPivotGrid = (pivotGridData: PivotGridDataSource) => {
		const field = pivotGridData.field('fundGroupFilter');
		return field && field.filterValues;
	};

	hasFundsChanged = (component: ReportMenuComponent): boolean => {
		const originalFilter = this.getFundsFromPivotGrid(component.pivotGridData);
		const result = this.hasFilterChanged(
			originalFilter,
			component.transients.funds,
			component.transients.hasFunds
		);
		return result;
	};

	setCategory(component: ReportMenuComponent, category?: AccountingCategory): void {
		let result;
		if (category === undefined) {
			result = component.tab.category;
		} else {
			result = category;
		}
		component.transients.category = result;
	}

	hasCategoryChanged(component: ReportMenuComponent): boolean {
		return component.transients.category && (
			component.transients.category !== component.tab.category
		);
	}

	// Only for BARS accounts, does not adhere to AccountCategory interface
	setCategories = (component: ReportMenuComponent, categories?: Array<Array<number>>): void => {
		let result;
		if (categories === undefined) {
			result = this.getCategoriesFilterFromPivotGrid(component.pivotGridData);
			if (result && result.length) {
				this.setHasCategories(component, true);
			}
		} else {
			result = categories;
		}
		component.transients.categories = result;
	};

	// Only for BARS accounts, does not adhere to AccountCategory interface
	private getCategoriesFilterFromPivotGrid = (pivotGrid: PivotGridDataSource) => {
		let accountField = this.fieldService.getActiveAlternateField(pivotGrid, 'financialSummaryHierarchy', 'account');
		const isShowDebtCapital = accountField && accountField.dataField.indexOf('debtCapitalExp') > -1;

		const queryField = isShowDebtCapital
			? 'DebtCapitalExpFunctionalAccountsGroup'
			: 'functionalAccountsGroup';

		accountField = this.fieldService.getFieldById(pivotGrid, queryField);
		return accountField && accountField.filterValues;
	};

	// Only for BARS accounts, does not adhere to AccountCategory interface
	hasCategoriesChanged = (component: ReportMenuComponent): boolean => {
		const originalFilter = this.getCategoriesFilterFromPivotGrid(component.pivotGridData);
		const result = this.hasFilterChanged(
			originalFilter,
			component.transients.categories,
			component.transients.hasCategories
		);
		return result;
	};

	// Only for BARS accounts, does not adhere to AccountCategory interface
	setHasCategories = (component: ReportMenuComponent, value?): void => {
		if (value === undefined) {
			// nothing to do because setCategories calls this function explicitly
		} else {
			component.transients.hasCategories = value;
		}
	};

	hasHasCategoriesChanged = (component: ReportMenuComponent): boolean => {
		// nothing to do, hasCategoriesChanged handles this
		return false;
	};

	setExpenditureObjects = (component: ReportMenuComponent, objectIds?: Array<string>): void => {
		let result;
		if (objectIds === undefined) {
			result = this.getExpenditureObjectsFilterFromPivotGrid(component.pivotGridData);
			if (result && result.length) {
				this.setHasExpenditureObjects(component, true);
			}
		} else {
			result = objectIds;
		}
		component.transients.expenditureObjects = result;
	};

	hasExpenditureObjectsChanged = (component: ReportMenuComponent): boolean => {
		const result = this.hasFilterChanged(
			this.getExpenditureObjectsFilterFromPivotGrid(component.pivotGridData),
			component.transients.expenditureObjects,
			component.transients.hasExpenditureObjects
		);
		return result;
	};

	private hasFilterChanged = (previousFilter: Array<any>, newFilter: Array<any>, switchValue: boolean): boolean => {
		let result;
		if (previousFilter && previousFilter.length > 0) {
			// initially had filters
			// has changed if switch turned off, or filter is not equal
			result = !switchValue
				|| !this.utility.areArrayValuesEqual(previousFilter, newFilter);
		} else {
			// did not initially have filters
			// has changed if switch turned on and filterValues
			result = switchValue
				&& newFilter
				&& newFilter.length > 0;
		}
		return result;
	};

	private getExpenditureObjectsFilterFromPivotGrid = (pivotGridData: PivotGridDataSource) => {
		const field = pivotGridData.field('expenditureObjectFilter');
		return field && field.filterValues;
	};

	setHasExpenditureObjects = (component: ReportMenuComponent, value?: boolean): void => {
		if (value === undefined) {
			// nothing to do because setExpenditureObjects calls this function explicitly
		} else {
			component.transients.hasExpenditureObjects = value;
		}
	};

	hasHasExpenditureObjectsChanged = (component: ReportMenuComponent): boolean => {
		// nothing to do, hasExpenditureObjectsChanged handles this
		return false;
	};

	setGuideline = (component: ReportMenuComponent, value: boolean): void => {
		this.logger.warn('Not implemented');
	};

	hasGuidelineChanged = (component: ReportMenuComponent): boolean => {
		this.logger.warn('Not implemented');
		return false;
	};

	async applyChangesToLGFRSReport(component: ReportMenuComponent) {
		// get transient properties that match current model
		// const modelProperties = Object.getOwnPropertyNames(component.model);
		// let values = modelProperties.reduce((accumulator, propertyName, index) => {
		// 	accumulator[propertyName] = component.transients[propertyName];
		// 	return accumulator;
		// }, {});

		let newTab = new Tab('New Tab', TabState.lgfrs, component.tab.snapshotId);
		const userFilters = this.getUserFilters(component);

		const trackId = this.resolveTrackIdFromExploreType(component);
		const reportId = component.transients.report;
		switch (component.model.constructor) {
			case Individual:
			case OSPIIndividual:
			case Comparison:
				this.logger.log('Individual, OSPIIndividual, or Comparison');
				newTab = await this.tabService.buildGovernmentBasedTab(newTab, {
					trackId,
					governments: this.getCombineGovernments(component),
					years: component.transients.years
				});
				newTab.report = this.tabService.availableReports.find(x => x.id === component.transients.report);

				// Check to see if any projection currently applied should be preserved on tab
				const supportedReports = this.tabService.availableReports.filter(x => x.supportsProjections).map(x => x.id);
				if (component.model.constructor === Individual && supportedReports.includes(newTab?.report?.id)) {
					const sameGovernment = component.tab?.governments[0]?.mcag === newTab.governments[0]?.mcag;
					const includesLatestYear = component.transients.years[1] === Math.max(...component.sources.availableYears);
					if (sameGovernment && includesLatestYear) {
						newTab.pivotGridSettings.projectionId = component.tab?.pivotGridSettings?.projectionId;
						newTab.pivotGridSettings.projectionName = component.tab?.pivotGridSettings?.projectionName;
					}
				}

				break;
			case GovernmentType:
				this.logger.log('GovernmentType');
				newTab = await this.tabService.buildSummaryBasedTab(newTab, {
					reportId,
					trackId: trackId,
					years: component.transients.years,
					govTypes: this.getGovTypeObjects(component.transients.govTypes, component.snapshot),
					locations: this.getLocationObjects(component.transients.locations, component.snapshot)
				});
				break;
			case Category:
				this.logger.log('Category');
				newTab = await this.tabService.buildRankingBasedTab(newTab, {
					trackId: trackId,
					years: component.transients.years,
					category: component.transients.category,
					govTypes: this.getGovTypeObjects(component.transients.govTypes, component.snapshot),
					locations: this.getLocationObjects(component.transients.locations, component.snapshot)
				});
				break;
			case FinancialCondition:
				this.logger.log('FinancialCondition');
				newTab = await this.tabService.buildIndicatorReportTab(newTab, {
					trackId: trackId,
					reportId: reportId,
					filingBasis: component.transients.filingBasis,
					years: component.transients.years,
					category: component.transients.category,
					govTypes: this.getGovTypeObjects(component.transients.govTypes, component.snapshot)
				});
				break;
		}

		// Data calls could be required
		this.resolvePivotGridSettings(component.tab, newTab).then(() => {
			if (component.isOpenInNewTab) {
				this.applyChangesToLGFRSReportNewTab(component, newTab, userFilters);
			} else {
				this.applyChangesToLGFRSReportInline(component, newTab, userFilters);
			}

			this.logger.log(newTab);
		}).catch(e => this.logger.error('ReportMenuService.resolvePivotGridSettings failed', e));
	}

	// Make sure we load government metrics before evaluating
	private resolvePivotGridSettings = (fromTab: Tab, toTab: Tab) => {
		this.uiService.requestApplicationFocus(this);
		return this.tabService.loadGovernmentMetricsForTab(toTab).then(() => {
			toTab.pivotGridSettings = this.transitionService.getApplicablePivotGridSettings(fromTab, toTab);
			this.uiService.releaseApplicationFocus();
		});
	};

	private getGovTypeObjects = (govTypes: Array<string>, snapshot: any) =>
		govTypes && snapshot.detail.governmentTypes.filter(x => govTypes.includes(x.code));

	private getLocationObjects = (locations: Array<number>, snapshot: any) =>
		locations && snapshot.detail.counties.filter(x => locations.includes(x.countyCode));

	private applyChangesToLGFRSReportInline(component: ReportMenuComponent, newTab: Tab, userFilters: Array<any>) {
		// Whether the base URL has to be regenerated (Schedule1s vs Schedule9s) vs just changing the filter on the PivotGrid
		const hasURLChanges = this.hasExploreChanged(component)
			// This seems like it would only affect the pivot grid filter?
			|| ((ExploreType.category || ExploreType.governmentType) && (this.hasGovTypesChanged(component) || this.hasLocationsChanged(component)))
			// Also need to check any Track A/Individual report change summary/rev/exp <=> debt & liabilites <=> operating results analysis
			// But we do not have previous state, so just reload for any report change in this scenario
			|| (ExploreType.individual && this.hasReportChanged(component));


		// test if track e and going from one report to the next
		const indicatorReportChanged = component.transients.explore === ExploreType.financialCondition
			&& component.transients.report !== component.tab.report.id;
		// this is PGDS filter-based
		const hasQueryDefiningChanges = this.hasBaselineGovernmentChanged(component)
			|| this.hasIncludedGovernmentsChanged(component)
			|| this.hasYearsChanged(component)
			|| this.hasGovTypesChanged(component)
			|| this.hasLocationsChanged(component)
			|| this.hasFilingBasisChanged(component) // only present on Track E
			|| this.hasCategoryChanged(component)
			|| (component.transients.explore === ExploreType.financialCondition && this.hasReportChanged(component))
			|| indicatorReportChanged;

		const hasReportChanged = this.hasReportChanged(component);

		const removingProjection = component.tab.pivotGridSettings?.projectionId && !newTab.pivotGridSettings?.projectionId;

		// hack in the report for track c and d
		// otherwise return transients.report
		const reportId = ['c', 'd'].includes(newTab.track.id)
			? newTab.report.id
			: component.transients.report;

		// Clear out any old pivot grid settings before proceeding to prevent user settings from overwriting
		// with invalid values for new track/report
		const settings = this.pivotGridService.getSettings(newTab, component.user);
		delete newTab.pivotGridSettings;
		newTab.pivotGridSettings = settings;

		if (hasURLChanges) {
			this.logger.log('has url changes for data source', component);
			const queryFilter = this.transitionService.getPivotGridFilterExpression(newTab);
			component.pivotGridData.filter(queryFilter);
			this.applyUserFiltersToPivotGrid(userFilters, component.pivotGridData);
			// do not set on current pivot grid since it is about to be rebuilt, pass it into the fields list
			// component.pivotGridData.fields(this.transitionService.transitionFields(component.pivotGridData.fields(), component.tab, newTab.track.id, reportId, true));

			// Copy over pivotGridSettings for new tab since this is used in reports. This may end up resetting some
			// settings that previously were preserved.
			// http://saoapolytfsweb:8080/tfs/SAOCollection/BonanzaUI/_workitems/edit/13484
			component.tab.pivotGridSettings = newTab.pivotGridSettings;
			const fields = this.transitionService.transitionFields(component.pivotGridData.fields(), component.tab, newTab.track.id, reportId, true);
			Object.assign(component.tab, newTab);
			this.pivotGridService.rebuildDataSource(component.tab, fields);
		}
		// component.transients.report does not work for track c or d because they do not show a report dropdown
		else if (hasQueryDefiningChanges && hasReportChanged) {
			this.logger.log('has query-defining and report changes');
			Object.assign(component.tab, newTab);
			const queryFilter = this.transitionService.getPivotGridFilterExpression(newTab);
			component.pivotGridData.filter(queryFilter);
			this.applyUserFiltersToPivotGrid(userFilters, component.pivotGridData);
			const fields = this.transitionService.transitionFields(component.pivotGridData.fields(), component.tab, newTab.track.id, reportId, true);
			if (removingProjection) {
				this.pivotGridService.rebuildDataSource(component.tab, fields);
			} else {
				component.pivotGridData.fields(fields);
				component.pivotGridData.reload();
			}
		} else if (!hasQueryDefiningChanges && hasReportChanged) {
			this.logger.log('just has report changes');
			newTab.governments = component.tab.governments; // make sure we preserve the metrics
			Object.assign(component.tab, newTab);
			this.applyUserFiltersToPivotGrid(userFilters, component.pivotGridData);
			const fields = this.transitionService.transitionFields(component.pivotGridData.fields(), component.tab, newTab.track.id, reportId, true);
			if (removingProjection) {
				this.pivotGridService.rebuildDataSource(component.tab, fields);
			} else {
				component.pivotGridData.fields(fields);
				component.pivotGridData.load();
			}
		} else if (hasQueryDefiningChanges && !hasReportChanged) {
			this.logger.log('has query-defining changes, but no report changes');
			Object.assign(component.tab, newTab);
			// change query filter only
			const queryFilter = this.transitionService.getPivotGridFilterExpression(newTab);
			component.pivotGridData.filter(queryFilter);
			this.applyUserFiltersToPivotGrid(userFilters, component.pivotGridData);
			const fields = this.transitionService.transitionFields(component.pivotGridData.fields(), component.tab, newTab.track.id, reportId, true);
			if (removingProjection) {
				this.pivotGridService.rebuildDataSource(component.tab, fields);
			} else {
				component.pivotGridData.fields(fields);
				this.applyDataSourceDependentSettings(component);
				component.pivotGridData.reload();
			}
		} else {
			this.logger.log('has no query-defining or report changes');
			newTab.governments = component.tab.governments; // make sure we preserve the metrics
			Object.assign(component.tab, newTab);
			// apply filters
			this.applyUserFiltersToPivotGrid(userFilters, component.pivotGridData);
			const fields = this.transitionService.transitionFields(component.pivotGridData.fields(), component.tab, newTab.track.id, reportId, true);
			if (removingProjection) {
				this.pivotGridService.rebuildDataSource(component.tab, fields);
			} else {
				component.pivotGridData.fields(fields);
				this.applyDataSourceDependentSettings(component);
				component.pivotGridData.load();
			}
		}
	}

	// When not removing a projection, set filing field and population fields (dependent on tab and field config)
	private applyDataSourceDependentSettings(component: ReportMenuComponent) {
		component.tab.pivotGridSettings.filingField = this.fieldService.getFieldNameForUniqueFilingDisplay(component.pivotGridData, component.tab);
		component.tab.pivotGridSettings.populationField = this.fieldService.getFieldNameForPopulationDisplay(component.pivotGridData, component.tab);
	}

	private applyChangesToLGFRSReportNewTab(component: ReportMenuComponent, newTab: Tab, userFilters: Array<any>) {
		newTab.initialFields = userFilters;
		this.tabService.add(newTab);
	}

	private resolveTrackIdFromExploreType(component: ReportMenuComponent): string {
		if (component.transients.explore === ExploreType.category) {
			return 'd';
		} else if (component.transients.explore === ExploreType.governmentType) {
			return 'c';
		} else if (component.transients.explore === ExploreType.financialCondition) {
			return 'e';
		}

		const hasBaseline = component.transients.baselineGovernment;
		const hasIncludedGovs = component.transients.includedGovernments && component.transients.includedGovernments.length;

		if (hasBaseline && !hasIncludedGovs) {
			return 'a';
		} else if (hasBaseline && hasIncludedGovs) {
			return 'b1';
		} else if (!hasBaseline && hasIncludedGovs) {
			return 'b2';
		} else {
			throw Error(`${this.constructor.name}.resolveTrackFromIndividualExploreType: Unhandled scenario.`);
		}
	}

	/**
	 * Combines all governments. Prime flags should be set before getting here.
	 */
	getCombineGovernments(component: ReportMenuComponent): Array<any> {
		let governments = [];
		if (component.model.hasOwnProperty('baselineGovernment') && component.transients.baselineGovernment) {
			// Ensure prime flag gets set before pushing onto tab
			const baseline = Object.assign({}, component.transients.baselineGovernment);
			baseline.prime = true;
			governments.push(baseline);
		}

		if (component.model.hasOwnProperty('includedGovernments') && Array.isArray(component.transients.includedGovernments)) {
			// ensure prime flag is not set for included governments
			governments = governments.concat(component.transients.includedGovernments.map(x => {
				delete x.prime;
				return x;
			}));
		}

		return governments;
	}

	applyChangesToIndicator(component: ReportMenuComponent) {
		const governments = [];
		const baselineGovernment = component.transients.baselineGovernment;
		baselineGovernment.prime = true;
		if (baselineGovernment) {
			governments.push(baselineGovernment);
		}
		if (component.transients.includedGovernments?.length > 0) {
			governments.push(...component.transients.includedGovernments);
		}
		const snapshotId = component.tab.snapshotId;
		const newTab = new Tab('New Tab', TabState.indicator, component.tab.snapshotId);

		component.isApplyingChanges = true;
		this.indicatorService.getAnnualFilingIndicatorGroups(
			component.transients.baselineGovernment.mcag,
			snapshotId,
			component.transients.years[0],
			component.transients.years[1]
		).pipe(first()).subscribe(result => {
			this.logger.log('Indicator Groups', result);
			// flatten
			result = result.reduce((arr, val) => arr.concat(val.indicators), []);
			const indicator = result.find(x => x.instanceCode === component.transients.indicatorCode);
			this.tabService.buildIndicatorBasedTab(newTab, {
				indicator,
				years: component.transients.years,
				governments: governments
			}).then(newTab => {
				newTab.title = `${baselineGovernment.entityNameWithDba}: ${indicator.title}`;
				if (component.isOpenInNewTab) {
					this.tabService.add(newTab);
				} else {
					newTab.id = component.tab.id;
					this.tabService.save(newTab);
				}
				component.isApplyingChanges = false;
			});
		});
	}

	/**
	 * Generate filter values based on existing component state
	 */
	private getUserFilters(component: ReportMenuComponent): Array<UserFilterField> {
		const result = [];

		// for each filter value, we need to test to see if the related 'has' property is enabled
		// and send a null filter if not
		const fundsFilterValue = component.transients.hasFunds
			? component.transients.funds
			: [];
		result.push({id: 'fundGroupFilter', name: 'fundGroupFilter', filterValues: fundsFilterValue});

		if (component.validations.hasCategoriesFilter) {
			// determine whether we are standard or debtCapital, default to standard
			const filterFieldId = component.tab?.pivotGridSettings?.financialSummaryHierarchy === 'debtCapitalExp'
				? 'debtCapitalExpFunctionalAccountsGroup'
				: 'functionalAccountsGroup';
			const categoriesFilterValue = component.transients.hasCategories
				? component.transients.categories
				: null;
			result.push({id: filterFieldId, name: filterFieldId, filterValues: categoriesFilterValue});
		}

		if (component.validations.hasExpenditureObjectsFilter) {
			const exObjFilterValue = component.transients.hasExpenditureObjects
				? component.transients.expenditureObjects
				: null;
			result.push({
				id: 'expenditureObjectFilter',
				name: 'expenditureObjectFilter',
				filterValues: exObjFilterValue
			});
		}

		if (component.transients.explore === ExploreType.financialCondition) {
			result.push({
				id: 'indicatorFilter',
				name: 'indicatorFilter',
				filterValues: component.transients.indicatorNames
			});
		}

		return result;
	}

	private applyUserFiltersToPivotGrid(fields: Array<UserFilterField>, pivotGridData: PivotGridDataSource) {
		pivotGridData.fields(fields);
	}

}
