import {Injectable, OnDestroy} from '@angular/core';
import {UserService} from 'app/shared/services/user.service';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {Subscription} from 'rxjs';
import {TabService} from 'app/shared/services/tab.service';
import {BehaviorSubject} from 'rxjs';
import {FilterBuilderService} from 'app/shared/services/filter-builder.service';
import DataSource from 'devextreme/data/data_source';
import {DataSourceService} from '../../shared/services/data-source.service';
import {User} from 'app/shared/models/user';
import {TabState} from 'app/shared/models/tab-state';
import {LoggerService} from 'app/shared/services/logger.service';
import {PivotGridService} from 'app/shared/services/pivot-grid.service';
import {HistoryService} from '../../shared/services/history.service';
import {Tab} from 'app/shared/models/tab';
import {TrackESubmit} from './track-e/track-e.component';
import {AccountingCategory} from '../../modules/services/accounting-category/models/accounting-category';

@Injectable({
	providedIn: 'root'
})
export class WizardService implements OnDestroy {
	tracks = [
		{
			id: 'initial', steps: [ // beginner, track selection
				{id: 1, component: 'trackChooser', title: `How do you want to explore the data?`}
			]
		},
		{
			id: 'a', name: 'By Individual Government', wayfinder: `You're currently exploring data for an individual local government.`, steps: [
				{id: 2, component: 'a3', title: 'Choose a local government.'}
			]
		}, // governments, report, year
		{
			id: 'b1', wayfinder: `You're currently exploring data for an individual local government.`, steps: [
				{id: 3, component: 'b4', isLastStep: true, title: 'Choose other governments to compare with.'}
			]
		}, // governments, report, year
		{
			id: 'c', wayfinder: `You\'re currently exploring data for groups of governments.`, steps: [
				{
					id:         2,
					component:  'c3',
					isLastStep: true,
					title:      `Choose government type(s) and/or government locations (or leave blank for all governments).`
				}
			]
		}, // groups of governments
		{
			id: 'd', wayfinder: `You\'re currently exploring data for a government function or revenue source.`, steps: [
				{id: 2, component: 'd3', title: `Choose a government function or revenue source`},
				{id: 3, component: 'd4', isLastStep: true, title: `Choose government type(s) and/or government locations`}
			]
		}, // BARS functional group, government category(ies)
		{
			id: 'e', wayfinder: null, steps: [
				{ id: 2, component: 'e3', title: null },
			]
		}
	];

	trackId: string;
	reportId: string;
	steps: Array<any> = this.tracks[0].steps;
	step: any         = this.steps[0];
	governments       = [];
	govTypes          = [];
	locations         = [];
	category: AccountingCategory;
	years: [number, number];
	metricYear: number;

	snapshotSubscription: Subscription;
	snapshot;
	userSubscription: Subscription;
	user: User;
	tab: Tab;
	showAccountCodes = false;
	userHasGlobalAccess = false;

	// Observable to relay state of wizard
	isLoading$ = new BehaviorSubject<boolean>(false);

	set isLoading(value: boolean) {
		this.isLoading$.next(value);
	}

	constructor(
		private userService: UserService,
		private snapshotService: SnapshotService,
		private tabService: TabService,
		private filterBuilder: FilterBuilderService,
		private dataSource: DataSourceService,
		private logger: LoggerService,
		private pivotGridService: PivotGridService,
		private historyService: HistoryService,
	) {
		this.isLoading = true;
		this.tabService.tabs.subscribe(
			(tabs) => {
				const currentTab = tabs.find((tab) => tab.selected);
				this.tab         = currentTab;
				this.setShowAccountCodes();
				if (currentTab) {
					currentTab.snapshotIdSubject.subscribe(
						(snapshotId) => {
							this.snapshotSubscription = this.snapshotService.getSnapshot(snapshotId)
								.subscribe(
									response => {
										if (response) { // do not use null/initial value
											this.snapshot  = response;
											this.isLoading = false;
											this.logger.log('wizard', response);
										}
									},
									error => this.snapshotSubscriptionError(error)
								);
						}
					);
				}
			}
		);

		this.userSubscription = this.userService.user.subscribe(user => {
			this.user = user;
			this.setShowAccountCodes();
			this.userHasGlobalAccess = this.user.hasGlobalAccess();
		});
	}

	setShowAccountCodes = () => {
		const isTabShowingAccountCodes = this.tab && this.tab.pivotGridSettings && this.tab.pivotGridSettings.showAccountCodes === true;
		const isUserTechnical          = this.user && this.user.showTechnical === true;
		this.showAccountCodes          = isTabShowingAccountCodes === true || isUserTechnical === true;
	}

	ngOnDestroy() {
		this.snapshotSubscription.unsubscribe();
		this.userSubscription.unsubscribe();
	}

	snapshotSubscriptionError(error) {
		this.isLoading = false;
		// TODO present error to user
	}

	reset() {
		this.governments = [];
		this.govTypes    = [];
		this.locations   = [];
		this.category    = null;
		this.years       = null;
		this.reportId    = 'summary';
	}

	changeTrack(toTrackId: string, saveSelections?: boolean): void {
		// If navigating to a new track, reset selections
		if (toTrackId !== this.trackId && !saveSelections) {
			this.reset();
		}

		const toTrack = this.tracks.find(v => v.id === toTrackId);

		if (!toTrack) {
			throw new Error(`Wizard.changeTrack: No track with id of "${toTrackId}"`);
		}

		this.trackId = toTrackId;
		this.addStepsForTrack(toTrack);
		this.handleForward();
	}

	addStepsForTrack(track) {
		// Keep only steps with ids that precede the track we're switching to
		const toTrackStepIds       = track.steps.map(v => v.id);
		// todo refactor to Math.min(...toTrackStepIds)
		const minStepIdForNewTrack = Math.min.apply(Math, toTrackStepIds);
		// todo this Math.min call looks redundant?
		this.steps                 = this.steps.filter(currentStep => currentStep.id < Math.min(minStepIdForNewTrack));

		// Add toTrack's steps to the end of the array
		this.steps = this.steps.concat(track.steps);

		this.logger.info('Wizard steps: ', this.steps);
	}

	jumpToStep(stepId: number) {
		const step = this.steps.find(v => v.id === stepId);

		if (!step) {
			throw new Error(`Wizard.jumpForwardTo: No step id ${stepId} found in steps array.`);
		}

		this.step = step;
	}

	handleForward() {
		// Special case for track D. Do not move to step 4 until category is selected.
		if (this.trackId === 'd' && this.step.id === 3 && !this.category) {
			return;
		}

		// TODO refactor
		this.step.isVisitable = true;
		const currentIndex    = this.steps.indexOf(this.step);
		const nextStep        = this.steps[currentIndex + 1];
		if (!nextStep) {
			return;
		}

		this.step             = nextStep;
		this.step.isVisitable = true;
	}

	canVisitStep(stepId: number): boolean {
		const step = this.steps.find(v => v.id === stepId);
		return step && step.isVisitable;
	}

	getGovernment() {
		return this.governments[0];
	}

	setGovernment(data) {
		this.governments = [data.government];
		const years      = this.getDefaultYears();
		this.tabService.buildGovernmentBasedTab(this.tabService.getSelectedTab(), {
			trackId:     this.trackId,
			governments: this.governments,
			years
		}).then(tab => tab.pivotGridSettings = this.pivotGridService.getSettings(tab, this.user));
	}

	getPrimaryGovernment = () => this.governments.find(v => v.prime);

	setPrimaryGovernment(data, newTrack) {
		data.government.prime = true;
		const exists          = this.governments.find(v => v.mcag === data.mcag);
		const currentPrime    = this.getPrimaryGovernment();

		if (exists) { // MCAG exists, but may not be prime
			this.governments[this.governments.indexOf(exists)] = data.government;
		}
		else if (currentPrime) { // already has a prime, but may not be this MCAG
			this.governments[this.governments.indexOf(currentPrime)] = data.government;
		}
		else { // no prime and MCAG does not exist
			this.governments.push(data.government);
		}

		if (data.metricYear) {
			this.metricYear = data.metricYear;
		}

		this.changeTrack(newTrack, true);
	}

	// track b1/b2
	setGovernments(data) {
		const primaryGovernment = this.getPrimaryGovernment();
		if (primaryGovernment) {
			data.push(primaryGovernment);
			this.trackId = 'b1';
		}
		this.governments = data;

		const years = this.getDefaultYears();
		this.tabService.buildGovernmentBasedTab(this.tabService.getSelectedTab(), {
			trackId:     this.trackId,
			governments: this.governments,
			years
		}).then(tab => {
			tab.pivotGridSettings = this.pivotGridService.getSettings(tab, this.user);
			this.pivotGridService.rebuildDataSource(tab);
		});
	}

	// Triggers track C build
	setGovernmentTypes(data) {
		this.historyService.replaceStateTabs();
		this.updateGovTypes(data.govTypeCodes);
		this.updateLocations(data.locationCodes);

		const years = this.getDefaultYears();
		this.tabService.buildSummaryBasedTab(this.tabService.getSelectedTab(), {
			trackId:   this.trackId,
			years,
			govTypes:  this.govTypes,
			locations: this.locations
		}).then(tab => {
			tab.pivotGridSettings = this.pivotGridService.getSettings(tab, this.user);
			this.pivotGridService.rebuildDataSource(tab);
			this.historyService.pushStateTabs();
		});
	}

	updateGovTypes(govTypes: Array<string | any>) {
		// accept a list of codes or objects
		const resolveKey = item => typeof item === 'object' ? item.code : item;
		this.govTypes    = this.snapshot.detail.governmentTypes.reduce((acc, item) => {
			if (govTypes && govTypes.some(v => resolveKey(v) === item.code)) {
				const itemCopy = Object.assign({}, item);
				delete itemCopy.count;
				acc.push(itemCopy);
			}
			return acc;
		}, []);
	}

	updateLocations(locations: Array<string | any>) {
		const resolveKey = item => typeof item === 'object' ? item.countyCode : item;
		this.locations   = this.snapshot.detail.counties.filter(county =>
			locations && locations.some(v => resolveKey(v) === county.countyCode)
		);
	}

	trackD() {
		if (!this.category) {
			return;
		}

		this.historyService.replaceStateTabs();
		this.tabService.setSelectedTabState(TabState.lgfrs);
		const years = this.getDefaultYears();
		this.tabService.buildRankingBasedTab(this.tabService.getSelectedTab(), {
			trackId:   this.trackId,
			years,
			category:  this.category,
			govTypes:  this.govTypes,
			locations: this.locations
		}, true).then(tab => {
			tab.pivotGridSettings = this.pivotGridService.getSettings(tab, this.user);
			this.pivotGridService.rebuildDataSource(tab);
			this.isLoading = false;

			this.historyService.pushStateTabs();
		});
	}

	trackE(event: TrackESubmit) {
		this.historyService.replaceStateTabs();
		this.updateGovTypes(event.govTypeCodes);
		this.tabService.buildIndicatorReportTab(this.tabService.getSelectedTab(), {
			years: this.getDefaultYears(),
			trackId: 'e',
			govTypes: this.govTypes,
			filingBasis: event.filingBasis,
			reportId: event.indicatorReportType.key
		}, true).then(tab => {
			tab.pivotGridSettings = this.pivotGridService.getSettings(tab, this.user);
			this.pivotGridService.rebuildDataSource(tab);
			this.isLoading = false;

			this.historyService.pushStateTabs();
		});
	}

	updateCategory(data: AccountingCategory) {
		this.category = data;
	}

	// Default year logic when a range is not specified
	getDefaultYears(): [number, number] {
		const max = Math.max.apply(null, this.snapshot.detail.includedYears);
		if (['a', 'c', 'd'].some(v => this.trackId === v)) {
			return [max - 3, max];
		}
		if (this.trackId === 'e') {
			return [max - 2, max];
		}
		else {
			return [max, null];
		}
	}
}
