<dx-popup
	width="50%"
	title="No advanced features available"
	height="auto"
	dragEnabled=false
	showCloseButton=false
	[(visible)]="isVisible"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__inner">
			<p>Your SSO role does not have access to advanced features of FIT, including live filing data and cross-device sessions. If you believe this to be an error, please contact your audit liaison.</p>
		</div>
		<div class="app-popup__footer">
			<div class="do-not-show">
				<dx-check-box
					[(value)]="doNotShow"
					text="Do not show this dialog in the future"
				></dx-check-box>
			</div>
			<dx-button
				text="OK"
				type="default"
				(onClick)="closeDialog()"
			></dx-button>
		</div>
	</div>
</dx-popup>
