<dx-popup
	title="Publish LGCS Snapshot"
	width="1000"
	[(visible)]="isVisible"
	(onHidden)="reset()"
>
	<div *dxTemplate="let data of 'content'">
		<div class="app-popup__wrapper" [ngSwitch]="state">
			<!-- State: user inputs -->
			<div class="app-popup__inner" *ngSwitchCase="'inputs'">
				<p>
					This will publish a snapshot of data for all years’ filings with a status of "completed" or "published". Published data is then available through the FIT application. Published data will use the BARS account hierarchy of the year specified below. Additionally, the filing status for all "completed" filings will be changed to "published".
				</p>

				<p>
					This process may take up to 10-15 minutes to complete.
				</p>

				<p>
					First enter a publishing year. The publish process will use the specified year's BARS accounts when publishing the data.
				</p>

				<p>
					Only the year specified and prior 10 years of data will be published in the snapshot.
				</p>

				<label class="input-label">Publish BARS Year</label>

				<dx-select-box
					[items]="filingStatuses"
					displayExpr="label"
					valueExpr="year"
					[(value)]="formFields.barsYear"
					(onValueChanged)="updateFilingStatusSelection()"
				>
					<dx-validator>
						<dxi-validation-rule type="required" message="Please choose a snapshot"></dxi-validation-rule>
					</dx-validator>
				</dx-select-box>

				<label class="input-label">Milestone Snapshot Code</label>

				<dx-text-box
					[value]="milestone.snapshotCode"
					[readOnly]="true"
				></dx-text-box>

				<label class="input-label">Milestone Snapshot Name</label>

				<dx-text-box
					[value]="milestone.snapshotName"
					[readOnly]="true"
				></dx-text-box>

				<label class="input-label">Baseline - Make this milestone snapshot the new baseline for this year?</label>

				<!-- TODO: there is a bug with the app-switch component that won't correctly two-way bind, -->
				<!--  this section should be swapped out when that bug is fixed (same for the .scss) -->
				<div class="dx-switch__container">
					<label class="label false" [class.label--active]="!formFields.baseline">No</label>
					<dx-switch
						switchedOnText=""
						switchedOffText=""
						[(value)]="formFields.baseline"
						[disabled]="disableBaselineToggle"
					></dx-switch>
					<label class="label true" [class.label--active]="formFields.baseline">Yes</label>

					<fa-icon [icon]="icons.infoCircle" id="publish-lgcs-snapshot-popup__baseline"></fa-icon>
					<dx-tooltip
						target="#publish-lgcs-snapshot-popup__baseline"
						position="bottom"
						showEvent="dxhoverstart"
						hideEvent="dxhoverend">
						<div *dxTemplate="let data = data of 'content'">
							If this snapshot is a revision for this year, please indicate whether it is the new baseline.<br/>
							In the future, users may have the option to select specific snapshot revisions to use in FIT<br/>
							and setting a baseline for the year will allow for a snapshot revision other than the latest<br/>
							or the initial revision to be used as the default.
						</div>
					</dx-tooltip>
				</div>

				<label class="input-label">Milestone Description</label>
				<p>{{ milestone.description }}</p>

				<label class="input-label">Publishing Comments</label>

				<dx-text-area
					[(value)]="formFields.publishingComments"
				></dx-text-area>

				<div *ngIf="selectedBarsYear">
				<label class="input-label">Stats Table</label>
					<dx-pivot-grid id="pivotGrid"
								   [allowSorting]="false"
								   [allowFiltering]="false"
								   [showBorders]="true"
								   [showColumnGrandTotals]="false"
								   [showRowGrandTotals]="false"
								   [showRowTotals]="false"
								   [showColumnTotals]="false"
								   [dataSource]="pivotGridDataSource"
								   >
						<dxo-field-panel
							[showDataFields]="false"
							[allowFieldDragging]="false"
							[showColumnFields]="false"
							[showRowFields]="true"
							[showFilterFields]="false"
							[visible]="true"
						>
						</dxo-field-panel>
						<dxo-field-chooser
							[enabled]="false"
						></dxo-field-chooser>
						<dxo-export [enabled]="true"></dxo-export>
					</dx-pivot-grid>

					<div class="footnotes">
						<div>¹ Filed & data available in FIT</div>
						<div>² These two numbers should total # of Filers</div>
					</div>
				</div>


			</div>

			<!-- State: confirmation -->
			<div class="app-popup__inner" *ngSwitchCase="'confirmation'">
				<p>
					Listed below are the full details of the snapshot to be generated for {{'Publish BARS Year'}} and ten prior years. Submitting this publish process will start a background job that can be monitored from the Admin Job Queue. This process may take up to 10-15 minutes to complete.
				</p>

				<label class="input-label">Publish BARS Year</label>
				<p>{{ formFields.barsYear }}</p>

				<label class="input-label">Milestone Snapshot Code</label>
				<p>{{ milestone.snapshotCode || '(none)' }}</p>

				<label class="input-label">Milestone Snapshot Name</label>
				<p>{{ milestone.snapshotName || '(none)' }}</p>

				<label class="input-label">Baseline - Make this milestone snapshot the new baseline for this year?</label>
				<p>{{ formFields.baseline ? 'Yes' : 'No' }}</p>

				<label class="input-label">Milestone Description</label>
				<p>{{ milestone.description || '(none)' }}</p>

				<label class="input-label">Publishing Comments</label>
				<p>{{ formFields.publishingComments || '(none)' }}</p>

				<label class="input-label">Total Revenue</label>
				<p>{{ totalRevenuesForPublishingSnapshot | currency : 'USD' : 'symbol' : '1.0-0'  }} </p>

				<label class="input-label">Filers w/ Data</label>
				<p>{{ totalFilersForPublishingSnapshot || '(none)' }}</p>
			</div>

			<!-- State: complete -->
			<div class="app-popup__inner" *ngSwitchCase="'complete'">
				<p>
					The publish process has been initiated and its progress can be monitored in the Admin Job Queue:
					<a [href]="jobQueueUrl" target="_blank">View Job Queue</a>.
				</p>
			</div>

			<div class="app-popup__footer" [ngSwitch]="state">
				<ng-container *ngSwitchCase="'inputs'">
					<dx-button
						text="Next"
						type="default"
						[disabled]="!toggleNextButton"
						(click)="next()"
					></dx-button>
				</ng-container>

				<ng-container *ngSwitchCase="'confirmation'">
					<dx-button
						text="Back"
						(click)="back()"
					></dx-button>

					<dx-button
						text="Confirm & Submit Publish Process"
						type="default"
						(click)="submit()"
					></dx-button>
				</ng-container>
			</div>
		</div>
	</div>
</dx-popup>
