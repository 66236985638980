import { Injectable } from '@angular/core';

function isString(value) {
	return Object.prototype.toString.call(value) === '[object String]';
}

export class Filter {
	key = null;
	predicate = null;
	value = null;

	constructor(key, predicate, value) {
		this.key = key;
		this.predicate = predicate;
		this.value = value;
	}

	toQueryString() {
		let result;
		if (this.predicate === 'in') {
			result = `${this.key}/any(c: c in (${this.value}))`;
			return this.value ? result : '';
		} else {
			result = this.key + ' ' + this.predicate + ' ';
			// add quote literal for strings, pass all others as-is
			result += isString(this.value)
				? '\'' + this.value + '\''
				: this.value;
			return result;
		}
	}
}

export class FilterGroup {
	filters = [];
	clause = 'or';

	constructor(filters = [], clause = 'or') {
		this.filters = filters;
		this.clause = clause;
	}

	toQueryString() {
		let result = '';
		const self = this;

		// if filters contains no Filters, do nothing
		if (Array.isArray(this.filters) && this.filters.length > 0) {
			result += '(';
			this.filters.forEach(function (filter, index, arr) {
				// add clause if not last in the array
				const suffix = index < arr.length - 1 ? ' ' + self.clause + ' ' : '';
				result += filter.toQueryString() + suffix;
			});
			result += ')';
		}

		return result;
	}
}

class ODataQueryBuilder {
	filterChunks = [];
	defaultFilterClause = 'and';
	defaultGroupFilterClause = 'or';

	constructor(filterChunks = []) {
		this.filterChunks = filterChunks;
	}

	addFilter(key, predicate, value, clause = null) {
		clause = clause || this.defaultFilterClause;
		// Do not add join clause for first item in filterChunks
		if (this.filterChunks.length > 0) {
			this.filterChunks.push(clause);
		}

		this.filterChunks.push(new Filter(key, predicate, value));
		return this;
	}

	addFilterGroup(filters, groupClause = null, clause = null) {
		// Do nothing if empty filters array is supplied
		if (Array.isArray(filters) && filters.length > 0) {
			clause = clause || this.defaultFilterClause;
			if (this.filterChunks.length > 0) {
				this.filterChunks.push(clause);
			}

			groupClause = groupClause || this.defaultGroupFilterClause;
			this.filterChunks.push(new FilterGroup(filters, groupClause));
		}

		return this;
	}

	toQueryString() {
		let result = '';

		this.filterChunks.forEach(function (filterChunk, index, arr) {
			const suffix = index < arr.length - 1 ? ' ' : '';
			if (filterChunk instanceof Filter || filterChunk instanceof FilterGroup) {
				result += filterChunk.toQueryString() + suffix;
			} else if (isString(filterChunk)) {
				result += filterChunk + ' ';
			}
		});

		return result;
	}
}


@Injectable({
	providedIn: 'root'
})
export class ODataQueryBuilderService {

	Filter = Filter;
	FilterGroup = FilterGroup;
	ODataQueryBuilder = ODataQueryBuilder;

	constructor() { }
}
