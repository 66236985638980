import {Injectable} from '@angular/core';
import {HistoryService} from './history.service';
import {TabService} from './tab.service';
import {Tab} from '../models/tab';
import {PivotGridService} from './pivot-grid.service';
import {UserInterfaceService} from './user-interface.service';
import {ReportId} from '../models/report-id';
import {TabState} from '../models/tab-state';
import {WizardService} from '../../components/wizard/wizard.service';
import {RankingParameters} from '../../modules/info/rankings/models/ranking-parameters';
import {FitApiService} from '../../modules/api/fit-api/fit-api.service';
import {LoggerService} from './logger.service';
import {GovernmentInfoService} from './government-info.service';
import {forkJoin} from 'rxjs';
import {IndicatorReportType} from '../../modules/api/fit-api/models/indicators/indicator-report-type';
import {FilingBasis} from '../../modules/api/fit-api/models/snapshots/filing-basis';
import {AccountingCategory} from '../../modules/services/accounting-category/models/accounting-category';
import {AccountingCategoryType} from '../../modules/services/accounting-category/models/accounting-category-type';
import {SnapshotId} from '../../modules/api/fit-api/models/snapshot-like';

@Injectable({
	providedIn: 'root'
})
/**
 * Allows a Bonanza/FIT component to wrap a genericized component and provide application-specific logic.
 */
export class FitActionsService {
	constructor(
		private history: HistoryService,
		private tabService: TabService,
		private pivotGridService: PivotGridService,
		private uiService: UserInterfaceService,
		private wizard: WizardService,
		private fitApi: FitApiService,
		private logger: LoggerService,
		private govInfoService: GovernmentInfoService
	) {
	}

	/**
	 * Capture the Tab reference that the navigation should occur on. Called from the parent component.
	 *
	 * @param tab
	 */
	navigateToGovProfile = (tab: Tab) => {
		// Navigate to Gov Profile for given mcag. Called from the child/Reusable component.
		return (mcag: string, snapshotId?: SnapshotId): void => {
			if (!mcag) {
				this.logger.error(`${this.constructor.name}.navigateToGovProfile requires an mcag`);
			}
			// push any customizations before switching state
			this.history.replaceStateTabs();
			// TODO: show loading dialog over app until finished
			// lookup government in LocalGovernments endpoint
			this.fitApi.getLocalGovernment(mcag, snapshotId ?? tab.snapshotId).subscribe(gov => {
				const updates = {governments: [gov], title: gov.entityNameWithDba};
				// buildProfileTab is going to modify tab inline, and thus newTab will just be a reference. Record
				// the state here
				const fromTabState = tab.state;
				this.tabService.buildProfileTab(tab, updates).then(newTab => {
					this.tabService.getInitialYearsRangeForTabState(TabState.profile, gov.financialsDatasetSource, tab.snapshotId).subscribe(years => {
						tab.years = years;
						// if we're coming from a government-profile, TabsComponent's switch will not detect a change,
						// so recreate the tab and delete the old one
						if (fromTabState === 'government-profile') {
							// newTab comes out as a reference to tab
							newTab = Object.assign(new Tab, newTab);
							delete newTab.id;
							this.tabService.add(newTab, true).then(() =>
								this.tabService.delete(tab).then(() =>
									this.history.pushStateTabs()
								)
							);
						} else {
							// capture the brand-new state after the tabs array has been emitted (this happens inside save promise)
							this.tabService.save(newTab).then(() => this.history.pushStateTabs());
						}
					});
				});
			});
		};
	};

	/**
	 * Create a new gov profile tab
	 *
	 * TODO: need to be able to pass bars years range in
	 */
	createGovProfile = (government: any) => {
		return new Promise<Tab>(resolve => {
			this.tabService.buildBlankTab('New Tab', TabState.hype, true).then(tab => {
				this.tabService.buildProfileTab(tab, {
					governments: [{
						mcag: government.mcag,
						countyCodes: government.CountyCode,
						entityName: government.EntityName,
						entityNameWithDba: government.entityNameWithDba,
						financialsDatasetSource: government.financialsDatasetSource,
						govTypeCode: government.govTypeCode,
						lookupNameWithDba: government.lookupNameWithDba,
						prime: government.prime
					}],
					title: government.entityNameWithDba,
				}, true).then(updateTab => {
					this.navigateToGovProfile(updateTab);
					resolve(updateTab);
				});
			});
		});
	};

	/**
	 * Capture the Tab reference that the navigation should occur on. Called from the parent component.
	 *
	 * @param tab If tab is not passed, create a new Tab
	 * @param snapshotId If snapshotId is not passed, assume latest
	 */
	navigateToGovTypeProfile = (tab?: Tab, snapshotId?: SnapshotId) => {
		// Navigate to Gov Type Profile for given govTypeCode. Called from the child/Reusable component.
		return (govTypeCode: string, govTypeName: string): void => {
			this.history.replaceStateTabs();
			// use the snapshot passed in, or snapshot from the tab (or undefined)
			const snapshotIdOrDefault = snapshotId ?? tab?.snapshotId;

			this.tabService.getInitialYearsRangeForTabState(TabState.typeProfile, null, snapshotIdOrDefault).subscribe(years => {
				const updates = {govTypes: [govTypeCode], title: govTypeName, years: years};
				this.tabService.buildTypeProfileTab(tab, updates).then(newTab => {
					this.tabService.save(newTab).then(() => this.history.pushStateTabs());
					// If the tab was not passed, then we must have created a new one, so assume we also want to select
					// that new Tab
					if (!tab) {
						this.tabService.setSelectedTab(newTab);
					}
				});
			});
		};
	};

	/**
	 * Navigate to a government report
	 *
	 * @param tab
	 */
	navigateToGovernmentReport = (tab: Tab) => {
		return (reportId: ReportId, displayYear: number, fieldTransformations: Array<any>): void => {
			this.history.replaceStateTabs();
			// update the tab state first!
			this.tabService.buildGovernmentBasedTab(tab, {
				reportId: reportId,
				governments: tab.governments,
				showPopupNotification: true,
			}).then(tabUpdate => {
				// Then set the years
				this.tabService.getInitialYearsRangeForTabState(tab.state, tabUpdate.report.financialsDatasetSource, tab.snapshotId, displayYear).subscribe(years => {
					tab.years = years;
					this.pivotGridService.rebuildDataSource(tabUpdate, fieldTransformations);
					this.tabService.save(tabUpdate).then(() => this.history.pushStateTabs());
				});
			});
		};
	};

	/**
	 * Navigate to Track C (summarized values across all governments or govTypes and locations)
	 *
	 * @param tab
	 * @param govTypeCodes
	 * @param locations - countyCode
	 */
	navigateToSummaryReport = (tab: Tab, govTypeCodes: Array<string> = [], locations: Array<number> = []) => {
		return (reportId: ReportId, displayYear?: number, fieldTransformations?: Array<any>): void => {
			this.fitApi.getAnnualFilingSnapshot(tab.snapshotId).subscribe(snapshot => {
				this.history.replaceStateTabs();

				const govTypeObjects = snapshot.detail.governmentTypes.filter(x => govTypeCodes.includes(x.code));
				const locationObjects = snapshot.detail.counties.filter(x => locations.includes(x.countyCode));
				this.tabService.buildSummaryBasedTab(tab, {
					reportId,
					govTypes: govTypeObjects,
					locations: locationObjects
				}).then(tabUpdate => {
					this.tabService.getInitialYearsRangeForTabState(tab.state, tabUpdate.report.financialsDatasetSource, tab.snapshotId).subscribe(years => {
						tab.years = years;
						this.tabService.save(tabUpdate).then(result => {
							this.pivotGridService.rebuildDataSource(result, fieldTransformations);
							this.history.pushStateTabs();
						});
					});
				});
			});
		};
	};

	/**
	 * Navigate to a ranking report
	 * TODO: looks like createNewTab was never implemented, may still need to be done, depending on what is calling it
	 *
	 * @param tab
	 * @param createNewTab
	 */
	navigateToRankingReport = (tab: Tab, createNewTab = false) => {
		return (parameters: RankingParameters): void => {
			this.fitApi.getAnnualFilingSnapshot(tab.snapshotId).subscribe(snapshot => {
				this.history.replaceStateTabs();

				// todo fix this mess that was created by the introduction of AccountingCategory
				// the category is barsAccountId if not null, otherwise use the fsSectionId
				const categoryId = parameters.barsAccountId ?? parameters.fsSectionId;
				const snapshotPath = parameters.barsAccountId == null ? 'financialSummarySections' : 'accountDescriptors';
				// @ts-ignore what in the hell is this error??
				const detail = snapshot.detail[snapshotPath].find(x => x.id === categoryId);
				// category.isSection = parameters.barsAccountId == null;
				const accountingCatType = parameters.barsAccountId != null
					? AccountingCategoryType.bars
					: AccountingCategoryType.financialSummarySection;

				const category = new AccountingCategory(
					accountingCatType,
					AccountingCategory.getCompositeKey(accountingCatType, categoryId),
					null,
					null,
					detail.acctSgmt,
					accountingCatType === AccountingCategoryType.financialSummarySection,
					categoryId,
					detail.sortOrder,
					detail.name,
					detail.categoryDisplay
				);

				// todo add logic for NOT creating new tab
				// Not providing a tab (i.e., null) causes the functions inside buildRankingBasedTab to *add* the new tab
				// to the tabs storage. Ugh. Don't do it.
				const newTab = new Tab('New Tab', TabState.lgfrs, tab.snapshotId);
				this.tabService.buildRankingBasedTab(newTab, {
					category: category,
					govTypes: [snapshot.detail.governmentTypes.find(x => x.code === parameters.govTypeCode)],
				}).then(updatedTab => {
					// Set fund category filters as needed
					const fields = [];
					if (parameters.fundCategoryId) {
						fields.push({
							id: 'fundGroupFilter',
							name: 'fundGroupFilter',
							filterValues: [[parameters.fundCategoryId]]
						});
					}
					updatedTab.initialFields = fields;

					this.tabService.getInitialYearsRangeForTabState(updatedTab.state, updatedTab.report.financialsDatasetSource, updatedTab.snapshotId).subscribe(years => {
						updatedTab.years = years;
						// launch in new tab
						this.tabService.add(updatedTab).then(() => this.history.pushStateTabs());
					});
				});
			});
		};
	};

	navigateToIndicator = (tab: Tab) => {
		return (mcag: string, instanceCode: string, years: [number, number] = null) => {
			this.history.replaceStateTabs();
			const localGovernment = this.fitApi.getLocalGovernment(mcag, tab.snapshotId);
			const indicatorGroups = this.govInfoService.getAnnualFilingIndicatorGroups(tab.snapshotId, mcag, years);
			forkJoin({localGovernment, indicatorGroups}).subscribe(result => {
				// indicator groups are groups of arrays, but we only care about looking through the indicators by
				// instanceCode
				const indicator = result.indicatorGroups
					.flatMap(x => x.indicators)
					.find(x => x.instanceCode === instanceCode);
				const updates = {
					years: years,
					governments: [result.localGovernment],
					indicator: indicator,
					title: `${result.localGovernment.entityNameWithDba}: ${indicator.title}`
				};
				this.tabService.buildIndicatorBasedTab(tab, updates).then(tabUpdate => {
					this.tabService.save(tab).then(() => this.history.pushStateTabs());
				});
			});
		};
	};

	// todo some of these functions are missing year parameters.
	navigateToIndicatorReport = (tab: Tab) => {
		return (indicatorReportType: IndicatorReportType, filingBasis: FilingBasis, govTypeCode: string, year?: number) => {
			this.fitApi.getAnnualFilingSnapshot(tab.snapshotId).subscribe(snapshot => {
				this.history.replaceStateTabs();
				const govType = snapshot.detail.governmentTypes.find(x => x.code === govTypeCode);
				const updates = {
					filingBasis: filingBasis,
					reportId: indicatorReportType.key,
					govTypes: [govType],
					years: year ? [year, null] : tab.years
				};
				this.tabService.buildIndicatorReportTab(tab, updates).then(tabUpdate => {
					this.tabService.save(tab).then(result => {
						this.pivotGridService.rebuildDataSource(result);
						this.history.pushStateTabs();
					});
				});
			});
		};
	};

	/**
	 * Launch the filer report
	 *
	 * @param tab
	 */
	launchFilerReport = (tab: Tab) => {
		return (): void => {
			this.uiService.showFilerReport();
		};
	};

	/**
	 * Launch the wizard
	 *
	 * @param tab
	 */
	launchWizard = (tab: Tab) => {
		return (): void => {
			this.history.replaceStateTabs();
			this.tabService.setSelectedTabState(TabState.wizard).then(() => {
				this.wizard.jumpToStep(1);
				this.wizard.reset();
				this.history.pushStateTabs();
			});
		};
	};
}
