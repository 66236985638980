import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { ProjectionService } from '../projection.service';
import { Tab } from 'app/shared/models/tab';
import { SnapshotService } from 'app/shared/services/snapshot.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { ModalService } from 'app/shared/modules/modal/modal.service';
import { Modal } from 'app/shared/modules/modal/modal';
import { TabService } from 'app/shared/services/tab.service';
import {PivotGridService} from '../../../shared/services/pivot-grid.service';
import {FilingStatusService} from '../../../modules/services/filing-status-service/filing-status.service';
import {pipe} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SnapshotId} from '../../../modules/api/fit-api/models/snapshot-like';

@Component({
	selector: 'app-projection-launcher',
	templateUrl: './projection-launcher.component.html',
	styleUrls: ['./projection-launcher.component.scss']
})
export class ProjectionLauncherComponent implements OnInit, OnChanges {

	@Input() tab: Tab;
	icon = faBinoculars;
	disabled = true;
	hint: string;
	mode: 'add' | 'remove';
	private hintText = 'Projections are only available for reports containing a single government entity. Currently, this report contains more than one entity. Try using the Report Menu to adjust your query to just one entity. The Projections feature will then become available for your report.';

	constructor(
		private projectionService: ProjectionService,
		private filingStatus: FilingStatusService,
		private snapshotService: SnapshotService,
		private logger: LoggerService,
		private modalService: ModalService,
		private pivotGridService: PivotGridService,
		private tabService: TabService
	) { }

	ngOnInit() { }

	private setMode = () => {
		this.projectionService.get(this.tab.pivotGridSettings.projectionId).then(result => {
			// if there is a projection applied to tab then set to 'remove' mode, otherwise add
			this.mode = result ? 'remove' : 'add';
		}).catch(error => {
			this.logger.error(error);
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		const hasTabChange = changes.tab && changes.tab.currentValue;
		const hasSingleGovernment = hasTabChange
			&& changes.tab.currentValue.governments.length === 1;
		if (hasSingleGovernment) {
			this.enable();
		} else {
			this.disable();
		}
		if (hasTabChange) {
			this.setMode();
		}
	}

	disable() {
		this.disabled = true;
		// TODO hint is not appearing
		this.hint = this.hintText;
	}

	enable() {
		this.disabled = false;
		this.hint = null;
	}

	remove = () => this.pivotGridService.removeFromPivotGridTab(this.tab);

	launch = () => {
		this.governmentHasFiledForBaseYear(this.tab?.getPrimaryGovernment()?.mcag, this.tab.snapshotId).subscribe(hasFiled => {
			if (this.tabIsOnLatestSnapshot() && hasFiled) {
				this.projectionService.launch();
			} else {
				let modal: Modal;
				if (hasFiled) {
					modal = {
						title: 'Projections are only available using the latest published data',
						width: 700,
						content: 'Projections are a way of creating an estimate for potential future finances. Since we’re looking into the future, we need to base projections on the most recent data available.',
						options: [
							{ key: 'ok', label: 'Switch to the most current published data snapshot, and start a new projection' },
							{ key: 'cancel', label: 'Cancel', default: true }
						]
					};
				} else {
					modal = {
						title: 'Projections are only available if there is recent filing data',
						width: 700,
						hideQuestion: true,
						content: 'Projections are a way of creating an estimate for potential future finances. Since we’re looking into the future, we need to base projections on the latest filing year.',
						options: [
							{ key: 'confirm', label: 'Ok', default: true }
						]
					};
				}
				this.modalService.prompt(modal).then(value => {
					if (value.key === 'ok') {
						// change snapshot on tab
						this.tab.snapshotId = this.snapshotService.latestId;
						this.tabService.save(this.tab);
						this.projectionService.launch();
					}
				});
			}
		});
	}

	private tabIsOnLatestSnapshot = () =>
		this.tab.snapshotId === this.snapshotService.latestId;

	private governmentHasFiledForBaseYear = (mcag: string, snapshotId: SnapshotId) =>
		this.projectionService.getBaseYear(mcag, snapshotId).pipe(
			switchMap(baseYear => this.filingStatus.getFilingStatusForYear(mcag, baseYear, snapshotId).pipe(
				map(fs => this.filingStatus.filed(fs))
			))
		);

}
