<h2>How do you want to explore the data?</h2>

<div class="content">
	<p>You can start with any of the options below and then explore further once you are viewing the data and have more questions.</p>

	<ul class="headers">
		<li>By Individual Government</li>
		<li>By Government Type</li>
		<li>By Category</li>
		<li>By Financial Condition</li>
	</ul>
	<ul class="selections">
		<li>
			<button
				type="button"
				class="choice"
				[ngClass]="{ 'active': ['a', 'b1', 'b2'].includes(trackId) }"
				(click)="changeTrack('a')"
			>
				Explore data for a <strong>specific government</strong>
			</button>
			<wasao-hint
				icon="question"
				hintInformation="exploreIndividualGovernment"
			></wasao-hint>
		</li>
		<li>
			<button
				type="button"
				class="choice"
				[ngClass]="{ 'active': trackId === 'c' }"
				(click)="changeTrack('c')"
			>
				Generate totals for <strong>groups of governments</strong>
			</button>
			<wasao-hint icon="question">
				Access summary data for specific government types. For example, Cities, Counties, Fire Districts, etc. "All" is also an option.
			</wasao-hint>
		</li>
		<li>
			<button
				type="button"
				class="choice"
				[ngClass]="{ 'active': trackId === 'd' }"
				(click)="changeTrack('d')"
			>
				Generate summaries by <strong>{{wizardService.userHasGlobalAccess ? 'debt/liability category, ' : '' }}government function, revenue source, beginning balances, or ending balances</strong>, ranked by individual government
			</button>
			<wasao-hint icon="question">
				Access summary data for {{wizardService.userHasGlobalAccess ? 'debt/liability categories, ' : '' }}sources of revenue (taxes, permits, charges, etc.), by government function (public safety, streets, parks, etc.), beginning balances, or ending balances.
			</wasao-hint>
		</li>
		<li>
			<button
				type="button"
				class="choice"
				[ngClass]="{ 'active': trackId === 'e' }"
				(click)="changeTrack('e')"
			>
				Generate financial indicator reports for <strong>a group of governments</strong>
			</button>
			<wasao-hint icon="question" hintInformation="exploreFinancialCondition"></wasao-hint>
		</li>
	</ul>
</div>

<!--<div class="parent">-->
<!--	<button-->
<!--		type="button"-->
<!--		class="choice"-->
<!--		[ngClass]="{ 'active': ['a', 'b1', 'b2'].includes(trackId) }"-->
<!--		(click)="changeTrack('a')"-->
<!--	>-->
<!--		<div class="div1 title">By Individual Government</div>-->
<!--		<div class="div2 description">-->
<!--			<div>Explore data for a <strong>specific government</strong></div>-->
<!--		</div>-->
<!--	</button>-->
<!--	<div class="div3">-->
<!--		<wasao-hint-->
<!--			icon="question"-->
<!--			hintInformation="exploreIndividualGovernment"-->
<!--		></wasao-hint>-->
<!--	</div>-->

<!--	<button-->
<!--		type="button"-->
<!--		class="choice"-->
<!--		[ngClass]="{ 'active': trackId === 'c' }"-->
<!--		(click)="changeTrack('c')"-->
<!--	>-->
<!--		<div class="div4 title"> By Government Type</div>-->
<!--		<div class="div5 description">-->
<!--			<div>Generate totals for <strong>groups of governments</strong></div>-->
<!--		</div>-->
<!--	</button>-->
<!--	<div class="div6">-->
<!--		<wasao-hint icon="question">-->
<!--			Access summary data for specific government types. For example, Cities, Counties, Fire Districts, etc.-->
<!--			"All" is also an option.-->
<!--		</wasao-hint>-->
<!--	</div>-->

<!--	<button-->
<!--		type="button"-->
<!--		class="choice"-->
<!--		[ngClass]="{ 'active': trackId === 'd' }"-->
<!--		[disabled]="wizardService.category"-->
<!--		(click)="changeTrack('d')"-->
<!--	>-->
<!--		<div class="div7 title">By Category</div>-->
<!--		<div class="div8 description">-->
<!--			<div>Generate summaries by <strong>government function or revenue source</strong>, ranked by individual government</div>-->
<!--		</div>-->
<!--	</button>-->
<!--	<div class="div9">-->
<!--		<wasao-hint icon="question">-->
<!--			Access summary data for sources of revenue (taxes, permits, charges, etc.) and/or by government function-->
<!--			(public safety, streets, parks, etc.).-->
<!--		</wasao-hint>-->
<!--	</div>-->

<!--	<button-->
<!--		type="button"-->
<!--		class="choice"-->
<!--		[ngClass]="{ 'active': trackId === 'e' }"-->
<!--		(click)="changeTrack('e')"-->
<!--	>-->
<!--		<div class="div10 title">By Financial Condition</div>-->
<!--		<div class="div11 description">-->
<!--			<div>Generate financial indicator reports for <strong>a group of governments</strong></div>-->
<!--		</div>-->
<!--	</button>-->
<!--	<div class="div12">-->
<!--		<wasao-hint icon="question" hintInformation="exploreFinancialCondition"></wasao-hint>-->
<!--	</div>-->
<!--</div>-->
