import {Component, ContentChild, ViewChild, ViewChildren} from '@angular/core';
import {UserInterfaceService} from 'app/shared/services/user-interface.service';
import {ProjectionService} from '../projection.service';
import {faCopy, faEdit, faInfoCircle, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {TabBarItem} from 'app/shared/components/tab-bar/tab-bar.component';
import {TabService} from 'app/shared/services/tab.service';
import {Tab} from 'app/shared/models/tab';
import {Projection} from '../projection';
import {SnapshotService} from 'app/shared/services/snapshot.service';
import {SideDrawerComponent} from '../../side-drawer/side-drawer.component';
import {PivotGridService} from '../../../shared/services/pivot-grid.service';
import {PeerSet} from '../../../shared/models/peer-set';
import {confirm} from 'devextreme/ui/dialog';

@Component({
	selector:    'app-projection-drawer',
	templateUrl: './projection-drawer.component.html',
	styleUrls:   ['./projection-drawer.component.scss']
})
export class ProjectionDrawerComponent {

	@ViewChild(SideDrawerComponent, {static: true}) drawer;

	// TabBar implementation
	public icons                            = {
		close: faTimes,
		info:  faInfoCircle,
		edit: faEdit,
		delete: faTrash,
		copy: faCopy
	};
	public tabBarItems: Array<TabBarItem> = [
		{id: 'existing', label: 'Existing Saved Projections'},
		{id: 'create', label: 'Create a Projection'},
		{id: 'edit', label: 'Edit Projection', visible: false }
	];

	public selectedTabBarId  = 'existing';
	public currentProjection: Projection;
	public baseYear: number;

	/** Keeps track of projections in database */
	public projections: Array<Projection> = [];

	/** Confirmation dialog is currently active */
	public isConfirmationDialogVisible: boolean;
	public isOpenInNewTab = false;
	public isDirty        = false;
	public isVisible      = false;
	public tab: Tab;
	public latestSnapshot;
	public currentGovernmentName: string;
	public editingMultipleFunds = false;
	public editorFundValue: string;

	public handleTabBarSelect = value => {
		if (value === 'existing') {
			this.currentProjection = null;
			this.setCreateEditTabBar('create');
		}
		this.selectedTabBarId = value;
	}

	constructor(
		private uiService: UserInterfaceService,
		private projectionService: ProjectionService,
		private tabService: TabService,
		private pivotGridService: PivotGridService,
		private snapshotService: SnapshotService
	) {
		this.tabService.tabs.subscribe(tabs => {
			this.tab                   = tabs && tabs.length && tabs.find(x => x.selected);
			this.currentGovernmentName = this.tab && this.tab.governments && this.tab.governments[0] && this.tab.governments[0].entityNameWithDba;
			const mcag: string = this.tab.governments?.[0]?.mcag;
			this.projectionService.getBaseYear(mcag, this.tab.snapshotId).subscribe(baseYear => this.baseYear = baseYear );
		});
		this.snapshotService.getSnapshot().subscribe(snapshot => {
			this.latestSnapshot = snapshot;
			const mcag: string = this.tab.governments?.[0]?.mcag;
			this.projectionService.getBaseYear(mcag, snapshot?.id).subscribe(baseYear => this.baseYear = baseYear );
		});
		this.projectionService.drawerIsOpen.subscribe(value => {
			this.isVisible = value;
			this.drawer.setVisibility(value);
		});
		this.projectionService.projections.subscribe(projections => {
			const mcag: string = this.tab.governments?.[0]?.mcag;
			this.projections = projections.filter(p => p.baseYear === this.baseYear && p.mcag === mcag)
				.sort((a, b) => a.name.localeCompare(b.name));
			this.setTabBar();
		});
		this.projectionService.editingMultipleFunds.subscribe(value => {
			this.editingMultipleFunds = value;
		});
		this.projectionService.editorFundValue.subscribe( value => {
			this.editorFundValue = value;
		});
	}

	setTabBar = () => {
		if (this.projections.length === 0) {
			this.setCreateEditTabBar('create');
			this.selectedTabBarId = 'create';
		} else {
			if (this.currentProjection?.id) {
				this.setCreateEditTabBar('edit');
				this.selectedTabBarId = 'edit';
			} else {
				this.selectedTabBarId = 'existing';
			}
		}
	};

	setProjection = event => {
		if (event) {
			this.isDirty           = true;
			this.currentProjection = event;
			if (!this.currentProjection.id && this.selectedTabBarId === 'edit') {
				this.setCreateEditTabBar('create');
				this.selectedTabBarId = 'create';
			}
		}
		else {
			this.isDirty           = false;
			this.currentProjection = null;
		}
	};

	private validate = (event): boolean => event.validationGroup.validate().isValid;

	save = (event) => {
		if (!this.validate(event)) {
			return;
		}
		if (this.selectedTabBarId === 'edit') {
			confirm('Are you sure you want to save these changes?  Saving will overwrite the existing saved projection with this name.', 'Save Projection').then(confirmed => {
				if (confirmed) {
					this.projectionService.save(this.currentProjection).then(result => {
						this.isDirty = false;
					});
				}
			});
		} else {
			this.projectionService.save(this.currentProjection).then(result => {
				this.isDirty = false;
				this.projectionService.savedProjectionId.next(this.currentProjection.id);
			});
		}
	};

	saveAndApply = (event) => {
		if (!this.validate(event)) {
			return;
		}
		if (this.selectedTabBarId === 'edit') {
			confirm('Are you sure you want to save these changes?  Saving will overwrite the existing saved projection with this name.', 'Save Projection').then(confirmed => {
				if (confirmed) {
					this.projectionService.save(this.currentProjection).then(result => {
						this.isDirty = false;
						this.pivotGridService.applyToPivotGridTab(this.tab, this.currentProjection);
						this.drawer.close();
						this.uiService.releaseApplicationFocus();
					});
				}
			});
		} else {
			this.projectionService.save(this.currentProjection).then(result => {
				this.isDirty = false;
				this.pivotGridService.applyToPivotGridTab(this.tab, this.currentProjection);
				this.drawer.close();
				this.uiService.releaseApplicationFocus();
			});
		}
	};

	resetAllFunds = (event) => {
		this.projectionService.resetEditedFunds('all');
	}

	resetThisFund = (event) => {
		this.projectionService.resetEditedFunds('selected');
	}

	edit(projection: Projection) {
		this.setCreateEditTabBar('edit');
		this.currentProjection = projection;
		this.projectionService.loadProjectionForEdit(projection);
		this.selectedTabBarId = 'edit';
	}

	confirmDelete(projection: Projection) {
		confirm('Are you sure you want to delete this projection?', 'Delete Projection').then(confirmed => {
			if (confirmed) {
				this.projectionService.delete(projection.id);
			}
		});
	}

	handleCreateClick() {
		this.setCreateEditTabBar('create');
		this.selectedTabBarId = 'create';
	}

	setCreateEditTabBar(which: 'edit' | 'create') {
		const other = which === 'edit' ? 'create' : 'edit';
		this.tabBarItems.find(x => x.id === which).visible = true;
		this.tabBarItems.find(x => x.id === other).visible = false;
	}

	apply(projection: Projection) {
		this.pivotGridService.applyToPivotGridTab(this.tab, projection);
		this.drawer.close();
		this.uiService.releaseApplicationFocus();
	}

	copy(projection: Projection) {
		this.setCreateEditTabBar('create');
		const newProjection = Object.assign(new Projection, projection);
		delete newProjection.id;
		newProjection.name = `${newProjection.name} Copy`;
		this.currentProjection = newProjection;
		this.isDirty = true;
		this.projectionService.loadProjectionForEdit(newProjection);
		this.selectedTabBarId = 'create';
	}

	onClosed($event) {
		this.setCreateEditTabBar('create');
		this.selectedTabBarId = null;
		this.currentProjection = null;
		this.isDirty = false;
	}

	onCancelClick() {
		if (this.isDirty) {
			confirm('Are you sure you want to cancel without saving your changes?', 'Cancel Edit Projection').then(confirmed => {
				if (confirmed) {
					this.drawer.close(true);
				}
			});
		} else {
			this.drawer.close(true);
		}
	}
}
