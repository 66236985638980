import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ReusableGovernmentTypeInputs} from '../../reusable/models/reusable-government-type-inputs';
import {ChartDataRow} from '../../services/filing-status-service/models/chart-data-row';
import {GovTypes} from '../../api/external-common-service-api/models/gov-types';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {FilingStatusService} from '../../services/filing-status-service/filing-status.service';
import {LoggerService} from '../../../shared/services/logger.service';
import {ExternalCommonServiceApiService} from '../../api/external-common-service-api/external-common-service-api.service';
import {SnapshotService} from '../../../shared/services/snapshot.service';
import {Tab} from '../../../shared/models/tab';
import {TabService} from '../../../shared/services/tab.service';
import {combineLatest} from 'rxjs';
import {pieChart} from '../../../../../sao-patterns/src/tokens/government-colors.js';

@Component({
	selector:    'wasao-accounting-bases',
	templateUrl: './accounting-bases.component.html',
	styleUrls:   ['./accounting-bases.component.scss']
})
export class AccountingBasesComponent extends ReusableGovernmentTypeInputs implements OnChanges {

	@Input() sizeGroup: string;

	dataSource: Array<ChartDataRow>;
	liveGovTypeInfo: GovTypes;
	snapshot: any;
	mandatoryFields: any;
	pieChartColors: Array<string> = pieChart;

	constructor(
		private fitApi: FitApiService,
		private filingStatus: FilingStatusService,
		private logger: LoggerService,
		private common: ExternalCommonServiceApiService,
		private snapshotService: SnapshotService,
		private tabService: TabService,
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.govTypeCode?.currentValue) {
			this.updateData();
		}
	}

	updateData = (): void => {
		this.isLoading = true;

		this.common.getGovTypeDetails().subscribe(result => {
			this.liveGovTypeInfo = result.filter(x => x.GovTypeCode === this.govTypeCode)[0];
		});

		this.tabService.tabs.subscribe((tabs: Array<Tab>) => {
			// cast to new tab to notify children of nested changes
			const currentTab = tabs.find((tab: Tab) => tab.selected);

			// get the snapshot and the mandatory fields
			combineLatest([
				this.snapshotService.getSnapshot(this.snapshotId),
				this.common.getEntitiesMandatoryFields(),
			]).subscribe(([snapshot, mandatoryFields]) => {
				if (snapshot) { // do not use null/initial value
					this.snapshot        = snapshot;
					this.mandatoryFields = mandatoryFields;

					this.dataSource = [];

					this.snapshot.detail.accountingBases.forEach(basis => {
						const count = this.lookupBasisCount(basis.id);
						if (count) {
							this.dataSource.push({
								status: basis.name,
								count:  count,
							});
						}
					});

					this.isLoading = false;
				}
			});
		});
	};

	/**
	 * Count all MCAGs for the specified basis ID, according to the GovTypeCode
	 *
	 * @param basisId
	 */
	lookupBasisCount = (basisId): number => {
		if (!this.mandatoryFields) {
			return null;
		}

		return this.mandatoryFields.filter(r => r.GovTypeCode === this.govTypeCode && r.AcctBasisTypeId === basisId).length;
	};

	customizeLabel = (pointInfo) => `${pointInfo.argumentText}\n(${pointInfo.valueText})`;

}
