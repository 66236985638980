import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ReusableGovernmentInputs} from '../../reusable/models/reusable-government-inputs';
import {switchMap, tap} from 'rxjs/operators';
import {FitApiService} from '../../api/fit-api/fit-api.service';
import {FinancialSummaryService} from '../../services/financial-summary-service/financial-summary.service';
import {forkJoin} from 'rxjs';
import {LoggerService} from '../../../shared/services/logger.service';
import {ReportId} from '../../../shared/models/report-id';
import {FundCategoryId} from '../../services/fund-service/models/fund-category';

class DataGridRow {
	label: string;
	governmentTypeAmount: number;
	thisGovernmentAmount: number;
}

@Component({
	selector: 'wasao-statewide-averages',
	templateUrl: './statewide-averages.component.html',
	styleUrls: ['./statewide-averages.component.scss']
})
export class StatewideAveragesComponent extends ReusableGovernmentInputs implements OnInit, OnChanges {
	/**
	 * Optional. Defaults to showing all fsSectionIds if not specified.
	 */
	@Input() reportType: ReportId;

	/**
	 * Optional. Defaults to all fundCategoryIds, if not specified.
	 */
	@Input() fundCategoryId: FundCategoryId;

	/**
	 * The year to display. Defaults to the barsYearUsed of the newest snapshot in the system.
	 */
	@Input() year: number;

	dataSource: Array<DataGridRow>;
	thisGovernmentAmountTotal: number;
	govTypeAmountTotal: number;
	govTypeDesc: string;

	constructor(
		private fitApi: FitApiService,
		private financialSummary: FinancialSummaryService,
		private logger: LoggerService
	) {
		super();
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.mcag?.currentValue || changes?.snapshotId?.currentValue) {
			this.updateData();
		}
	}

	updateData = () => {
		// Can only perform with an MCAG
		if (!this.mcag) {
			return;
		}
		// do not return statewide averages for live dataset
		if (this.snapshotId === 'live') {
			this.dataSource = null;
			this.hasData.emit(false);
		}

		this.logger.time(this, this.reportType);
		this.thisGovernmentAmountTotal = 0;
		this.govTypeAmountTotal = 0;
		this.isLoading = true;
		this.fitApi.getLocalGovernment(this.mcag, this.snapshotId).pipe(
			tap(localGovernment => this.govTypeDesc = localGovernment.govTypeDesc),
			switchMap(localGovernment =>
				forkJoin({
					governmentAmounts: this.financialSummary.getAmountsByCategoryForYear(
						{ type: 'government', id: this.mcag, year: this.year }, this.reportType, this.fundCategoryId, this.snapshotId
					),
					govTypeAmounts: this.financialSummary.getAmountsByCategoryForYear(
						{ type: 'governmentType', id: localGovernment.govTypeCode }, this.reportType, this.fundCategoryId, this.snapshotId
					)
				})
			)
		).subscribe(result => {
			this.dataSource = new Array<DataGridRow>();
			// iterate the list of govTypeAmounts since this is the larger list (logically, a government of a particular
			// govType cannot have a "category" that does not exist in the larger govType
			// Only process if the government itself has data
			if (result.governmentAmounts?.length) {
				result.govTypeAmounts.forEach(row => {
					const governmentAmountRow = result.governmentAmounts.find(x => x.label === row.label);
					this.thisGovernmentAmountTotal += governmentAmountRow?.amount ?? 0;
					this.govTypeAmountTotal += row.amount;
					this.dataSource.push({
						label: row.label,
						governmentTypeAmount: row.amount,
						thisGovernmentAmount: governmentAmountRow?.amount ?? 0
					});
				});
			} else {
				// null out dataSource in the event user switches to a snapshot without data
				this.dataSource = null;
			}
			this.hasData.emit(this.dataSource?.length > 0);

			this.isLoading = false;
			this.logger.log(this);
			this.logger.timeEnd(this, this.reportType);
		});
	};

	calculateGovTypeAmount = (rowData: DataGridRow) =>
		rowData.governmentTypeAmount / this.govTypeAmountTotal;

	calculateThisGovernmentAmount = (rowData: DataGridRow) =>
		rowData.thisGovernmentAmount / this.thisGovernmentAmountTotal;

}
