import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FinancesAtAGlanceComponent} from './finances-at-a-glance.component';
import {HintModule} from '../../ui/hint/hint.module';
import {DxLoadIndicatorModule, DxSparklineModule} from 'devextreme-angular';
import {LoadingModule} from '../../ui/loading/loading.module';
import {FinancialSummaryServiceModule} from '../../services/financial-summary-service/financial-summary-service.module';


@NgModule({
	declarations: [
		FinancesAtAGlanceComponent
	],
	imports: [
		CommonModule,
		HintModule,
		DxSparklineModule,
		DxLoadIndicatorModule,
		LoadingModule,
		FinancialSummaryServiceModule
	],
	exports: [
		FinancesAtAGlanceComponent
	]
})
export class FinancesAtAGlanceModule {
}
